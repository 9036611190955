import { Modal } from "antd";
import moment from "moment-timezone";
import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";


export default function PaymentBill(props) { 
  const { t,i18n } = useTranslation();

	const {fonctionnalites,role,user,modules} = useContext(AppContext);

  const language=i18n.language 
  const modulesLanguage={"fr":"name","en":"name_en"}
  const moduleLanguage=modulesLanguage[language]

  const functionalitiesLanguage={"fr":"fName","en":"fName_en"}
  const functionalityLanguage=functionalitiesLanguage[language]

  const captureRef = useRef(null);
  const [download,setDownload]=useState(false)


  let {createdAt,amount,ptfideliteUsed,remise,transactionId,allProduct,idCompanyPackage} = props.order;
  //order.allProduct.map

  let fonctionnalities =allProduct.map(item=>{return item.id.fonctionnalites[0].listefonctionnalite })
  if(idCompanyPackage) {

    //companyPackage
  fonctionnalities.push(idCompanyPackage.functionalities.map(f=>({...f,idFonctionnalite:f.idFunctionality})))
  }
  let idFonctionnalities=[]
  let qtes=[]
  let fTypes=[]
  for (let i = 0; i < fonctionnalities.length; i++) {
   const forfait = fonctionnalities[i];
   for (let j = 0; j < forfait.length; j++) {
     const item = forfait[j];
         let index=idFonctionnalities.indexOf(item.idFonctionnalite)
         let fType= fonctionnalites.find((functionality) => functionality._id == item.idFonctionnalite)?.fType
   if(index==-1){
     idFonctionnalities.push(item.idFonctionnalite)
     fTypes.push(fType)
     qtes.push(parseInt(item.qte))
   }
   else{
     if(fType==1||fType==3){
      qtes[index]=Math.max(qtes[index],parseInt(item.qte))
     }else
     qtes[index]=qtes[index]+parseInt(item.qte)
   }
   }
  }
  fonctionnalities=[]
  for (let i = 0; i < idFonctionnalities.length; i++) {
   fonctionnalities.push({idFonctionnalite:idFonctionnalities[i],qte:qtes[i],fType:fTypes[i]})
  }
 //  

  //
  let functionalities=fonctionnalities
  let company=role=="company"?user:user.company
  const getScreenshot = async () => {
    await setDownload(true)
    const canvas = await html2canvas(captureRef.current,{ logging: true, letterRendering: 1, useCORS : true});
    const img = canvas.toDataURL("image/png");
    //converting px to in
    const width=parseInt(canvas.width)/96
    const height=parseInt(canvas.height)/96
    const doc = new jsPDF({orientation:'p',unit:'in',format:[width,height]});
    doc.addImage(img, 0, 0);
  await  doc.save(company.name+moment(createdAt).format("DD-MM-YYYY")+".pdf");
    setDownload(false)
  };
  let subtotal=0
  for (let i = 0; i < props.order.modules.length; i++) {
    subtotal+=props.order.modules[i].price
    
  }
 let list= modules.map( m=>{
  let module=props.order.modules.find(module=>module.idModule==m._id)
  let price=module?module.price:0

let functionalitiesList=m.functionalities.map( f=>{
  let functionality= fonctionnalites.find((fun)=>fun.fType==f)
  let fName=functionality[functionalityLanguage]
  let qte= functionalities.find((fun)=>fun.fType==f)?.qte??0
  return(<tr>
  <td style={{borderColor:"#ddd0"}} >
    <span >{""}</span>
  </td>
  <td  >
    <span >{fName}</span>
  </td>
  <td  >
    <span >{qte>999999?t("illimité"):qte}</span>
  </td>
  <td  style={{borderColor:"#ddd0"}} >
    <span > </span>
    <span>{""}</span>
  </td>
  </tr>)
    })

	return(<>
		<tr>
		<td>
		  <span >{m[moduleLanguage]}</span>
		</td>
		<td  style={{borderColor:"#ddd0"}} >
		  <span > </span>
		  <span ></span>
		</td>
		<td  style={{borderColor:"#ddd0"}} >
		  <span >{}</span>
		</td>
		<td>
		  <span>{price.toFixed(3)}</span>
		  <span > {t("DTN")} </span>
		</td>
	  </tr>
    {functionalitiesList}
    </>
	)
  })
  let promoPrice=((amount/(1-(remise/100)))*(remise/100)).toFixed(3)
  let pfPrice=(ptfideliteUsed/100).toFixed(3)
  let totalHT=((amount+Number(promoPrice)+Number(pfPrice))/1.19).toFixed(3)
   let reduction=((1-((totalHT)/(subtotal)))*100).toFixed(2)
   let reductionPrice=((subtotal)*reduction/100).toFixed(3)
  let TVA=(totalHT*0.19).toFixed(3)
  // let total=(amount*1.19).toFixed(3)
  return (
    <div className="">
      <Modal
        title={<><h5 className="m-1">{t("Facture")}
        </h5>
        </>}
        className="w-75"
        centered
        // closeIcon={
        //    <i className="mt-5 ">
        //      <CloseOutlined className="m-3" />
        //    </i>
        // }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={
          <>
            {/* <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer "
                onClick={() => {
                  props.history.push("/company/manageJobPosts");
                }}
              /> */}
            {/* <input
                type="button"
                className=" btn btn-primary p-1 rounded m-1 "
                value=" Ok"
                onClick={() => {
                  props.history.push("/company/addJobPost");
                }}
              /> */}
          </>
        }
      >
        <div>
        <span role="button" className="right icons15 btn btn-primary  rounded-circle d-flex align-items-center p-2" onClick={getScreenshot}  ><DownloadOutlined className="" /></span>

          <div  ref={captureRef} 
          // style={{  width:download &&"168mm",minHeight:download&& "238mm"}} 
  className={download?"facture p-2 a4":"facture w-100 p-0 m-0"}  >
            <header>
              <h1>{t("Facture")}</h1>
              <address>
                <p> {t("Rec-INOV")}</p>
                <p>
                  {t("AVENU 5 AOUT RUE IBN RACHIK")}
                  <br />
                  3001 Sfax
                </p>
                <p>(216) 58 063 066</p>
                <p>service@Rec-INOV.com</p>
                <p>WWW.Rec-INOV.COM</p>
              </address>
              <span>
                <img
                  alt=""
                  src="images/logo.png"
                />
                <input type="file" accept="image/*" />
              </span>
            </header>
            <article>
              {/* <h1>Client :</h1> */}
              <address>
                <p>
                  {t("Nom")} : {company.name}
                  <br />
                  {t("adresse")} :{company.address} <br /> {t("TEL")} : +{company.phone}<br /> {t("matricule fiscale")} :{company.registrationNumber}
                </p>
              </address>
              <table className="meta">
                <tr>
                  <th>
                    <span>{t("Facture")} N #</span>
                  </th>
                  <td>
                    <span>{transactionId}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>{t("Date de facturation")} </span>
                  </th>
                  <td>
                    <span>{moment(createdAt).format('L')}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>{t("Réf client")} </span>
                  </th>
                  <td>
                    <span>{company.registrationNumber}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>{t("Montant Total")} </span>
                  </th>
                  <td>
                    <span>{amount}</span>
                    <span id="prefix"> {t("DTN")}</span>
                  </td>
                </tr>
              </table>
              <table className="inventory">
                <thead>
                <tr>
                    <th>
                      <span>{t("Module")}</span>
                    </th>
                    <th>
                      <span>{t("Fonctionnalité")}</span>
                    </th>
                    <th>
                      <span>{t("Quantite")}</span>
                    </th>
                    <th>
                      <span>{t("Total")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
					{list}
                </tbody>
              </table>
              <table className="balance">
                <tr>
                  <th>
                    <span dataPrefix>{t("Sous-total")}</span>
                  </th>
                  <td>
                    <span>{subtotal.toFixed(3)}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span dataPrefix> {t("Reduction")}</span> <span>{reduction}%</span>
                  </th>
                  <td>
                    <span dataPrefix>{reductionPrice}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span dataPrefix>{t("Total HT")}</span>
                  </th>
                  <td>
                    <span>{totalHT}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span dataPrefix>T.V.A. 19% </span>
                  </th>
                  <td>
                    <span dataPrefix>{TVA}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
           
                
                <tr>
                  <th>
                    <span dataPrefix> {t("Code PROMO")}</span> <span>{remise}%</span>
                  </th>
                  <td>
                    <span dataPrefix>{promoPrice}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
				<tr>
                  <th>
                    <span dataPrefix> {t("Points de fidélité")}</span> <span>{ptfideliteUsed}</span>
                  </th>
                  <td>
                    <span dataPrefix>{pfPrice}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
            
             
                <tr>
                  <th>
                    <span dataPrefix>{t("Total")} TTC</span>
                  </th>
                  <td>
                    <span>{amount}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
              </table>
            </article>
            <aside>
              <div>
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  style={{
                    fontFamily: "ArialMT,Arial,sans-serif",
                    maxWidth: "100%",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          <span style={{ color: "#7f8c8d" }}>
                            This email was sent to you by Service@Rec-INOV.com
                            (Do not reply)
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </aside>
          </div>
        </div>
      </Modal>
    </div>
  );
}
