import React, { useContext, useState } from "react";
import { UpdateTestExpiration } from "../../services/api/companyTest/UpdateTestExpiration";
import { Button, DatePicker, Modal } from "antd";
import { AppContext } from "../../routes/AppContext";
import { CloseOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useTranslation } from "react-i18next";

export default function UpdateTestExpirationModal(props) {
  const { t } = useTranslation();

  const { headers,serverTime,user,role } = useContext(AppContext);
  let expiration=new Date(serverTime)
  const [expirationDate, setExpirationDate] = useState(expiration);
  const [loading, setLoading] = useState(false);

 const disabledExpirationDate=(current)=> {
    // Can not select days before today and today
    let date=moment(serverTime).add(1, 'months');
    return current 
    && current > moment(date) || current<moment(serverTime)
  }
  const update = async () => {
    setLoading(true)
    if(role==="company"){
    let res = await UpdateTestExpiration(
      { idCandidateTest: props.testResult._id,expirationDate:expirationDate,emailCandidat: props.testResult.candidate.email,idCandidat: props.testResult.candidate._id,companyName:user.name ,jobPostid:props.jobPostid,language: user.emailsLanguage},
      headers
    );
    }else if(role==="responsible"){
      let res = await UpdateTestExpiration(
        { idCandidateTest: props.testResult._id,expirationDate:expirationDate,emailCandidat: props.testResult.candidate.email,idCandidat: props.testResult.candidate._id,companyName:user.company.name ,jobPostid:props.jobPostid ,language: user.company.emailsLanguage},
        headers
      );
    }
    props.setModalVisible(false)
  };
  return (
    <div>
      <Modal
        title={
          <h5 className=" m-1 text-primary">
            <b>{t("Mettre a jour l'expiration du test")}</b>
          </h5>
        }
        className="m-5"
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
              className="m-3"
            />
          </i>
        }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={<></>}
      >
        <div>
        <label className="col-12 mt-2" htmlFor="linkedinPage">
                      <span className="float-left"> {t("Date d'expiration")}</span>
                      <div className="" >
                      <DatePicker
                        placeholder="DD-MM-YYYY"

                      allowClear={false}
                      disabledDate={disabledExpirationDate}
                      format={"DD-MM-YYYY"}

                      className="border form-control"
                          defaultValue={expirationDate && moment(expirationDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                          let m = moment.utcOffset(0);
                        m.set({hour:0,minute:0,second:0,millisecond:0})
                            setExpirationDate(m.toDate())
                    }
                      }}
                       />
                      </div>
                    </label>
        <div className="d-flex justify-content-center mt-2" >

        <Button  className="" type="primary" loading={loading} onClick={update}>
        {t("Enregisrer")}
</Button>
        </div>
        </div>
      </Modal>
    </div>
  );
}
