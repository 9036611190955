import { Modal } from 'antd';
import React, { useContext } from 'react'
import { withRouter } from 'react-router'
import { CloseOutlined } from "@ant-design/icons";
import { ArchiveUploadedCvs } from '../services/api/extractCvs/ArchiveUploadedCvs';
import { GetUploadedCvs } from '../services/api/extractCvs/GetUploadedCvs';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../routes/AppContext';
import decrypt from '../utils/decrypt';
import { useTranslation } from "react-i18next";

 function UpdateCvConfirm(props) {
    const history = useHistory({});
    const { t } = useTranslation();
    const {user,setUser } =
        useContext(AppContext);
    const archiveUploadedCvs=async()=>{
        let response = await ArchiveUploadedCvs();
          if(response){
            props.setModalVisible(false)
          }
    }

    const getUploadedCvs=async()=>{
        let response = await GetUploadedCvs();
        if(response){
            return JSON.parse(decrypt(response.data))
        }
    }

    const updateMyCV=async()=>{
      const cvs=await  getUploadedCvs()
      //fusionner les cvs
      let cv=user
      cv.hardSkills = [...cv.hardSkills,...cvs.hardSkills];
      cv.softSkills = [...cv.softSkills,...cvs.softSkills];
      cv.languages = [...cv.languages,...cvs.languages];
      cv.experiences = [...cv.experiences,...cvs.experiences];
      cv.educations = [...cv.educations,...cvs.educations];
      cv.certificates = [...cv.certificates,...cvs.certificates];
      setUser(cv)
      //redirect to update cv
      history.push({
        pathname: "/candidate/cv",
      });

      props.setModalVisible(false)
      archiveUploadedCvs()
    }
  return (
    <div>
        <Modal
          title={<h5 className="m-1">{t("Mettre à jour votre cv ?")}</h5>}
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined className="m-3" />
            </i>
          }
          visible={props.modalVisible}
          onOk={() => props.setModalVisible(false)}
          onCancel={() => props.setModalVisible(false)}
          footer={
            <>
              <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer"
                onClick={() => {
                    archiveUploadedCvs()
                }}
              />
              <input
                type="button"
                className=" btn btn-primary p-1 rounded m-1 "
                value="Ok"
                onClick={() => {updateMyCV()
                }}
              />
            </>
          }
        >
            {t('Nous avons détecté une nouvelle version de votre cv')}
        </Modal>
      </div>
  )
}
export default withRouter(UpdateCvConfirm)