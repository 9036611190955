import CryptoJS from "crypto-js";
import encode from "./encode";
import decode from "./decode";
 const decryptEnv =async (data) => {
  //this keys are static to decrypt env vars after receiving them in the client side 
//,if you will change the keys here you should change them in the server side 
let decrypted = CryptoJS.AES.decrypt(data,encode("FgH%p5h[nB^^k8R15S")).toString(CryptoJS.enc.Utf8);
decrypted= decode(decrypted)
decrypted = CryptoJS.AES.decrypt(decrypted,encode("!XVe)qT-mFL71rWcNx")).toString(CryptoJS.enc.Utf8);
decrypted= decode(decrypted)
decrypted = CryptoJS.AES.decrypt(decrypted,encode("sKjn$fd5(fù-j&nZ#n")).toString(CryptoJS.enc.Utf8);
decrypted= decode(decrypted)
return decrypted 
}
export default  decryptEnv
