import { Modal } from 'antd';
 import moment from 'moment-timezone';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../routes/AppContext';
import crypt from '../../utils/crypt';
import AsyncImage from '../AsyncImage';

export default function InterviewModal(props) {
  const {role,envVar} = useContext(AppContext); 
  const { t } = useTranslation();

    const history = useHistory({});
    const goToMeet=(e)=>{
       e.preventDefault() 
       e.stopPropagation()
       let interviewLink=props.interview.link
       if(interviewLink)
    window.open(interviewLink,"_blank");
    else
       history.push({
               pathname: "/"+role+"/room/"+crypt(props.interview),
             });
       
     }
    const title=props.interview.idJobPost[0].title
    const date=moment(props.interview.date).format("LLLL")
    const address=props.interview.address
    const name=role=="candidate"?props.interview.idJobPost[0].idCompany[0].name:(props.interview.idCandidate.length?props.interview.idCandidate[0].name:t("Non Alloué"))
    const image=role=="candidate"?"/company/images/"+props.interview.idJobPost[0].idCompany[0].image:(props.interview.idCandidate.length?"/candidate/images/"+props.interview.idCandidate[0].image:"/candidate/images/null")
  return (
    <div>
                <Modal
          title={<h5 className="m-1">{title}</h5>}
          className=""
          centered
        //   closeIcon={
        //     <i className="mt-5 ">
        //       <CloseOutlined className="m-3" />
        //     </i>
        //   }
          visible={props.modalVisible}
          onOk={() => props.setModalVisible(false)}
          onCancel={() => props.setModalVisible(false)}
          footer={
            <>
              {/* <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer "
                onClick={() => {
                //   history.push("/company/manageJobPosts");
                }}
              /> */}
            {!address&&  <input
                type="button"
                disabled={!props.interview.idCandidate.length}
                className=" btn btn-primary p-1 rounded m-1 "
                value={t("Commencer l'entretien")}
                onClick={goToMeet}
              />}
            </>
          }
        >
<AsyncImage  className="logo-m rounded-3 me-2" alt={name} src={ 
                          envVar.REACT_APP_CLIENT_URL
                          +image} /> 
          {name}
          <br/>
          <b>{t("Offre")} :</b> {title}
          <br/>
          <b>{t("Date")}  :</b> {date}
          {address&&<>
          <br/>
          <b>{t("Adresse")}  :</b> {address}
          </>}
        </Modal>
    </div>
  )
}
