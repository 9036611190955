import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { withRouter } from 'react-router-dom'
import { AppContext } from '../../../routes/AppContext'
import { GetJobPostByReference } from '../../../services/api/manageJobPosts/GetJobPostByReference'
import decrypt from '../../../utils/decrypt'

import MatchingView from '../../../views/company/MatchingView'
import Interviews from '../../interviews/Interviews'
import JobApplications from '../../jobApplications/JobApplications'
import CandidatesList from '../../jobPostCandidatesList/CandidatesList'
import TestResults from '../../jobPostTestResults/TestResults'
import Loading from '../../Loading'
import JobPostNavbar from '../../navbar/JobPostNavbar'
import { JobPostContext } from './JobPostContext'

 function JobPostDashboard(props) {
  const {headers} = useContext(AppContext);
  const { t } = useTranslation();

  const [page, setPage] = useState("dashboard")
  const [jobPost, setJobPost] = useState({})
  const [interviews, setInterviews] = useState([])
  const [dates, setDates] = useState([])
  const [nonFixedInterviews, setNonFixedInterviews] = useState([])
  const [applications, setApplications] = useState([])
  const [testResults, setTestResults] = useState([])
  const [showError,setShowError]=useState(false)
let reference=props.match.params.reference
  useEffect(()=>{
      
      
  },[reference])
  useEffect(()=>{
    checkAvailabilityDates()
},[nonFixedInterviews,dates])
const checkAvailabilityDates=()=>{
  let invitedCandidates=nonFixedInterviews.length
  let availabilityDates=dates.length
  if (invitedCandidates>availabilityDates){
    setShowError(true)
  }
  else {setShowError(false)}
}

  
  const getJobPostByRef=async()=>{
    let res = await GetJobPostByReference({reference},headers)
    if(res){
    let jobPostFromDB=JSON.parse(decrypt(res.data))
    setJobPost(jobPostFromDB)
      setInterviews([])
      setDates([])
      setNonFixedInterviews([])
      setApplications([])
      setTestResults([])
    return jobPostFromDB
    }
  }
  const { data: jobPostFromDB, isLoading, isError } = useQuery(
    ['jobPost','reference', reference], // Query key
    getJobPostByRef
  );
  let jobPostData=jobPostFromDB??{}

  let values={
    jobPost:jobPostData,
    applications:applications,
    interviews:interviews,
    nonFixedInterviews:nonFixedInterviews,
    dates:dates,
    testResults:testResults,
    setJobPost:setJobPost,
    setInterviews:setInterviews,
    setApplications:setApplications,
    setTestResults:setTestResults,
    setDates:setDates,
    setNonFixedInterviews:setNonFixedInterviews,
  }
  return (
    <div>
            <JobPostContext.Provider value={values}>
      <h3 className=' text-center titrePage  text-capitalize'> {jobPostData.title}  </h3>
      <JobPostNavbar page={page} setPage={setPage} />
{showError&&<div className='h4 text-white bg-danger rounded  p-1 d-flex justify-content-center' >
  {t("Veuillez ajouter des dates de disponibilités pour que les candidats peuvent fixer les dates d'entretiens!!")}
</div>}
{isLoading?<Loading/>:   <>
      {page == "dashboard" && <>
        <CandidatesList setPage={setPage} userId={props.userId} />

      </>}
      {page == "interviews" && <>
        <Interviews setPage={setPage} userId={props.userId} />

      </>}
      {page == "tests" && <>
        <TestResults userId={props.userId} jobPostid={jobPost._id} />
      </>}
      {page == "matching" && <>
        <MatchingView userId={props.userId} />

      </>}
      {page == "applications" && <>
        <JobApplications headers={headers} setPage={setPage} userId={props.userId} />

      </>}</>}
      </JobPostContext.Provider>
    </div>
  )
}
export default withRouter(JobPostDashboard);
