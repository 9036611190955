import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.min.css";
import "./App.scss";
import {  useTranslation } from "react-i18next";
import UserPenIcon from "../icons/UserPenIcon";
import PenIcon from "../icons/PenIcon";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../routes/AppContext";
import { ObjectID } from "bson";
import EducationComponent from "./EducationComponent";
import ExperienceComponent from "./ExperienceComponent";
import LanguageComponent from "./LanguageComponent";
import SoftSkillComponent from "./SoftSkillComponent";
import HardSkillComponent from "./HardSkillComponent";
import PlusIcon from "../icons/PlusIcon";
import EducationForm from "../cv/education/EducationForm";
import ExperienceForm from "../cv/experience/ExperienceForm";
import { AddEducation } from "../../services/api/cv/education/AddEducation";
import { AddExperience } from "../../services/api/cv/experience/AddExperience";
import { AddHardSkill } from "../../services/api/cv/hardSkills/AddHardSkill";
import { AddSoftSkill } from "../../services/api/cv/softSkills/AddSoftSkill";
import { AddLanguage } from "../../services/api/cv/languages/AddLanguage";
import HardSkillForm from "../cv/hardSkill/HardSkillForm";
import { UpdateExperience } from "../../services/api/cv/experience/UpdateExperience";
import { UpdateEducation } from "../../services/api/cv/education/UpdateEducation";
import { AddRequestedSkill } from "../../services/api/cv/requestedSkill/AddRequestedSkill";
import SoftSkillForm from "../cv/softSkill/SoftSkillForm";
import LanguageForm from "../cv/language/LanguageForm";
import UploadIcon from "../icons/UploadIcon";
import InfoIcon from "../icons/InfoIcon";
import { Modal } from "antd";
import AsyncImage from "../AsyncImage";
import gif from "../../media/image/attentionPlease.gif"
import Cv from "../cvPDF/Cv";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FileIcon from "../icons/FileIcon";
import CvComponent from "../cvPDF/CvComponent";

import CertificateComponent from "./CertificateComponent";
import CertificateForm from "../cv/certificate/CertificateForm";
import { AddCertificate } from "../../services/api/cv/certification/AddCertification";
import { UpdateCertificate } from "../../services/api/cv/certification/UpdateCertification";
import CVFileIcon from "../icons/CVFileIcon";
import { AddProject } from "../../services/api/cv/project/AddProject";
import { UpdateProject } from "../../services/api/cv/project/UpdateProject";
import ProjectComponent from "./ProjectComponent";
import ProjectForm from "../cv/project/ProjectForm";
import Content from "../cvPDF/template/Content";
import HardSkillComponents from "./templateEdit/HardSkillComponents";
import SoftSkillComponents from "./templateEdit/SoftSkillComponents";
import LanguageComponents from "./templateEdit/LanguageComponents";
import { Timeline} from 'antd';
import SchoolIcon from '@material-ui/icons/School';
import CardMembershipIcon from '@material-ui/icons/CardMembership';

import EducationComponents from "./templateEdit/EducationComponents";
import CertificateComponents from "./templateEdit/CertificateComponents";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ExperienceComponents from "./templateEdit/ExperienceComponents";
import ProjectComponents from "./templateEdit/ProjectComponents";
import TemplateTwo from "../cvPDF/templateTwo/TemplateTwo";
function getDate(dateS, dateE) {
  let months = [
    "Janvier ",
    "Février ",
    "Mars ",
    "Avril ",
    "May ",
    "Juin ",
    "Juillet ",
    "Aout ",
    "Septembre ",
    "Octobre ",
    "Novembre ",
    "Décembre ",
  ];
  let date1 = new Date(dateS);
  let date2 = new Date(dateE);
  let DateE = dateE
    ? months[date2.getMonth()] + date2.getFullYear()
    : "Présent";
  let dateText = months[date1.getMonth()] + date1.getFullYear() + " - " + DateE;
  return dateText;
}
function getAge(date) {
  let diff = Date.now() - new Date(date).getTime();
  let age = new Date(diff);
  return Math.abs(age.getUTCFullYear() - 1970);
}


function ProfilCv() {
  var { t } = useTranslation();
  const history = useHistory();
  const { user,headers,envVar } = useContext(AppContext);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [educationModalVisible, setEducationModalVisible] = useState(false);
  const [certificateModalVisible, setCertificateModalVisible] = useState(false);
  const [experienceModalVisible, setExperienceModalVisible] = useState(false);
  const [projectModalVisible, setProjectModalVisible] = useState(false);

  const [hardSkillModalVisible, setHardSkillModalVisible] = useState(false);
  const [softSkillModalVisible, setSoftSkillModalVisible] = useState(false);
  const [languageModalVisible, setLanguageModalVisible] = useState(false);
  const [view, setView] = useState(false);
  const [index, setIndex] = useState(null);
  const [id, setId] = useState(new ObjectID().toString());
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);


  //template 1 apercu CV
  const [to1, setTo1] = useState("#178ae7");
  const [to2, setTo2] = useState("#006cc9");

  const [tt1, setTt1] = useState("#178ae7")
  const [tt2, setTt2] = useState("#5464e4")
  const [tt3, setTt3] = useState("#b1b1b1")
  const [tt4, setTt4] = useState("#7e2955")


  function getDate(dateS, dateE ) {
    // console.log(dateS, dateE )
    let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]

  
    let date1 = new Date(dateS);
    let date2 = new Date(dateE);
    let DateE = dateE
      ? months[date2.getMonth()] +" "+ date2.getFullYear()
      : t("Présent");
    let dateText = months[date1.getMonth()] +" "+ date1.getFullYear() + " - " + DateE;
    return dateText;
  }
  function getAge(date) {
    let diff = Date.now() - new Date(date).getTime();
    let age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }



const info = () => {
  Modal.info({
    title: null,
    content: (
      <div>
        <p> {t("Toutes les données de votre profil et les informations du CV que vous avez indiquées sont prises en considération lors du calcul du score de correspondance,pour vous trouver les meilleures ✨ offres ✨ qui vous conviennent.")}
        </p>
      </div>
    ),

    onOk() { },
  });
};
//certificate
const addCertificate = async (certificate) => {
  if (index == null) {
    //add
    // let certificate = user.certificates[user.certificates.length];
    let res = await AddCertificate(
      {
        _id:id,
    certificateName: certificate.certificateName,
    reference: certificate.reference,
    emissionDate: certificate.emissionDate,
    expirationDate: certificate.expirationDate
    },headers);
    setId(new ObjectID().toString())
  } else {
    // let certificate = user.certificates[index];
    //update
    UpdateCertificate(
      {
        id:certificate._id,
    certificateName: certificate.certificateName,
    reference: certificate.reference,
    emissionDate: certificate.emissionDate,
    expirationDate: certificate.expirationDate
    },headers)
    setIndex(null);
  }
};
//education
  const addEducation = async (education) => {
    if (index == null) {
      //add
      // let education = user.educations[user.educations.length];
      let res = await AddEducation(
        {
          _id:id,
      diploma: education.diploma,
      school: education.school,
      startDate: education.startDate,
      endDate: education.endDate
      },headers);
      setId(new ObjectID().toString())
    } else {
      // let education = user.educations[index];
      //update
      UpdateEducation(
        {
          id:education._id,
      diploma: education.diploma,
      school: education.school,
      startDate: education.startDate,
      endDate: education.endDate
      },headers)
      setIndex(null);
    }
  };
  const addExperience = async (experience) => {
    if (index == null) {
      //add
      let res = await AddExperience(
        {_id:id,
          idExperienceDetail:experience.idExperienceDetail[0]._id,
        companyName:experience.companyName,
        description:experience.description,
        title:experience.title,
        startDate: experience.startDate,
        endDate: experience.endDate},headers);
      setId(new ObjectID().toString())
      
    } else {
      let experience = user.experiences[index];

      //update
      UpdateExperience({id: experience._id,
        idExperienceDetail:experience.idExperienceDetail[0]._id,
        companyName:experience.companyName,
        description:experience.description,
        title:experience.title,
        startDate: experience.startDate,
        endDate: experience.endDate},headers)
      setIndex(null);
    }
  };

  const addProject = async (project) => {
    if (index == null) {
      //add
      let res = await AddProject(
        {_id:id,
        url:project.url,
        description:project.description,
        title:project.title,
        startDate: project.startDate,
        endDate: project.endDate},headers);
      setId(new ObjectID().toString())
      
    } else {
      let project = user.projects[index];

      //update
      UpdateProject({id: project._id,
        url:project.url,
        description:project.description,
        title:project.title,
        startDate: project.startDate,
        endDate: project.endDate},headers)
      setIndex(null);
    }
  };

  const addHardSkill = async (hardSkill) => {
    //send add skill request with id
    //update context and view
    let res
    if(hardSkill.code){
       res = await AddHardSkill({   
         _id:id,
        idHardSkill: hardSkill.idHardSkill,
        score: hardSkill.score,},headers);
    }
    else{
       res = await AddRequestedSkill({   
        _id:id,
       skill: hardSkill.skill,
        feature:hardSkill.feature
      },headers);
    }
    setId(new ObjectID().toString())
    return res
  };
  const addSoftSkill = async (softSkill) => {
    let res
 if(softSkill.code){
    res = await AddSoftSkill({    _id:id,
      idSoftSkill: softSkill.idSoftSkill,
      score: softSkill.score,},headers);}
    else{
       res = await AddRequestedSkill({   
        _id:id,
        soft_skill_fr: softSkill.soft_skill_fr,
        feature:softSkill.feature
      },headers);
    }

    setId(new ObjectID().toString())
    return res
  };
  const addLanguage = async (language) => {
    let res
if(language.code) { 
  
   res = await AddLanguage({    _id: id,
      idLanguage: language.idLanguage,
      score: language.score,},headers);}
    else{
       res = await AddRequestedSkill({   
        _id:id,
        language_fr: language.language_fr,
        feature:language.feature
      },headers);
    }

    setId(new ObjectID().toString())
    return res
  };
  const updateView = async () => {
    setView(!view);
  };
  let age = getAge(user.birthday);

  document.body.classList.remove("dark");

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content-2")) {
      setToggleMenu(false);
    }
  });
  return (
    <div className="ProfilCv  ">
      <div className="d-flex justify-content-between" >
           <button
              onClick={() =>history.push('/candidate/cv/upload')}
              className="btn btn-primary  rounded-pill m-3"
            >
              <i className=" m-2">
                <UploadIcon fill="white" width="1.5rem" />
              </i>
            {  t('Upload CV')}
            </button>
            <span onClick={info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>

      </div>
      <section id="about" className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="img-thumb wow fadeInLeft" data-wow-delay="0.3s">
                {user.image ? (
                  <img
                    className="img-fluid"
                    
                    src={envVar.REACT_APP_CLIENT_URL +
                      "/candidate/images/"
                      + user.image}
                    alt="UserImage"
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={`images/`+ "User_icon.svg"}
                    alt="UserImage"
                  />
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div
                className="profile-wrapper wow fadeInRight"
                data-wow-delay="0.3s"
              >
                  <i
                    role="button"
                    onClick={() => setModalVisible2(true)}
                    className=" text-primary  m-1 zoom1 icons12 right"
                  >
                    <CVFileIcon width="3rem" fill="blue" />
                  </i>
                  
                <h3> {user.name + " " + user.lastName} </h3>
                <Link to="/candidate/profile">
                  {" "}
                  <span
                    alt="Profil"
                    role="button"
                    className="right rounded-circle p-1 zoom1 "
                  >
                    <PenIcon width="1.3rem" fill="blue" />
                  </span>
                </Link>
                <h5 style={{ color: "#0d6efd" }}>
                  {" "}
                  <i>{user.desiredJobPost}</i>
                </h5>
                <div className="about-profile">
                  <ul className="admin-profile">
                    <li>
                      <span className="pro-title">       {  t('Adresse')} </span>{" "}
                      <span className="pro-detail">{user.address} </span>
                    </li>

                    <li>
                      <span className="pro-title"> {  t('E-mail')}  </span>{" "}
                      <span className="pro-detail"> {user.email} </span>
                    </li>
                    <li>
                      <span className="pro-title"> {  t('Téléphone')}  </span>{" "}
                      <span className="pro-detail">+{user.phone} </span>
                    </li>
                    <li>
                      <span className="pro-title">
                        {" "}{  t('Type de contrat souhaité')} 
                       {" "}
                      </span>{" "}
                      <span className="pro-detail">{user.contractType} </span>
                    </li>
                    <li>
                      <span className="pro-title">
                        {" "}
                        {  t('Rémunération souhaitée')} {" "}
                      </span>{" "}
                      <span className="pro-detail">
                        {user.desiredSalary != "" &&
                        user.desiredSalary != null ? (
                          <>{user.desiredSalary + " DTN"}</>
                        ) : (
                          <>{" -"}</>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="cv-body">
        <div className="cv-b-l">
          {/* compétence  */}
           
          <div className="cv-body-competence" style={{marginTop: "13px"}} >
            <div className="cv-r-top">
            <div className="cv-b-comp">{t('Compétences techniques')}
                  <span
                      onClick={() => setHardSkillModalVisible(true)}
                  role="button" className="right rounded-circle">
                    <PlusIcon width="1.1rem" fill="blue" style={{marginLeft: "10px"}} />
                  </span>
            
            </div>                
            </div>
            <div className="tech-comp">
              {/* <div className="tech-l" key={i}>{hardSkill.skill}</div> */}
              {user.hardSkills.map((hardSkill, key) => (

                    <HardSkillComponents
                      hardSkill={hardSkill}
                      key={key}
                      index={key}
                      updateView={updateView}
                    />
                  ))}
            </div>

          </div>

          <div className="cv-body-competence" style={{marginTop: "20px"}}>
            <div className="cv-r-top">
              <div className="cv-b-comp">{t('Compétences interpersonnelles')}
                  <span
                      onClick={() => setSoftSkillModalVisible(true)}
                  role="button" className="right rounded-circle   ">
                    <PlusIcon width="1.1rem" fill="blue" style={{marginLeft: "10px"}} />
                  </span>
              </div>                
            </div>
            <div className="tech-comp">
              {user.softSkills.map((softSkill, key) => (
                    <SoftSkillComponents
                      softSkill={softSkill}
                      key={key}
                      index={key}
                      updateView={updateView}
                    />
                  ))}
            </div>
          </div>

          <div className="cv-body-competence" style={{marginTop: "20px"}}>
            <div className="cv-r-top">
              <div className="cv-b-comp">{t('Langues')}
                  <span
                      onClick={() => setLanguageModalVisible(true)}
                  
                  role="button" className=" rounded-circle   ">
                    <PlusIcon width="1.1rem" fill="blue" style={{marginLeft: "10px"}}/>
                  </span>
              </div>                
            </div>

            <div className="cv-lang">
              {user.languages.map((language, key) => (
                    <LanguageComponents
                      language={language}
                      key={key}
                      index={key}
                      updateView={updateView}
                    />
                  ))}         
            </div>
          </div>

          {/* Formations académiques */}
          <div className="cv-cert" style={{marginTop: "13px"}}>
            <div className="cert-t">

              <div className="cert-top">
                <div className="cert-icon">
                  <div className="cert-icons">
                    <SchoolIcon className='cert-icon-cont'  />                
                  </div>
                </div>
                <div className="cert-name">
                {t('Formations académiques')}
                        <span
                          role="button"
                          onClick={() => setEducationModalVisible(true)}
                          className="right rounded-circle  zoom1 "
                        >
                          <PlusIcon width="1.1rem" fill="white" style={{marginLeft: "10px"}} />
                        </span>

                </div>
              </div>
            </div>

            <Timeline style={{marginLeft:"25px"}}>
            {user.educations.map((education, key) => (
                        <EducationComponents
                          setIndex={setIndex}
                          setModalVisible={setEducationModalVisible}
                          education={education}
                          key={key}
                          index={key}
                          getDate={getDate}
                          updateView={updateView}
                        />
                      ))}
              
              
            </Timeline>
          </div>
            {/* Certificats */}
          <div className="cv-cert" style={{marginTop: "13px"}}>
                <div className="cert-t">

                    <div className="cert-top">
                        <div className="cert-icon">
                            <div className="cert-icons">
                                <CardMembershipIcon  className='cert-icon-cont'  />
                            </div>
                        </div>
                        <div className="cert-name">
                        {t('Certificats')}
                            <span
                              role="button"
                              onClick={() => setCertificateModalVisible(true)}
                              className="right rounded-circle  zoom1 "
                            >
                              <PlusIcon width="1.1rem" fill="white" style={{marginLeft: "10px"}}/>
                            </span>
                        </div>
                    </div>
                </div>

            <Timeline style={{marginLeft:"25px"}}>
              {user.certificates.map((certificate, key) => (
                      <CertificateComponents
                        setIndex={setIndex}
                        setModalVisible={setCertificateModalVisible}
                        certificate={certificate}
                        key={key}
                        index={key}
                        getDate={getDate}
                        updateView={updateView}
                      />
                    ))}
            </Timeline>
          </div>


        </div>

        <div className="cv-b-m"></div>

        <div className="cv-b-r">

          {/* Expériences professionnelles */}
          <div className="cv-cert" style={{marginTop: "13px"}}>
                  <div className="cert-t">

                      <div className="cert-top">
                          <div className="cert-icon">
                          <div className="cert-icons">
                              <LocationCityIcon   className='cert-icon-cont'  />
                          </div>
                          </div>
                          <div className="cert-name">
                          {t('Expériences professionnelles')}
                          <span
                            role="button"
                            onClick={() => setExperienceModalVisible(true)}
                            className="right rounded-circle  zoom1 "
                          >
                            <PlusIcon width="1.1rem" fill="white" style={{marginLeft: "10px"}} />
                          </span>
                          </div>
                      </div>
                  </div>

                  <Timeline style={{marginLeft:"25px"}}>
                  {user.experiences.map((experience, key) => (
                    <ExperienceComponents
                      setIndex={setIndex}
                      setModalVisible={setExperienceModalVisible}
                      experience={experience}
                      key={key}
                      index={key}
                      getDate={getDate}
                      updateView={updateView}
                    />
                  ))}          
                  </Timeline>
          </div>


          <div className="cv-cert" style={{marginTop: "13px"}}>
                <div className="cert-t">
        
                <div className="cert-top">
                    <div className="cert-icon">
                    <div className="cert-icons">
                        <AccountTreeIcon   className='cert-icon-cont'  />
                    </div>
                    </div>
                    <div className="cert-name">
                    {t('Projets académiques')}
                    <span
                      role="button"
                      onClick={() => setProjectModalVisible(true)}
                      className="right rounded-circle  zoom1 "
                    >
                      <PlusIcon width="1.1rem" fill="white" style={{marginLeft: "10px"}} />
                    </span>
                    </div>
                </div>
                </div>
        
                <Timeline style={{marginLeft:"25px"}}>
                {user.projects.map((project, key) => (
                    <ProjectComponents
                      setIndex={setIndex}
                      setModalVisible={setProjectModalVisible}
                      project={project}
                      key={key}
                      index={key}
                      getDate={getDate}
                      updateView={updateView}
                    />
                  ))}
                </Timeline>
          </div>

        </div>
    
      </div>


      {/* <section className="d-flex">
        <div className="container">
          <div className="row">
            <div
              id="services"
              className="services section-padding col-lg-12 col-md-12 col-sm-12 "
            >
              <div className="d-flex flex-row justify-content-center">
                <h2 className="section-title wow flipInX" data-wow-delay="0.4s">
                {  t('Mes compétence techniques')} 
                </h2>
                  {" "}
                  <span
                      onClick={() => setHardSkillModalVisible(true)}
                  role="button" className="right rounded-circle   ">
                    <PlusIcon width="1.3rem" fill="blue" />
                  </span>
              </div>

              <div className="container">
                <div className="row d-flex justify-content-center ">
                  {user.hardSkills.map((hardSkill, key) => (
                    <HardSkillComponent
                      hardSkill={hardSkill}
                      key={key}
                      index={key}
                      updateView={updateView}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div
              id="services"
              className="services2 section-padding  col-lg-12 col-md-12 col-sm-12 "
            >
              <div className="d-flex flex-row justify-content-center">
                <h2 className="section-title wow flipInX" data-wow-delay="0.4s">
                {  t('Mes compétences relationnelles')} 
                </h2>
                  {" "}
                  <span
                      onClick={() => setSoftSkillModalVisible(true)}
                  role="button" className="right rounded-circle   ">
                    <PlusIcon width="1.3rem" fill="blue" />
                  </span>
              </div>

              <div className="container">
                <div className="row d-flex justify-content-center">
                  {user.softSkills.map((softSkill, key) => (
                    <SoftSkillComponent
                      softSkill={softSkill}
                      key={key}
                      index={key}
                      updateView={updateView}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div
              id="services"
              className="services section-padding  col-lg-12 col-md-12 col-sm-12  "
            >
              <div className="d-flex justify-content-center">
                <h2 className="section-title wow flipInX" data-wow-delay="0.4s">
                {  t('Langues')} 
                </h2>
                  {" "}
                  <span
                      onClick={() => setLanguageModalVisible(true)}
                  
                  role="button" className=" rounded-circle   ">
                    <PlusIcon width="1.3rem" fill="blue" />
                  </span>
              </div>
              <br />
              <div className="container">
                <div className="row d-flex justify-content-center">
                  {user.languages.map((language, key) => (
                    <LanguageComponent
                      language={language}
                      key={key}
                      index={key}
                      updateView={updateView}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="resume" className="section-padding">
        <div className="container">
          <div className="row m-1">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="education wow fadeInRight" data-wow-delay="0.3s">
                <ul className="timeline">
                  <li>
                    <i className="mdi mdi-school"></i>
                    <span
                      role="button"
                      onClick={() => setEducationModalVisible(true)}
                      className="right rounded-circle  zoom1 "
                    >
                      <PlusIcon width="1.3rem" fill="blue" />
                    </span>
                    <h2 className="timeline-title"> {  t('Formations')}</h2>
                  </li>
                  {user.educations.map((education, key) => (
                    <EducationComponent
                      setIndex={setIndex}
                      setModalVisible={setEducationModalVisible}
                      education={education}
                      key={key}
                      index={key}
                      getDate={getDate}
                      updateView={updateView}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="experience wow fadeInRight" data-wow-delay="0.6s">
                <ul className="timeline">
                  <li>
                    <i className="mdi mdi-tie"></i>
                    <span
                      role="button"
                      onClick={() => setExperienceModalVisible(true)}
                      className="right rounded-circle  zoom1 "
                    >
                      <PlusIcon width="1.3rem" fill="blue" />
                    </span>
                    <h2 className="timeline-title"> {  t('Experiences')} </h2>{" "}
                  </li>
                  {user.experiences.map((experience, key) => (
                    <ExperienceComponent
                      setIndex={setIndex}
                      setModalVisible={setExperienceModalVisible}
                      experience={experience}
                      key={key}
                      index={key}
                      getDate={getDate}
                      updateView={updateView}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="education wow fadeInRight" data-wow-delay="0.3s">
                <ul className="timeline">
                  <li>
                    <i className="mdi mdi-certificate"></i>
                    <span
                      role="button"
                      onClick={() => setCertificateModalVisible(true)}
                      className="right rounded-circle  zoom1 "
                    >
                      <PlusIcon width="1.3rem" fill="blue" />
                    </span>
                    <h2 className="timeline-title"> {  t('Certificats')}</h2>
                  </li>
                  {user.certificates.map((certificate, key) => (
                    <CertificateComponent
                      setIndex={setIndex}
                      setModalVisible={setCertificateModalVisible}
                      certificate={certificate}
                      key={key}
                      index={key}
                      getDate={getDate}
                      updateView={updateView}
                    />
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="experience wow fadeInRight" data-wow-delay="0.6s">
                <ul className="timeline">
                  <li>
                    <i className="mdi mdi-lan"></i>
                    <span
                      role="button"
                      onClick={() => setProjectModalVisible(true)}
                      className="right rounded-circle  zoom1 "
                    >
                      <PlusIcon width="1.3rem" fill="blue" />
                    </span>
                    <h2 className="timeline-title"> {  t('Projets')} </h2>{" "}
                  </li>
                  {user.projects.map((project, key) => (
                    <ProjectComponent
                      setIndex={setIndex}
                      setModalVisible={setProjectModalVisible}
                      project={project}
                      key={key}
                      index={key}
                      getDate={getDate}
                      updateView={updateView}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {hardSkillModalVisible && (
      <HardSkillForm
        hardSkills={user.hardSkills}
        setHardSkill={addHardSkill}
        userId={user.id}
        modalVisible={hardSkillModalVisible}
        setModalVisible={setHardSkillModalVisible}
        id={id}
      />)}

{softSkillModalVisible && (
      <SoftSkillForm
        softSkills={user.softSkills}
        setSoftSkill={addSoftSkill}
        userId={user.id}
        modalVisible={softSkillModalVisible}
        setModalVisible={setSoftSkillModalVisible}
        id={id}
      />)}

      {languageModalVisible && (
      <LanguageForm
        languages={user.languages}
        setLanguage={addLanguage}
        userId={user.id}
        modalVisible={languageModalVisible}
        setModalVisible={setLanguageModalVisible}
        id={id}
      />)}

      {educationModalVisible && (
        <EducationForm
          education={user.educations[index]}
          index={index}
          modalVisible={educationModalVisible}
          setModalVisible={setEducationModalVisible}
          updateView={addEducation}
          setIndex={setIndex}
          id={id}
        />
      )}
        {certificateModalVisible && (
        <CertificateForm
          certificate={user.certificates[index]}
          index={index}
          modalVisible={certificateModalVisible}
          setModalVisible={setCertificateModalVisible}
          updateView={addCertificate}
          setIndex={setIndex}
          id={id}
        />
      )}
      {experienceModalVisible && (
        <ExperienceForm
          experience={user.experiences[index]}
          index={index}
          modalVisible={experienceModalVisible}
          setModalVisible={setExperienceModalVisible}
          updateView={addExperience}
          setIndex={setIndex}
          id={id}
        />
      )}
          {projectModalVisible && (
        <ProjectForm
          project={user.projects[index]}
          index={index}
          modalVisible={projectModalVisible}
          setModalVisible={setProjectModalVisible}
          updateView={addProject}
          setIndex={setIndex}
          id={id}
        />
      )}
      <div className="text-center" >
           <button
              onClick={() =>{
                Modal.confirm({
                  title: null,
                  okText: t("Continuer"),
                  okType: "primary",
                  cancelText: t("Annuler"),
                  content: (
                    <div>
                          <AsyncImage 
                           className="w-100"
                             src={gif} />
                      <p>  {  t("Veuillez lire attentivement votre CV et vérifier les champs qui contiennent un astérisque ⭐, Car ces détails * poste souhaité, compétences techniques, compétences relationnelles, langue * sont étroitement liés à la correspondance des opportunités d'emploi proposées dans la plateforme.")}
                      </p>
                    </div>
                  ),
              
                  onOk() {
                    history.push('/candidate/jobPosts')
                  },
                });
       
               }}
              className="btn btn-primary  rounded-pill m-3"
            >
        
        {  t('Consulter nos offres')}
            </button>
    </div>
    {/* <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b> {  t('Aperçu du CV')}</b>
                      </h5>
                    }
                    className="m-5 w-75"
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    visible={modalVisible1}
                    onOk={() => setModalVisible1(false)}
                    onCancel={() => setModalVisible1(false)}
                    footer={<></>}
                  >
                     <CvComponent decryptedData={true} cv={user} candidate={user} /> 
                    {modalVisible1 && (
                    <Content decryptedData={true} cv={user} candidate={user}  />
                    )}
                  </Modal> */}


                  <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{  t('Aperçu du CV')}</b>
                      </h5>
                    }
                    // className="m-5 w-75"
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    width={"95%"}
                    visible={modalVisible2}
                    onOk={() => {setModalVisible2(false);setTo1("#178ae7");setTo2("#006cc9");setTt1("#178ae7");setTt2("#5464e4");setTt3("#b1b1b1");setTt4("#7e2955");}}
                    onCancel={() => {setModalVisible2(false);setTo1("#178ae7");setTo2("#006cc9");setTt1("#178ae7");setTt2("#5464e4");setTt3("#b1b1b1");setTt4("#7e2955");}}
                    footer={<></>}
                  >
                    {/* <CvComponent decryptedData={true} cv={user} candidate={user} /> */}
                    {/* <Content decryptedData={true} cv={user} candidate={user}  /> */}
                    {modalVisible2 && (
                    <TemplateTwo decryptedData={true} cv={user} candidate={user}
                    hardSkillModalVisible={hardSkillModalVisible} setHardSkillModalVisible={setHardSkillModalVisible} 
                    to1={to1} setTo1={setTo1} to2={to2} setTo2={setTo2} 
                    tt1={tt1} setTt1={setTt1} tt2={tt2} setTt2={setTt2} tt3={tt3} setTt3={setTt3} tt4={tt4} setTt4={setTt4}

                    />
                    )}
                  </Modal>
    </div>
  );
}

export default ProfilCv;
