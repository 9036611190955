import jwt from "jsonwebtoken";
import { getEnvVars } from "./envVars";

const generateToken = (data,timestampSeconds) => {
  let envVars=getEnvVars()
  data.exp=timestampSeconds
  return jwt.sign( data , envVars.REACT_APP_JWT_SECRET);
};

export default generateToken;
