import React, { useContext, useState, useEffect } from "react";
import { Modal, notification, Progress, Table, Tag } from "antd";
import decrypt from "../../utils/decrypt";
import { reverseMatching } from "../../services/api/matching/reverseMatching";
import CandidateComponent from "./CandidateComponent";
import InfoIcon from "../icons/InfoIcon";
import gif from "../../media/image/500.gif";
import AsyncImage from "../AsyncImage";
import Cv from "../cvPDF/Cv";
import cv from "../../media/image/cv.svg";
import email from "../../media/image/email.svg";
import { AppContext } from "../../routes/AppContext";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { RecommendCandidate } from "../../services/api/recommendCandidate/RecommendCandidate";
import gif1 from "../../media/image/profil.gif";
import gearing from "../../media/image/gearing.gif";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;

const CandidatesList = (props) => {
  const context = useContext(AppContext);
  const { t,i18n } = useTranslation();

  const [candidates, setCandidates] = useState([]);
  const [load, setLoad] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [candidate, setCandidate] = useState(null);

  const languages = { "fr": "language_fr", "en": "language_en" }
  const softSkills = { "fr": "soft_skill_fr", "en": "soft_skill_en" }
  const language = languages[i18n.language]
  const softSkill = softSkills[i18n.language]

  const Contact = async (candidate) => {
    Modal.confirm({
      title: t("Envoyer"),
      icon: <ExclamationCircleOutlined />,
      content:
        t("Après confirmation, un courriel de recommandation sera envoyé à") +" "+
        candidate.name +" "+
        t("pour consulter votre offre et postuler."),
      okText: t("Envoyer"),
      cancelText: t("Annuler"),
      onOk: async () => {
        await RecommendCandidate(
          {
            email: candidate.email,
            companyName: context.companyName,
            jobPostTitle: props.jobPost.title,
            idJobPost: props.jobPost._id,
            idCandidate: candidate._id,
          },
          context.headers
        );
        context.socket.emit("sendNotification", {
          receiverId: candidate._id,
          type: 8,
        });
        notification.success({
          description: t("envoyé avec succée"),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#d4edda",
            marginTop: "5rem",
          },
        });
      },
    });
  };

  const info = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p>
            {t("Pour avoir plus de chances à raccrocher votre emploi idéal.")}
            {t("Veuillez sélectionner les compétences qui vous correspondent le mieux et que vous excellez le plus.")}
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  useEffect(() => {
    const qte = context.myFunctionalities.find(
      (functionality) => functionality.fType === 3
    )?.qte;

    if (qte) {
      match(qte);
    } else {
      Modal.error({
        title: null,
        content: (
          <>
            <div className="d-flex justify-content-center">
              <AsyncImage className="w-100" src={gif1} />
            </div>
            <h6 className="text-dark rounded   ">
              {t("Vous devez acheter un forfait pour bénéficier de nos services.")}
            </h6>
          </>
        ),
        onOk: () => {
          props.history.push("/" + context.role + "/pricing");
        },
      });
    }
  }, []);

  const match = async (qte) => {
    try {
      setLoad(true);
      const res = await reverseMatching(
        { idJobPost: props.jobPost._id, candidatesNumber: qte },
        context.headers
      );

      if (res) {
        const data = JSON.parse(decrypt(res.data));
        data.sort((a, b) => (a.score < b.score ? 1 : -1));
        setTimeout(() => {
          setLoad(false);
          setCandidates(data);
        }, 2000);
      }
    } catch (error) {
      setLoad(false);
    }
  };

  return (
    <div className="row " id="scroller">
      <div className="col-sm-12 rounded shadow">
        <div className="container candidatList">
          <h6 className="titre-table">
            {t("Liste des Top Profils")}
            <span onClick={info} role="button">
              <InfoIcon className="zoom1" fill="blue" width="1.4rem" />
            </span>
          </h6>

          {load ? (
            <span>
              <div className="mt-5 text-center">
                <AsyncImage
                  src={gearing}
                  alt="email"
                  style={{ width: "15rem", height: "15rem" }}
                />
              </div>
            </span>
          ) : (
            !candidates.length && (
              <>
                <div className="m-3 d-flex justify-content-center">
                  <AsyncImage className="w-50" src={gif} />
                </div>
              </>
            )
          )}

          {candidates.length > 0 && (
            <>
              <div className=" rounded shadow">
              <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
              <Table
                      rowKey={record => {
                        return record._id
                      }}
                      pagination={{ defaultPageSize: 5, showSizeChanger: false}} 
                      expandable={{
                        expandedRowRender: (candidate) => {
                          let skillsTags = candidate.idHardSkills.map((skill) => {
                            return (
                              <Tag className="m-1 " color="magenta">
                                {skill.idHardSkill.skill}
                              </Tag>
                            );
                          });
                          let softSkillsTags = candidate.idSoftSkills.map(
                            (skill) => {
                              return (
                                <Tag className="m-1" color="cyan">
                                  {skill.idSoftSkill[softSkill]}
                                </Tag>
                              );
                            }
                          );
                          let languagesTags = candidate.idLanguages.map((skill) => {
                            return (
                              <Tag className="m-1" color="geekblue">
                                {skill.idLanguage[language]}
                              </Tag>
                            );
                          });
                          return (
                            <p style={{ margin: 0 }}>
                              {" "}
                              {skillsTags}
                              {softSkillsTags}
                              {languagesTags}
                            </p>
                          );
                        },
                        // rowExpandable: record => record.name !== 'Not Expandable',
                      }}
                      dataSource={candidates}
                    >
                      <Column
                        align="left"
                        sorter={(a, b) => a.name.localeCompare(b.name)}
                        title={t("Nom")}
                        dataIndex="name"
                        key="name"
                        render={(text, candidate, index) => {
                          return (
                            <div role="button"
                            onClick={() => {
                              setModalVisible(true);
                              setCandidate(candidate);
                            }} className=" d-flex justify-content-start align-items-center">
                              <AsyncImage
                                className="logo-m rounded me-1"
                                src={
                                  context.envVar.REACT_APP_CLIENT_URL
                                  + "/candidate/images/" + candidate.image
                                }
                              />
                              {candidate.name}
                            </div>
                          );
                        }}
                      />

                      <Column
                        align="center"
                        sorter={(a, b) => a.email.localeCompare(b.email)}
                        // responsive={["lg"]}
                        title={t("Email")}
                        dataIndex="email"
                        key="email"
                        render={(text, candidate, index) => {
                          let email=candidate.email
                          let hiddenEmail = "";
                      for (let i = 0; i < email.length; i++) {
                        if (i > 2 && i< email.indexOf("@") ) {
                          hiddenEmail += "*";
                        } else {
                          hiddenEmail += email[i];
                        }
                      }
                          return <>{hiddenEmail}</>;
                        }}
                      />
                      <Column
                        align="center"
                        sorter={(a, b) => a.phone - b.phone}
                        // responsive={["lg"]}
                        title={t("Téléphone")}
                        dataIndex="phone"
                        key="phone"
                        render={(text, candidate, index) => {
                          let phone=candidate?.phone?.toString()
                      let hiddenPhone = "";
                      for (let i = 0; i < phone.length; i++) {
                        if (i > 4) {
                          hiddenPhone += "*";
                        } else {
                          hiddenPhone += phone[i];
                        }
                      }
                          return <>+{hiddenPhone}</>;
                        }}
                      />
                      <Column
                        align="center"
                        sorter={(a, b) => a.score - b.score}
                        // responsive={["lg"]}
                        title={t("Score")}
                        dataIndex="score"
                        key="score"
                        render={(text, candidate, index) => {
                          return (
                            <>
                              {
                                <Progress
                                  type="circle"
                                  percent={parseInt(candidate.score * 100)}
                                  format={(percent) => `${percent}%`}
                                  width={55}
                                  strokeColor={
                                    parseInt(candidate.score * 100) < 30
                                      ? "orange"
                                      : "green"
                                  }
                                />
                              }
                            </>
                          );
                        }}
                      />

                      <Column
                        align="center"
                        // responsive={["lg"]}
                        title=""
                        dataIndex=""
                        key="Lien"
                        render={(text, candidate, index) => {
                          return (
                            <div>
                              <span
                                role="button"
                                onClick={() => {
                                  setModalVisible(true);
                                  setCandidate(candidate);
                                }}
                              >
                                <AsyncImage
                                  className="logo-m rounded-3 m-1"
                                  alt={"cv"}
                                  src={cv}
                                />
                              </span>
                            </div>
                          );
                        }}
                      />
                      <Column
                        align="center"
                        // responsive={["lg"]}
                        title=""
                        dataIndex=""
                        key="Lien"
                        render={(text, candidate, index) => {
                          return (
                            <div>
                              <span onClick={() => { Contact(candidate) }} role="button">
                                <AsyncImage
                                  className="logo-m rounded-3"
                                  alt={"email"}
                                  src={email}
                                />
                              </span>
                            </div>
                          );
                        }}
                      />
                    </Table>
                    </ConfigProvider>
              </div>
            </>
          )}

          {modalVisible && (
            <Modal
              title={
                <h5 className=" m-1 text-primary">
                  <b>{t("Aperçu du CV")}</b>
                </h5>
              }
              className="mb-2 mt-2"
              width={"95%"}
              centered
              closeIcon={
                <i className="mt-5 ">
                  <CloseOutlined className="m-3" />
                </i>
              }
              visible={modalVisible}
              onOk={() => setModalVisible(false)}
              onCancel={() => setModalVisible(false)}
              footer={<></>}
            >
              {modalVisible && (
                <Cv
                  decryptedData={false}
                  userId={props.userId}
                  candidate={candidate}
                />
              )}
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CandidatesList);
