
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);




export default function DoughnutChart(props) {
  const { t } = useTranslation();

  const data = {
    labels: [t('Entretien'), t('Accepté'), t('Refusé')],
    datasets: [
      {
        label: '# of Votes',
        data: [props.interviews, props.accepted, props.refused],
        backgroundColor: [
          'rgba(42,118,244,255)',
          'rgba(148,186,249,255)',
          'rgba(217,230,253,255)',
  
        ],
        borderColor: [
          'rgba(42,118,244,255)',
          'rgba(148,186,249,255)',
          'rgba(217,230,253,255)',
   
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div><Doughnut data={data} /></div>
  )
}
