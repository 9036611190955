import React, { Fragment, useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { AppContext } from "../../routes/AppContext";
import decrypt from "../../utils/decrypt";

const CheckoutProducts2 = ({}) => {
  const { t,i18n } = useTranslation();

  const history = useHistory();
  const { cart, uCodepromo, uPtsFidelite, setTotal, total } =
    useContext(AppContext);
    const language=i18n.language 
    const modulesLanguage={"fr":"name","en":"name_en"}
    const moduleLanguage=modulesLanguage[language]
  
    const functionalitiesLanguage={"fr":"fName","en":"fName_en"}
    const functionalityLanguage=functionalitiesLanguage[language]

    const packagesLanguage={"fr":"pName","en":"pName_en"}
    const packageLanguage=packagesLanguage[language]
  useEffect(() => {
    let amount = 0;
    cart.map((p) => {
      amount += p.pPrice;
    });
    amount=amount*1.19
    if (uCodepromo) amount = (amount * (100 - uCodepromo.pourcentage)) / 100;
    amount = amount - uPtsFidelite / 100;
    setTotal((amount).toFixed(3));
  }, [cart, uCodepromo, uPtsFidelite]);

  return (
    <Fragment>
      <br />

      <div className="max-w-full  bg-white flex flex-col rounded overflow-hidden shadow-lg pb-2 mt-16">
        <div
          className="flex flex-row items-baseline flex-nowrap p-8"
          style={{ backgroundColor: "#005bea" }}
        >
          <h1
            className="ml-2 uppercase font-bold text-white"
            style={{ color: "#FFe436" }}
          >
            Informations
          </h1>
          <p className="ml-2 font-normal text-white">{t("Forfaits à acheter")}</p>
        </div>

        <div className="mt-4 ">
          {cart !== null && cart.length > 0 ? (
            cart.map((product, index) => {
              return (
                <div className="mt-2 flex md:border-b sm:flex-row mx-6 sm:justify-between flex-wrap ">
                  <div className="flex flex-col ml-2 mt-8">
                    <p className="text-xs text-gray-500 font-bold">
                      {" "}
                      {product[packagesLanguage]}
                    </p>
                  </div>

                  <div className="flex flex-col p-2 mt-6">
                    <p className="font-bold"> {t("Prix")}</p>
                    <p className="text-gray-500">
                      <span className="font-bold">{product.pPrice} </span> {t("TND")}{" "}
                    </p>
                  </div>

                  <div className="flex flex-col flex-wrap p-2 mt-6">
                    <p className="font-bold">{t("Subtotal")}</p>

                  
                      <p className="text-gray-500">
                        <span className="font-bold"> {(product.pPrice*1.19).toFixed(3)}</span> {t("TND")}
                      </p>
                   
                  </div>

                  <div className="flex flex-col p-2 mt-6">
                    <p className="font-bold">{t("TVA")}</p>
            
                      <p className="text-gray-500">
                        <span className="font-bold">+ 19%</span>
                      </p>
                    
                  </div>
                </div>
              );
            })
          ) : (
            <div>{t("Aucun forfait trouvé")}</div>
          )}
        </div>

        <div
          className="mt-2 flex flex-row flex-wrap md:flex-nowrap justify-between items-baseline"
          style={{ backgroundColor: "#005bea" }}
        >
          <div className="flex py-2 flex-row flex-wrap"></div>
          <p className="font-bold text-white ml-64">Total:</p>
          <div className="md:border-l-2 mx-6 md:border-dotted flex flex-row py-4 mr-12 flex-wrap">
            <div className="flex flex-col flex-wrap p-2 mt-4">
              <p className="font-bold text-white">{t("Subtotal")}</p>

              <p className="text-white">
                <span className="font-bold"> {total} </span> {t("TND")}
              </p>
            </div>

            <div className="flex flex-col p-2 mt-4 ml-12">
              <p className="font-bold text-white">{t("Remise")}</p>

              <p className="text-white">
                <span className="font-bold">
                  - {uCodepromo ? uCodepromo.pourcentage : 0}
                </span>{" "}
                %{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutProducts2;
