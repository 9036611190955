import React, { Component } from 'react';
import {Rate, Tag } from 'antd'
import { withTranslation } from 'react-i18next';

class HardSkills extends Component {
  constructor(props){
    super(props)
  }
  render() {
    const { t } = this.props;

    let i=-1
    let list=this?.props?.hardSkills?.map(hardSkill=>{
      i++
      return( <Tag className="m-1 capitalize" color="magenta">
      {hardSkill.skill}
    </Tag>

      )
    })
    return (
    <div className='mt-3' >
<h6><b> {t("Compétences techniques")} </b></h6>
{list}
    </div>)
  }
}
export default withTranslation()(HardSkills)