import { Modal } from 'antd';
import React, { useContext } from 'react'
import { AppContext } from '../../../routes/AppContext';
import { DeleteSoftSkill } from '../../../services/api/cv/softSkills/DeleteSoftSkill';
import XMarkIcon from '../../icons/XMarkIcon';
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { DeleteRequestedSkill } from '../../../services/api/cv/requestedSkill/DeleteRequestedSkill';
import { useTranslation } from "react-i18next";


const SoftSkillComponents = (props) => {
    const {user,setUser,headers} = useContext(AppContext);
    const { t } = useTranslation();
  const deleteSoftSkill = async () => {
    if(user.softSkills.length==1){}
    else
    Modal.confirm({
      title: t("Supprimer formation"),
      icon: <ExclamationCircleOutlined />,
      content:
      t('voulez vous supprimer la compétence')  + props.softSkill.soft_skill_fr,
      okText: "Supprimer",
      okType: "danger",
      cancelText: "Annuler",
      onOk: async() => {
        //send request
    if(props.softSkill.code) {   let res=await  DeleteSoftSkill({id:props.softSkill.id},headers)}
        else{
          let res=await  DeleteRequestedSkill({id:props.softSkill.id},headers)
        }
        //update context
        let cv = user
        cv.softSkills.splice(props.index, 1);
        cv.updated = true;
        setUser(cv)
        props.updateView()
      },
      // onCancel:()=>{alert(index)}
    });
    let res = await DeleteSoftSkill();
  };
  return (
    <div className="tech-int">          
        {props.softSkill.soft_skill_fr}

        <XMarkIcon
          onClick={deleteSoftSkill}
          className="me-2 zoom1"
          width="0.6rem"
          fill="red"
          style={{marginLeft:"5px"}}
        />
        
    </div>
  )
}

export default SoftSkillComponents