import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AddTestService } from "../../../services/api/companyTest/AddTest";
import ChooseSkillsForm from "./ChooseSkillsForm";
import QuestionsList from "./QuestionsList";
import { Button, Input, Modal, Select, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory, withRouter } from "react-router-dom";
import { AppContext } from "../../../routes/AppContext";
import AsyncImage from "../../AsyncImage";
import gif from "../../../media/image/profil.gif";
import TestPreview from "../testPreview/TestPreview";
import FormSteps from "../FormSteps";
import ModalSetting from "../ModalSetting";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
const { Option } = Select;

function CreateTestContainer(props) {
  const { t } = useTranslation();

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [questionsError, setQuestionsError] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [languages, setLanguages] = useState(["fr"]);
  const [globalStopwatch, setGlobalStopwatch] = useState(false);
  const [correctionMethod, setCorrectionMethod] = useState(0);
  const [showResult, setShowResult] = useState(true);
  const [level, setLevel] = useState(0);
  const [validity, setValidity] = useState(15);
  const [current, setCurrent] = useState(0);

  const {
    fonctionnalites,
    role,
    decrementMyFunctionalities,
    myFunctionalities,
    recinovQuestions,
    setRecinovQuestions,
    setSelectedSkills,
    selectedSkills,
    userId,
    testLanguage,
    setTestLanguage,headers
  } = useContext(AppContext);
  const [questions, setQuestions] = useState([]);
  const history = useHistory({});
  const childRef = useRef();
  const queryClient = useQueryClient();
  
    const handleInvalidate = () => {
      // Invalidate the query data
      queryClient.invalidateQueries(['tests']);
    };
  const testValidation = () => {
    let isvalid = true;
    //description validation
    if (description == "") {
      // document.getElementById("description").scrollIntoView();
      // document.getElementById("description").focus();
      isvalid = false;
      setDescriptionError(t("champ obligatoire"));
    }
    //title validation
    if (title == "") {
      // document.getElementById("title").scrollIntoView();
      // document.getElementById("title").focus();
      isvalid = false;
      setTitleError(t("champ obligatoire"));
    }
    return isvalid;
  };
  const validation = () => {
    let isvalid = true;
    //description validation
    if (description == "") {
      document.getElementById("description").scrollIntoView();
      document.getElementById("description").focus();
      isvalid = false;
      setDescriptionError(t("champ obligatoire"));
    }
    //title validation
    if (title == "") {
      document.getElementById("title").scrollIntoView();
      document.getElementById("title").focus();
      isvalid = false;
      setTitleError(t("champ obligatoire"));
    }
    //questions validation
    if (selectedQuestions.length < 2) {
      setQuestionsError(t("minimum 2 questions"));
      isvalid = false;
    } else {
      setQuestionsError("");
    }
    return isvalid;
  };
  const submit = () => {
    let idQuestions = [];
    for (let i = 0; i < selectedQuestions.length; i++) {
      idQuestions.push(recinovQuestions[selectedQuestions[i]]._id);
    }
    AddTestService(
      {
        title: title,
        description: description,
        idQuestions: idQuestions,
        languages: languages,
        globalStopwatch: globalStopwatch,
        correctionMethod: correctionMethod,
        showResult: showResult,
        level: level,
        validity: validity,

        idFunctionality: fonctionnalites.find(
          (functionality) => functionality.fType == 2
        )?._id,
      },
      headers
    );
    decrementMyFunctionalities(2);
    setRecinovQuestions([]);
    setSelectedSkills([]);
    handleInvalidate()
    setTimeout(() => {
      history.push("/" + role + "/manageTests");
    }, 1000);
  };
  const handleSubmitTest = (e) => {
    e.preventDefault();
    if (testValidation()) {
      setCurrent(1);
    }
  };
  const previous = () => {
      setCurrent(current-1);
  };
  const handleSubmitQuestions = (e) => {
    e.preventDefault();

    if (validation()) {
      let idQuestions = [];
      let q = [];

      for (let i = 0; i < selectedQuestions.length; i++) {
        idQuestions.push(recinovQuestions[selectedQuestions[i]]._id);
        q.push(recinovQuestions[selectedQuestions[i]]);
      }

      setSelected(q);
      setCurrent(2);
    }
  };
  const updateQuestions = (questions) => {
    setQuestions(questions);
    childRef.current();
  };
  useEffect(() => {
    let addTestFromDB = myFunctionalities.find((f) => f.fType == 6);
    let addTest = myFunctionalities.find((f) => f.fType == 2);
    if (
      !addTest ||
      addTest.qte <= 0 ||
      !addTestFromDB ||
      addTestFromDB.qte <= 0
    ) {
      Modal.error({
        title: null,
        content: (
          <>
            <div className="d-flex justify-content-center">
              <AsyncImage className="w-100" src={gif} />
            </div>
            <h6 className="  text-dark rounded   ">
              {t('Vous devez acheter un forfait pour bénéficier de nos services.')}
            </h6>
          </>
        ),
        onOk: () => {
          history.push("/" + role + "/pricing");
        },
      });
    } else if(current==1) {
      updateQuestions(recinovQuestions);
    }
  }, [current]);
  const levelOptions = [
    { label: t("Junior"), value: 0 },
    { label: t("Intérmediaire"), value: 1 },
    { label: t("Senior"), value: 2 },
    { label: t("Expert"), value: 3 },
  ];
  return (
    <div>
      <FormSteps current={current} />
      {current == 0 && (
        <div className="Setup-Test p-2">
          <div className="text-center m-1 p-2">
            <h3 className="text-primary  ">
              <b>{t("Paramètres du test")}</b>
            </h3>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {/* <hr className="m-2 ms-4 w-100 " /> */}

            <Tooltip title={t("Paramètres par défaut!")} color={"#6F88FC"} open>
              <ModalSetting
                languages={languages}
                setLanguages={setLanguages}
                stopwatch={globalStopwatch}
                setStopwatch={setGlobalStopwatch}
                correctionMethod={correctionMethod}
                setCorrectionMethod={setCorrectionMethod}
                showResult={showResult}
                setShowResult={setShowResult}
              />
            </Tooltip>
          </div>
          <label>
            {t("Titre de test")} <b style={{ color: "blue" }}>* </b>
          </label>
          <div className="md-form">
            <input
              className="form-control validate mb-2"
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError("");
              }}
            />
          </div>
          <p className="text-danger">
            <small>{titleError}</small>
          </p>

          <label className="col-12 ">
            {" "}
            <span className="">
              {" "}
              {t("Description")} <b style={{ color: "blue" }}>* </b>{" "}
            </span>
            <textarea
              name="description"
              className="border form-control "
              onChange={(e) => {
                setDescription(e.target.value);
                setDescriptionError("");
              }}
              value={description}
              id="description"
              rows="2"
            ></textarea>
          </label>
          <p className="text-danger">
            <small>{descriptionError}</small>
          </p>

          <div className="row mb-2">
           
            <div className="col-lg-6 col-md-12">
              <label>{t("Expérience")} :</label>
              <br />
              <Select
                className="w-100"
                placeholder={t("Veuillez choisir une expérience.")}
                onChange={(value, i) => {
                  setLevel(value);
                }}
                value={level}
              >
                {levelOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              <br />
            </div>
            <div className="col-lg-6 col-md-12">
              <label>
                {t("Expiration")}<small>({t("jours")})</small>:
              </label>
              <br />
              <Input
                className=" validate mb-2"
                type="number"
                id="validity"
                name="validity"
                value={validity}
                onChange={(e) => {
                  setValidity(e.target.value);
                }}
              />
              <br />
            </div>
          </div>
        </div>
      )}

        <div hidden={current !== 1}>
          <ChooseSkillsForm
            loading={loading}
            setLoading={setLoading}
            userId={props.useId}
            setQuestions={updateQuestions}
            setSelectedSkills={setSelectedSkills}
          />
          <br />
          <hr />
          <QuestionsList
            t={t}
            ref={childRef}
            userId={userId}
            loading={loading}
            questions={questions}
            setSelectedQuestions={setSelectedQuestions}
            selectedSkills={selectedSkills}
          />
          <p className="text-danger">
            <small>{questionsError}</small>
          </p>
        </div>
 {current == 0 && (
          <div className="d-flex justify-content-end">
            <button onClick={handleSubmitTest} className="btn btn-primary m-1">
              {t("Suivant")}
            </button>
          </div>
        )}
      {current == 1 && (
          <div className="d-flex justify-content-end">
            <button onClick={previous} className="btn btn-primary m-1">
              {t("Précedent")}
            </button>
            <button onClick={handleSubmitQuestions} className="btn btn-primary m-1">
              {t("Suivant")}
            </button>
          </div>
        )}

      {current == 2 && (
          <div className="p-4">
            <TestPreview
              submit={submit}
              test={{
                questions:selected,
                title,
                description,
                level,
                languages,
                validity,
                globalStopwatch,
                correctionMethod,
                showResult,
              }}
            />
              <Button  className="m-1" type="primary"  onClick={previous}>
            {t("Précedent")}
</Button>
            <br />
         
          </div>
        )}
    </div>
  );
}
export default withRouter(CreateTestContainer);
