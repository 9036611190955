import axios from "../../../interceptors/axios";
export const GetTestDescriptionByLink = async (data, headers) => {
  try {
    let res = await axios.get("/companyTest/description/link/" + data.link, {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
