import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UploadCv from "./UploadCv";
import { withTranslation } from 'react-i18next';
import { AppContext } from "../../../routes/AppContext";
import { Button,  Space } from "antd";
import { LaptopOutlined, UnorderedListOutlined} from '@ant-design/icons';

class CandidateCvUpload extends Component {
  static contextType = AppContext

  state = {
    modal1Visible: false,
  };

  goToCvForm = async () => {
    // if(!sessionStorage.getItem('cv'))
    // await this.GetMyCV()
    this.props.history.push("/candidate/cv");
  };
  setModalVisible = (modal1Visible) => {
    this.setState({ modal1Visible });
  };
  render() {
    return (
      <div className="  ">

<h3>{this.props.t("COMMENT NOUS PARLER DE VOUS")}  !</h3>
         <div className="AddTestView3button">
      <section className="btn">
        <div className="row">
          
          <div  className="col-xl-5 col-lg-6 col-md-12 m-4">
            <div id="btn2">
             
                {" "}
                <Space>
                  <Button className="bouton"   onClick={() => this.setModalVisible(true)}>
                    <div>
                    <LaptopOutlined className="icon" />
                    </div>
                    <div>
                    <span id="text">
                    {this.props.t("Upload CV")} 
                    </span>
                    </div>
                  </Button>
                </Space>
              
            </div>
          </div>
          <div  className="col-xl-5 col-lg-6 col-md-12 m-4">
            <div id="btn2">
           
                <Space>
                  <Button className="bouton" onClick={this.goToCvForm}>
                    <div>
                    <UnorderedListOutlined className="icon" />
                     </div>
                     <div>
                    <span id="text">
                    {this.props.t("Créer mon CV")} 
                   
                    </span>
                    </div>
                  </Button>
                </Space>
             
            </div>
          </div>
        </div>
      </section>
    </div>
      
        <UploadCv
          userId={this.props.userId}
          modalVisible={this.state.modal1Visible}
          setModalVisible={this.setModalVisible}
        />
      </div>
    );
  }
}
export default withTranslation()( withRouter(CandidateCvUpload))
