import React, { useRef, useState, useCallback, useContext } from "react";
import { Upload, message, Modal } from "antd";
import VerifAuth from "./VerifAuth";
import face from "./face.png";
import "./Step.css";
import Webcam from "react-webcam";
import axios from "axios";

import { notification } from "antd";
import CheckIcon from "../icons/CheckIcon";
import AsyncImage from "../AsyncImage";
import TestSteps from "../jobPostTesting/TestSteps";
import InfoIcon from "../icons/InfoIcon";
import img1 from "../../media/image/echange.png";
import img2 from "../../media/image/seul.webp";
import img3 from "../../media/image/wifi.webp";
import { Input } from "reactstrap";
import CameraIcon from "../icons/CameraIcon";

import { AppContext } from "../../routes/AppContext";

function Test(props) {
  const {headers} = useContext(AppContext);

  const [acces, setAcces] = useState("");

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrcCin, setImgSrcCin] = useState(null);
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(0);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  const capture = useCallback(() => {
    const image = webcamRef.current.getScreenshot();
    setImgSrc(image);
  }, [webcamRef, setImgSrc]);

  const cin = useCallback(() => {
    const cin = webcamRef.current.getScreenshot();
    setImgSrcCin(cin);
  }, [webcamRef, setImgSrcCin]);

  const handleSubmit = () => {
    // document.getElementById("verif").style.backgroundColor = "blue";
    async function convert() {
      const base64 = await fetch(imgSrc);
      const blob = await base64.blob();
      const cinBase64 = await fetch(imgSrcCin);
      const cinBlob = await cinBase64.blob();
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");
      formData.append("cin", cinBlob, "cin.jpg");
      const res = await axios
        .post(`/api/face-recognition/compare`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers
          },
        })
        .then((res) => {
          // document.getElementById("valid").style.backgroundColor = "green";
          props.setAuth(true);
          notification.success({
            message: "Notification",
            description: "autorisation d'acces  ",
            className: "custom-class",
            style: {
              width: 400,
            },
          });
        })
        .catch((err) => {
          // document.getElementById("valid").style.backgroundColor = "red";
          notification.error({
            message: "Notification",
            description: "autorisation refusé  ",
            className: "custom-class",
            style: {
              width: 400,
            },
          });
          if (err.response) {
            if (err.response.status === 422) {
            }
          }
        });
    }
    convert();
    // props.setAuth(true)
    // notification.success({
    //   message: "Notification",
    //   description: "autorisation d'acces  ",
    //   className: "custom-class",
    //   style: {
    //     width: 400,
    //   },
    // });

  };
  const info0 = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p className="">
            Info : Vous devez prendre des précautions avant le test (aucun
            mouvement n'est autorisé pendant le test) et vous devez être seul et
            dans une pièce bien éclairée.
          </p>
        </div>
      ),
      onOk() {},
    });
  };
  const info1 = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p className=""> Approcher votre pièce d 'identité comportant votre photo devant cotre webcam de façon a ce qu'elle soit dans le rectangle et nette. 
Quand le résultat est satisfaisant, cliquer sur prendre une photo


</p>
        </div>
      ),
      onOk() {},
    });
  };
  const info2 = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p className="">Quand l'aperçu de la photo est net et bien cadré, cliquer sur prendre une photo
</p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <>
      <TestSteps current={step} style={{ direction: "ltr"}}/>

      {show ? (
        <VerifAuth setAcces={setAcces} image={props.image} />
      ) : (
        <>
          {step == 1 ? (
            <div>
              {/* <div className="md-stepper-horizontal orange">
                <div className="md-step active ">
                  <div className="md-step-circle">
                    <span>1</span>
                  </div>
                  <div className="md-step-title">Authentification</div>
                  <div className="md-step-bar-left"></div>
                  <div className="md-step-bar-right"></div>
                </div>
                <div className="md-step  ">
                  <div className="md-step-circle" id="verif">
                    <span>2</span>
                  </div>
                  <div className="md-step-title">vérification</div>

                  <div className="md-step-bar-left"></div>
                  <div className="md-step-bar-right"></div>
                </div>
                <div className="md-step ">
                  <div className="md-step-circle" id="valid">
                    <span>3</span>
                  </div>
                  <div className="md-step-title">validation</div>
                  <div className="md-step-bar-left"></div>
                  <div className="md-step-bar-right"></div>
                </div>
              </div> */}

              <div className="form row text-center" onSubmit={handleSubmit}>
                {/* <p className=" text-muted ptag font-weight-bold   ">
                  - Nous allons maintenant commencer la vérification préalable
                  au test de compétences. <br />
                  - Prenez vous en photo avec la webcam pour vérifier qu’il
                  s’agit de votre compte.
                  <br />
                </p> */}
                <div className="col-md-6 col-sm-12 p-3 ">
                <div className="border border-primary rounded border-4 " >

                  <div className="">
                    <h5>
                      Photo visage
                      <span onClick={info1} role="button">
                        <InfoIcon
                          className="zoom1 mx-2"
                          fill="blue"
                          width="1.4rem"
                        />{" "}
                      </span>
                    </h5>
                    <div className="">
                      <Webcam
                        ref={webcamRef}
                        style={{
                          width: "15rem",
                          borderRadius: 8,
                          marginRight: "",
                        }}
                        screenshotFormat="image/png"
                        audio={false}
                      />
                    </div>
                    <div className="">
                      {imgSrc === null ? (
                        <div></div>
                      ) : (
                        <AsyncImage
                          src={imgSrc}
                          style={{ width: "15rem", borderRadius: 8 }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="btn-grp">
                    <button className="btn btn-primary m-1" onClick={capture}>
                    <CameraIcon
                          className="zoom1 mx-2"
                          fill="white"
                          width="1.2rem"
                        />{" "}
                      Prendre une photo
                    </button>
                  </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12  p-3">
                  <div className="border border-primary rounded border-4 " >
                  <h5>
                    Carte d'identité
                    <span onClick={info2} role="button">
                      <InfoIcon
                        className="zoom1 mx-2"
                        fill="blue"
                        width="1.4rem"
                      />{" "}
                    </span>
                  </h5>

                  <div className="">
                    <div className="">
                      <Webcam
                        ref={webcamRef}
                        style={{
                          width: "15rem",
                          borderRadius: 8,
                          marginRight: "",
                        }}
                        screenshotFormat="image/png"
                        audio={false}
                      />
                    </div>
                    <div className="">
                      {imgSrcCin === null ? (
                        <div></div>
                      ) : (
                        <AsyncImage
                          src={imgSrcCin}
                          style={{ width: "15rem", borderRadius: 8 }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="btn-grp">
                    <button className="btn btn-primary m-1" onClick={cin}>
                    <CameraIcon
                          className="zoom1 mx-2"
                          fill="white"
                          width="1.2rem"
                        />{" "}
                    Prendre une photo
                    </button>
                  </div>
                  </div>
                </div>

              </div>
              <div className="d-flex justify-content-end">
                {imgSrc == null || imgSrcCin == null ? (
                  <button
                    className="btn btn-primary mt-2"
                    disabled
                  >
                    Valider
                  </button>
                ) : (
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleSubmit}
                  >
                    Valider
                  </button>
                )}
              </div>
            </div>
          ) : (
            <body>
              <div className="container">
                <div>
                  <p className="inline block text-muted ptag   ">
                    <h5 className="">
                      Avant de commencer, s’il vous plait assurez-vous que :{" "}
                      <span onClick={info0} role="button">
                        <InfoIcon
                          className="zoom1 mx-2"
                          fill="blue"
                          width="1.4rem"
                        />{" "}
                      </span>
                    </h5>
                    <div className="text-center row">
                      <div className="col-md-4 col-sm-12">
                        <AsyncImage
                          src={img1}
                          alt="rocket"
                          titre="rocket"
                          height="150"
                          className="mg-fluid"
                        />

                        <div>
                          <Input
                            className="m-1"
                            role="button"
                            type="checkbox"
                            id="checkbox2"
                            onChange={(e) => setChecked1(!checked1)}
                          />
                          Ne pas changer l'onglet de test
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <AsyncImage
                          src={img2}
                          alt="rocket"
                          titre="rocket"
                          height="150"
                          className="mg-fluid"
                        />
                        <div>
                          <Input
                            className="m-1"
                            role="button"
                            type="checkbox"
                            id="checkbox2"
                            onChange={(e) => setChecked2(!checked2)}
                          />
                          Vous êtes seul
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <AsyncImage
                          src={img3}
                          alt="rocket"
                          titre="rocket"
                          height="150"
                          className="mg-fluid"
                        />
                        <div>
                          <Input
                            className="m-1"
                            role="button"
                            type="checkbox"
                            id="checkbox2"
                            onChange={(e) => setChecked3(!checked3)}
                          />
                          Votre internet est stable
                        </div>
                      </div>
                    </div>
                    <br />
                  </p>
                  <div className="d-flex justify-content-end">
                    {checked1 && checked2 && checked3 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        Valider
                      </button>
                    ) : (
                      <button className="btn btn-primary" disabled>
                        Valider
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </body>
          )}
        </>
      )}
    </>
  );
}

export default Test;
