import React from "react";
import AsyncImage from "../AsyncImage";
import img1 from "../../media/image/img1.webp"
import img2 from "../../media/image/img2.webp"
import img3 from "../../media/image/img3.webp"
import img4 from "../../media/image/img4.webp"
import { useTranslation } from "react-i18next";

export default function JobPostNavbar(props) {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row">
        <div onClick={()=>{props.setPage("dashboard")}}  role="button" className=" col-md-3 col-sm-6">
        <div className="bg-white border rounded m-2">
         <div className="d-flex justify-content-center" >
          <AsyncImage
            className="logo-m rounded-3 "
            alt=""
            src={img1}
          />
          </div>
          <b className="d-flex justify-content-center" >
          {t("Candidats")}
          </b></div>
        </div>
        <div onClick={()=>{props.setPage("interviews")}}  role="button" className=" col-md-3 col-sm-6">
        <div className="bg-white border rounded m-2">
         <div className="d-flex justify-content-center" >
          <AsyncImage
            className="logo-m rounded-3 "
            alt=""
            src={img2}
          />
          </div>
          <b className="d-flex justify-content-center" >
          {t("Entretiens visio")}
          </b></div>
        </div>
        <div onClick={()=>{props.setPage("tests")}}  role="button" className=" col-md-3 col-sm-6">
        <div className="bg-white border rounded m-2">
         <div className="d-flex justify-content-center" >
          <AsyncImage
            className="logo-m rounded-3 "
            alt=""
            src={img3}
          />
          </div>
          <b className="d-flex justify-content-center text-center" >
          {t("Entretiens techniques")}
          </b></div>
        </div>
        <div onClick={()=>{props.setPage("matching")}}  role="button" className=" col-md-3 col-sm-6">
        <div className="bg-white border rounded m-2">
         <div className="d-flex justify-content-center" >
          <AsyncImage
            className="logo-m rounded-3 "
            alt=""
            src={img4}
          />
          </div>
          <b className="d-flex justify-content-center" >
          {t("Top profils")}
          </b></div>
        </div>
  
      </div>
    </div>
  );
}
