import React, { useContext, useEffect } from "react";
import AsyncImage from "../../../AsyncImage";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { AppContext } from "../../../../routes/AppContext";

export default function AnswerComponent(props) {
  const {envVar,testLanguage } = useContext(AppContext);
  let answerLanguage=testLanguage=="fr"?"answer_fr":"answer_en"
  useEffect(() => {
    hljs.highlightAll();
  });
  return (
    <div
    style={{maxHeight:"15rem",maxWidth:"100%",overflow:"auto"}}

      className={
        props.solution
          ? "border border-secondary border-3 rounded m-2 p-1 bg-secondary"
          : props.answer.chosen
          ? props.answer.isCorrect
            ? "border border-success border-3 rounded m-2 p-1 bg-success"
            : "border border-danger border-3 rounded m-2 p-1 bg-danger"
          : "border rounded m-2 p-1"
      }
    >
      {  props.answer.type != 1 ? (
          <div  dangerouslySetInnerHTML={{
            __html: props.answer[answerLanguage],
          }} ></div>
          
        ) : (
          <AsyncImage
            className="w-100"
            src={
              envVar.REACT_APP_CLIENT_URL
              + "/Test/images/" +
              props.answer[answerLanguage]
            }
          />
        )}
    </div>
  );
}
