import React, {
  Fragment,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { AppContext } from "../../routes/AppContext";
import { useCodepromo } from "../../services/api/pricing/useCodepromo";

import { notification } from "antd";
import { useTranslation } from "react-i18next";


const CodePromopay = () => {
  const { t } = useTranslation();

  const { userId, setUCodepromo, uCodepromo,headers } = useContext(AppContext);
  const [fData, setFdata] = useState({
    codepromo: "",
    error: false,
    success: false,
  });
  const HandleConversion = async () => {
    {
      let res = await useCodepromo(
        { code: fData.codepromo },
        headers
      );
      if (res?.status == 201) {
        notification.success({
          description: res.data.message,
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor:"#d4edda",
            marginTop:"5rem"
          },
        });
        setUCodepromo(res.data.code);
      } else {
        notification.error({
          description: res.data.message,
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor:"#f8d7da",
            marginTop:"5rem"
          },
        });
      }
    }
  };

  // alert
  const alert1 = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  return (
    <Fragment>
      {!uCodepromo && (
        <div className="flex flex-col space-y-2">
          {fData.success ? alert1(fData.success, "green") : ""}
          <div className="font-medium text text-gray-600 tracking-widest  px-3 py-2 rounded-lg">
            {t("Entrez un code promo, ici!")}
          </div>
          <input
            placeholder="#code promo"
            onChange={(e) => {
              setFdata({ codepromo: e.target.value, error: false });
            }}
            value={fData.codepromo}
            type="text"
            id="number"
            className={`${
              !fData.error ? "" : "border-red-500"
            } border px-4 py-2 w-full focus:outline-none`}
          />
          {!fData.error ? "" : <p className="text-red-500">{fData.error}</p>}
          <div className="text-center">
            <div onClick={HandleConversion} className=" btn btn-primary w-50">
              {t("Utiliser")}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CodePromopay;
