import { Modal } from "antd";
import moment from "moment-timezone";
import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";


export default function EstimationBill(props) {
  const { t,i18n } = useTranslation();

	const {fonctionnalites,role,user,modules} = useContext(AppContext);

  const captureRef = useRef(null);
  const [download,setDownload]=useState(false)


  let {duration,functionalities,discount,allProduct,idCompanyPackage,pName,pDescription,pPrice} = props.bill;
  //order.allProduct.map

  
  let company=role=="company"?user:user.company

  const language=i18n.language 
  const modulesLanguage={"fr":"name","en":"name_en"}
  const module=modulesLanguage[language]

  const functionalitiesLanguage={"fr":"fName","en":"fName_en"}
  const functionalityLanguage=functionalitiesLanguage[language]
  const getScreenshot = async () => {
    await setDownload(true)
    const canvas = await html2canvas(captureRef.current,{ logging: true, letterRendering: 1, useCORS : true});
    const img = canvas.toDataURL("image/png");
    //converting px to in
    const width=parseInt(canvas.width)/96
    const height=parseInt(canvas.height)/96
    const doc = new jsPDF({orientation:'p',unit:'in',format:[width,height]});
    doc.addImage(img, 0, 0);
  await  doc.save(company.name+moment().format("DD-MM-YYYY")+".pdf");
    setDownload(false)
  };


  let amount=(pPrice).toFixed(3)
    let total=(amount*1.19).toFixed(3)
    let reduction=(discount).toFixed(2)
    let reductionPrice=((amount)*(reduction/100)/(1-reduction/100)).toFixed(3)
    let subtotal=(Number(amount)+Number(reductionPrice)).toFixed(3)
    let TVA=(amount*0.19).toFixed(3)

 let list= modules.map( m=>{
  let price=props.bill.modules.find(module=>module.idModule==m._id).price

let functionalitiesList=m.functionalities.map( f=>{
  let functionality= fonctionnalites.find((fun)=>fun.fType==f)
  let fName=functionality[functionalityLanguage]
  let qte=functionalities.find((fun)=>fun.fType==f)?.qte
  return(<tr>
  <td style={{borderColor:"#ddd0"}} >
    <span >{""}</span>
  </td>
  <td  >
    <span >{fName}</span>
  </td>
  <td  >
    <span >{qte>999999?t("illimité"):qte}</span>
  </td>
  <td  style={{borderColor:"#ddd0"}} >
    <span > </span>
    <span>{""}</span>
  </td>
  </tr>)
    })

	return(<>
		<tr>
		<td>
		  <span >{m[module]}</span>
		</td>
		<td  style={{borderColor:"#ddd0"}} >
		  <span > </span>
		  <span ></span>
		</td>
		<td  style={{borderColor:"#ddd0"}} >
		  <span >{}</span>
		</td>
		<td>
		  <span>{price.toFixed(3)}</span>
		  <span >{t("DTN")} </span>
		</td>
	  </tr>
    {functionalitiesList}
    </>
	)
  })

  return (
    <div className="">
      <Modal
        title={<><h5 className="m-1">{t("Facture")}
        </h5>
        </>}
        className="w-75"
        centered
        // closeIcon={
        //    <i className="mt-5 ">
        //      <CloseOutlined className="m-3" />
        //    </i>
        // }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={
          <>
            {/* <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer "
                onClick={() => {
                  props.history.push("/company/manageJobPosts");
                }}
              /> */}
            {/* <input
                type="button"
                className=" btn btn-primary p-1 rounded m-1 "
                value=" Ok"
                onClick={() => {
                  props.history.push("/company/addJobPost");
                }}
              /> */}
          </>
        }
      >
        <div>
        <span role="button" className="right icons15 btn btn-primary  rounded-circle d-flex align-items-center p-2" onClick={getScreenshot}  ><DownloadOutlined className="" /></span>

          <div  ref={captureRef} 
          // style={{  width:download &&"168mm",minHeight:download&& "238mm"}} 
  className={download?"facture p-2 a4":"facture w-100 p-0 m-0"}  >
            <header>
              <h1>{t("Devis")}</h1>
              <address>
                <p> Rec-INOV</p>
                <p>
                  {t("AVENU 5 AOUT RUE IBN RACHIK")}
                  <br />
                  3001 Sfax
                </p>
                <p>(216) 58 063 066</p>
                <p>service@Rec-INOV.com</p>
                <p>WWW.Rec-INOV.COM</p>
              </address>
              <span>
                <img
                  alt=""
                  src="images/logo.png"
                />
                <input type="file" accept="image/*" />
              </span>
            </header>
            <article>
              {/* <h1>Client :</h1> */}
             
            
              <table className="inventory">
                <thead>
                  <tr>
                    <th>
                      <span>{t("Module")}</span>
                    </th>
                    <th>
                      <span>{t("Fonctionnalité")}</span>
                    </th>
                    <th>
                      <span>{t("Quantite")}</span>
                    </th>
                    <th>
                      <span>{t("Total")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
					{list}
                </tbody>
              </table>
              <table className="balance">
                <tr>
                  <th>
                    <span dataPrefix>{t("Sous-total")}</span>
                  </th>
                  <td>
                    <span>{subtotal}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
           
                <tr>
                  <th>
                    <span dataPrefix> {t("Reduction")}</span> <span>{reduction}%</span>
                  </th>
                  <td>
                    <span dataPrefix>{reductionPrice}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span dataPrefix>{t("Total HT")}</span>
                  </th>
                  <td>
                    <span>{amount}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span dataPrefix>T.V.A. 19% </span>
                  </th>
                  <td>
                    <span dataPrefix>{TVA}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
         
			
                <tr>
                  <th>
                    <span dataPrefix>{t("Total")} TTC</span>
                  </th>
                  <td>
                    <span>{total}</span>
                    <span dataPrefix> {t("DTN")} </span>
                  </td>
                </tr>
              </table>
            </article>
            <aside>
              <div>
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  style={{
                    fontFamily: "ArialMT,Arial,sans-serif",
                    maxWidth: "100%",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          <span style={{ color: "#7f8c8d" }}>
                            This email was sent to you by Service@Rec-INOV.com
                            (Do not reply)
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </aside>
          </div>
        </div>
      </Modal>
    </div>
  );
}
