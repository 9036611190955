import React, { Component } from 'react'
import { Timeline } from 'antd';
import CvCercle from './cv-cercle/CvCercle';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { withTranslation } from 'react-i18next';
class ExperiencesEColor extends Component {
  render() {
    const { t } = this.props;
    let i=-1
    let list=this?.props?.experiences?.map(experience=>{
        i++
    let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]

        let startDate=new Date(experience.startDate)
        let date2 =new Date(experience.endDate)
        let endDate=experience.endDate? months[date2.getMonth()]+date2.getFullYear(): t("Présent")
       let dateText=months[startDate.getMonth()]+startDate.getFullYear()+" - "+endDate
      return(<Timeline.Item dot={<CvCercle/>}  key={i}>
      <div className="time">
        <div className="time-title">
        {experience.companyName}
        </div>
        <div className="time-body">
          <div className="time-b-top">
            <div className="time-date" style={{border: `solid 1px ${this?.props?.to1}`,color: this?.props?.to1}}>
            {dateText}
            </div>
            <div className="time-job" style={{color: this?.props?.to1}}>
            {experience.title}            
            </div>
          </div>

          <div className="time-cont">
          {experience.description}
          </div>
        </div>
      </div>
    </Timeline.Item>
      )
    }) 
    return (
        <>
        {list.length>0&&(
            <div className="cv-cert">
                <div className="cert-t">

                    <div className="cert-top">
                        <div className="cert-icon">
                        <div className="cert-icons" style={{border: `solid 2px ${this?.props?.to1}`,color: this?.props?.to1}}>
                            <LocationCityIcon   className='cert-icon-cont'  />
                        </div>
                        </div>
                        <div className="cert-name" style={{background: this?.props?.to1}}>
                        {t("Expériences professionnelles")}
                        </div>
                    </div>
                </div>

                <Timeline style={{marginLeft:"25px"}}>
                    {list}          
                </Timeline>
        </div>
        )
        
        }
        </>
        
    )
  }
}
export default withTranslation()(ExperiencesEColor)