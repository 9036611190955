import React from "react";
import { Link, withRouter } from "react-router-dom";
// import logo from "../../media/image/recin-transparent.webp";
import ScrollspyNav from "./scrollSpy";
import { Menu, Dropdown } from "antd";
import { LoginOutlined, ApiOutlined } from "@ant-design/icons";
import Cookies from "../home/cookies/cookies";
import FooterAlt from "../footer/FooterAlt";
import AsyncImage from "../AsyncImage";
import { withTranslation } from "react-i18next";
import logoText from "../../media/image/rec-text-blan.png";
import logo from "../../media/image/rec-logo-blan.png";
import LanguageSelector from "./LanguageSelector";
import { AppContext } from "../../routes/AppContext";
class Navbar extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = { Tab: "",logoSelected : logoText ,mytoggle:false};
    this.navbarCollapseRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.updateDevTools();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    if(window.innerWidth <=450)
      this.setState({ logoSelected:logo})
    else
    this.setState({ logoSelected:logoText})
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.context.role==="")  {
      if(this.state.mytoggle===true){
      this.navbarCollapseRef.current.className = 'navbar-collapse collapse show';
      }else
      {this.navbarCollapseRef.current.className = 'navbar-collapse collapse';}
    }
    // Vérifiez si envVar a changé depuis la dernière mise à jour
    const prevDevTool = prevProps?.context?.envVar?.REACT_APP_DEVTOOL;
    const currentDevTool = this.context?.envVar?.REACT_APP_DEVTOOL;

    // Vérifiez si envVar a changé depuis la dernière mise à jour
    if (prevDevTool !== currentDevTool) {
      this.updateDevTools();
    }
  }

  updateDevTools() {
    const { envVar } = this.context;

    if (envVar.REACT_APP_DEVTOOL === 'production') {
      // Désactiver les sorties console en production
      console.log = console.warn = console.error = console.info = console.debug = () => {};
    
      // Désactiver React DevTools
      if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => {} : null;
        }
      }
    } else {
      // Réactivez les DevTools dans tous les autres cas
      // (optionnel, dépend de votre logique de gestion des DevTools)
    }
  }

  /**
   * Sets active tab
   */
  setActiveTab = (tab, e) => {
    this.setState({ Tab: tab });
  };
  menu = (
    <Menu>
      <Menu.Item key="1" icon={<LoginOutlined />}>
        <Link to="/signin/company"> {this.props.t("M'identifier")}</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ApiOutlined />}>
        <Link to="/signup/company">{this.props.t("Inscription")}</Link>
      </Menu.Item>
    </Menu>
  );
  // handleChangeClassName = () => {
  //   if (this.navbarCollapseRef.current) {
  //     this.navbarCollapseRef.current.className = 'navbar-collapse collapse';
  //   }
  // };
  handleMyToggle = () => {
  this.setState(prevState =>{
    return{
         ...prevState,
         mytoggle : !prevState.mytoggle
    }
 })
}

  render() {
    let path = this.props.history.location.pathname;
    return (
      <React.Fragment>
        {this.props.user.role == "" ? (
          <>
            {" "}
            <nav
              id="main_navbar"
              className="  main_navbar navbar navbar-expand-lg  fixed-top navbar-custom sticky main-navbar-home"
            >
              <div className="container">
                <Link to="/">
                  <div
                    onClick={() => {
                      this.setState({mytoggle:false});
                      this.props.history.push("/");
                    }}
                    className="navbar-brand"
                  >
                    <AsyncImage
                      src={this.state.logoSelected}
                      style={{  height: "4rem" }}
                    />
                    {/* <b className="text-white">Rec-INOV</b> */}
                  </div>
                </Link>
                <div className="d-flex">
                    <div className="navbar-lang">
                      <LanguageSelector/>
                    </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  // data-toggle="collapse"
                  // data-target="#navbarCollapse"
                  // aria-controls="navbarCollapse"
                  // aria-expanded="false"
                  // aria-label="Toggle navigation"
                  onClick={()=>{this.handleMyToggle()}} 
                  >
                  <i className="mdi mdi-menu"></i>
                </button>
                  </div>
                <div className="collapse navbar-collapse" id="navbarCollapse" ref={this.navbarCollapseRef}>
                  <ScrollspyNav
                    scrollTargetIds={[
                      "blog",
                      "Demander une Demo , connexion , Recruteur ",
                    ]}
                    activeNavclassName="active"
                    scrollDuration="800"
                    headerBackground="true"
                    className="ms-auto  row col-12 "
                  >
                    {/* <ul
                      className="col-6 navbar-nav ms-auto navbar-center"
                      id="mySidenav"
                    ></ul> */}
                    <ul className=" d-flex    justify-content-end text-end navbar-nav ">
                      {path == "/" ? (
                        <li  onClick={() => {this.setState({mytoggle:false})}} className="d-flex align-items-end  justify-content-end d-lg-inline text-end  py-1">
                          <a
                            href="#blog"
                            className="d-flex align-items-end  justify-content-end nav-link  text-white  text-uppercase blog"
                            style={{ color: "#00000000" ,padding:" 6px" }}
                          >
                            <b>{this.props.t("Blog")}</b>
                          </a>
                        </li>
                      ) : (
                        <li 
                          onClick={() => {
                            this.setState({mytoggle:false}) ;
                            this.props.history.push("/blog");
                          }}
                          className="d-flex align-items-end  justify-content-end d-lg-inline text-end  py-1"
                        >
                          <a
                            style={{ color: "#00000000" ,padding:" 6px" }}
                          className="d-flex align-items-end  justify-content-end nav-link  text-white  text-uppercase blog ">
                            <b>{this.props.t("Blog")}</b>
                          </a>
                        </li>
                      )}

                      {path == "/home-company" ||
                      path == "/signin/company" ||path == "/demo" ||
                      path == "/signup/company" ? (
                        <>
                        <li  role="button"  onClick={() => {
                         this.setState({mytoggle:false})
                          this.props.history.push("/signin/company");
                        }}  className="nav-item text-white  d-flex align-items-end  justify-content-end"  style={{paddingBottom:" 9px"}}>
                         <b className="pb-2" ><span className="mdi mdi-login "></span> {this.props.t("Connexion")}</b> 
                        </li>
                      <li
                        onClick={() => {
                          this.setState({mytoggle:false})
                          this.props.history.push("/demo");
                        }}
                        className=" text-white  d-flex align-items-end  justify-content-end"
                        style={{ color: "#00000000",paddingBottom:" 4px" }}
                      >
                        <a className="nav-link  text-white text-uppercase demo  ">
                          <b>{this.props.t("Demander une Demo")}</b>
                        </a>
                      </li>
                    </>
                      ) : (path == "/blog" ? 
                            <li role="button"    onClick={() => {
                              this.setState({mytoggle:false})
                              this.props.history.push("/signin/candidate");
                            }} className="nav-item text-white d-flex align-items-end  justify-content-end "
                            style={{paddingBottom:" 7px"}}
                            >
                          <b className="pb-2" ><span className="mdi mdi-login "></span>{this.props.t("Connexion")} </b> 
                          </li>
                          :
                          <li role="button"    onClick={() => {
                            this.setState({mytoggle:false})
                              this.props.history.push("/signin/candidate");
                            }} className="nav-item text-white d-flex align-items-end  justify-content-end "
                             style={{paddingBottom:" 7px"}}
                             >
                          <b className="pb-2" ><span className="mdi mdi-login "></span>{this.props.t("Connexion")} </b> 
                          </li>
                      )}
                    </ul>
                  </ScrollspyNav>
                </div>
                <div className="m-0 d-none d-lg-inline m-2">
                    <LanguageSelector/>
                  </div>
              </div>
            </nav>
            <Cookies />
            {this.props.children}
            <FooterAlt />
          </>
        ) : (
          <>{this.props.children}</>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(Navbar));
