import axios from "../../../interceptors/axios";
export const GetInterviewsByJobPost = async (data, headers) => {
  try {
    let res = await axios.get("/api/interview/get/" + data.id, {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
