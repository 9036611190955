import { Menu } from 'antd';
import React, { useContext, useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { AppContext } from '../../routes/AppContext';
import { useTranslation } from 'react-i18next';

function CompanySettingNavbar(props) {
  const { t } = useTranslation();

     const history = useHistory({});
  const {role} = useContext(AppContext);
  // useEffect(()=>{
  //   document.getElementById('sNavbar').scrollIntoView({ behavior: 'smooth' });
  // },[])
    //  let visible=(history.location.pathname=="/company/setting"||history.location.pathname=="/company/orders")
   const  RedirectMe = (e) => {
        switch (e.key) {
          // case "0":
          //   history.push("/"+role+"/language")
          //   break
          case "1":
            history.push("/"+role+"/setting")
            break
          case "2":
            history.push("/"+role+"/emails")
            break
          // case "3":
          //   history.push("/"+role+"/language")
          //   break
   
          default: break
        }
      }
     return (
      <>
   <Menu id="sNavbar"  onClick={(e) => RedirectMe(e)} mode="horizontal" defaultSelectedKeys={[props.selectedKey]}>
    <Menu.Item key="1" >
    {t("Mot de passe")}
    </Menu.Item>
    <Menu.Item key="2" >
    {t("Emails personnalisés")}
    </Menu.Item>
   {/* <Menu.Item key="3" >
    {t("Langue")}
    </Menu.Item> */}
    {/* <Menu.Item key="3" >
    Politique de confidentialite
    </Menu.Item> */}
  
  </Menu>
  
  </>
  )
}
export default withRouter(CompanySettingNavbar)