import React, { useContext, useEffect, useState } from "react";
import Calendar from "../../calendar/Calendar";
import DoughnutChart from "./DoughnutChart";
import LineChart from "./LineChart";
import img1 from "../../../media/image/business.png";
import img2 from "../../../media/image/undraw-calendar.svg";
import AsyncImage from "../../AsyncImage";
import Planning from "./Planning";
import { GetInterviews } from "../../../services/api/interview/GetInterviews";
import moment from "moment-timezone";

import decrypt from "../../../utils/decrypt";
import { GetCompanyStatistics } from "../../../services/api/statistics/GetCompanyStatistics";
import StatisticTable from "./StatisticTable";
import AnimatedNumber from "react-animated-number";
import { GetCompanyStatisticsByYear } from "../../../services/api/statistics/GetCompanyStatisticsByYear";
import { Modal, notification, Select } from "antd";

import { AddInterviews } from "../../../services/api/interview/AddInterviews";
import { Gestyessin } from "../../../services/api/interview/Gettestyessin";
import { ObjectID } from "bson";
import InterviewForm from "../../interviews/InterviewForm";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../routes/AppContext";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export default function CompanyDashboard(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [interviews, setInterviews] = useState([]);
  // const [applications, setApplications] = useState([
  //   0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  // ]);
  // const [meetings, setMeetings] = useState([
  //   0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  // ]);
  const { t } = useTranslation();
const { user,role,headers,serverTime } = useContext(AppContext);
  const [year, setYear] = useState(moment(serverTime).year());
  const [date, setDate] = useState(moment(serverTime));
  const history = useHistory({});
  const addInterview = async (interview) => {
    //send request 
    let id = new ObjectID().toString()
  
    let response = await AddInterviews(
      { interviews: [{ date: interview.date,link: interview.link,address: interview.address, _id: id }], idJobPost: data.jobPosts[interview.jobPostIndex]._id,  },
      headers
    );
    if(response)
    {notification.success({
      description: t(response.data.code),
      className: 'custom-class',
      style: {
        width: 600,
        backgroundColor: "#d4edda",
        marginTop: "5rem"
      },
    });
    //push to dates
     setEvents([...events,{
      title:data.jobPosts[interview.jobPostIndex].title,
      image:null,
      interview: interview,
      id: id,
      color:"gray",
      textColor:"black",
      date: moment(interview.date).format(),
    }])
    setModalVisible(false)}
  }
  const getInterviews = async () => {
    let res = await GetInterviews(
      {},
      headers
    );
    if(res){
    let jobPosts = JSON.parse(decrypt(res.data));
    let interviews = [];
    for (let i = 0; i < jobPosts.length; i++) {
      interviews = [...interviews, ...jobPosts[i].interviews];
    }
    setInterviews(interviews);
    let e = interviews.map((a) => {
      return {
        title: a.idCandidate.length?a.idCandidate[0].name:a.idJobPost[0].title,
        image: a.idCandidate.length?a.idCandidate[0].image:null,
        interview: a,
        id: a._id,
        color:a.idCandidate.length?(moment(serverTime).isBefore(moment(a.date))?"green":"red"):"gray",
        textColor:a.idCandidate.length?"white":"black",
        date: moment(a.date).format(),
      };
    });
    setEvents(e);}
  };
  const getCompanyStatistics = async () => {
    let res = await GetCompanyStatistics(
      {},
      headers
    );
    if(res){
    let cData = JSON.parse(decrypt(res.data));
    return cData
    // setData(cData);
    //jobposts
    // for (let i = 0; i <= cData.jobPostsN; i++) {
    //   setTimeout(() => {
    //     setJobPostsN(i);
    //   }, 500);
    // }}
  };}
  const getCompanyStatisticsByYear = async () => {
    let res = await GetCompanyStatisticsByYear(
      { year: year },
      headers
    );
    if(res){
    let cData = JSON.parse(decrypt(res.data));
    let applications = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    for (let i = 0; i < cData.applications.length; i++) {
      applications[cData.applications[i]._id - 1] = cData.applications[i].count;
    }

    // setApplications(apps);
    let meetings = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    for (let i = 0; i < cData.interviews.length; i++) {
      meetings[cData.interviews[i]._id - 1] = cData.interviews[i].count;
    }
    // setMeetings(meetings);
    return({applications,meetings})
  }}
  useEffect(async () => {
      //check profile
      if(role=='company'){
        if (
          (user.description == "" ||
          user.employeesNumber == "" ||
          user.employeesNumber == null ||
          user.sector == "" ||
          user.phone == "" ||
          user.address == "")
        )
        history.push('/company/profile')
      }
    getInterviews();
    // getCompanyStatistics();
  }, []);
  // useEffect(async () => {
  //   getCompanyStatisticsByYear();
  // }, [year]);
  const y=moment(serverTime).year()

  const handleDateClick=(date)=>{
    //open interviewFormModal  and set clicked date
    setDate(date)
    setModalVisible(true)
  }
  const { data:stats, isLoading, isError } = useQuery(
    ['stats'], // Query key
   getCompanyStatistics
  );
  const { data:statsByYear, isLoading2, isError2 } = useQuery(
    ['stats',year], // Query key
   getCompanyStatisticsByYear
  );
  let meetings=statsByYear?.meetings??[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  let applications=statsByYear?.applications??[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
let data =stats??{
  jobPostsN:0,
interviews:0,
tests:0,
applications:0,
jobPosts:[]
}

  return (

    <div>
      <div className="row">
        <div className="col-md-3 col-sm-12 p-2 ">
          <div className="m-1 rounded shadow-sm h-100 d-flex align-items-center">
            <div className="p-2">
              <h5 className="text-center mb-2">
                <b>Rec-INOV</b>
              </h5>
              {t('La première plateforme tunisienne qui accompagne vos RH lors du processus de recrutement')}
              
              <AsyncImage className="w-100 rounded-3 " alt="" src={img1} />
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 ">
          <div className="row">
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-account-multiple icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.jobPostsN}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
                <br />
                <b className="">
              {t('Offres')}
                   </b>
              </div>
            </div>
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-clock-outline icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.interviews}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
                <br />
                <b className="">
              {t('Entretiens visio')}
                  </b>
              </div>
            </div>
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-pulse icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.tests}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
                <br />
                <b className="">
              {t("Test d'évaluation")}
                  </b>
              </div>
            </div>
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-party-popper icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.applications}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
                <br />
                <b className="">
              {t('Candidats postulés')}
                  </b>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="m-2 rounded shadow-sm">
              <div className="text-center m-0 fw-bold text-secondary">
                {" "}
              {t('Statistiques par mois en')}

                {" "}
                <Select
                  defaultValue={year}
                  onChange={setYear}
                  style={{ width: 80 }}
                  bordered={false}
                >
                  <Option value={y}>{y}</Option>
                  <Option value={y - 1}>{y - 1}</Option>
                  <Option value={y - 2}>{y - 2}</Option>
                </Select>
              </div>
              <LineChart applications={applications} meetings={meetings} />
            </div>
          </div>
        </div>
      </div>
       <div className="row">
        <div className="col-md-8">
          <div className="m-1 p-2  rounded shadow-sm h-100 row align-items-center">
              <strong> 
              {t('Résumé des offres publiées')}
                </strong>
            <StatisticTable data={data.jobPosts} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="m-1 rounded shadow-sm h-100 py-3">
          <strong className='m-1'>
          {t('Statistique des statuts des candidats')}
              </strong>
            <DoughnutChart
              interviews={data.interviews}
              accepted={data.accepted}
              refused={data.refused}
            />
          </div>
        </div>
      </div>
       <div className="row align-items-center mt-2">
        <div className="col-md-4">
          <div className="m-1 rounded shadow-sm h-100 text-center ">
            <AsyncImage className="w-75 rounded-3 " alt="" src={img2} />
            <Planning interviews={interviews} events={events} />
          </div>
        </div>
        <div className="col-md-8">
          <div className="m-1 rounded shadow-sm h-100 ">
            <Calendar events={events} handleDateClick={handleDateClick}/>
          </div>
        </div>
      </div> 
      <Modal
        title={
          <h5 className=" m-1 text-primary">
            <b>
            {t('Ajouter des dates')}
              </b>
          </h5>
        }
        style={{ top: 20 }}
        className=" h-100"
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
              className="m-3"
            />
          </i>
        }
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={<></>}
      >
        <div className='' >
         {modalVisible && <InterviewForm date={date} jobPosts={data.jobPosts} idJobPost={null}  add={addInterview} />}
        </div>
      </Modal>
    </div>
  );
}
