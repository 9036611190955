import React, { useEffect } from 'react'
import DemoContainer from '../components/demo/DemoContainer'

export default function DemoView() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div className='m-4' >
        <br/>
        <div className='my-5'>
        <DemoContainer/>

        </div>
    </div>
  )
}
