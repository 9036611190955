import { notification } from "antd";
import axios from "../../../interceptors/axios";

export const companyPackage = async (data, headers) => {
    try {
      let res = await axios.post(`/api/pricing/packages/company`, data,{
        headers: headers,
      });
      return res;
    } catch (err) {
      notification.error({
        description:err.response.data.message==undefined?"Une erreur est survenue":err.response.data.message,
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor:"#f8d7da",
          marginTop:"5rem"
        },
      }) 
    }
  };