import React, { useContext, useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { useHistory, withRouter ,Link} from 'react-router-dom';
import TestResultComponent from './TestResultComponent';
import { GetTestResults } from '../../services/api/companyTest/GetTestResults';
import EyeIcon from '../icons/EyeIcon';
import AnalyticsIcon from '../icons/AnalyticsIcon';
import { GetTest } from '../../services/api/companyTest/GetTest';
import Cv from '../cvPDF/Cv';
import { AppContext } from '../../routes/AppContext';
import decrypt from '../../utils/decrypt';
import TestStatistics from '../testStatistics/TestStatistics';
import UpdateTestExpirationModal from '../updateTestExpiration/UpdateTestExpirationModal';
import moment from 'moment';
import ClockIcon from '../icons/ClockIcon';
import { useQuery } from 'react-query';
import dateFromObjectId from '../../utils/dateFromObjectId';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;

 function TestResults(props) {
  const { t } = useTranslation();

  const {role,headers,serverTime} = useContext(AppContext);


    const [testResult,setTestResult]=useState({})
    const [test,setTest]=useState(props?.location?.state?.test?props.location.state.test:{})
    const [statsModalVisible,setStatsModalVisible]=useState(false)
    const [modalVisible,setModalVisible]=useState(false)
    const [expirationModalVisible,setExpirationModalVisible]=useState(false)
    const [modalVisible1,setModalVisible1]=useState(false)
  const history = useHistory()

const openStatisticsModal=async()=>{
  setStatsModalVisible(true)
}

  const getTestResults=async()=>{
    let response=await 
    GetTestResults({id:props?.location?.state?.test._id},headers)
    if(response){
  let data=JSON.parse(decrypt(response.data))
   data.map(test=>{test.name=test?.candidate?.name??test.name})
   getTest()
   return data
  }
    }
    const getTest=async()=>{
     if(!test?.questions){ let response= await GetTest({id:props?.location?.state?.test._id},headers)
    if(response){
  let data=JSON.parse(decrypt(response.data))
  setTest(data)}}
  }
       useEffect(() => {
           props?.location?.state?.test?
          console.log()
             : history.push('/'+role+'/manageTests')
   
       }, [])
 
       const { data, isLoading, isError } = useQuery(
        ['testResults',props?.location?.state?.test._id], // Query key
        getTestResults
      );
      let testResults=data??[]
  return (
    <div>

<h3 className="titrePage mb-3">

 {t("RÉSULTAT DU TEST")}  {test.name}
        </h3>
      
        <br/>
       {testResults.length>0 && <div className='d-flex justify-content-end' >
       <span className='m-1 icons12' role="button" onClick={()=>{
     openStatisticsModal({})
              }} >
                {t("Statistiques")}
                <AnalyticsIcon fill="black" width="2.5rem" />
                </span>
        </div>}
        <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
      <Table loading={isLoading} pagination={true} dataSource={testResults}>
        <Column align="center" sorter={(a, b) => a.name.localeCompare(b.name)} 
        // responsive={['lg']} 
        title={t("Nom")} dataIndex="name" key="name"
        render={(text, test, index) => {
        return(<>
{(typeof test.candidate === 'undefined')?
<span className=" text-secondary">{test.name} </span>
        :
        <span role="button" onClick={() => {
          setTestResult(test)
      setModalVisible1(true)
        }} >{test.name} </span>}</>)}}
        />
          <Column align="center" sorter={(a, b) => a.score-b.score} 
        // responsive={['lg']} 
        title={t("Score")} dataIndex="score" key="score"
        render={(text, test, index) => {
          return (<div className="" >
            {(typeof test.score === 'undefined')?
            (test.expirationDate && moment(test.expirationDate).isBefore(moment(serverTime)))?"essai expiré":
            "en attente":test.score}
          </div>)}}
        />
         <Column align="center" sorter={(a, b) => dateFromObjectId(a._id)-dateFromObjectId(b._id)} 
        // responsive={['lg']} 
        title={t("Date d'envoi")} dataIndex="date" key="date"
        render={(text, test, index) => {
          return (<div className="" >
            {moment(dateFromObjectId(test._id)).format("DD/MM/YYYY")}
          </div>)}}
        />
        <Column align="center" title={t("Actions")} dataIndex="Actions" key="Actions"
        responsive={['lg']} 
          render={(text, test, index) => {
            return (<div className="" >

            {(typeof test.score === 'undefined')?
            (test.expirationDate && moment(test.expirationDate).isBefore(moment(serverTime)))?
            <i className=" text-success  m-1 zoom1 icons12"  onClick={()=>{
              setTestResult(test)
              setExpirationModalVisible(true)
            }} role="button" 
            >
              <ClockIcon width="1.7rem" fill="rgb(239 128 72 / 85%)" />
            </i>
            :
               <i title="Résultat détaillée" className=" text-secondary m-2" role="button" 
             >
               <EyeIcon fill="gray" width="1.5rem"  />
             </i>
            :   <i title="Résultat détaillée" className=" text-primary m-2" role="button" 
            onClick={()=>{
              setTestResult(test)
            setModalVisible(true)
            }}
          >
            <EyeIcon  width="1.5rem"  />
          </i>}
           
            
            </div>)
          }}
        />

      </Table>
      </ConfigProvider>
     {modalVisible&& <Modal
        title={
          <h5 className=" m-1 text-primary">
            <b>{t("Aperçu du test")}</b>
          </h5>
        }
        className="m-5 w-75"
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              className="m-3"
            />
          </i>
        }
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={<></>}
      >
        <TestResultComponent setModalVisible={setModalVisible} modalVisible={modalVisible}  idTestResult={testResult._id} testResult={testResult} test={test}/>
   </Modal>}
   <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b>{t("Aperçu du CV")}</b>
            </h5>
          }
          className="mb-2 mt-2"
width={"95%"}
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={modalVisible1}
          onOk={() => setModalVisible1(false)}
          onCancel={() => setModalVisible1(false)}
          footer={<></>}
        >
      {modalVisible1 &&  <Cv decryptedData={true} userId={props.userId} candidate={testResult.candidate}  />}
     </Modal>
     {statsModalVisible&& <Modal
        title={
          <h5 className=" m-1 text-primary">
            <b>{t("Statistiques du test")}</b>
          </h5>
        }
        className="m-5 w-75"
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              className="m-3"
            />
          </i>
        }
        visible={statsModalVisible}
        onOk={() => setStatsModalVisible(false)}
        onCancel={() => setStatsModalVisible(false)}
        footer={<></>}
      >
        <TestStatistics test={test}/>
   </Modal>}
   {expirationModalVisible && <UpdateTestExpirationModal modalVisible={expirationModalVisible} setModalVisible={setExpirationModalVisible} test={test} testResult={testResult} jobPostid={testResult.idJobPost}/>}

    </div>
  )
}
export default withRouter(TestResults)