import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
class HardSkillsTwoEColor extends Component {
    constructor(props){
        super(props)
      }
  render() {
    const { t } = this.props;
    let i=-1
    let list=this?.props?.hardSkills?.map(hardSkill=>{
      i++
      return( 
               <div className="cvt-comp-elem" key={i}>{hardSkill.skill}</div>
        )
    })
    return (
        <div className="cvt-comp-t">
        <div className="cvt-comp-t-top">
            <div className="cvt-rect" style={{border: `solid 2px ${this?.props?.tt1}`}}>
                <div className="cvt-rectt" style={{backgroundColor: this?.props?.tt1}} ></div>
            </div>
            <div className="cvt-comp-name" style={{color: this?.props?.tt1}} >{t("Compétences techniques")} :</div>
        </div>
        <div className="cvt-comp-all">
            {list}
        </div>
    </div>

    )
  }
}
export default withTranslation()(HardSkillsTwoEColor)