import { Modal } from "antd";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { GetInterviews } from "../../services/api/interview/GetInterviews";
import decrypt from "../../utils/decrypt";

import Calendar from "../calendar/Calendar";
import { useTranslation } from "react-i18next";

export default function FullCalendarModal(props) {
  const [events, setEvents] = useState([]);
  const { headers, serverTime } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(async () => {
    getInterviews();
  }, []);

  const getInterviews = async () => {
    let res = await GetInterviews({}, headers);
    if (res) {
      let jobPosts = JSON.parse(decrypt(res.data));
      let interviews = [];
      for (let i = 0; i < jobPosts.length; i++) {
        interviews = [...interviews, ...jobPosts[i].interviews];
      }
      // setInterviews(interviews);
      let e = interviews.map((a) => {
        return {
          title: a.idCandidate.length
            ? a.idCandidate[0].name
            : a.idJobPost[0].title,
          image: a.idCandidate.length ? a.idCandidate[0].image : null,
          interview: a,
          id: a._id,
          color:
            a.idJobPost[0]._id == props.idJobPost
              ? a.idCandidate.length
                ? moment(serverTime).isBefore(moment(a.date))
                  ? "green"
                  : "red"
                : "gray"
              : "#eaad0cde",
          textColor: a.idCandidate.length ? "white" : "black",
          date: moment(a.date).format(),
        };
      });
      setEvents(e);
    }
  };
  return (
    <div>
      <Modal
        title={<h5 className="m-1">{t("Calendrier des entretiens")}</h5>}
        className="w-75"
        centered
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={null}
      >
        <div className="d-flex justify-content-start">
          <div className="m-1 d-flex">
            <div
              className="fc-daygrid-event-dot m-1"
              style={{ borderColor: "#eaad0cde" }}
            >
              {" "}
            </div>
            {t("Autre offre")}
          </div>
          <div className="m-1 d-flex">
            <div
              className="fc-daygrid-event-dot m-1"
              style={{ borderColor: "green" }}
            ></div>
            {t("Entretiens fixé")}
          </div>
          <div className="m-1 d-flex">
            <div
              className="fc-daygrid-event-dot m-1"
              style={{ borderColor: "red" }}
            ></div>
            {t("Entretiens passé")}
          </div>
          <div className="m-1 d-flex">
            <div
              className="fc-daygrid-event-dot m-1"
              style={{ borderColor: "gray" }}
            ></div>
            {t("Dates libres")}
          </div>
        </div>
        <Calendar events={events} handleDateClick={props.handleDateClick} />
      </Modal>
    </div>
  );
}
