import React from 'react'
import DetailsCookies from '../components/home/cookies/DetailsCookies';

export default function DetailsCookiesView(props) {
    return (
        <div  className="HomePage"> 
         <br/>
         <div className='m-5' >

          <DetailsCookies />
         </div>
        </div>
    )
}
