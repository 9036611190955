import { notification } from 'antd';
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { checkCompanyPackage } from '../../services/api/pricing/checkCompanyPackage';

import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { CandidateSigninWithToken } from '../../services/api/signin/CandidateSigninWithToken';


export default function CandidateSocialLogin(props) {
  const {headers} = useContext(AppContext);

  const history = useHistory({});

  const params = useParams();
  useEffect(async()=>{
    let res = await CandidateSigninWithToken({
      token:params.token,
      
    },headers)
  // notification.open({
           
  //   description:res.data.message,
  //   className: 'custom-class mt-5',
  //   style: {
  //       width: 600,
  //       border: "2px solid black",
  //       marginTop:"5rem"
  //     },
  // });
  // res?.data?.message &&    this.props.history.push('/signin/candidate')
  if(res?.status==200){
    localStorage.clear();
    sessionStorage.clear();
    await localStorage.setItem("token", res.data.token);
    //notification
    notification.success({
      description: res.data.message,
      className: "custom-class mt-5",
      style: {
        width: 600,
        backgroundColor: "#d4edda",
        marginTop: "5rem",
      },
    });
    window.location.replace("/candidate/profile");
  }else{
    history.push({
      pathname: "/signin/candidate",
    });
  }
  },[])
  return (
    <div></div>
  )
}
