import React,{useEffect, useState} from 'react';
import {get} from 'axios';
import Loader from "react-loader-spinner";
import "./verif.css"
import "./Step.css"

const VerifAuth = (props) => {
    const [loading,setLoading]=useState(false)
    const[verif,setVerif]=useState("")
    const[acces,setAcces]=useState(false)
    const fetchQuizData = async () => {
        setLoading(true);
        // try {
        
        //    const response = await get(`/api/candidate/CheckUser/${props.image}`);
        //  setVerif(response.data.toString())
        // } catch (error) {
        // }
    
        // setLoading(false);
      };
    useEffect(()=>{
fetchQuizData()
    },[])
    props.setAcces(verif);
    // return (
    //     <div>
    //         {loading?(<div>loading</div>):(<div>{verif}</div>)}
    //     </div>
    // )
    return (
      <div>
         <div className="md-stepper-horizontal orange">
    <div className="md-step active done">
      <div className="md-step-circle"><span>1</span></div>
      <div className="md-step-title">Authentification</div>
      <div className="md-step-bar-left"></div>
      <div className="md-step-bar-right"></div>
    </div>
    <div className="md-step  active">
      <div className="md-step-circle"><span>2</span></div>
      <div className="md-step-title">vérification</div>
      
      <div className="md-step-bar-left"></div>
      <div className="md-step-bar-right"></div>
    </div> 
    <div className="md-step ">
      <div className="md-step-circle"><span>3</span></div>
      <div className="md-step-title">validation</div>
      <div className="md-step-bar-left"></div>
      <div className="md-step-bar-right"></div>
    </div>
  
  </div>
 
         <div className="center"> <Loader
      type="Puff"
      color="#00BFFF"
      height={100}
      width={100}
      
    /></div>
      </div>
   );
}

export default VerifAuth
