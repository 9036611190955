import React, { useContext, useEffect, useState } from "react";

import { Link, useHistory, withRouter } from "react-router-dom";
import decrypt from "../../utils/decrypt";
import { AppContext } from "../../routes/AppContext";
import Pagination from "@mui/material/Pagination";
import { GetApplications } from "../../services/api/application/GetApplications";
import CandidateComponent from "./CandidateComponent";
import JobPostStateComponent from "./JobPostStateComponent";
import { Button, Checkbox, Modal, notification, Progress, Table } from "antd";
import moment from "moment-timezone";
import AsyncImage from "../AsyncImage";
import TagResponsibleForm from "../jobApplications/tagResponsible/TagResponsibleForm";
import Cv from "../cvPDF/Cv";
import FileIcon from "../icons/FileIcon";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import TimelineIcon from "../icons/TimelineIcon";
import TimelineModal from "../timelineModal/TimelineModal";

import { JobPostContext } from "../dashboard/jobPostDashboard/JobPostContext";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import UploadedCv from "../cvPDF/UploadedCv";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
import ReportIcon from '@material-ui/icons/Report';
import { AddProblem } from "../../services/api/problem/AddProblem";
const { Column } = Table;

function CandidatesList(props) {
  const {role,envVar,headers,user} = useContext(AppContext);
  const {jobPost,setDates,setNonFixedInterviews,setApplications,applications} = useContext(JobPostContext);
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalVisible3, setModalVisible3] = useState(false);
  const [candidate, setCandidate] = useState({});
  const [application, setApplication] = useState({});
const [emailSig, setEmailSig] = useState("")
const [descSig, setDescSig] = useState("")
const [validEmail, setValidEmail] = useState(true)
const [loadingForm, setLoadingForm] = useState(false)


  const   dateFromObjectId =  (objectId) =>{
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};
  //convert id to date
  // read state and render ui
  const history = useHistory();
console.log("candidate",candidate)
  const get = async () => {
    if(!jobPost._id){
     history.push("/company/manageJobPosts")}
    let response = await GetApplications(
      { id: jobPost._id },
      headers
    );
    if(response){
    let data = JSON.parse(decrypt(response.data));
    // let data=JSON.parse(decrypt(response.data))
    if(data.length){
    setApplications(data[0].applications)
    setNonFixedInterviews(data[0].applications.filter(a=>a.state==1.5))
    setDates(data[0].interviews)}
    else setModalVisible(true)
  return data
  }
  };

  const { data, isLoading, isError } = useQuery(
    ['applications',jobPost._id ], // Query key
    get
  );
  const applicationsFromDB=data?.length?data[0].applications:[]

  const handleSubmit = async (event) =>{
    event.preventDefault();
    setLoadingForm(true)  
    let response    
      if(role==="company"){
        response=await AddProblem({          
          idCompany:user._id,
          idCandidate:candidate._id,
          emailCandidat:emailSig,
          message:descSig
        },headers)
      }else if(role==="responsible"){
        response=await AddProblem({          
          idCompany:user.company._id,
          idCandidate:candidate._id,
          emailCandidat:emailSig,
          message:descSig
        },headers)
      }
if(response){
    //notification
    notification.success({
    
     description:this.props.t(response.data.code),
     className: 'custom-class',
     style: {
      width: 600,
      backgroundColor:"#d4edda",
      marginTop:"5rem"
    },
   })
 if(response.data.result){
  let r=response.data.result
  r.creationDate = moment(r.creationDate).format("DD/MM/YYYY");
   this.props.addResponsible(r)

 }}
setLoadingForm(false)
setEmailSig("")
setDescSig("")
setValidEmail(true)
setModalVisible3(false)
 }

  return (
    <div  className="row " id="scroller" >
      <div className="col-sm-12 col-md-8 rounded shadow">
      <div className="d-flex justify-content-left" >
      <h6 className=" titre-table"> {t("Liste des candidats")}  </h6>
      <button onClick={()=>{props.setPage("applications")}} className='btn btn-primary rounded-pill m-1' >
              {" "}
              <b className="">{t("Gérer candidatures")}
               </b>
            </button></div>
            <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table 
rowClassName={(record, index) => record.cvFile ? 'table-row-light-blue' :  'table-row-light'}
        loading={isLoading} pagination={true} dataSource={applicationsFromDB}>
          <Column
            align="left"
            defaultSortOrder={(a, b) => a.candidate[0].name.localeCompare(b.candidate[0].name)}
            title={t("Nom")}
            dataIndex="name"
            key="name"
            render={(text, application, index) => {
              let candidate =application.candidate[0];
            
              return (
                <div role="button" onClick={() => {
                  setCandidate(candidate)
                  setApplication(application)
                  setModalVisible1(true)}} className=" d-flex justify-content-start align-items-center">
                  <AsyncImage
                    className="logo-m rounded me-1"
                    src={ 
                      envVar.REACT_APP_CLIENT_URL
                      + "/candidate/images/" + candidate.image}
                  />
                  {candidate.name}
                </div>
              );
            }}
          />

<Column
            align="center"
            sorter={(a, b) => a.state - b.state}
            // responsive={["lg"]}
            onFilter= {(value, record) => record.state==value}
            title={t("Status")}
            dataIndex="state"
            key="state"
            filters= {[
              {
                text: t('En attente'),
                value: 0,
              },
              {
                text: t('Test'),
                value: 1,
              },
              {
                text: t('Entretien'),
                value: 1.5,
              },
              {
                text: t('Entretien planifié'),
                value: 2,
              },
              {
                text: t('Refusé'),
                value: 3,
              },
              {
                text: t('Accepté'),
                value: 4,
              },
            ]}
            render={(text, application, index) => {
              let state=application.state
              let status=state==0?<>{t("En attente")}</>:(state==1?<>{t("Test")}</>:(state==1.5?<>{t("Entretien")}</>:(state==2?<>{t("Entretien planifié")}</>:(state==3?<>{t("Refusé")}</>:(state==4?<>{t("Accepté")}</>:"")))))

              return <div role="button" onClick={()=>{
                if(state==1){
                  props.setPage("tests")
                }
                else if(state==1.5||state==2){
                  props.setPage("interviews")
                }
              }} >{status}</div>;
            }}
          />
          <Column
            align="center"
            sorter={(a, b) => dateFromObjectId(a._id) -dateFromObjectId(b._id)}
            // responsive={["lg"]}
            title={t("Date postulation")}
            dataIndex="date"
            key="date"
            render={(text, application, index) => {
              let date=dateFromObjectId(application._id) 
              return <>{moment(date).format("HH:mm L")}</>;
            }}
          />

<Column
                        align="center"
                        sorter={(a, b) => a.score - b.score}
                        // responsive={["lg"]}
                        title={t("Score")}
                        dataIndex="score"
                        key="score"
                        render={(text, candidate, index) => {
                          return (
                            <>
                              {
                                <Progress
                                  type="circle"
                                  percent={parseInt(candidate.score * 100)}
                                  format={(percent) => `${percent}%`}
                                  width={55}
                                  strokeColor={
                                    parseInt(candidate.score * 100) < 30
                                      ? "orange"
                                      : "green"
                                  }
                                />
                              }
                            </>
                          );
                        }}
                      />
          <Column
            align="center"
            // responsive={["lg"]}
            title=""
            dataIndex=""
            key="Lien"
            render={(text, interview, index,record) => {
              let candidate = interview.candidate[0];
              return (
                <div className="d-flex align-items-center">
                    <i
                    role="button"
                    onClick={() => {
                      setCandidate(candidate)
                      setModalVisible2(true)
                    }}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <TimelineIcon width="1.7rem" fill="rgb(239 128 72 / 85%)" />
                  </i>
                  {interview.cvFile && <ReportIcon style={{ fontSize: 25, color:"red" ,marginLeft:"5px" ,cursor:"pointer"}} onClick={() => {
                      setCandidate(candidate)
                      setModalVisible3(true)
                  }}
                      />}
                  {/* <i
                    role="button"
                    onClick={() => {
                      setCandidate(candidate)
                      setModalVisible1(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <FileIcon width="1.2rem" fill="#676ee7" />
                  </i> */}
                </div>
              );
            }}
          />
        </Table>
        </ConfigProvider>
      </div>
<div className="col-sm-12 col-md-4" >
       {role=="company"&& <TagResponsibleForm jobPostTitle={jobPost?.title} idResponsible={jobPost?.idResponsible} idJobPost={jobPost._id} userId={props.userId} />}
<JobPostStateComponent jobPost={jobPost} />
</div>
{modalVisible1&&    <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{t("Aperçu du CV")}</b>
                      </h5>
                    }
                    className="mb-2 mt-2"
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    width={"95%"} 
                    visible={modalVisible1}
                    onOk={() => setModalVisible1(false)}
                    onCancel={() => setModalVisible1(false)}
                    footer={<></>}
                  >
                   
      {modalVisible1 &&  (application.cvFile?<UploadedCv decryptedData={true} userId={props.userId} candidate={candidate} idJobPost={jobPost._id} />:<Cv  decryptedData={true} userId={props.userId} candidate={candidate} />)}

                  </Modal>}
                  {modalVisible2&& <TimelineModal idJobPost={jobPost._id} modalVisible={modalVisible2} setModalVisible={setModalVisible2} candidate={candidate} /> }
                  {modalVisible3&&  <Modal
          title={t("Signaler un problem")}
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={modalVisible3}
          onOk={() => setModalVisible3(false)}
          onCancel={() => setModalVisible3(false)}
          footer={<></>}
     
        >


<div className="">
              <div className="row justify-content-start">
                <div className="  ">
                  <form   className="   "
                    // onSubmit={this.handleSubmit}
                    >
                    <div className="">
                      <div className="mx-6">
                      <div className="d-flex">
                        {/* <input type="checkbox" name="changeemail" value="changeemail" checked={validEmail?"true":"false"} onClick={} /> */}
                        <Checkbox checked={validEmail? true :false}  
                            onChange={()=>{
                                setValidEmail(prev=> !prev)
                                setEmailSig("")
                                }}>
                        <div style={{fontSize:"17px"}}>
                          {t("Changer email")}
                          </div> 
                        </Checkbox>
                      </div>

                        {validEmail&&
                          <>
                            <label>{t("Nouvelle email")}</label>
                            <div className="md-form">
                              <input
                                className="form-control validate mb-2"
                                type="text"
                                id="email"
                                name="email"
                                value={emailSig}
                                onChange={(e)=>setEmailSig(e.target.value)}
                                
                              />
                            </div>
                          </>}

                        {/* <p className="text-danger"><small>{this.state.emailError}</small></p> */}
                        <br />
                        
                        <label>{t("Votre problem")}</label>
                        <textarea
                      name="description"
                      className="border form-control "
                      placeholder={t("Entrez le message...")}
                      onChange={(e)=>setDescSig(e.target.value)}
                      value={descSig}
                      id="description"
                      rows="6"
                    ></textarea>
                        
                        <br />
                        <div className="text-center">
                          {/* <button
                            type="submit"
                            className=" submitspinner btn btn-primary btn-block z-depth-1a"
                          
                          >
                      
                      {this.state.id ? "Modifier" : "Ajouter"}
                          </button> */}
                          <Button type="primary" 
                          loading={loadingForm} onClick={handleSubmit}
                          >
                          {t("Envoyer un message")}
        </Button>
                        </div>
                        <br />
                      </div>
                  
                    </div>
                  </form>
                </div>
              </div>
            </div>



        </Modal>
        }
    </div>
  );
}
export default withRouter(CandidatesList);
