import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import listPlugin from "@fullcalendar/list" // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid" // needed for dayClick
import frLocale from '@fullcalendar/core/locales/fr';
import AsyncImage from '../../AsyncImage'
import InterviewModal from '../../calendar/InterviewModal'
import { useTranslation } from 'react-i18next'
export default function Planning(props) {
  const { i18n } = useTranslation();

  const [modalVisible,setModalVisible]=useState(false)  
  const [interview,setInterview]=useState(null) 
   const handleDateClick = (arg) => { // bind with an arrow function
        alert(arg.dateStr)
      }
      
  return (
    <div className=' m-2' >
    {/* <h3>
    <b className=' rounded-circle bg-primary text-white px-2 py-1  m-1 ' >1</b>
    <strong> Choisissez un rendez-vous</strong>
    <hr />
    </h3> */}
    <FullCalendar
        plugins={[ dayGridPlugin,interactionPlugin,listPlugin,timeGridPlugin ]}
        initialView="listWeek"
        // loading={true}
        // views={}
        weekends={true}
        locale={i18n.language}
        locales={frLocale}
        timeZone='Africa/Tunis'
        // slotMinTime="08:00:00"
        // slotMaxTime="18:00:00"
        // slotDuration={{minutes:30}}
        // dateClick={handleDateClick}
        headerToolbar={{
          left: "",
          center: "prev,next",
          right: "",
        }}
        // footerToolbar= {{
        //   left: '',
        //   center: '',
        //   right: 'prev,next'
        // }}
        navLinks={true}
        allDaySlot={false}
  // initialDate={"2021-05-09T00:00:00"}
  // editable={true}
  height={640}
  selectable={true}
  themeSystem= 'bootstrap5'
  // selectMirror={true}
  dayMaxEvents={true}
  // select={(e)=>{console.log(e);}}
  events={props.events}
  eventClick={(e) => {
    setInterview(e.event._def.extendedProps.interview)
    setModalVisible(true)
  }}
  eventContent= {(e)=>{
    // return(<h6><b className='text-center text-white center  ' >

    //   </b></h6>)
  }}
  // dayHeaderFormat={(param) => {
  //   return param.date.day.toString();}}
      />
      {modalVisible &&<InterviewModal modalVisible={modalVisible} setModalVisible={setModalVisible} interview={interview} />}

    </div>
  )
}
