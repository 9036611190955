import { Pagination } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { GetTestStatistics } from "../../services/api/companyTest/GetTestStatistics";
import decrypt from "../../utils/decrypt";
import formatTime from "../../utils/formatTime";
import DoughnutChart from "../DoughnutChart";
import QuestionComponent from "./QuestionComponent";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

export default function TestStatistics(props) {
  const { t } = useTranslation();

  const [questionNumber, setQuestion] = useState(1);
  const [hideQuestions, setHideQuestions] = useState(true);

  const { userId, headers } = useContext(AppContext);

  const getTestStatistics = async () => {
    let response = await GetTestStatistics(
      { id: props?.test._id },
      headers
    );
    if (response) {
      let data = JSON.parse(decrypt(response.data));
      return (data[0]);
    }
  };

  const { data: testStats, isLoading, isError } = useQuery(
    ['testStats', props?.test._id], // Query key
    getTestStatistics
  );
  let data = testStats ?? {
    title: "",
    description: "",
    correctionType: 0,
    globalStopwatch: false,
    languages: ["fr"],
    level: 0,
    showResult: true,
    validity: 30,
    link: "",
    candidateTests: 0,
    scoreMax: 0,
    scoreMin: 0,
    scoreAvg: 0,
    pointsMax: 0,
    pointsMin: 0,
    pointsAvg: 0,
    timeMax: 0,
    timeMin: 0,
    timeAvg: 0,
    questions: [],
    numQuestions: 10,
    totalPoints: 0,
    totalTime: 0,
  }

  let list = data.questions.map((question, index) => (
    <>
      <QuestionComponent candidates={data.candidates} startIndex={0} index={index} question={question} />
    </>
  ));

  return (
    <div>
      <div className=" mx-4 p-2" >
        <b style={{ color: "darkblue" }}> <span><i>{t("Titre du test")} :</i></span> </b><h4  >  {data.title} </h4>
        <b style={{ color: "darkblue" }}> <span><i>{t("Description du test")} :</i></span> </b> <h6 > {data.description} </h6>

      </div>

      <div>

        <div className="Content-Question  p-4 mb-4">
          <div className="row g-3 justify-content-center" >
            <h4> {t("Chiffres Clés du Test")} :</h4>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }} >
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} >{t("Nombre de questions")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {data.numQuestions}
                </h6>

              </div>
            </div>

            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} >{t("Nombre de participants")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {data.candidates}
                </h6>
              </div>
            </div>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Participants ayant terminé le test")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {data.finishedCandidates}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="Content-Question  p-4 mb-4">
          <div className="row g-3 justify-content-center" >
            <h4> {t("Durée du Test")} :</h4>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Durée totale du test")}</h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {formatTime(data.totalTime)}
                </h6>

              </div>
            </div>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Durée moyenne du test")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {formatTime(data.timeAvg)}
                </h6>

              </div>
            </div>

            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Durée maximale du test")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {formatTime(data.timeMax)}
                </h6>
              </div>
            </div>

            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Durée minimale du test")}</h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {formatTime(data.timeMin)}
                </h6>

              </div>
            </div>
          </div>
        </div>
        <div className="Content-Question  p-4 mb-4">
          <div className="row g-3 justify-content-center" >
            <h4> {t("Scores")} :</h4>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Total des points dans le test")}</h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {data.totalPoints} {t("points")}
                </h6>
              </div>
            </div>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Moyenne des scores")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {data.pointsAvg} {t("points")} ({data.scoreAvg?.toFixed(2)}%)
                </h6>

              </div>
            </div>

            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Score maximum")}</h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {data.pointsMax} {t("points")} ({data.scoreMax?.toFixed(2)}%)
                </h6>
              </div>
            </div>

            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Score minimum")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">

                  {data.pointsMin} {t("points")} ({data.scoreMin?.toFixed(2)}%)
                </h6>
              </div>
            </div>

          </div>

        </div>
      </div>
      {/* 
      Nombre total de questions : {data.numQuestions}
      <br />
      Durée planifiée pour le test : {data.totalTime}
      <br />
      Nombre total des points dans le test : {data.totalPoints}
      <br />
      <br />
      Nombre total de participants : {data.candidates}
      <br />
      Nombre total de participants terminé le test : {data.finishedCandidates}
      <br />
      Durée moyenne de passage du test : {data.timeAvg} secondes
      <br />
      Durée maximale de passage du test : {data.timeMax} secondes
      <br />
      Durée minimale de passage du test : {data.timeMin} secondes
      <br />
      <br />
      Moyenne globale des scores :{data.pointsAvg} points ({data.scoreAvg}%)
      <br />
      Score le plus élevé :{data.pointsMax} points ({data.scoreMax}%)
      <br />
      Score le plus bas :{data.pointsMin} points ({data.scoreMin}%)
      <br /> */}
      {/* Nombre total de candidats ayant terminé le test : 70 

Pourcentage de candidats ayant terminé le test  */}
      <div className="Content-Question  p-4 mb-4  ">
        <h4> {t("Statistiques des résultats reçus selon la méthode d'envoi")} : </h4>
        <div className=" d-flex justify-content-center p-1 "   >
          <div className=" col-5">
            <DoughnutChart
              labels={[t("par email"), t("par offre"), t("via la gestion d'offre sur la plateforme")]}
              colors={[
                'rgba(42,118,244,255)',
                'rgba(148,186,249,255)',
                'rgba(217,230,253,255)',
                // "rgb(255, 202, 3)",
              ]}
              data={[data.invitedWithEmail, data.invitedFromJobPost, data.withLink]}
              title={""}
            />
          </div>
        </div>
      </div>
      <br />
      {hideQuestions ? <div onClick={() => { setHideQuestions(prevHideQuestions => !prevHideQuestions) }} className="text-center" >

        <a class="btn btn-light" >{t("Voir plus de détails")} <span class="ml-2 right-icon">→</span></a>
      </div> : <div>
        <h4>{t("Statistiques par question")} :</h4>
        {list && list[questionNumber - 1]}

        <div className="right">
          <Pagination
            current={questionNumber}
            // total={props.test.questions.length}
            total={data.questions.length}
            pageSize={1}
            onChange={(page) => {
              setQuestion(page);
            }}
          />
        </div>
      </div>}
    </div>
  );
}
