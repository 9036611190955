import axios from "../../../interceptors/axios"
// import { notification } from 'antd';

export const GetQuestions =async (data,headers) => {
    try {
      let res=await  axios.get('/companyTest/getQuestions/'+data.code,
      {
       headers: headers
     })
     return res
  }
 catch (err) {
    }
  }