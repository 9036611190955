import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Map , TileLayer, Marker } from 'react-leaflet-universal';
import "leaflet/dist/leaflet.css";
import markerIconPng from "../../media/image/marker.png"




export default function MapComponent(props) {
  
  const [currentPos,setCurrentPos]=useState(props.localization)
  useEffect(() => {
    const L = require("leaflet");
    delete L.Icon.Default.prototype._getIconUrl;
    
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("../../media/image/markerMap.png"),
        iconUrl: require("../../media/image/markerMap.png"),
        shadowUrl: require("../../media/image/marker.png")
    });
    
  }, [])
  
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setCurrentPos(marker.getLatLng())
            props.setPosition(marker.getLatLng())
          }
        },
      }),
      [],
    )

    


     const currentLocation= { lat: 34.5, lng: 11 }
     const   zoom= 5.75
    return (
        <div>
             <div  id="leafletmap">
      
      <Map className="leaflet-container" center={currentLocation} zoom={zoom} 
      onClick={(e)=>{
      props.setPosition(e.latlng)
      setCurrentPos(e.latlng)
      }}
      >
         {/* <TileLayer
              url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
          /> */}
            <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    attribution=''
  />
{ currentPos && 
<Marker
eventHandlers={eventHandlers}
  position={currentPos}
   draggable={true} 
    ref={markerRef}
    // icon={new L.Icon({iconUrl: markerIconPng, iconSize: [35, 35], iconAnchor: [12, 41]})}
    // onClick={(e)=>{console.log(e);}}
   >
{/* <Popup position={currentPos}>
  Current location: <pre>{JSON.stringify(currentPos, null, 2)}</pre>
</Popup> */}
</Marker>}
{/* <MyComponent /> */}
      </Map>
    
      </div>
        </div>
    )
}
// /media/adnen/HDD Disk2/SSR-Platform/Platform/front_end/node_modules/leaflet/dist/leaflet-src.js:10
// }(this, (function (exports) { 'use strict';
//  ^
// ReferenceError: window is not defined
//     at /media/adnen/HDD Disk2/SSR-Platform/Platform/front_end/node_modules/leaflet/src/core/Util.js:214:24
//     at /media/adnen/HDD Disk2/SSR-Platform/Platform/front_end/node_modules/leaflet/dist/leaflet-src.js:7:66
//     at Object.<anonymous> (/media/adnen/HDD Disk2/SSR-Platform/Platform/front_end/node_modules/leaflet/dist/leaflet-src.js:10:2)
//     at Module._compile (internal/modules/cjs/loader.js:999:30)
//     at Module._compile (/media/adnen/HDD Disk2/SSR-Platform/Platform/front_end/node_modules/pirates/lib/index.js:136:24)
//     at Module._extensions..js (internal/modules/cjs/loader.js:1027:10)
//     at Object.newLoader [as .js] (/media/adnen/HDD Disk2/SSR-Platform/Platform/front_end/node_modules/pirates/lib/index.js:141:7)
//     at Module.load (internal/modules/cjs/loader.js:863:32)
//     at Function.Module._load (internal/modules/cjs/loader.js:708:14)
//     at Module.require (internal/modules/cjs/loader.js:887:19)
