import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getAllFonctionnalite } from "../../services/api/pricing/getAllFonctionnalite";
import { getAllProductPardefaut } from "../../services/api/pricing/getAllProductPardefaut";
import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../routes/AppContext";

import { Modal, notification } from "antd";
import ProductDetails from "./ProductDetails";
import decrypt from "../../utils/decrypt";
import ContactUsModal from "./ContactUsModal";
import FreePackage from "./FreePackage";
import PackageComponent from "./PackageComponent";
import { useTranslation } from "react-i18next";


const SingleProduct = (props) => {
  const { t } = useTranslation();

  // const { data, dispatch } = useContext(HomeContext);
  const {
    cart,
    setCart,
    products,
    setProducts,
    fonctionnalites,
    setFonctionnalites,
    orders,userId,headers  } = useContext(AppContext);
  const history = useHistory();

  const [showComponentMensuel, setshowComponentMensuel] = useState(true);
  const [showComponentAnnuel, setshowComponentAnnuel] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [productIndex, setIndex] = useState(0);


  /* WhisList State */
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  );

  useEffect(() => {
    fetchData();
  }, []);

  // cette fonction get all forfaits de recrutement par defaut et les fonctionnalites de recrutement
  //  & dispatch home context
  const fetchData = async () => {
    // dispatch({ type: "loading", payload: true });
    try {
      let response = await getAllProductPardefaut(headers);
      let responseData = JSON.parse(decrypt(response.data));
      let response2 = await getAllFonctionnalite(headers);
        if (responseData && responseData.Products) {
          setProducts(responseData.Products);
          setFonctionnalites(
            JSON.parse(decrypt(response2.data)).fonctionnalites
          );
          // dispatch({ type: "setProducts", payload: responseData.Products });
          // dispatch({
          //   type: "setFonctionnalites",
          //   payload: responseData2.fonctionnalites,
          // });
          // dispatch({ type: "loading", payload: false });
        }
    } catch (error) {
    }
  };

  // cette fonction change state -> forfait mesuel
  const _onButtonClickMensuel = async () => {
    setshowComponentMensuel(true);
    setshowComponentAnnuel(false);
  };

  // cette fonction change state -> forfait annuel
  const _onButtonClickAnnuel = async () => {
    setshowComponentMensuel(false);
    setshowComponentAnnuel(true);
  };

  // if (data.loading) {
  //   return (
  //     <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24">
  //       <svg
  //         className="w-12 h-12 animate-spin text-gray-600"
  //         fill="none"
  //         stroke="currentColor"
  //         viewBox="0 0 24 24"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           strokeWidth="2"
  //           d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
  //         ></path>
  //       </svg>
  //     </div>
  //   );
  // }
  return (
    <Fragment>
      <div className="w-3/4 md:w-full">
        <div className="text-center mt-24">
          <h3 className="font-bold text-2xl mb-2">
            <b>{t("Forfaits de Recrutement")}</b>
          </h3>
          <h6 className="text-gray-600">
            {t("Choisi le forfait de tarification que vous convient")}
          </h6>
          <br />
          <span className="p-2 text-white bg-primary rounded-pill" >{t("Mensuel")}</span>
          <br />
          {/* section type forfait (Mensuel/Annuel) */}
          {/* <div className="hidden lg:block col-span-1 flex text-secondary mt-1">
            <span
            role="button"
              className={`${
                showComponentMensuel
                  ? "font-medium text-lg tracking-widest text-white bg-primary px-3 py-2 rounded-lg "
                  : "font-medium text-lg tracking-widest text-secondary bg-white border border-secondary  px-3 py-2 rounded-lg"
              }`}
              onClick={() => _onButtonClickMensuel()}
            >
              Mensuel
            </span>

            <span> </span>
            <button
            role="button"
            disabled
              className={`${
                showComponentAnnuel
                ? "font-medium text-lg tracking-widest text-white bg-primary px-3 py-2 rounded-lg "
                : "font-medium text-lg tracking-widest text-secondary bg-white border border-secondary  px-3 py-1 rounded-lg"              }`}
              onClick={() => _onButtonClickAnnuel()}
            >
              Annuel
            </button>
          </div> */}
        </div>
        {/* Section forfait de recrutement selon periode selectionnée */}
      </div>
      <section className="section pricing" id="pricing">
        <div className="container">
          <div className="row">
            {products &&
              products.length > 0 &&
              products.map((plan, index) => {
                return (
                  <PackageComponent plan={plan} key={index} />
                );
              })}
          </div>
        </div>
      </section>
      {/* {modalVisible && (
        <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b>Détails du forfait</b>
            </h5>
          }
          className="m-5 w-75"
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          footer={<></>}
        >
          <ProductDetails
            setModalVisible={setModalVisible}
            addToCart={AddToCart}
            productIndex={productIndex}
          />
        </Modal>
      )} */}

    </Fragment>
  );
};

export default SingleProduct;
