import React, { Component } from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
class AddJobPostConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
    };
  }

  render() {
    return (
      <div>
        <Modal
          title={<h5 className="m-1">{this.props.t("Ajouter un offre ?")}</h5>}
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined className="m-3" />
            </i>
          }
          visible={this.props.modalVisible}
          onOk={() => this.props.setModalVisible(false)}
          onCancel={() => this.props.setModalVisible(false)}
          footer={
            <>
              <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer "
                onClick={() => {
                  this.props.history.push("/company/manageJobPosts");
                }}
              />
              <input
                type="button"
                className=" btn btn-primary p-1 rounded m-1 "
                value=" Ok"
                onClick={() => {
                  this.props.history.push("/company/addJobPost");
                }}
              />
            </>
          }
        >
          {this.props.t("voulez-vous ajouter un offre d'emploi maintement ?")}
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(withRouter(AddJobPostConfirm));
