import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
class HardSkills extends Component {
    constructor(props){
        super(props)
      }
  render() {
    const { t } = this.props;
    let i=-1
    let list=this?.props?.hardSkills?.map(hardSkill=>{
      i++
      return( 
                <div className="tech-l" key={i}>{hardSkill.skill}</div>

        )
    })
    return (
        <div className="cv-body-competence">
            <div className="cv-r-top">
            <div className="cv-b-comp">{t("Compétences techniques")}</div>                
            </div>
            <div className="tech-comp">
            {list}
            </div>

        </div>
    )
  }
}
export default withTranslation()(HardSkills)