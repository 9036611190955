import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import en from '../../media/image/enflag.svg';
import fr from '../../media/image/frflag.svg';
import ar from '../../media/image/arbflag.svg';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const options = [
    { value: 'en', label: 'English', icon: en },
    { value: 'fr', label: 'Français', icon: fr },
    { value: 'ar', label: 'Arabe', icon: ar },
    // Ajoutez d'autres options de langue ici
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #ddd',
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected ? '#007bff' : 'transparent', // Background transparent
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      transition: 'background-color 0.2s ease'
    }),
    control: (provided, state) => ({
      ...provided,
      marginTop: 10,
      borderColor: state.isFocused ? '#007bff' : '#ccc',
      boxShadow: 'none', // Optional: remove the shadow
      '&:hover': {
        borderColor: state.isFocused ? '#007bff' : '#bbb'
      },
      borderRadius: 10,
      backgroundColor: 'transparent', // Background transparent
      color: '#fff', // Text in white
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff', // Text in white
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 10,
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    }),
    container: (provided) => ({
      ...provided,
      width: 200
    })
  };

  const formatOptionLabel = ({ value, label, icon }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={icon} alt={label} style={{ width: 30, marginRight: 10 }} /> 
      {label}
    </div>
  );

  const handleChange = (selectedOption) => {
    const lng = selectedOption.value;
    localStorage.setItem('ln', lng);
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  return (
    <Select
      defaultValue={options.find(option => option.value === i18n.language)}
      options={options}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles}
    />
  );
}

export default LanguageSelector;
