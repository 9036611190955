import React from 'react'
import ApplicationsList from '../../components/candidateApplications/ApplicationsList'

export default function CandidateApplicationsView(props) {
    return (
        <>
            <ApplicationsList userId={props.userId} />
        </>
    )
}
