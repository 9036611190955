import React, { useContext, useState } from "react";
import moment from "moment-timezone";
import { Modal } from "antd";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Calendar from "../../calendar/Calendar";
import AsyncImage from "../../AsyncImage";
import img from "../../../media/image/calendar-img.webp"
import { AppContext } from "../../../routes/AppContext";

import { useTranslation } from "react-i18next";
export default function InterviewsComponent(props) {
  const {serverTime} = useContext(AppContext);
  const { t } = useTranslation();
  const [calendarVisible, setCalendarVisible] = useState(false);
  let dates = [];
  let interviewsGroupedByDate = [];
  const interviews=props.interviews.filter(i=>moment(i.date).isAfter(moment(serverTime).subtract(1, 'days')));
  for (let i = 0; i < interviews.length; i++) {
    const interview = interviews[i]
    let date = moment(interview.date).format("Do MMMM");
    let index = dates.indexOf(date);
    if (index == -1) {
      dates.push(date);
      interviewsGroupedByDate.push([interview]);
    } else {
      interviewsGroupedByDate[index].push(interview);
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center my-3 ">
        <h5>
          <b>
            
          {t("Calendrier")}</b>
        </h5>
        <span
          className="btn btn-light rounded-circle"
          role="button"
          onClick={() => {
            setCalendarVisible(true);
          }}
        >
          <i className="mdi mdi-calendar icons12 "></i>
        </span>
      </div>
      <div>
        {dates.length ? (
          dates.map((date, index) => (
            <>
              <b className="text-muted">{date}</b>
              {interviewsGroupedByDate[index].map((interview) => (
                <div className="row text-center align-items-center my-2">
                  <div className="col-6 fs-4">
                    <b>{moment(interview.date).format("HH:mm")}</b>
                  </div>
                  <div className="col-6 ">{interview.idJobPost[0].title}</div>
                </div>
              ))}
            </>
          ))
        ) : (
          <>
            <AsyncImage className="w-100" src={img} />
          </>
        )}
      </div>

      <Modal
        title={
          <h5 className=" m-1 text-primary">
            <b>{t("Calendrier")}</b>
          </h5>
        }
        className="w-100"
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
              className="m-3"
            />
          </i>
        }
        visible={calendarVisible}
        onOk={() => setCalendarVisible(false)}
        onCancel={() => setCalendarVisible(false)}
        footer={<></>}
      >
        <Calendar events={props.events} />
      </Modal>
    </div>
  );
}
