import { Collapse } from 'antd';
import React from 'react';
import './EditTemplate.css'
// import AddIcon from '@mui/icons-material/Add';
import cv1 from '../../../../media/image/cv1ok.png'
import cv2 from '../../../../media/image/cv2ok.png'
import valideG from '../../../../media/image/valide-green.png'
import { useTranslation } from "react-i18next";
const EditTemplate = ({template,setTemplate,to1,setTo1,to2,setTo2 ,tt1,setTt1,tt2,setTt2,tt3,setTt3,tt4,setTt4}) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  return (
    <div className="edt-body">
    <Collapse accordion defaultActiveKey={['1']}>
    <Panel header={t("Choisir votre Templates")} key="1">
      <div className="edt-temp" >
        <div className="edt-select">
          <img src={cv1} alt='cv1' className={(template==='one') ? 'edt-cv  edt-selected' :'edt-cv'} onClick={()=>setTemplate('one')} />
          {template==="one" &&(<img src={valideG} alt='cv1' className='edt-valide'/>)}
        </div>
        <div className="edt-select">
          <img src={cv2} alt='cv2' className={(template==='two') ? 'edt-cv  edt-selected' :'edt-cv'} onClick={()=>setTemplate('two')}/>
          {template==="two" &&(<img src={valideG} alt='cv1' className='edt-valide'/>)}
        </div>
      </div>
    </Panel>
    <Panel header={t("Changer les couleurs")} key="2">
      {(template==='one')&&(
        <>
          <div className='edt-color'>
            <span className='edt-color-name'>{t("couleur")}  1 : </span>  <input type="color" value={to1} onChange={(e) => setTo1(e.target.value)} /> <br/>
          </div>
          <div className='edt-color'>
            <span className='edt-color-name'>{t("couleur")}  2 : </span><input type="color" value={to2} onChange={(e) => setTo2(e.target.value)}  /> <br/>
          </div>
        </>
      )}
      {(template==='two')&&(
        <>
          <div className='edt-color'>
            <span className='edt-color-name'>{t("couleur")}  1 : </span>  <input type="color" value={tt1} onChange={(e) => setTt1(e.target.value)} /> <br/>
          </div>
          <div className='edt-color'>
            <span className='edt-color-name'>{t("couleur")}  2 : </span><input type="color" value={tt2} onChange={(e) => setTt2(e.target.value)}  /> <br/>
          </div>
          <div className='edt-color'>
            <span className='edt-color-name'>{t("couleur")}  3 : </span>  <input type="color" value={tt3} onChange={(e) => setTt3(e.target.value)} /> <br/>
          </div>
          <div className='edt-color'>
            <span className='edt-color-name'>{t("couleur")}  4 : </span><input type="color" value={tt4} onChange={(e) => setTt4(e.target.value)}  /> <br/>
          </div>
        </>
      )}
      
    </Panel>
  </Collapse>
  </div>

  )
}

export default EditTemplate