import { Modal } from "antd";
import React, { useContext, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { GenerateTestLink } from "../../services/api/companyTest/GenerateTestLink";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function PublicLink(props) {
  const { t } = useTranslation();

  const [link, setLink] = useState(props?.test?.link ?? "");
  const [copied, setCopied] = useState(false);
  const { userId,envVar,headers } = useContext(AppContext);

  const generateLink = async () => {
    Modal.confirm({
      title: t("Génerer un lien"),
      icon: <ExclamationCircleOutlined />,
      content:
        t("voulez vous génerer un nouveau lien, l'ancien lien va étre indisponible"),
      okText: t("Génerer"),
      okType: "danger",
      cancelText: t("Annuler"),
      onOk: async() => {
        // this.Delete(jobPost._id, index);
        let res = await GenerateTestLink(
          { idTest: props.test._id },
          headers
        );
    if(res){
        setLink(res.data.link);}
        //set link in the test(state)
      },
      // onCancel:()=>{alert(index)}
    });
  };
  return (
    <div className="">
    <div className="d-flex justify-content-center">
        <button onClick={generateLink} className="btn btn-primary m-2" type="button">
        {link?t("Regénerer un lien"):t("Génerer un lien")}
      </button>
      </div>

     {link && <div className="input-group my-2">
        <input
          disabled
          value={envVar.REACT_APP_CLIENT_URL+"/jobPostTest/link/"+link}
          type="text"
          className="form-control"
          placeholder={t("Lien du test")}
          aria-label="Lien du test"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append">
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                envVar.REACT_APP_CLIENT_URL+"/jobPostTest/link/"+link
              );
              setCopied(true)
              setTimeout(()=>{setCopied(false)},3000)
            }}
            className={copied?"btn btn-success":"btn btn-secondary"}
            type="button"
          >
            {copied?<><i className="mdi mdi-check-circle" ></i>{t("Copié")}</>:t("Copier")}
          </button>
        </div>
      </div>}

    
    </div>
  );
}
