import React, { useContext } from 'react'
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import ManageTests from '../../components/manageTests/ManageTests'
import { AppContext } from '../../routes/AppContext';
import { GetTests } from '../../services/api/companyTest/GetTests'
import decrypt from '../../utils/decrypt';

export default function ManageTestsView(props) {
  const { headers } = useContext(AppContext);
    
    const getTests=async()=>{
        let response= await GetTests({},headers)
 if(response){
    let data=JSON.parse(decrypt(response.data))
    return data
    }}
    const { data, isLoading, isError } = useQuery(
        ['tests'], // Query key
        getTests,{
    refetchOnMount: true,
    refetchQueries: ['test'],

        }
      );
      let tests=data??[]
  return (
    <div>
        {isLoading?<Loading/>:<ManageTests tests={tests} loading={isLoading} idResponsible={props.idResponsible} />}
    </div>
  )
}
