import moment from 'moment-timezone';
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../routes/AppContext';
import { SetAppointment } from '../../services/api/appointments/SetAppointment'

import { useTranslation } from "react-i18next";

export default function Confirmation(props) {
  const history = useHistory()
  const {headers} = useContext(AppContext);
  const { t } = useTranslation();
  const Confirm=async()=>{
    SetAppointment({
      name:props.user.name,
      email:props.user.email,
      phone:props.user.phone,
      company:props.user.company,
      role:props.user.role,
      isCompany:props.user.isCompany,
      idAppointment:props.selected.id,
      date:props.selected.date
    },headers)
    
    history.push('/')

  }
  return (
    <div className=' pb-4 mb-5' >
    <h4 className='' >
    <b className=' rounded-circle bg-primary text-white px-2 py-1  m-1 ' >3</b>
    <strong> {t("Confirmation")}</strong>
    </h4>
   {props.step==3&& <>
    <hr />
  
    <div>
    {moment(props.selected.date).format("dddd DD MMM")}<br/>
    {moment(props.selected.date).format("HH[h]mm")}
    </div>
{ props.user.isCompany&&   <div>
    {t("Nom d'entreprise")} :  {props.user.company}
      </div>}
      <div>
        {t("Nom")} :
      {props.user.name}
      </div>
      <div>
       {t("Email")} :
      {props.user.email}
      </div>
      <div>
       {t("Téléphone")} :
      {props.user.phone}
      </div>
      <br/>
    <div className='text-center mt-3'>
    <button onClick={()=>{props.setStep(1)}} className='btn btn-danger  rounded m-1'>
    {t("Annuler")}
</button>
<button onClick={Confirm} className='btn btn-primary  rounded m-1'>
{t("Réserver")}
</button>
</div>
    </>}
    </div>
  )
}
