import React, { useContext } from 'react'
import { AppContext } from '../../routes/AppContext';
import { useTranslation } from "react-i18next";
export default function TestDetails(props) {
  const {envVar } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div>
      <h3 className='text-center' >{t("Êtes-vous prêts")} ?
      </h3>
    <ul>
    <li>
    {t("Vous allez démarrer le test")} .
    </li>
    <li>
    {t('Ce test est constitué de')} {parseInt(envVar.REACT_APP_SAME_LEVEL_QUESTIONS,10)+parseInt(envVar.REACT_APP_PREVIOUS_LEVEL_QUESTIONS,10)} {t('questions, il y a toujours au moins une réponse valide')}.
    </li>
    <li>
    {t('Vous avez entre')} 1 {t('à')}  3 {t('min par question')}.
    </li>
    <li>
    {t("Le classement final prend en compte l'ensemble des réponses pour chaque question, pas de panique si vous avez une erreur dans vos réponses !")}
    </li>
    </ul>
<h6 className='text-danger' >    {t("Attention")} :</h6>
    <p className='text-primary' ><strong>
      
    {t("Sachez que dès que vous avez cliqué sur le bouton 'Commencez l'examen', vous ne pouvez plus interrompre le processus d'examen. Tout abandon, après avoir cliqué sur le bouton, aura pour conséquence que votre examen sera considéré comme étant passé.")}
  </strong>  </p>
    
    
      <br/>
        <button className='btn btn-primary right' onClick={()=>{props.getRandomTest()}} >{t("Commencer le test")}</button>
    </div>
  )
}
