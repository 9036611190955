import { notification } from 'antd';
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import { AppContext } from '../../routes/AppContext';
import { checkPayment } from '../../services/api/paymee/checkPayment';
import { payOrder } from '../../services/api/pricing/payOrder';
import decrypt from '../../utils/decrypt';

 function PayMe(props) {
  const { t } = useTranslation();

  const { setCart,userId,total,uCodepromo,uPtsFidelite,setUCodepromo,setUPtsFidelite,fonctionnalites,headers} = useContext(AppContext);
  let data =props.location.state
  let token=data.data.data.token
  const history = useHistory();
  useEffect(()=>{
    window.addEventListener('message',async function(event) {
      if(event.data.event_id === 'paymee.complete') {
          //Execute Step 3
          // window.location.replace("Callback URL");
          //check then pay
          setTimeout(async() => {
            let res=await checkPayment({token:token})
            if(res.data.data.payment_status){
             Pay(res.data.data.transaction_id)
            }
            else {
          //notification
          notification.error({
           message: t("Notification"),
           description:t("paiement non validé!"),
           className: 'custom-class',
           style: {
            width: 600,
            backgroundColor:"#f8d7da",
            marginTop:"5rem"
          },
         });
         history.push({
           pathname: "/company/profile"})
            }
            
          }, 1000);
          }
  }, false);

  },[])
  const Pay = async (transactionId) => {
    let panier=JSON.parse(decrypt(localStorage.getItem("cart")))
    let allProduct=panier.map(item=>{if(item._id)return({id:item._id});
     else return false
    })
    allProduct=allProduct.filter(p=>p!=false)
    let idCompanyPackage=null
    let duration=0
    for (let i = 0; i < panier.length; i++) {
      if(panier[i].idCompanyPackage){
        idCompanyPackage=panier[i].idCompanyPackage
        duration=panier[i].duration
      }
    }

   let fonctionnalities =panier.map(item=>{return item.fonctionnalites[0].listefonctionnalite })
   let idFonctionnalities=[]
   let qtes=[]
   let fTypes=[]
   for (let i = 0; i < fonctionnalities.length; i++) {
    const forfait = fonctionnalities[i];
    for (let j = 0; j < forfait.length; j++) {
      const item = forfait[j];
          let index=idFonctionnalities.indexOf(item.idFonctionnalite)
          let fType= fonctionnalites.find((functionality) => functionality._id == item.idFonctionnalite)?.fType
    if(index==-1){
      idFonctionnalities.push(item.idFonctionnalite)
      fTypes.push(fType)
      qtes.push(parseInt(item.qte))
    }
    else{
      if(fType==1||fType==3){
       qtes[index]=Math.max(qtes[index],parseInt(item.qte))
      }else
      qtes[index]=qtes[index]+parseInt(item.qte)
    }
    }
   }
   fonctionnalities=[]
   for (let i = 0; i < idFonctionnalities.length; i++) {
    fonctionnalities.push({idFonctionnalite:idFonctionnalities[i],qte:qtes[i],fType:fTypes[i]})
   }
   //
   let allModules =panier.map(item=>{return item.modules })
   let idModules=[]
   let prices=[]
   for (let i = 0; i < allModules.length; i++) {
    const forfait = allModules[i];
    for (let j = 0; j < forfait.length; j++) {
      const item = forfait[j];
          let index=idModules.indexOf(item.idModule)
          let price= parseInt(item.price)
    if(index==-1){
      idModules.push(item.idModule)
      prices.push(price)
    }
    else{
      prices[index]=prices[index]+price
    }
    }
   }
   allModules=[]
   for (let i = 0; i < idModules.length; i++) {
    allModules.push({idModule:idModules[i],price:prices[i]})
   }
   //{idfonctionnalites:"",qte:""}
    let res=await payOrder({  
      allProductF:{},
      allProduct:allProduct ,
      fonctionnalites:fonctionnalities,
      user: userId,
      amount:Number(total) ,
      ptfideliteOffert:Number(total),
      ptfideliteUsed: uPtsFidelite,
      transactionId: transactionId,
      remise:uCodepromo?uCodepromo.pourcentage:0 ,
      status: "en cours",
      type: "Mensuel",
      order: "Recrutement",
      idCompanyPackage:idCompanyPackage,
      duration:duration,
      modules:allModules
    },headers)
      //notification
      notification.success({
        message: t("Notification"),
        description:res.data.message,
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor:"#d4edda",
          marginTop:"5rem"
        },
      });
      setCart([])
      setUCodepromo({})
      setUPtsFidelite(0)
      localStorage.removeItem("cart")
      localStorage.removeItem("uCodepromo")
      localStorage.removeItem("uPtsFidelite")
      window.location.replace('/company/orders');

  };
  return (
    <div
     >
        <iframe id="inlineFrameExample"
    title="Paymee"
    className='w-100' 
    height="600"
    // width="300"
    // height="200"
    src={"https://app.paymee.tn/gateway/"+token}>
</iframe>
    </div>
  )
}

export default withRouter(PayMe)