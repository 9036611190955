
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useTranslation } from "react-i18next";
ChartJS.register(ArcElement, Tooltip, Legend);




export default function DoughnutChart(props) {
  const { t } = useTranslation();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          // Change the label position
          // For doughnut charts, you can use the 'position' property in the 'labels' object.
          // Available options: 'default', 'edge', and 'outside'
          // position: 'right',
          padding: 10,
          boxWidth: 20,
        },
      title: {
        display: true,
        text: props.title ,
      },
    },
   }}
  const data = {
    labels: props.labels,
    datasets: [
      {
        title:"Entretiens",
        label: '# of Votes',
        data: props.data,
        backgroundColor: props.colors,
        borderColor: props.colors,
        borderWidth: 1,
      },  
    ],
  };
  return (
    <div><Doughnut options={options} data={data} /></div>
  )
}
