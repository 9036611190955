import React from "react";
// import Accueil from "./Accueil";
// import AboutSection from "./AboutSection";
// import ServiceSection from "./ServiceSection";
// import FeatureSection from "./FeatureSection";
// import ContactSection from "./ContactSection";
// import BlogSection from "./BlogSection";
// import Footer from "../footer/Footer";
// import ClientSection from "./ClientSection";
// import Counter from "../HomeCompany/components/Counter/counter";
import Loadable from 'react-loadable';
import Loading from "../Loading";

const Accueil = Loadable({
  loader: () => import('./Accueil'),
  loading: Loading,
});
const AboutSection = Loadable({
  loader: () => import('./AboutSection'),
  loading: Loading,
});
const ServiceSection = Loadable({
  loader: () => import('./ServiceSection'),
  loading: Loading,
});
const FeatureSection = Loadable({
  loader: () => import('./FeatureSection'),
  loading: Loading,
});
const ContactSection = Loadable({
  loader: () => import('./ContactSection'),
  loading: Loading,
});
const BlogSection = Loadable({
  loader: () => import('./BlogSection'),
  loading: Loading,
});
const Counter = Loadable({
  loader: () => import('../HomeCompany/components/Counter/counter'),
  loading: Loading,
});
const ClientSection = Loadable({
  loader: () => import('./ClientSection'),
  loading: Loading,
});
const Footer = Loadable({
  loader: () => import('../footer/Footer'),
  loading: Loading,
});
class IndexHome extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Accueil />

        {/* <AboutSection /> */}

        <FeatureSection />

        <ServiceSection />

        <BlogSection />
        <Counter/>

        <ContactSection />
          <ClientSection />
        <Footer />
      </React.Fragment>
    );
  }
}

export default IndexHome;
