import { Radio, Space } from "antd";
import React, { useState } from "react";
import { FrownOutlined, MehOutlined, SmileOutlined  }from '@ant-design/icons';
import { useTranslation } from "react-i18next";
export default function QuestionComponent(props) {
  const [answer, setAnswer] = useState("");

  const {t,i18n}=useTranslation();
const language=i18n.language 
const contents={"fr":"question","en":"question_en" ,"ar":"question_en"}
const content=contents[language]
// i18next.language==="fr" ? props.answers :props.answers_en
const contentsAns={"fr":"answers","en":"answers_en" ,"ar":"answers_en"}
const contentAns=contentsAns[language]
  const handleChange = (e) => {
    setAnswer(e.target.value)
  }
  const next = async () => {
    if (answer.length) {
      props.setAnswers([...props.answers, { answer: answer, idQuestion: props.question._id }]);
      //  if() 
      props.setIndex(props.myIndex + 1)
      setAnswer('')
    }
  };

  return (
    <>
      {props.myIndex == props.index && (
        <div>
          <h5>{props.question[content] }</h5>

          {props.question.type == 1 && <div className="d-flex">
            <Radio.Group onChange={handleChange} value={answer}>
              <Space direction="vertical">
                <Radio value={"bien"}>
                  <SmileOutlined style={{ color: "#37720be8" , fontSize: '2rem' }}  /></Radio>
                <Radio value={"moyen"}>
                  <MehOutlined style={{ color: "#f3ff49", fontSize: '2rem '}}  /></Radio>
                <Radio value={"mauvais"}>
                  <FrownOutlined style={{ color: "#ff0000cf" , fontSize: '2rem ' }}  /></Radio>
              </Space>
            </Radio.Group>
          </div>}
          {props.question.type == 2 && <>
            <Radio.Group onChange={handleChange} value={answer}>
              <Space direction="vertical">
                {props.question[contentAns].map(answer => (
                  <Radio value={answer}>{answer}</Radio>
                ))}
              </Space>
            </Radio.Group>
          </>}
          {props.question.type == 0 && <>
            <input
              className="border form-control "
              id="title"
              type="text"
              name="title"
              value={answer}
              onChange={handleChange}

            />
          </>}
          <br />
          <div className="d-flex justify-content-end" >
            <button className="btn btn-primary" onClick={next}>{t("Suivant")}</button>
          </div>
        </div>
      )}
    </>
  );
}
