import { Modal, notification, Select } from "antd";
// import { CKEditor } from "ckeditor4-react";
// import ReactQuill from 'react-quill';
import React, { useContext, useEffect, useState,Suspense } from "react";
import { AddCompanyEmail } from "../../services/api/companyEmail/AddCompanyEmail";
import { UpdateCompanyEmail } from "../../services/api/companyEmail/UpdateCompanyEmail";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { AppContext } from "../../routes/AppContext";
import { ObjectID } from "bson";
import { DeleteCompanyEmail } from "../../services/api/companyEmail/DeleteCompanyEmail";
import InfoIcon from "../icons/InfoIcon";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function CompanyEmailForm(props) {
  const { t } = useTranslation();

  const { userId,companyName,headers } = useContext(AppContext);
  const [id, setId] = useState(new ObjectID().toString());
  const [model, setModel] = useState(props.email?props.email.model:"");
  const [subject, setSubject] = useState(props.email?props.email.emailObject:"");
  const [body, setBody] = useState(props.email?props.email.emailBody:"");
  const [modelError, setModelError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
const eType=props.email.eType
const eTypeNew=props.email.eTypeNew
const language=props.email.language
useEffect(() => {
  setIsClient(true); 
  // Marquez le composant comme prêt une fois monté
  setIsReady(true);
}, []);
  const deleteEmail=async(id)=>{
    let res =await DeleteCompanyEmail({
      id:id
    },headers)
    if(res){
      // let emails=props.emails.filter(e=>e._id!=id)
      // emails.push(props.defaultEmails.find(e=>e.eType==eType))
      // props.setEmails(emails)
      props.setModifiedd(prev=>!prev)
      props.setModalVisible(false)

    notification.success({
      description: res.data.message,
      className: "custom-class",
      style: {
        width: 600,
        backgroundColor: "#d4edda",
        marginTop: "5rem",
      },
    });}

}
  const validation=()=>{
let isValid=true
if(!model){
    isValid=false
    setModelError(t("champs obligatoire"))
}
else{
    setModelError("")
}

if(!subject){
    isValid=false
    setSubjectError(t("champs obligatoire"))
}
else{
    setSubjectError("")
}

if(!body){
    isValid=false
    setBodyError(t("champs obligatoire"))
}
else{
    setBodyError("")
}

return isValid
  } 
 
const handleSubmit=(e)=>{
  e.preventDefault()
    if(validation()){
      props.setModalVisible(false)
            addEmail()
    }
}
  const addEmail=async()=>{
        let res =await AddCompanyEmail({
          model:model,emailBody:body,emailObject:subject,_id:id,eType:eType,language:language,eTypeNew:eTypeNew
        },headers)
    if(res){
        // let emails=props.emails.filter(e=>e.eType!=eType)
        // emails.push({_id:id,model:model,emailBody:body,emailObject:subject,idCompany:userId,eType:eType,language:language})
        // props.setEmails(emails)
        props.setModifiedd(prev=>!prev)
        setId(new ObjectID().toString())
        notification.success({
          description: res.data.message,
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#d4edda",
            marginTop: "5rem",
          },
        });}

  }
 const info = () => {
    Modal.info({
      title: null,
      content: (
        <div>
           <p className='fw-bold' > 
           {t("Vous pouvez ajouter l'intitulé du poste dans le contenu de l'email au format 'titre offre' afin qu'il soit dynamique pour toutes les offres d'emploi.")}
            </p>
        </div>
      ),
      onOk() { },
    });
  };

  const LazyReactQuill = isClient ? require("react-quill") : null;

  if (!isClient || !LazyReactQuill) {
    return <div>Chargement...</div>; // Afficher un indicateur de chargement si nécessaire
  }

  return (
    <div className=" ">
      <Modal
        title={
          <h5 className="m-1 text-primary">
            <b>{id ? t("Modifier") : t("Ajout")} {t("email")}</b>
          </h5>
        }
        className="w-75"
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
              className="m-3"
            />
          </i>
        }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={<></>}
      >
        <div className="">
          <div className="row justify-content-start">
            <div className="  ">
              <form className="   " onSubmit={handleSubmit}>
                <div className="">
                  <div className="mx-6">
                    <div className="d-flex justify-content-between" >
                    <label className="h5" >{t("Email")} : {model} </label>
        <span onClick={info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>

                   {props.email.idCompany && <button type="button"  onClick={() => {
                    Modal.confirm({
                      title: t("Reset email"),
                      icon: <ExclamationCircleOutlined />,
                      content: t("voulez vous utilisez l'email par défaut") + model,
                      okText: t("Reset"),
                      okType: "danger",
                      cancelText: t("Annuler"),
                      onOk: () => { 
                        deleteEmail(props.email._id)

                       },
                    });
                  }} className="btn btn-primary m-1"  >
                    {t("Utiliser l'email par défaut")}
                    </button>}
                    </div>
                    <div className="md-form">
    
                    </div>
                

                    <label>
                      {t("Objet du mail")} 
                      <b style={{ color: "blue" }}>* </b>
                    </label>
                    <div className="md-form">
                      <input
                        className="form-control validate mb-2"
                        id="jobTitle"
                        type="text"
                        name="jobTitle"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      />
                    </div>
                    <p className="text-danger">
                      <small>{subjectError}</small>
                    </p>

                    <label
                      className="col-lg-12 col-sm-12 mt-2 "
                      htmlFor="description"
                    >
                      <span className="">
                        {t("Corps du mail")} <b style={{ color: "blue" }}>*</b>
                      </span>{" "}
                      <br />
                      <div className="parent-containery">
                      {isClient && LazyReactQuill && (
                      <LazyReactQuill value={body} onChange={(e)=>{setBody(e)}} 
                      modules={{
                        toolbar: [
                          [{ header: "1" }, { header: "2" }, { font: [] }],
                          [{ size: [] }],
                          ["bold", "italic", "underline", "strike", "blockquote"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image", "video"],
                          ["clean"],
                        ],
                        clipboard: {
                          matchVisual: false, // Gérer le collage d'images sans ajustement automatique
                        },
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "link",
                        "image",
                        "video",
                      ]} 
                      theme="snow"                     
                      />
                      )}
                      </div>
                      {/* <CKEditor
                        onChange={(event) => {
                          const data = event.editor.getData();
                          setBody(data);
                        }}
                        initData={body}
                        config={{
                          toolbar: [
                            [
                              "Bold",
                              "Italic",
                              "Underline",
                              "Strike",
                              "-",
                              "RemoveFormat",
                            ],
                            [ 'Link', 'Unlink',"Anchor" ],
                            ["NumberedList", "BulletedList"],
                            ["Outdent","Indent","Blockquote" ],
                            [ 'Image', 'HorizontalRule',"SpecialChar" ],
                            ["Styles", "Format"],
                            ["Maximize"],
                          ],
                        }}
                      /> */}
                      <p className="text-danger">
                        <small>{bodyError}</small>
                      </p>
                    </label>

                    <br />
                    <div className="text-center">
                      <button
                        type="submit"
                        className=" submitspinner btn btn-primary btn-block z-depth-1a"
                      >
                        {id ? t("Modifier") : t("Ajouter")}
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
