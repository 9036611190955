import { Modal, Timeline } from 'antd';
import React, { useContext } from 'react'
import PenIcon from '../../icons/PenIcon';
import XMarkIcon from '../../icons/XMarkIcon';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../routes/AppContext";
import certificat from "../../../media/image/certificates/certificat.jpeg"
import { DeleteCertificate } from '../../../services/api/cv/certification/DeleteCertification';
import AsyncImage from '../../AsyncImage';
import CvCercle from '../../cvPDF/template/cv-cercle/CvCercle';
import { useTranslation } from "react-i18next";
const CertificateComponents = (props) => {
    const {user,setUser,headers} = useContext(AppContext);
    const { t } = useTranslation();
    let {certificate, getDate}=props
    const updateCertificate = async () => {
      props.setIndex(props.index);
      props.setModalVisible(true)
    };
    const deleteCertificate = async () => {
      Modal.confirm({
        title: t("Supprimer certificat"),
        icon: <ExclamationCircleOutlined />,
        content:
        t("voulez vous supprimer la certificat") + certificate.certificateName,
        okText: "Supprimer",
        okType: "danger",
        cancelText: "Annuler",
        onOk: async() => {
          //send request
        let res=await  DeleteCertificate({id:certificate._id},headers)
          //update context
          let cv = user
          cv.certificates.splice(props.index, 1);
          setUser(cv)
          props.updateView()
        },
        // onCancel:()=>{alert(index)}
      });
    };
    //update and delete icons , update will open the modal
  return (
    <Timeline.Item dot={<CvCercle/>} >
            <div className="time">
              <div className="time-title">
              {certificate?.certificateName}
              <PenIcon onClick={updateCertificate} className="me-2   zoom1" width="1rem" fill="blue" style={{marginLeft: "10px"}}  />
          <XMarkIcon onClick={deleteCertificate} className="me-2 zoom1" width="1rem" fill="red" />
              </div>
              <div className="time-body">

                <div className="time-date">
                {getDate(certificate.emissionDate, certificate.expirationDate)}
                </div>
                <div className="time-cont">
                {certificate.reference}
                </div>
              </div>
            </div>
          </Timeline.Item>
  )
}

export default CertificateComponents