import { Button, Pagination } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import PlusIcon from "../../../icons/PlusIcon";
import QuestionForm from "./QuestionForm";
import {
  PlusOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const QuestionsList = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [update, setUpdate] = useState(false);

  const pageSize = 1; // nombre de questions par page

  const handlePageChange = (pageIndex) => {
    console.log(pageIndex - 1)
    props.setCurrentPageIndex(pageIndex - 1);
    console.log("QL: handlePageChange",props.currentPageIndex)
  };
  const addQuestion = () => {
    let longQ=props.questions.length
    console.log(props.questions.length)
    console.log("QL: addQuestion",props.currentPageIndex)
    props.addQuestion();
    console.log(props.questions.length)
    props.setCurrentPageIndex(longQ);
    console.log("QL: addQuestion",props.currentPageIndex)
    setUpdate(!update);
  };
  
  const deleteQuestion = (i) => {
    props.deleteQuestion(i);
    setUpdate(!update);
    // if(props.currentPageIndex==props.questions.length-1){
    // props.setCurrentPageIndex(props.currentPageIndex - 1);
    // console.log("QL: deleteQuestion",props.CurrentPageIndex)
    // }
  };
  let i = -1;
  const list = props.questions.map((question) => {
    i++;
    const index = i;
    return (
      <div key={index} className=" my-2">
        <QuestionForm
          ref={ref}
          currentPageIndex={props.currentPageIndex}
          openQuestions={props.openQuestions}
          updateOpenQuestions={props.updateOpenQuestions}
          updateQuestion={props.updateQuestion}
          deleteQuestion={deleteQuestion}
          question={question}
          index={index}
          id={props.id}
          language={props.language}
          numQuestions={props.questions.length}

        />
      </div>
    );
  });
  let temps = 0;
  return (
    <div className="Setup-Question p-2">
      <>{list[props.currentPageIndex]}</>
      <br />
      <div className="d-flex justify-content-center ">
        {/* <button
          type="button"
          onClick={addQuestion}
          className="btn btn-primary rounded-pill p-2   "
        >
          <i className=" m-2">
            <PlusIcon fill="white" width="1.5rem" />
          </i>
          <b>Nouvelle question</b>
        </button> */}
        <Button
          onClick={addQuestion}
          style={{
            display: "flex",
            alignItems: "center",
            margin: "auto",
            marginTop: 20,
          }}
          type="primary"
          icon={<PlusOutlined />}
        >
          {t("Nouvelle Question")}
        </Button>
      </div>
      <Pagination
        style={{ marginTop: 16, textAlign: "center", marginBottom: 15 }}
        current={props.currentPageIndex + 1}
        pageSize={pageSize}
        total={props.questions.length}
        onChange={handlePageChange}
        block
      />
    </div>
  );
});
export default QuestionsList;
