import { Modal } from "antd";
import React, { Component } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import TrashIcon from "../../icons/TrashIcon";
import EditIcon from "../../icons/EditIcon";
import SchoolIcon from "../../icons/Schoolcon";
import { AppContext } from "../../../routes/AppContext";
import AsyncImage from "../../AsyncImage";

import { withTranslation } from 'react-i18next';
import certificat from "../../../media/image/certificates/certificat.jpeg"
class CertificateComponent extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      certificate: this.props.certificate,
      date: this.props.certificate.emissionDate,
    };
  }
  componentDidMount() {}
  render() {
    let certificate = this.props.certificate;
    let dateText = "";
    if (certificate?.emissionDate) {
      let emissionDate = moment(certificate.emissionDate).format("DD/MM/YYYY");
   
      dateText = emissionDate
    }
    return (
      <div
        className="border rounded m-2 p-2 d-flex justify-content-start"
        
      >
        <div className="align-self-start me-2">
        { console.log(certificate)}
        {certificate?.provider?<AsyncImage 
        // images/certificates from aws
    
        src={`${this.context.envVar.REACT_APP_CLIENT_URL}/images/certificates/${certificate?.provider?.toLowerCase()}.jpeg`}
        width="25px"
        className="m-1"
        // src={`/src/client/media/certificates/${title}.jpeg`}
         />
         :
         <AsyncImage 
         // images/certificates from local
         src={certificat}
         width="25px"
         className="m-1"
          />
        //  <SearchIcon fill="blue" width="2rem" />
         }
        </div>
        <div className="me-auto">
          <h5 className="" >
          {certificate?.certificateName}
            </h5>
          <i className="text-break text-primary ">{dateText}</i>
          <h6>{certificate?.reference}</h6>
        </div>
        <div className="">
          <i
            title={this.props.t("Modifier")}
            className=" text-success  m-1"
            role="button"
            onClick={() => {
              this.props.setModalVisible(true, this.props.index);
            }}
          >
            <EditIcon width="1.8rem" fill="green" />
          </i>
        </div>
        <div className="">
          <i
            title={this.props.t("Supprimer")}
            className=" text-danger m-1"
            role="button"
            onClick={() => {
              // alert("fassakh baba fassakh")
              Modal.confirm({
                title: this.props.t("Supprimer formation"),
                icon: <ExclamationCircleOutlined />,
                content:
                  this.props.t("voulez vous supprimer la formation") + certificate.certificateName,
                  okText: this.props.t("Supprimer"),
                  okType: "danger",
                  cancelText: this.props.t("Annuler"),
                onOk: () => {
                  let cv = this.context.user
                  cv.certificates.splice(this.props.index, 1);
                  this.context.setUser(cv)
                  this.props.updateView();
                },
                // onCancel:()=>{alert(index)}
              });
              //
            }}
          >
            <TrashIcon fill="red" width="1.5rem" />
          </i>
        </div>
      </div>
    );
  }
}

export default withTranslation()( CertificateComponent )