import { Progress, Table } from "antd";
import moment from "moment-timezone";

import { useTranslation } from "react-i18next";
import React from "react";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;
export default function ApplicationsTable(props) {
  const { t } = useTranslation();
  const dateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };
  return (
    <div>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
      <Table
        loading={props.loading}
        pagination={{ defaultPageSize: 5, showSizeChanger: false }}
        rowKey={(record) => {
          return record._id;
        }}
        dataSource={props.applications}
      >
        <Column
          align="center"
          sorter={(a, b) =>
            a.idJobPost[0].title.localeCompare(b.idJobPost[0].title)
          }
          title={t("Offre")}
          dataIndex="title"
          key="title"
          render={(text, application, index) => {
            return (
              <>
                <span>{application.idJobPost[0].title} </span>
              </>
            );
          }}
        />
        <Column
          align="center"
          sorter={(a, b) =>
            a.idJobPost[0].idCompany[0].name.localeCompare(
              b.idJobPost[0].idCompany[0].name
            )
          }
          title={t("Entreprise")}
          dataIndex="title"
          key="title"
          render={(text, application, index) => {
            return (
              <>
                <span>{application.idJobPost[0].idCompany[0].name} </span>
              </>
            );
          }}
        />
        <Column
          align="center"
          sorter={(a, b) => a.score - b.score}
          // responsive={["lg"]}
          title={t("Score")}
          dataIndex="score"
          key="score"
          render={(text, application, index) => {
            return (
              <>
                <span>
                  {" "}
                  <Progress
                    type="circle"
                    percent={parseInt(application.score * 100)}
                    format={(percent) => `${percent}%`}
                    width={55}
                    strokeColor={
                      parseInt(application.score * 100) < 30
                        ? "orange"
                        : "green"
                    }
                  />{" "}
                </span>
              </>
            );
          }}
        />
        <Column
          align="center"
          sorter={(a, b) => dateFromObjectId(a._id) - dateFromObjectId(b._id)}
          // responsive={["lg"]}
          title={t("Date postulation")}
          dataIndex="date"
          key="date"
          render={(text, application, index) => {
            let date = dateFromObjectId(application._id);
            return <>{moment(date).format("L")}</>;
          }}
        />
        <Column
          align="center"
          sorter={(a, b) => a.state - b.state}
          // responsive={["lg"]}
          onFilter={(value, record) => record.state == value}
          title={t("Status")}
          dataIndex="state"
          key="state"
          filters={[
            {
              text: t("En attente"),
              value: 0,
            },
            {
              text: t("Test"),
              value: 1,
            },
            {
              text: t("Entretien"),
              value: 1.5,
            },
            {
              text: t("Entretien planifié"),
              value: 2,
            },
            {
              text: t("Refusé"),
              value: 3,
            },
            {
              text: t("Accepté"),
              value: 4,
            },
          ]}
          render={(text, application, index) => {
            let state = application.state;
            let status = "";
            switch (state) {
              case 0:
                status = <> {t("En attente")}</>;
                break;
              case 1:
                status = <>{t("Test")}</>;
                break;
              case 1.5:
                status = <>{t("Entretien")}</>;
                break;
              case 2:
                status = <>{t("Entretien planifié")}</>;
                break;
              case 3:
                status = <>{t["Refusé"]}</>;
                break;
              case 4:
                status = <>{t("Accepté")}</>;
                break;
              default:
                break;
            }
            return <>{status}</>;
          }}
        />
      </Table>
      </ConfigProvider>
    </div>
  );
}
