import { notification, Rate,Select,Table,Modal, Divider, Space, Input, Typography, Tag, Card } from 'antd'

import { AddSkill } from '../../services/api/features/AddSkill';
import React, { Component } from 'react'
import { PlusOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';
import decrypt from '../../utils/decrypt';
import { WarningOutlined} from '@ant-design/icons';
import InfoIcon from '../icons/InfoIcon';
import { AppContext } from '../../routes/AppContext';
import { withTranslation } from 'react-i18next';

const { Column } = Table;

const { Option } = Select;
class SoftSkills extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
    score: 1,
    skill: "",
    key: null,
    update: false,
    error: "",
    name: "",
    firstTime: true,
    showAllSuggestion: false,

  };
}

  info = () => {
    this.setState({ firstTime: false });
    Modal.info({
      title: null,
      okText: this.props.t("ok"),
      content: (
        <div>
          <p>
            {" "}
            {this.props.t("L'atmosphère de travail compte pour vous! Veuillez choisir les compétences interpersonnelles les plus importantes que vos candidats ciblés doivent satisfaire.(maximum 5)")}
          </p>
        </div>
      ),

      onOk() {},
    });
  };

  onNameChange = (value) => {
    this.setState({ name: value });
  };
  setShowAllSuggestion = () => {
    this.setState({ showAllSuggestion:!this.state.showAllSuggestion });
  };
  addItem = async (e) => {
    e.preventDefault();
    if (this.state.name) {
      if (this.validation(this.state.name)) {
      let res = await AddSkill(
        { skill: this.state.name, feature: 1 },
        this.context.headers
      );
      if(res){
        let skill = {
          score: 0.2,
          soft_skill_fr: this.state.name,
          feature: 1,
        };
        let softSkills = this.props.softSkills;
        softSkills.push(skill);
        this.props.setSoftSkills(softSkills);}
      }
    }
  };
  handleChange = (value) => {
    this.setState({ score: value });
  };
  handleSelect = (value, i) => {
    this.addSkill(value, i.key);
  };
  validation = (skill) => {
    let isvalid = true;
    // let skill=this.state.skill
    let softSkills = this.props.softSkills;

    if (skill == "") {
      isvalid = false;
      notification.warn({
        description: this.props.t("veuillez choisir une compétence"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    } else if (softSkills.length >= 5) {
      isvalid = false;
      notification.warn({
        description: this.props.t("vous atteindre le maximum des compétences possibles"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    } else {
      for (let i = 0; i < softSkills.length; i++) {
        const element = softSkills[i];
        if (element.soft_skill_fr == skill) {
          isvalid = false;
          notification.warn({
            description: this.props.t("compétence déja utilisé"),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor: "#fff3cd",
              marginTop: "5rem",
            },
          });
        }
      }
    }

    return isvalid;
  };

  addSkillFromTags = (skill) => {
    if (this.validation(skill.soft_skill_fr)) {
      let softSkills = this.props.softSkills;
      softSkills.push(skill);
      this.props.setSoftSkills(softSkills);
    }
  };

  addSkill = (skill, key) => {
    if (this.validation(skill)) {
      let skill = this.context.softSkillsList[key];
      skill.score = 0.2;

      let softSkills = this.props.softSkills;
      softSkills.push(skill);
      this.props.setSoftSkills(softSkills);
    }
  };
  updateScore = (index, value) => {
    let softSkills = this.props.softSkills;
    softSkills[index].score = value / 5;
    this.props.setSoftSkills(softSkills);
  };
  render() {
    const { t,i18n } = this.props;
    const language=i18n.language 
    const softSkillLanguages={"fr":"soft_skill_fr","en":"soft_skill_en"}
    const softSkillLanguage=softSkillLanguages[language]
    let i = -1;
    let list = this.props.softSkills.map((skill) => {
      i++;
      let code = skill.code;
      let index = i;
      return (
        <div
          key={i}
          className="p-2 m-3 border row rounded  d-flex align-items-center"
        >
          <div className="col-4 text-center capitalize">
            {skill[softSkillLanguage]}
          </div>
          <div className="col-6 text-center">
            <Rate
              allowHalf
              onChange={(value) => this.updateScore(index, value)}
              value={skill.score * 5}
            />
          </div>
          <div className="col-2 text-center ">
            <i
              title={t("Supprimer")}
              className=" text-danger m-1"
              role="button"
              onClick={() => {
                Modal.confirm({
                  title: t("Supprimer compétence"),
                  icon: <ExclamationCircleOutlined />,
                  content:
                    t("voulez vous supprimer la compétence") +" "+
                    skill[softSkillLanguage],
                  okText: t("Supprimer"),
                  okType: "danger",
                  cancelText: t("Annuler"),
                  onOk: () => {
                    this.props.setSoftSkills(
                      this.props.softSkills.filter((item) => {
                        return item.soft_skill_fr !== skill.soft_skill_fr;
                      })
                    );
                    this.setState({ update: !this.state.update });
                  },
                });
              }}
            >
              <TrashIcon fill="red" width="1.5rem" />
            </i>
          </div>
        </div>
      );
    });

    const children = [];
    let softSkillsList = this.context.softSkillsList;
    for (let i = 0; i < softSkillsList.length; i++) {
      children.push(
        <Option key={i} value={softSkillsList[i].soft_skill_fr}>
          <div className="capitalize">{softSkillsList[i].other_ss}</div>
        </Option>
      );
    }
    let suggestedSoftSkills = this.props.suggestedSoftSkills;
    suggestedSoftSkills.sort((a, b) => b.occ - a.occ);
    if (!this.state.showAllSuggestion)
      suggestedSoftSkills = suggestedSoftSkills.slice(0, 10);
    suggestedSoftSkills = suggestedSoftSkills.map((skill) => {
      return (
        <span
          role="button"
          onClick={() => {
            this.addSkillFromTags(skill);
          }}
        >
          {" "}
          <Tag
            className="capitalize"
            color={
              skill.occ == 0 ? "geekblue" : skill.occ == 1 ? "cyan" : "magenta"
            }
          >
            {skill[softSkillLanguage]}
          </Tag>
        </span>
      );
    });
    return (
      <div>
        {/* <h4 className="text-primary" > Compétences techniques </h4> */}
        {/* <b> - Compétences interprsonnelles </b> */}
        {this.props.suggestedSoftSkills.length != 0 && (
          <>
            <br />{" "}
            <Card
              style={{
                maxHeight: "10rem",
                overflowY: "auto",
              }}
            >
              {suggestedSoftSkills}
              {this.props.suggestedSoftSkills.length > 10 && (
            <div className="d-flex justify-content-center" >
            <button  class="btn btn-primary" onClick={this.setShowAllSuggestion} >
              {t("Voir Plus..")}
        </button>
            </div>
              )}
            </Card>
          </>
        )}
        <div className=" m-3">
          <Select
            className=" w-50"
            // style={{ width: '40%' }}
            showSearch
            placeholder={t("Rechercher pour sélectionner")}
            onChange={this.handleSelect}
            onSearch={this.onNameChange}
            notFoundContent={
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Typography.Link
                    onClick={this.addItem}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <PlusOutlined /> {t("Ajouter")}
                  </Typography.Link>
                </Space>
              </>
            }
          >
            {children}
          </Select>
          <span onClick={this.info} role="button">
            <InfoIcon className="zoom1 m-1" fill="blue" width="1.4rem" />{" "}
          </span>

          {/* <span  className="m-3" >
                <Rate  allowHalf onChange={this.handleChange} value={this.state.score}  defaultValue={1} />
                </span>   */}
          {/* <span className="m-3">
                <button
               onClick={this.addSkill} 
               style={{ borderRadius: "50%", width: "35px", height: "35px" }}

               className='btn btn-primary  p-0 m-2 '>
            <i className="">
            <PlusIcon fill="white" width="1.5rem" />

            </i>
                </button>
        </span> */}
        </div>
        <div>
          <div className="mx-5">{list}</div>
        </div>
      </div>
    );
  }
}
export default  withTranslation()(SoftSkills)