import React, { Component } from 'react'
import {  notification } from 'antd';
import { CancelAppointmentService } from '../../services/api/appointments/CancelAppointmentService';
import { withRouter } from 'react-router';

import { AppContext } from '../../routes/AppContext';

class CancelAppointment extends Component {
  static contextType = AppContext;

    constructor(props){
        super(props);
        
    } 
   async  componentDidMount(){
        let token =this.props.match.params.token
        let res = await CancelAppointmentService({token:token},this.context.headers)
    if(res){
        notification.open({
            description:res.data.message,
            className: 'custom-class mt-5',
            style: {
                width: 600,
                border: "2px solid black",
                marginTop:"5rem"
              },
          });
          res?.data?.message &&   this.props.history.push('/')}
    
    
  
    }
    
    render() {
        return (
            <div>

            </div>
        )
    }
}
export default withRouter(CancelAppointment)
