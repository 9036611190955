import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../routes/AppContext'
import { GetCompanyEmails } from '../../services/api/companyEmail/GetCompanyEmails'
import { GetDefaultEmails } from '../../services/api/companyEmail/GetDefaultEmails'
import decrypt from '../../utils/decrypt'
import CompanyEmailForm from './CompanyEmailForm'
import CompanyEmails from './CompanyEmails'
import DefaultEmails from './DefaultEmails'
import EmailsLanguageSelector from './EmailsLanguageSelector'
import EmailsNav from './EmailsNav'
import EmailViewer from './EmailViewer'

export default function CompanyEmailsContainer(props) {
  const { companyName,envVar,headers } = useContext(AppContext);
  const { t,i18n } = useTranslation();

    const [emails,setEmails]=useState([])
    const [defaultEmails,setDefaultEmails]=useState([])
    const [showDefaultEmails,setShowDefaultEmails]=useState(false)
    const [loading,setLoading]=useState(true)
    const [modalVisible,setModalVisible]=useState(false)
    const [modalFormVisible,setModalFormVisible]=useState(false)
    const [email,setEmail]=useState(null)
    const [emailCompany, setEmailCompany] = useState([])
    const [modifiedd, setModifiedd] = useState(false)
    const [emailFr, setEmailFr] = useState([])
    const [emailEn, setEmailEn] = useState([])
    useEffect(async()=>{
        let res=await GetCompanyEmails({},headers)
    if(res){

        let data = JSON.parse(decrypt(res.data));
        data=data.map((r)=>{r.modified=true;return r})
        
        let res2=await GetDefaultEmails({},headers)
      let data2 = JSON.parse(decrypt(res2.data));
      for (let i = 0; i < data2.length; i++) {
        let body=data2[i].emailBody.split("${companyName}").join(companyName)
        body=body.split("${jobPostTitle}").join("'titre offre'")
        body=body.split("${CLIENT_ORIGIN}").join(envVar.REACT_APP_CLIENT_URL)

        let object=data2[i].emailObject.split("${companyName}").join(companyName)


        data2[i].emailBody=body
        data2[i].emailObject=object
      }
      let mergedData=[...data2]
      setEmailCompany(data)
      setDefaultEmails(data2)
      // for (let i = 0; i < data.length; i++) {
      //   let eType=data[i].eType
      //   let index=mergedData.findIndex((e)=>e.eType==eType)
      //   mergedData[index]=data[i]
      // }
      // setEmails(mergedData)
      setLoading(false)}
    },[modifiedd])


  return (
    <div>
        <h3 className="titrePage my-1"> {t("Consulter et pérsonnaliser vos emails")}</h3>
        
      <EmailsLanguageSelector/>
      <CompanyEmails setModalFormVisible={setModalFormVisible} setModalVisible={setModalVisible} setEmail={setEmail} emails={emails}setEmails={setEmails}  loading={loading} emailCompany={emailCompany} defaultEmails={defaultEmails} emailFr={emailFr} setEmailFr={setEmailFr} emailEn={emailEn} setEmailEn={setEmailEn} />
       {modalVisible&& <EmailViewer modalVisible={modalVisible} email={email} setModalVisible={setModalVisible} />}
       {modalFormVisible&& <CompanyEmailForm modalVisible={modalFormVisible} email={email} setModalVisible={setModalFormVisible}  defaultEmails={defaultEmails}  emails={emails} setEmails={setEmails}  setModifiedd={setModifiedd} />}
    </div>
  )
}
