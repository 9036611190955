import React, { Fragment, useEffect, useContext, useState } from "react";
import Otp from "./Opt";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { notification } from "antd";
import decrypt from "../../utils/decrypt";
import { AppContext } from "../../routes/AppContext";




const alert1 = (msg, type) => (
  <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
);
class Appsms extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      pno: "",
      otpShow: false,
      otp: "",
      tel: "",
      email: "",
      error: false,
    };
  }

  isNumeric(n) {
    return !isNaN(parseInt(n)) && isFinite(n);
  }

  // Fonction envoyer code verification
  _getCode = async () => {
    const e = this.state.code + this.state.pno;
    await axios
      .post(`/api/pricing/order/confirm`, {
        email: this.state.email,
        companyName:this.context.companyName
      }, {
        headers: this.context.headers
      })
      .then((res) => {
        let code = JSON.parse(decrypt(res.data)).code
        this.setState({ otpShow: true, code: code });
      })
      .catch((err) =>{ 
      });
  };

  // Fonction verifier le code de verification saisi
  _verifyCode = async () => {
    if (this.state.otp == this.state.code) {
      //notification
      notification.success({
        description: "Code vérifié",
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor:"#d4edda",
          marginTop:"5rem"
        },
      });
      this.setState({ error: "" });
      this.props.history.push("/company/checkout/2");
    } else {
      this.setState({ error: "code incorrect!" });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="w-full px-4 py-2 md:order-last">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto bg-gray-100">
            <div className="absolute inset-0 bg-blue-600  shadow-lg transform -skew-y-8  rotate-48 sm:skew-y-8 sm:-rotate-20 sm:rounded-3xl"></div>
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
              <div className="max-w-md mx-auto">
                <div>
                  <div className="p-4 md:p-8">
                    <br />
                    {/* Affiche Recuperer code promo composant ou verification code sms composant selon le state.otpShow */}
                    {!this.state.otpShow ? (
                      <div className="hidden lg:block col-span-1 flex  mb-16">
                        <div>
                          <span
                            className="h-20 w-20 p-4 rounded-full tracking-widest text-black"
                            style={{ backgroundColor: "#FFe436" }}
                          >
                            {" "}
                            01{" "}
                          </span>
                          <span className="text-2xl ml-4 font-semibold">
                            Récupérer le code
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="hidden lg:block col-span-1 flex  mb-16">
                        <div>
                          <span
                            className="h-20 w-20 p-4 rounded-full tracking-widest text-black"
                            style={{ backgroundColor: "#FFe436" }}
                          >
                            {" "}
                            02{" "}
                          </span>
                          <span className="text-2xl ml-4 font-semibold">
                            Vérification par Email
                          </span>
                        </div>
                      </div>
                    )}
                    {this.state.error ? alert1(this.state.error, "red") : ""}
                    {!this.state.otpShow ? (
                      <h3>Faire passer votre email!</h3>
                    ) : (
                      <h3>Entrez le code OTP, ici!</h3>
                    )}
                    <br />
                    {this.state.otpShow ? (
                      <p>
                        Vous obtenez un seul code pour la vérification par
                        Email.
                      </p>
                    ) : null}
                    <br />
                    <div>
                      {!this.state.otpShow ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "auto",
                          }}
                        >
                          <br />
                          <div className="ml-2">
                            <label>email</label>
                            <input
                              id="email"
                              label="email"
                              color="secondary"
                              value={this.state.email}
                              type="email"
                              className="ml-8 px-4 py-2 w-full border focus:outline-none"
                              onChange={(e) => {
                                this.setState({ email: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <Otp
                          otp={this.state.otp}
                          setOtp={(val) => this.setState({ otp: val })}
                        />
                      )}

                      <br />
                      {this.state.otpShow ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 5,
                            marginLeft: 4,
                          }}
                        >
                          Vous n'avez pas reçu d'OTP ?{" "}
                          <div
                            onClick={() => this._getCode()}
                            className="text-blue-600 cursor-pointer ml-2"
                            style={{ fontSize: 15 }}
                          >
                            Renvoyer OTP
                          </div>
                        </div>
                      ) : null}

                      {!this.state.otpShow ? (
                        <span className="flex ml-2 p-4 bg-indigo-200">
                          Par saisi de votre mail, vous recevez un email
                          contenant un code de vérification. Merci de confirmer
                          l'envoi pour passer à l'étape suivante!
                        </span>
                      ) : null}

                      <br />
                      <br />
                      <div className="">
                        <div
                          onClick={() => {
                            let email = this.state.email;
                            if (this.state.otpShow) {
                              this._verifyCode();
                              //email validation
                            } else if (email == "") {
                              this.setState({ error: "champ obligatoire" });
                            } else if (
                              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                email
                              )
                            ) {
                              this.setState({ error: "email non valide" });
                            } else {
                              this._getCode();
                              this.setState({ otpShow: true, error: "" });
                            }
                          }}
                          className=" text-white btn btn-primary rounded mx-3"
                          // style={{ background: "#005bea" }}
                        >
                          {" "}
                          Confirmer
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Appsms);
