import axios from "../../../interceptors/axios"

export const GetTests = async (data,headers)=> {
    try {
      let res=await  axios.get('/companyTest/getTests',
  {
    headers: headers
  })
  return res
  }
 catch (err) {
    }
  }