import React, { Component } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Dropdown,
  Button,
  Card,
  notification,
  Badge,
  Tag,
} from "antd";
import { Link, Redirect, withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";

// import logo from "../../media/image/recin-transparent.webp";
// import { logo } from "./Logo";
import logoText from "../../media/image/rec-text-blan.png";
import logo from "../../media/image/rec-logo-blan.png";
import {
  DesktopOutlined,
  ContainerOutlined,
  UploadOutlined,
  ArrowLeftOutlined,
  UserOutlined,
  UnorderedListOutlined,
  ContactsOutlined,
  FileProtectOutlined,
  RightOutlined,
  LeftOutlined,
  SettingOutlined,
  LogoutOutlined,
  FieldTimeOutlined,
  BoldOutlined,
  HomeOutlined,
  DollarOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  AuditOutlined
} from "@ant-design/icons";
import BlogIcon from "../icons/BlogIcon";
import BellIcon from "../icons/BellIcon";
import HeartIcon from "../icons/HeartIcon";
import CartShoppingIcon from "../icons/CartShoppingIcon";
import HandHoldingDollarIcon from "../icons/HandHoldingDollarIcon";
import { AppContext } from "../../routes/AppContext";
import CartModal from "../pricing/CartModal";
import AsyncImage from "../AsyncImage";
import { GetCompanyNotification } from "../../services/api/notification/GetCompanyNotification";
import NotificationDropdown from "../notification/NotificationDropdown";
import decrypt from "../../utils/decrypt";
import { withTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import SideBarMenu from "../../routes/SideBarMenu";
// import beta from "../../media/image/VERSION_BETA.png"

// import BlogIcon from "../icons/BlogIcon"
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class CompanyNavbar extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      cartModalOpen: false,
      notifications: [],
      logoSelected : logoText
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    if(window.innerWidth <992)
      this.setState({ logoSelected:logo})
    else
    this.setState({ logoSelected:logoText})
  }
  RedirectMe = (e) => {
    switch (e.key) {
      case "s01":
        this.props.history.push("/company/profile");
        break;
      case "s02":
        this.props.history.push("/company/contact");
        break;
      case "2":
        this.props.history.push("/company/manageResponsible");
        break;
      case "3":
        this.props.history.push("/company/manageJobPosts");
        break;
      case "4":
        this.props.history.push("/company/uploadOffre");
        break;
      case "5":
        this.props.history.push("/company/addJobPost");
        break;
      case "s61":
        this.props.history.push("/company/addTest");
        break;
      case "s62":
        this.props.history.push("/company/manageTests");
        break;
      case "7":
        this.props.history.push("/company/dashboard");
        break;
      case "s81":
        this.props.history.push("/company/pricing");
        break;
      case "s82":
        this.props.history.push("/company/orders");
        break;
      case "9":
        this.props.history.push("/company/smartContract/acceptedCandidates");
        break;
      case "10":
        this.props.history.push("/company/extract-emails");
        break;
        case "11":
          this.props.history.push("/company/extract-cvFiles");
          break;
          case "12":
          this.props.history.push("/company/CandidateParsing");
          break;
      default:
        break;
    }
  };
  // componentDidMount() {
  //   let company = this.props.company;
  //   if (
  //     company.description == "" ||
  //     company.employeesNumber == "" ||
  //     company.employeesNumber == null ||
  //     company.sector == ""
  //   ) {
  //     this.props.history.push("/company/profile");
  //   } else if (company.phone == "" || company.address == "") {
  //     this.props.history.push("/company/contact");
  //   }
  // }
  MenuButton = (props) => (
    <div className="fixed-bottom w-25 z-500 ">
      <button
        className=" MenuButton    btn-lg rounded "
        style={{
          width: props.collapsed ? "5rem" : "15rem",
          outline: "none",
          backgroundColor: "#1565c0 ",
        }}
        onClick={this.onCollapse}
      >
        {props.collapsed ? (
          <RightOutlined className="text-white" />
        ) : (
          <LeftOutlined className="text-white" />
        )}
      </button>
    </div>
  );
  getCompanyNotifications = async () => {
    if (
      this.context.notificationsNumber > 0 ||
      this.state.notifications.length == 0
    ) {
      let response = await GetCompanyNotification(null, this.context.headers);
      if (response) {
        let notifications = JSON.parse(decrypt(response.data));
        this.setState({ notifications });
        this.context.setNotificationsNumber(0);
      }
    }
  };
  onCollapse = (collapsed) => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  setCartModalOpen = (bool) => {
    this.setState({ cartModalOpen: bool });
  };

  
 
  render() {
    const { cart, setCart, notificationsNumber } = this.context;
    const { collapsed } = this.state;
    const { t } = this.props;
  const  menu = (
      <Menu style={{ width:"160px"}} >
        <Menu.Item key="1" icon={<BoldOutlined />}>
          <Link to="/blog">{this.props.t("Blogs")}</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<SettingOutlined />}>
          <Link to="/company/setting">{this.props.t("Paramètres")}</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<DollarOutlined />}>
          <Link to="/company/pricing">{this.props.t("Tarification")}</Link>
        </Menu.Item>
  
        <Menu.Item key="4" icon={<LockOutlined />}>
          <Link to="/company/politique-confidentialite">{this.props.t("politique...")}</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<QuestionCircleOutlined />}>
          <Link to="/company/faq">{this.props.t("FAQ")}</Link>
        </Menu.Item>
  
        <Menu.Item key="5" icon={<LogoutOutlined />}>
          <Link to="/logout">{this.props.t("Déconnecter")}</Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        {this.props.company.role == "company" ? (
          <div id="navbar" className="navbar-company">
            <Layout>
              <div className="shadow-lg">
                <nav className="navbar navbar-expand-lg navbar-dark">
                  <Link to="/company/dashboard">
                    <div className="navbar-brand ml-2">
                      <AsyncImage
                        alt="Rec-INOV"
                        src={this.state.logoSelected}
                        className="m-1 "
                        style={{ height: "3rem" }}
                      />
                      {/* <b className="d-none d-lg-inline  ">Rec-INOV</b> */}
                    </div>
                  </Link>

                  {/* <div className="m-0 d-lg-none">
                <Link to="/company/blog">
                  <div
                    className=" p-1 btn  "
                    style={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <i className="  text-white">
                      <BlogIcon fill="white" width="20px" />
                    </i>
                  </div>
                </Link>
           
              </div> */}

                  {/* <div className="m-0 d-lg-none ">
                <Link to="/company/pricing">
                  <div
                    className=" p-1 btn   "
                    style={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <HandHoldingDollarIcon fill="white" width="25px" />
                  </div>
                </Link>
           
              </div> */}
               <div className="d-lg-none m-0">
                    <LanguageSelector/>
                  </div>
                  <div className="d-lg-none m-0">
                    <NotificationDropdown
                      notifications={this.state.notifications}
                    >
                      <div
                        onClick={this.getCompanyNotifications}
                        className=" p-1 btn   "
                        style={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <Badge
                          style={{ boxShadow: "0 0 0 1px red" }}
                          size="small"
                          count={notificationsNumber}
                        >
                          <BellIcon fill="white" width="20px" />
                        </Badge>
                      </div>
                    </NotificationDropdown>
                  </div>
                  <div className="d-lg-none ">
                    <div
                      onClick={() => this.setCartModalOpen(true)}
                      className=" p-1 btn  "
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Badge
                        style={{ boxShadow: "0 0 0 1px red" }}
                        size="small"
                        count={cart.length}
                      >
                        <CartShoppingIcon fill="white" width="20px" />
                      </Badge>
                      {/* <ShoppingCartOutlined /> */}
                    </div>
                  </div>

                  {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button> */}
                  <Link to="">
                    <div className="d-lg-none m-2">
                      <Dropdown
                        overlay={menu}
                        placement="bottom"
                        trigger={["click"]}
                      >
                        <div
                          onClick={(e) => e.preventDefault()}
                          className=" p-1 btn   ant-dropdown-link  icons15"
                        >
                          {this.props.company.image ? (
                            <AsyncImage
                              className="logo rounded-circle"
                              alt="recinov"
                              src={
                                this.context.envVar.REACT_APP_CLIENT_URL +
                                "/company/images/" +
                                this.props.company.image
                              }
                            />
                          ) : (
                            <UserOutlined />
                          )}
                        </div>
                      </Dropdown>
                    </div>
                  </Link>

                  <div className="collapse navbar-collapse" id="navbarText">
                    <ul
                      // className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
                      className="navbar-nav mr-auto"
                    >
                      <li className="d-lg-none">
                        <Link to="/logout">
                          <span className="navbar-text  ">
                            {" "}
                            <div className="nav-link">
                              {t("Déconnecter")}
                            </div>{" "}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="m-0 d-none d-lg-inline m-2">
                    <LanguageSelector/>
                  </div>
                  <div className="m-0 d-none d-lg-inline m-2">
                    <NotificationDropdown
                      notifications={this.state.notifications}
                    >
                      <div
                        className=" p-1 btn   "
                        style={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                        }}
                        onClick={this.getCompanyNotifications}
                      >
                        <Badge
                          style={{ boxShadow: "0 0 0 1px red" }}
                          size="small"
                          count={notificationsNumber}
                        >
                          <BellIcon fill="white" width="20px" />
                        </Badge>
                      </div>
                    </NotificationDropdown>
                  </div>
                  <div className="d-none d-lg-inline m-2">
                    <div
                      onClick={() => this.setCartModalOpen(true)}
                      className=" p-1 btn   "
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Badge
                        style={{ boxShadow: "0 0 0 1px red" }}
                        size="small"
                        count={cart.length}
                      >
                        <CartShoppingIcon fill="white" width="20px" />
                      </Badge>
                    </div>
                  </div>

                  <Dropdown
                    overlay={menu}
                    placement="bottom"
                    trigger={["click"]}
                  >
                    <div className=" m-2 btn  rounded-pill p-1 d-none d-lg-inline  ">
                      <div className="rounded-circle p-1 btn  icons15">
                        {this.props.company.image ? (
                          <AsyncImage
                            className="logo rounded-circle"
                            alt="recinov"
                            src={
                              this.context.envVar.REACT_APP_CLIENT_URL +
                              "/company/images/" +
                              this.props.company.image
                            }
                          />
                        ) : (
                          <UserOutlined />
                        )}
                      </div>
                      <b className="text-white d-none d-lg-inline m-0">
                        {this.props.company.name}
                      </b>
                    </div>
                  </Dropdown>
                </nav>
              </div>
              <SideBarMenu/>
              <div className=" d-lg-none">
                {/* <Menu
                  mode="horizontal"
                  // defaultSelectedKeys={["1"]}
                  // defaultOpenKeys={["1"]}
                  onClick={(e) => this.RedirectMe(e)}
                  className="p-2 d-flex justify-content-around"
                >
                  <Menu.Item key="7" icon={<HomeOutlined />}>
                    <div></div>
                  </Menu.Item>{" "}
                  <SubMenu
                    className=""
                    key="1"
                    icon={<DesktopOutlined />}
                    title=""
                  >
                    <Menu.Item key="s01">
                      <FileProtectOutlined /> {t("Profil")}
                    </Menu.Item>

                    <Menu.Item key="s02">
                      <ContactsOutlined />  { t("Contact")}
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="2" icon={<UserOutlined />}>
                    <div></div>
                  </Menu.Item>
                  <SubMenu
                    className=""
                    key="s3"
                    icon={<UnorderedListOutlined />}
                    title=""
                  >
                    <Menu.Item key="3">
                      {" "}
                      <UnorderedListOutlined /> {t("Liste Offres")}
                    </Menu.Item>

                    <Menu.Item key="4">
                      <UploadOutlined />  {t("Upload Offre")}
                    </Menu.Item>
                    <Menu.Item key="5">
                      <ContainerOutlined />  {t("Ajouter Offre")}
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="11" icon={<FileProtectOutlined />}>
                    </Menu.Item>{" "}
                  <SubMenu
                    className=" subMenu"
                    key="6"
                    icon={<ContainerOutlined />}
                    title=""
                  >
                    <Menu.Item key="s62">
                      {" "}
                      <UnorderedListOutlined /> {t("Liste Tests")}
                    </Menu.Item>
                    <Menu.Item key="s61">
                      <ContainerOutlined /> {t("Créer Test")}
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="9" icon={<FileProtectOutlined />}>
                    <div></div>
                  </Menu.Item>{" "}
                  //<Menu.Item key="10" icon={<FileProtectOutlined />}>
                   // <div></div>
                  //</Menu.Item>{" "} 
                </Menu> */}
              </div>
              <Layout>
                <Sider
                  collapsed={collapsed}
                  onCollapse={this.onCollapse}
                  breakpoint="lg"
                  width={"15rem"}
                  collapsedWidth={"5rem"}
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "4px 0 2px -2px white",
                  }}
                  className="site-layout-background  d-none d-lg-inline"
                >
                  <Menu
                    mode="inline"
                    // defaultOpenKeys={["1"]}
                    className="MenuSider"
                    onClick={(e) => this.RedirectMe(e)}
                  >
                    <Menu.Item key="7" icon={<HomeOutlined />}>
                      <div>{t("Tableau de bord")}</div>
                    </Menu.Item>{" "}
                    <SubMenu
                      className=""
                      key="1"
                      icon={<DesktopOutlined />}
                      title={t("Coordonnées")}
                    >
                      <Menu.Item key="s01">
                        <FileProtectOutlined /> { t("Profil")}
                      </Menu.Item>

                      <Menu.Item key="s02">
                        <ContactsOutlined />
                          { t("Contact")}
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="2" icon={<UserOutlined />}>
                      <div>{t("Responsables")}</div>
                    </Menu.Item>{" "}
                    <SubMenu
                      className=""
                      key="s3"
                      icon={<UnorderedListOutlined />}
                      title={t("Offres")}
                    >
                      <Menu.Item key="5">
                        <ContainerOutlined />
                         {t("Créer une offre via le formulaire")}
                      </Menu.Item>
                      <Menu.Item key="4">
                        <UploadOutlined />
                         {t("Télécharger l'offre")}
                      </Menu.Item>
                      <Menu.Item key="3">
                        {" "}
                        <UnorderedListOutlined />
                         {t("Liste des offres..")}
                      </Menu.Item>

                    </SubMenu> 
                    <span className="changing-color-element ms-5" >{t("Nouveau")}</span>
                    <Menu.Item key="11" icon={<FileProtectOutlined />}>
                    {t("Télécharger votre CVthèque")}  &nbsp;
                    </Menu.Item>{" "}
                    <SubMenu
                      className=" subMenu"  
                      key="6"
                      icon={<ContainerOutlined />}
                      title={t("Test Compétence")}
                    >
                      <Menu.Item key="s62">
                        {" "}
                        <UnorderedListOutlined />
                        {t("Liste Tests")}
                      </Menu.Item>
                      <Menu.Item key="s61">
                        <ContainerOutlined />
                         {t("Créer Test")}
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="9" icon={<FileProtectOutlined />}>
                      <div>{t("Liste des candidats acceptés.")}</div>
                    </Menu.Item>
                    <Menu.Item key="12" icon={<AuditOutlined />}>
                      <div>{t("Liste des CV téléchargés")}</div>
                    </Menu.Item>{" "}
                    {/* <Menu.Item key="10" icon={<FileProtectOutlined />}>
                      <div>Extracter des emails</div>
                    </Menu.Item>{" "} */}
                    <this.MenuButton collapsed={collapsed} />
                  </Menu>
                </Sider>
                <Layout style={{ padding: "" }} className="sectionIN">
                  <Content
                    className="site-layout-background company-bg"
                    style={{
                      padding: 24,
                      margin: 0,

                      // backgroundImage: "url('../../media/backgroundImage/profil-contact-e.jpg')"
                    }}
                  >
                    <div style={{ position: "relative" }} className="">
                      <span
                        role="button"
                        className="icons15"
                        onClick={() => this.props.history.goBack()}
                      >
                        <ArrowLeftOutlined />
                      </span>
                      {/* <AsyncImage
                        className="aaa"
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          top: " -14px",
                          right: "-26px",
                          width: "10rem",
                        }}
                        src={beta}
                      /> */}
                    </div>

                    <Card className="m-3 rounded shadow bg-white" style={{}}>
                      {this.props.children}
                    </Card>
                  </Content>
                  {/* </div> */}
                </Layout>
              </Layout>
            </Layout>
            <div className="cart-modal">
              <CartModal
                open={this.state.cartModalOpen}
                setCartModalOpen={this.setCartModalOpen}
              />
            </div>
          </div>
        ) : (
          <>{this.props.children}</>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(CompanyNavbar));
