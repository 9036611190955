import React, { useState } from 'react'
import GenerateTestLink from './PublicLink'
import SendTestWithEmail from './SendTestWithEmail'
import SendTestNavbar from './SendTestNavbar'

export default function SendTest(props) {
  const [method, setMethod] = useState(0);

  return (
    <div>
      <SendTestNavbar method={method} setMethod={setMethod} />

      {method==0 &&<GenerateTestLink test={props.test} />}
      {method==1 &&<SendTestWithEmail setModalVisible={props.setModalVisible}  test={props.test} />}
      </div>
  )
}
