import React, { Fragment } from "react";
// import Layout from "../layout";
// import { Footer, Navber } from "../partials";
import { CheckoutComponent } from "./CheckoutProducts";

const CheckoutPage = (props) => {
  return (
    <Fragment>
      <div className="flex-grow cart-modal">
        <CheckoutComponent />
      </div>{" "}
    </Fragment>
  );
};

export default CheckoutPage;
