import axios from "../../../interceptors/axios"

export const GetCompanyProfile = async (data,headers) => {
    try {
   let res=await   axios.get("/api/profile/company/get", {
        headers:headers
      },{ withCredentials: true })
      return res
} catch (err) {
    }
  }