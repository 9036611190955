import React from "react";
import CompanySignin from "../../components/signin/CompanySignin";


export default function CompanySigninView(props) {
    return (
        <main  className="CompanySigninPage container-fluid"> 
         
          <CompanySignin />
        </main>
    )
}
