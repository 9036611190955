import React, { useEffect, useMemo, useRef, useState } from "react";
import { Map, TileLayer, Marker } from "react-leaflet-universal";
// import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "../../media/image/marker.png";

// delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require("../../media/image/marker.png"),
//     iconUrl: require("../../media/image/marker.png"),
//     shadowUrl: require("../../media/image/marker.png")
// });

export default function MapComponent(props) {
  const [currentPos, setCurrentPos] = useState(props.localization);
  // useEffect(() => {
  //   const address=JSON.parse(sessionStorage.getItem("user")).adress
  //     if(address!==null){setCurrentPos(address) }
  // }, [])
  useEffect(() => {
    const L = require("leaflet");
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("../../media/image/markerMap.png"),
      iconUrl: require("../../media/image/markerMap.png"),
      shadowUrl: require("../../media/image/marker.png"),
    });
  }, []);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setCurrentPos(marker.getLatLng());
          props.setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  const currentLocation = { lat: 34.5, lng: 11 };
  const zoom = 5.75;
  return (
    <div className="h-100" style={{height:""}}>
      <div className="border rounded-top h-100"  id="leafletmap">
        <Map className="w-100 h-100 " center={currentLocation} zoom={zoom}>
          {/* <TileLayer
              url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
          /> */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution=""
          />
          {currentPos && (
            <Marker
              eventHandlers={eventHandlers}
              position={currentPos}
              ref={markerRef}
              // icon={new Icon({iconUrl: markerIconPng, iconSize: [35, 35], iconAnchor: [12, 41]})}
              // onClick={(e)=>{console.log(e);}}
            >
              {/* <Popup position={currentPos}>
  Current location: <pre>{JSON.stringify(currentPos, null, 2)}</pre>
</Popup> */}
            </Marker>
          )}
          {/* <MyComponent /> */}
        </Map>
      </div>
    </div>
  );
}
