import { Button, notification, Steps } from "antd";
import React, { useContext, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import axios from "axios";

import Refused from "./Refused";
import {
  ExceptionOutlined,
  ContactsOutlined,
  CloseSquareOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Test from "./Test";
import Interviews from "./Interviews";
import Accepted from "./Accepted";
import { UpdateApplications } from "../../../services/api/application/UpdateApplications";
import { AppContext } from "../../../routes/AppContext";
import { useTranslation } from "react-i18next";
import { JobPostContext } from "../../dashboard/jobPostDashboard/JobPostContext";
const { Step } = Steps;
function ConfirmationModal(props) {
  const { user, socket,headers } = useContext(AppContext);
  //context userId w companyName
  const history = useHistory();
  const {jobPost} = useContext(JobPostContext);

  const [step, setStep] = useState(0);
  const [interviews, setInterviews] = useState(props.interviews);
  const [idTest, setIdTest] = useState(jobPost.idTest);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // const [update,setUpdate]=useState(false)
  // const interviewsUpdate=(interviews)=>{
  //   setInterviews(interviews)
  // setUpdate(!update)
  // }
  const validation =()=>{
    let valid=true
    //interviews validation
    if(props.applications[2].length>interviews.length){
      valid=false
      setStep(1)
      notification.warn({
        description: "Veuiller ajouter au moins "+props.applications[2].length+" dates",
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
    }
    //test validation
    if(props.applications[1].length&&!idTest){
      valid=false
      setStep(0)
      notification.warn({
        description: "Veuiller ajouter un test",
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
    }
    return valid
  }
  let next = () => setStep(step + 1);
  let previous = () => setStep(step - 1);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if(validation()){
      setLoading(true)
    let applications = { 0: {}, 1: {}, 2: {}, 3: {}, 4: {} };
    let notifications = [];
    for (let i = 0; i < 5; i++) {
      props.applications[i].map((item) => {
        let obj = {};
        let id = item.idCandidate;
        obj[id] = item.candidate[0].email;
        Object.assign(applications[i], obj);
        if (i > 0) {
          notifications.push({
            idCandidate: item.idCandidate,
            idJobPost: props.idJobPost,
            type: i,
            seen: false,
          });
          socket.emit("sendNotification",
    {receiverId:item.idCandidate,type:i}
    );
        }
      });
    }
    let companyName = user.role == "company" ? user.name : user?.company?.name;
    let res = await UpdateApplications(
      {
        
        applications: applications,
        interviews: interviews,
        companyName: companyName,
        idJobPost: props.idJobPost,
        jobPostTitle: props.jobPostTitle,
        startMissionDate: props.startMissionDate,
        notifications: notifications,
        idTest: idTest,
      },
      headers
    );
    if(res?.data){
    //notification
    notification.success({
      description: res.data.message,
      className: "custom-class",
      style: {
        width: 600,
        backgroundColor: "#d4edda",
        marginTop: "5rem",
      },
    });
    history.push("/" + user.role + "/manageJobPosts");}
  
  }
  };
  return (
    <div>
      <Steps current={step}>
        <Step title={t("Test")} icon={<ExceptionOutlined />} />
        <Step title={t("Entretiens")} icon={<ContactsOutlined />} />
        <Step title={t("Refusé")} icon={<CloseSquareOutlined />} />
        <Step title={t("Accepté")} icon={<CheckCircleOutlined />} />
      </Steps>
      <div className="overflow-y-75">
        {step == 0 && (
          <div className="m-3">
            <Test
              setIdTest={setIdTest}
              idJobPost={props.idJobPost}
              userId={props.userId}
              applications={props.applications[1]}
            />
          </div>
        )}
        {step == 1 && (
          <div className="m-3">
            <Interviews
              applications={props.applications[2]}
              interviews={interviews}
              setInterviews={setInterviews}
            />
          </div>
        )}
        {step == 2 && (
          <div className="m-3">
            <Refused applications={props.applications[3]} />
          </div>
        )}
        {step == 3 && (
          <div className="m-3">
            <Accepted applications={props.applications[4]} />
          </div>
        )}
      </div>
      <br />
      <br />
      <div className="">
        {step < 3 && (
          <button
            onClick={next}
            className="btn btn-primary position-absolute bottom-0 end-0 m-2"
          >
            Suivant
          </button>
        )}
        {step > 0 && (
          <button
            onClick={previous}
            className="btn btn-secondary position-absolute bottom-0 start-0 m-2"
          >
            Précédent
          </button>
        )}
        {step == 3 && (
         
          <Button  className="position-absolute bottom-0 end-0 m-2" type="primary" loading={loading} onClick={handleSubmit}>
          Valider toutes les actions
</Button>
        )}
      </div>
    </div>
  );
}
export default withRouter(ConfirmationModal);
