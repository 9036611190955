import React, { Component } from "react";
import { AutoComplete, DatePicker, Modal, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { AppContext } from "../../../routes/AppContext";
import AsyncImage from "../../AsyncImage";

import { withTranslation } from 'react-i18next';
class CertificateForm extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    let certificate = this.props.certificate
      ? this.props.certificate
      : {};
    this.state = {
      certificateName: certificate.certificateName ? certificate.certificateName : "",
      reference: certificate.reference ? certificate.reference : "",
      emissionDate: certificate.emissionDate ? certificate.emissionDate : null,
      expirationDate: certificate.expirationDate ? certificate.expirationDate : null,
      // currently:
      //   (certificate.emissionDate != null) & (certificate.expirationDate == null)
      //     ? true
      //     : false,

      //  referencesList:context.referencesList,
      certificateNameError: "",
      referenceError: "",
      emissionDateError: "",
      expirationDateError: "",
    };
  }
  handleSelect = (value, i) => {
    let str = `${value}`;
    let list = str.split(",");

    this.setState({ reference: list[list.length - 1] });
  };
  onNameChange = (value) => {
    this.setState({ reference: value });
  };
  disabledDate = (current) => {
    return current > moment(this.context.serverTime);
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  setCertificateName=(certificate)=>{
    let certificateName=certificate.split("**")[0]
    this.setState({certificateName})
  }
  validation = () => {
    let isvalid = true;
    let certificateName = this.state.certificateName;
    let reference = this.state.reference;
    let emissionDate = this.state.emissionDate;

    //certificateName validation
    if (certificateName == "") {
      isvalid = false;
      this.setState({titleError:this.props.t("champ obligatoire")})
    } else this.setState({ certificateNameError: "" });

    //reference validation
    if (reference == "") {
      isvalid = false;
      this.setState({titleError:this.props.t("champ obligatoire")})
    } else this.setState({ referenceError: "" });

    //emissionDate validation
    if (emissionDate == null) {
      isvalid = false;
      this.setState({titleError:this.props.t("champ obligatoire")})
    } else this.setState({ emissionDateError: "" });


    return isvalid;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validation()) {
      let cv = this.context.user
      let postedObject
      if (this.props.index != null) {
         postedObject=cv.certificates[this.props.index]
        postedObject.certificateName=this.state.certificateName
        postedObject.reference=this.state.reference
        postedObject.emissionDate=this.state.emissionDate
        postedObject.expirationDate=this.state.expirationDate
        
        cv.certificates[this.props.index] = postedObject
      } else {
         postedObject = {
          certificateName: this.state.certificateName,
          reference: this.state.reference,
          emissionDate: this.state.emissionDate,
          expirationDate: this.state.expirationDate,
        };
        if(this.props.id){
          postedObject._id=this.props.id}
        cv.certificates.push(postedObject);
      }
      this.context.setUser(cv)
      this.props.updateView(postedObject);
      this.props.setModalVisible(false);
    }
  };
  setEmissionDate = (value) => {
    this.setState({ emissionDate: value });
  };
  setExpirationDate = (value) => {
    this.setState({ expirationDate: value });
  };
  render() {
    let certificatesList = this.context.certificatesList;
    const language=this.props.i18n.language 
    //dans la base donne par default english certificate_name_en
    const certificatesLanguage={"fr":"certificate_name","en":"certificate_name","ar":"certificate_name"}
    const certificateLanguage=certificatesLanguage[language]
    const renderTitle = (title) => (
      <span>
        
        <AsyncImage 
        // images/certificates from aws
        src={`${this.context.envVar.REACT_APP_CLIENT_URL}/images/certificates/${title?.toLowerCase()}.jpeg`}
        width="18px"
        className="m-1"
        // src={`/src/client/media/certificates/${title}.jpeg`}
         />
        {title}
      </span>
    );
    const renderItem = (c) => ({
      value: `${c.certificate_name}**${c.provider}**${c._id}`,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'start'
          }}
        >
          {c[certificateLanguage]}
        </div>
      ),
    });
    const options = [
    ];
    for (let i = 0; i < certificatesList.length; i++) {
      let certificates=certificatesList[i].certificates.map((c)=>renderItem(c))
      options.push({
        label: renderTitle(certificatesList[i]._id),
        options: certificates,
      }
      );
    }
    return (
      <div>
        <Modal
          title={
            <h5 className="m-1 text-primary">
              <b>
                {this.props.index == null ? this.props.t('Ajouter'): this.props.t("Modifier")} {this.props.t('une certificat')} 
              </b>
            </h5>
          }
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={this.props.modalVisible}
          onOk={() => this.props.setModalVisible(false)}
          onCancel={() => {this.props.setModalVisible(false);this.props.setIndex(null);}}
          footer={null}
        >
          <div className="">
            <div className="row justify-content-start">
              <div className="  ">
                <form className="   " onSubmit={this.handleSubmit}>
                  <div className="">
                    <div className="">
                      <label>{this.props.t("Certificat")}  *</label>
                      <div className="md-form">
                      <AutoComplete
                    className="w-100 border "
                    id="certificateName"
                    type="text"
                    name="certificateName"
                    value={this.state.certificateName}
                    onChange={this.setCertificateName}
                        options={options}
                        // placeholder="try to type `b`"
                        filterOption={(inputValue, option) =>
                          { 
                            if(option.value)
                           return option?.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1}
                        }
                      />
                        {/* <input
                          className="form-control validate mb-2"
                          id="certificateName"
                          type="text"
                          name="certificateName"
                          value={this.state.certificateName}
                          onChange={this.handleChange}
                        /> */}
                      </div>
                      <p className="text-danger">
                        <small>{this.state.certificateNameError}</small>
                      </p>

                      <label>{this.props.t("Réference")}  *</label>
                      <div className="md-form">
                        <input
                            className="form-control validate mb-2"
                            id="reference"
                            type="text"
                            name="reference"
                            value={this.state.reference}
                            onChange={this.handleChange}
                             
                          />
                      
                      </div>
                      <p className="text-danger">
                        <small>{this.state.referenceError}</small>
                      </p>

                      <div className="row mb-2">
                        <div className="col-lg-6 col-sm-12 ">
                          <label className="col-12" htmlFor="linkedinPage">
                            <span className="float-left">{this.props.t("Date d'émission")}  *</span>
                            <div className="">
                              <DatePicker
                                placeholder="MM-YYYY"
                                picker="month"
                                format={"MM-YYYY"}
                                //   allowClear={false}
                                // disabledDate={this.disabledDate}
                                className="border form-control"
                                defaultValue={
                                  this.state.emissionDate &&
                                  moment(this.state.emissionDate)
                                }
                                onChange={(moment, date) => {
                                  if (moment != null) {
                                    this.setEmissionDate(moment.toDate());
                                  } else this.setEmissionDate(null);
                                }}
                              />
                            </div>
                          </label>
                          <p className="text-danger">
                            <small>{this.state.emissionDateError}</small>
                          </p>
                        </div>
                        <div className="col-lg-6 col-sm-12 ">
                         
                            <label className="col-12" htmlFor="linkedinPage">
                              <span className="float-left"> {this.props.t("Date d'expiration")}  </span>
                              <div className="">
                                <DatePicker
                                  placeholder="MM-YYYY"
                                  picker="month"
                                  //   allowClear={false}
                                  // disabledDate={this.disabledDate}
                                  format={"MM-YYYY"}
                                  className="border form-control"
                                  defaultValue={
                                    this.state.expirationDate &&
                                    moment(this.state.expirationDate)
                                  }
                                  onChange={(moment, date) => {
                                    if (moment != null) {
                                      this.setExpirationDate(moment.toDate());
                                    } else this.setExpirationDate(null);
                                  }}
                                />
                              </div>
                              <p className="text-danger">
                                <small>{this.state.expirationDateError}</small>
                              </p>
                            </label>
                        </div>
                      </div>
                 
                      <div className="text-center">
                        <button
                          type="submit"
                          className=" submitspinner  btn btn-primary btn-block z-depth-1a"
                        >
                         {this.props.t("Enregistrer")} 
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()( CertificateForm )