import React, { useContext, useEffect, useState } from 'react'
import SideBlogComponent from './SideBlogComponent';
import { GetBlogs } from '../../services/api/blog/GetBlogs';
import decrypt from '../../utils/decrypt';
import BlogNavbar from './BlogNavbar';
import BlogComponent from './BlogComponent';
import { useParams, withRouter } from 'react-router-dom';
import { Pagination } from 'antd';

import { AppContext } from '../../routes/AppContext';
import { useQuery } from 'react-query';

 function BlogContainer(props) {
  const {headers} = useContext(AppContext);
  const pageSize=9
  const [filter,setFilter]=useState("all")
  const [page,setPage]=useState(1)
  const [index,setIndex]=useState(props.index?props.index:0)
  const params = useParams();
  let list=[]
  let blogsLength=1
const handleChangeFilter=(filter)=>{
  setFilter(filter)
}
 const getBlogs=async()=>{
 let response = await GetBlogs({},headers)
if(response){
   return(JSON.parse(decrypt(response.data)))}
  }
  useEffect(()=>{
    // if(blogs.length==0)getBlogs()
    // let element = document.getElementById("blog");
    // element.scrollIntoView(true)

  },[])
  const { data, isLoading, isError } = useQuery(
    ['blogs'], // Query key
    getBlogs
  );
  let blogs=data??[]
  console.log(blogs)
  if(blogs.length){
    let blogsList=blogs.filter(b=>b.type===filter||filter==="all")  
     blogsLength=blogsList.length
    let firstIndex = (page - 1) * pageSize
    blogsList = blogsList.slice(firstIndex, firstIndex + pageSize)
    
   list=blogsList.map((blog,index)=>{
    
    return(
      <>
      {<div key={index} className="mt-3 p-1 col-xl-4 col-md-12" >
        <BlogComponent blog={blog} />
      </div>}
      </>
    )
  })}
  let blogsTypes=blogs.map((blog)=>blog.type)
  blogsTypes=[...new Set(blogsTypes)]
  // let i=-1
  // let sideList=blogs&&blogs.map(blog=>{
  //   i++
  //   const j=i
  //   return(
  //     <div key={j} className="mt-3 ms-3" >
  //       <SideBlogComponent index={j} setIndex={setIndex} blog={blog} />
  //     </div>
  //   )
  // })
  return (
    <div id="blog" className='publicRoute'  >
      <div className='container' >
        <BlogNavbar setFilter={setFilter} blogs={blogsTypes} setPage={setPage} />
      <div className='mt-5 row' >
      {list}

      </div>
</div>
<div className='m-1 d-flex justify-content-end' >
      <Pagination 
        current={page}
        onChange={(page)=>{setPage(page)}}
      defaultCurrent={1} total={blogsLength} pageSize={pageSize} />
</div>
    </div>
  )
}
export default withRouter(BlogContainer)