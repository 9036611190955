import React, { useContext, useEffect, useRef, useState }  from 'react'

import './Content.css'

import { AppContext } from "../../../routes/AppContext";
import { Timeline,Switch } from 'antd';


// import jsPDF from 'jspdf';

// import { GrCertificate } from "react-icons/gr";


import { ClockCircleOutlined } from '@ant-design/icons';
import { useReactToPrint } from "react-to-print";

import CvCercle from './cv-cercle/CvCercle';
// import { IoMail } from "react-icons/io5";


import CardMembershipIcon from '@material-ui/icons/CardMembership';

// import { FaBuilding, FaCodeFork, FaGraduationCap, FaPhone} from 'react-icons/fa6';
// import { FaLocationDot } from "react-icons/fa6";
// import { GrValidate } from "react-icons/gr";
import logo from "../../../media/image/logo-rec.png"
import profile from "../../../media/image/y.jpg"
import Contact from './Contact';
import HardSkills from './HardSkills';
import SoftSkills from './SoftSkills';
import Language from './Language';
import Certificates from './Certificates';
import Educations from './Educations';
import Experiences from './Experiences';
import Projects from './Projects';
import moment from 'moment';
import { useTranslation } from "react-i18next";
const Content = (props) => {
  const [showCVFile, setShowCVFile] = useState(false);
  const [namee, setNamee] = useState("")
  const { envVar,orders ,role } = useContext(AppContext);
  // const reportTemplateRef = useRef(null);
  const [buy, setBuy] = useState(0)
  const { t } = useTranslation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current;
    }
  });

  useEffect(()=>{
    let nameee=""
    if(props.myname==undefined)
      {nameee= props.candidate.name + (props.candidate.lastName==undefined ? "" :(" " +props.candidate.lastName))
    }
    else if(props.candidate.name==undefined)
{    nameee=props.myname + (props.candidate.lastName==undefined ? "" :(" " +props.candidate.lastName))
}    else
{    nameee=props.candidate.name + (props.candidate.lastName==undefined ? "" :(" " +props.candidate.lastName))
}    

    setNamee(nameee)
    setBuy(0)
   if(orders.length>0){
    let count=0
    const now = moment();
    orders.map((item)=>{
      const dateFinObject = moment(item.datefin);
      if(dateFinObject.isAfter(now) && item.category!=="Gratuit"){
        count=count+1
      }
    })
    setBuy(count)
   }
    },[])

  return (
    <div className="wrapper">
      {console.log("candidat",props.candidate)}
      {console.log("props.cv",props.cv)}
    {(props.candidate.cvFile && buy>0 ) && (
      <div className="right m-1">
        <Switch
          checkedChildren={"cv original"}
          unCheckedChildren={"cv original"}
          className={showCVFile ? "me-1 btn-success" : "me-1 btn-danger"}
          // size="small"
          checked={showCVFile}
          onChange={() => {
            setShowCVFile(!showCVFile);
          }}
        />
      </div>
    )}
    {showCVFile ? (
      <div style={{ height: "650px" }}>
        <iframe
          src={
            envVar.REACT_APP_CLIENT_URL +
            "/candidate/cv_file/" +
            encodeURIComponent(props.candidate.cvFile)
          }
          height="100%"
          width="100%"
        ></iframe>
      </div>
    ) : (  




    <div className="cv-contain" ref={componentRef} >
      <div className="cv-top">
        <div className="cv-t-l">
          <img src={
                envVar.REACT_APP_CLIENT_URL +
                "/candidate/images/" +
                props.candidate.image
              } alt='image' className='cv-img'/>
        </div>
        <div className="cv-t-r">
          <div className="cv-r-top">
            {console.log("props.candidate.lastName",props.candidate.lastName)}
            <div className="cv-r-name">{namee}</div>
            {/* <div className="cv-r-social">
              <FaFacebookSquare className="social-icons" />
              <FaLinkedin   className="social-icons"/>
            </div> */}
            
          </div>
          <div className="cv-r-bot">
            <div className="cv-r-exp">
             <div className="cv-job">{props.candidate.desiredJobPost}</div> 
             {console.log(props.cv.experience)}
             <div className="cv-exp">Total experience: {(props.cv.experience >=0) ? (Math.floor(props.cv.experience / 12)) : "-"} Ans</div>
            </div>
            {props.cv.email && (
            <Contact decryptedData={props.decryptedData} candidate={props.candidate} role={role} />
            )}
          </div>
        </div>
        <div className="cv-t-r-logo">
          {/* <div className="cv-logo"> */}
            <img src={logo} alt='logo' className='cv-log' />
          {/* </div> */}
          <div className="cv-down" onClick={handlePrint}>
          {t("Télécharger CV")}
          </div>
        </div>

        
      </div>



      <div className="cv-body">
        <div className="cv-b-l">

          {/* compétence  */}
           
          {props.cv.hardSkills?.length>0 && (
                <HardSkills hardSkills={props.cv.hardSkills} />
              )}
            {props.cv.softSkills?.length>0 && (
                <SoftSkills softSkills={props.cv.softSkills} />
              )} 
              {props.cv.languages?.length>0 && (
                <Language languages={props.cv.languages} />
              )}
            



          {/* Formations académiques */}

          {props.cv.educations?.length>0 && (
                <Educations educations={props.cv.educations} />
              )}

            {/* Certificats */}
            {props.cv.certificates?.length>0 && (
                <Certificates certificates={props.cv.certificates} />
              )}
        </div>


        <div className="cv-b-m"></div>


        <div className="cv-b-r">

            {/* Expériences professionnelles */}
            {props.cv.experiences?.length>0 && (
                <Experiences experiences={props.cv.experiences} />
              )}
          

          {/* Projets académiques */}
          {props.cv.projects?.length>0 && <Projects projects={props.cv.projects} />}
          


        </div>
      </div>
    </div>
    )}
    </div>
  )
}

export default Content