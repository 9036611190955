import React, { useContext, useEffect, useState } from 'react'
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { AppContext } from '../../../routes/AppContext';
import { Col, Row } from 'antd';
import AsyncImage from '../../AsyncImage';
import formatTime from '../../../utils/formatTime';
import { useTranslation } from 'react-i18next';

export default function QuestionPreview(props) {
  const { t } = useTranslation();

  const { testLanguage,envVar,hardSkillsList } = useContext(AppContext);
  const [skill,setSkill]=useState("")
  let questionLanguage=testLanguage=="fr"?"question_fr":"question_en"
  let answerLanguage=testLanguage=="fr"?"answer_fr":"answer_en"
  const levels=[t("Débutant"),
    t("Intermédiaire"),
    t("Avancé"),
    t("Expert")]
  useEffect(() => {
    hljs.highlightAll();
  });
  useEffect(() => {
    if(props?.question?.skill){
      let s=hardSkillsList.find(s=>s.code==props?.question?.skill)
     if(s) setSkill(s.skill)
    }
  },[]);
console.log(props?.question);
  let i =-1
  let list=props?.question?.answers?.map((answer)=>{
    i++
    return(<li className={answer.isCorrect?'border-light-success rounded m-2 p-1 bg-light-success ul':'border rounded m-2 p-1 ul'} key={i}  >
         {  answer.type != 1 ? (
          <div  dangerouslySetInnerHTML={{
            __html: answer[answerLanguage],
          }} ></div>
          
        ) : (
          <AsyncImage
            className="w-100"
            src={
              envVar.REACT_APP_CLIENT_URL
              + "/Test/images/" +
              answer[answerLanguage]
            }
          />
        )}
    {/* <AnswerComponent  index={i}  answer={answer} /> */}
    </li>)
  })
  return (
    <div className='Content-Question' >
              <h5 className="h5">{t("Question")} {!isNaN(props?.startIndex)&&(props?.startIndex+props?.index+1)}</h5>

      <p className='Questions-Para' >
      { props.question.type != 3 ? (
          <div  dangerouslySetInnerHTML={{
            __html: props?.question[questionLanguage],
          }} ></div>
        ) : (
          <AsyncImage
          className="w-100"     
              src={envVar.REACT_APP_CLIENT_URL + "/Test/images/" + props?.question[questionLanguage]}
          />
        )}
        </p>
        <ul>
    {list}</ul>
    <div className='d-flex justify-content-center' >
    <hr style={{boxShadow:"10px grey"}} />
    </div>
              <div className="secondary-Setup-Question">
                <Row span={24}>
              
                <Col >
                <p>
                  <strong>{t("Difficulté")}: </strong>
                  {levels[props?.question.level]}
                </p>
                </Col >
                <Col>
                <p>
                  <strong>{t("Compétence")}:</strong> {skill}
                </p>
                </Col>
                <Col>
                <p>
                  <strong>{t("Durée")}:</strong> {formatTime(props?.question.time)}
                </p>
                </Col>
                <Col >
                <p>
                  <strong>{t("Points")}:</strong> {props?.question.points}
                </p>
                </Col>
                </Row>
              </div>
    </div>
  )
}
