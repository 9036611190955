import axios from "axios";
import React, { Component } from "react";
import { notification, Select, DatePicker, Modal, Space, Divider, Input, AutoComplete, Button } from "antd";

import moment from "moment-timezone";
import "react-phone-number-input/style.css";
import ImageUploader from "./ImageUploader";

import InscriptionSteps from "../InscriptionSteps";
import { withRouter } from "react-router";
import InfoIcon from "../icons/InfoIcon";
import { AppContext } from "../../routes/AppContext";
import { withTranslation } from "react-i18next";

const { Option } = Select;

// import { Link } from "react-router-dom";

class CompanyProfile extends Component {
  static contextType = AppContext

  constructor(props,context) {
    super(props);
    //   let entreprise=JSON.parse(sessionStorage.getItem("user"))

    let company = context.user;

    this.state = {
      id: company.id,
      name: company.name,
      email: company.email,
      sector: company.sector,
      companyCreationDate: company.companyCreationDate,
      employeesNumber: company.employeesNumber,
      description: company?.idCompanyDetail.description,
      image: company.image,
      // id:entreprise._id,
      registrationNumber: company.registrationNumber,
      registrationNumberError: "",

      sectorError: "",
      companyCreationDateError: "",
      descriptionError: "",
      employeesNumberError: "",
      imageError: "",
      loading:false
    };
    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  onChange = (value) => {
    this.setState({ sector: value });
  };
  onChangeEmployeesNumber = (value) => {
    this.setState({ employeesNumber: value });
  };
  setImage = (value) => {
    this.setState({ image: value });
  };
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  info = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p>
            Veuillez vérifier le numéro d'identification fiscale de votre entreprise.
            Si vous rencontrez des difficultés, veuillez nous contacter pour vous aider à créer un compte ou à prendre rendez-vous à partir
            <a onClick={()=>{this.props.history.push("/demo")}} href="#"> <i>d'ici </i>  </a>


          </p>
        </div>
      ),

      onOk() { },
    });
  };
  disableDate=(current)=> {
    // Can not select days before today and today
    return current > moment(this.context.serverTime);
  }
  validation = () => {
    let isvalid = true;
    let sector = this.state.sector;
    let description = this.state.description;
    let employeesNumber = this.state.employeesNumber;
    let companyCreationDate = this.state.companyCreationDate;
    let image = this.state.image;
    let registrationNumber = this.state.registrationNumber;
  //registration number validation
    if (registrationNumber != "") {
      if (
        !(
          registrationNumber.length === 8 &&
          /^[0-9]{7}[A-Za-z]/.test(registrationNumber)
        )
      ) {
        isvalid = false;
        this.setState({ registrationNumberError: this.props.t("identifiant invalide") });
      } else this.setState({ registrationNumberError: "" });
    }  
      //sector validation
    if (sector == "") {
      isvalid = false;
      this.setState({ sectorError: this.props.t("champ obligatoire") });
    } else this.setState({ sectorError: "" });

    //description validation
    if (description == "") {
      isvalid = false;
      this.setState({ descriptionError: this.props.t("champ obligatoire") });
    } else this.setState({ descriptionError: "" });

    //employeesNumber validation
    // if (employeesNumber == "" || employeesNumber == null) {
    //   isvalid = false;
    //   this.setState({ employeesNumberError: this.props.t("champ obligatoire") });
    // } else this.setState({ employeesNumberError: "" });
    if (employeesNumber == "") {
      isvalid = false;
      this.setState({ employeesNumberError: this.props.t("champ obligatoire") });
    } else this.setState({ employeesNumberError: "" });
    //companyCreationDate validation
    // if (companyCreationDate == null) {
    //   isvalid = false;
    //   this.setState({ companyCreationDateError: this.props.t("champ obligatoire") });
    // } else this.setState({ companyCreationDateError: "" });

    //image validation
    // if (image == null) {
    //   isvalid = false;
    //   this.setState({ imageError: this.props.t("champ obligatoire") });
    // } else this.setState({ imageError: "" });

    return isvalid;
  };
  handleSubmit(event) {
    event.preventDefault();

    if (this.validation()) {
      this.setState({loading:true})
      axios
        .put(
          "/api/profile/company",
          {
            
            sector: this.state.sector,
            companyCreationDate: this.state.companyCreationDate,
            employeesNumber: this.state.employeesNumber,
        registrationNumber: this.state.registrationNumber,
        description: this.state.description,
          },
          {
            headers: this.context.headers
          }
        )
        .then((response) => {
 if(response){
         let data = this.context.user;
          data.sector = this.state.sector;
          data.registrationNumber = this.state.registrationNumber;
          data.companyCreationDate = this.state.companyCreationDate;
          data.employeesNumber = this.state.employeesNumber;
          data.idCompanyDetail.description = this.state.description;
          data.image = this.state.image;
         this.context.setUser(data)
          //notification
          notification.success({
            description: this.props.t(response.data.code),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor:"#d4edda",
              marginTop:"5rem"
            },
          });

          this.props.history.push("/company/contact");}
        })

        .catch((error) => {
          //notification
          notification.error({
            description: this.props.t(error.response.data.code),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor:"#f8d7da",
              marginTop:"5rem"
            },
          });

          // console.warn(error.response.data);
        });
    }
  }

  setPosition = (position) => {
    this.setState({ localization: position });
  };

  render() {
    const sectors = { "fr": "sector_fr", "en": "sector_en","ar":"sector_ar" }
    const sector = sectors[this.props.i18n.language]
    const renderItem = (c) => ({
      value: `${c[sector]}`,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'start'
          }}
        >
          {c[sector]}
        </div>
      ),
    });
    let sectorsList=this.context.sectorsList
   const sectorChildren=sectorsList.map((s)=>renderItem(s))
  // for (let i = 0; i <sectorsList.length ; i++) {
  //   sectorChildren.push(<Option key={i} value={sectorsList[i].sector_fr}><div className='capitalize' >{sectorsList[i].sector_fr}</div></Option>);
  // }
  const { t } = this.props;

    return (
      <div className="">
        <div className=" m-1">
          <InscriptionSteps current={1} />

          <div className="">
            <form className=" rounded" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className=" col-lg-6 col-sm-12 ">
                  <label className="col-12 mt-2 " htmlFor="name">
                    <span className="">{t("Nom de l'entreprise")}</span> <br />
                    <input
                      className="border form-control "
                      id="name"
                      type="text"
                      name="name"
                      value={this.state.name}
                      readOnly
                    />
                  </label>

                  <label className="col-12 mt-2 " htmlFor="email">
                    <span className="">{t("Email")}</span> <br />
                    <input
                      className="border form-control "
                      id="email"
                      type="text"
                      name="email"
                      value={this.state.email}
                      readOnly
                    />
                  </label>

                  <label className="mt-2 ">{t("Secteur Activité")} *</label>
                  <br />
                  <AutoComplete
                    className="w-100 border "
                    id="sector"
                    type="text"
                    name="sector"
                    value={this.state.sector}
                    onChange={this.onChange}
                        options={sectorChildren}
                        // placeholder="try to type `b`"
                        filterOption={(inputValue, option) =>
                          { 
                            if(option.value)
                           return option?.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1}
                        }
                      />
                  {/* <Select
                    // style={{ width: 200 }}
                    placeholder="Selectionner.."
                    className="w-100"
                    // style={{width:"100%"}}
                    showSearch
                    onChange={this.onChange}
                    value={this.state.sector}
              
                  >
                   {sectorChildren}
                  </Select> */}
                  {/* <AutoComplete
                    className="w-100 border "
                    id="certificateName"
                    type="text"
                    name="certificateName"
                    value={this.state.certificateName}
                    onChange={this.setCertificateName}
                        options={options}
                        // placeholder="try to type `b`"
                        filterOption={(inputValue, option) =>
                          { 
                            if(option.value)
                           return option?.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1}
                        }
                      /> */}
                  <br />
                  <p className="text-danger">
                    <small>{this.state.sectorError}</small>
                  </p>

                  <div className="  ">
                    <label className=" col-12 mt-2" htmlFor="employeesNumber">
                      <span className="float-left"> {t("Nombre d'employés")} * </span>
                      <br />
                  <Select
                    // style={{ width: 200 }}
                    placeholder={t("Selectionner..")}
                    className="w-100"
                    id="employeesNumber"
                    name="employeesNumber"
                    // style={{width:"100%"}}
                    onChange={this.onChangeEmployeesNumber}
                    value={this.state.employeesNumber}
                  >
                
                    <Option value="1-10">1-10</Option>
                    <Option value="11-20">11-20</Option>
                    <Option value="21-50">21-50</Option>
                    <Option value=">50">&gt;50</Option>
                  </Select>
                  <br />
                    </label>
                    <p className="text-danger">
                      <small>{this.state.employeesNumberError}</small>
                    </p>

                    <label className=" col-12 mt-2" htmlFor="linkedinPage">
                      <span className="float-left"> {t("Date de création")} </span>
                      <div className="col-12">
                        <DatePicker
                        placeholder="DD-MM-YYYY"

                        format={"DD-MM-YYYY"}
                          className="border form-control"
                          disabledDate={
                            this.disableDate}
                          // value={moment(this.state.companyCreationDate)}
                          defaultValue={
                            this.state.companyCreationDate &&
                            moment(this.state.companyCreationDate)
                          }
                          onChange={(moment, date) => {
                            if (moment != null) {
                              this.setState({
                                companyCreationDate: moment.toDate(),
                              });
                            } else {
                              this.setState({ companyCreationDate: null });
                            }
                          }}
                        />
                      </div>
                    </label>
                    <p className="text-danger">
                      <small>{this.state.companyCreationDateError}</small>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 ">
 
                  <label className="col-12 mt-2">
                    {" "}
                    <span className=""> {t("Description")} * </span>
                    <textarea
                      name="description"
                      className="border form-control "
                      onChange={this.handleChange}
                      value={this.state.description}
                      id="description"
                      rows="6"
                    ></textarea>
                  </label>
                  <p className="text-danger">
                    <small>{this.state.descriptionError}</small>
                  </p>
                  <label className="col-12  " 
                         for="registrationNumber">{t("Identifiant Unique")}  
                         {/* <span onClick={this.info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1rem" /> </span> */}
                        <input
                          name="registrationNumber"
                          id="registrationNumber"
                          type="text"
                          className="form-control"
                          placeholder={t("Votre matricule fiscale...")}
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={this.state.registrationNumber}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.registrationNumberError}</small>
                        </p>
                    </label>
                  <label className="col-12 ">
                    {" "}
                    <span className=""> {t("Logo d'entreprise")} </span>
                    <ImageUploader
                      isCompany={true}
                      id={this.state.id}
                      image={this.state.image}
                      setImage={this.setImage}
                    />
                  </label>
                  <p className="text-danger">
                    <small>{this.state.imageError}</small>
                  </p>
                </div>

                <br />
                <div className="text-center mt-4">
                  {/* <input
                    className=" btn btn-primary "
                    type="submit"
                    id="inscri"
                    name="send"
                    value="Enregistrer"
                  /> */}
                  <Button  className="" type="primary" loading={this.state.loading} onClick={this.handleSubmit}>
              {t("Enregistrer")}
</Button>
                </div>
                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(withRouter(CompanyProfile));
