import React, { Component } from "react";
import { Input, Modal, notification, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { withRouter } from "react-router";
import jwt from "jsonwebtoken";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// import testPreview from './testPreview';
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TrashIcon from "../icons/TrashIcon";
import UsersIcon from "../icons/UsersIcon";
import CloneIcon from "../icons/CloneIcon";
import { DeleteTest } from "../../services/api/companyTest/DeleteTest";
import TestResultComponent from "../testResults/TestResultComponent";
import InfoIcon from "../icons/InfoIcon";
import AsyncImage from "../AsyncImage";
import img from "../../media/image/bilan.webp";
import EditIcon from "../icons/EditIcon";
import decrypt from "../../utils/decrypt";
import ShareIcon from "../icons/ShareIcon";
import SendTest from "../sendTest/SendTest";
import TestPreview from "../addTest/testPreview/TestPreview";

import { AppContext } from "../../routes/AppContext";
import { withTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';

const { Column } = Table;
class ManageTests extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      sendModalVisible: false,
      test: {},
      tests: [],
      filteredTests: [],
      update: false,
      filterInput: "",
    };
  }
  async componentDidMount() {
    let data = this.props.tests;

    if (data) {
      this.setState({
        tests: data,
        filteredTests: data,
        testTitles: data.map((test) => test.title),
      });
    }
  }
  componentDidUpdate(prevProps) {
    let data = this.props.tests;
    if (data !== prevProps.tests) {
      if (data) {
        this.setState({
          tests: data,
          filteredTests: data,
          testTitles: data.map((test) => test.title),
        });
      }
    }
  }
  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  setSendModalVisible = (sendModalVisible) => {
    this.setState({ sendModalVisible });
  };
  setTest = (test) => {
    this.setState({ test });
  };
  Delete = async (id) => {
    let res = await DeleteTest({ id: id }, this.context.headers);
    if (res) {
      //notification
      notification.success({
        description: res.data.message,
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem",
        },
      });
      this.setState((prev) => ({
        tests: prev.tests.filter((test) => test._id !== id),
        filteredTests: prev.filteredTests.filter((test) => test._id !== id),
      }));
    }
  };
  info = () => {
    const {t}=this.props
    
    Modal.info({
      title: null,
      okText: t("ok"),
      content: (
        <div>
          <AsyncImage
            src={img}
            alt="rocket"
            titre="rocket"
            height="150"
            className="mg-fluid"
          />

          <p className="fw-bold">
            {" "}
            -{t("Qualifiez vos candidats de la manière la plus efficace possible !")}
            {t("Nos outils de tests d'évaluation vous permettent de concevoir vos propres tests pour fiabiliser vos recrutements,")}
             {t("évaluer objectivement les compétences et identifier les hauts potentiels.")}
          </p>
          <p className="fw-bold">
            -{t("Personnalisez votre questionnaire : nombre de questions, libellé, format des réponses (champs de texte libre, QCM,,,).")}
          </p>
          <p className="fw-bold">
            -{t("Le recruteur visualise et évalue les résultats du candidat pour déterminer l'adéquation de ses compétences avec l'offre.")}
          </p>
        </div>
      ),

      onOk() {},
    });
  };
  render() {
    const {t}=this.props
    return (
      <div>
        <h3 className="titrePage mb-5">{t("LISTE DES TESTS DE COMPÉTENCE")}</h3>
        <div
          onClick={() =>
            this.props.history.push(
              this.props.idResponsible
                ? "/responsible/addTest"
                : "/company/addTest"
            )
          }
          className="btn btn-primary rounded-pill m-2"
        >
          <PlusOutlined
            className=" border  rounded-circle "
            style={{ fontSize: "1.4rem" }}
          />
          <b className="m-3">{t("Ajouter Test")}</b>
        </div>
        <span onClick={this.info} role="button">
          <InfoIcon className="zoom1" fill="blue" width="1.4rem" />{" "}
        </span>

        <div className="m-3 md-form">
          <Input
            className=" form-control"
            placeholder={t("Rechercher un test..")}
            value={this.state.filterInput}
            onChange={(e) => {
              const currValue = e.target.value;
              // setValue(currValue);
              this.setState({ filterInput: currValue });
              this.setState((prev) => ({
                filteredTests: prev.tests.filter((test) =>
                  test.title.includes(currValue)
                ),
              }));
            }}
          />
        </div>
        <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table
          loading={this.props.loading}
          pagination={true}
          dataSource={this.state.filteredTests}
        >
          <Column
            align="center"
            sorter={(a, b) => a.title.localeCompare(b.title)}
            // responsive={['lg']}
            title={t("Titre")}
            dataIndex="title"
            key="title"
            render={(text, test, index) => {
              return (
                <div
                  role="button"
                  onClick={() => {
                    this.setModalVisible(true);
                    this.setState({ test: test });
                  }}
                >
                  {test.title}
                </div>
              );
            }}
          />
          <Column
            align="center"
            title=""
            dataIndex="Actions"
            key="Actions"
            render={(text, test, index) => {
              return (
                <Link
                  to={{
                    pathname: this.props.idResponsible
                      ? "/responsible/testResults"
                      : "/company/testResults",
                    state: { test: test },
                  }}
                >
                  <button className="btn btn-primary rounded-pill">
                    {t("Suivi des tests")}
                  </button>
                </Link>
              );
            }}
          />
          <Column
            align="center"
            title={t("Actions")}
            dataIndex="Actions"
            key="Actions"
            render={(text, test, index) => {
              return (
                <div className="">
                  {/* <Link to={{
                      pathname:this.props.idResponsible?"/responsible/testResults":"/company/testResults",
                      state: { test:test },
                    }}  >
                <i title="Candidats" className=" text-primary m-2" role="button"

                //  onClick={this.Delete(responsible._id)}
                >
                  <UsersIcon width="1.5rem" fill="#676ee7" />
                </i></Link> */}
                  {test.tagged ? (
                    <i
                      title={t("Modifier")}
                      className=" text-success  m-2"
                      role="button"
                    >
                      <EditIcon fill="gray" width="1.5rem" />
                    </i>
                  ) : (
                    <Link
                      to={{
                        pathname: this.props.idResponsible
                          ? "/responsible/addTest/form"
                          : "/company/addTest/form",
                        state: { id: test._id, test: test },
                      }}
                    >
                      <i
                        title={t("Modifier")}
                        className=" text-success  m-2"
                        role="button"
                      >
                        <EditIcon fill="black" width="1.5rem" />
                      </i>
                    </Link>
                  )}
                  {true ? (
                    <Link
                      to={{
                        pathname: this.props.idResponsible
                          ? "/responsible/addTest/form"
                          : "/company/addTest/form",
                        state: {
                          test: test,
                          testTitles: this.state.testTitles,
                        },
                      }}
                    >
                      <i
                        title={t("cloner")}
                        className=" text-success  m-2"
                        role="button"
                      >
                        <CloneIcon fill="green" width="1.5rem" />
                      </i>
                    </Link>
                  ) : (
                    <i
                      title={t("cloner")}
                      className=" text-success  m-2"
                      role="button"
                    >
                      <CloneIcon fill="gray" width="1.5rem" />
                    </i>
                  )}
                  {test.status !== "Supprimé" ? (
                    <i
                      title={t("Archiver")}
                      className=" text-danger m-2"
                      role="button"
                      onClick={() => {
                        Modal.confirm({
                          title: t("Archiver test"),
                          icon: <ExclamationCircleOutlined />,
                          content: t("voulez vous archiver le test")+" " + test.title,
                          okText: t("Archiver"),
                          okType: "danger",
                          cancelText: t("Annuler"),
                          onOk: () => {
                            this.Delete(test._id);
                          },
                        });
                      }}
                    >
                      <TrashIcon fill="red" width="1.5rem" />
                    </i>
                  ) : (
                    <i
                      title={t("Archiver")}
                      className=" text-secondary m-2"
                      role="button"
                    >
                      <TrashIcon fill="gray" width="1.5rem" />
                    </i>
                  )}
                  <i
                    onClick={() => {
                      this.setSendModalVisible(true);
                      this.setTest(test);
                    }}
                    title={t("partager")}
                    className=" text-primary  m-2"
                    role="button"
                  >
                    <ShareIcon fill="blue" width="1.5rem" />
                  </i>
                </div>
              );
            }}
          />
        </Table>
        </ConfigProvider>
        {this.state.modalVisible && (
          <Modal
            title={
              <h5 className=" m-1 text-primary">
                <b>{t("Aperçu du test")}</b>
              </h5>
            }
            className="m-5 w-75"
            centered
            closeIcon={
              <i className="mt-5 ">
                <CloseOutlined className="m-3" />
              </i>
            }
            visible={this.state.modalVisible}
            onOk={() => this.setModalVisible(false)}
            onCancel={() => this.setModalVisible(false)}
            footer={<></>}
          >
            {/* <TestResultComponent modalVisible={this.state.modalVisible}  test={this.state.test}/> */}
            <TestPreview test={this.state.test} />
          </Modal>
        )}
        {this.state.sendModalVisible && (
          <Modal
            title={
              <h5 className=" m-1 text-primary">
                <b>{t("Envoyer test")}</b>
              </h5>
            }
            className="m-5 w-75"
            centered
            closeIcon={
              <i className="mt-5 ">
                <CloseOutlined className="m-3" />
              </i>
            }
            visible={this.state.sendModalVisible}
            onOk={() => this.setSendModalVisible(false)}
            onCancel={() => this.setSendModalVisible(false)}
            footer={<></>}
          >
            <SendTest
              setModalVisible={this.setSendModalVisible}
              test={this.state.test}
            />
          </Modal>
        )}
      </div>
    );
  }
}
export default withTranslation()(withRouter(ManageTests));
