import React, { useContext, useEffect, useState } from "react";

import { useHistory, withRouter } from "react-router-dom";
import decrypt from "../../utils/decrypt";
import { AppContext } from "../../routes/AppContext";
import Pagination from "@mui/material/Pagination";
import { GetTestResultsByJobPost } from "../../services/api/companyTest/GetTestResultsByJobPost";
import TestsForm from "./TestsForm";
import SideTestComponent from "./SideTestComponent";
import { Modal, notification, Table } from "antd";
import EyeIcon from "../icons/EyeIcon";
import moment from "moment-timezone";
import AsyncImage from "../AsyncImage";
import TestResultComponent from "../testResults/TestResultComponent";
import { CloseOutlined } from "@ant-design/icons";
import { GetTests } from "../../services/api/companyTest/GetTests";
import ManageApplicationsModal from "../jobApplications/ManageApplicationsModal";

import { JobPostContext } from "../dashboard/jobPostDashboard/JobPostContext";
import UpdateTestExpirationModal from "../updateTestExpiration/UpdateTestExpirationModal";
import ClockIcon from "../icons/ClockIcon";
import Cv from "../cvPDF/Cv";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import UploadedCv from "../cvPDF/UploadedCv";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';

const { Column } = Table;

function TestResults(props) {
  const { jobPost,dates,applications} = useContext(JobPostContext);
  const {envVar,headers,serverTime } = useContext(AppContext);
  const { t } = useTranslation();
  
  const [page, setPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [testsNumber, setTestsNumber] = useState(0);
  const [testResult, setTestResult] = useState(null);
  const [idTest, setIdTest] = useState(jobPost.idTest);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expirationModalVisible,setExpirationModalVisible]=useState(false)
  const [modalVisible1, setModalVisible1] = useState(false);
  const [candidate, setCandidate] = useState({});


  const history = useHistory();
  const handleChangePage = (e, page) => {
    setPage(page);
    document.getElementById("scroller").scrollIntoView();
  };
  const dateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };
 
  const getTests=async()=>{
    let response= await GetTests({},headers)
if(response){
let data=JSON.parse(decrypt(response.data))
return data
}}

  const getTestResultsByJobPost = async () => {
    let response = await GetTestResultsByJobPost(
      { id: jobPost._id },
      headers
    );
    if(response)
    {let data = JSON.parse(decrypt(response.data));
      return data
    }
  };
  useEffect(() => {
    if (jobPost._id) {
      // getTestResultsByJobPost();
      // getTests();
    } else history.push("/company/manageJobPosts");
  }, []);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const { data, isLoading, isError } = useQuery(
    ['tests'], // Query key
    getTests
  );
  let tests=data??[]
  const { data:data2, isLoading:isLoading2, isError:isError2 } = useQuery(
    ['testResults',jobPost._id], // Query key
    getTestResultsByJobPost
  );
  console.log(data2)
  let candidates=data2??[]
  return (
    <div id="scroller" className="row">
      <div className="col-sm-12 col-md-10 rounded shadow">
        {idTest ? (
          <>
       <div className="d-flex justify-content-left align-items-center" >
            <h6 className=" titre-table">
              
              {t("Liste des candidats passant le test")}
            </h6>
            <button
              onClick={() => {
                if (selectedRowKeys.length) {
                  setModal2Visible(true);
                } else {
                  notification.warn({
                    description: t("aucun candidat selectionné"),
                    className: "custom-class",
                    style: {
                      width: 600,
                      backgroundColor: "#fff3cd",
                      marginTop: "5rem",
                    },
                  });
                }
              }}
              className="btn btn-primary rounded-pill m-1"
            >
              {t("Etape suivante")}
            </button>
            </div>
            <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
            <Table
            loading={isLoading&&isLoading2}
              rowKey={(record,index) => {
                let k = `{"${record.idCandidate}":"${record.email}"}`;
                return index;
              }}
              rowSelection={rowSelection}
              pagination={true}
              dataSource={candidates}
            >
              <Column
                align="left"
                sorter={(a, b) => a.name.localeCompare(b.name)}
                title={t("Nom")}
                dataIndex="name"
                key="name"
                render={(text, test, index) => {
                  return (
                    <div role="button"
                    onClick={() => {
                      let candidate={_id:test.idCandidate}
                      let application=applications.find(app=>app.idCandidate==test.idCandidate)
                      if(application.cvFile){
                        candidate.cvFile=application.cvFile
                        candidate.uploaded=true
                      }
                      setCandidate(candidate)
                      setModalVisible1(true)}} className=" d-flex justify-content-start align-items-center">
                      <AsyncImage
                        className="logo-m rounded me-1"
                        src={
                          envVar.REACT_APP_CLIENT_URL +
                          "/candidate/images/" +
                          test.image
                        }
                      />
                      {test.name}
                    </div>
                  );
                }}
              />
              <Column
                align="center"
                sorter={(a, b) => a.title.localeCompare(b.title)}
                // responsive={["lg"]}
                title={t("Test")}
                dataIndex="title"
                key="title"
              />
              <Column
                align="center"
                sorter={(a, b) =>
                  dateFromObjectId(a._id) - dateFromObjectId(b._id)
                }
                // responsive={["lg"]}
                title={t("Date d'envoi")}
                dataIndex="date"
                key="date"
                render={(text, test, index) => {
                  return <>{moment(dateFromObjectId(test._id)).format("DD/MM/YYYY")}</>;
                }}
              />
              <Column
                align="center"
                sorter={(a, b) =>
                  new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
                }
                // responsive={["lg"]}
                title={t("Date de passage")}
                dataIndex="updatedAt"
                key="updatedAt"
                render={(text, record, index) => {
                  console.log(record.updatedAt)
                  return <>{moment(record.updatedAt).format("DD/MM/YYYY")}</>;
                }}
              />
              <Column
                align="center"
                sorter={(a, b) => a.score - b.score}
                // responsive={["lg"]}
                title={t("Score")}
                dataIndex="score"
                key="score"
                render={(text, test, index) => {
                  return (<div className="" >
                    {(typeof test.score === 'undefined')?
            (test.expirationDate && moment(test.expirationDate).isBefore(moment(serverTime)))?t("essai expiré"):
                    t("en attente"):test.score}
                  </div>)}}
              />
              <Column
                align="center"
                title={t("Actions")}
                dataIndex="Actions"
                key="Actions"
                render={(text, testResult, index) => {
                  return (
                    <div className="">
                    {(typeof testResult.score === 'undefined')?
                    (testResult.expirationDate && moment(testResult.expirationDate).isBefore(moment(serverTime)))?
                    <i className=" text-success  m-1 zoom1 icons12"  onClick={()=>{
                      setTestResult(testResult)
                      setExpirationModalVisible(true)
                    }} role="button" 
                    >
                      <ClockIcon width="1.7rem" fill="rgb(239 128 72 / 85%)" />
                    </i>
                    :
                    <i
                    title="prévu"
                    className=" m-2"
                    role="button"
                  >
                    <EyeIcon fill="gray"  width="1rem" />
                  </i>:
                  <div className="d-flex">
                    <i
                        title="prévu"
                        className=" m-2"
                        role="button"
                        onClick={() => {
                          setModalVisible(true);
                          setTestResult(testResult);
                        }}
                        >
                        <EyeIcon width="1rem" />
                      </i>
                    <i className=" text-success  m-1 zoom1 icons12"  onClick={()=>{
                      setTestResult(testResult)
                      setExpirationModalVisible(true)
                      console.log(testResult)
                    }} role="button" 
                    >
                      <ClockIcon width="1.7rem" fill="rgb(239 128 72 / 85%)" />
                    </i>
                  </div>
                    }


                      
                    </div>
                  );
                }}
              />
            </Table>
            </ConfigProvider>
          </>
        ) : (
          <TestsForm
            setIdTest={setIdTest}
            setTestsNumber={setTestsNumber}
            idTest={idTest}
            idJobPost={jobPost._id}
            userId={props.userId}
          />
        )}
      </div>

      <SideTestComponent
        setIdTest={setIdTest}
        candidatesNumber={candidates.length}
        idJobPost={jobPost._id}
        userId={props.userId}
        setTestsNumber={setTestsNumber}
        testsNumber={testsNumber}
        idTest={idTest}
      />

      {modalVisible && (
        <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b>{t("Aperçu du test")}</b>
            </h5>
          }
          className="m-5 w-75"
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined className="m-3" />
            </i>
          }
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          footer={<></>}
        >
          <TestResultComponent
          setModalVisible={setModalVisible}
            modalVisible={modalVisible}
            idTestResult={testResult._id}
            testResult={testResult}
            test={tests.find((test) => test._id == testResult.idTest)}
          />
          {/* <testPreview company={this.props.company} test={this.state.test} /> */}
        </Modal>
      )}
      <ManageApplicationsModal
        isTest={true}
        selectedCandidates={selectedRowKeys}
        title={jobPost.title}
        startMissionDate={jobPost.startMissionDate}
        idTest={idTest}
        idJobPost={jobPost._id}
        modalVisible={modal2Visible}
        setModalVisible={setModal2Visible}
        interviews={dates}
      />
   {expirationModalVisible && <UpdateTestExpirationModal modalVisible={expirationModalVisible} setModalVisible={setExpirationModalVisible} test={tests.find((test) => test._id == testResult.idTest)} testResult={testResult} jobPostid={testResult.idJobPost} />}
   {modalVisible1&&    <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{t("Aperçu du CV")}</b>
                      </h5>
                    }
                    className="mb-2 mt-2"
                    width={"95%"}
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    visible={modalVisible1}
                    onOk={() => setModalVisible1(false)}
                    onCancel={() => setModalVisible1(false)}
                    footer={<></>}
                  >
                      {modalVisible1 &&  
      (candidate.uploaded?<UploadedCv decryptedData={true} userId={props.userId} candidate={candidate} idJobPost={jobPost._id} />:
      <Cv  decryptedData={true} userId={props.userId} candidate={candidate} />)}

                    {modalVisible1 && (
                      <Cv decryptedData={true} userId={props.userId} candidate={candidate} />
                    )}
                  </Modal>}
    </div>
  );
}
export default withRouter(TestResults);
