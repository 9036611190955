import React from 'react'
import { withRouter } from 'react-router-dom'
import AddTest from '../../components/addTest/AddTest'
import { useQueryClient } from 'react-query';
function AddTestView(props) {
    const queryClient = useQueryClient();
  
    const handleInvalidate = () => {
      // Invalidate the query data
      queryClient.invalidateQueries(['tests']);
    };
    return (
        <div className="AddTestView">   
            <AddTest handleInvalidate={handleInvalidate}  userId={props.userId}/>
        </div>
    )
}
export default withRouter(AddTestView)