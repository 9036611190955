import React, { useState } from 'react'
import { useEffect } from 'react';
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

export default function AnswerComponent(props) {
  const [selected,setSelected]=useState(false)
  // setSelected(false)
  useEffect(() => {
    hljs.highlightAll();
  });

const select=()=>{
  let selectedList=props.selected
  //condition to push or to pop
  if(selected){
    selectedList=selectedList.filter(item=>{
return item!=props.answer._id
    })
    props.setSelected(selectedList)

  }
  else{
    selectedList.push(props.answer._id)
    props.setSelected(selectedList)
  }
  setSelected(!selected)

}
  return (
    <div onClick={select} role="button" className={selected?'border rounded m-2 bg-info ':'border rounded m-2 '} >
      <div  dangerouslySetInnerHTML={{
        __html: props.answer.answer_fr,
      }} ></div>
      
    </div>
  )
}
