import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
export default function TestDescription(props) {
  const { t } = useTranslation();
    const validation=()=>{ 
      let valid=true
      return valid

    }
    const handleSubmit=(e)=>{
      e.preventDefault()
      if(validation()){
        props.setRegistered(true)
      }
     }
  return (
    <div className='row mt-5 pt-5' >
    <div className="col-6 p-2 text-center">
      <h4 className='' >{props.testDescription?.title}</h4>
      <p>{props.testDescription?.description}</p>
    </div>
    <div className="col-6">
                  <form   className="   "
                    onSubmit={handleSubmit}>
                    <div className="">
                      <div className="mx-2">
                     
                        <label> {t('Nom')} *</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            id="name"
                            type="text"
                            name="name"
                            value={props.name}
                            onChange={(e)=>{props.setName(e.target.value)}}
                             
                          />
                        </div>
  
     
  


    
    
                        <label>{('Adresse Email')} *</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            type="text"
                            id="email"
                            name="email"
                            value={props.email}
                            // readOnly={id}
                            onChange={(e)=>{props.setEmail(e.target.value)}}
                            disabled={props?.emailCandidattt?.length>0 ? true : false}

                          />
                        </div>
    
                        
                        <br />
                        <div className="text-center">
                          <button
                            type="submit"
                            className=" submitspinner btn btn-primary btn-block z-depth-1a"
                          
                          >
                      {t("S'authentifier")}
                          </button>
                        </div>
                        <br />
                      </div>
                  
                    </div>
                  </form>
                </div>
    </div>
  )
}
