import React, { Component } from 'react'
import { Modal } from 'antd' 
import { CloseOutlined } from "@ant-design/icons";
import { DatePicker } from 'antd';
 import moment from 'moment-timezone';
import { AppContext } from '../../../routes/AppContext';
import { ObjectID } from "bson";
import { withTranslation } from "react-i18next";
 class ExperienceForm extends Component {
  static contextType = AppContext;
  constructor(props){
    super(props)
    let experience =this.props.experience ?this.props.experience :{}

    this.state={
         title:experience.title?experience.title:"",
         description:experience.description?experience.description:"",
         companyName:experience.companyName?experience.companyName:"",
         startDate:experience.startDate?experience.startDate:null,
         endDate:experience.endDate?experience.endDate:null,
         currently:(experience.startDate!=null & experience.endDate==null )? true :false,
 
         titleError:"",
         descriptionError:"",
         companyNameError:"",
         startDateError:"",
         endDateError:"",
 
     }
  }
    handleChange=(event)=> {
        const{name,value}=event.target;
        this.setState({
          [name]:value
        })
        
      }
      validation=()=>{
        let isvalid=true
        let title=this.state.title
        let description=this.state.description
        let companyName=this.state.companyName
        let startDate=this.state.startDate
        let endDate=this.state.endDate
        let currently=this.state.currently
      
     
     //title validation
     if(title=="") {
       isvalid=false
       this.setState({titleError:this.props.t("champ obligatoire")})
     }
     else 
     this.setState({titleError:""})

  //description validation
  if(description=="") {
    isvalid=false
    this.setState({titleError:this.props.t("champ obligatoire")})
  }
  else if(description.length<10) {
    isvalid=false
    this.setState({descriptionError:this.props.t("Minimum 10 caractères")})
  }
  else 
  this.setState({descriptionError:""}) 

   //companyName validation
  if(companyName=="") {
    isvalid=false
    this.setState({titleError:this.props.t("champ obligatoire")})
  }
  else 
  this.setState({companyNameError:""})  

  //startDate validation
  if(startDate==null) {
    isvalid=false
    this.setState({titleError:this.props.t("champ obligatoire")})
  }
  else 
  this.setState({startDateError:""}) 

  //endDate validation
  if(!currently&&startDate!==null ){
    
    if(endDate==null ) {
      isvalid=false
      this.setState({titleError:this.props.t("champ obligatoire")})
    }
     
    else   if(moment(endDate).isBefore(moment(startDate))){
      isvalid=false
    this.setState({endDateError:this.props.t("la date de fin doit étre apres la date de début")})}
  
    
    else 
    this.setState({endDateError:""})
  }


     return isvalid
     }
       handleSubmit=(event)=>{
         event.preventDefault();
         if(this.validation()){
           let cv=this.context.user
           if(this.props.index!=null){
            //update
             let experience=cv.experiences[this.props.index]
             experience.title=this.state.title
             experience.description=this.state.description
             experience.companyName=this.state.companyName
             experience.startDate=this.state.startDate
             experience.endDate=this.state.endDate
             cv.experiences[this.props.index]=experience
            
           }
           else{
            //add
           let postedObject={
             title:this.state.title,
             description:this.state.description,
             companyName:this.state.companyName,
             startDate:this.state.startDate,
             endDate:this.state.endDate,
           }
           if(this.props.id){
            postedObject._id=this.props.id}
            postedObject.idExperienceDetail=[{_id:new ObjectID().toString()}]
           cv.experiences.push(postedObject)
           this.props.updateView(postedObject)
          }
          this.context.setUser(cv)
           this.props.setModalVisible(false)

      }
    }


    disabledDate=(current)=> {
      return current > moment(this.context.serverTime);
        }
      setStartDate=(value)=>{this.setState({startDate: value})}
      setEndDate=(value)=>{this.setState({endDate: value})}

    render() {
        return (
            <div>
                 <Modal
   title={<h5 className="m-1 text-primary"><b>{this.props.index===null ? this.props.t('Ajouter'): this.props.t("Modifier")} {this.props.t('une expérience')} </b></h5>}
   className=""

   centered
   closeIcon={
     <i className="mt-5 ">
       <CloseOutlined
         // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
         className="m-3"
       />
     </i>
   }
   visible={this.props.modalVisible}
   onOk={() => this.props.setModalVisible(false)}
   onCancel={() => {this.props.setModalVisible(false);this.props.setIndex(null);}}
   footer={null}

 >

<div className="">
              <div className="row justify-content-start">
                <div className="  ">
                  <form   className=" "
                    onSubmit={this.handleSubmit}
                    >
                    <div className="">
                      <div className="">
                     
                        <label>{this.props.t("Titre")}</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            id="title"
                            type="text"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleChange}
                             
                          />
                          
                        </div>
    <p  className="text-danger"><small>{this.state.titleError}</small></p>
  
     
    
    <label>{this.props.t("Nom d'entreprise")} *</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            id="companyName"
                            type="text"
                            name="companyName"
                            value={this.state.companyName}
                            onChange={this.handleChange}
                             
                          />
                        </div>
    <p className="text-danger"><small>{this.state.companyNameError}</small></p>


                        <label className="col-12 mb-2 ">  <span className="float-left"> {this.props.t("Titre")}Description </span>
       <textarea 
       name="description"
       placeholder={this.props.t("Réalisation des taches 1 2 3 .. au sein de l'entreprise..") +this.state.companyName}
        className="border form-control "
       onChange={this.handleChange}
      value={this.state.description} 
      id="description"
       rows="4"
       >
        
       </textarea>
    <p className="text-danger"><small>{this.state.descriptionError}</small></p>

       </label>

                        <div className="row mb-2" >
              <div className="col-lg-6 col-sm-12 " >

                 <label className="col-12" htmlFor="linkedinPage">
                      <span className="float-left"> {this.props.t("Date de début")}</span>
                      <div className="" >
                      <DatePicker
                        placeholder="MM-YYYY"
                        picker="month"

                    //   allowClear={false}
                        format={"MM-YYYY"}
                          disabledDate={this.disabledDate}
                      className="border form-control"
                         defaultValue={this.state.startDate && moment(this.state.startDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                            this.setStartDate(moment.toDate())
                    }
                    else 
                    this.setStartDate(null)
                      }}
                       />
                      </div>
                    </label>
                    <p className="text-danger"><small>{this.state.startDateError}</small></p>
                    </div>
              <div className="col-lg-6 col-sm-12 " >

                  {!this.state.currently &&  <label className="col-12" htmlFor="linkedinPage">
                      <span className="float-left"> {this.props.t("Date de fin")}</span>
                      <div className="" >
                      <DatePicker
                        placeholder="MM-YYYY"
                        picker="month"

                    //   allowClear={false}
                    //   disabledDate={this.disabledDate}
                    format={"MM-YYYY"}
                    disabledDate={
                      this.disabledDate}
                      className="border form-control"
                         defaultValue={this.state.endDate && moment(this.state.endDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                            this.setEndDate(moment.toDate())
                    }
                    else 
                    this.setEndDate(null)
                      }}
                       />
                      </div>
                    <p className="text-danger"><small>{this.state.endDateError}</small></p>

                    </label>}
                    </div></div>
                    <div className="form-check mb-4">
  <input onChange={(e)=>this.setState({currently:!this.state.currently,endDate:null})} className="form-check-input " type="checkbox" checked={this.state.currently} role="button" id="defaultCheck1"/>
  <label className="form-check-label" for="defaultCheck1">
  {this.props.t("Je travaille actuellement ici")} 
  </label>
</div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className=" submitspinner  btn btn-primary btn-block z-depth-1a"
                          
                          >
                      
                      {this.props.t("Enregistrer")}
                          </button>
                        </div>
                      </div>
                  
                    </div>
                  </form>
                </div>
              </div>
            </div>



 </Modal>
            </div>
        )
    }
}
export default withTranslation()(ExperienceForm);