import { Modal, Steps } from 'antd'
import moment from 'moment';
import React, { useContext } from 'react'
import { AppContext } from '../../routes/AppContext';
import { useTranslation } from "react-i18next";
const { Step } = Steps;

export default function InterviewsHistory(props) {
  const { serverTime } = useContext(AppContext);
  const { t } = useTranslation();
  let current=0
  let list =props.interviews.map((interview,index)=>{
    if(moment(serverTime).isBefore(moment(interview.date))) current=index
  })

  return (
    <div>
    <Modal
          title={<h5 className="m-1">{t('Historique des entretiens')}</h5>}
          className=""
          centered
          visible={props.modalVisible}
          onOk={() => props.setModalVisible(false)}
          onCancel={() => props.setModalVisible(false)}
          footer={
            <>
            </>
          }
        ><div 
        // className='d-flex justify-content center h-100'
        >
              <Steps progressDot direction="vertical"  className="mb-5" current={current} >
   {           props.interviews.map((interview,index)=>{
    let title=interview.archived?t('Retardé'):(moment(serverTime).isBefore(moment(interview.date))?t('Programmé'):t('Passé'))
    return(<>
    <Step status="" description={moment(interview.date).format("L")} title={title} />
  </>)})}

                </Steps></div>
  
        </Modal>
      </div>
  )
}
