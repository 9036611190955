import React, { Component } from 'react'
import {  Modal, Progress } from 'antd'
import { CloseOutlined } from "@ant-design/icons";
import Cv from '../cvPDF/Cv';
import AsyncImage from '../AsyncImage';
import img from './candidate.png'
import moment from 'moment';
import TimelineModal from '../timelineModal/TimelineModal';
import { AppContext } from '../../routes/AppContext';
import UploadedCv from '../cvPDF/UploadedCv';
// import CvComponent from '../cv/CvComponent'
// import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
class Candidat extends Component {
  static contextType = AppContext;
  
    state={      modalVisible: false,modalVisible2:false
    }
    setModalVisible = (modalVisible) => {
        this.setState({ modalVisible });
      };
      setModalVisible2 = (modalVisible2) => {
        this.setState({ modalVisible2 });
      };
       dateFromObjectId = (objectId) => {
        return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
      };
    render() {
      const { t } = this.props;
      let state=this.props.candidat.state
let candidat=this.props.candidat.candidate[0]
        return (
            <div  className="mt-1 ">
            <div role="button" onClick={()=>this.setModalVisible(true)} className="   p-1 " style={{width:205}}>
            
            <div className='d-flex justify-content-start text-center align-middle' >
            <AsyncImage
              className="logo rounded-3 m-1"
              alt={candidat.name}
              src={
                // img
                this.context.envVar.REACT_APP_CLIENT_URL
                + "/candidate/images/" + candidat.image
              }
            />
              <div>
                <span className="text-small ">{candidat.name} {candidat.lastName}</span><br/>
                <span className="text-xx-small ">{candidat.email}</span>

              </div>
            </div>
            <div className='d-flex justify-content-around align-items-center' >
            <div className="text-smaller text-muted">
              <i className='mdi mdi-calendar text-secondary ' ></i>
              {moment(this.dateFromObjectId(candidat._id)).format('L')}</div>
              <div onClick={(e)=>{e.stopPropagation(); this.setModalVisible2(true)}} className="text-smaller text-orange hoverSelect rounded">
              <i className='mdi mdi-timeline text-orange me-1' > </i>
              Historique</div>
            <Progress type="circle" 
            percent={parseInt(this.props.candidat.score*100)}
            format={percent => `${percent}%`}
             width={25}   strokeColor={parseInt(this.props.candidat.score*100) < 30 ? "orange" : "green"} />
            </div>
       {/* <img className="col-4 rounded-circle p-0 mt-0 mb-0 ml-3 logo" alt="recinov"src={this.props.candidat.image}/> */}

            </div>
            <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b>{  t('Aperçu du CV')}</b>
            </h5>
          }
          className="mb-2 mt-2"
          width={"95%"}
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
          footer={<></>}
        >
      {this.state.modalVisible &&  (this.props.candidat.cvFile?<UploadedCv decryptedData={true} userId={this.props.userId} candidate={candidat} idJobPost={this.props.idJobPost} />:<Cv decryptedData={true} userId={this.props.userId} candidate={candidat}  />)}
     </Modal>
     {this.state.modalVisible2&& <TimelineModal idJobPost={this.props.idJobPost} modalVisible={this.state.modalVisible2} setModalVisible={this.setModalVisible2} candidate={candidat} /> }

            {/* <Modal 
  
  className="Modal border rounded"
  overlayClassName="Overlay"
  appElement={document.getElementById('root')}
  style={customStyles}
 
isOpen={this.state.show}
onRequestClose={this.handleClose}>
<div  >{this.state.show &&<CvComponent candidat={this.props.candidat}  handleClose={this.handleClose} />}</div>


</Modal> */}
            </div>
        )
    }
}
export default withTranslation()(Candidat)