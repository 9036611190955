import React, { useContext, useState } from "react";
import JobChoice from "./JobChoice";
import UploadCvsSteps from "./UploadCvsSteps";
import UploadedList from "./UploadedList";
import ExtractWithUpload from "./ExtractWithUpload";

import { useTranslation } from "react-i18next";
export default function UploadCvsContainer() {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [idJobPost, setIdJobPost] = useState("");
  const [jobPost, setJobPost] = useState({});

  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState(0);

  const [files, setFiles] = useState("");
  const [parsedAttachments, setParsedAttachments] = useState(0);

  //matching
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [byCompanyCandidates, setByCompanyCandidates] = useState(false);
  const [dateLimitGt, setDateLimitGt] = useState("");

  const next = () => {
    setCurrent(current + 1);
  };

  const previous = () => {
    setCurrent(current - 1);
  };
  return (
    <div className="container">
          <h3 className='titrePage'> {t("Télécharger des CV depuis l'ordinateur")}</h3>

      <br />
      <UploadCvsSteps current={current} setCurrent={setCurrent} />
      <div>
        {current == 0 && (
          <JobChoice
            next={next}
            setIdJobPost={setIdJobPost}
            setJobPost={setJobPost}
            idJobPost={idJobPost}
          />
        )}
        {current == 1 && (
          <ExtractWithUpload
            setCurrent={setCurrent}
            next={next}
            previous={previous}
            idJobPost={idJobPost}
            setAttachments={setAttachments}
            files={files}
            setFiles={setFiles}
            parsedAttachments={parsedAttachments}
            setParsedAttachments={setParsedAttachments}
            candidates={candidates}
            setCandidates={setCandidates}
            jobPost={jobPost}

          />
        )}

        {current == 2 && (
          <UploadedList
          files={files}
          parsedAttachments={parsedAttachments}
            candidates={candidates}
            selectedRowKeys={selectedRowKeys}
            byCompanyCandidates={byCompanyCandidates}
            dateLimitGt={dateLimitGt}
            setSelectedRowKeys={setSelectedRowKeys}
            setByCompanyCandidates={setByCompanyCandidates}
            setDateLimitGt={setDateLimitGt}
            setCurrent={setCurrent}
            next={next}
            attachments={attachments}
            idJobPost={idJobPost}
            jobPost={jobPost}
          />
        )}
      </div>
    </div>
  );
}
