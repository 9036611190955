import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import featurebg from "../../assets/images/features/features-bg.png";
import { withTranslation } from 'react-i18next';
class FeatureBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.features.map((feature, key) =>
          feature.id % 2 !== 0 ? (
            <Row
              key={key}
              className={
                feature.id === 1
                  ? "align-items-center"
                  : "align-items-center mt-5"
              }
            >
              <Col
                md={5}
                style={{ background: `url(${featurebg}) center center` }}
              >
                <div className="features-img">
                  <img
                    src={feature.img}
                    alt=""
                    className="img-fluid d-block mx-auto"
                  />
                </div>
              </Col>
              <Col md={{ size: 6, offset: 1 }}>
                <div className="features-box mt-5 mt-sm-0 mb-4">
                  <div className="features-icon my-4">
                    <i className={feature.icon}></i>
                  </div>
                  <h5 className="text-dark font-weight-normal mb-3 pt-3 text-uppercase">
                    {feature.title}
                  </h5>
                  <p className="text-muted mb-3 f-18">{feature.desc}</p>
                  <ul>
                    {feature?.list?.map((data, key) => (
                      <li className=" text-muted  f-18" >{data}</li>
                    ))}
                  </ul>
                  <Link to={feature.link} >
                    <b  role="button"
                      className="btn  d-flex align-items-center justify-content-center"
                      style={{padding:"17px",backgroundColor: "#3866be",color: "#ffffff"}}
                      >
                      <span className=" d-flex align-items-center ">
                      {this.props.t("DEMANDER UNE DEMO")}{" "}
                      <span className="right-icon ml-2" style={{margin: "0px 0px 6px 9px"}}>&#8594;</span>
                      </span>
                    </b>
                  </Link> 
                </div>
              </Col>
            </Row>
          ) : (
            <Row key={key} className="align-items-center mt-5">
              <Col md={6}>
                <div className="features-box mb-4">
                  <div className="features-icon my-4">
                    <i className={feature.icon}></i>
                  </div>
                  <h5 className="text-dark font-weight-normal mb-3 pt-3 text-uppercase">
                    {feature.title}
                  </h5>
                  <p className="text-muted mb-3 f-18">{feature.desc}</p>
                  <ul>
                    {feature?.list?.map((data, key) => (
                      <li className=" text-muted  f-18">{data}</li>
                    ))}
                  </ul>
                  <Link to={feature.link}>
                    <b  role="button"
                        className="btn  d-flex align-items-center justify-content-center"
                        style={{padding:"17px",backgroundColor: "#3866be",color: "#ffffff"}}
                        >
                        <span className=" d-flex align-items-center ">
                        {this.props.t("DEMANDER UNE DEMO")}{" "}
                        <span className="right-icon ml-2" style={{margin: "0px 0px 6px 9px"}}>&#8594;</span>
                        </span>
                      </b>
                  </Link>
                </div>
              </Col>
              <Col
                md={{ size: 5, offset: 1 }}
                className="mt-5 mt-sm-0"
                style={{ background: `url(${featurebg}) center center` }}
              >
                <div className="features-img">
                  <img
                    src={feature.img}
                    alt=""
                    className="img-fluid d-block mx-auto"
                  />
                </div>
              </Col>
            </Row>
          )
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(FeatureBox);
