import { DatePicker, Modal } from 'antd';
 import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AddInterviewForm from '../interviews/InterviewForm';
import { AppContext } from '../../routes/AppContext';

moment.locale('fr')

export default function InterviewsForm(props) {
  const { serverTime } = useContext(AppContext);

  const [update, setUpdate] = useState(false)
  let date = moment(serverTime)
  const [year, setYear] = useState(date.year())
  const [month, setMonth] = useState(date.month())
  const [day, setDay] = useState(date.date())
  const [hour, setHour] = useState(date.hour())
  const [minutes, setMinutes] = useState(date.minutes())

  const onChangeDate = (e) => {
    setDay(e.date())
    setMonth(e.month())
    setYear(e.year())
  }
  const onChangeTime = (e) => {
    setHour(e.hour())
    setMinutes(e.minutes())
  }
  const disabledDate = (current) => {
    return current < moment(serverTime);
  }
  const add = (interview) => {
    let interviews = props.interviews;
    // let date=new Date(year,month,day,hour,minutes,0)
    interviews.push(interview)
    props.setInterviews(interviews)
    setUpdate(!update)
  }
  const deleteInterview = (index) => {
    let interviews = props.interviews;
    interviews.splice(index, 1);
    // interviews.push({date})
    props.setInterviews(interviews)
    setUpdate(!update)
  }
  let i = -1
  let list = props.interviews.map(interview => {
    i++
    const index = i
    return (
      <li >
        <div className='d-flex  justify-content-between' >
          {moment(interview.date).format("LLLL")}
          <i
            title="Supprimer"
            className="  text-danger"
            role="button"
            onClick={() => {
              // alert("fassakh baba fassakh")
              Modal.confirm({
                title: "Supprimer offre",
                icon: <ExclamationCircleOutlined />,
                content:
                  "voulez vous supprimer le date " + moment(interview.date).format("LLLL"),
                okText: "Supprimer",
                okType: "danger",
                cancelText: "Annuler",
                onOk: () => {
                  deleteInterview(index)
                  // this.Delete(jobPost._id, index);
                },
                // onCancel:()=>{alert(index)}
              });
              //
            }}
          >
            <TrashIcon fill="#e25757" width="15px" />
          </i>
        </div>
      </li>
    )
  });
  return <div>
    {/* <DatePicker 
                        placeholder="DD-MM-YYYY"

                        format={"DD-MM-YYYY"}
                        disabledDate={disabledDate}
      
      onChange={onChangeDate} className="m-1" />
      <DatePicker picker={'time'} onChange={onChangeTime} className="m-1" />
      <button
               onClick={add} 
               className='btn btn-primary btn-sm rounded-circle p-0 m-1 '>
            <i className=" m-1">
            <PlusIcon fill="white" width="1.5rem" />

            </i>
                </button> */}
    <AddInterviewForm add={add} />
    <br />
    {props.applications.length > props.interviews.length && <small className='text-danger' > - Merci d'ajouter au moins <b>{props.applications.length}</b> dates de disponibilité d'entretiens !</small>}
    <ol> {list}</ol>
  </div>;
}
