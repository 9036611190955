import React, { Fragment, createContext, useReducer } from "react";
// import Layout from "../layout";
import { homeState, homeReducer } from "./HomeContext";
import PackagesTable from "./PackagesTable";
import SingleProduct from "./SingleProduct";

/* This context manage all of the home component's data */
// export const HomeContext = createContext();

const HomeComponent = () => {
  return (
    <Fragment>
      <br />
      {/* Product Section */}
      {/* <FreePlan/> */}
      <SingleProduct />
      <PackagesTable/>
    </Fragment>
  );
};

const Home = (props) => {
  /* To use useReducer make sure that reducer is the first arg */
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      {/* <HomeContext.Provider value={{ data, dispatch }}> */}
        {/* <Layout children={<HomeComponent />} /> */}
        <HomeComponent />
      {/* </HomeContext.Provider> */}
    </Fragment>
  );
};

export default Home;
