import axios from "../../../interceptors/axios";
export const GetAcceptedCandidates = async (data, headers) => {
  try {
    let res = await axios.get("/api/smartContract/candidates/get", {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
