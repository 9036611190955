import { Collapse } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import './Faq.css'
import Colaps from './Colaps';
import { GetFaqAll } from '../../services/api/faq/GetFaqAll';
import decrypt from '../../utils/decrypt';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../routes/AppContext';
const { Panel } = Collapse;
const text = `
A dog is a type of domesticated animal.
Known for its loyalty and faithfulness,
it can be found as a welcome guest in many households across the world.
`;
const Faq = () => {
  const [faqAlll, setFaqAlll] = useState([])
  const [myRole, setMyRole] = useState(0)
  const { i18n,t } = useTranslation();
  const {role} = useContext(AppContext);

  const language=i18n.language 
  const questions={"fr":"question_fr","en":"question_en","ar":"question_ar"}
const question=questions[language]
const reponses={"fr":"reponse_fr","en":"reponse_en","ar":"reponse_ar"}
const reponse=reponses[language]
  const getFaq=async()=>{
    let response = await GetFaqAll()
    if(response){
      const blog=JSON.parse(decrypt(response.data))
      setFaqAlll(blog)
      console.log(blog)
    }
     }
     useEffect(()=>{
      getFaq()
      if(role==="company" || role==="responsible"){
        setMyRole(1)
      }else if(role==="candidate"){
        setMyRole(2)
      }
    
     },[])
  return (
    <div>
      <div className="about">{t("About")}</div>
      <div className="faq">{t("FAQ")}</div>
      {
        faqAlll.map((item)=>{

          if(item.role===0 || item.role===myRole)
          return (<Colaps question={item[question]} reponse={item[reponse]} />)
          else return;
        })
      }
    </div>
  )
}

export default Faq
