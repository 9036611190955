import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import en from '../../media/image/enflag.svg';
import fr from '../../media/image/frflag.svg';
import ar from '../../media/image/flagar.png';
import { t } from 'i18next';

function LanguageSelector() {
  const { i18n,t } = useTranslation();
  const [affiche, setAffiche] = useState(false)
  const options = [
    { value: 'en', label: t("Anglais"), icon: en },
    { value: 'fr', label: t("Français"), icon: fr },
    { value: 'ar', label: t("Arabe"), icon: ar },
    // Ajoutez d'autres options de langue ici
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #ddd',
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected ? '#007bff' : 'transparent', // Background transparent
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      transition: 'background-color 0.2s ease'
    }),
    control: (provided, state) => ({
      ...provided,
      // marginTop: 10,
      borderColor: state.isFocused ? '#007bff' : '#ccc',
      boxShadow: 'none', // Optional: remove the shadow
      '&:hover': {
        borderColor: state.isFocused ? '#007bff' : '#bbb'
      },
      borderRadius: 10,
      backgroundColor: 'transparent', // Background transparent
      color: '#fff', // Text in white
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff', // Text in white
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 10,
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    }),
    container: (provided) => ({
      ...provided,
      // width: 200
    })
  };

  const formatOptionLabel = ({ value, label, icon }) => (
    <div style={{ display: 'flex', alignItems: 'center',cursor:"pointer" }} 
    // onClick={()=>handleClick(value)}
    >
      <img src={icon} alt={label} style={{ width: 30, marginRight: 5 }} /> 
      <div className='lang-hide'>{label}</div> 
      {""}
    </div>
  );

  const handleChange = (selectedOption) => {
    // setAffiche(false)
    const lng = selectedOption.value;
    localStorage.setItem('ln', lng);
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };
  // const handleClick = (selectedOption) => {
  //   setAffiche(false)
  //   const lng = selectedOption;
  //   localStorage.setItem('ln', lng);
  //   i18n.changeLanguage(lng);
  //   moment.locale(lng);
  // };

  return (
    <div className='select-lang'>
      <Select
      value={options.find(option => option.value === i18n.language)}
      options={options}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles}
      isSearchable={false} 
      // onFocus={()=>{setAffiche(true)}}
      // onBlur={()=>{setAffiche(false)}}
      // onDropdownVisibleChange={()=>{console.log("onDropdownVisibleChange")}}
      // onSelect={()=>{console.log("onSelect")}}
      // onInputKeyDown={()=>{console.log("onInputKeyDown")}}
      // openMenuOnClick={()=>{console.log("openMenuOnClick")}}
      // onClick={()=>{console.log("onClick")}}
      />
    </div>
  );
}

export default LanguageSelector;
