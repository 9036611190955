import { Modal, Timeline } from "antd";
import React, { useContext } from "react";
import PenIcon from "../../icons/PenIcon";
import XMarkIcon from "../../icons/XMarkIcon";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../routes/AppContext";

import { DeleteProject } from "../../../services/api/cv/project/DeleteProject";
import CvCercle from "../../cvPDF/template/cv-cercle/CvCercle";
import { useTranslation } from "react-i18next";
const ProjectComponents = (props) => {
    const {user,setUser,headers} = useContext(AppContext);
    const { t } = useTranslation();
  let { project, getDate } = props;
  const updateProject = async () => {
    props.setIndex(props.index);
    props.setModalVisible(true)
  };
  const deleteProject = async () => {
    Modal.confirm({
      title: t("Supprimer projet"),
      icon: <ExclamationCircleOutlined />,
      content:
      t("voulez vous supprimer le projet")+ project.title,
      okText: "Supprimer",
      okType: "danger",
      cancelText: "Annuler",
      onOk: async() => {
        //send request
        let res=await  DeleteProject({id:project._id},headers)
        //update context
        let cv = user
        cv.projects.splice(props.index, 1);
        setUser(cv)
        props.updateView()
      },
      // onCancel:()=>{alert(index)}
    });
  };
  let description =
  project.description
    .slice(0, 200) + " ...";
  return (
    

    <Timeline.Item dot={<CvCercle/>}  >
        <div className="time">
            <div className="time-title">
            {project.title}
                <PenIcon
                onClick={updateProject}
                className="me-2   zoom1"
                width="1rem"
                fill="blue" style={{marginLeft: "10px"}}
                />
                <XMarkIcon onClick={deleteProject} className="me-2 zoom1" width="1rem" fill="red" />
            </div>
            <div className="time-body">

            <div className="time-date">
            {getDate(project.startDate, project.endDate)}
            </div>
            <div className="time-cont">
            {description}
            </div>
            </div>
        </div>
    </Timeline.Item>
  )
}

export default ProjectComponents