import React, { useContext, useMemo, useState } from 'react';
import { notification, Select } from 'antd';
 import moment from 'moment-timezone';
import axios from 'axios';

import {TagTest} from "../../services/api/companyTest/TagTest"
import {GetTests} from "../../services/api/companyTest/GetTests"
import AsyncImage from '../AsyncImage';
import img from "../../media/image/pc-computer.gif"
import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';


const { Option } = Select;
export default function TestsForm(props) {
    const [selected,setSelected]=useState(false)
    const [idTest,setIdTest]=useState(props.idTest)
    const [defaultValue,setDefaultValue]=useState(null)
  const {headers} = useContext(AppContext);
  const { t } = useTranslation();

const getTests=async()=>{
  let response= await GetTests({},headers)
if(response){
let data=JSON.parse(decrypt(response.data))
props.setTestsNumber(data.length)
      if(props.idTest){
      for (let i = 0; i <data.length ; i++) {
          if(data[i]._id==props.idTest){
            setDefaultValue(data[i].title)
          }
        }
        }
return data
}}
  const  handleSelect=(value,i)=> {
        // this.setState({ skill:value,key:i.key });
         setIdTest(tests[i.key]._id)
         setDefaultValue(tests[i.key].title)
         setSelected(true)
      }
      const  handleSubmit=()=> {if(idTest)
       {setSelected(false)
        TagTest({
          
          idTest:idTest,
          idJobPost:props.idJobPost
  
        },headers)
        props.setIdTest(idTest)
        ;}}
        const { data, isLoading, isError } = useQuery(
          ['tests'], // Query key
          getTests
        );
        let tests=data??[]
      const children = [];
  for (let i = 0; i <tests.length ; i++) {
  children.push(<Option key={i} value={tests[i].title}>{tests[i].title}</Option>);
  }
  return <div className='mt-2' >
      <div>
<h5> {t("Veuillez sélectionner un test technique pour cette offre d'emploi")} </h5>
<br/>
<div className='m-3 d-flex justify-content-center' >
                 <AsyncImage 

className="w-50"
  src={img} /></div>
  <div className='d-flex justify-content-center' >
<Select loading={isLoading} className=" w-50"  
                  // style={{ width: '40%' }} 
                   value={defaultValue}
    placeholder={t("Rechercher pour sélectionner")} onChange={handleSelect}>
    {children}
  </Select>
  {selected?<span onClick={handleSubmit} className='ms-2 btn btn-primary  p-1' >
    {t("Confirmer")}</span>:<span className='ms-2 btn btn-secondary  p-1' >
    {t("Confirmer")}</span>
  //   :
  // <span onClick={handleSubmit} className='ms-2 btn btn-primary rounded-circle p-1' ><i className="">
  //   <CheckIcon fill="white" width="1.5rem" />
  //   </i></span>
    }
  <br/></div>
{/* <p className="text-danger"><small>{locationError}</small></p> */}
  </div></div>;
}
