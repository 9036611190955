import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, withRouter } from "react-router-dom";
import JobPostPreview from "../components/manageJobPosts/JobPostPreview";
import { AppContext } from "../routes/AppContext";
import { GetJobPostByRef } from "../services/api/manageJobPosts/GetJobPostByRef";
import decrypt from "../utils/decrypt";

 function CompanyJobPostView(props) {
  const { role,user,headers } = useContext(AppContext);

  const getJobPost=async()=>{
    let res = await GetJobPostByRef(
      { reference: props.reference },
      headers
    );
    if(res){
      const jobPostFromDB=JSON.parse(decrypt(res.data))
      return jobPostFromDB
    }
  }
  const { data: jobPost, isLoading, isError } = useQuery(
    ['jobPost','ref', props.reference], // Query key
   getJobPost
  );
  return (
    <div>
   {!isLoading &&  <JobPostPreview
        // userId={props.userId}
        company={jobPost?.idCompany}
        jobPost={jobPost}
      />}
    </div>
  );
}
export default withRouter(CompanyJobPostView);
