import React, { useContext, useEffect, useMemo, useState } from "react";
import Test from "../test/Test";
import TestDetails from "./TestDetails";
import TestResult from "./TestResult";
import TestQuestions from "./TestQuestions";
import jwt from "jsonwebtoken";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { Modal, notification } from "antd";
import { GetTest } from "../../services/api/companyTest/GetTest";
import { StartTest } from "../../services/api/companyTest/StartTest";
import decrypt from "../../utils/decrypt";
import TestDescription from "./TestDescription";
import { GetTestDescription } from "../../services/api/companyTest/GetTestDescription";
import { GetTestDescriptionByLink } from "../../services/api/companyTest/GetTestDescriptionByLink";
import { GetTestDescriptionByIdCandidate } from "../../services/api/companyTest/GetTestDescriptionByIdCandidate";
import { AppContext } from "../../routes/AppContext";
import { StartLinkTest } from "../../services/api/companyTest/StartLinkTest";
import { StartIdCandidateTest } from "../../services/api/companyTest/StartIdCandidateTest";

function JobPostTestingContainer(props) {
  const history = useHistory({});
  const { user,setTestLanguage,headers,role } = useContext(AppContext);

  const [name,setName]=useState("")
  const [email,setEmail]=useState("")
  const [emailCandidat, setEmailCandidat] = useState("")
  const [emailCandidattt, setEmailCandidattt] = useState("")
  const [registered, setRegistered] = useState(false);
  const [authenticated, setAuth] = useState(true);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [time, setTime] = useState(0);
  const [timePerQuestion, setTimePerQuestion] = useState([]);
  
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [idCandidateTest, setIdCandidateTest] = useState(null);
  const [idTest, setIdTest] = useState(props.idTest);
  const [idCompany, setIdCompany] = useState(props.idCompany);
  const [idJobPost, setIdJobPost] = useState(props.idJobPost);
  const [testDescription, setTestDescription] = useState({});
  const [questiony, setQuestiony] = useState([]);
  // const [questionyShuff, setQuestionyShuff] = useState([]);
  const [questEnd, setQuestEnd] = useState(false);
  const params = useParams();

  const getTestDescription = async () => {
    let response = await GetTestDescription(
      { idTest: idTest },
      headers
    );

    if (response) {
      let data = JSON.parse(decrypt(response.data));
      setTestDescription(data);
      setTestLanguage(data.languages[0])
      setName(user.name)
      setEmail(user.email)
    }
  };

  const getTestDescriptionByLink = async () => {

    let response = await GetTestDescriptionByLink(
      { link: params.link },
      headers
    );

    if (response) {
      let data = JSON.parse(decrypt(response.data));
      setTestDescription(data);
      setTestLanguage(data.languages[0])
      setIdTest(data._id)
      setIdCompany(data.idCompany)
    }
  };
  const getTestDescriptionByIdCandidate = async () => {
    let response = await GetTestDescriptionByIdCandidate(
      { id: params.id },
      headers
    );

    if (response) {
      let data = JSON.parse(decrypt(response.data));
      setTestDescription(data.test);
      setTestLanguage(data.test.languages[0])
      setName(data.name)
      setEmail(data.email)
      setIdCandidateTest(params.id)
      setIdTest(data.test._id)
      setIdCompany(data.test.idCompany)
      setIdJobPost(data.idJobPost)

    }
  };
  const companyTestingTest=async()=>{
//show description
//pass the authentication
setRegistered(true)
setAuth(true)
setQuestions(props.test.questions)
setTestDescription(props.test)
setTestLanguage(props.test.languages[0])

//start test
//don't save
  }
  useEffect(() => {
    if(user.email)
    setEmailCandidat(user.email)
    setEmailCandidattt(user.email)
    console.log(user.email)
  },[user]);

  useEffect(() => {
    switch (props.method) {
      case 0:
        getTestDescription()
        break;
      case 1:
        getTestDescriptionByLink()
        break;
      case 2:
        getTestDescriptionByIdCandidate()
        break;      
        case 3:
        companyTestingTest()
        break;
      default:
        break;
    }
  }, []);

  // useEffect(async() => {
  //   if(role==="candidate"){
  //   if(questEnd){
  //       let response = await GetTest(
  //       { id: idTest },
  //       headers
  //     );
  //     if (response) {
  //       const datatesty = JSON.parse(decrypt(response.data));
  //       setQuestionyShuff(datatesty)
  //     }
  //   }
  // }    
  // }, [questEnd]);
  const getTest = async () => {
    let response = await GetTest(
      { id: idTest },
      headers
    );

    if (response) {
      const datat = JSON.parse(decrypt(response.data));
      const dataCopie =datat
       dataCopie.map(res=> {
        res.answers.map(ans=> {delete ans.isCorrect}) })
      setQuestions(dataCopie);
      setStarted(true);
    }
  };
  const startLinkTest=async()=>{
    let response = await StartLinkTest(
      {
        idTest: idTest,
        name:name,
        email:email
      },
      headers
    );
    if (response == undefined) {
      history.push("/candidate/applications");
    } else if (response?.status == 200) {
      let data = JSON.parse(decrypt(response.data));
      setIdCandidateTest(data.idCandidateTest);
      getTest();
    }
  }
  const startIdCandidateTest=async()=>{
    let response = await StartIdCandidateTest(
      {
        idTest: idTest,
        idJobPost: idJobPost, 
        idCandidateTest: idCandidateTest, 
        name:user.name

      },
      headers
    );
    if (response == undefined) {
      history.push("/candidate/applications");
    } else if (response?.status == 200) {
      let data = JSON.parse(decrypt(response.data));
      getTest();
    }
  }
  const startTest = async () => {
    switch (props.method) {
      case 0:
        let response = await StartTest(
          {
            idTest: idTest,
            idJobPost: idJobPost, 
            name:user.name
          },
          headers
        );
        if (response == undefined) {
          history.push("/candidate/applications");
        } else if (response?.status == 200) {
          let data = JSON.parse(decrypt(response.data));
          setIdCandidateTest(data.idCandidateTest);
          getTest();
        }
        break;
      case 1:
        startLinkTest()
        break;
      case 2:
        startIdCandidateTest()
        break;
        case 3:
          setStarted(true)
        break;
        
      default:
        break;
    }
    
  };
  const pushAnswer = (answer) => {
    let toPush = answers;
    toPush.push(answer);
    setAnswers(toPush);
  };
  const shuffle = (a) => {
    for (let i = a?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };
  const shuffledQuestions = useMemo(() => shuffle(questions), [questions]);
  // const shuffledQuestionsss = useMemo(() => shuffle(questionyShuff), [questionyShuff]);
  const finishTest = (time,timePerQuestion) => {
    setTime(time);
    setTimePerQuestion(timePerQuestion);
    setFinished(true);
  };

  return (
    <div className={!user.name?"py-5 mt-5 px-2 d-flex justify-content-center align-items-center":""} style={{minHeight:!user.name?"86vh":""}} >
      {!registered ? (
        <TestDescription name={name} emailCandidattt={emailCandidattt} email={emailCandidat} setName={setName} setEmail={setEmailCandidat} testDescription={testDescription} setRegistered={setRegistered} />
      ) : (
        !authenticated && <Test  setAuth={setAuth} />
      )}
      {registered && authenticated && !started && !finished && (
        <TestDetails testDescription={testDescription} getTest={startTest} questions={shuffledQuestions} />
      )}
      {/* condidtion original */}
      {/* {started && !finished && ( */}
      {started && !finished && (
        <TestQuestions
          setTime={setTime}
          pushAnswer={pushAnswer}
          questions={shuffledQuestions}
          answers={answers}
          setAnswers={setAnswers}
          finishTest={finishTest}
          isCompany={props.method==3}
          test={testDescription}
          setQuestEnd={setQuestEnd}
          role={role}
          idCandidateTest={idCandidateTest}
        />
      )}
      {finished && (
        <TestResult
          name={name}
          email={emailCandidat}
          idCompany={idCompany}
          idJobPost={idJobPost}
          idTest={idTest}
          idCandidateTest={idCandidateTest}
          userId={props.userId}
          testTime={props.testTime}
          time={time}
          timePerQuestion={timePerQuestion}
          
          questions={shuffledQuestions}
          answers={answers}
          isCompany={props.method==3}
          method={props.method}
        testDescription={testDescription}
        // questiony={shuffledQuestionsss}
        />
      )}
   
    </div>
  );
}
export default withRouter(JobPostTestingContainer);
