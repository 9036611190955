import axios from "../../../interceptors/axios"
export const GetTest =async (data,headers) => {
    try {
      let res=await  axios.get('/companyTest/get/'+data.id,
      {
       headers: headers
     })
     return res
  }
 catch (err) {
    }
  }