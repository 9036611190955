import axios from "../../../interceptors/axios";
export const RecommendCandidate = async (data, headers) => {
  try {
    let res = await axios.post('/api/recommend/send', data, {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
