import React, { Fragment, useContext, useEffect, useState } from "react";
import "antd/dist/antd.css";
import { message, Progress, notification, Space, Spin, Modal } from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import AffichePFD from "./affiche_PDF";
import jwt from "jsonwebtoken"
import decrypt from "../../../utils/decrypt";
import { AppContext } from "../../../routes/AppContext";
import { useHistory } from "react-router-dom";
import AsyncImage from "../../AsyncImage";
import gif from "../../../media/image/profil.gif"
import { UploadTestService } from "../../../services/api/companyTest/UploadTestService";
import image from "../../../media/image/testUpload.png"
import Dropzone from "../../Dropzone";
import { useTranslation } from "react-i18next";


const UploadTest = (props) => {
  const history = useHistory({});
  const { t } = useTranslation();

  const [afficheProgress, setafficheProgress] = useState(false);
  const [statusprogresse, setstatusprogresse] = useState("");
  const [styleprogresse, setstyleprogresse] = useState({ display: "none" });
  const [file, setFile] = useState("");
  const [FileName, setFileName] = useState(" Nom du fichier ");
  const [verif, setverif] = useState("");
  const [verifupload, setverifupload] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);
  
  const [questions, setQuestions] = useState([]);
  const {role,myFunctionalities,headers} = useContext(AppContext);


  function beforeUpload(file) {
    if (file !== "") {
      let typeFile = file.type.split(".").pop();
      let acceptedTypes = ["application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        // "application/rtf",
        "application/vnd.oasis.opendocument.text",
        // "image/png",
        // "image/jpeg",
        "document",
        "text/plain",
        "application/pdf"]
      const isJpgOrPng = acceptedTypes.includes(typeFile)
      if (!isJpgOrPng) {
        message.error(
          t("Vous ne pouvez télécharger que les types de fichiers Pdf - doc - docx - txt")
        );
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error(t("L'image doit être plus petite que 5MB!"));
      }
      return isJpgOrPng && isLt5M;
    } else {
      message.warning(t("Sélection votre test svp!"));
      return false;
    }
  }
  function getBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //convert it to text here
      setUploadedFile(reader.result);
      return reader.result;
    };
    reader.onerror = function (error) {
      return reader.result;
    };
  }
  const onChange = (files) => {
    setFile("");
    setverif("");
    setFileName("");
    setverifupload(false);
    setFileName(files[0].name);
    setFile(files[0]);
    getBase64(files[0]);
   let printedTypes= ["text/plain","application/pdf"]
    let typeFile = files[0].type.split(".").pop();
    setShowFile(printedTypes.includes(typeFile));
  //   else
     setverif(beforeUpload(files[0]));
    
    setafficheProgress(false);
    setstatusprogresse("");
    // document.getElementById("uploadedFile").scrollIntoView();
    // document.getElementById("uploadedFile").focus();
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setafficheProgress(true);
    setverif(false);
    let buffer = getBase64(file);
    const formData = new FormData();
    formData.append("idCompany", props.userId);
    formData.append("file", file);

    if (beforeUpload(file)) {
      setstyleprogresse({});

      try {
        let res = await UploadTestService(formData,{
            "Content-Type": "multipart/form-data",
            ...headers,
          })
      
        if (res?.status === 200) {
          setUploadPercentage(100);
          notification.open({
            description:
              t("Le test a été téléchargée avec succès, veuillez confirmer l'accès à l'analyse"),
            className: "custom-class ",
            icon: <UploadOutlined style={{ color: "#148f24" }} />,
            style: {
              width: "auto",
            },
          });
          let data=JSON.parse(decrypt(res.data))

          let questions = data.data;
          for (let i = 0; i < questions.length; i++) {
            // level:0,type:0,time:1,points:1,answers:[{answer:"",isCorrect:true}
            questions[i].level = 0;
            questions[i].type = 0;
            questions[i].time = 60;
            questions[i].points = 1;
            questions[i].question_fr = questions[i].question
            questions[i].question_en = questions[i].question
            let answers = [];
            for (let j = 0; j < questions[i].answers.length; j++) {
              let answer = {
                answer_fr: questions[i].answers[j],
                answer_en: questions[i].answers[j],
                isCorrect: false,
                type:0
              };
              answers.push(answer);
            }
            questions[i].answers = answers;
          }
          setQuestions(questions);
          // let parsedJobPost= res.data.data
         
          history.push({
            pathname: "/"+role+"/addTest/form",
            state: { test:{questions:questions,description:"",
              title:""} },
          
          });
    
          setafficheProgress(false);
          setverifupload(true);
          setstatusprogresse("active");
        }
      } catch (err) {
        setstatusprogresse("exception");
        setafficheProgress(false);
        // notification.open({

        //   description: "erreur avec le serveur, veuillez réessayer ! ",
        //   className: "custom-class ",
        //   icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        //   style: {
        //     width: "auto",
        //   },
        // });
      }
    }
  };
  return (
    <div className="UploadJobPostPage" >
    <div className="uploadFile">
      <h3 className="titrePage">{t("Télécharger votre test")}</h3>
      <div className="modal-body">
        <Fragment>
          {/* {message ? <Message msg={message} /> : null} */}
          <form onSubmit={onSubmit}>
            {/* <div className="input-group">
              <label className="input-group-btn">
                <span className="btn btn-primary">
                Choisir un fichier &hellip;{" "} */}
                  {/* <input
                    id="formFileSm"
                    type="file"
                    onChange={onChange}
                    style={{ display: "none" }}
                  /> */}
                                   <Dropzone image={image} onDrop={onChange} />

                {/* </span>
              </label>
              <input
                type="text"
                className="form-control"
                value={FileName}
                readonly
              />
            </div> */}
            <div id="uploadedFile" >
            {verif ? (
              <>
               {showFile ? <AffichePFD src={uploadedFile}  />:""}
                <input
                  type="submit"
                  value={t("Upload")}
                  className="d-flex justify-content-center btn btn-primary btn-block mt-4  mx-auto p-2"
                />
              </>
            ) : null}

{afficheProgress ? (
              <div className="d-flex justify-content-center">
                <Space size="large">
                  <Spin size="large" />
                </Space>
              </div>
            ) : null}
         </div>
          </form>
        </Fragment>
      </div>
    </div></div>
  );
};

export default UploadTest;
