import React, { useContext } from 'react'
import { Steps, Divider } from 'antd';
 import moment from 'moment-timezone';
import { AppContext } from '../../routes/AppContext';
import { useTranslation } from 'react-i18next';
const { Step } = Steps;
export default function JobPostStateComponent(props) {
  const { serverTime } = useContext(AppContext);
  const { t } = useTranslation();

  let current=0
  const jobPost = props.jobPost
  if (jobPost.closed) {
    current=3
     }
     else if (moment(jobPost.jobPostPublicationDate).isAfter(moment(serverTime))) {
      current=0
     } else if (moment(jobPost.jobPostExpirationDate).isBefore(moment(serverTime))) {
      current=4
     }
     else  if (!jobPost.published) {
      current=2
     }
     else current=1
  return (
    <div className=' rounded shadow p-3' >
            <Steps progressDot direction="vertical"  className="mb-5" current={current} >
                    <Step status="" description={moment(jobPost.jobPostPublicationDate).format("L")} title={t("Programmé")}  />
                    <Step status="" description={moment(jobPost.jobPostPublicationDate).format("L")} title={t("Publié")}  />
                    <Step status="" description="" title={t("En attente")}  />
                    <Step status="" description="" title={t("Archivé")} />
                    <Step status="" description={moment(jobPost.jobPostExpirationDate).format("L")} title={t("Expiré")} />
                </Steps> 
    </div>
  )
}

