import axios from "../../../interceptors/axios";
export const AddSkill = async (data, headers) => {
  try {
    let response = await axios.post(`/api/suggest/add`, data, {
      headers: headers,
    }, { withCredentials: true });
    return response;
  } catch (err) {
  }
};
