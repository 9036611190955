import { Modal, notification, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import EyeIcon from '../icons/EyeIcon';
import { PlusOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import EditIcon from '../icons/EditIcon';
import TrashIcon from '../icons/TrashIcon';
import { DeleteCompanyEmail } from '../../services/api/companyEmail/DeleteCompanyEmail';

import { AppContext } from '../../routes/AppContext';
import PenIcon from '../icons/PenIcon';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;

export default function CompanyEmails(props) {
  const { t,i18n } = useTranslation();

  const { headers,myFunctionalities, user } = useContext(AppContext);

  const deleteEmail=async(id)=>{
    let res =await DeleteCompanyEmail({
      id:id
    },headers)
    if(res){
      let emails=props.emails.filter(e=>e._id!=id)
      props.setEmails(emails)
    notification.success({
      description: res.data.message,
      className: "custom-class",
      style: {
        width: 600,
        backgroundColor: "#d4edda",
        marginTop: "5rem",
      },
    });}

}
useEffect(async()=>{
let  emFr=props.defaultEmails.filter(e=>e.language=="fr")
let emEn=props.defaultEmails.filter(e=>e.language=="en")

 if(props.emailCompany.length>0){
  for (let i = 0; i < props.emailCompany.length; i++) {
    emFr=emFr.filter(item=> props.emailCompany[i].eType !=item.eType)
    emEn=emEn.filter(item=> props.emailCompany[i].eType !=item.eType)
  }
  console.log("filteredFr",emFr)
  console.log("filteredEn",emEn)
  emFr.push(...props.emailCompany)
  emEn.push(...props.emailCompany)
 }
 props.setEmailFr(emFr)
 props.setEmailEn(emEn)
// emailCompany={emailCompany} defaultEmails={defaultEmails}
},[props.defaultEmails])

let max =
  myFunctionalities.find(
    (functionality) => functionality.fType == 17
    )?.qte ?? 0;
    // const defaultLang= i18n.language=="fr" ? "fr" : "en"
    const defaultLang= user.emailsLanguage=="en" ? "en" : "fr"

  return (
    <div>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
         <Table
         loading={props.loading}
          className="m-2"
          pagination={false}
          // dataSource={props.emails.filter(e=>e.language==defaultLang)}
          dataSource={user.emailsLanguage=="en" ? props.emailEn : props.emailFr}
          rowKey={(record) => {return record._id;
          }}
        >
          <Column
            align="center"
            sorter={(a, b) => a.model.localeCompare(b.model)}
            title={t("Email")}
            dataIndex="model"
            key="name"
          />
          <Column
            align="center"
            sorter={(a, b) => a.language.localeCompare(b.language)}
            title={t("Langue")}
            dataIndex="language"
            key="language"
            render={(text, email, index) => {
              const languEm=email?.language=='en' ? t("Anglais") : t("Francais") 
              return (
                <>
                  {languEm}
                </>
              );
            }}
          />

          <Column
            align="center"
            title={t("Actions")}
            dataIndex="Actions"
            key="Actions"
            render={(text, email, index) => {
              return (
                <div className="">
                  <i
                    role="button"
                    onClick={() => {
                      props.setEmail(email)
                      props.setModalVisible(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <EyeIcon width="1.2rem" fill="#676ee7" />
                  </i>

                  {(max>=email?.eType) || email.modified ? <i
                    role="button"
                    onClick={() => {
                      props.setEmail(email)
                      props.setModalFormVisible(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <PenIcon width="1.2rem" fill="blue" />
                  </i>:<i
                    role="button"
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <PenIcon width="1.2rem" fill="gray" />
                  </i>}
          
                </div>
              );
            }}
          />
    
        </Table>
        </ConfigProvider>
    </div>
  )
}
