import { Modal, notification } from 'antd';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ed from "../../media/image/ecRegistration.webp"
import { AppContext } from '../../routes/AppContext';
import { payOrder } from '../../services/api/pricing/payOrder'

import AsyncImage from '../AsyncImage'
export default function FreePackage(props) {
	const { t } = useTranslation();
	const { userId,orders,setOrders,fonctionnalites,setMyFunctionalities,headers,serverTime } = useContext(AppContext);
	const history = useHistory({});

	const Pay=async()=>{

		let fonctionnalities=[]
		let forfait=props.package.fonctionnalites[0].listefonctionnalite
		 for (let j = 0; j < forfait.length; j++) {
		   const item = forfait[j];
			   let fType= fonctionnalites.find((functionality) => functionality._id == item.idFonctionnalite)?.fType
			   fonctionnalities.push({idFonctionnalite:item.idFonctionnalite,qte:item.qte,fType:fType})
		 }
		let oneYearFromNow = new Date(serverTime);
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
		let order={  
			fonctionnalites:fonctionnalities,
			allProductF:[],
			allProduct:{id:props.package._id},
			user: userId,
			amount:0 ,
			ptfideliteOffert:0,
			ptfideliteUsed: 0,
			transactionId: "-",
			remise:0 ,
			status: "en cours",
			type: "Annuel",
			order: "Recrutement",
			category:"Gratuit",
			datefin:oneYearFromNow
		  }
		let res=await payOrder(order,headers)
		if(res){
			//notification
		  notification.success({
			message: t("Notification"),
			description:t("Félicitations ! Grâce à l'offre GRATUIT, vous pouvez maintenant explorer nos services."),
			className: 'custom-class',
			style: {
			  width: 600,
			  backgroundColor:"#d4edda",
			  marginTop:"5rem"
			},
		  });
		  setOrders([...orders,order])
		  setMyFunctionalities(fonctionnalities)
		//   history.push({
		// 	pathname: "/company/orders",
		//   });
		  window.location.replace('/company/orders');}

	}
  return (
	<>
	 <Modal
        title={<h5 className="m-1">{t("Contactez nous")}</h5>}
        className=""
        centered
        // closeIcon={
        //    <i className="mt-5 ">
        //      <CloseOutlined className="m-3" />
        //    </i>
        // }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={
          <>
            {/* <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer "
                onClick={() => {
                  props.history.push("/company/manageJobPosts");
                }}
              /> */}
            {/* <input
                type="button"
                className=" btn btn-primary p-1 rounded m-1 "
                value="Enregistrer"
                onClick={handleSubmit}
              /> */}
          </>
        }
      >
         <div>
		
        <div className="d-flex justify-content-center m-2 p-2">
				<div style={{backgroundColor:"rgb(237 245 251)"}} className="rounded p-4 "> 
					<div className='bg-white rounded' style={{ padding:"3rem", paddingBottom:"7rem"}} >
                        <div className="itemHead">
    						<h2> {t("Offre Gratuite")}</h2>
    						<span className="dimText">{t("Offre de lancement pour les nouveaux utilisateurs")}</span>
					</div>
					<div className="itemBody" style={{width:"90%", marginLeft: "5px"}}>
        					<div className="itemBodyLabel">
            						<span className="mdi mdi-check-circle-outline text-success"></span>
            						<span className="itemText">{t("Offre d'emploi visible")} </span>
        					</div>
        					<div className="itemBodyLabel">
            						<span className="mdi mdi-check-circle-outline text-success"></span>
            						<span className="itemText">{t("Gérer les réponses sur Rec-INOV")}</span>
        					</div>
        					<div className="itemBodyLabel">
            						<span className="mdi mdi-check-circle-outline text-success"></span>
            						<span className="itemText">{t("Accès illimité au nombre de candidatures")}</span>
        					</div>
        					<div className="itemBodyLabel">
            						<span className="mdi mdi-check-circle-outline text-success"></span>
            						<span className="itemText">{t("Pas de carte de crédit requise")}</span>
        					</div>
        					{/* <div className="itemBodyLabel">
            						<span className="mdi mdi-check-circle-outline text-success"></span>
            						<span className="itemText">No credit card required</span>
        					</div> */}
					</div></div>
					<div className="itemFooter">
                    <AsyncImage style={{    
                        width: "22.4rem",
    height: "10rem",
    marginTop: "-8%",
    marginLeft: "7%"}} src={ed} />

				</div>
			</div>
        	</div>
			<div className='d-flex justify-content-center' >
{orders.length?<button disabled className='btn btn-primary rounded m-2' >{t("Essayer gratuitement")}</button>:<button onClick={Pay} className='btn btn-primary rounded m-2' >{t("Essayer gratuitement")}</button>}

			</div>

    </div>
      </Modal>
    </>
  )
}
