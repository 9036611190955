import React, { useContext, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import file from "./modele-contrat-de-travail-cdi.pdf";
import file2 from "./company.pdf";
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import { SendContract } from "../../services/api/smartContract/SendContract";

import { AppContext } from "../../routes/AppContext";
import { CompanyContractSign } from "../../services/api/smartContract/CompanyContractSign";
import { useHistory, withRouter } from "react-router-dom";
import ContractSend from "./ContractSend";
import { CandidateContractSign } from "../../services/api/smartContract/CandidateContractSign";
import { Radio, Select } from "antd";
import ImageUploader from "./ImageUploader";
import moment from "moment-timezone";

const { Option } = Select;

function ContractSign(props) {
  const { role, userId, companyName,user,envVar,headers,serverTime } = useContext(AppContext);
  const history = useHistory({});
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  let pdf =
    props.cState == 1
      ? file
      : // file2
      envVar.REACT_APP_CLIENT_URL + "/api/company.pdf";
  const [PDF, setPDF] = useState(pdf);
  const [method, setMethod] = useState(0);

  let sigPad = {};
  const signContractAsCompany = async (file) => {
    const formData = new FormData();
    formData.append("jobPostTitle", props?.location?.state?.jobPostTitle);
    formData.append("email", props?.location?.state?.email);
    formData.append("name", props?.location?.state?.name);
    formData.append("deadline", props?.deadline);
    formData.append("responsibleName", props?.responsibleName);
    formData.append("idJobPost", props?.location?.state?.idJobPost);
    formData.append("companyName", companyName);
    formData.append("companyPhone", user.phone);
    formData.append("companyEmail", user.email);
    formData.append("idCandidate", props?.location?.state?.idCandidate);
    formData.append("file", file);
    let res = await CompanyContractSign(formData, headers);
    props.setCState(2);
  };
  const onChange=(e) => {setMethod(e.target.value);}
  const signContractAsCandidate = async (file) => {
    const formData = new FormData();
    
    formData.append("jobPostTitle", props?.jobPostTitle);
    formData.append("candidateEmail", user.email);
    formData.append("email", props?.email);
    formData.append("idJobPost", props?.idJobPost);
    formData.append("name", props.name);
    formData.append("idCompany", props.idCompany);
    formData.append("file", file);
    let res = await CandidateContractSign(formData, headers);
    //redirect
    history.push("/candidate/dashboard");
  };
  const clear = () => {
    sigPad.clear();
  };
  const trim = () => {
    let image = sigPad.getTrimmedCanvas().toDataURL("image/png");

    setTrimmedDataURL(image);
    createPDF(image);
  };
  const trim2 = () => {
    createPDF(trimmedDataURL);
  };
  const createPDF = async (image) => {
    // These should be Uint8Arrays or ArrayBuffers
    // This data can be obtained in a number of different ways
    // If your running in a Node environment, you could use fs.readFile()
    // In the browser, you could make a fetch() call and use res.arrayBuffer()
    const pngImageBytes = await fetch(image).then((res) => res.arrayBuffer());

    // Create a new PDFDocument
    // const pdfDoc = await PDFDocument.create()

    const PdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(PdfBytes);
    // Embed the JPG image bytes and PNG image bytes
    const pngImage = await pdfDoc.embedPng(pngImageBytes);
    // Get the width/height of the PNG image scaled down to 50% of its original size
    const pngDims = pngImage.scale(0.3);

    // Add a blank page to the document
    const page = pdfDoc.getPage(pdfDoc.getPageCount() - 1);
    // Draw the PNG image near the lower right corner of the JPG image

    page.drawImage(pngImage, {
      x: props.cState == 1 ? page.getWidth() - pngDims.width - 80 : 70,
      y: page.getHeight() - pngDims.height - 155,
      width: pngDims.width,
      height: pngDims.height,
    });
    let text =
      (props.cState == 1 ? companyName : props.name) +
      " " +
      moment(serverTime).format("LLLL");
    page.drawText(text, {
      x: props.cState == 1 ? page.getWidth() - 210 - text.length : 30,
      y: page.getHeight() - pngDims.height - 155 - 12,
      size: 12,
    });
    let text2 =
      "En signant electroniquement le présent document, le salarié accepte les termes du contrat , l'ordre de \n mission ainsi que l'ensemble des annexes et notices d'information qui y sont associées.";
    page.drawText(text2, {
      x: 15,
      y: 35,
      size: 12,
    });
    // const form = pdfDoc.getForm();
    // form.getTextField('signature').setText('Mario');
    // const singlelineCenteredTf = form.createTextField(
    //   'signature entreprise',
    // );
    // singlelineCenteredTf.setText('');
    // singlelineCenteredTf.addToPage(page, {
    //   y:  50,
    //   width: 250,
    //   height: 25,
    //   borderWidth: 3,
    // });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    setPDF(blobUrl);
    if (props.cState == 1) {
      signContractAsCompany(blob);
    } else if (props.cState == 3) {
      download(
        pdfBytes,
        "pdf-lib_image_embedding_example.pdf",
        "application/pdf"
      );
      signContractAsCandidate(blob);
    }
  };

  return (
    <div>
      <div
        onContextMenu={(e) => e.preventDefault()}
        className="modal-dialog  modal-lg "
      >
        <h4>Votre Contrat </h4>
        <div className="modal-content" style={{ height: "350px" }}>
          <div className="modal-body" id="modal-body">
            <iframe
              src={PDF + "#toolbar=0"}
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="modal-dialog  modal-lg  ">
        <h4>Votre signature   </h4>
        </div>
        <div className="" >
          <div className="d-flex justify-content-center m-1">
   <Radio.Group onChange={onChange}value={method}>
      <Radio value={0}>manuellement</Radio>
      <Radio value={1}>upload</Radio>
    </Radio.Group>
      </div>
        {method == 0 && (
          <>
            <div className="d-flex justify-content-center m-1">
              <SignatureCanvas
                penColor="blue"
                ref={(ref) => {
                  sigPad = ref;
                }}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas border border-3 rounded",
                }}
              />
            </div>

            <div className="d-flex justify-content-center">
              <button onClick={clear} className="btn btn-danger rounded m-1">
                Reset
              </button>
              <button onClick={trim} className="btn btn-primary rounded m-1">
                Signer
              </button>
            </div>
          </>
        )}
        {method == 1 && (<>
          <div className=" text-center d-flex justify-content-center m-1">
            <ImageUploader
              image={trimmedDataURL}
              setImage={setTrimmedDataURL}
            /></div>
            <div className="d-flex justify-content-center">
              <button onClick={trim2} className="btn btn-primary rounded m-1">
                Signer
              </button>
            </div>
          </>
        )}
        {/* {trimmedDataURL
        ? <img className=""
          src={trimmedDataURL} />
        : null} */}
      </div>
    </div>
  );
}
export default withRouter(ContractSign);
