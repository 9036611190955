import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Tooltip,
} from "antd";
import jwt from "jsonwebtoken";
import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import QuestionsList from "./questions/QuestionsList";
import { AddTestService } from "../../../services/api/companyTest/AddTest";
import { GetTest } from "../../../services/api/companyTest/GetTest";
import { AppContext } from "../../../routes/AppContext";
import { useHistory } from "react-router-dom";
import { UpdateTest } from "../../../services/api/companyTest/UpdateTest";
import AsyncImage from "../../AsyncImage";
import gif from "../../../media/image/profil.gif";
import FormSteps from "../FormSteps";
import ModalSetting from "../ModalSetting";
import TestPreview from "../testPreview/TestPreview";
import { useTranslation } from "react-i18next";
import TestPreviewTest from "../testPreview/TestPreviewTest";
const { Option } = Select;

export default function AddTest(props) {
  const { t } = useTranslation();

  // const [data,setData]=useState("")
  const [load, setLoad] = useState(false);
  const [current, setCurrent] = useState(0);

  const [openQuestions, setOpenQuestions] = useState([false]);
  const [title, setTitle] = useState("");
  const [language, setLanguage] = useState("fr");
  const [languages, setLanguages] = useState(["fr"]);
  const [globalStopwatch, setGlobalStopwatch] = useState(false);
  const [correctionMethod, setCorrectionMethod] = useState(0);
  const [showResult, setShowResult] = useState(true);
  const [permission, setPermission] = useState(true);
  const [level, setLevel] = useState(0);
  const [validity, setValidity] = useState(15);
  const [titleError, setTitleError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [questions, setQuestions] = useState([
    {
      skill: "",
      question_en: "",
      question_fr: "",
      level: 0,
      type: 0,
      time: 60,
      points: 1,
      answers: [
        { answer_en: "", answer_fr: "", isCorrect: true, type: 0 },
        { answer_en: "", answer_fr: "", isCorrect: false, type: 0 },
      ],
    },
  ]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  useEffect(()=>{
    document.getElementById("scroller").scrollIntoView();
  },[currentPageIndex,current])
  const history = useHistory({});
  const {
    fonctionnalites,
    role,
    myFunctionalities,
    decrementMyFunctionalities,headers
  } = useContext(AppContext);

  useEffect(() => {
    // sessionStorage.removeItem("test");

    if (sessionStorage.getItem("test")) {
      let test = JSON.parse(sessionStorage.getItem("test"));
      setTitle(test.title);
      setDescription(test.description);
      setQuestions(test.questions);
      setLanguage(test.languages[0]);
      setLanguages(test.languages);
      setGlobalStopwatch(test.globalStopwatch);
      setCorrectionMethod(test.correctionMethod);
      setShowResult(test.showResult);
      setLevel(test.level);
      setValidity(test.validity);
    }
  }, []);
  const childRef = createRef();
  const get = async () => {
    // let response = await GetTest(
    //   { id: props.id },
    //   {
    //     authorization: generateToken(
    //       { id: props.userId }
    //     ),
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   }
    // );
    sessionStorage.removeItem("test");
    let data = props.test;
    if (data._id) delete data._id;
    for (let i = 0; i < data.questions.length; i++) {
      delete data.questions[i]._id;
      delete data.questions[i].idTest;
      data.questions[i].answers.map((a) => {
        delete a._id;
        delete a.idQuestion;
      });
    }
    setQuestions(data.questions);
    setDescription(data.description);
    setTitle(data.title);
  };
  useMemo(() => {
    if (props.test) get();
  }, []);
  const updateOpenQuestions = (index) => {
    let list = [...openQuestions];
    list[index] = !list[index];
    setOpenQuestions(list);
  };
  const testValidation = () => {
    let isvalid = true;

    //description validation
    if (description == "") {
      document.getElementById("description").scrollIntoView();
      document.getElementById("description").focus();
      isvalid = false;
      setDescriptionError(t("champ obligatoire"));
    }
    //title validation
    if (title == "") {
      document.getElementById("title").scrollIntoView();
      document.getElementById("title").focus();
      isvalid = false;
      setTitleError(t("champ obligatoire"));
    }
    if (props.testTitles) {
      if (props.testTitles.includes(title)) {
        document.getElementById("title").scrollIntoView();
        document.getElementById("title").focus();
        isvalid = false;
        setTitleError(t("titre de test doit étre unique"));
      }
    }
    return isvalid;
  };
  const validation = () => {
    let isvalid = true;
    //questions validation
    //au moins une question
    if (questions.length == 0) {
      setQuestionError(t("tu doit ajouter au moins une question"));
      isvalid = false;
    } else {
      setOpenQuestions([...openQuestions.fill(false)]);
      // childRef.current.validation();
      //for loop
      // for (let i = questions.length - 1; i > -1; i--) {
        for (let i = 0; i <questions.length; i++) {
        //question validation
        const question = questions[i];
        if (question.type != 2) {
          //au moins 2 reponses
          let answers = question.answers;

          if (answers.length < 2) {
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
            isvalid = false;
            document.getElementById("question" + i).scrollIntoView();
            return false
          } else {
            //au moins une reponse correcte
            let valid = false;
            for (let j = answers.length - 1; j > -1; j--) {
              const answer = answers[j];
              if (answer.isCorrect) {
                valid = true;
              }

              if (language == "fr") {
                if (answer.answer_fr == "") {
                  isvalid = false;
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
                  document.getElementById("txt" + i + "a" + j).scrollIntoView();
                  document.getElementById("txt" + i + "a" + j).focus();
            return false

                }
              } else if (language == "en") {
                if (answer.answer_en == "") {
                  isvalid = false;
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
                  document.getElementById("txt" + i + "a" + j).scrollIntoView();
                  document.getElementById("txt" + i + "a" + j).focus();
            return false

                }
              }
            }
            if (!valid) {
              isvalid = false;
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
              document.getElementById("question" + i).scrollIntoView();
            return false

            }
          }
        }
        //les champs obligatoire
        //question
        if (language == "fr") {
          if (question.question_fr == "") {
            isvalid = false;
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
            document.getElementById("question" + i).scrollIntoView();
            document.getElementById("question" + i).focus();
            return false

          }
        } else if (language == "en") {
          if (question.question_en == "") {
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
            isvalid = false;
            document.getElementById("question" + i).scrollIntoView();
            document.getElementById("question" + i).focus();
            return false

          }
        }

        //points
        if (question.points == "") {
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
            isvalid = false;
          document.getElementById("points" + i).scrollIntoView();
          document.getElementById("points" + i).focus();
          return false

        }
        //time
        if (question.time == "") {
            setCurrentPageIndex(i)
            console.log("validation",currentPageIndex)
            isvalid = false;
          document.getElementById("time" + i).scrollIntoView();
          document.getElementById("time" + i).focus();
          return false

        }
           //skill
        //    if (question.skill == "") {
        //     setCurrentPageIndex(i)
        //     isvalid = false;
        //   document.getElementById("skill" + i).scrollIntoView();
        //   document.getElementById("skill" + i).focus();
        // }
      }
    }
    //description validation
    if (description == "") {
      document.getElementById("description").scrollIntoView();
      document.getElementById("description").focus();
      isvalid = false;
      setDescriptionError(t("champ obligatoire"));
    }
    //title validation
    if (title == "") {
      document.getElementById("title").scrollIntoView();
      document.getElementById("title").focus();
      isvalid = false;
      setTitleError(t("champ obligatoire"));
    }
    if (props.testTitles) {
      if (props.testTitles.includes(title)) {
        document.getElementById("title").scrollIntoView();
        document.getElementById("title").focus();
        isvalid = false;
        setTitleError(t("titre de test doit étre unique"));
      }
    }
    return isvalid;
  };
  const handleSubmitTest = (e) => {
    e.preventDefault();
    if (testValidation()) {
      setCurrent(1);
    }
  };
  const previous = () => {
    setCurrent(current-1);
};
  const handleSubmitQuestions = (e) => {
    e.preventDefault();
    if (validation()) {
      setCurrent(2);
    }
  };
  const handleSubmit = (e) => {
    if (validation()) {
      setLoad(true);
      if (props.id) {
        //update
        UpdateTest(
          {
            id: props.id,
            title: title,
            description: description,
            questions: questions,
            language: language,
            languages: languages,
            globalStopwatch: globalStopwatch,
            correctionMethod: correctionMethod,
            showResult: showResult,
            level: level,
            validity: validity,
            permission:permission,
          },
          headers
        );
      props.handleInvalidate()
      
        setTimeout(() => {
          history.push("/" + role + "/manageTests");
        }, 2000);
      } else {
        let addT = myFunctionalities.find((f) => f.fType == 2);
        if (!addT || addT.qte <= 0) {
          sessionStorage.setItem(
            "test",
            JSON.stringify({ title, description, questions })
          );
          Modal.error({
            title: null,
            content: (
              <>
                <div className="d-flex justify-content-center">
                  <AsyncImage className="w-100" src={gif} />
                </div>
                <h6 className="  text-dark rounded   ">
                  {t("Vous devez acheter un forfait pour bénéficier de nos services.")}
                </h6>
              </>
            ),
            onOk: () => {
              history.push("/" + role + "/pricing");
            },
          });
        } else {
          AddTestService(
            {
              title: title,
              description: description,
              questions: questions,
              language: language,
              languages: languages,
              globalStopwatch: globalStopwatch,
              correctionMethod: correctionMethod,
              showResult: showResult,
              level: level,
              validity: validity,
              permission:permission,
              idFunctionality: fonctionnalites.find(
                (functionality) => functionality.fType == 2
              )?._id,
            },
            headers
          );
          decrementMyFunctionalities(2);
          props.handleInvalidate()

          setTimeout(() => {
            history.push("/" + role + "/manageTests");
            sessionStorage.removeItem("test");
          }, 1000);
        }
      }
    }
  };
  const addQuestion = () => {
    if (questions.length > 0) childRef.current.validation();
    let q = questions;
    q.push({
      skill: "",
      question_fr: "",
      question_en: "",
      level: 0,
      type: 0,
      time: 60,
      points: 1,
      answers: [
        { answer_en: "", answer_fr: "", isCorrect: true, type: 0 },
        { answer_en: "", answer_fr: "", isCorrect: false, type: 0 },
      ],
    });
    setQuestions(q);
    setQuestionError("");
  };
  const deleteQuestion = (index) => {
    if (questions.length == 1)
      setQuestionError(t("tu doit ajouter au moins une question"));
    let q = questions;
    q.splice(index, 1);
    setQuestions(q);
    currentPageIndex >0 &&(
    setCurrentPageIndex(prev=>prev-1))
    console.log("validation",currentPageIndex)
  };
  const updateQuestion = (index, question) => {
    let q = questions;
    q[index] = question;
    setQuestions(q);
  };
  const languageOptions = [
    { value: "fr", label: t("Français") },
    { value: "en", label: t("Anglais") },
  ];
  const levelOptions = [
    { label: t("Junior"), value: 0 },
    { label: t("Intérmediaire"), value: 1 },
    { label: t("Senior"), value: 2 },
    { label: t("Expert"), value: 3 },
  ];
  return (
    <div id="scroller" >
      <FormSteps current={current} />
      <form className="   ">
        {current == 0 && (
          <div className="Setup-Test">
            <div className="text-center m-1 p-2">
              <h3 className="text-primary  ">
                <b>{t("Paramètres du test")}</b>
              </h3>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              {/* <hr className="m-2 ms-4 w-100 " /> */}

              <Tooltip title={t("Paramètres par défaut!")} color={"#6F88FC"} open>
                <ModalSetting
                  languages={languages}
                  setLanguages={setLanguages}
                  stopwatch={globalStopwatch}
                  setStopwatch={setGlobalStopwatch}
                  correctionMethod={correctionMethod}
                  setCorrectionMethod={setCorrectionMethod}
                  showResult={showResult}
                  setShowResult={setShowResult}
                  fromForm={true}
                  permission={permission}
                  setPermission={setPermission}
                />
              </Tooltip>
            </div>
            <div className="p-2">
              <label>
                {t("Titre de test")} <b style={{ color: "blue" }}>* </b>
              </label>
              <div className="md-form">
                <input
                  className="form-control validate mb-2"
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleError("");
                  }}
                />
              </div>
              <p className="text-danger">
                <small>{titleError}</small>
              </p>

              <label className="col-12 ">
                {" "}
                <span className="">
                  {" "}
                  {t("Description")} <b style={{ color: "blue" }}>* </b>{" "}
                </span>
                <textarea
                  name="description"
                  className="border form-control "
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionError("");
                  }}
                  value={description}
                  id="description"
                  rows="2"
                ></textarea>
              </label>
              <p className="text-danger">
                <small>{descriptionError}</small>
              </p>

              <div className="row mb-2">
                <div className="col-lg-4 col-md-12">
                  <label>{t("Langue")} :</label>
                  <br />
                  <Select
                    value={language}
                    onChange={(value, i) => {
                      setLanguage(value);
                    }}
                    className="w-100"
                    placeholder={t("Veuillez choisir une langue.")}
                  >
                    {languageOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  <br />
                </div>
                <div className="col-lg-4 col-md-12">
                  <label>{t("Expérience")} :</label>
                  <br />
                  <Select
                    className="w-100"
                    placeholder={t("Veuillez choisir une expérience.")}
                    onChange={(value, i) => {
                      setLevel(value);
                    }}
                    value={level}
                  >
                    {levelOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  <br />
                </div>
                <div className="col-lg-4 col-md-12">
                  <label>
                    {t("Expiration")}<small>({t("jours")})</small>:
                  </label>
                  <br />
                  <Input
                    className=" validate mb-2"
                    type="number"
                    id="validity"
                    name="validity"
                    value={validity}
                    onChange={(e) => {
                      setValidity(e.target.value);
                    }}
                  />
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}
        {current == 0 && (
          <div className="d-flex justify-content-end">
            <button onClick={handleSubmitTest} className="btn btn-primary">
              {t("Suivant")}
            </button>
          </div>
        )}
        {current == 1 && (
          <div className="mx-6">
            <div className="">
              <QuestionsList
                ref={childRef}
                updateQuestion={updateQuestion}
                addQuestion={addQuestion}
                deleteQuestion={deleteQuestion}
                questions={questions}
                openQuestions={openQuestions}
                updateOpenQuestions={updateOpenQuestions}
                id={props.userId}
                language={language}
                currentPageIndex={currentPageIndex}
                setCurrentPageIndex={setCurrentPageIndex}
              />
            </div>
            <p className="text-danger">
              <small>{questionError}</small>
            </p>
          </div>
        )}
        {current == 1 && (
          <div className="d-flex justify-content-end">
            <button onClick={previous} className="btn btn-primary m-1">
              {t("Précedent")}
            </button>
            <button onClick={handleSubmitQuestions} className="btn btn-primary m-1">
              {t("Suivant")}
            </button>
          </div>
        )}
        {current == 2 && (
          <div className="p-4">
            {/* <TestPreview
              submit={handleSubmit}
              test={{
                questions,
                title,
                description,
                level,
                language,
                languages,
                validity,
                globalStopwatch,
                correctionMethod,
                showResult,
              }}
            /> */}
            <TestPreviewTest
              submit={handleSubmit}
              test={{
                questions,
                title,
                description,
                level,
                language,
                languages,
                validity,
                globalStopwatch,
                correctionMethod,
                showResult,
              }}
            />
            <Button  className="m-1" type="primary"  onClick={previous}>
            {t("Précedent")}
</Button>
            <br />
            {/* <div className="text-center mb-0">
            <button
              type="submit"
              className=" submitspinner btn btn-primary btn-block z-depth-1a right"
              disabled={load}
            >
              Enregistrer
            </button>
          </div>
          <br /> */}
          </div>
        )}
      </form>
    </div>
  );
}
