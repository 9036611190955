const NavigatorNotification=(body)=>{
    let permission = Notification.permission;

if(permission === "granted"){
   showNotification(body);
} else if(permission === "default"){
   requestAndShowPermission(body);
} else {
  alert(body);
}

function requestAndShowPermission(notif) {
    Notification.requestPermission(function (permission) {
        if (permission === "granted") {
            showNotification(notif);
        }
    });
}
function showNotification(text) {
  //  if(document.visibilityState === "visible") {
  //      return;
  //  }
   let title = "Rec-INOV";
   let icon = 'https://rec-inov.com/company/images/629fc301b4512d557d6ec69b.png';
   let body = text;

   let notification = new Notification(title, { body, icon });

   notification.onclick = () => {
          notification.close();
          window.parent.focus();
   }
   
}
  }
  export default NavigatorNotification;