import {
  Checkbox,
  Col,
  Drawer,
  Progress,
  Radio,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DoughnutChart from "../DoughnutChart";
import { AppContext } from "../../routes/AppContext";
import { GetCompanyStatistics } from "../../services/api/statistics/GetJobPostStatistics";
import dateFromObjectId from "../../utils/dateFromObjectId";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function JobApplicationsDrawer(props) {
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const { userId, headers, locationsList } = useContext(AppContext);
  let regions = [...new Set(locationsList.map((loc) => loc.province))];
  const containerStyle = {
    position: "relative",
    height: "100%",
    padding: 10,
    overflow: "hidden",
    textAlign: "center",
    // background: token.colorFillAlter,
    // border: `1px solid ${token.colorBorderSecondary}`,
    // borderRadius: token.borderRadiusLG,
  };
  const [checkedList, setCheckedList] = useState([]);
  const [sorter, setSorter] = useState(-1);
  const setSortedColumns = (value) => {
    let sortedApplications = { ...props.columns };
    if (value == "A") {
      for (let i = 0; i < 5; i++) {
        sortedApplications[i].items = sortedApplications[i].items.sort(
          (a, b) =>
            dateFromObjectId(a.content.props.candidat.idCandidate) -
            dateFromObjectId(b.content.props.candidat.idCandidate)
        );
      }
    } else if (value == "B") {
      for (let i = 0; i < 5; i++) {
        sortedApplications[i].items = sortedApplications[i].items.sort(
          (b, a) =>
            dateFromObjectId(a.content.props.candidat.idCandidate) -
            dateFromObjectId(b.content.props.candidat.idCandidate)
        );
      }
    } else if (value == "C") {
      for (let i = 0; i < 5; i++) {
        sortedApplications[i].items = sortedApplications[i].items.sort(
          (a, b) =>
            b.content.props.candidat.score - a.content.props.candidat.score
        );
      }
    } else if (value == "D") {
      for (let i = 0; i < 5; i++) {
        sortedApplications[i].items = sortedApplications[i].items.sort(
          (b, a) =>
            b.content.props.candidat.score - a.content.props.candidat.score
        );
      }
    } else if (value == "E") {
      for (let i = 0; i < 5; i++) {
        sortedApplications[i].items = sortedApplications[i].items.sort((a, b) =>
          a.content.props.candidat.candidate[0].name.localeCompare(
            b.content.props.candidat.candidate[0].name
          )
        );
      }
    } else if (value == "F") {
      for (let i = 0; i < 5; i++) {
        sortedApplications[i].items = sortedApplications[i].items.sort((b, a) =>
          a.content.props.candidat.candidate[0].name.localeCompare(
            b.content.props.candidat.candidate[0].name
          )
        );
      }
    }
    props.setColumns(sortedApplications);
    setSorter(value);
  };
  const setFilteredColumns = async () => {
    let filteredApplications = { ...props.columns };
    console.log("filteredApplications",filteredApplications)
    for (let i = 0; i < 5; i++) {
      filteredApplications[i].items = await filteredApplications[i].items.map(
        (application) => {
          return { ...application, visible: false };
        }
      );
    }
    //gender
    if (props.gender.includes("Homme")) {
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[i].items.map(
          (application) => {
            if (
              application.content.props.candidat.candidate[0].gender == 1
            ) {
              return {
                ...application,
                visible: true,
              };
            } else return application;
          }
        );
      }
    }
    if (props.gender.includes("Femme")) {
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[i].items.map(
          (application) => {
            if (
              application.content.props.candidat.candidate[0].gender == 2
            ) {
              return {
                ...application,
                visible: true,
              };
            } else return application;
          }
        );
      }
    }
    //experience
    if (props.experience.length < 4) {
      if (!props.experience.includes("0-1")) {
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            if (
              application.visible &&
              application.content.props.candidat.candidate[0].experience < 13
            ) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }
      if (!props.experience.includes("1-3")) {
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            let experience =
              application.content.props.candidat.candidate[0].experience;
            if (application.visible && experience < 37 && experience > 11) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }
      if (!props.experience.includes("3-5")) {
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            let experience =
              application.content.props.candidat.candidate[0].experience;
            if (application.visible && experience < 60 && experience > 35) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }
      if (!props.experience.includes(">5")) {
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            let experience =
              application.content.props.candidat.candidate[0].experience;
            if (application.visible && experience > 59) {
              return { ...application, visible: false };
            } else return application;
          });
        }
      }
    }
    //regions
    if (props.regions.length) {
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[i].items.map(
          (application) => {
            if (application.visible) {
              return {
                ...application,
                visible: props.regions.some((region) =>
                  application.content.props.candidat.candidate[0].regions.includes(
                    region
                  )
                ),
              };
            } else return application;
          }
        );
      }
    }
    //handicap
    if (props.handicap) {
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[i].items.map(
          (application) => {
            if (application.visible) {
              return {
                ...application,
                visible:
                  application.content.props.candidat.candidate[0].handicap,
              };
            } else return application;
          }
        );
      }
    }

    //score content.props.candidat[].candidate
    if (props.scoreFilter === 10) {
        
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[
          i
        ].items.map((application) => {
          if (
            application.visible &&
            application.content.props.candidat.score < 0.10
          ) {
            return {
              ...application,
              visible: false,
            };
          } else return application;
        });
      }
    }
    if (props.scoreFilter === 20) {
        
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[
          i
        ].items.map((application) => {
          if (
            application.visible &&
            application.content.props.candidat.score < 0.20
          ) {
            return {
              ...application,
              visible: false,
            };
          } else return application;
        });
      }
    }
    if (props.scoreFilter === 30) {
        
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[
          i
        ].items.map((application) => {
          if (
            application.visible &&
            application.content.props.candidat.score < 0.30
          ) {
            return {
              ...application,
              visible: false,
            };
          } else return application;
        });
      }
    }
    if (props.scoreFilter === 40) {
        
      for (let i = 0; i < 5; i++) {
        filteredApplications[i].items = await filteredApplications[
          i
        ].items.map((application) => {
          if (
            application.visible &&
            application.content.props.candidat.score < 0.40
          ) {
            return {
              ...application,
              visible: false,
            };
          } else return application;
        });
      }
    }
    
      if (props.scoreFilter === 50) {
        
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            if (
              application.visible &&
              application.content.props.candidat.score < 0.50
            ) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }
      if (props.scoreFilter === 60) {
        
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            if (
              application.visible &&
              application.content.props.candidat.score < 0.60
            ) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }
      if (props.scoreFilter === 70) {
        
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            if (
              application.visible &&
              application.content.props.candidat.score < 0.70
            ) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }
      if (props.scoreFilter === 80) {
        
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            if (
              application.visible &&
              application.content.props.candidat.score < 0.80
            ) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }
      if (props.scoreFilter === 90) {
        console.log("ff score 90")
        for (let i = 0; i < 5; i++) {
          filteredApplications[i].items = await filteredApplications[
            i
          ].items.map((application) => {
            if (
              application.visible &&
              application.content.props.candidat.score < 0.90
            ) {
              return {
                ...application,
                visible: false,
              };
            } else return application;
          });
        }
      }

    



    props.setColumns(filteredApplications);
  };
  useMemo(() => {
    setFilteredColumns();
  }, [props.gender, props.experience, props.handicap, props.regions,props.scoreFilter]);

  const onChangeSorters = (checkedValues) => {
    const checkedValue = checkedValues[checkedValues.length - 1];
    setCheckedList([checkedValue]);
    setSortedColumns(checkedValue);
  };
  const onChangeGender = (checkedValues) => {
    props.setGender(checkedValues);
  };
  const onChangeExperience = (checkedValues) => {
    props.setExperience(checkedValues);
  };
  // const onChangeHandicap = (checkedValues) => {
  //   const checkedValue = checkedValues[checkedValues.length - 1];
  //   props.setHandicap(checkedValue);
  // };
  const onChangeHandicapi = () => {
    props.setHandicap(prev=> !prev);
  };

  

  return (
    <div>
      <Drawer
        drawerStyle={containerStyle}
        title={""}
        placement="right"
        onClose={props.onClose}
        open={props.open}
        getContainer={false}
        style={{padding:0}}
        visible={props.open}
      >
        <div>
          <h5 className=" mb-2 text-primary">
            <b>{t("Filtrer Par")} :</b>
          </h5>

          <div className="text-start ">
            <Checkbox.Group
              value={props.gender}
              style={{
                width: "100%",
              }}
              onChange={onChangeGender}
            >
              <Row>
                <Col span={12}><b>{t("Genre")} :</b></Col>
                <Col span={12}>
                  <Col span={24}>
                    <Checkbox value="Femme" >{t("Femme")}</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Homme" >{t("Homme")}</Checkbox>
                  </Col>
                </Col>
              </Row>
            </Checkbox.Group>
      
            <br />
            <br />
            <Checkbox.Group
              value={props.experience}
              style={{
                width: "100%",
              }}
              onChange={onChangeExperience}
            >
              <Row>
                <Col span={12}><b>{t("Experience")} :</b></Col>
                <Col span={12}>
                  <Col span={24}>
                    <Checkbox value="0-1">0-1</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="1-3">1-3</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="3-5">3-5</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value=">5">&gt; 5</Checkbox>
                  </Col>
                </Col>
              </Row>
            </Checkbox.Group>

            <br />
            
            <Row>
              <Col span={6}><b>{t("Score")} :</b></Col>
              <Col span={18}>
                <Select
                  style={{ width: 100 }}
                  placeholder="Selectionner.."
                
                  // style={{width:"100%"}}
                  onChange={props.setScoreFilter}
                  value={props.scoreFilter}
                  
                >
                  <Option value={0}>{t("Tous")}</Option>
                    <Option value={90}>&gt;=90</Option>
                    <Option value={80}>&gt;=80</Option>
                    <Option value={70}>&gt;=70</Option>
                    <Option value={60}>&gt;=60</Option>
                    <Option value={50}>&gt;=50</Option>
                    <Option value={40}>&gt;=40</Option>
                  
                </Select>
              </Col>
            </Row>



            <br />
            <Row>
              <Col span={6}><b>{t("Région")} :</b></Col>
              <Col span={18}>
                <Select
                  // style={{ width: 200 }}
                  placeholder={t("Selectionner..")}
                  className="mb-2 w-100"
                  showSearch
                  mode="multiple"
                  // style={{width:"100%"}}
                  onChange={props.setRegions}
                  value={props.regions}
                  allowClear={true}
                >
                  {regions.map((r) => (
                    <Option value={r}>{r}</Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <br />

            <Checkbox.Group
              value={[props.handicap]}
              style={{
                width: "100%",
              }}
              // onChange={onChangeHandicapi}
            >
              <Row>
                <Col span={12}><b>{t("Handicapé")} :</b></Col>
                <Col span={12} style={{cursor:"pointer"}}>
                  <Col  span={24} onClick={onChangeHandicapi}>
                    <Checkbox value={true} style={{marginRight:"5px"}}></Checkbox>{t("Priorité")}
                  </Col>
                  <Col  span={24} onClick={onChangeHandicapi}>
                    <Checkbox value={false} style={{marginRight:"5px"}}></Checkbox>{t("Non spécifié")}
                  </Col>
                </Col>
              </Row>
            </Checkbox.Group>
            <br />
            <br />
              </div>
            <h5 className=" mb-2 text-primary">
              <b>{t("Trier par")} :</b>
            </h5>
<div className="text-start" >
            <Checkbox.Group
              value={checkedList}
              style={{
                width: "100%",
              }}
              onChange={onChangeSorters}
            >
              <Row>
                <Col span={8}><b>{t("Date")}</b></Col>
                <Col span={8}>
                  <Checkbox value="A">&#11016;</Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="B">&#11018;</Checkbox>
                </Col>
                <Col span={8}><b>{t("Score")}</b></Col>
                <Col span={8}>
                  <Checkbox value="C">&#11016;</Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="D">&#11018;</Checkbox>
                </Col>
                <Col span={8}><b>{t("Nom")}</b></Col>
                <Col span={8}>
                  <Checkbox value="E">&#11016;</Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="F">&#11018;</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
