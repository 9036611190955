import React, { useState } from "react";
import { useCookies } from "react-cookie";
  import { useHistory, withRouter } from 'react-router-dom'
  import { useTranslation } from "react-i18next";
 function Cookies(props) {
  const [cookies, setCookie] = useCookies(["user"]);
  const [style, setStyle] = useState();
  const { t } = useTranslation();
  const history = useHistory({});

  const handleSubmit = (event) => {      
    event.preventDefault();
    let oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setStyle( { display: "none" ,backgroundColor: ""  });
    setCookie("user", true,{expires:oneYearFromNow});
   }   

   
    return (
      <>
      {(!cookies.user) ? (
   
          <div className=" text-center   fixed-bottom ">  
            <div
              className="modal-content"
              style={{ border: "none", backgroundColor: "#e9e9e9c9" }}
            >
              <div className="modal-body">
                {" "}
                &#x1F36A; {t("Nous utilisons des cookies pour vous offrir la meilleure expérience sur notre site.")}{" "}
                <a onClick={()=>{history.push("/politique-confidentialite")}} href="#" >
                  {" "}
                  {t("Plus d'informations")} {" "}
                </a>
                <button
                  type="button"
                  className="btn ms-5 acceptcookies btn btn-success"
                  aria-label="Close"
                  onClick={handleSubmit}
                >
                  {t("accepter")}
                </button>
              </div>
            </div>
          </div>
      ) : (<></>
      )  } 
      
   
      </>
    );
  }
  export default withRouter(Cookies)