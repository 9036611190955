import { Table } from 'antd'
import React, { useState } from 'react'
import JobPostDrawer from '../../jobPostDrawer/JobPostDrawer';
import {AreaChartOutlined } from "@ant-design/icons";
import AnalyticsIcon from '../../icons/AnalyticsIcon';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;

export default function StatisticTable(props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [jobPost, setJobPost] = useState(null);
  const showDrawer = (jobPost) => {
    setJobPost(jobPost)
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };
  return (
    <>
    <div className='d-flex justify-content-end align-items-center' >
    {/* Voir vos statistiques globales  */}
    <span className='m-1 icons12' role="button" onClick={()=>{
     showDrawer({})
              }} >
                {t("Statistiques")}
                <AnalyticsIcon fill="black" width="2.5rem" />
                </span></div>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table 
        pagination={{ defaultPageSize: 5, showSizeChanger: false}}
     rowKey={record =>{
       return record._id}}
      dataSource={props.data}> 
          <Column
            align="center"
            sorter={(a, b) => a.title.localeCompare(b.title)}
            title={t("Titre")}
            dataIndex="title"
            key="title"
       
          />
          <Column
            align="center"
            sorter={(a, b) => a.applications-b.applications}
            // responsive={["lg"]}
            title={t("Candidats postulé")}
            dataIndex="applications"
            key="applications"
        
            // render={(text, jobPost, index) => {
            //   return (<>
            //   <span role="button" onClick={()=>{
            //       this.setState({ jobPost: jobPost });
            //       this.setModalVisible(true);
            //   }} >{jobPost.title} </span>
            //   </>)}}
            
          />
             <Column
            align="center"
            sorter={(a, b) => a.interviews-b.interviews}
            // responsive={["lg"]}
            title={t("Entretiens")}
            dataIndex="interviews"
            key="interviews"
        
            // render={(text, jobPost, index) => {
            //   return (<>
            //   <span role="button" onClick={()=>{
            //       this.setState({ jobPost: jobPost });
            //       this.setModalVisible(true);
            //   }} >{jobPost.title} </span>
            //   </>)}}
            
          />
             <Column
            align="center"
            sorter={(a, b) => a.accepted-b.accepted}
            // responsive={["lg"]}
            title={t("Candidats accepté")}
            dataIndex="accepted"
            key="accepted"
        
            // render={(text, jobPost, index) => {
            //   return (<>
            //   <span role="button" onClick={()=>{
            //       this.setState({ jobPost: jobPost });
            //       this.setModalVisible(true);
            //   }} >{jobPost.title} </span>
            //   </>)}}
            
          />
     <Column
            align="center"
            // responsive={["lg"]}
            // title="Candidats accepté"
            dataIndex="accepted"
            key="accepted"
        
            render={(text, jobPost, index) => {
              return (<>
              <span className='' role="button" onClick={()=>{
     showDrawer(jobPost)
              }} >
                                <AnalyticsIcon fill="black" width="2rem" />

                </span>
              </>)}}
            
          />
        </Table>
        </ConfigProvider>
      {open&&<JobPostDrawer jobPost={jobPost} open={open} onClose={closeDrawer} />}
        </>
  )
}
