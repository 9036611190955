
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useTranslation } from "react-i18next";
ChartJS.register(ArcElement, Tooltip, Legend);




export default function DoughnutChart(props) {
  const { t } = useTranslation();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: t('Statistiques des entretiens'),
      },
    },
   };
  const data = {
    labels: [t('Entretien visio'), t('Entretien technique')],
    datasets: [
      {
        title:t("Entretiens"),
        label: '# of Votes',
        data: [props.interviews, props.tests],
        backgroundColor: [
          'rgba(42,118,244,255)',
          'rgba(148,186,249,255)',
  
        ],
        borderColor: [
          'rgba(42,118,244,255)',
          'rgba(148,186,249,255)',
   
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div><Doughnut options={options} data={data} /></div>
  )
}
