import React, { Fragment, useContext, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { useHistory } from 'react-router-dom'
import { message, notification, Modal } from "antd";
import {
  UploadOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import AffichePFD from "./affiche_PDF";
import Dropzone from "./Dropzone";
import { Spin, Space } from "antd";
import { AppContext } from "../../../routes/AppContext";
import { UploadCVService } from "../../../services/api/cv/UploadCVService";
import { UploadCV } from "../../../services/api/extractCvs/UploadCv";
import { useTranslation } from "react-i18next";

function beforeUpload(file,t) {
  if (file !== "") {
    let typeFile = file.type.split(".").pop();
    let acceptedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/rtf",
      "application/vnd.oasis.opendocument.text",
      // "image/png",
      // "image/jpeg",
      "document",
      "text/plain",
      "application/pdf",
    ];
    const isTrue = acceptedTypes.includes(typeFile);
    if (!isTrue) {
      message.error(
        t("Vous ne pouvez télécharger que les types de fichiers Pdf - doc - docx - txt")
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(t("Le fichier doit être plus petite que 5MB!"));
    }
    return isTrue && isLt5M;
  } else {
    message.warning(t("Sélection votre CV svp!"));
    return false;
  }
}

const UploadJobPost = (props) => {
  const { t } = useTranslation();
  const history = useHistory({});
  const [afficheProgress, setafficheProgress] = useState(false);

  const [file, setFile] = useState("");
  const [FileName, setFileName] = useState(t("Nom du fichier"));
  const [verif, setverif] = useState(false);
  const [verifupload, setverifupload] = useState(false);
  const [showFile, setShowFile] = useState(false);

  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { hardSkillsList, softSkillsList, languagesList,user,setUser,headers } =
    useContext(AppContext);

  function getBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setUploadedFile(reader.result);
      return reader.result;
    };
    reader.onerror = function (error) {
      return reader.result;
    };
  }
  const onChange = useCallback(async (acceptedFiles) => {
    setFile("");
    setverif("");
    setFileName("");
    setverifupload(false);
    setFileName(acceptedFiles[0].name);
    setFile(acceptedFiles[0]);
    getBase64(acceptedFiles[0]);
    setverif(beforeUpload(acceptedFiles[0],t));
    setShowFile(true);
  

  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    setShowFile(false);
    setafficheProgress(true);
    const formData = new FormData();
    formData.append("idCompany", props.userId);
    formData.append("role", "candidate");
    formData.append("file", file);
    if (beforeUpload(file)) {
      try {
        const res = await UploadCV(formData,{
            "Content-Type": "multipart/form-data",
            ...headers
          })
       
        if (res?.status === 200) {
          setUploadPercentage(100);
          notification.open({
            message: "Notification",
            description: t("Votre CV a été téléchargé avec succès"),
            className: "custom-class ",
            icon: <UploadOutlined style={{ color: "#148f24" }} />,
            style: {
              width: "auto",
            },
          });
          // sessionStorage.setItem("data", res.data.data);
          let parsedJobPost = res.data.data;
        //   if (sessionStorage.getItem("cv"))
        //  let cv = JSON.parse(sessionStorage.getItem("cv"));
        //   else let cv = {
        //     educations: [],
        //     experiences: [],
        //     hardSkills: [],
        //     languages: [],
        //     softSkills: [],
        //   };
        let cv=user

          const parsedHardSkills = parsedJobPost.skills;
          const parsedSoftSkills = parsedJobPost.soft_skills;
          const parsedLanguages = parsedJobPost.languages;

          let filteredHardSkills = hardSkillsList.filter((skill) => { 
            return parsedHardSkills.includes(skill.similar_skill);
          });
          let filteredSoftSkills = softSkillsList.filter((skill) => {
            return (
              parsedSoftSkills.includes(skill.other_ss) 
            );
          });
          let filteredLanguages = languagesList.filter((skill) => {
            return (
              parsedLanguages.includes(skill.language_fr) ||
              parsedLanguages.includes(skill.language_en)
            );
          });
          let hardSkills = cv.hardSkills;
          let softSkills = cv.softSkills;
          let languages = cv.languages;
          //hardskills
          for (let i = 0; i < filteredHardSkills.length; i++) {
            const skill = filteredHardSkills[i];
            let notExist = true;
            for (let j = 0; j < hardSkills.length; j++) {
              const element = hardSkills[j];
              if (element.skill == skill.skill) notExist = false;
            }
            if (notExist) hardSkills.push(skill);
          }
          //softSkills
          for (let i = 0; i < filteredSoftSkills.length; i++) {
            const skill = filteredSoftSkills[i];
            let notExist = true;
            for (let j = 0; j < softSkills.length; j++) {
              const element = softSkills[j];
              if (element.code == skill.code) notExist = false;
            }
            if (notExist) softSkills.push(skill);
          }
          //languages
          for (let i = 0; i < filteredLanguages.length; i++) {
            const skill = filteredLanguages[i];
            let notExist = true;
            for (let j = 0; j < languages.length; j++) {
              const element = languages[j];
              if (element.code == skill.code) notExist = false;
            }
            if (notExist) languages.push(skill);
          }
          //  let experiences = parsedJobPost.experience_cv
          hardSkills.map((skill) => {
            skill.score = 0.2;
          });
          softSkills.map((skill) => {
            skill.score = 0.2;
          });
          languages.map((skill) => {
            skill.score = 0.2;
          });


          cv.hardSkills =hardSkills;
          cv.softSkills = softSkills;
          cv.languages = languages;
          cv.experiences = [...cv.experiences,...parsedJobPost.candidateexperiences];
          cv.educations = [...cv.educations,...parsedJobPost.candidateeducations];
          cv.certificates = [...cv.certificates,...parsedJobPost.certificates];
          cv.cvFile=parsedJobPost.path
          setUser(cv)
          // sessionStorage.setItem("cv", JSON.stringify(cv));
          history.push({
            pathname: "/candidate/cv",
          
          });
          setafficheProgress(false);
          setverifupload(true);
          
    
        } else {
          
          notification.open({
            message: "Notification",
            description: "Oups,le téléchargement de votre CV n'a pas fonctionné. Pas de stress, on a un plan B ! 🌟 Allez-y, créez votre CV direct en remplissant un petit formulaire. C'est rapide et facile avec Rec-Inov. Et si ça coince, juste un coup de fil au (+216) 58 063 066 et on est là pour vous aider !",
            className: "custom-class ",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            style: {
              width: "auto",
            },
          });
          setafficheProgress(false);
          setverif(false);
          // notification.open({
          //   message: "Notification",
          //   description: "erreur avec le serveur, veuillez réessayer ! ",
          //   className: "custom-class ",
          //   icon: <ExclamationCircleOutlined style={{ color: "orange" }} />,
          //   style: {
          //     width: "auto",
          //   },
          // });
        }
      } catch (err) {
        
        setafficheProgress(false);
        setverif(false);
        notification.open({
          message: "Notification",
          description: t("erreur avec le serveur, veuillez réessayer !"),
          className: "custom-class ",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          style: {
            width: "auto",
          },
        });
      }
    }
  };
  return (
    <div className="uploadFile ">
      <Modal
        title={
          <h1 className="m-1 text-primary">
            <b>{t('Déposer votre CV')}</b>
          </h1>
        }
        className=""
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined className="m-3" />
          </i>
        }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={<></>}
      >
        <div className="modal-body">
          <Fragment>
            <form onSubmit={onSubmit}>
              {verif ? (
                <>
                  {showFile ? (
                    <>
                    <AffichePFD src={uploadedFile} />
                    <input
                    type="submit"
                    value={t("Valider")}
                    className="btn btn-primary btn-block mt-4 col-lg-2 mx-auto"
                  />
                  </>
                  ) : (
                    <>
                      {afficheProgress ? (
                        <div className="d-flex justify-content-center">
                          <Space size="large">
                            <Spin size="large" />
                          </Space>
                        </div>
                      ) : (
                        <>
                          {verifupload ? (
                            <>
                              <Link to="/candidate/cv" className="d-flex">
                                <button className="btn btn-primary btn-block mt-4 col-lg-2 mx-auto">
                                  {t('Etape Suivante')} <h1>&#8594;</h1>
                                </button>
                              </Link>
                            </>
                          ) : (
                            <Dropzone onDrop={onChange} />
                          )}
                        </>
                      )}
                    </>
                  )}
                  
                </>
              ) : (
                <Dropzone onDrop={onChange} />
              )}
            </form>
          </Fragment>
        </div>
      </Modal>
    </div>
  );
};

export default UploadJobPost;
