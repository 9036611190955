import { notification } from 'antd';


import axios from "../../../interceptors/axios";
export const AddProblem =  async (data,headers)=> {
    try {
      axios.post('/api/problem/add', data,
        {
          headers: headers
        }
      ).then(response => {
        notification.success({
         
          description: response.data.message,
          className: 'custom-class',
          style: {
            width: 600,
            backgroundColor:"#d4edda",
            marginTop:"5rem"
          },
        })
      }).catch((err) => {
        //notification
        notification.error({
          description:err.response.data.message==undefined?"Une erreur est survenue":err.response.data.message,
          className: 'custom-class',
          style: {
            width: 600,
            backgroundColor:"#f8d7da",
            marginTop:"5rem"
          },
        }) 
      });
    
} catch (err) {

  
    }
  }