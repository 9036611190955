import React, { Component } from "react";

import JobPostComponent from "./JobPostComponent";
import { Input, Modal, notification, Popover, Spin } from "antd";
import { match } from "../../services/api/matching/matching";
import decrypt from "../../utils/decrypt";
import { withRouter } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import searchJob from "../../media/image/searchJob.svg";
import AsyncImage from "../AsyncImage";
import { AppContext } from "../../routes/AppContext";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import crypt from "../../utils/crypt";
import gif from "../../media/image/newposte.gif"
import gearing from "../../media/image/gearing.gif"
import InfoIcon from "../icons/InfoIcon";
import { MyApplications } from "../../services/api/application/MyApplications";

import { withTranslation } from "react-i18next";

class JobPostsList extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      popoverVisible: false,
      load: false,
      clicked: false,
      page: 1,
      filterInput:""
    };
  }
  info = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p> {this.props.t('Toutes les données de votre profil et les informations du CV que vous avez indiquées sont prises en considération lors du calcul du score de correspondance,pour vous trouver les meilleures ✨ offres ✨ qui vous conviennent.')}
          </p>
        </div>
      ),

      onOk() { },
    });
  };
  async  componentDidMount() {
    if(this.context.candidateAccess){
      if(this.context.user.updated){
        this.match()
      }
      else{
        if(!this.context.jobPosts.length){
          this.match()
        }
      }
    }

    let response = await MyApplications(
      {},
      this.context.headers
    );
    if(response){
    let applications = JSON.parse(decrypt(response.data));
      this.context.setCandidateApplications(applications)
    }
      // this.handleVisibleChange(true)
  }
  // handleVisibleChange = (popoverVisible) => {
  //   this.setState({ popoverVisible });
  // };
  handleChangePage = (e, page) => {
    this.setState({ page: page })
    // document.getElementById("scroller").scrollIntoView();
  }
  match = async (e) => {
    try {
      this.setState({ load: true });
      let res = await match({}, this.context.headers);

      if(res){
      let data = JSON.parse(decrypt(res.data));
      data.sort((a, b) => (a.score < b.score ? 1 : -1));
      {console.log(data)}
      if (data[0].score < 0.3) {
        notification.warn({
          description: <div className="text-center"><AsyncImage src={gif} alt="email" style={{ width: "15rem", height: "15rem" }} /><br />
            <span> {this.props.t("Nous avons le regret de vous informer que vous ne pouvez pas postuler pour aucun de ces postes vacants. Votre score de pertinence est faible. Nous vous contacterons par e-mail dès qu'une offre correspondant à votre CV sera disponible.")}
            </span>
          </div>,

          className: "custom-class",
          placement: 'top',
          style: {
            width: 600,
            backgroundColor: "#fff",
            marginTop: "5rem",
          },
        });
      }
      this.context.setJobPosts(data)
      let user=this.context.user
      user.updated=false
      this.context.setUser(user)
      this.setState({ load: false, clicked: true });}
      // MatchingService.match({id: JSON.parse(localStorage.getItem('user')).additionalInfo._id})
    } catch (exp) {
      this.setState({ load: false, clicked: true });
    }
  };

  render() {
    let i = -1;
    let firstIndex = (this.state.page - 1) * 5
    let jobPosts = this.context.jobPosts
    if(this.state.filterInput!="")
    jobPosts = jobPosts.filter((jobPost) => {
      let filter = this.state.filterInput.toLowerCase();
      
      let jobPostTitle = jobPost.title.toLowerCase();
      let description = jobPost?.idJobPostDescription?.description
      .replace(/<[^>]+>/g, "").toLowerCase();
      let requestedProfile = jobPost?.idJobPostRequestedProfile?.description
      .replace(/<[^>]+>/g, "").toLowerCase();
      let companyName = jobPost.idCompany.name.toLowerCase();
      return     jobPostTitle.includes(filter) ||
      description.includes(filter)||requestedProfile.includes(filter) ||companyName.includes(filter)
    });
    let jobPostsLength=jobPosts
     jobPosts = jobPosts.slice(firstIndex, firstIndex + 5)
    let list =
      jobPosts &&
      jobPosts.map((jobPost) => {
        i++;
        return (
          <div key={i} style={{ direction: "ltr",textAlign: "left"}}>
            <JobPostComponent
              userId={this.props.userId}
              index={i}
              jobPost={jobPost}
            />
          </div>
        );
      });

    return (
      <div className="container" id="scroller" >
        <header className="jumbotron">
          <h3>{this.state.content}</h3>

          {!this.context.candidateAccess ? (
            <div className="d-flex justify-content-center">
              <h6 className="w-100 m-2 p-4  text-dark rounded border bg-red ">
                {" "}
                <span className="m-1 icons12 ">
                  {" "}
                  <WarningOutlined />
                </span>
                {this.props.t('Vous devez renseigner votre')}{" "}
                <Link to="/candidate/profile">{this.props.t('profil')}</Link> {this.props.t('et remplir votre')}{" "}
                <Link to="/candidate/cv/upload">{this.props.t("cv")}</Link>{this.props.t('pour bénéficier de nos services .')}
              </h6>
            </div>
          ) : (
            <>
  <div className="d-flex justify-content-between">
    <h1>{this.props.t('Liste des offres')}</h1>
        

              <span onClick={this.info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>

              </div>
            </>
            
          )}
                {this.state.load ? (
                <>
          
                    <div className="text-center">
              {/* <Spin size="large" /> */}
              <AsyncImage src={gearing} alt="email" style={{ width: "15rem", height: "15rem" }} />
              </div>
                </>
              ) : (
                <>
     
                </>
              )}
        </header>
        <div className="row">
          <div className=" col-lg-10 offset-lg-1">
            {  this.context.jobPosts.length > 0 &&  <div className="d-flex my-2">
            <Input
              className=" me-2"
              placeholder={this.props.t("Rechercher un offre..")}
              value={this.state.filterInput}
              onChange={(e) => {
                const currValue = e.target.value;
                this.setState({ filterInput: currValue ,page: 1 });
              }}
            /></div>}
            {list}
            {jobPosts.length > 0 && <div className="d-flex justify-content-end m-1 mt-3 " style={{ direction: "ltr"}} >
              <Pagination
                page={this.state.page}

                onChange={this.handleChangePage}
                // rowsPerPage={5}
                // count={Math.ceil(this.context.jobPosts.length / 5)}
                count={Math.ceil(jobPostsLength.length / 5)}
                color="primary"
              />
            </div>}
            {(this.context.jobPosts.length == 0 && this.state.clicked) && (
              <h4 className="mt-5 d-flex justify-content-center text-secondary">
                {" "}
                <span className="m-3 icons-lg ">
                  {" "}
                  <WarningOutlined />
                </span>{" "}
                {this.props.t("Nous vous remercions d'avoir remplir votre profil. Nous déterminerons à quel offre vous correspond. Vous serez contacté prochainement")}{" "}
              </h4>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(withRouter(JobPostsList));
