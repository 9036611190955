import React, { Fragment, useContext, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

import "antd/dist/antd.css";
import { DatePicker, message, notification ,Modal } from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {SmileOutlined} from '@ant-design/icons';
import { Spin, Space } from "antd";
import { AppContext } from "../../routes/AppContext";
import { useHistory } from 'react-router-dom'
import moment from "moment-timezone";
import { SendContract } from "../../services/api/smartContract/SendContract";
import InfoIcon from "../icons/InfoIcon";
import image from "../../media/image/contratUpload.png"
import Dropzone from "../Dropzone";


function beforeUpload(file) {
  if (file !== "") {
    let typeFile = file.type.split(".").pop();
    let acceptedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/rtf",
      "application/vnd.oasis.opendocument.text",
      // "image/png",
      // "image/jpeg",
      "document","text/plain",
      "application/pdf",
      "document",
    ];
    const isTrue = acceptedTypes.includes(typeFile);
    if (!isTrue) {
      message.error(
        "Vous ne pouvez télécharger que le type de fichier Pdf - doc - docx - txt" 
        // "doc/docx//rtf/png/jpeg/text!"
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Le fichier doit être plus petite que 5MB!");
    }
    return isTrue && isLt5M;
  } else {
    message.warning("Sélectionner votre contrat svp!");
    return false;
  }
}

const UploadContract = (props) => {
  const [afficheProgress, setafficheProgress] = useState(false);
  const [statusprogresse, setstatusprogresse] = useState("");
  const [styleprogresse, setstyleprogresse] = useState({ display: "none" });
  const [file, setFile] = useState("");
  const [FileName, setFileName] = useState(" Nom du fichier ");
  const [verif, setverif] = useState("");
  const [verifupload, setverifupload] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { companyName,userId,headers} = useContext(AppContext);

  function getBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setUploadedFile(reader.result);
      return reader.result;
    };
    reader.onerror = function (error) {
      return reader.result;
    };
  }
  const info = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p className="">
Info :  contract
</p>
        </div>
      ),
      onOk() {},
    });
  };
  const onChange = (files) => {
    setFile("");
    setverif("");
    setFileName("");
    setverifupload(false);
    setFileName(files[0].name);
    setFile(files[0]);
    getBase64(files[0]);
   let printedTypes= ["text/plain","application/pdf"]
    let typeFile = files[0].type.split(".").pop();
    setShowFile(printedTypes.includes(typeFile));
  //   else
     setverif(beforeUpload(files[0]));
    
    setafficheProgress(false);
    setstatusprogresse("");
    // document.getElementById("uploadedFile").scrollIntoView();
    // document.getElementById("uploadedFile").focus();
  };
  const Send=async()=>{

}
  const onSubmit = async (e) => {
    e.preventDefault();
    setafficheProgress(true);
    setverif(false);
    const formData = new FormData();
    formData.append("jobPostTitle", props?.location?.state?.jobPostTitle);
    formData.append('email', props?.location?.state?.email);
    formData.append('idJobPost', props?.location?.state?.idJobPost);
    formData.append('companyName', companyName);
    formData.append('idCandidate', props?.location?.state?.idCandidate);
    formData.append("file", file);
    if (beforeUpload(file)) {
      setstyleprogresse({});
      try {
    let res=await SendContract({companyName:companyName,deadline:props.deadline,responsibleName:props.responsibleName,
      jobPostTitle:props?.location?.state?.jobPostTitle,email:props?.location?.state?.email,
      idJobPost:props?.location?.state?.idJobPost,idCandidate:props?.location?.state?.idCandidate},headers)

        // const res = await UploadJobPostService(formData,{
        //     "Content-Type": "multipart/form-data",
        //     authorization: generateToken(
        //       { id: props.userId }
        //     ),
        //   })
     
        // if (res.status === 200) {
          setUploadPercentage(100);
          notification.open({
            description:
              "Votre contrat a été téléchargé avec succès ",
            className: "custom-class ",
            icon: <UploadOutlined style={{ color: "#148f24" }} />,
            style: {
              width: "auto",
            },
          });
          
          props.setState(1)
          setafficheProgress(false);
          setverifupload(true);
          setstatusprogresse("active");
        // }
      } catch (err) {
        setstatusprogresse("exception");
        setafficheProgress(false);
        notification.open({
          description: "erreur avec le serveur, veuillez réessayer ! ",
          className: "custom-class ",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          style: {
            width: "auto",
          },
        });
      }
    }
  };

  return (
    <div className="uploadFile ">
<h3 className='titrePage'> Télécharger votre Contrat <span onClick={info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>
   </h3>
  

      <div className="modal-body">
      {/* <div className='d-flex justify-content-center' >
      <h6 className='w-80 p-4  text-dark rounded border  ' style={{backgroundColor: "beige"}}>  <SmileOutlined />    Téléchargez votre offre d'emploi. Avec notre outil innovant, nous vous simplifions la tâche. </h6>
      </div> */}
  
        <Fragment>
          <form onSubmit={onSubmit}>
               {/* <div className="input-group">
              <label className="input-group-btn">
                <span className="btn btn-primary">
                Choisir un fichier &hellip;{" "} */}
                  {/* <input
                    id="formFileSm"
                    type="file"
                    onChange={onChange}
                    style={{ display: "none" }}
                  /> */}
                                   <Dropzone image={image} onDrop={onChange} />

                {/* </span>
              </label>
              <input
                type="text"
                className="form-control"
                value={FileName}
                readonly
              />
            </div> */}
<div id="uploadedFile" >
            {verif ? (
              <>
               {showFile ? 
                    <> <card>
                     <h4>Merci de valider votre Contrat </h4>
                     <div className="modal-dialog  modal-lg ">
                       <div className="modal-content" style={{ height: "350px" }}>
                         <div className="modal-body" id="modal-body">
                           <iframe src={uploadedFile} height="100%" width="100%"></iframe>
                         </div>
                       </div>
                     </div>
                   </card>
                   <div className="d-flex justify-content-center " >
                  <label htmlFor="birthday">
                    <h4 className=""> Nom du responsable </h4>
                    <div className="">
                    <input
                      className="border form-control "
                      id="address"
                      type="text"
                      name="address"
                      value={props.responsibleName}
                      onChange={e=>{props.setResponsibleName(e.target.value)}}
                    />
                    </div>
                  </label></div>
                   <div className="d-flex justify-content-center " >
                  <label htmlFor="birthday">
                    <h4 className=""> Voulez vous ajouter un deadline pour la signature du contrat de la part du candidat </h4>
                    <div className="">
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        className="border form-control"
                        placeholder="DD-MM-YYYY"
                        // value={moment(this.state.companyCreationDate)}
                        // disabledDate={this.disabledBirthday}
                        defaultValue={
                          props.deadline && moment(props.deadline)
                          // .format("DD-MM-YYYY")
                        }
                        onChange={(moment, date) => {
                          if (moment != null) {
                            props.setDeadline(moment.toDate())
                          } else {
                            props.setDeadline(null)
                          }
                        }}
                      />
                    </div>
                  </label></div>
                   </>
              :""}
                <input
                  type="submit"
                  value="Envoyer le contrat"
                  className="d-flex justify-content-center btn btn-primary btn-block mt-4  mx-auto p-1"
                />
              </>
            ) : null}
            {afficheProgress ? (
              <div className="d-flex justify-content-center">
                <Space size="large">
                  <Spin size="large" />
                </Space>
              </div>
            ) : null}</div>
            {/* {verifupload ? (
              <>
                <Link to={role=="company"?"/company/addJobPost":"/responsible/addJobPost"} className="d-flex">
                  <button className="btn btn-primary btn-block mt-4 col-lg-2 mx-auto">
                    Etape Suivante <h1>&#8594;</h1>
                  </button>
                </Link>
              </>
            ) : null} */}
          </form>
        </Fragment>
      </div>
    </div>
  );
};

export default withRouter(UploadContract)
