import React from "react";
import { useTranslation } from "react-i18next";

const AffichePFD = (props) => {
  const { t } = useTranslation();

  return (
    <card>
      <h4>{t("Merci de valider votre Offre")} </h4>
      <div className="modal-dialog modal-lg ">
        <div className="modal-content" style={{ height: "350px" }}>
          <div className="modal-body" id="modal-body">
            <iframe src={props.src} height="100%" width="100%"></iframe>
          </div>
        </div>
      </div>
    </card>
  );
};

export default AffichePFD;
