import { Button } from "antd";
import React, { useState } from "react";
import PlusIcon from "../../../../icons/PlusIcon";
import AnswerForm from "./AnswerForm";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
export default function AnswersList(props) {
  const { t } = useTranslation();

  const [update, setUpdate] = useState(false);
  const addAnswer = () => {
    props.addAnswer();
    setUpdate(!update);
  };
  const deleteAnswer = (i) => {
    props.deleteAnswer(i);
    setUpdate(!update);
  };
  let i = -1;
  const list = props.answers.map((answer) => {
    i++;
    const index = i;
    return (
      <div key={index}>
        <AnswerForm
          id={props.id}
          questionIndex={props.questionIndex}
          updateAnswer={props.updateAnswer}
          deleteAnswer={deleteAnswer}
          answer={answer}
          language={props.language}
          index={index}
          numAnswers={props.answers.length}
        />
      </div>
    );
  });
  return (
    <div className="me-3">
      <br />
      <div>{list}</div>
      <div className="d-flex justify-content-left m-2 ">
        <Button
          onClick={addAnswer}
          type="primary"
          style={{
            marginTop: 5,
            marginLeft: 20,
          }}
          icon={<PlusOutlined />}
        >
          {t("Nouvelle Réponse")}
        </Button>
      </div>
    </div>
  );
}
