import { Menu } from 'antd';
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom'
import { AppContext } from '../../routes/AppContext';

function SendTestNavbar(props) {
  const { t } = useTranslation();

     const history = useHistory({});
  const {role} = useContext(AppContext);
  // useEffect(()=>{
  //   document.getElementById('sNavbar').scrollIntoView({ behavior: 'smooth' });
  // },[])
    //  let visible=(history.location.pathname=="/company/setting"||history.location.pathname=="/company/orders")
   const  RedirectMe = (e) => {
        switch (e.key) {
          case "0":
            props.setMethod(0)
            break
          case "1":
            props.setMethod(1)

            break
          case "3":
            history.push("/"+role+"/politique-confidentialite")
            break
   
          default: break
        }
      }
     return (
      <>
   <Menu id="sNavbar"  onClick={(e) => RedirectMe(e)} mode="horizontal" defaultSelectedKeys={["0"]}>
    <Menu.Item key="0" >
    {t("Lien publique")}
    </Menu.Item>
    <Menu.Item key="1" >
    {t("inviter par email")}
    </Menu.Item>
    {/* <Menu.Item key="3" >
    Politique de confidentialite
    </Menu.Item> */}
  
  </Menu>
  
  </>
  )
}
export default withRouter(SendTestNavbar)