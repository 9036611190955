import React, { Component } from 'react'
import { AppContext } from '../../../routes/AppContext';
import { Timeline} from 'antd';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import CvCercle from '../template/cv-cercle/CvCercle';
import { withTranslation } from 'react-i18next';
class CertificatesTwo extends Component {
    static contextType = AppContext

    render() {
        const { t } = this.props;
      let i=-1
      let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]
  
      let list=this?.props?.certificates?.map(certificate=>{
          i++
          let emissionDate=new Date(certificate.emissionDate)
          let date2 =new Date(certificate.expirationDate)
          let expirationDate=certificate.expirationDate? months[date2.getMonth()]+date2.getFullYear():t("Présent")
         let dateText=months[emissionDate.getMonth()]+emissionDate.getFullYear()
        
        return(
                    <Timeline.Item dot={<CvCercle/>}  key={i} >
                        <div className="cvt-f-t">
                            <div className="cvt-f-t-top">
                                <div className="cvt-f-t-top-l">
                                    <div className="cvt-f-t-name">
                                        {certificate.certificateName}
                                    </div>
                                </div>
                            </div>
                            <div className="cvt-f-t-top-time">
                                <div className="cvt-f-t-top-r">
                                {dateText}
                                </div>
                            </div>
                            <div className="cvt-f-t-b">
                                {certificate?.reference}
                            </div>
                        </div>
                    </Timeline.Item>
        )
      }) 
    return (
        <>{list.length>0&&(
        <div className="cvt-formation theme-two">
            <div className="cvt-f-top">
                <div className="cvt-f-img-cert">
                    <CardMembershipIcon  className='cert-icon-cont'  />
                </div>
                <div className="cvt-f-name-cert"> {t("Certificats")} :</div>
            </div>
            <Timeline style={{marginLeft:"25px"}}>
                {list}
            </Timeline>
        </div>
        )}</>
    )
  }
}
export default withTranslation()(CertificatesTwo)