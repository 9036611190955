import { Modal, notification, Progress, Tag } from "antd";
import moment from "moment-timezone";
import React, { Component } from "react";
import Cv from "../cvPDF/Cv";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import AsyncImage from "../AsyncImage";
import { RecommendCandidate } from "../../services/api/recommendCandidate/RecommendCandidate";
import { AppContext } from "../../routes/AppContext";
import cv from "../../media/image/cv.svg"
import { withTranslation } from 'react-i18next';

class CandidateComponent extends Component {
  static contextType = AppContext;

  state = { modalVisible: false, clicked: false };
  setModalVisible = (value) => {
    this.setState({ modalVisible: value });
  };
  Contact = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let candidate = this.props.candidate;
    Modal.confirm({
      title: this.props.t("Envoyer"),
      icon: <ExclamationCircleOutlined />,
      content:
        this.props.t("Après confirmation, un courriel de recommandation sera envoyé à") +" "+
        candidate.name +
        this.props.t("pour consulter votre offre et postuler."),
      okText: this.props.t("Envoyer"),
      cancelText: this.props.t("Annuler"),
      onOk: async () => {
        await RecommendCandidate(
          {
            email: candidate.email,
            companyName: this.context.companyName,
            jobPostTitle: this.props.jobPost.title,
            idJobPost:this.props.jobPost._id,
            idCandidate:candidate._id,
          },
          {}
        );
        this.context.socket.emit("sendNotification",
        {receiverId:candidate._id,type:8}
     );
        this.setState({ clicked: true });
        //notif
        notification.success({
          description: this.props.t("envoyé avec succée"),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#d4edda",
            marginTop: "5rem",
          },
        });
      },
    });
  };
  render() {
    const { t,i18n } = this.props;
    const languages = { "fr": "language_fr", "en": "language_en" }
    const softSkills = { "fr": "soft_skill_fr", "en": "soft_skill_en" }
    const language = languages[i18n.language]
    const softSkill = softSkills[i18n.language]

    let candidate = this.props.candidate;
    let email = candidate.email;
    let phone = candidate.phone.toString();
    let hiddenEmail = "";
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    let hiddenPhone = "";

    for (let i = 0; i < phone.length; i++) {
      if (i > 4) {
        hiddenPhone += "*";
      } else {
        hiddenPhone += phone[i];
      }
    }
    let skillsTags = candidate.idHardSkills.map((skill) => {
      return (
        <Tag className="m-1 " color="magenta">
          {skill.idHardSkill.skill}
        </Tag>
      );
    });
    let softSkillsTags = candidate.idSoftSkills.map((skill) => {
      return (
        <Tag className="m-1" color="cyan">
          {skill.idSoftSkill[softSkill]}
        </Tag>
      );
    });
    let languagesTags = candidate.idLanguages.map((skill) => {
      return (
        <Tag className="m-1" color="geekblue">
          {skill.idLanguage[language]}
        </Tag>
      );
    });
    // let description = candidate?.idJobPostDescription?.description.replace(/<[^>]+>/g, '').slice(0,300)+" ...";
    return (
      <>
        <tr

        >         
         <td>
          {candidate.image ? (
            <AsyncImage
              className="logo-m rounded-3"
              alt={candidate.name}
              src={
                this.context.envVar.REACT_APP_CLIENT_URL
                + "/candidate/images/" + candidate.image}
            />
          ) : (
            ""
          )}</td>
<td><b className="text-primary m-1"> {candidate.name}</b></td>
<td>   <div className="text-secondary">
{this.props.t("email") }: {hiddenEmail}
          </div></td>
<td>
<div className="text-secondary">
{this.props.t("téléphone") }  : {hiddenPhone}
          </div>
</td>
          <td className="  text-center m-2 align-middle">
            {/* Score: {parseInt(jobPost.score*100)}% */}

            <Progress
              type="circle"
              percent={parseInt(candidate.score * 100)}
              format={(percent) => `${percent}%`}
              width={55}
              strokeColor={
                parseInt(candidate.score * 100) < 30 ? "orange" : "green"
              }
            />
          </td>

          <td          
           role="button"
          onClick={() => {
            this.setModalVisible(true);
          }}>
                <AsyncImage
              className="logo-m rounded-3"
              alt={"cv"}
              src={cv}
            />
          </td>
       <td>
          {this.state.clicked ? (
               <span
               >
               <AsyncImage
               className="logo-m rounded-3"
               alt={"cv"}
               src={cv}
             /></span>
          ) : (
            <span
            onClick={this.Contact}
            role="button"
            >
            <AsyncImage
            className="logo-m rounded-3"
            alt={"cv"}
            src={cv}
          /></span>
          )}
</td>

        </tr>
        <tr>
                <td colSpan={6} >
                     {skillsTags}
          {softSkillsTags}
          {languagesTags}
                </td>
            </tr>
        <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b> {this.props.t("Aperçu du CV") }  </b>
            </h5>
          }
          className="mb-2 mt-2"
          width={"95%"}
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
          footer={<></>}
        >
          {this.state.modalVisible && (
            <Cv decryptedData={false} userId={this.props.userId} candidate={candidate} />
          )}
        </Modal>
      </>
    );
  }
}
export default withTranslation()(CandidateComponent)
