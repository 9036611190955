import axios from "../../../interceptors/axios"

export const getEstimationBills = async (headers) => {
    try {
      let res = await axios.get(`/api/pricing/estimationBill/get`,{
        headers:headers
      });
      return res;
    } catch (err) {
    }
  };