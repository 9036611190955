import React from 'react'
import { useParams, withRouter } from 'react-router-dom';
import BlogContainer from '../components/blog/BlogContainer'

 function BlogView(props) {
  const params = useParams();
  return (
    <div style={{marginTop:"5rem"}} >
<BlogContainer />
     
    </div>
  )
}
export default withRouter(BlogView)