import CryptoJS from "crypto-js";
import { getEnvVars } from "./envVars";

 const decrypt = (data) => {
  const envVar = getEnvVars();
    return CryptoJS.AES.decrypt(
        data,
        envVar.REACT_APP_CRYPTING_KEY
      ).toString(CryptoJS.enc.Utf8);

}
export default  decrypt
