import axios from "../../../interceptors/axios"

export const SaveTestResult =  async (data,headers) => {
    try {
     let res=await axios.post('/skillTest/save',data,
      {
      headers:headers
      }
      )
     return res
  }
 catch (err) {
    }
  }