import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Educations extends Component {
  render() {
    const { t } = this.props;

    let i=-1
    let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]
    let list=this?.props?.educations?.map(education=>{
        i++
        let startDate=new Date(education.startDate)
        let date2 =new Date(education.endDate)
        let endDate=education.endDate? months[date2.getMonth()]+" "+date2.getFullYear():t("Présent")
       let dateText=months[startDate.getMonth()]+" "+startDate.getFullYear()+" - "+endDate
      return(<li key={i} className=''>
          <b className='text-primary text-capitalize' > {education.school} </b><br/>
          <span className='text-secondary' >{dateText}</span><br/>
          <span className='text-capitalize' >{education.diploma}</span>
        <br/>
        </li>
      )
    }) 
    return <div className='u-list '>
{list.length>0&&<h6><b>
<i className="mdi mdi-school me-1 icons12"></i>
   {t("Formations académiques")} </b></h6>}
<ul className=' border-start border-3 border-primary'>
{list}
</ul>
    </div>;
  }
}
export default withTranslation()(Educations)