import { Modal, Timeline } from 'antd';
import React, { useContext } from 'react'
import { DeleteEducation } from '../../../services/api/cv/education/DeleteEducation';
import PenIcon from '../../icons/PenIcon';
import XMarkIcon from '../../icons/XMarkIcon';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../routes/AppContext";
import CvCercle from '../../cvPDF/template/cv-cercle/CvCercle';
import { useTranslation } from "react-i18next";

const EducationComponents = (props) => {
    const {user,setUser,headers} = useContext(AppContext);
    const { t } = useTranslation();
    let {education, getDate}=props
    const updateEducation = async () => {
      props.setIndex(props.index);
      props.setModalVisible(true)
    };
    const deleteEducation = async () => {
    if (user.educations.length == 1) {}
    else{
      Modal.confirm({
        title: t("Supprimer formation"),
        icon: <ExclamationCircleOutlined />,
        content:
        t("voulez vous supprimer la formation") + education.diploma,
        okText: "Supprimer",
        okType: "danger",
        cancelText: "Annuler",
        onOk: async() => {
          //send request
        let res=await  DeleteEducation({id:education._id},headers)
          //update context
          let cv = user
          cv.educations.splice(props.index, 1);
          setUser(cv)
          props.updateView()
        },
        // onCancel:()=>{alert(index)}
      });}
    };
    //update and delete icons , update will open the modal
  return (
    <Timeline.Item dot={<CvCercle/>}  >
        <div className="time">
          <div className="time-title">
          {education.school}
          <PenIcon onClick={updateEducation} className="me-2   zoom1" width="1rem" fill="blue" />
          <XMarkIcon onClick={deleteEducation} className="me-2 zoom1" width="1rem" fill="red" />
          </div>
          <div className="time-body">

            <div className="time-date">
            {getDate(education.startDate, education.endDate)}
            </div>
            <div className="time-cont">
            {education.diploma}
            </div>
          </div>
        </div>
      </Timeline.Item>
  )
}

export default EducationComponents