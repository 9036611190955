import { Modal, Select } from 'antd';
import React, { useContext } from 'react'
import { AppContext } from '../../routes/AppContext';
import CheckIcon from '../icons/CheckIcon';
import InfoIcon from '../icons/InfoIcon';
import TestSteps from './TestSteps'
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function TestDetails(props) {
  const { testLanguage,setTestLanguage } = useContext(AppContext);
  const { t,i18n } = useTranslation();
  const info = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <p className="">
{t("Info : Votre minuterie d’examen commence seulement après avois cliqué sur le bouton « Commencer le test » à la fin de cette procédure d’installation")}
</p>
        </div>
      ),
      onOk() {},
    });
  };
  return (
    <div>
          <TestSteps current={4} />

      <h3 className='text-center' style={{direction : i18n.language==="ar" ?"rtl" : "ltr"}} >{t("Êtes-vous prêts")} {i18n.language==="ar" ? "؟":"?"}     <span onClick={info} role="button">
                        <InfoIcon
                          className="zoom1 mx-2"
                          fill="blue"
                          width="1.4rem"
                        />{" "}
                      </span>
      </h3>
      <p className="inline block text-muted ptag   " style={{direction : i18n.language==="ar" ?"rtl" : "ltr" , textAlign: i18n.language==="ar" ?"right":"left"}}>
                    <h4>{t("Avant de commencer")} :</h4> 
                    <i className="">
                      <CheckIcon width="1rem" />
                    </i>{" "}
                    {t("Assurez vous que votre connexion internet est fiable")}.
                    <br />
                    <i className="">
                      <CheckIcon width="1rem" />
                    </i>{" "}
                    {t("Veuillez noter qu’une fois le test commence vous ne pouvez pas faire un retour en arrière.")}
                    <br />
                    <i className="">
                      <CheckIcon width="1rem" />
                    </i>{" "}
                    {t('Vous devez terminer le test en une seule session')}
                    <br />
                  </p>
                  <p className="inline block text-muted ptag   " style={{direction : i18n.language==="ar" ?"rtl" : "ltr" , textAlign: i18n.language==="ar" ?"right":"left"}}>
                    <h4>{t("Vous allez démarrer le test")} :</h4> 
                    <i className="">
                      <CheckIcon width="1rem" />
                    </i>{" "}
                    {t('Ce test est constitué de')} {props.testDescription.questionsNumber } {t('questions, il y a toujours au moins une réponse valide')}.
                    <br />
                    {/* <i className="">
                      <CheckIcon width="1rem" />
                    </i>{" "}
                    {t('Vous avez entre')} {props.testDescription.minTime} -  {props.testDescription.maxTime} {t('min par question')}.
                    <br /> */}
                    <i className="">
                      <CheckIcon width="1rem" />
                    </i>{" "}
                    {t("Le score final prend en compte l'ensemble des réponses pour chaque question, pas de panique si vous avez une erreur dans vos réponses !")}
                    <br />
                  </p>
                  <p className="inline block text-muted ptag   " style={{direction : i18n.language==="ar" ?"rtl" : "ltr" , textAlign: i18n.language==="ar" ?"right":"left"}}>

      <h6 className='text-danger' >    {t("Attention")} :</h6>
      <p className='text-primary' ><strong>

        {t("Sachez que dès que vous avez cliqué sur le bouton 'Commencer le test', vous ne pouvez plus interrompre le processus d'examen. Tout abandon, après avoir cliqué sur le bouton, aura pour conséquence que votre examen sera considéré comme étant passé.")}
      </strong>  </p></p>
<p className='inline block text-muted ptag' style={{direction : i18n.language==="ar" ?"rtl" : "ltr" , textAlign: i18n.language==="ar" ?"right":"left"}} >
  <h5>{t("Choisir la langue du test")}</h5>
       <Select className="w-50"  
                  // style={{ width: '40%' }} 
                   showSearch value={testLanguage}
    placeholder={t("Rechercher pour sélectionner")} onChange={setTestLanguage}>
    <Option disabled={!props.testDescription.languages.includes("fr")} value="fr">{t("Francais")}</Option>
    <Option disabled={!props.testDescription.languages.includes("en")} value="en">{t("Anglais")}</Option>
  </Select>

</p>
      <br />
      <button className='btn btn-primary right' onClick={() => { props.getTest() }} >{t("Commencer le test")}</button>
    </div>
  )
}
