import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DatePicker } from "@material-ui/pickers";
import { GetAppointments } from '../../services/api/appointments/GetAppointments';
import decrypt from '../../utils/decrypt';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { notification } from 'antd';

// pick a date util library
import MomentUtils from '@date-io/moment';
import { useTranslation } from "react-i18next";
import { AppContext } from '../../routes/AppContext';

export default function DateTimePicker(props) {
  const {headers,serverTime} = useContext(AppContext);
  const { t } = useTranslation();
    const [date, changeDate] = useState(new Date(serverTime));
    const [selected, setSelected] = useState(null);
    const [events,setEvents]=useState([{date:"2022-05-11T11:00"}])
    useEffect(async()=>{
      let res=await GetAppointments(headers)
  if(res){
      let appointments=JSON.parse(decrypt(res.data))
      let e=appointments.map(a=>{
        return({
          id:a._id,
          date:moment(a.date).format()
        })
      })
      setEvents(e)}
    },[])
    const Confirm=()=>{
        if(selected){
props.setStep(2)
props.setSelected(selected)
        }
        else{
   //notification
   notification.warn({
    description:"veuiller choisir un rendez-vous!",
    className: 'custom-class mt-5',
    style: {
      width: 600,
      backgroundColor:"#fff3cd",
      marginTop:"5rem"
    },
  });
        }
    }
   const timesList= events.filter((e)=>{
     return(moment(e.date).isSame(moment(date), 'day'))
})
const list=timesList.map((e)=>{return(<div className='col-lg-4 col-md-6 col-sm-12 col-4 d-flex justify-content-end ' >
   <div onClick={()=>{setSelected(e)}} role="button" className={selected?.id==e.id?"rounded bg-primary text-white mb-2 mx-0 p-2":'rounded border border-primary zoom-0 text-primary mb-2 mx-0 p-2 '} style={{width:"5rem"}}>
       {moment(e.date).format('HH:mm')}
       </div> 
    </div>)})
    
  return (
    <div className='my-3' >
    <h4>
    <b className=' rounded-circle bg-primary text-white px-2 py-1  m-1 ' >1</b>
    <strong>{t("Choisissez un rendez-vous")}</strong>
    </h4>
   {props.step==1&& <>
    <hr />
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale="fr" >
        <div className='row mx-3 text-center' >
            {/* <div className='text-secondary' >
             {moment(date).format("YYYY")}
            </div> */}
            <h5 className='text-black'>
                <b>
             {moment(date).format("dddd DD MMM")}
             {selected&& <> {" , "+moment(selected.date).format("HH:mm")}</>}
                </b>
            </h5>
              </div>
    <div className='row mt-4'>
        <div className='col-md-1'></div>
        <div className='col-5 text-center' >
        <DatePicker
        autoOk
        variant="static"
        openTo="date"
        value={date}
        onChange={(d)=>{changeDate(d);setSelected(null)}}
        shouldDisableDate={(day)=>{
            // return !moment('2010-01-01').isSame('2010-02-01', 'day'); 
          let  weekDay=moment(day).day()
            return(weekDay==0||weekDay==6);
        }}
        // minDate={new Date()}
        disablePast={true}
        // ToolbarComponent
        disableToolbar={true}
        
      />

        </div>
     

        {list.length? <div 
        className='col-sm-5  text-center'
         >
            <div className='row ml-4 pl-1  mt-4 ' >

{list}
            </div>

        </div>
        :<div className='text-center col-lg-5' >{t("Il n’y a aucune disponibilité à cette date. Veuillez en choisir une autre.")}</div>}
        <div className='col-lg-1'></div>
        
    </div>
    <div className='text-center mb-2'>

    <div onClick={Confirm} className='btn btn-primary  rounded'>
    {t("Confirmer")}
    </div>
    </div>
  </MuiPickersUtilsProvider></>}
  </div>
  )
}
