import { Button, Col, Row, Space } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DatabaseIcon from "../icons/DatabaseIcon";
import PlusIcon from "../icons/PlusIcon";
import UploadIcon from "../icons/UploadIcon";
import { AppContext } from "../../routes/AppContext";
import { useHistory, withRouter } from "react-router-dom";
import { DatabaseOutlined, LaptopOutlined, UnorderedListOutlined} from '@ant-design/icons';
import { Trans, useTranslation } from "react-i18next";

function AddTest() {
  const { t } = useTranslation();

  const { role } = useContext(AppContext);
  const history = useHistory({});
  return (
    <div className="AddTestView3button">
      <h3 className="titrePage ">{t("Ajouter votre test de compétence")}</h3>
      <section className="btn">
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-12">
            <div id="btn1">
              <Link to={{ pathname: "/" + role + "/addTest/upload" }}>
                {" "}
                <Space>
                  <Button className="bouton" id="bouton1">
                    <div>
                      <LaptopOutlined className="icon" />
                    </div>
                    <br></br>
                    <span id="text">
                      {" "}
                      <Trans i18nKey="TÉLÉCHARGER À PARTIR <br></br>VOTRE UNIVER">
                      TÉLÉCHARGER À PARTIR <br></br>VOTRE UNIVER
                      </Trans>
                    </span>
                  </Button>
                </Space>{" "}
              </Link>
            </div>
          </div>
          <div  className="col-xl-4 col-lg-6 col-md-12">
            <div id="btn2">
              <Link to={{ pathname: "/" + role + "/addTest/fromDB" }}>
                {" "}
                <Space>
                  <Button className="bouton">
                    <div>
                      <DatabaseOutlined className="icon" />
                    </div>
                    <br></br>
                    <span id="text">
                    <Trans i18nKey="CRÉER À PARTIR DE NOTRE <br></br> BASE DE DONNÉES">
                      CRÉER À PARTIR DE NOTRE <br></br> BASE DE DONNÉES
                      </Trans>
                    </span>
                  </Button>
                </Space>
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12">
            <div id="btn3">
              <Link  to={{ pathname: "/" + role + "/addTest/form" }}>
                {" "}
                <Space>
                  <Button className="bouton" id="bouton2" >
                    <div>
                      <UnorderedListOutlined className="icon" />
                    </div>
                    <br></br>
                    <span id="text">
                    <Trans i18nKey="CRÉER À PARTIR <br></br> D'UN FORMULAIRE">
                      CRÉER À PARTIR <br></br> D'UN FORMULAIRE
                      </Trans>
                    </span>
                  </Button>
                </Space>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default withRouter(AddTest);
