import React from "react";
import CandidateSignin from "../../components/signin/CandidateSignin";


export default function CandidateSigninView(props) {
    return (
      <main  className="">   
      <CandidateSignin/>
        </main>
    )
}
