import axios from "../../../interceptors/axios"

export const payOrder = async (orderData,headers) => {
    try {
      let res = await axios.post(`/api/pricing/order/pay`, orderData,{
        headers:headers
      });
      return res;
    } catch (err) {
    }
  };