import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import {  Steps } from 'antd';

export default function UploadCvsSteps(props) {
  const { t } = useTranslation();
  const { Step } = Steps;
  const steps = [
  
    {
      title: t("Offre"),
    },
    {
      title: t("Upload"),
    },
    // {
    //   title: 'Confirmer',
    // },
    {
      title: t("Résultat"),
    },
   
  //   {
  //     title: 'Consulter',
  //   },
  ];
  
  return (
         <div className="steps-title " style={{ direction: "ltr"}}>
        <Steps  current={props.current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        </div>  
  )
}
