import { getEnvVars } from '../../../utils/envVars';
import axios from "../../../interceptors/axios"
export const checkPayment = async (data) => {
  const envVar = getEnvVars();
        try{
           let res = await axios.get(`https://app.paymee.tn/api/v1/payments/${data.token}/check`,
         {
          headers: {"Content-Type": "application/json",
          Authorization: `Token ${envVar.REACT_APP_PAYMEE_TOKEN}`}
        })
        return res
        }catch(err){
        }
    }
