import axios from "../../../interceptors/axios"

export const GetCv = async (data,headers) => {
    try {
   let res=await   axios.get(data?.id? "/api/cv/get/"+data?.id:"/api/cv/get", {
        headers:headers
      })
      return res
} catch (err) {
    }
  }