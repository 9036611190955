//company or responsible
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../AppContext";
import Container from "../Container";
export default function ResponsibleRouter({ children,component, ...rest }) {
  const { user } = useContext(AppContext);
  return (
    <Route {...rest}>
      {user.role == "responsible" ? (
        <Container {...rest}>{children?children:component()}</Container>
      ) : (
        <Redirect to="/signin/company" />
      )}
    </Route>
  );
}
