import React, { Fragment, useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Timer from "./timer";
import PointsFidelitepay from "./PointsFidelitepay";
import CodePromopay from "./CodePromopay";
import { AppContext } from "../../routes/AppContext";
import { initiatePayment } from "../../services/api/paymee/initiatePayment";
import { notification } from "antd";
import { payOrder } from "../../services/api/pricing/payOrder";
import decrypt from "../../utils/decrypt";
import { useTranslation } from "react-i18next";


export const CheckoutComponent = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { cart, products,total,setCart,setUCodepromo,setUPtsFidelite,userId,fonctionnalites,envVar,headers } = useContext(AppContext);
const [showPF,setShowPF]=useState(false)
const [showCP,setShowCP]=useState(false)
  
  const [state, setState] = useState({
    address: "",
    phone: "",
    error: false,
    success: false,
    clientToken: null,
    instance: {},
  });


  const Pay = async () => {
    if(total==0){
      let panier=JSON.parse(decrypt(localStorage.getItem("cart")))
      let allProduct=panier.map(item=>{if(item._id)return({id:item._id});
       else return false
      })
      allProduct=allProduct.filter(p=>p!=false)
      let idCompanyPackage=null
      let duration=0
      for (let i = 0; i < panier.length; i++) {
        if(panier[i].idCompanyPackage){
          idCompanyPackage=panier[i].idCompanyPackage
          duration=panier[i].duration
        }
      }
      //
     let fonctionnalities =panier.map(item=>{return item.fonctionnalites[0].listefonctionnalite })
     let idFonctionnalities=[]
     let qtes=[]
     let fTypes=[]
     for (let i = 0; i < fonctionnalities.length; i++) {
      const forfait = fonctionnalities[i];
      for (let j = 0; j < forfait.length; j++) {
        const item = forfait[j];
            let index=idFonctionnalities.indexOf(item.idFonctionnalite)
            let fType= fonctionnalites.find((functionality) => functionality._id == item.idFonctionnalite)?.fType
      if(index==-1){
        idFonctionnalities.push(item.idFonctionnalite)
        fTypes.push(fType)
        qtes.push(parseInt(item.qte))
      }
      else{
        if(fType==1||fType==3){
         qtes[index]=Math.max(qtes[index],parseInt(item.qte))
        }else
        qtes[index]=qtes[index]+parseInt(item.qte)
      }
      }
     }
     fonctionnalities=[]
     for (let i = 0; i < idFonctionnalities.length; i++) {
      fonctionnalities.push({idFonctionnalite:idFonctionnalities[i],qte:qtes[i],fType:fTypes[i]})
     }

   let allModules =panier.map(item=>{return item.modules })
   let idModules=[]
   let prices=[]
   for (let i = 0; i < allModules.length; i++) {
    const forfait = allModules[i];
    for (let j = 0; j < forfait.length; j++) {
      const item = forfait[j];
          let index=idModules.indexOf(item.idModule)
          let price= parseInt(item.price)
    if(index==-1){
      idModules.push(item.idModule)
      prices.push(price)
    }
    else{
      prices[index]=prices[index]+price
    }
    }
   }
   allModules=[]
   for (let i = 0; i < idModules.length; i++) {
    allModules.push({idModule:idModules[i],price:prices[i]})
   }
     //{idfonctionnalites:"",qte:""}
      let res=await payOrder({  
        allProductF:{},
        allProduct:allProduct ,
        fonctionnalites:fonctionnalities,
        modules:allModules,
        user: userId,
        amount:Number(total) ,
        ptfideliteOffert:Number(total),
        ptfideliteUsed: 0,
        transactionId: "transactionId",
        remise:0 ,
        status: "en cours",
        type: "Mensuel",
        order: "Recrutement",
        category:"Gratuit",
        idCompanyPackage:idCompanyPackage,
        duration:duration
      },headers)
    if(res){
       
       //notification
        notification.success({
          message: 'Notification',
          description:res.data.message,
          className: 'custom-class',
          style: {
            width: 600,
            backgroundColor:"#d4edda",
            marginTop:"5rem"
          },
        });
        setCart([])
        setUCodepromo({})
        setUPtsFidelite(0)
        localStorage.removeItem("cart")
        localStorage.removeItem("uCodepromo")
        localStorage.removeItem("uPtsFidelite")
        window.location.replace('/company/orders');}
    }
    else
   { let res = await initiatePayment({
      vendor: envVar.REACT_APP_PAYMEE_VENDOR,
      amount: total,
      note: "Order #1000132",
    });
 
    history.push({
      pathname: "/company/paymee",
      state: { data: res.data },
    });}
  };

  return (
    <Fragment>
      <div className="">
        <section className="mx-4 mt-20 md:mx-12 md:mt-32 lg:mt-24 ">
          {/* Product List */}

          <div className="">
            <div className="  mb-20">
              <div className="mb-4 mt-16">
                <div className="text-xl mx-2 ml-16" style={{ marginTop: 30 }}>
                  {t("Paiement processus")}
                </div>
                <br />
                <br />
                {/* paiement processus */}
                <div className="hidden lg:block col-span-1 flex item-center ">
                  <span
                    className=" ml-16 px-4 py-4 rounded-l-full font-light tracking-widest "
                    style={{ backgroundColor: "#005bea" }}
                  >
                    <span
                      className="h-20 w-20 p-2 rounded-full tracking-widest text-black"
                      style={{ backgroundColor: "#FFe436" }}
                    >
                      {" "}
                      01{" "}
                    </span>
                    <span className="ml-2 text-white"> {t("Informations")} </span>
                  </span>

                  <span
                    className="ml-2 px-4 py-4 rounded-l-full font-light tracking-widest"
                    style={{ backgroundColor: "#005bea" }}
                  >
                    <span
                      className="h-20 w-20 p-2 rounded-full tracking-widest text-black"
                      style={{ backgroundColor: "#FFe436" }}
                    >
                      {" "}
                      02{" "}
                    </span>
                    <span className="ml-2 text-white"> {t("Verification")} </span>
                  </span>
                  <span
                    className="ml-2 px-4 py-4 rounded-l-full font-light tracking-widest "
                    style={{ backgroundColor: "#005bea" }}
                  >
                    <span
                      className="h-20 w-20 p-2 rounded-full tracking-widest text-black"
                      style={{ backgroundColor: "#FFe436" }}
                    >
                      {" "}
                      03{" "}
                    </span>
                    <span className="ml-2 text-white"> {t("Paiement")} </span>
                  </span>
                </div>
              </div>
              {/* Forfaits a acheter informations composant*/}
            </div>
            <div className="row">
              <span className="col-md-5">
                <CheckoutProducts cart={cart} products={products} />
              </span>
              <span className="col-md-7">
                {/*  composant de paiement*/}
                <div className="w-full px-8 py-2  md:order-last">
                  <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    <div className="absolute inset-0 transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                    <div className="relative px-8 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                      <div className="max-w-md mx-auto">
                        <Fragment>
                          <div
                            onBlur={(e) => setState({ ...state, error: false })}
                            className="p-4 md:p-8"
                          >
                            {state.error ? (
                              <div className="bg-red-200 py-2 px-4 rounded">
                                {state.error}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="hidden lg:block col-span-1 flex  mb-1">
                              <div>
                                <span
                                  className="h-20 w-20 p-4 rounded-full tracking-widest text-black"
                                  style={{ backgroundColor: "#FFe436" }}
                                >
                                  {" "}
                                  03{" "}
                                </span>
                                <span className="text-2xl ml-4 font-semibold">
                                  {t("Paiement")}
                                </span>
                              </div>
                            </div>
                            <br />
                            <br />

                            <Timer />
                            <br />
                            <br />

                            <div>
                              <div role="button" onClick={()=>{setShowPF(!showPF)}} className="border border-blue-200 ml-2 mb-4 px-4 py-4 rounded-full font-light tracking-widest curseur-pointer">
                                <span className="ml-2">
                                  {" "}
                                  {t("Utilisation des points de fidélité")}
                                </span>
                              </div>
                           {showPF &&   <div className="additionalClassForContent">
                                <PointsFidelitepay />
                                <br />
                              </div>}
                              <div role="button" onClick={()=>{setShowCP(!showCP)}} className="border ml-2 mb-4 px-4 py-4 rounded-full font-light tracking-widest curseur-pointer">
                                <span className="ml-2">
                                  {" "}
                                  {t("Utilisation de code promo")}
                                </span>
                              </div>
                            {showCP &&  <div>
                                <CodePromopay />
                                <br />
                              </div>}

                              <div className="text-center">
                                <br />

                                <div
                                  onClick={Pay}
                                  className="btn btn-primary btn-lg  w-50"
                                >
                                  {t("Payer")}
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

const CheckoutProducts = () => {
  const { t,i18n } = useTranslation();

  const history = useHistory();
  const { cart, uCodepromo, uPtsFidelite, total, setTotal } =
    useContext(AppContext);

    const language=i18n.language 
    const modulesLanguage={"fr":"name","en":"name_en"}
    const moduleLanguage=modulesLanguage[language]
  
    const functionalitiesLanguage={"fr":"fName","en":"fName_en"}
    const functionalityLanguage=functionalitiesLanguage[language]

    const packagesLanguage={"fr":"pName","en":"pName_en"}
    const packageLanguage=packagesLanguage[language]

  useEffect(() => {
    let amount = 0;
    cart.map((p) => {
      amount += p.pPrice;
    });
    amount=amount*1.19
    if (uCodepromo) amount = (amount * (100 - uCodepromo.pourcentage)) / 100;
    amount = amount - uPtsFidelite / 100;
    setTotal((amount).toFixed(3));
  }, [cart, uCodepromo, uPtsFidelite]);

  return (
    <Fragment>
      <br />

      {/* 22 */}

      <div className="max-w-full  bg-white flex flex-col rounded overflow-hidden shadow-lg pb-8 mt-16">
        <div
          className="flex flex-row items-baseline flex-nowrap p-8"
          style={{ backgroundColor: "#005bea" }}
        >
          <h1
            className="ml-2 uppercase font-bold text-white"
            style={{ color: "#FFe436" }}
          >
            {t("Informations")}
          </h1>
          <p className="ml-2 font-normal text-white">{t("Forfaits à acheter")}</p>
        </div>

        <div className="mt-4 ">
          {cart.length ? (
            cart.map((product, index) => {
              return (
                <div className="mt-2 flex md:border-b sm:flex-row mx-6 sm:justify-between flex-wrap ">
                  <div className="flex flex-col ml-2 mt-8">
                    <p className="text-xs text-gray-500 font-bold">
                      {" "}
                      {product[packageLanguage]}
                    </p>
                  </div>

                  <div className="flex flex-col p-2 mt-6">
                    <p className="font-bold"> Prix</p>
                    <p className="text-gray-500">
                      <span className="font-bold">{product.pPrice} </span> {t("TND")}{" "}
                    </p>
                  </div>

                  <div className="flex flex-col flex-wrap p-2 mt-6">
                    <p className="font-bold">{t("Subtotal")}</p>

                      <p className="text-gray-500">
                        <span className="font-bold"> {(product.pPrice*1.19).toFixed(3)}</span> {t("TND")}
                      </p>
                   
                  </div>

                  <div className="flex flex-col p-2 mt-6">
                    <p className="font-bold">{t("TVA")}</p>
           
                      <p className="text-gray-500">
                        <span className="font-bold">+ 19%</span>
                      </p>
                
                   
                  </div>
                </div>
              );
            })
          ) : (
            <div>{t("Aucun forfait trouvé")}</div>
          )}
        </div>

        <div
          className="mt-2 flex flex-row flex-wrap md:flex-nowrap justify-between items-baseline"
          style={{ backgroundColor: "#005bea" }}
        >
          <div className="flex py-2 flex-row flex-wrap"></div>
          <p className="font-bold text-white ml-64">{t("Total")}</p>

          {cart.length ? (
            <div className="md:border-l-2 mx-6 md:border-dotted flex flex-row py-4 mr-12 flex-wrap">
              <div className="flex flex-col flex-wrap p-2 mt-4">
                <p className="font-bold text-white">{t("Subtotal")}</p>

                <p className="text-white">
                  <span className="font-bold"> {total} </span> {t("TND")}
                </p>
              </div>

              <div className="flex flex-col p-2 mt-4 ml-12">
                <p className="font-bold text-white">{t("Remise")}</p>

                <p className="text-white">
                  <span className="font-bold">
                    - {uCodepromo ? uCodepromo.pourcentage : 0}
                  </span>{" "}
                  %{" "}
                </p>
              </div>
            </div>
          ) : (
            <div className="md:border-l-2 mx-6 md:border-dotted flex flex-row py-4 mr-12 flex-wrap">
              <div className="flex flex-col flex-wrap p-2 mt-4">
                <p className="font-bold text-white">{t("Subtotal")}</p>

                <p className="text-white">
                  <span className="font-bold"> {0} </span> {t("TND")}
                </p>
              </div>

              <div className="flex flex-col p-2 mt-4 ml-12">
                <p className="font-bold text-white">{t("Remise")}</p>

                <p className="text-white">
                  <span className="font-bold">- {0}</span> %{" "}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutProducts;
