import { notification } from "antd";
import React, {
  Fragment,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";


import { AppContext } from "../../routes/AppContext";
import { usePtsFidelite } from "../../services/api/pricing/usePtsFidelite";


const PointsFidelitepay = () => {
  const { t } = useTranslation();

  // const { cart, dispatch } = useContext(AppContext);
 let points =0
 let  price =0
 let  remisesolde =0
 const { userId,  uPtsFidelite,setUPtsFidelite,headers } = useContext(AppContext);
 

  const [fData, setFdata] = useState({
    id: "",
    prix: price,
    pointsf: 0,
    converttt: points,
    msg:
      "Votre somme de points de fidélité est"+" " + 0
      ,
    error: false,
  });


  let a = parseInt(points);
  let b = parseInt(fData.pointsf);
  let c = parseFloat(price);

  // cette fonction get point fidelite user & update la valeur par soustraction de la valeur
  // saisie de la somme initiale & dispatch layout context & localStorage
  const updateptfUserAction = async (dispatch, fData) => {
 
  };
const Updateptsfidelite=async()=>{
  let res=await  usePtsFidelite({ptsfideliteUsed:fData.pointsf},headers)
  notification.success({
    description:"points fidélité utilisé avec succes",
    className: 'custom-class',
    style: {
      width: 600,
      backgroundColor:"#d4edda",
      marginTop:"5rem"
    },
  });
  setUPtsFidelite(uPtsFidelite+fData.pointsf)
}
  // cette fonction fixe et convertie la valeur saisie a convertir en point de fidelite en prix
  //  dans des variables localStorage & appelle la fonction de MAJ utilisateur par somme pt fidelite.
  const HandleConversion = async() => {
    if (a != 0) {
      if (b > 0 && b <= a) {
        localStorage.setItem(
          "pointsfidelitesUsed",
          parseInt(localStorage.getItem("pointsfidelitesUsed")) + b
        );
        localStorage.setItem(
          "pointsfidelitesRemiseSolde",
          (
            b * c +
            parseFloat(localStorage.getItem("pointsfidelitesRemiseSolde"))
          ).toFixed(2)
        );

        setFdata({
          ...fData,
          pointsf: a - b,
          converttt: "",
          msg:
            "vous avez convertir " +
            fData.pointsf +
            " points et il vous reste " +
            (a - b),
        });

        const formData = {
          uId: fData.id,
          ptfidelite: a - b,
        };

        // updateptfUserAction(dispatch, fData);
        Updateptsfidelite()


      } else {
        setFdata({
          ...fData,
          error: `Somme entrée non convenable. Entrez une somme 0-${a}.`,
        });
        setTimeout(() => {
          setFdata({
            ...fData,
            pointsf: 0,
            error: false,
          });
        }, 2000);
      }
    } else {
      setFdata({
        ...fData,
        error: "Vous n'avez pas de points de fidélité. Votre somme est nulle!",
      });
      setTimeout(() => {
        setFdata({
          ...fData,
          pointsf: 0,
          error: false,
        });
      }, 2000);
    }
  };


  const alert1 = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  return (
    <Fragment>
      <div className="flex flex-col space-y-2">
      {/* Alert */}
        {fData.error ? alert1(fData.error, "red") : ""}
        <div className="font-medium text text-gray-600 tracking-widest  px-3 py-2 rounded-lg">
          {t("Entrez la somme à utiliser, ici!")}
        </div>

        <input
          placeholder="Nombre de points"
          value={fData.pointsf}
          onChange={(e) => setFdata({ ...fData, pointsf: e.target.value })}
          type="number"
          id="number"
          className="border px-4 py-2 w-full focus:outline-none"
        />
        <div>{fData.msg}</div>

        <div className="text-center" >
        <div
          onClick={HandleConversion}
          className=" btn btn-primary w-50"
        >
          {t("Utiliser")}
        </div>

</div>
        
      </div>
    </Fragment>
  );
};

export default PointsFidelitepay;
