import React, { Component } from "react";
import { notification, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {CompanyForgotPasswordService} from "../../services/api/forgotPassword/CompanyForgotPassword"
import { withTranslation } from 'react-i18next';
import { AppContext } from "../../routes/AppContext";

class CompanyForgotPassword extends Component {
  static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = { 
        
          email:"",
        
            emailError:""

        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
  
      
      handleChange(event) {
        const{name,value}=event.target;
        this.setState({
          [name]:value
        })
    
      }
      validation=()=>{
       let isvalid=true
       let email=this.state.email
       //email validation
        if(email=="") {
          isvalid=false
          this.setState({emailError:"champ obligatoire"})
        }
        else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))){
          isvalid=false
          this.setState({emailError:"email non valide"})
      
      }
    
    else 
    this.setState({emailError:""})
    
    
   
    return isvalid
    }
      handleSubmit(event){
        event.preventDefault();
    
      
        if(this.validation()){
          CompanyForgotPasswordService({
            
           email:this.state.email,
         },this.context.headers)
       
      this.props.setModalVisible(false)
         
         this.setState({ 
           email:"", 
         })}
     }


  render() {
    return (
      <div>
        <Modal
          title={<h5 className="m-1">Mot de passe oublié ?</h5>}
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
              className="m-3"
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
              />
            </i>
          }
          visible={this.props.modalVisible}
          onOk={() => this.props.setModalVisible(false)}
          onCancel={() => this.props.setModalVisible(false)}
          footer={
            <input
              type="button"
              className="btn btn-primary p-1 rounded"
              value={this.props.t("Retrouver mon mot de passe")}
              onClick={this.handleSubmit}
            />
          }
        >
          {this.props.t("Pas de panique")}
          <br />
          {this.props.t("Remplissez le formulaire ci-dessous et nous vous enverrons par email")}
          <br />
          {this.props.t("Votre email")} * :
          <br />

          <input
                        className="form-control"
                        type="text"
                        id="email"
                        name="email"
                        placeholder={this.props.t("Votre E-mail...")}
                        value={this.state.email}
                        onChange={this.handleChange}
                    
                      />
                    <p className="text-danger"><small>{this.state.emailError}</small></p>
          
          <small>
          {this.props.t("Indiquez l'email que vous nous donnez lors de votre inscription.")}
          </small>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(CompanyForgotPassword)