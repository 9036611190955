import { Drawer, Progress, Spin } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import DoughnutChart from "../DoughnutChart";
import { AppContext } from "../../routes/AppContext";
import { GetJobPostStatistics } from "../../services/api/statistics/GetJobPostStatistics";
import { useQuery } from "react-query";
import moment from "moment";
import { GetJobPostsStatistics } from "../../services/api/statistics/GetJobPostsStatistics";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
export default function JobPostDrawer(props) {
  const { t } = useTranslation();

  const { userId, headers } = useContext(AppContext);


  const componentRef = useRef(null);
  const [download, setDownload] = useState(false);
  const getScreenshot = async () => {
    await setDownload(true);
    const canvas = await html2canvas(componentRef.current, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    });
    const img = canvas.toDataURL("image/png");
    //converting px to in
    // const width = parseInt(canvas.width) / 96;
    // const height = parseInt(canvas.height) / 96;
    const doc = new jsPDF("p", "mm", "a4");
    var width = doc.internal.pageSize.getWidth()/2;
var height = doc.internal.pageSize.getHeight();
    // new jsPDF({
    //   orientation: "p",
    //   unit: "in",
    //   format: [width, height],
    // });
    doc.addImage(img, width/2, 20,width,height);
    await doc.save("Statistic.pdf");
    setDownload(false);
  };
  // useEffect(() => {
  //   if (props?.jobPost?._id) {
  //     getStats()
  //   }
  //   else {
  //     // setData(props.stats)
  //   }
  // }, [])
  const getStats = async () => {
    if (props?.jobPost?._id) {
      let res = await GetJobPostStatistics(
        { idJobPost: props?.jobPost?._id },
        headers
      );
      if (res) {
        return res.data;
      }
    } else {
      let res = await GetJobPostsStatistics({}, headers);
      if (res) {
        return res.data;
      }
    }
  };
  const {
    data: stats,
    isLoading,
    isError,
  } = useQuery(
    ["allStats", props?.jobPost?._id], // Query key
    getStats
  );
  let data = stats ? stats[0] : {};
  return (
    <div className="statistique-dashboard">
      <Drawer
        title={
          <>
          <h5 className="text-primary">
            {props?.jobPost?._id ? (
              <b>{t("Statistiques")} :{props.jobPost.title}</b>
            ) : (
              <b>{t("Statistiques Globale")}</b>
            )}
          </h5>
          <div className="cv-down" onClick={getScreenshot}>
          {t("Télécharger CV")}
        </div>
        </>
        }
        placement="right"
        onClose={props.onClose}
        open={props.open}
        getContainer={false}
        visible={props.open}
      >
        <div ref={componentRef}>
        <Spin  spinning={isLoading}>
          <h5
            style={{
              fontWeight: "600",
              fontSize: "15px",
              color: "#212830",
              marginBottom: "0",
              lineHeight: "1.4",
            }}
            className="mt-2"
          >
            {t("Taux de sélection")} :
          </h5>
          <small className="fs-sm text-secondary mb-4 ">
            {t("Pourcentage des candidats invités pour un entretien tech ou visio")}
          </small>
          <div className="d-flex justify-content-center">
            <Progress
              type="circle"
              percent={parseInt((data?.selected / data?.applications) * 100)}
              format={(percent) => `${percent}%`}
              width={55}
              strokeColor={
                parseInt((data?.selected / data?.applications) * 100) < 30
                  ? "orange"
                  : "green"
              }
            />
          </div>
          <h5
            style={{
              fontWeight: "600",
              fontSize: "15px",
              color: "#212830",
              marginBottom: "0",
              lineHeight: "1.4",
            }}
            className="mt-2"
          >
            {t("Taux de conversion")}:
          </h5>
          <small className="fs-sm text-secondary mb-4 ">
            {t("Pourcentage des candidats acceptées par rapport aux candidats préselectionnés")}
          </small>
          <div className="d-flex justify-content-center">
            <Progress
              type="circle"
              percent={parseInt((data?.accepted / data?.selected) * 100)}
              format={(percent) => `${percent}%`}
              width={55}
              strokeColor={
                parseInt((data?.accepted / data?.selected) * 100) < 30
                  ? "orange"
                  : "green"
              }
            />
          </div>
          {props?.jobPost?._id && (
            <>
              <h5
                style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#212830",
                  marginBottom: "0",
                  lineHeight: "1.4",
                }}
                className="mt-2"
              >
                {t("Le temps moyen de recrutement")}:
              </h5>
              <small className="fs-sm text-secondary mb-4 ">
                {/* durée de recrutement, temps d'embauche
            <br/> */}
                {t("temps de publication de l'offre")} :
              </small>
              {moment(data?.jobPostPublicationDate).format("L")}
            </>
          )}
          <h5
            style={{
              fontWeight: "600",
              fontSize: "15px",
              color: "#212830",
              marginBottom: "0",
              lineHeight: "1.4",
            }}
            className="mt-2"
          >
            {t("Nombre de candidatures")} : {data?.applications}
          </h5>
          <small className="fs-sm text-secondary mb-4 ">
            {t("Le nombre total de candidatures reçues peut être présenté, ainsi que leur répartition par source et leur qualité ( sexe , age , années d'expérience )")} .
            {/* région, niveau d'étude */}
          </small>
          <div
            className="text-center m-0 fw-bold mt-4 texte-statistique"
            style={{
              fontSize: "15px",
              color: "#05366e",
              lineHeight: "0.4",
            }}
          >
            {t("Statistiques par années d'expérience")}
            <DoughnutChart
              labels={[`0-1 ${t("an")}`, `1-3 ${t("ans")}`, `3-5 ${t("ans")}`, `>5 ${t("ans")}`]}
              colors={[
                "rgba(42,118,244,255)",
                "rgba(148,186,249,255)",
                "rgba(217,230,253,255)",
                "rgba(30,90,240,255)",
              ]}
              data={[
                data?.experienceLessThanYear,
                data?.experienceBetweenOneAndThreeYears,
                data?.experienceBetweenThreeAndFiveYears,
                data?.experienceMoreThanFiveYears,
              ]}
              title={""}
            />
          </div>

          <div
            className="text-center m-0 fw-bold mt-4"
            style={{
              fontSize: "15px",
              color: "#05366e",
              lineHeight: "0.4",
            }}
          >
            {t("Statistiques par age")}
            <DoughnutChart
              labels={[`<18 ${t("ans")}`, `18-25 ${t("ans")}`, `25-35 ${t("ans")}`, `>35 ${t("ans")}`]}
              colors={[
                "rgba(42,118,244,255)",
                "rgba(148,186,249,255)",
                "rgba(217,230,253,255)",
                "rgba(30,90,240,255)",
              ]}
              data={[
                data?.ageLessThan18,
                data?.ageBetween18and25,
                data?.ageBetween25and35,
                data?.ageGreaterThan35,
              ]}
              title={""}
            />
          </div>
          <div
            className="text-center m-0 fw-bold mt-4"
            style={{
              fontSize: "15px",
              color: "#05366e",
              lineHeight: "0.4",
            }}
          >
            {t("Statistiques par sexe")}
            <DoughnutChart
              labels={[t("Homme"), t("Femme")]}
              colors={["rgba(42,118,244,255)", "rgba(148,186,249,255)"]}
              data={[data?.men, data?.applications - data?.men]}
              title={""}
            />
          </div>
          <h5
            style={{
              fontWeight: "600",
              fontSize: "15px",
              color: "#212830",
              marginBottom: "0",
              lineHeight: "1.4",
            }}
            className="mt-2"
          >
            {t("statistiques des entretiens")}:
          </h5>
          {/* <small className="fs-sm text-secondary mb-4 ">
            durée totales des entretiens
          </small>
          <small className="fs-sm text-secondary mb-4 ">
            durée moyenne des entretiens
          </small> */}
          <div className="text-center m-0 fw-bold text-secondary mt-2">
            {data?.interviews} {t("entretiens planifiées")}
            <DoughnutChart
              labels={[t("confirmé"), t("non confirmée")]}
              colors={["rgba(42,118,244,255)", "rgba(148,186,249,255)"]}
              data={[
                data?.interviews,
                data?.planifiedInterviews - data?.interviews,
              ]}
              title={""}
            />

          </div>
          
          {/* <h5 style={{ fontWeight: "600",
            fontSize: "15px" ,
            color: "#212830" ,
            marginBottom: "0",
            lineHeight: "1.4",}}className="mt-2">
          statistiques des tests :

          </h5> */}
          {/* <small className="fs-sm text-secondary mb-4 ">
          nombre des tests
          </small> */}
          {/* <small className="fs-sm text-secondary mb-4 ">
          max score
          </small>
          <small className="fs-sm text-secondary mb-4 ">
           score moyen
          </small> */}
          {/* <p>Some contents...</p> */}
          </Spin>
        </div>
      </Drawer>
    </div>
  );
}
