import { Button, Col, Pagination, Row } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../routes/AppContext";
import { AddTestService } from "../../../services/api/companyTest/AddTest";
import formatTime from "../../../utils/formatTime";
import JobPostTestingContainer from "../../jobPostTesting/JobPostTestingContainer";
import JobPostTestingContainerTest from "../../jobPostTesting/JobPostTestingContainerTest";
import QuestionPreview from "./QuestionPreview";

export default function TestPreviewTest(props) {
  const { t } = useTranslation();

  const {
    questions,
    title,
    description,
    experience,
    level,
    language,
    languages,
    validity,
    globalStopwatch,
    correctionMethod,
    showResult,
  } = props?.test;
  
  const levels = [t("Débutant"),
    t("Intermédiaire"),
    t("Avancé"),
    t("Expert")]
    const langs = { "fr": t("Français"), "en": t("Anglais") }
    
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [testing, setTesting] = useState(false);
    const [loading,setLoading]=useState(false)
  const pageSize = 4; // number of questions per page

  const handleSubmit = () => {
    setLoading(true)
    props.submit();
  };
  const handlePageChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex - 1);
  };

  const testConsult = () => {
    setTesting(true);
  };

  const startIndex = currentPageIndex * pageSize;
  const endIndex = startIndex + pageSize;
  const currentQuestions = questions.slice(startIndex, endIndex);
  let totalPoints = 0;
  let duration = 0;

  for (let index = 0; index < questions.length; index++) {
    duration += Number(questions[index].time);
  }

  for (let index = 0; index < questions.length; index++) {
    totalPoints += Number(questions[index].points);
  }
  let list = currentQuestions?.map((question, i) => {
    return (
      <QuestionPreview
        index={i}
        startIndex={startIndex}
        key={i}
        question={question}
      />
    );
  });

  return (
    <>
      {testing ? (
        <div className="shadow p-3">
          {/* <JobPostTestingContainer
            method={3}
            test={props.test}
          /> */}
          <JobPostTestingContainerTest
            method={3}
            test={props.test}
          />

          <div className="d-flex justify-content-end">

            <button onClick={() => { setTesting(false) }} className="btn btn-primary mx-1" >
              {t("Fermer")}
            </button>
          </div></div>
      ) : (
        <div className="Review-Test p-5">
          <div className=" m-5" >
            <b style={{ color: "darkblue" }}> <span><i>{t("Titre du test")} :</i></span> </b><h4  > {title} </h4>
            <b style={{ color: "darkblue" }}> <span><i>{t("Description du test")} :</i></span> </b> <h6 > {description} </h6>

          </div>
          {/* <h6 className="Description" style={{ paddingTop: 25 }}>
            {title}
          </h6>
          <p className="Description">{description}</p> */}
          <div className="secondary-Setup-Question">
            <Row span={24}>
              <Col>
                <p>
                  <strong>{t("Experience")}:</strong> {levels[level]}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>{t("langue")}:</strong> {langs[languages[0]]}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>{t("Durée totale")}: </strong>
                  {formatTime(duration)}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>{t("Points Totale")}:</strong> {totalPoints}
                </p>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-center">
            <hr />
          </div>
          <div>{list}</div>
          <Pagination
            style={{ margin: 16, textAlign: "center" }}
            current={currentPageIndex + 1}
            pageSize={pageSize}
            total={questions.length}
            onChange={handlePageChange}
            block
          />

          <div className="d-flex justify-content-end">
            {/* <button className=" btn btn-primary m-2" onClick={testConsult}>
              <i className="mdi mdi-eye me-2" ></i>
              Consulter
            </button> */}
            <Button className="m-2" type="primary" onClick={testConsult}>
            <i className="mdi mdi-eye me-2" ></i>
              {t("Consulter")}
</Button>
            {props.submit && (
              <Button  className="m-2" type="primary" loading={loading} onClick={handleSubmit}>
              {t("Enregistrer")}
</Button>
              // <button type="button" className=" btn btn-primary m-2" onClick={handleSubmit}>
              //   Enregistrer
              // </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
