import React from 'react';
import ReactDOM from 'react-dom';

import "./styles/styles.scss";
import "bootstrap/scss/bootstrap.scss";
import 'react-quill/dist/quill.snow.css';

import App from '../App';

ReactDOM.hydrate( <>
    <App />
  </>, document.getElementById('root'));





