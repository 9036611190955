
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Experiences extends Component {
  render() {
    const { t } = this.props;

    let i=-1
    let list=this?.props?.experiences?.map(experience=>{
        i++
        let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]

        let startDate=new Date(experience.startDate)
        let date2 =new Date(experience.endDate)
        let endDate=experience.endDate? months[date2.getMonth()]+" "+date2.getFullYear():t("Présent")
       let dateText=months[startDate.getMonth()]+" "+startDate.getFullYear()+" - "+endDate
      return(<li key={i} className=''>
          <b className='text-primary text-capitalize' > {experience.companyName} </b><br/>
          <span className='text-capitalize' >{experience.title}</span><br/>
           <span className='text-secondary' > {dateText}</span><br/>
          <span className='text-capitalize' >{experience.description}</span>
        <br/>
        </li>
      )
    }) 
    return <div className='u-list mt-4'>
{list.length>0&&<h6><b>
<i className="mdi mdi-domain me-1 icons12"></i>
   {t("Expériences professionnelles")} </b></h6>}
<ul className=' border-start border-3 border-primary'>
{list}
</ul>
    </div>;
  }
}
export default withTranslation()(Experiences)