import { notification } from 'antd';

import axios from "../../../interceptors/axios"
 
export const CompanyForgotPasswordService = async (data,headers) => {
  try {
    axios.post('/api/forgotPassword/company',data,{headers:headers}, { withCredentials: true }
   ).then(response =>{
  notification.success({
   
    description:response.data.message,
    className: 'custom-class mt-5',
    style: {
      width: 600,
      backgroundColor:"#d4edda",
      marginTop:"5rem"
    },
  });
   }).catch((err) => {
  //notification
  notification.error({
    description:err.response.data.message==undefined?"Une erreur est survenue":err.response.data.message,
    className: 'custom-class',
    style: {
      width: 600,
      backgroundColor:"#f8d7da",
      marginTop:"5rem"
    },
  }) 

      // console.warn(error.response.data);
 });
  } catch (err) {
    // console.warn(err);



  }
};
