import React, { Component } from 'react';
import { AppContext } from '../../routes/AppContext';
import AsyncImage from '../AsyncImage';

import certificat from "../../media/image/certificates/certificat.jpeg"
import { withTranslation } from 'react-i18next';
class Certificates extends Component {
  static contextType = AppContext

  render() {
    const { t } = this.props;

    let i=-1
    let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]
    let list=this?.props?.certificates?.map(certificate=>{
        i++
        let emissionDate=new Date(certificate.emissionDate)
        let date2 =new Date(certificate.expirationDate)
        let expirationDate=certificate.expirationDate? months[date2.getMonth()]+date2.getFullYear():t("Présent")
       let dateText=months[emissionDate.getMonth()]+emissionDate.getFullYear()
      
      return(<li key={i} className=''>
          <b className='text-primary text-capitalize' >
          { console.log(certificate)}
            {certificate?.provider?<AsyncImage 
        // images/certificates from aws
        src={`${this.context.envVar.REACT_APP_CLIENT_URL}/images/certificates/${certificate?.provider?.toLowerCase()}.jpeg`}
        width="18px"
        className="m-1"
         />:<AsyncImage 
         // images/certificates from local
         src={certificat}
         width="18px"
         className="m-1"q
          />  }
         {certificate.certificateName} </b><br/>
          <span className='text-secondary' >{dateText}</span><br/>
          <span className='text-capitalize' >{certificate?.reference}</span>
        <br/>
        </li>
      )
    }) 
    return <div className='u-list mt-4'>
{list.length>0&&<h6><b>
<i className="mdi mdi-certificate me-1 icons12"></i>
   {t("Certificats")} </b></h6>}
<ul className=' border-start border-3 border-primary'>
{list}
</ul>
    </div>;
  }
}
export default withTranslation()(Certificates)