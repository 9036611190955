import axios from "../../../interceptors/axios"

export const AddResponsible = async (data,headers) => {
    try {
    let res=await  axios.post('/api/manageResponsible/add',data,
     {
      headers: headers
  }
     )
return res
} catch (err) {
    }
  }