import React from "react";
import { Route } from "react-router-dom";
import Container from "../Container";
export default function SimpleRouter({ children,component, ...rest }) {
  return (
    <Route {...rest}>
    <Container {...rest}>
        {children?children:component()}
    </Container>
    </Route>
  );
}
