import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Appsms from "./Appsms";
import CheckoutProducts2 from "./CheckoutProducts2";

const CheckoutVerificationPage = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="cart-modal">
        <div className="flex-grow ">
          <section className=" ">
            <div>
              <div>
                <div className="mb-8">
                  <div className="text-xl mx-2 ml-16" style={{ marginTop: 30 }}>
                    {t("Paiement processus")}
                  </div>
                  <br />
                  <br />
                  <div className="hidden lg:block col-span-1 flex flex-wrap space-y-12 item-center ">
                    <span
                      className=" ml-16 px-4 py-4 rounded-l-full font-light tracking-widest "
                      style={{ backgroundColor: "#005bea" }}
                    >
                      <span
                        className="h-20 w-20 p-2 rounded-full tracking-widest text-black"
                        style={{ backgroundColor: "#FFe436" }}
                      >
                        {" "}
                        01{" "}
                      </span>
                      <span className="ml-2 text-white"> {t("Informations")} </span>
                    </span>

                    <span
                      className="ml-2 px-4 py-4 rounded-l-full font-light tracking-widest"
                      style={{ backgroundColor: "#005bea" }}
                    >
                      <span
                        className="h-20 w-20 p-2 rounded-full tracking-widest text-black"
                        style={{ backgroundColor: "#FFe436" }}
                      >
                        {" "}
                        02{" "}
                      </span>
                      <span className="ml-2 text-white"> {t("Verification")} </span>
                    </span>
                    <span
                      className="ml-2 px-4 py-4 rounded-l-full font-light tracking-widest "
                      style={{ backgroundColor: "#005bea" }}
                    >
                      <span
                        className="h-20 w-20 p-2 rounded-full tracking-widest text-black"
                        style={{ backgroundColor: "#FFe436" }}
                      >
                        {" "}
                        03{" "}
                      </span>
                      <span className="ml-2 text-white"> {t("Paiement")} </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <section className="row">
              <span className="col-lg-7">
                <CheckoutProducts2 />
              </span>
              <span className="col-lg-5">
                <Appsms />
              </span>
            </section>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutVerificationPage;
