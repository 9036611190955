import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import JobPostPreview from "../components/manageJobPosts/JobPostPreview";
import { AppContext } from "../routes/AppContext";
import { GetJobPostByRef } from "../services/api/manageJobPosts/GetJobPostByRef";
import decrypt from "../utils/decrypt";
import jwt from "jsonwebtoken";

 function JobPostView(props) {
  const { role,user,headers } = useContext(AppContext);
  const [jobPost,setJobPost]=useState({})
  const [load,setLoad]=useState(true)
  const history = useHistory({});
let reference=props.match.params.reference
  useEffect(async()=>{
    try
   {   if (props?.location?.state?.jobPost) {
        setJobPost(props.location.state.jobPost)
      } else {
        let res = await GetJobPostByRef(
          { reference: props.match.params.reference },
          headers
        );
        if(res){
          let jobPostFromDB=JSON.parse(decrypt(res.data))
          setJobPost(jobPostFromDB)
          setLoad(false)
        }
      }}catch(err){
        history.push({
          pathname: "/",
        });
      }

  },[reference])
  return (
    <div className="mt-5 pt-5">
   {!load &&  <JobPostPreview
        // userId={props.userId}
        company={jobPost?.idCompany}
        jobPost={jobPost}
      />}
    </div>
  );
}
export default withRouter(JobPostView);
