import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
class SoftSkillsTwo extends Component {
    constructor(props){
        super(props)
      }
  render() {
    const { t } = this.props;
    let i=-1
    let list=this?.props?.softSkills?.map(softSkill=>{
        i++
      return( 
                <div className="cvt-comp-elem-i" key={i}>{softSkill.soft_skill_fr}</div>
      )
    })
    return (
      <>
        <hr className='cvt-w'/>
        <div className="cvt-comp-t">
            <div className="cvt-comp-t-top">
                <div className="cvt-rect">
                    <div className="cvt-rectt"></div>
                </div>
                <div className="cvt-comp-name">{t("Compétences interpersonnelles")} :</div>
            </div>
            <div className="cvt-comp-all">
            {list}

            </div>
        </div>
      </>
    )
  }
}
export default withTranslation()(SoftSkillsTwo)