import { Modal, notification, Progress, Tag } from "antd";
import moment from "moment-timezone";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Apply } from "../../services/api/application/Apply";
import MapMarkerIcon from "../icons/MapMarkerIcon";
import UserIcon from "../icons/UserIcon";
import ClockIcon from "../icons/ClockIcon";
import FileContractIcon from "../icons/FileContractIcon";
import {  ExclamationCircleOutlined,TrophyOutlined } from "@ant-design/icons";
import AsyncImage from "../AsyncImage";
import gif from "../../media/image/bad.gif"
import { AppContext } from "../../routes/AppContext";
import gif2 from "../../media/image/postulerSuccee.gif"
import { withTranslation } from "react-i18next";
class JobPostComponent extends Component {
  static contextType = AppContext

  state = { applied: false };
  getText=(html)=>{
    let divContainer= document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}

  apply = (e) => {
    e.preventDefault();
    // e.stopPropagation()
     if(!this.context.candidateAccess){
      notification.warn({
        description: <div className="text-center">
       <span> 
            {this.props.t("Vous devez renseigner votre profil et remplir votre cv pour pouvoir postuler")}.
              </span>
      </div>,
          className: "custom-class",
        placement: 'top',className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff",
          marginTop: "5rem",
        },
      });
     }
     else if (parseInt(this.props.jobPost.score * 100) < 30) {
      notification.info({
        
        description: <div className="text-center"><AsyncImage src={gif} alt="email" style={{ width: "15rem", height: "15rem" }} /><br />
        <span> 
        {this.props.t("Malheureusement, vous ne pouvez pas postuler pour ce poste. Nous vous contacterons par e-mail dès qu'une offre correspondant à votre CV sera disponible")}.
        </span>
      </div>,
          className: "custom-class",
        placement: 'top',className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff",
          marginTop: "5rem",
        },
      });
    }
    else {
      Modal.confirm({
        title: "Postuler",
        icon: <ExclamationCircleOutlined />,
        content:
        this.props.t("voulez vous postuler à l'offre") +
          this.props.jobPost.title +
          this.props.t("Si vous postulez, vous ne pouvez pas annuler votre candidature")
          ,
        okText:  this.props.t("Postuler"),
        cancelText:  this.props.t("Annuler"),
        onOk: async() => {
         let response=await Apply(
            {
              
              idJobPost: this.props.jobPost._id,
              score: parseInt(this.props.jobPost.score * 100) / 100,
              idCompany:this.props.jobPost.idCompany._id,
              titleJobpost:this.props.jobPost.title
            },
            this.context.headers
          );
      if(response)
         { notification.open({
            description: <div className="text-center">
              <span><b>{this.props.t(response.data.code)}</b></span>
              <AsyncImage src={gif2} alt="postuler" style={{ width: "15rem", height: "15rem" }} />
            </div>,
            className: 'custom-class mt-5 ',
            placement: 'top',
            duration: 10,
            style: {
              backgroundColor: "#fff",
              marginTop: "5rem"
            },
          })
          let jobPosts = this.context.jobPosts
          jobPosts[this.props.index].applied = true;
          this.context.setJobPosts(jobPosts)
          this.setState({ applied: true });
          if(response.status==201)this.context.socket.emit("sendNotification",
    {receiverId:this.props.jobPost.idCompany._id,type:0}
    )
        }},
      });
    }
  };
  render() {
    let jobPost = this.props.jobPost;
 
    let description =this.getText(jobPost?.idJobPostDescription?.description)
     
        .slice(0, 300) + " ...";
    let appli=false
   let varib= this.context.candidateApplications.find((element) => element.idJobPost[0]._id ==jobPost._id);
   varib?._id && (appli=true)
    return (
      <Link
        to={{ pathname: "/candidate/jobPost", state: { jobPost: jobPost ,appli:appli } }}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <div className=" rounded zoom-0 mt-3 p-3 shadow-sm">
          <div className=" right text-center m-2 align-middle">
            {/* Score: {parseInt(jobPost.score*100)}% */}

            <Progress
              type="circle"
              percent={parseInt(jobPost.score * 100)}
              format={(percent) => <TrophyOutlined />}
              // format={(percent) => `${percent}%`}
              width={55}
              strokeColor={
                parseInt(jobPost.score * 100) < 30 ? "orange" : "green"
              }
            />
            <br />
          </div>
          <AsyncImage
                          className="logo-m rounded-3"
                          alt={jobPost.idCompany.name}
                          src={
                            this.context.envVar.REACT_APP_CLIENT_URL +
                            "/company/images/" +
                            jobPost.idCompany.image
                          }
                        />
       
          <b className="text-primary m-1 text-capitalize"> {jobPost.title}</b>
          <br />
          <div className="text-secondary text-larger capitalize">{description}</div>



          <br />
          {jobPost?.applied==true || this.state.applied==true || appli==true ? (
            <span
              className="d-inline-block float-end"
              tabindex="0"
              data-toggle="tooltip"
              title={this.props.t("Déja postulé")}
            >
              <button
                disabled={true}
                className="btn btn-primary float-end zoom-1 "
              >
                {" "}
                {this.props.t("Déja postulé")}{" "}
              </button>
            </span>
          ) : (
            <button
              onClick={this.apply}
              className="btn btn-primary float-end zoom-1 "
            >
              {" "}
              {this.props.t("Postuler")}
              {" "}
            </button>
          )}
          <i title="Date d'expiration" className=" text-dark m-2">
            <ClockIcon width="1.3rem" />

            {moment(jobPost.jobPostExpirationDate).format("DD/MM/YYYY")}
          </i>
          <i title="Contrat" className=" text-dark m-2">
            <FileContractIcon width="1rem" />

            {(typeof jobPost.contract === 'string'?jobPost.contract:jobPost.contract.join(","))}
          </i>
          <i title="Nombre de postes" className=" text-dark m-2">
            <UserIcon width="1rem" />
            {jobPost.vacancies ? jobPost.vacancies : 1}{" "}
          </i>
          <i title="Adresse" className=" text-dark m-2">
            <MapMarkerIcon width="1rem" />
            {jobPost.location.idLocation.location}{" "}
          </i>
        </div>
      </Link>
    );
  }
}
export default withTranslation()(JobPostComponent);