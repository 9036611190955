import React, { useContext } from 'react'
import Loading from '../components/Loading'
import { AppContext } from './AppContext';

export default function Container(props) {
  const { tokensLoad } = useContext(AppContext);
    const {load,children}=props
  return (
    <>
    {load||tokensLoad?<Loading />:children}
    </>
  )
}
