import React, { Component } from "react";
import io from "socket.io-client";

import { IconButton, Badge, Input, Button } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import CallEndIcon from "@material-ui/icons/CallEnd";
import ChatIcon from "@material-ui/icons/Chat";
// import { message } from 'antd'
// import 'antd/dist/antd.css'

// import { Row } from 'reactstrap'
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import decrypt from "../../utils/decrypt";
import { Col, notification, Row } from "antd";
import { withRouter } from "react-router-dom";
import Countdown from "antd/lib/statistic/Countdown";
import moment from "moment-timezone";
import decodeToken from "../../utils/decodeToken";
import { AppContext } from "../../routes/AppContext";

const server_url =
 "/";
// process.env.REACT_APP_VIDEOCONFERENCE_URL
// "http://localhost:3002"


let connections = {};
const peerConnectionConfig = {
  iceServers: [
    // { 'urls': 'stun:stun.services.mozilla.com' },
    { urls: "stun:stun.l.google.com:19302" },
    { urls: "stun:stun1.l.google.com:19302" },
    { urls: "stun:stun2.l.google.com:19302" },
    { urls: "stun:stun3.l.google.com:19302" },
    { urls: "stun:stun4.l.google.com:19302" },
    { urls: "stun:stun01.sipphone.com" },
    { urls: "stun:stun.ekiga.net" },
    { urls: "stun:stun.fwdnet.net" },
    { urls: "stun:stun.ideasip.com" },
    { urls: "stun:stun.iptel.org" },
    { urls: "stun:stun.rixtelecom.se" },
    { urls: "stun:stun.schlund.de" },
    { urls: "stun:stunserver.org" },
    { urls: "stun:stun.softjoys.com" },
    { urls: "stun:stun.voiparound.com" },
    { urls: "stun:stun.voipbuster.com" },
    { urls: "stun:stun.voipstunt.com" },
    { urls: "stun:stun.voxgratia.org" },
    { urls: "stun:stun.xten.com" },
  ],
};
let socket = null;
let socketId = null;
let elms = 0;

class Room extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props);

    this.localVideoref = React.createRef();

    this.videoAvailable = false;
    this.audioAvailable = false;
    this.state = {
      interview: null,
      room: null,
      video: true,
      audio: true,
      screen: false,
      showModal: false,
      screenAvailable: false,
      messages: [],
      message: "",
      newmessages: 0,
      askForUsername: true,
	  auth:false,
      // username: localStorage.getItem("token") ? decodeToken(decrypt(localStorage.getItem("token"))).data.name : "" ,
      username: "",
      enableButtonTime: "01/01/2100",
      enableButton: false,
      countDown:Date.now()
    };
    connections = {};
  }
  componentDidMount() {
    try {
      let lien=""
      if(this.props.isDemo)
      { lien= window.location.href.split("demo/");}
      else
      { lien = window.location.href.split("room/")}
      
      let lien2 = lien[1];
      let interview = JSON.parse(decrypt(lien2));
      let room=interview._id
      let deadline = moment(interview.date);
      deadline.add(1, "days");
      let enableButtonTime = moment(interview.date);
      enableButtonTime.add(-5, "minutes");
      let username=""
      if(this.props.isDemo){
         username=interview.name
      }
      else{
       username =this.context.user.name?this.context.user.name: "";
}
      this.setState({
        room: room,
        interview: interview,
        enableButtonTime: enableButtonTime,
        username: username,
		auth:username.length>0
      });

      //deadline condition
      if(deadline.isBefore(moment())){
      	this.props.history.goBack()
      	notification.error({
      		message: 'Notification',
      		description:"La date d'Entretien est déjà passée, il est impossible d'accéder à cette room.",
      		className: 'custom-class',
      		style: {
      		  width: 600,
      		},
      	  });
      }
      // let room = interview._id;

     
    } catch {
      this.props.history.goBack();
      notification.error({
        message: "Notification",
        description: "Lien indisponible",
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor:"#f8d7da",
          marginTop:"5rem"
        },
      });
    }

    setInterval(() => {
      // let now=new Date()
      if (moment().isAfter(moment(this.state.enableButtonTime)))
        this.setState({ enableButton: true });
    }, 1000);
  }
  getPermissions = async () => {
    try {
      await navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => (this.videoAvailable = true))
        .catch(() => (this.videoAvailable = false));

      await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => (this.audioAvailable = true))
        .catch(() => (this.audioAvailable = false));

      if (navigator.mediaDevices.getDisplayMedia) {
        this.setState({ screenAvailable: true });
      } else {
        this.setState({ screenAvailable: false });
      }

      if (this.videoAvailable || this.audioAvailable) {
        navigator.mediaDevices
          .getUserMedia({
            video: this.videoAvailable,
            audio: this.audioAvailable,
          })
          .then((stream) => {
            window.localStream = stream;
            this.localVideoref.current.srcObject = stream;
          })
          .then((stream) => {})
          .catch((e) =>{
        }
           );
      }
    } catch (e) {
    }
  };

  getMedia = () => {
    this.setState({}, () => {
      this.getUserMedia();
      this.connectToSocketServer();
    });
  };

  getUserMedia = () => {
    if (
      (this.state.video && this.videoAvailable) ||
      (this.state.audio && this.audioAvailable)
    ) {
      navigator.mediaDevices
        .getUserMedia({ video: this.state.video, audio: this.state.audio })
        .then(this.getUserMediaSuccess)
        .then((stream) => {})
        .catch((e) => 
        {
        }
        );
    } else {
      try {
        let tracks = this.localVideoref.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      } catch (e) {}
    }
  };

  getUserMediaSuccess = (stream) => {
    try {
      window.localStream.getTracks().forEach((track) => track.stop());
    } catch (e) {
    }

    window.localStream = stream;
    this.localVideoref.current.srcObject = stream;

    for (let id in connections) {
      if (id === socketId) continue;

      connections[id].addStream(window.localStream);

      connections[id].createOffer().then((description) => {
        connections[id]
          .setLocalDescription(description)
          .then(() => {
            socket.emit(
              "signal",
              id,
              JSON.stringify({ sdp: connections[id].localDescription })
            );
          })
          .catch((e) => {
          });
      });
    }

    stream.getTracks().forEach(
      (track) =>
        (track.onended = () => {
          this.setState(
            {
              video: false,
              audio: false,
            },
            () => {
              try {
                let tracks = this.localVideoref.current.srcObject.getTracks();
                tracks.forEach((track) => track.stop());
              } catch (e) {
              }

              let blackSilence = (...args) =>
                new MediaStream([this.black(...args), this.silence()]);
              window.localStream = blackSilence();
              this.localVideoref.current.srcObject = window.localStream;

              for (let id in connections) {
                connections[id].addStream(window.localStream);

                connections[id].createOffer().then((description) => {
                  connections[id]
                    .setLocalDescription(description)
                    .then(() => {
                      socket.emit(
                        "signal",
                        id,
                        JSON.stringify({
                          sdp: connections[id].localDescription,
                        })
                      );
                    })
                    .catch((e) => {
                    });
                });
              }
            }
          );
        })
    );
  };

  closeMedia = () => {
    navigator.mediaDevices
    .getUserMedia({ video: false, audio:false })
    .then(this.getUserMediaSuccess)
    .then((stream) => {})
    .catch((e) => 
    {
    })
let tracks = this.localVideoref.current.srcObject.getTracks();
    tracks.forEach((track) => track.stop());
  };
  getDislayMedia = () => {
    if (this.state.screen) {
      if (navigator.mediaDevices.getDisplayMedia) {
        navigator.mediaDevices
          .getDisplayMedia({ video: true, audio: true })
          .then(this.getDislayMediaSuccess)
          .then((stream) => {})
          .catch((e) => {
          });
      }
    }
  };

  getDislayMediaSuccess = (stream) => {
    try {
      window.localStream.getTracks().forEach((track) => track.stop());
    } catch (e) {
    }

    window.localStream = stream;
    this.localVideoref.current.srcObject = stream;

    for (let id in connections) {
      if (id === socketId) continue;

      connections[id].addStream(window.localStream);

      connections[id].createOffer().then((description) => {
        connections[id]
          .setLocalDescription(description)
          .then(() => {
            socket.emit(
              "signal",
              id,
              JSON.stringify({ sdp: connections[id].localDescription })
            );
          })
          .catch((e) => {
          });
      });
    }

    stream.getTracks().forEach(
      (track) =>
        (track.onended = () => {
          this.setState(
            {
              screen: false,
            },
            () => {
              try {
                let tracks = this.localVideoref.current.srcObject.getTracks();
                tracks.forEach((track) => track.stop());
              } catch (e) {
              }

              let blackSilence = (...args) =>
                new MediaStream([this.black(...args), this.silence()]);
              window.localStream = blackSilence();
              this.localVideoref.current.srcObject = window.localStream;

              this.getUserMedia();
            }
          );
        })
    );
  };

  gotMessageFromServer = (fromId, message) => {
    let signal = JSON.parse(message);

    if (fromId !== socketId) {
      if (signal.sdp) {
        connections[fromId]
          .setRemoteDescription(new RTCSessionDescription(signal.sdp))
          .then(() => {
            if (signal.sdp.type === "offer") {
              connections[fromId]
                .createAnswer()
                .then((description) => {
                  connections[fromId]
                    .setLocalDescription(description)
                    .then(() => {
                      socket.emit(
                        "signal",
                        fromId,
                        JSON.stringify({
                          sdp: connections[fromId].localDescription,
                        })
                      );
                    })
                    .catch((e) => console.log(e));
                })
                .catch((e) => console.log(e));
            }
          })
          .catch((e) => console.log(e));
      }

      if (signal.ice) {
        connections[fromId]
          .addIceCandidate(new RTCIceCandidate(signal.ice))
          .catch((e) => console.log(e));
      }
    }
  };

  changeCssVideos = (main) => {
    let widthMain = main.offsetWidth;
    let minWidth = "30%";
    if ((widthMain * 30) / 100 < 300) {
      minWidth = "300px";
    }
    let minHeight = "40%";

    let height = String(100 / elms) + "%";
    let width = "";
    if (elms === 1 || elms === 2) {
      width = "100%";
      height = "100%";
    } else if (elms === 3) {
      width = "45%";
      height = "100%";
    } else if (elms === 4 || elms === 5) {
      width = "35%";
      height = "50%";
    } else {
      width = String(100 / elms) + "%";
    }

    let videos = main.querySelectorAll("video");
    for (let a = 0; a < videos.length; ++a) {
    //   videos[a].style.minWidth = minWidth;
    //   videos[a].style.minHeight = minHeight;
    //   videos[a].style.setProperty("width", width);
    //   videos[a].style.setProperty("height", height);
      //videos[a].style.setProperty("controls" )
    }

    return { minWidth, minHeight, width, height };
  };

  connectToSocketServer = () => {
    socket = io.connect(server_url, { secure: true });
    let idCompany=null
    let role=this.context.role
    if(this.props.isDemo){
      role=""
    }
    if(role=="company"){
      idCompany=this.context.user.id
    }
    else  if(role=="responsible"){
      idCompany=this.context.user.idCompany
    }

    socket.on("signal", this.gotMessageFromServer);

    socket.on("connect", () => {
      socket.emit("join-call", this.state.room,idCompany);
      // socket.emit('join-call', "123")
      socketId = socket.id;

      socket.on("chat-message", this.addMessage);

      socket.on("user-left", (id) => {
        let video = document.querySelector(`[data-socket="${id}"]`);
        if (video !== null) {
          elms--;
          video.parentNode.removeChild(video);

          let main = document.getElementById("main");
          // this.changeCssVideos(main);
        }
      });

      socket.on("user-joined", (id, clients) => {
        clients.forEach((socketListId) => {
          connections[socketListId] = new RTCPeerConnection(
            peerConnectionConfig
          );
          // Wait for their ice candidate
          connections[socketListId].onicecandidate = function (event) {
            if (event.candidate != null) {
              socket.emit(
                "signal",
                socketListId,
                JSON.stringify({ ice: event.candidate })
              );
            }
          };

          // Wait for their video stream
          connections[socketListId].onaddstream = (event) => {
            // TODO mute button, full screen button
            let searchVidep = document.querySelector(
              `[data-socket="${socketListId}"]`
            );
            if (searchVidep !== null) {
              // if i don't do this check it make an empyt square
              searchVidep.srcObject = event.stream;
            } else {
              elms = clients.length;
              let main = document.getElementById("main");
              let cssMesure = this.changeCssVideos(main);

              let video = document.createElement("video");

              let css = {
                // minWidth: cssMesure.minWidth,
                // minHeight: cssMesure.minHeight,
                // maxHeight: "100%",
                 margin: "10px",
                // borderStyle: "solid",
                // borderColor: "#bdbdbd",
                // objectFit: "fill",
              };
              for (let i in css) video.style[i] = css[i];

              video.style.setProperty("width", cssMesure.width);
              video.style.setProperty("height", cssMesure.height);
              video.setAttribute("data-socket", socketListId);
              video.srcObject = event.stream;
              video.autoplay = true;
              video.controls = true;
              video.className = "";
              video.playsinline = true;

              main.appendChild(video);
            }
          };

          // Add the local video stream
          if (window.localStream !== undefined && window.localStream !== null) {
            connections[socketListId].addStream(window.localStream);
          } else {
            let blackSilence = (...args) =>
              new MediaStream([this.black(...args), this.silence()]);
            window.localStream = blackSilence();
            connections[socketListId].addStream(window.localStream);
          }
        });

        if (id === socketId) {
          for (let id2 in connections) {
            if (id2 === socketId) continue;

            try {
              connections[id2].addStream(window.localStream);
            } catch (e) {}

            connections[id2].createOffer().then((description) => {
              connections[id2]
                .setLocalDescription(description)
                .then(() => {
                  socket.emit(
                    "signal",
                    id2,
                    JSON.stringify({ sdp: connections[id2].localDescription })
                  );
                })
                .catch((e) => console.log(e));
            });
          }
        }
      });
    });
  };

  silence = () => {
    let ctx = new AudioContext();
    let oscillator = ctx.createOscillator();
    let dst = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    ctx.resume();
    return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
  };
  black = ({ width = 640, height = 480 } = {}) => {
    let canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    canvas.getContext("2d").fillRect(0, 0, width, height);
    let stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], { enabled: false });
  };

  handleVideo = () =>
    this.setState({ video: !this.state.video }, () => this.getUserMedia());
  handleAudio = () =>
    this.setState({ audio: !this.state.audio }, () => this.getUserMedia());
  handleScreen = () =>
    this.setState({ screen: !this.state.screen }, () => this.getDislayMedia());

  handleEndCall = () => {
    try {
      let tracks = this.localVideoref.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    } catch (e) {}
    window.location.href = "/";
  };

  openChat = () => this.setState({ showModal: true, newmessages: 0 });
  closeChat = () => this.setState({ showModal: false });
  handleMessage = (e) => this.setState({ message: e.target.value });

  addMessage = (data, sender, socketIdSender) => {
    this.setState((prevState) => ({
      messages: [...prevState.messages, { sender: sender, data: data }],
    }));
    if (socketIdSender !== socketId) {
      this.setState({ newmessages: this.state.newmessages + 1 });
    }
  };

  handleUsername = (e) => this.setState({ username: e.target.value });

  sendMessage = (e) => {
	  e.preventDefault()
    socket.emit("chat-message", this.state.message, this.state.username);
    this.setState({ message: "", sender: this.state.username });
  };

  // copyUrl = () => {
  // 	let text = window.location.href
  // 	if (!navigator.clipboard) {
  // 		let textArea = document.createElement("textarea")
  // 		textArea.value = text
  // 		document.body.appendChild(textArea)
  // 		textArea.focus()
  // 		textArea.select()
  // 		try {
  // 			document.execCommand('copy')
  // 			message.success("Link copied to clipboard!")
  // 		} catch (err) {
  // 			message.error("Failed to copy")
  // 		}
  // 		document.body.removeChild(textArea)
  // 		return
  // 	}
  // 	navigator.clipboard.writeText(text).then(function () {
  // 		message.success("Link copied to clipboard!")
  // 	}, () => {
  // 		message.error("Failed to copy")
  // 	})
  // }
 componentWillUnmount(){
  navigator.mediaDevices
  .getUserMedia({ video: false, audio:false })
  .then(this.getUserMediaSuccess)
  .then((stream) => {})
  .catch((e) => 
  {
  })

}
  connect = () =>
  {
    let qte=60
    let role=this.context.user.role
    if(this.props.isDemo){
      role=""
    }
if(role=="company"||role=="responsible"){
 let videoconferenceF= this.context.myFunctionalities.find(f=>f.fType==4)
  if(videoconferenceF){qte=videoconferenceF.qte}
  else{
    qte=0
    //alert
    this.handleEndCall()
  }
}
    let countDown=Date.now()+Math.min(1000*60*60,qte*60*1000)
		this.getPermissions();
		this.setState({ askForUsername: false,countDown:countDown }, () => this.getMedia());
    const handleTabClose = event => {
      event.preventDefault();
      event.stopPropagation()
      this.setState({ video: false,audio:false,screen:false }, () =>this.closeMedia());
     this.handleEndCall()
      // return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
}
  isChrome = function () {
    let userAgent = (navigator && (navigator.userAgent || "")).toLowerCase();
    let vendor = (navigator && (navigator.vendor || "")).toLowerCase();
    let matchChrome = /google inc/.test(vendor)
      ? userAgent.match(/(?:chrome|crios)\/(\d+)/)
      : null;
    // let matchFirefox = userAgent.match(/(?:firefox|fxios)\/(\d+)/)
    // return matchChrome !== null || matchFirefox !== null
    return matchChrome !== null;
  };
  onFinish = () => {
  };
  render() {
    /*if(this.isChrome() === false){
			return (
				<div style={{background: "white", width: "30%", height: "auto", padding: "20px", minWidth: "400px",
						textAlign: "center", margin: "auto", marginTop: "50px", justifyContent: "center"}}>
					<h1>Sorry, this works only with Google Chrome</h1>
				</div>
			)
		}*/
    let qte=0
    let role=this.context.user.role
    if(this.props.isDemo){
      role=""
    }
if(role=="company"||role=="responsible"){
 let videoconferenceF= this.context.myFunctionalities.find(f=>f.fType==4)
  if(videoconferenceF){qte=videoconferenceF.qte}
  else{
    qte=0
    //alert
    this.handleEndCall()

  }
}
    return (
      // after connection
      <div className="room">
        {this.state.askForUsername === true ? (
          <div>
            <div className="text-center pb-0 mt-5 container ">
              {/* <div className="col-6 pt-5 pb-0 " 
							style={{ justifyContent: "center", textAlign: "center" }}
							>
							<video  id="my-video" ref={this.localVideoref} autoPlay  muted style={{
								borderStyle: "solid",borderColor: "#bdbdbd"}}></video>
						</div> */}

              <div
                className=" pb-5 mb-5 "
                style={{
                  background: "white",
                  width: "auto",
                  height: "auto",
                  textAlign: "center",
                  justifyContent: "center",
                  verticalAlign: "middle",
                }}
              >
                {!this.state.auth ? (
                  <div>
                    <div
                   
                    >
                     <b className="me-1" > Entrer votre nom
                    </b>
                    <Input
                    //   placeholder="nom"
                      value={this.state.username}
                      onChange={(e) => this.handleUsername(e)}
                    />
					</div>
					<br/>
                  </div>
                ) : (<>
                  <h4>Bonjour, {this.state.username}</h4>
              {(role=="company"||role=="responsible") &&<>Il vous reste  {Math.floor(qte/60)} heures et {qte%60} minutes</>}
                </>

                )}
                {this.state.interview && (<>
				{this.props.isDemo?<h5>Votre rendez-vous avec notre représentant rec inov va bientôt commencer Merci d'être venu</h5>:<h5>votre entretien commencerait dans</h5> }
                  <span className="p-0 m-0">
                    <Countdown
                      value={this.state.interview.date}
                      onFinish={this.onFinish}
                      //  format='HH:mm'
                    />
                  </span>
               </> )}

                {this.state.enableButton ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.connect}
                    style={{ margin: "20px" }}
                  >
                    Connecter
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    style={{ margin: "20px" }}
                  >
                    Connecter
                  </Button>
                )}
              </div>
            </div>

            {/* <div className="btn-down bg-primary" style={{  textAlign: "center" }}>
							<IconButton style={{ color: "#ffffff" }} onClick={this.handleVideo}>
								{(this.state.video === true) ? <VideocamIcon /> : <VideocamOffIcon />}
							</IconButton>

							<IconButton style={{ color: "#ffffff" }} onClick={this.handleEndCall}>
								<CallEndIcon />
							</IconButton>

							<IconButton style={{ color: "#ffffff" }} onClick={this.handleAudio}>
								{this.state.audio === true ? <MicIcon /> : <MicOffIcon />}
							</IconButton></div> */}
          </div>
        ) : (
          <div className="container  " >
            <div
              className="video-control"
              style={{ textAlign: "center" }}
            >
              <IconButton
                style={{ color: "#ffffff",backgroundColor:"#0b5ed7",margin:"1px" }}
                onClick={this.handleVideo}
              >
                {this.state.video === true ? (
                  <VideocamIcon />
                ) : (
                  <VideocamOffIcon />
                )}
              </IconButton>

              <IconButton
                style={{ color: "#f44336",backgroundColor:"#0b5ed7",margin:"1px" }}
                onClick={this.handleEndCall}
              >
                <CallEndIcon />
              </IconButton>

              <IconButton
                style={{ color: "#ffffff",backgroundColor:"#0b5ed7",margin:"1px" }}
                onClick={this.handleAudio}
              >
                {this.state.audio === true ? <MicIcon /> : <MicOffIcon />}
              </IconButton>

              {this.state.screenAvailable === true ? (
                <IconButton
                  style={{ color: "#ffffff",backgroundColor:"#0b5ed7",margin:"1px" }}
                  onClick={this.handleScreen}
                >
                  {this.state.screen === true ? (
                    <ScreenShareIcon />
                  ) : (
                    <StopScreenShareIcon />
                  )}
                </IconButton>
              ) : null}

              <Badge
                badgeContent={this.state.newmessages}
                max={999}
                color="secondary"
                onClick={this.openChat}
              >
                <IconButton
                  style={{ color: "#ffffff",backgroundColor:"#0b5ed7",margin:"1px" }}
                  onClick={this.openChat}
                >
                  <ChatIcon />
                </IconButton>
              </Badge>
            </div>

            <Modal
              show={this.state.showModal}
              onHide={this.closeChat}
              style={{ zIndex: "999999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Messages de la réunion en cours</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  overflow: "auto",
                  overflowY: "auto",
                  height: "400px",
                  textAlign: "left",
                }}
              >
                {this.state.messages.length > 0 ? (
                  this.state.messages.map((item, index) => (
                    <div key={index} style={{ textAlign: "left" }}>
                      <p style={{ wordBreak: "break-all" }}>
                        <b>{item.sender}</b>: {item.data}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>pas encore de message</p>
                )}
              </Modal.Body>
              <Modal.Footer className="div-send-msg">
<form onSubmit={this.sendMessage} >
             <span className="me-5 pe-3" >  <Input
                  placeholder="Message"
                  value={this.state.message}
                  onChange={(e) => this.handleMessage(e)}
                /></span> 
                <button
                  variant="contained"
					className="btn btn-primary m-1"
                >
                  Envoyer
                </button>
</form>

              </Modal.Footer>
            </Modal>
           
            <div 
			className="h-75"
			>
              {/* <div style={{ paddingTop: "20px" }}>
								<Input value={window.location.href} disable="true"></Input>
								<Button style={{backgroundColor: "#3f51b5",color: "whitesmoke",marginLeft: "20px",
									marginTop: "10px",width: "120px",fontSize: "10px"
								}} onClick={this.copyUrl}>Copy invite link</Button>
							</div> */}

              <div
                id="main"
                 className="flex-container d-flex flex-wrap"
              >
              </div>
                <video
                  className=""
                  id="localVideo"
                  ref={this.localVideoref}
                  autoPlay
                  controls
                  muted
                  style={{
                    margin: "5px",
                  }}
                ></video>
            </div>
                 <span className="" >
            <Countdown style={{height:"5rem",width:"5rem",paddingTop:"1.2rem"}} className="border border-dark border-2 rounded-circle m-1" format={"mm:ss"} title="" value={this.state.countDown} onFinish={this.handleEndCall} />
            </span>
       
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Room);
