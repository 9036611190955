import React, { useContext, useEffect, useState } from "react";
import Calendar from "../../calendar/Calendar";
import DoughnutChart from "./DoughnutChart";
import img1 from "../../../media/image/business.png";
import img2 from "../../../media/image/undraw-calendar.svg";
import AsyncImage from "../../AsyncImage";
import { GetInterviews } from "../../../services/api/interview/GetInterviews";
import moment from "moment-timezone";

import { useTranslation } from "react-i18next";
import decrypt from "../../../utils/decrypt";
import AnimatedNumber from "react-animated-number";
import { GetCandidateStatistics } from "../../../services/api/statistics/GetCandidateStatistics";
import { GetCandidateStatisticsByYear } from "../../../services/api/statistics/GetCandidateStatisticsByYear";
import ProfileComponent from "./ProfileComponent";
import VerticalBarChart from "./VerticalBarChart";
import ApplicationsTable from "./ApplicationsTable";
import InterviewsComponent from "./InterviewsComponent";
import { GetCandidateInterviews } from "../../../services/api/interview/GetCandidateInterviews";
import { Select } from "antd";

import { AppContext } from "../../../routes/AppContext";
import { useHistory } from "react-router-dom";
const { Option } = Select;

export default function CandidateDashboard(props) {
  const { t } = useTranslation();
  const { candidateAccess,headers,serverTime } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [pending, setPending] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [accepted, setAccepted] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [refused, setRefused] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [interview, setInterview] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [data, setData] = useState({
    jobPosts:0,
    applicationsN:0,
    closedJobPosts:0,});
  const [year, setYear] = useState(moment(serverTime).year());
  const history = useHistory({});

  const getInterviews = async () => {
    let res = await GetCandidateInterviews(
      {},
      headers
    );
    if(res){
    let interviews = JSON.parse(decrypt(res.data));
    setInterviews(interviews)
//this for calendar component 
    let e = interviews.map((a) => {
      return {
        title: a.idJobPost[0].title,
         image: a.idJobPost[0].idCompany[0].image,
         interview: a,
        id: a._id,
        date: moment(a.date).format(),
      };
    });
    setEvents(e);}
  };
  const getCandidateStatistics = async () => {
    let res = await GetCandidateStatistics(
      {},
      headers
    );
    if(res){
    let cData = JSON.parse(decrypt(res.data));
    setData(cData);
    setLoading(false)
    //jobposts
    // for (let i = 0; i <= cData.jobPostsN; i++) {
    //   setTimeout(() => {
    //     setJobPostsN(i);
    //   }, 500);
    // }
  }
  };
  const getCandidateStatisticsByYear = async () => {
    let res = await GetCandidateStatisticsByYear(
      { year: year },
      headers
    );
    if(res){
    let cData = JSON.parse(decrypt(res.data));
    let newPending = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let newAccepted = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let newRefused = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let newInterview = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < cData.length; i++) {
      let state = cData[i]._id.state;
      if (state == 0) {
        newPending[cData[i]._id.month - 1] = cData[i].count;
      }else if (state == 1) {
        newInterview[cData[i]._id.month - 1] =newInterview[cData[i]._id.month - 1]+ cData[i].count;
      } else if (state == 2) {
        newInterview[cData[i]._id.month - 1] =newInterview[cData[i]._id.month - 1]+ cData[i].count;
      } else if (state == 3) {
        newRefused[cData[i]._id.month - 1] = cData[i].count;
      } else if (state == 4) {
        newAccepted[cData[i]._id.month - 1] = cData[i].count;
      }
    }
    setAccepted(newAccepted)
    setInterview(newInterview)
    setRefused(newRefused)
    setPending(newPending)}
  };
  useEffect(async () => {
    //check profile & cv
    if(!candidateAccess){
      history.push('/candidate/profile')
    }
    getCandidateStatistics();
    getInterviews()
  }, []);
  useEffect(async () => {
    getCandidateStatisticsByYear();
  }, [year]);
  const y=moment(serverTime).year()
  return (
    <div>
      <div className="row">
        <div className="col-md-3 col-sm-12 p-2 ">
          <div className="m-1 rounded shadow-sm ">
            <div className="p-2">
              <ProfileComponent />
            </div>
          </div>
          <br />
          <div className="m-1 rounded shadow-sm ">
            <div className="p-2">
              <InterviewsComponent interviews={interviews} events={events} />
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 ">
          <div className="row">
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-account-multiple icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.jobPosts}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
                <br/>
                <b className="">
                {t("Offres d'emploi publiés")}
                  {" "}
                </b>
              </div>
            </div>
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-clock-outline icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.applicationsN}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
                <br/>
                <b className="">
                {t("Offres d'emploi postulés")}
                 
                </b>
              </div>
            </div>
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-pulse icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.jobPosts - data.closedJobPosts}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
<br/>
                <b className="">
                {t("Offres d'emploi en cours")}
                  
                </b>
              </div>
            </div>
            <div className=" col-md-3 col-sm-6">
              <div className="bg-white border rounded m-2 shadow-sm text-center">
                <div className="">
                  <i className="mdi mdi-party-popper icons15"></i>
                </div>
                <b className="">
                  <AnimatedNumber
                    value={data.closedJobPosts}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </b>
                <br/>
                <b className="">
                {t("Offres d'emploi fermés")}
                  
                </b>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="m-1 rounded shadow-sm">
              <div className="text-center m-0 fw-bold text-secondary">
                {" "}
                {t("Statistiques des postulation en")}
                {" "}
                <Select
                  defaultValue={year}
                  onChange={setYear}
                  style={{ width: 80 }}
                  bordered={false}
                >
                  <Option value={y}>{y}</Option>
                  <Option value={y - 1}>{y - 1}</Option>
                  <Option value={y - 2}>{y - 2}</Option>
                </Select>
              </div>
                <VerticalBarChart
                  pending={pending}
                  accepted={accepted}
                  refused={refused}
                  interview={interview}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="m-1 rounded shadow-sm h-100">
                <DoughnutChart
                  tests={data.tests}
                  interviews={data.interviews}
                />
              </div>
            </div>
            <div className="m-1 rounded shadow-sm">
              <ApplicationsTable loading={loading} applications={data.applications} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
