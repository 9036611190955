import { notification, Rate, Select, Table, Modal, Divider, Space, Input, Typography, Card, Tag } from 'antd'

import { AddSkill } from '../../services/api/features/AddSkill';
import React, { Component } from 'react'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';

import InfoIcon from '../icons/InfoIcon';
import { AppContext } from '../../routes/AppContext';
import { withTranslation } from 'react-i18next';

const { Column } = Table;

const { Option } = Select;
class Languages extends Component {
  static contextType = AppContext

  state = {
    score: 1, skill: "", key: null, update: false, error: "", name: "", firstTime: true
  }
  info = () => {
    this.setState({ firstTime: false })
    Modal.info({
      title: null,
      okText: this.props.t("ok"),
      content: (
        <div>
          <p>   {this.props.t("La précision compte et aucun travail ne peut être accompli sans communication! Nous vous remercions de bien vouloir remplir toutes les langues requises pour votre offre. (maximum 5)")}
          </p>
        </div>
      ),

      onOk() { },
    });
  };


  onNameChange = value => {
    this.setState({ name: value })
  };

  addItem = async (e) => {
    e.preventDefault();
    if (this.state.name) {
      let res = await AddSkill({ skill: this.state.name, feature: 2 }, this.context.headers)
      if (this.validation(this.state.name)) {
        let skill = {
          score: 0.2,
          language_fr: this.state.name,
          language_en: this.state.name,
          feature: 2
        }
        let languages = this.props.languages
        languages.push(skill)
        this.props.setLanguages(languages)
      }
    }
  };
  handleChange = value => {
    this.setState({ score: value });
  };
  handleSelect = (value, i) => {
    this.addSkill(value, i.key);
  }
  validation = (skill) => {
    let isvalid = true
    // let skill=this.state.skill
    let languages = this.props.languages

    if (skill == "") {
      isvalid = false
      notification.warn({
        description: this.props.t("veuillez choisir une langues"),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
    }
    else if (languages.length >= 5) {
      isvalid = false
      notification.warn({
        description: this.props.t("vous atteindre le maximum des languess possibles"),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
    }
    else {
      for (let i = 0; i < languages.length; i++) {
        const element = languages[i];
        if (element.language_fr == skill || element.language_en) {
          isvalid = false
          notification.warn({
            description: this.props.t("langues déja utilisé"),
            className: 'custom-class',
            style: {
              width: 600,
              backgroundColor: "#fff3cd",
              marginTop: "5rem"
            },
          })
        }

      }
    }

    return isvalid
  }

  addSkillFromTags = (skill) => {
    if (this.validation(skill.language_fr)) {
      let languages = this.props.languages
      languages.push(skill)
      this.props.setLanguages(languages)
    }
  }

  addSkill = (skill, key) => {
    if (this.validation(skill)) {
      let skill = this.context.languagesList[key]
      skill.score = 0.2
      let languages = this.props.languages
      languages.push(skill)
      this.props.setLanguages(languages)
    }

  }
  updateScore = (index, value) => {
    let languages = this.props.languages
    languages[index].score = value / 5
    this.props.setLanguages(languages)
  }
  render() {
    const { t,i18n } = this.props;
    const languages = { "fr": "language_fr", "en": "language_en" , "ar": "language_en"}
    const language = languages[i18n.language]

    let i = -1
    let list = this.props.languages.map(skill => {
      i++
      let code = skill.code
      let index = i
      return (
        <div key={i} className="p-2 m-3 border row rounded d-flex align-items-center " >
          <div className='col-4 text-center capitalize' >{skill[language]}</div>
          <div className='col-6 text-center' >
            <Rate allowHalf onChange={(value) => this.updateScore(index, value)} value={skill.score * 5} /></div>
          <div className='col-2 text-center ' >

            <i title={this.props.t("Supprimer")} className=" text-danger m-1" role="button"
              onClick={() => {
                Modal.confirm({
                  title: t('Supprimer compétence'),
                  icon: <ExclamationCircleOutlined />,
                  content: t('voulez vous supprimer la compétence') + " " + skill[language],
                  okText: t('Supprimer'),
                  okType: "danger",
                  cancelText: t('Annuler'),
                  onOk: () => {
                    //   this.setState(prev=>({
                    //     hardSkills:prev.hardSkills.filter(function(value, i, arr){ 
                    //       return i!==index;
                    //   })
                    //   }),
                    this.props.setLanguages(this.props.languages.filter(item => { return item[language] !== skill[language] }))
                    this.setState({ update: !this.state.update })

                    // onCancel:()=>{alert(index)}
                  }

                })
              }}
            >
              <TrashIcon fill="red" width="1.5rem" />
            </i></div>
        </div>
      )
    })

    const children = [];
    let languagesList = this.context.languagesList
    for (let i = 0; i < languagesList.length; i++) {
      children.push(<Option key={i} value={languagesList[i][language]}><div className='capitalize' >{languagesList[i][language]}</div></Option>);
    }
    return (
      <div>
        {/* <h4 className="text-primary" > languess techniques </h4> */}
        {/* <b> - Langues  </b> */}

        {this.props.suggestedLanguages.length != 0 && <><br /> <Card style={{
          maxHeight: "10rem",
          overflowY: "auto"
        }}>
          {
            this.props.suggestedLanguages.map(skill => {
              return (

                <span role="button" onClick={() => { this.addSkillFromTags(skill) }} > <Tag className='capitalize' color="success">{skill[language]}</Tag></span>



              )
            })
          }
        </Card></>}
        <div className=" m-3">

          <Select className=" w-50"
            // style={{ width: '40%' }} 
            showSearch
            placeholder={t("Rechercher pour sélectionner")} onChange={this.handleSelect}
            onSearch={this.onNameChange}
            notFoundContent={<>
              <Divider style={{ margin: '8px 0' }} />
              <Space align="center" style={{ padding: '0 8px 4px' }}>
                <Typography.Link onClick={this.addItem} style={{ whiteSpace: 'nowrap' }}>
                  <PlusOutlined /> {t("Ajouter")}
                </Typography.Link>
              </Space>
            </>}
          >
            {children}
          </Select>
          <span onClick={this.info} role="button" ><InfoIcon className="zoom1 m-1" fill="blue" width="1.4rem" /> </span>

          {/* <span  className="m-3" >
                <Rate  allowHalf onChange={this.handleChange} value={this.state.score}  defaultValue={1} />
                </span>   */}
          {/* <span className="m-3">
                <button
               onClick={this.addSkill} 
               style={{ borderRadius: "50%", width: "35px", height: "35px" }}
               className='btn btn-primary  p-0 m-2 '>
            <i className="">
            <PlusIcon fill="white" width="1.5rem" />
            </i>
                </button>
        </span> */}
        </div>
        <div>

          <div className='mx-5'>
            {list}
          </div>
        </div>

      </div>
    )
  }
}
export default withTranslation()(Languages)