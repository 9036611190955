import React, { Component } from "react";
import { Input, notification } from "antd";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { withRouter } from 'react-router';


import { AppContext } from "../../routes/AppContext";

import { withTranslation } from 'react-i18next';
class ResetPasswordCandidate extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      token:
        this.props.match.params.token1.substr(1) +
        "." +
        this.props.match.params.token2.substr(1) +
        "." +
        this.props.match.params.token3.substr(1),

      password: "",
      passwordConfirm: "",

      passwordError: "",
      passwordConfirmError: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    try {
      let decoded = jwt_decode(this.state.token);
      const exp = decoded.exp;
      let current_time = new Date(this.context.serverTime).getTime() / 1000;
      if (current_time > exp) {
        /* expired */
        //notification
        notification.error({
          description: this.props.t("lien non valide"),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#f8d7da",
            marginTop: "5rem",
          },
        });

        this.props.history.push("/signin/candidate");
      }
    } catch (error) {
      notification.error({
        description: this.props.t("lien non valide"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#f8d7da",
          marginTop: "5rem",
        },
      });

      this.props.history.push("/signin/candidate");
    }
  }
  onChange = (value) => {
    this.setState({ sector: value });
  };
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  validation = () => {
    let isvalid = true;

    let password = this.state.password;
    let passwordConfirm = this.state.passwordConfirm;

    //password validation
    if (password == "") {
      isvalid = false;
       this.setState({ passwordError: this.props.t("champ obligatoire") });
    } else if (
      !(
        password.match(/[0-9]/g) &&
        password.match(/[A-Z]/g) &&
        password.match(/[a-z]/g) &&
        password.match(/[^a-zA-Z\d]/g) &&
        password.length >= 8
      )
    ) {
      isvalid = false;
      this.setState({
        passwordError:
        this.props.t("Mot de passe faible ( * Au moins 1 caractère majuscule 'ABCD...', * Au moins 1 caractère minuscule 'abcd..', *Au moins 1 chiffre '123...' , *Au moins 1 caractère spécial'+-*/...' , *Minimum 8 caractères)."),   });
    } else this.setState({ passwordError: "" });

    //password Confirm validation
    if (passwordConfirm == "") {
      isvalid = false;
       this.setState({ passwordConfirmError: this.props.t("champ obligatoire") });
    } else if (!(password === passwordConfirm)) {
      isvalid = false;
      this.setState({
      passwordConfirmError: this.props.t("Mot de passe doit étre conforme.") ,
      });
    } else this.setState({ passwordConfirmError: "" });

    return isvalid;
  };
  handleSubmit(event) {
    event.preventDefault();

    if (this.validation()) {
      axios
        .post("/api/resetPassword/candidate", {
          
          token: this.state.token,
          password: this.state.password,
        }, {
          headers: this.context.headers
        })
        .then((response) => {
    if(response){
          localStorage.setItem("token", response.data.token);

          //notification
          notification.success({
            description: this.props.t(response.data.code),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor: "#d4edda",
              marginTop: "5rem",
            },
          });
          window.location.replace("/candidate/cv/upload");}

          //  this.props.history.push('/company/dashboard')
        })
        .catch((error) => {
          //notification
          notification.error({
            description: this.props.t(error.response.data.code),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor: "#f8d7da",
              marginTop: "5rem",
            },
          });

          // console.warn(error.response.data);
        });

      this.setState({
        password: "",
        passwordConfirm: "",
      });
    }
  }

  render() {
    return (
      <div className="ResetPassword">
        <div className="container">
          <div className=" centered-element mt-5">
            <form className=" shadow p-5 " onSubmit={this.handleSubmit}>
              <h1>{this.props.t("Changer mon mot de passe de connexion") }</h1>
              {this.props.t("Pour changer votre mot de passe, complétez les informations ci-dessous.") }
              <br />
              <br />
              <div className="row  ">
                <div className="col-2"></div>
                <div className="col-8">
                 <label>{this.props.t("Saisissez votre nouveau mot de passe") }</label>
                  <div className="form-group  ">
                    {/* <input
                      id="password"
                      className="form-control  mb-0 pr-5 pl-5 col-8"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    /> */}
                    <Input.Password
                  id="password"
                  className="  mb-0 pr-5 pl-5 col-8"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                    />
                    <span>
                      <p className="text-danger">
                        <small>{this.state.passwordError}</small>
                      </p>
                    </span>
                  </div>
                  <label>{this.props.t("Resaisissez votre nouveau mot de passe") }</label>

                  <div className=" form-group">
                    {/* <input
                      id="passwordConfirm"
                      className="form-control  mb-0 pr-5 pl-5 col-8"
                      type="password"
                      name="passwordConfirm"
                      value={this.state.passwordConfirm}
                      onChange={this.handleChange}
                    /> */}
                    <Input.Password
                      id="passwordConfirm"
                      className="  mb-0 pr-5 pl-5 col-8"
                      type="password"
                      name="passwordConfirm"
                      value={this.state.passwordConfirm}
                      onChange={this.handleChange}
                    />
                    <span>
                      <p className="text-danger">
                        <small>{this.state.passwordConfirmError}</small>
                      </p>
                    </span>
                  </div>
                  <div className="  text-center ">
                    <button
                      type="submit"
                      className="form-control rounded-pill  btn  btn-primary btn-sm text-capitalize "
                    >
                     <b>{this.props.t("Changer mot de passe") }</b>
                    </button>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            </form>
          </div>
        </div>{" "}
      </div>
    );
  }
}
export default withTranslation()(withRouter(ResetPasswordCandidate))

