import React from 'react'
import ProfilCv from '../../components/ProfilCV/ProfilCv'

export default function CandidateProfilCvView(props) {
    return (
        <>
            <ProfilCv userId={props.userId} />
        </>
    )
}
