import axios from "../../../interceptors/axios";
export const CandidateContractSign = async (data, headers) => {
  try {
    let res = await axios.post("/api/smartContract/sign/candidate", data, {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
