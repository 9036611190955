import React, { Component } from 'react'
import { Steps, Divider, Popover } from 'antd';
import { UserOutlined, SolutionOutlined, AuditOutlined, FileDoneOutlined } from '@ant-design/icons';
const { Step } = Steps;
export default class CvSteps extends Component {
    render() {
        return (
            <div style={{ direction: "ltr"}}> 
                <Steps  className="mb-5" current={this.props.current}  onChange={this.props.setCurrent} >
                
                    <Step  icon={<Popover content={"Compétences techniques"}  trigger="hover"><i className="mdi  mdi-cogs icons20"></i></Popover>} />
                    <Step  icon={<Popover content={"Compétences interprsonnelles"}  trigger="hover"><i className="mdi mdi-lightbulb icons20"></i></Popover>} />
                    <Step  icon={<Popover content={"Langues"}  trigger="hover"><i className="mdi mdi-alphabetical-variant icons20"></i></Popover>} />
                    <Step  icon={<Popover content={"Expériences professionnelles"}  trigger="hover"><i className="mdi mdi-domain icons20"></i></Popover>} />
                    <Step  icon={<Popover content={"Formations académiques"}  trigger="hover"><i className="mdi mdi-school icons20"></i></Popover>} />
                    <Step  icon={<Popover content={"Certificats"}  trigger="hover"><i className="mdi mdi-certificate icons20"></i></Popover>} />
                </Steps> 
            </div>
        )
    }
}
