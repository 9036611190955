import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import QuestionComponent from './QuestionComponent';
import gif from "../../media/image/reponsevide.gif";
import gif2 from "../../media/image/tempsexpiré.gif";
import AsyncImage from '../AsyncImage';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, notification } from "antd";
import NavigatorNotification from '../../utils/navigatorNotification';
import { AppContext } from '../../routes/AppContext';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTranslation } from "react-i18next";
import Counter from './Counter';
import { SaveTestResult } from "../../services/api/companyTest/SaveTestResult";
import { CommencerTest } from '../../services/api/companyTest/CommencerTest';
export default function TestQuestions(props) {
  const { envVar } = useContext(AppContext);
  const { t } = useTranslation();
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const {headers,role } = useContext(AppContext);
  const [lastRenderTime, setLastRenderTime] = useState(0);
  const [answer, setAnswer] = useState(props.answers[currentQuestion]??props.questions[currentQuestion].type==2?{answer:"",idQuestion:props.questions[currentQuestion]._id}:[]);

  // const answer = useRef(props.answers[currentQuestion]??props.questions[currentQuestion].type==2?{answer:"",idQuestion:props.questions[currentQuestion]._id}:[])
  // const testingTime=useRef(0)
  const timeLeft = useRef(0)
  const timeLeftPerQuestion = useRef([0])

  useEffect(() => {
    if(role==="candidate"){
      CommencerTest(
        {
          idCandidateTest: props.idCandidateTest
        },
        headers
      );
    }
  }, [])
  // const screenTest=()=>{
  //   if(window.innerWidth != window.outerWidth ||window.innerHeight != window.outerHeight ){
  //     // alert("end test");
  //   window.location.replace('/');

  //   }
  // }

  // const setAnswer = (val) => {
  //   console.log(val);
  //   answer.current = val
  // }
  const convertTime = (val) => {
    let m = Math.floor(val / 60)
    let s = val % 60
    return m + ":" + s
  }
  //  openFullscreen
  const openFullScreen = () => {
    let elem = document.getElementById("test");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  let downloadTimer = useRef()
  const previousQuestion=()=>{
    setAnswers()
    const previousQuestion=currentQuestion-1
    // setAnswer([])
    setAnswer(props.answers[previousQuestion])
    setCurrentQuestion(previousQuestion)

  }
  const nextQuestion = () => {
    if (answer.length || (answer?.answer?.length)) { next() }
    else if(!props.test.globalStopwatch) {
      Modal.confirm({
        zIndex: 2147483647,
        title: "Aucune reponse selectionner",
        icon: <ExclamationCircleOutlined />,
        content: <div className='' >
          {t("Vous n'avez sélectionné aucune réponse et si vous décidez de continuer, tu peut pas revenir a cette question!")}.
          <AsyncImage src={gif} alt="rocket" titre="rocket" className="mg-fluid w-100" />
        </div>,
        okText: t("Suivant"),
        okType: "danger",
        cancelText: t("Annuler"),
        onOk: () => {
          next()
        },
        // onCancel:()=>{alert(index)}
      });
    }else{
      next()
    }
  }
  const setAnswers=()=>{
    let answers=props.answers
    answers[currentQuestion]=answer
    props.setAnswers(answers)
  }
  const next = () => {
    // clearInterval(downloadTimer.current);
    setAnswers()
    // if(props.questions.length>8){
    //   if (currentQuestion > (props.questions.length*0.5)) {
    //   props.setQuestEnd(true)
    //   }
    // }else if(props.questions.length>5){
    //   if (currentQuestion > (props.questions.length*0.35)) {
    //     props.setQuestEnd(true)
    //     }
    // }else if(props.questions.length>2){
    //   if (currentQuestion > (props.questions.length*0.1)) {
    //     props.setQuestEnd(true)
    //     }
    // }else{
    //   props.setQuestEnd(true)
    // }

    if (currentQuestion < props.questions.length - 1) {
      const nextQuestion=currentQuestion+1
      setAnswer(props.answers[nextQuestion]?props.answers[nextQuestion]:props.questions[nextQuestion].type==2?{answer:"",idQuestion:props.questions[nextQuestion]._id}:[])
    // setAnswer([])
      setCurrentQuestion(nextQuestion)
    }
    else {
      props.finishTest(timeLeft.current,timeLeftPerQuestion.current)

    }
  }
  const countTimeWithLevel = (level) => {
    let count = 60
const questionLevel=level??props.questions[currentQuestion].level
    switch (questionLevel) {
      case 0: count = parseInt(envVar.REACT_APP_QUESTION_TIME_0);
        break;
      case 1: count = parseInt(envVar.REACT_APP_QUESTION_TIME_1);
        break;
      case 2: count = parseInt(envVar.REACT_APP_QUESTION_TIME_2);
        break;
      case 3: count = parseInt(envVar.REACT_APP_QUESTION_TIME_3);
        break;
      default:
        break;
    }
    return count
  }

  useMemo(() => {


  }, [currentQuestion]);
  const handleUpdate = (time) => {
    if (time == 0) {
      if (props.test.globalStopwatch) {
        props.finishTest(timeLeft.current,timeLeftPerQuestion.current)
      } else {
        next()
        notification.open({
          duration: 1.5,
          message: t("Temps expiré"),
          description: <div className="text-center">
           {t("Vous avez dépassé le temps imparti pour cette question")}<br />
            <AsyncImage className="w-100" src={gif2} alt="email" />
          </div>,
          className: 'custom-class mt-0 ',
          placement: 'top',
          style: {

            backgroundColor: "#fff",
            marginTop: "5rem"
          },
        })

      }
    }
    timeLeft.current++
    timeLeftPerQuestion.current[currentQuestion]=timeLeftPerQuestion.current[currentQuestion]?timeLeftPerQuestion.current[currentQuestion]+1:1

  }
  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }
  useEffect(() => {
    // openFullScreen()
    //   window.addEventListener("fullscreenchange",  e => {
    // openFullScreen()
    //   })


    //blocage clavier souris 
    if (!props.isCompany) {
      window.addEventListener('keydown', e => {
        let blackList = ["Control", "Alt", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12"]
        if (blackList.includes(e.key)) {
          e.stopPropagation()
          e.stopImmediatePropagation()
          e.preventDefault();
          return false
        }
        else return true
        // openFullScreen()
      });
      window.addEventListener('contextmenu', event => event.preventDefault());
      let cheatAttempts = 0
      window.addEventListener('blur', (event) => {
        cheatAttempts += 1
        let notif = t("nous avons detecter que vous avez quitter l'onglet de test")
                if (cheatAttempts == 1) {
          notif = t("Attention vous avez déjà consommé une tentative, vous n'en avez plus que 2 avant la clôture automatique du test.")
        }
        else if (cheatAttempts == 2) {
          notif = t("Attention vous avez déjà consommé 2 tentatives, vous n'en avez plus que 1 avant la clôture automatique du test.")
        }
        else {
          notif = t("Désolé, vous avez épuisé vos 3 tentatives dans le test, votre session sera fermée automatiquement.")
        }
        notification.warn({
          description: notif,
          duration: 30,
          className: 'custom-class',
          style: {
            width: 600,
            backgroundColor: "#fff3cd",
            marginTop: "5rem"
          },
        })
        // NavigatorNotification(notif)
        if (cheatAttempts >= 3) {
          //finish test
          // clearInterval(downloadTimer.current);
          setAnswers()
          props.finishTest(timeLeft.current,timeLeftPerQuestion.current)


        }
      })
    }
  }, []);


  
  useEffect(() => {
    return () => {

    }
  }, [])

  let i = -1
  const list = 
  // useMemo(() => 
  props?.questions.map(q => {
    i++
    return (<>
      <QuestionComponent key={i} question={q} answer={answer} setAnswer={setAnswer} />
    </>
    )
  })
  // , [])

  const count = props.questions[currentQuestion]?.time ? props.questions[currentQuestion]?.time : countTimeWithLevel()
  const questions = props.questions;
  let totalDuration = 0;
  for (let i = 0; i < questions?.length; i++) {
    const question = questions[i];
    totalDuration += question?.time?Number(question.time):countTimeWithLevel(question.level);
  }

  return (
    <div className=' ' >
      <Modal
        title={null}

        style={{ minWidth: "85vw", 
        minHeight: "87vh",
        // marginTop:"10vh"
       }}
        centered
        closable={false}
        visible={true}
        onOk={() => { }}
        onCancel={() => { }}
        footer={null}
      >
        <div
          style={{ minHeight: "77vh" }}

          id="test" className=' w-100 h-100 p-5' >

          <div>

            <h6 className="">{currentQuestion+1}/{props?.questions.length}</h6>
            <br/>
            <div className="timer-wrapper">
              {props.test.globalStopwatch ? <CountdownCircleTimer
                isPlaying
                duration={totalDuration}
                size={120}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[totalDuration, Math.ceil(totalDuration / 5), Math.ceil(totalDuration / 10), 0]}
                strokeLinecap='square'
                //  onComplete={handleComplete}
                onUpdate={handleUpdate}
              >
                {({ remainingTime }) => (
                  <div className="timer">
                    <div className="text">{t("Reste")}</div>
                    <div className="value">{convertTime(remainingTime)}</div>
                  </div>
                )}
              </CountdownCircleTimer> : <CountdownCircleTimer
                isPlaying
                duration={count}
                //  colors={["#87d068", "#f58f0a", "#f50a0a"]}
                size={120}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[count, Math.ceil(count / 5), Math.ceil(count / 10), 0]}
                strokeLinecap='square'
                //  onComplete={handleComplete}
                onUpdate={handleUpdate}
                key={currentQuestion}
              >
                {({ remainingTime }) => (
                  <div className="timer">
                    <div className="text">{t("Reste")}</div>
                    <div className="value">{convertTime(remainingTime)}</div>
                  </div>
                )}
              </CountdownCircleTimer>}


            </div>


          </div>
          <div 
          className=''
          // className='my-3 align-middle' 
          >
            {/* <br /> */}
            {list && list[currentQuestion]}
          </div>
          <div className='d-flex justify-content-end' >
          {(props.test.globalStopwatch&&currentQuestion>0)&&<button onClick={() => { previousQuestion() }} className='btn btn-primary m-1' >{t("Précedent")}</button>}
          <button onClick={() => { nextQuestion() }} className='btn btn-primary m-1' >{t("Suivant")}</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
