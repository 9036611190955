import React from "react";
import { AppContext } from "../../../routes/AppContext";
import { withTranslation } from "react-i18next";

class DetailsCookies extends React.Component {
  static contextType = AppContext;

  render() {
    const {t,i18n}=this.props
    const language=i18n.language 
    const contents={"fr":"content","en":"content_en","ar":"content_ar"}
    const content=contents[language]
    return (
      <React.Fragment>
        <div id="main-content" className="mt-1">
          <article id="post-4745" className=" status-publish DetailsCookies ">
            <div className="section">
              <div className="home-center">
                <div className="et_builder_inner_content et_pb_gutters3">
                  <div
                    className="section_first"
                    data-fix-page-container="on"
                    // style={{ paddingTop: "133px" }}
                  >
                    <div className="et_pb_row et_pb_row_0">
                      <div className="et-last-child">
                        <div className="bg_layout_light">
                          <div className="mt-5">
                            <h1 className="text-primary text-center">
                              <strong>{t("POLITIQUE DE CONFIDENTIALITÉ")}</strong>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-secondary  mx-5">
                    <div className="et_pb_row et_pb_row_1">
                      <div className="last-child">
                        <div className="bg_layout_light">
                          <div className="et_pb_text_inner">
                {this.context.content.length>0&&          <div  dangerouslySetInnerHTML={{
              __html:this?.context?.content[0][content],
            }} ></div>}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(DetailsCookies);
