import React, { useContext, useMemo, useState } from 'react';
import { notification, Select } from 'antd';

import CheckIcon from '../../icons/CheckIcon';
import { GetResponsible } from '../../../services/api/manageResponsible/GetResponsible';
import { TagResponsible } from '../../../services/api/manageJobPosts/TagResponsible';
import decrypt from '../../../utils/decrypt';
import { AppContext } from '../../../routes/AppContext';

import { JobPostContext } from '../../dashboard/jobPostDashboard/JobPostContext';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
export default function TagResponsibleForm(props) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null)
  const [changed, setChanged] = useState(false)
  const [idResponsible, setIdResponsible] = useState(null)
  const [responsibleEmail, setResponsibleEmail] = useState("")
  const {companyName,headers} = useContext(AppContext);
  const {jobPost,setJobPost} = useContext(JobPostContext);

  const getResponsible= async () => {
    //get responsible
    let response = await GetResponsible({}, headers)
    if(response){
    let responsibleList = JSON.parse(decrypt(response.data))
    for (let i = 0; i < responsibleList.length; i++) {
      if (responsibleList[i]._id == props.idResponsible) {
        setSelected(responsibleList[i].name)
      }
    }
  return responsibleList
  }
  }
  const handleSelect = (value, i) => {
    // this.setState({ skill:value,key:i.key });
    //    alert(value)
    setChanged(true)
    if(value)
  {  
    setIdResponsible(responsible[i.key]._id)
    setSelected(responsible[i.key].name)
    setResponsibleEmail(responsible[i.key].email)
  }
  else{    setIdResponsible(null)
    setSelected(null)}
  }
  
  const handleSubmit = async () => {
    if (changed) {
      let response = await TagResponsible({
        
        idResponsible: idResponsible,
        idJobPost: props.idJobPost,
        reference:jobPost.reference,
        companyName:companyName,
        responsibleName:selected,
        jobPostTitle:props.jobPostTitle,
        responsibleEmail:responsibleEmail
      }, headers)
    if(response){
      //setIdResponsible in context
      let jp=jobPost
      jp.idResponsible=idResponsible
      setJobPost(jp)
      
      setIdResponsible(null)
      setChanged(false)

      notification.success({

        description: t(response.data.code),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem"
        },
      });
      ;}
    }
  }
  const { data, isLoading, isError } = useQuery(
    ['responsible'], // Query key
    getResponsible
  );
  const responsible=isLoading?[]:data
  const children = [<Option key={"n"} value={null}>{companyName}</Option>];
  for (let i = 0; i < responsible.length; i++) {
    children.push(<Option key={i} value={responsible[i].name}>{responsible[i].name}</Option>);
  }
  return <div className='my-2' >
    <div>
      <label>{t("Choisir un responsable")}</label>
      <br />
      <Select className="w-50"
      loading={isLoading}
        // style={{ width: '40%' }} 
        showSearch
        placeholder={t("Rechercher pour sélectionner")} value={selected} onChange={handleSelect}>
        {children}
      </Select>
      {changed ? <span onClick={handleSubmit} className='ms-2 btn btn-success rounded p-1' >
        <b className="mobile">{t("Modifier")}</b>
      </span> :
        <span className='ms-2 btn btn-secondary rounded p-1' >
          <b className="mobile">{t("Modifier")}</b>
        </span>
      }
      <br />
      {/* <p className="text-danger"><small>{locationError}</small></p> */}
    </div></div>;
}
