import React, { Component } from 'react';
import MapMarkerIcon from "../icons/MapMarkerIcon"
import EnvelopeIcon from "../icons/EnvelopeIcon"
import PhoneIcon from "../icons/PhoneIcon"
import { withTranslation } from 'react-i18next';
class Contact extends Component {
  render() {
    const { t } = this.props;
    
      let email=this.props.candidate.email
      let phone=this.props.candidate?.phone?.toString()??""
      let hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (i > 2 && i< email.indexOf("@") ) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  let hiddenPhone = "";

  for (let i = 0; i < phone.length; i++) {
    if (i > 4) {
      hiddenPhone += "*";
    } else {
      hiddenPhone += phone[i];
    }
  }
    return <div>
<h6><b> {t("Contact")} </b></h6>
<i  title="Adresse"  className=" m-3"   >  
<MapMarkerIcon width="0.8rem"/>
</i>{this.props.candidate.address}

<br/>
<i title="Email" className=" m-3"> 
<EnvelopeIcon width="0.8rem"/>

</i>
{this.props.decryptedData?email:hiddenEmail}
{/* {email} */}
<br/>
<i title="Télephone" className=" m-3"> 
<PhoneIcon width="0.8rem"/>
</i>+
{this.props.decryptedData?phone:hiddenPhone}
{/* {phone} */}
    </div>;
  }
}
export default withTranslation()(Contact)