import { Modal, notification } from 'antd';
import React, { useContext, useState } from 'react'
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { companyPackage } from '../../services/api/pricing/companyPackage';

import { AppContext } from '../../routes/AppContext';
import { useTranslation } from 'react-i18next';


export default function ContactUsModal(props) {
  const { t } = useTranslation();

  const [name,setName]=useState("")
  const [phone,setPhone]=useState("") 
   const [nameError,setNameError]=useState("")
  const [phoneError,setPhoneError]=useState("")
  const {companyName,headers} = useContext(AppContext);

  const validation = () => {
    let isvalid = true;

    //name validation
    if (name == "") {
      isvalid = false;
      setNameError(t("champ obligatoire"));
    } else setNameError("");


    //phone validation
    if (phone == undefined) {
      isvalid = false;
      setPhoneError(t("champ obligatoire"));
    } else if (!isValidPhoneNumber(phone)) {
      setPhoneError(t("Numéro non valide"));
      isvalid = false;
    } else setPhoneError("");

    return isvalid;
  };
  const handleSubmit=async()=>{
    if(validation()){
      let res=await companyPackage({name:name,phone:phone,companyName:companyName}, headers)
             //notification
             notification.success({
              description: t("demande envoyé"),
              className: 'custom-class',
              style: {
                width: 600,
                backgroundColor:"#d1ecf1",
                marginTop:"5rem"
              },
            });
      props.setModalVisible(false)
    }
  }
  return (
    <div>
         <Modal
        title={<h5 className="m-1">{t("Contactez nous")}</h5>}
        className=""
        centered
        // closeIcon={
        //    <i className="mt-5 ">
        //      <CloseOutlined className="m-3" />
        //    </i>
        // }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={
          <>
            {/* <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer "
                onClick={() => {
                  props.history.push("/company/manageJobPosts");
                }}
              /> */}
            <input
                type="button"
                className=" btn btn-primary p-1 rounded m-1 "
                value="Envoyer"
                onClick={handleSubmit}
              />
          </>
        }
      >
       <label className=" " htmlFor="name">
                    <span className="">
                      {t("Nom")}  <b style={{    color: "blue"}}>* </b>
                    </span>{" "}
                    <br />
                    <input
                      className="border form-control "
                      id="name"
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e)=>{setName(e.target.value)}}
                    />
                  </label>
                  <p className="text-danger">
                    <small>{nameError}</small>
                  </p>

                    <label className="  " htmlFor="tel">
                      <span className=""> {t("Téléphone")} <b style={{    color: "blue"}}>* </b> </span>
                      <br />

                      <PhoneInput
                        defaultCountry="TN"
                        numberInputProps={{ className: "form-control" }}
                        placeholder={t("Entrez le numéro de téléphone")}
                        value={phone}
                        onChange={setPhone}
                      />
                      <p className="text-danger">
                        <small>{phoneError}</small>
                      </p>
                    </label>
      </Modal>
    </div>
  )
}
