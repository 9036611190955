import React, { useContext, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { Modal,Select } from "antd";
import { SettingFilled } from "@ant-design/icons/lib/icons";
import { useTranslation } from "react-i18next";
import { UpdateCompanyEmailLanguage } from '../../services/api/companyEmail/UpdateCompanyEmailLanguage'
const { Option } = Select;
export default function EmailsLanguageSelector() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { companyName, user, setUser,headers } = useContext(AppContext);
  const [emailsLanguage, setEmailsLanguage] = useState(user.emailsLanguage);
  const changeLanguage = (lng) => {
    // let newUser = user;
    // newUser.emailsLanguage = lng;
    setEmailsLanguage(lng);
    // setUser(newUser);
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async() => {
    setConfirmLoading(true);
    let newUser = user;
    newUser.emailsLanguage = emailsLanguage;
    setUser(newUser);
    await UpdateCompanyEmailLanguage({
        id: user._id,
        emaillanguage:emailsLanguage},headers)
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 900);
  };
  return (
    <div>
      <div className="d-flex justify-content-end" >
      <SettingFilled
        style={{
          color: "#002fff",
          fontSize: "25px",
          marginRight: 30,
          marginTop: -5,
        }}
        onClick={showModal}
      /></div>
      <Modal
        title={t("Langue des emails à envoyer")}
        visible={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={700}
      >
        <div className="m-2">
          
          <br />
          <Select
            className="w-50"
            showSearch
            value={emailsLanguage}
            placeholder={t("Rechercher pour sélectionner")}
            onChange={changeLanguage}
          >
            <Option value="fr">🇫🇷 {t("Francais")}</Option>
            <Option value="en">🇬🇧 {t("Anglais")}</Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
}
