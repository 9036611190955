import React, { Component, useContext, useEffect, useState } from "react";
import axios from "axios";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
import { Input, Modal, notification, Popover, Switch, Table ,Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { withRouter } from "react-router";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import JobPostPreview from "./JobPostPreview";
import { CloseOutlined, ApiOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import { DeleteJobPost } from "../../services/api/manageJobPosts/DeleteJobPost";
import { GetJobPosts } from "../../services/api/manageJobPosts/GetJobPosts";
import TrashIcon from "../icons/TrashIcon";
import EyeIcon from "../icons/EyeIcon";
import EditIcon from "../icons/EditIcon";
import UsersIcon from "../icons/UsersIcon";
import UserClockIcon from "../icons/UserClockIcon";
import decrypt from "../../utils/decrypt";
import MatchingIcon from "../icons/MatchingIcon";
import XMarkIcon from "../icons/XMarkIcon";
import { CloseJobPost } from "../../services/api/manageJobPosts/CloseJobPost";
import { SetPublished } from "../../services/api/manageJobPosts/SetPublished";
import NoticeForm from "../noticeForm/NoticeForm";
import CloneIcon from "../icons/CloneIcon";
import { AppContext } from "../../routes/AppContext";
import { GetJobPostByRef } from "../../services/api/manageJobPosts/GetJobPostByRef";
import CompanyJobPostView from "../../views/CompanyJobPostView";
import { useTranslation } from "react-i18next";


const { Column } = Table;
const ManageJobPosts = (props) => {
  const context = useContext(AppContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [noticeModalVisible, setNoticeModalVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [jobPost, setJobPost] = useState({});
  const [jobPostLoad, setJobPostLoad] = useState(false);
  const [jobPosts, setJobPosts] = useState([]);
  const [filteredJobPosts, setFilteredJobPosts] = useState([]);
  const [update, setUpdate] = useState(false);
  const [filterInput, setFilterInput] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let jobPosts = await props.jobPosts;
      setJobPosts(jobPosts);
      setFilteredJobPosts(jobPosts);
      setLoading(false);
      if (!jobPosts.length) {
        handleVisibleChange(true);
      }
    };
    fetchData();
  }, [props.jobPosts]);

  const getJobPost= async(reference)=>{
    setJobPostLoad(true)
  let res = await GetJobPostByRef(
    { reference},
    context.headers
  );
  if(res){
    let jobPostFromDB=JSON.parse(decrypt(res.data))
    setJobPost(jobPostFromDB)
    setJobPostLoad(false)
    return jobPostFromDB
  }
}

// const setJobPostLoad =  (jobPostLoad) =>{
//   setState({ jobPostLoad,loading:jobPostLoad });
// };

 const   dateFromObjectId =  (objectId) =>{
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const  handleVisibleChange = (popoverVisible) => {
  setPopoverVisible(popoverVisible)
  };
  const handleFilterInputChange = (e) => {
    const currValue = e.target.value;
    setFilterInput(currValue);
    let filteredJP=props.jobPosts.filter((jobPost) => (
      jobPost.title.toLowerCase().includes(currValue.toLowerCase())
    ))
    setFilteredJobPosts(filteredJP);
  };
  const Close = async (jobPost) => {
    let id = jobPost._id;
    let res = await CloseJobPost(
      {
        jobPostTitle: jobPost.title,
        companyName: context.companyName,
        idJobPost: id,
      },
      context.headers
    );

    if (res) {
      notification.success({
        description: res.data.message,
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem",
        },
      });

      const updatedJobPosts = jobPosts.map((j) => {
        if (j._id === id) {
          return { ...j, status: "Archivé" };
        }
        return j;
      });

      setJobPosts(updatedJobPosts);
      setFilteredJobPosts(updatedJobPosts);
      setNoticeModalVisible(true);
    }
  };

  const setPublished = async (id, published) => {
    let res = await SetPublished(
      {
        idJobPost: id,
        published: published,
      },
      context.headers
    );

    if (res) {
      notification.success({
        description: res.data.message,
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem",
        },
      });

      let status = published ? "Publié" : "En attente";

      const updatedJobPosts = jobPosts.map((j) => {
        if (j._id === id) {
          return { ...j, status: status, published: published };
        }
        return j;
      });

      setJobPosts(updatedJobPosts);
      setFilteredJobPosts(updatedJobPosts);
    }
  };
    const confirmAdmin=context.role=="responsible"?true:(context.user.confirmAdmin)
    return (
      <div className="ManageJobPosts">
        <h3 className="titrePage"> {t("Liste des offres")}</h3>
        <Popover
          content={<span >{t("Vous pouvez créer votre description de poste en appuyant sur ce bouton 🖱.")}</span>}
          title={t("Ajouter des offres")}
          placement="right"
          visible={popoverVisible}
          onVisibleChange={handleVisibleChange}
        >
          <div
            onClick={() => props.history.push(props.idResponsible ? "/responsible/addJobPost" : "/company/addJobPost")}
            className="btn btn-primary rounded-pill m-2"
          >
            <PlusOutlined
              className=" border  rounded-circle "
              style={{ fontSize: "1.4rem" }}
            />
            <b className="m-3">{t("Ajouter Offre")}</b>
          </div>

        </Popover>

        <div className="m-3 md-form col-6">
          <Input
            className=" form-control "
            placeholder={t("Rechercher un offre..")}
            value={filterInput}
            onChange={handleFilterInputChange}
          />
        </div>
        <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table
        loading={loading}
rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          pagination={true} dataSource={filteredJobPosts}>
    
          <Column
            align="center"
            sorter={(a, b) => a.title.localeCompare(b.title)}
            // responsive={["lg"]}
            title={t("Titre")}
            dataIndex="title"
            key="title"
            render={(text, jobPost, index) => {
              return (<>
                <span role="button" onClick={async() => {
                 await setJobPost(jobPost)
                  setModalVisible(true);
                }} >{jobPost.title} </span>
              </>)
            }}

          />
      
           <Column
            align="center"
            sorter={(a, b) => {
              const dateA = dateFromObjectId(a._id).getTime(); // Convertir en timestamp
              const dateB = dateFromObjectId(b._id).getTime(); // Convertir en timestamp
              return dateA - dateB;
            }}
            responsive={["lg"]}
            title={t("Date")}
            dataIndex="_id"
            key="_id"
            render={(text, jobPost, index) => {
              let date=dateFromObjectId(jobPost._id) 
              return <>{moment(date).format("DD/MM/YYYY")}</>;
            }}

          />
          <Column
            align="center"
            onFilter= {(value, record) => record.status==value}
            filters= {[
              {
                text: t('Archivé'),
                value: 'Archivé',
              },
              {
                text: t('Publié'),
                value: 'Publié',
              },
              {
                text: t('Expiré'),
                value: 'Expiré',
              },
              {
                text: t('En attente'),
                value: 'En attente',
              }, 
              {
                text: t('Programmé'),
                value: 'Programmé',
              }, 
              
            ]}
            sorter={(a, b) => a.status.localeCompare(b.status)}
            responsive={["lg"]}
            title={t("Status")}
            dataIndex="status"
            key="status"
            render={(text, jobPost, index) => {
              return (
                <div className="">
                  <Switch
                    checkedChildren={t(`${jobPost.status}`)} unCheckedChildren={t(`${jobPost.status}`)}
                    className={jobPost?.status == 'Publié' ? "me-1 btn-success" : "me-1 btn-danger"}
                    // size="small"
                    checked={jobPost.status == 'Publié'}
                    onChange={() => {
                      if(!confirmAdmin){
                        //notif
                        notification.warn({
                          description: t("Vous devez attendre la confirmation de l'admin "),
                          className: 'custom-class',
                          style: {
                            width: 600,
                            backgroundColor: "#fff3cd",
                            marginTop: "5rem"
                          },
                        })
                      }
                     else if (jobPost.status == 'Publié' || jobPost.status == 'En attente') {
                        let status = jobPost.status == 'En attente'
                        Modal.confirm({
                          title: status ? t("Publier offre") : t("Mettre en attente offre"),
                          icon: <ExclamationCircleOutlined />,
                          content: <>
                            {status?t("Voulez vous publier l'offre"):t("Voulez vous mettre en attente l'offre")} {jobPost.title}!
                          </>,
                          okText: status ? t("Publier") : t("Mettre en attente"),
                          okType: status ? "primary" : "danger",
                          cancelText: "Annuler",
                          onOk: () => {
                            setPublished(jobPost._id, status);
                          },
                          // onCancel:()=>{alert(index)}
                        });
                      }
                    }}
                  />
                </div>
              )
            }}
          />
             <Column
            align="center"
            title=""
            dataIndex="Actions"
            key="Actions"
            render={(text, jobPost, index) => {
              return (
                  <Link
                    to={{
                      pathname: props.idResponsible ? "/responsible/manageJobPost/"+jobPost.reference : "/company/manageJobPost/"+jobPost.reference,
                      state: { jobPost ,text },
                    }}
                  ><button className="btn btn-primary rounded-pill " >
                  {t("Gestion des candidatures")}
                 </button>
                  </Link>             
                  )}} />       
          <Column
            align="center"
            title={t("Actions")}
            dataIndex="Actions"
            key="Actions"
            render={(text, jobPost, index) => {
              return (
                <div className="">

                  {/* <Link
                    to={{
                      pathname: props.idResponsible ? "/responsible/manageJobPost/"+jobPost.reference : "/company/manageJobPost/"+jobPost.reference,
                      state: { jobPost ,text },
                    }}
                  >
                    <i
                      title="Gestion Des Candidatures"
                      className=" text-primary m-2"
                      role="button"
                    >
                      <UsersIcon fill="blue" width="1.5rem" />
                    </i>
                  </Link> */}

{ (jobPost.status=="Expiré"||jobPost.status=="Archivé")?  <i
                    title={t("Republier")}
                    className=" text-success  m-2"
                    role="button"
                    onClick={async() => {
                 let jobPostFromDB=await getJobPost(jobPost.reference)
                      let jpToClone={...jobPostFromDB}
                      delete jpToClone._id;
                      localStorage.setItem(
                        "jobPost",
                        JSON.stringify(jpToClone)
                      );
                      props.history.push(props.idResponsible ? "/responsible/addJobPost" : "/company/addJobPost");
                    }}
                  >
                    <CloneIcon fill="#4caf50" width="1.5rem" />
                  </i>:
                   <i
                   title={t("Modifier")}
                   className=" text-success  m-2"
                   role="button"
                   onClick={async() => {
                    let jobPostFromDB=await getJobPost(jobPost.reference)
                     localStorage.setItem(
                       "jobPost",
                       JSON.stringify(jobPostFromDB)
                     );
                     props.history.push(props.idResponsible ? "/responsible/addJobPost" : "/company/addJobPost");
                   }}
                 >
                   <EditIcon fill="#4caf50" width="1.5rem" />
                 </i>
                  }
                  {jobPost.status !== "Archivé" ? (
                    <i
                      title={t("Archiver")}
                      className="  text-danger m-2"
                      role="button"
                      onClick={() => {
                        // alert("fassakh baba fassakh")
                        Modal.confirm({
                          title: t("Archiver offre"),
                          icon: <ExclamationCircleOutlined />,
                          content:
                          t("Si vous souhaitez désactiver l'offre pour le poste de ")+jobPost.title+t(", tous les candidats en attente seront automatiquement déplacés vers la liste des candidatures rejetées, et un e-mail intitulé 'Candidature non acceptée' sera envoyé aux candidats concernés."),
                          okText: "Archiver",
                          okType: "danger",
                          cancelText: "Annuler",
                          onOk: () => {
                            Close(jobPost, index);
                          },
                 
                        });
                        //
                      }}
                    >

                      <XMarkIcon fill="#e25757" width="1.2rem" />
                    </i>
                  ) : (
                    <i
                      title="Archiver"
                      className=" text-secondary m-2"
                      role="button"
                    >
                      <XMarkIcon fill="gray" width="1.2rem" />
                    </i>
                  )}
                </div>
              );
            }}
          />
          
        </Table>
        </ConfigProvider> 
        {modalVisible && <Modal
          title={<h3 className=" m-1 text-primary">{t("Détails du poste")}</h3>}
          className="m-5 w-75"
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                className="m-3"
              />
            </i>
          }
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          footer={<></>}
        >
          <CompanyJobPostView
            company={props.company}
            reference={jobPost?.reference}
          />
          
        </Modal>}
        {noticeModalVisible && <NoticeForm userId={props.userId} />}

      </div>
    );
}
export default withRouter(ManageJobPosts);
