import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
class Language extends Component {
    constructor(props){
        super(props)
      }
      render() {
        const { t } = this.props;
          let i=-1
        let list=this?.props?.languages?.map(language=>{
            i++
          return(  <div className="cv-l" key={i}>
                        {language.language_fr}
                    </div>
          // <div key={i} className='row'>
          //   <div className=' col-6' >{language.language_fr}</div>
          //   <div className='col-6' ><Rate className='m-0 p-0' style={{fontSize:15}}  allowHalf disabled value={language.score*5} />
          //   </div><br/>
          //   </div> 
          )
        }) 
    return (
        <div className="cv-body-competence">
        <div className="cv-r-top">
          <div className="cv-b-comp">{t("Langues")}</div>                
        </div>

        <div className="cv-lang">
            {list}          
        </div>
      </div>
    )
  }
}
export default withTranslation()(Language)