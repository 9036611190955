import { notification } from 'antd';
import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { VerifyTest } from '../../services/api/companyTest/VerifyTest';
import { useTranslation } from "react-i18next";

import { AppContext } from '../../routes/AppContext';

export default function TestButton(props) {
  const history = useHistory({});
  const { user,headers } = useContext(AppContext);
  const { t } = useTranslation();
  let startTest = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    let response = await VerifyTest(
      {
        idTest: props.idTest,
        idJobPost: props.idJobPost,
        name:user.name,
        email:user.email
      },
      headers
    );
     if(response.status==200){
      history.push({
        pathname: "/candidate/jobPostTest",
        state: { idTest:props.idTest,idJobPost:props.idJobPost,idCompany:props.idCompany},
      });
  }
  };
  return (
    <div className='d-flex justify-content-center' >
      <div onClick={startTest} className='btn btn-primary zoom-1 ' > {t('Commencer le test')} </div>
    </div>
  )
}
