import { Modal, Popover, Progress, Steps, Tag } from "antd";
import moment from "moment-timezone";
import React, { Component } from "react";
import {

  TrophyOutlined,
} from "@ant-design/icons";
import ConfirmInterview from "./ConfirmInterview";
import Interview from "./Interview";
import TestButton from "./TestButton";
import AsyncImage from "../AsyncImage";
import { Link, withRouter } from "react-router-dom";
import CircleQuestionIcon from "../icons/CircleQuestionIcon";
import InterviewsHistory from "./InterviewsHistory";
import { AppContext } from "../../routes/AppContext";
import { withTranslation } from "react-i18next";
const { Step } = Steps;
class ApplicationComponent extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    let fix = false;
    let interview = {};
    let interviews = this.props.application.idJobPost[0].interviews
    let filteredInterviews = interviews.filter(i => !i.archived)
    interview = filteredInterviews[filteredInterviews.length - 1]
    this.state = { fixed: this.props.application.state == 2, interview: interview, interviews: interviews, modalVisible: false };
  }
  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  }
  confirmInterview = (i) => {
    this.setState({ fixed: true, interview: i });
    //change state 1.5=>2
    let applications = [...this.context.candidateApplications]
    for (let i = 0; i < applications.length; i++) {
      if (applications[i]._id == this.props.application._id) {
        applications[i].state = 2
        let interview = { ...i, idCandidate: this.context.userId }
        applications[i].idJobPost[0].interviews.push(interview)
      }
    }
    this.context.setCandidateApplications(applications)

  };
  dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };
  info = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let state = this.props.application.state;
    let message;
    switch (state) {
      case 0:
        message = <p> {this.props.t('message 0')} </p>;
        break;
      case 1:
        message = <p> {this.props.t('message 1')} </p>;
        break;
      case 2:
        message = <p> {this.props.t('message 2')} </p>;
        break;
      case 3:
        message = <p> {this.props.t('message 3')} </p>;
        break;
      case 4:
        message = <p> {this.props.t('message 4')} </p>;
        break;
      default:
        break;
    }
    Modal.info({
      title: null,
      content: (
        <div>
          {message}
        </div>
      ),
      onOk() { },
    });
  };
  customDot = (dot, { status, index }) =>{ 
    let state=this.props.application.state
    return(
    <Popover
      content={
        <span>
          {index==0?<>{this.props.t('En attente')}</>:(index==1?<>{this.props.t('Test')}</>:(index==1.5?<>{this.props.t('Entretien')}</>:(index==2?<>{this.props.t('Entretien planifié')} </>:(index==3?<>{this.props.t("Accepté / Refusé")}</>:""))))}
        </span>
      }
    >
      {/* <div className='icons12'  ><UserOutlined /></div> */}
   {index==2?   <span style={{    position: "relative",
    right: "9px",
    top: "-4px"}} className="m-1" onClick={()=>this.setModalVisible(true)} role="button">
            <i
              className={
                state == 3
                ? "mdi mdi-history text-danger icons12"
                : state == 4
                  ? "mdi mdi-history text-success icons12"
                  : "mdi mdi-history text-primary icons12"
            }
          />{" "}
        </span>
          : dot}
      </Popover>
    )
  };
  render() {
    let application = this.props.application;
    // application.state=3
    let jobPost = application.idJobPost[0];
    let company = jobPost.idCompany[0];
    let jobPostState = "";
    if (jobPost.closed) {
      jobPostState = this.props.t('Archivé') ;
    } else if (moment(jobPost.jobPostPublicationDate).isAfter(moment(this.context.serverTime))) {
      jobPostState = this.props.t('Programmé') ;
    } else if (moment(jobPost.jobPostExpirationDate).isBefore(moment(this.context.serverTime))) {
      jobPostState = this.props.t('Expiré') ;
    } else if (!jobPost.published) {
      jobPostState = this.props.t('En attente') ;
    } else jobPostState = this.props.t('Publié') ;

    return (
      <div
        // role="button"

        className=" rounded mt-3 p-3 shadow-sm"
      >
        <div className=" right text-center m-3 align-middle">
          {/* Score: {parseInt(jobPost.score*100)}% */}
          {/* <span onClick={this.info} role="button">
            <CircleQuestionIcon
              className="zoom-1 mb-3"
              fill="blue"
              width="1.4rem"
            />{" "}
          </span> */}

          <br />
          <Progress
            type="circle"
            percent={parseInt(application.score * 100)}
            format={(percent) => <TrophyOutlined />}
            width={55}
            strokeColor={
              parseInt(application.score * 100) < 30 ? "orange" : "green"
            }
          />
          <br />
        </div>

        <AsyncImage
          className="logo-m rounded-3 m-3"
          alt={company.name}
          src={
            this.context.envVar.REACT_APP_CLIENT_URL +
            "/company/images/" +
            company.image
          }
        />
        <b className="text-primary mt-3  text-capitalize"> {jobPost.title}</b>
        <br />
        <div className="text-secondary">
          {/* {description} */}
          {this.props.t('postulé le')}{" "}
          {moment(this.dateFromObjectId(application._id)).format("DD/MM/YYYY")}
        </div>
        <br />
        <Steps
          current={application.state}
          progressDot={this.customDot}
          className={
            application.state == 3
              ? "step-danger my-3"
              : application.state == 4
                ? "step-success my-3"
                : "my-3"
          }
        >
          <Step
            title={this.props.t("En attente")}
          // status={0}
          />
          <Step
            title={this.props.t('Test')}
          // status={1}
          />
          <Step
            title={this.props.t('Entretien')}
          // status={2}
          />
          <Step
            title={
              application.state == 3
                ? this.props.t("Refusé")
                : application.state == 4
                ? this.props.t("Accepté")
                : this.props.t("Accepté / Refusé")
            }
          // status={application.state}
          />
        </Steps>
        <Link to={"/jobPost/" + jobPost.reference} >
        <span role="button" className="right btn btn-primary p-1 zoom"  
        onClick={() => {this.props.history.push("/jobPost/" + jobPost.reference);}} >
          { this.props.t("Voir l'offre")}
          </span></Link>
        <br />
        {application.state == 1.5 && (
          <ConfirmInterview
            userId={this.props.userId}
            confirmInterview={this.confirmInterview}
            interviews={jobPost.dates}
            idCompany={company._id}
            idJobPost={jobPost._id}
            company={company}
            jobPost={jobPost}
          />
        )}
        {application.state == 2 && this.state.fixed && (
          <Interview
            userId={this.props.userId}
            interview={this.state.interview}
            state={application}
          />
        )}
        {application.state == 1 && (
          <TestButton
            idCompany={company._id}
            userId={this.props.userId}
            idJobPost={jobPost._id}
            idTest={application.idTest}
          />
        )}

        {/* <button disabled={parseInt(jobPost.score*100)<50} onClick={this.apply} className='btn btn-primary float-end zoom-1 '> Postuler </button>
       <i  title="Date d'expiration"  className=" text-dark m-2"   >{moment(jobPost.jobPostExpirationDate).format("DD/MM/YYYY")}</i>
            <i  title="Contrat"  className=" text-dark m-2"   > {jobPost.contract}</i> 
       <i  title="Nombre de postes"  className=" text-dark m-2"   > {jobPost.vacancies?jobPost.vacancies:1} </i>
       <i  title="Adresse"  className=" text-dark m-2"   > {jobPost.location.idLocation.location} </i> */}
        <InterviewsHistory modalVisible={this.state.modalVisible}
          setModalVisible={this.setModalVisible} interviews={this.state.interviews.reverse()} />
      </div>
    );
  }
}
export default withTranslation()(withRouter(ApplicationComponent));
