import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GetNotice } from "../../services/api/notice/GetNotice";
import { SetNotice } from "../../services/api/notice/SetNotice";

import Questions from "./Questions";
import decrypt from "../../utils/decrypt";
import { CloseOutlined } from "@ant-design/icons";
import gif from "../../media/image/remerciement.gif";
import AsyncImage from "../AsyncImage";

import { AppContext } from "../../routes/AppContext";
import { useTranslation } from "react-i18next";
export default function NoticeForm(props) {
  const {headers} = useContext(AppContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    get();
  }, []);
  const get = async () => {
    let response = await GetNotice(
      {},
      headers
    );
 if(response){
      let data = JSON.parse(decrypt(response.data));
    if (data.length) {
      setModalVisible(true);
      setQuestions(data);
    }}
  };
  const set = async () => {
    let response = await SetNotice(
      { answers: answers },
      headers
    );
    setModalVisible(false);
    Modal.success({
      title: null,
      content: (
        <div>
          <AsyncImage
            src={gif}
            alt="rocket"
            titre="rocket"
            height="150"
            className="mg-fluid"
          />
        </div>
      ),
      onOk() {},
    });
  };
  return (
    <>
      {modalVisible && (
        <Modal
          title={<h3 className=" m-1 text-primary">{t("Avant de continuer, ..!")}</h3>}
          closeIcon={
            <i
              onClick={() => {
                setModalVisible(false);
              }}
              className="icons05 m-0"
            >
              <CloseOutlined className="m-0" />
            </i>
          }
          className="m-5"
          centered
          closable={true}
          visible={modalVisible}
          onOk={() => {}}
          onCancel={() => {}}
          footer={null}
        >
          <Questions
            set={set}
            questions={questions}
            setAnswers={setAnswers}
            answers={answers}
          />
        </Modal>
      )}
    </>
  );
}
