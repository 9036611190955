
import axios from "../../../interceptors/axios";

export const profileCandidate = async (data,headers) => {
    try {
let res =await axios
.put("/api/profile/candidate",data,headers,{ withCredentials: true })
return res
} catch (err) {
    }
  };
