import CryptoJS from "crypto-js";
import { getEnvVars } from "./envVars";

 const crypt = (data) => {
    const envVar = getEnvVars();

// Encrypt
return CryptoJS.AES.encrypt(JSON.stringify(data),envVar.REACT_APP_CRYPTING_KEY).toString();
  
}
 
export default crypt
