import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
class AffichePFD extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    
    return (
      <card>
        <h3> {this.props.t("Merci de valider votre Cv")} </h3>
        <div className="modal-dialog  modal-lg ">
          <div className="modal-content" style={{ height: "350px" }}>
            <div className="modal-body" id="modal-body">
              <iframe src={this.props.src} height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>
      </card>
    );
  }
}
export default withTranslation()((AffichePFD))
