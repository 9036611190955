import React, { Component } from 'react'
import PlusIcon from '../../icons/PlusIcon';
import EducationComponent from './EducationComponent';
import EducationForm from './EducationForm';

import { withTranslation } from 'react-i18next';
class EducationContainer extends Component {
    constructor(){
        super()
      }
        state={modalVisible: false,
            // educations:this.props.educations?this.props.educations:[]
            index:null
        }
        setModalVisible = (modalVisible,index) => {
            this.setState({ modalVisible });
            index!=null ?  this.setState({ index }):this.setState({ index:null });
          };
    render() {
        let i=-1
        let list=this.props.educations && this.props.educations.map((education)=>{
            i++
            return(
            <div key={i}>
                <EducationComponent setModalVisible={this.setModalVisible} updateView={this.props.updateView}  index={i} education={education}/>
            </div>)});
        return (
            <div> 
           
                  <div className='d-flex justify-content-between' >
               <b>   - {this.props.t("Formations académiques")}</b>
               {/* <button
               onClick={()=>this.setModalVisible(true)} 
               style={{ borderRadius: "50%", width: "35px", height: "35px" }}

               className='btn btn-primary rounded-circle p-0 '>
            <i className="">
              <PlusIcon fill="white" width="1.5rem" />

            </i>
                </button> */}
             </div>
             <div className='Experience-add-all' onClick={()=>this.setModalVisible(true)}   >
                <div className='Experience-add'>
                    <div className='Experience-div-icon'>
                        <PlusIcon className='Experience-icon' 
                        // fill="white" width="1.5rem" 
                        />
                    </div>
                    <div className='Experience-text'>{this.props.t("Ajouter une formation")}</div>

                </div>
            </div>
               {this.state.modalVisible && <EducationForm education={this.props.educations[this.state.index]} index={this.state.index} modalVisible={this.state.modalVisible} setModalVisible={this.setModalVisible}  updateView={this.props.updateView} />}
             <div className='mt-4'>
                  {list}
                 
                 </div>  
            </div>
        )
    }
}
export default withTranslation()( EducationContainer )