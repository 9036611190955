import { notification, Rate,Select,Table,Modal, Divider, Space, Input, Typography } from 'antd'

import { AddSkill } from '../../services/api/features/AddSkill';
import { PlusOutlined } from '@ant-design/icons';
import React, { Component } from 'react'
import { CloseOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import ChooseLevelModal from './ChooseLevelModal';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';
import FileIcon from '../icons/FileIcon';
import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { WarningOutlined} from '@ant-design/icons';
import InfoIcon from '../icons/InfoIcon';

import { withTranslation } from 'react-i18next';

const { Column } = Table;

const { Option } = Select;
class SoftSkills extends Component {
  static contextType = AppContext

    state={score:1,skill:"",key:null,update:false,error:"",name:"",
softSkills:this.props.softSkills?this.props.softSkills:[],
modalVisible:false,skillToTest:{},firstTime:true
}

onNameChange = value => {
  this.setState({name:value})
 };

addItem = async(e) => {
   e.preventDefault();
   if (this.state.name) 
   { 
     if(this.validation(this.state.name)){
    let res=await AddSkill({skill:this.state.name,feature:1}, this.context.headers)
  if(res){
    let skill= {soft_skill_fr:this.state.name}
    skill.score=0.2
    skill.feature=1    
 
           let softSkills=this.props.softSkills
           softSkills.push(skill)
          this.props.setSoftSkills(softSkills)
          
          notification.success({
        
            description:res.data.message,
            className: 'custom-class',
            style: {
             width: 600,
             backgroundColor:"#d4edda",
             marginTop:"5rem"
           },
           });}
   
     }
   this.setState({name:"",skill:""})}
 };
setModalVisible=(value,skill)=>{
  this.setState({ modalVisible:value,skillToTest:skill });
}
    handleChange = value => {
        this.setState({ score:value });
      };
       handleSelect=(value,i)=> {
        // this.setState({ skill:value,key:i.key });
        this.addSkill(value,i.key)
      }
      validation=(skill)=>{
        let isvalid=true
        // let skill=this.state.skill
        let softSkills=this.props.softSkills

if(skill==""){
    isvalid=false
    notification.warn({
       
      description:this.props.t("veuillez choisir une compétence"),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor:"#fff3cd",
          marginTop:"5rem"
        },
      })
}
else if(softSkills.length>=5) {
    isvalid=false
    notification.warn({
       
      description:this.props.t("vous atteignez le maximum de compétences possibles"),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor:"#fff3cd",
          marginTop:"5rem"
        },
      })
}
else {
  for (let i = 0; i < softSkills.length; i++) {
      const element = softSkills[i];
      if(element.soft_skill_fr==skill){
        isvalid=false
        notification.warn({
           
          description:this.props.t("compétence déja utilisé"),
            className: 'custom-class',
            style: {
              width: 600,
              backgroundColor:"#fff3cd",
              marginTop:"5rem"
            },
          }) 
      }
      
  }
}
 
     return isvalid
     }
      addSkill=(s,key)=> {
if(this.validation(s)){
   let skill= this.context.softSkillsList[key]
   skill.score=0.2


          let softSkills=this.props.softSkills
          softSkills.push(skill)
         this.props.setSoftSkills(softSkills)
     
  
    }

      }
      info = () => {
        this.setState({firstTime:false})
         
        Modal.info({
          title: null,
          content: (
            <div>
              <p>       {this.props.t("Puisque les 'Soft skills' indiquent souvent votre capacité à travailler avec les autres et à évoluer au sein d'une entreprise. Mettez votre profil en valeur et choisissez les compétences interpersonnelle qui vous décrivent le mieux afin de vous aider à trouver l'emploi adéquat. (maximum 5)")} </p>
              
            </div>
          ),
      
          onOk() {},
        });
      };
    render() {
      const { t,i18n } = this.props;
      const language=i18n.language 
      const softSkillLanguages={"fr":"soft_skill_fr","en":"soft_skill_en"}
      const softSkillLanguage=softSkillLanguages[language]

      let i=-1
      let list=this.props.softSkills.map(skill=>{
        i++
        let code=skill.code
        return(
          <div key={i} className="p-2 m-2 border  rounded d-flex align-items-center " >
            <div className='text-center capitalize' >{skill[softSkillLanguage]}</div>
            <div className='text-center ms-auto me-4' >
            {/* <Rate  allowHalf disabled value={skill.score*5} /> */}
            </div>
            <div className=' text-center ' >

            <i className="text-primary m-2 "
role="button"
//  style={{cursor: "pointer"}} 
             onClick={()=>{this.setModalVisible(true,skill)
            }}
             >
            <FileIcon width="1.3rem" fill="blue" />

             </i></div>
             <div className=' text-center ' >
            <i  title="Supprimer"  className=" text-danger m-1" role="button"
          onClick={() => {
            Modal.confirm({
              title:  this.props.t('Supprimer compétence'),
              icon: <ExclamationCircleOutlined />,
              content: 
              this.props.t('voulez vous supprimer la compétence')+skill[softSkillLanguage],
              okText: this.props.t("Supprimer"),
              okType: "danger",
              cancelText: this.props.t("Annuler"),
              onOk:()=>{
          
                this.props.setSoftSkills(this.props.softSkills.filter(item=>{return item.soft_skill_fr!==skill.soft_skill_fr}))
                this.setState({update:!this.state.update})
              
            
            }
          
          })}}
        >
          <TrashIcon fill="red" width="1.5rem" />
        </i></div>
          </div>
        )
      })
       
        const children = [];
        let softSkillsList=this.context.softSkillsList
for (let i = 0; i <softSkillsList.length ; i++) {
  children.push(<Option key={i} value={softSkillsList[i].soft_skill_fr}><div className='capitalize' >{softSkillsList[i].other_ss}</div></Option>);
}
        return (
            <div>
                {/* <h4 className="text-primary" > Compétences techniques </h4> */}
               <b> - {this.props.t('Compétences interprsonnelles')} </b>
               <span onClick={this.info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>

               {/* <div className='d-flex justify-content-center' >
      <h6 className='w-100 m-2 p-4  text-dark rounded border bg-warn ' > <span className='m-1 icons12 ' > <WarningOutlined /></span> 
      Puisque les "Soft skills" indiquent souvent votre capacité à travailler avec les autres et à évoluer au sein d'une entreprise. 
Mettez votre profil en valeur et choisissez les compétences interpersonnelle qui vous décrivent le mieux afin de vous aider à trouver l'emploi adéquat. (maximum 5)  
      </h6></div> */}
            <div className=" m-3">
                
                 <Select className="w-75"  
                  // style={{ width: '40%' }} 
                  onFocus={()=>{if(this.props.softSkills.length==0&&this.state.firstTime)this.info()}}
                   showSearch
    placeholder={this.props.t("Rechercher pour sélectionner")} onChange={this.handleSelect}
    onSearch={this.onNameChange}
    notFoundContent={<>
     <Divider style={{ margin: '8px 0' }} />
          <Space align="center" style={{ padding: '0 8px 4px' }}>
            <Typography.Link onClick={this.addItem} style={{ whiteSpace: 'nowrap' }}>
              <PlusOutlined /> {this.props.t('Ajouter')}
            </Typography.Link>
          </Space> 
    </>}  
    >
    {children}
  </Select>
  

                   </div>
        <div>
       

          <div>
     
     <div className='mx-5'>
      {list}
     </div>
       </div>
          {this.state.modalVisible && 
          <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b>Choisir le niveau du test</b>
            </h5>
          }
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={this.state.modalVisible }
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
          footer={<></>}
        >
       <ChooseLevelModal userId={this.props.userId} skill={this.state.skillToTest}   />
     </Modal>}
           
            </div>
                   
                   </div>
        )
    }
}
export default withTranslation()(SoftSkills)