import axios from "../../../interceptors/axios"

export const CompanyAccountEmailConfirm = async (data, headers) => {
  try {
    let res = await axios.post("/api/accountConfirm/company", data, {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
    
  }
};
