import React, { Component } from 'react'
import PlusIcon from '../../icons/PlusIcon';
import ExperienceComponent from './ExperienceComponent';
import ExperienceForm from './ExperienceForm'
import './ExperienceContainer.css'
import { withTranslation } from "react-i18next";
 class ExperienceContainer extends Component {
  
        state={
            modalVisible: false,
            index:null
            // experiences:this.props.experiences?this.props.experiences:[]
        }
    setModalVisible = (modalVisible,index) => {
        this.setState({ modalVisible });
        index!=null ?  this.setState({ index }):this.setState({ index:null });
      };
    render() {
        let i=-1
        let list=this.props.experiences && this.props.experiences.map((experience)=>{
            i++
            return(
            <div key={i}>
                <ExperienceComponent setModalVisible={this.setModalVisible} updateView={this.props.updateView}  index={i} experience={experience}/>
            </div>)});
        return (
            <div>
                <div className='d-flex justify-content-between' >
               <b>   - {this.props.t('Expériences professionnelles')} </b>
               {/* <button
               onClick={()=>this.setModalVisible(true)} 
               style={{ borderRadius: "50%", width: "35px", height: "35px" }}

               className='btn btn-primary rounded-circle p-0 '>
            <i className="">
              <PlusIcon fill="white" width="1.5rem" />

            </i>
                </button> */}
             </div>
             <div className='Experience-add-all' onClick={()=>this.setModalVisible(true)}   >
                <div className='Experience-add'>
                    <div className='Experience-div-icon'>
                        <PlusIcon className='Experience-icon' 
                        // fill="white" width="1.5rem" 
                        />
                    </div>
                    <div className='Experience-text'>{this.props.t("Ajouter une expérience")}</div>

                </div>
            </div>
               {this.state.modalVisible &&  <ExperienceForm experience={this.props.experiences[this.state.index]} index={this.state.index} modalVisible={this.state.modalVisible} setModalVisible={this.setModalVisible} updateView={this.props.updateView} />}
               <div className='mt-4' >

                {list}
               </div>

            </div>
        )
    }
}

export default withTranslation()(ExperienceContainer);