import React, { useContext, useState } from 'react';
import Candidate from './Candidate';
import { WarningOutlined} from '@ant-design/icons';
import InterviewsForm from '../../addInterviews/InterviewsForm';
import { Radio, Select } from 'antd';
import { AppContext } from '../../../routes/AppContext';
const { Option } = Select;

export default function Interviews(props) {

    let i=-1
    let list= props.applications.map((candidat)=>{
        i++
        return(<li key={i}><Candidate userId={props.userId}  index={i} candidat={candidat}/></li>)});
  return <div className='row' >
<div className='col-md-12 col-sm-12' > <InterviewsForm applications={props.applications} interviews={props.interviews} setInterviews={props.setInterviews} /> 
{/* {true && <small className='text-danger' > - Merci d'ajouter au moins <b>{props.applications.length}</b> dates de disponibilité d'entretiens !</small>} */}
</div>

    
      <ol className='col-md-12 col-sm-12' >
{list}
</ol>
{props.applications.length==0 && <h4 className=' d-flex justify-content-center text-secondary' > <span className='m-3 icons-lg ' > <WarningOutlined /></span> <br/> Aucun candidat invité pour un entretien  </h4>}

  </div>;
}
