import React from 'react'
import CandidateCvUpload from '../../components/cv/uploadCv/CandidateCvUpload'

export default function CandidateCvUploadView(props) {
    return (
        <>
            <CandidateCvUpload userId={props.userId} />
        </>
    )
}
