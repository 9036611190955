import { Modal, notification, Progress, Tag } from "antd";
import React, { Component } from "react";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import moment from "moment-timezone";
import MapComponent from "./MapComponent";
import { Apply } from "../../services/api/application/Apply";
import MapMarkerIcon from "../icons/MapMarkerIcon";
import UserIcon from "../icons/UserIcon";
import ClockIcon from "../icons/ClockIcon";
import MoneyIcon from "../icons/MoneyIcon";
import SchoolIcon from "../icons/Schoolcon";
import SearchIcon from "../icons/SearchIcon";
import AsyncImage from "../AsyncImage";
import decrypt from "../../utils/decrypt";
import crypt from "../../utils/crypt";
import gif from "../../media/image/bad.gif";
import gif2 from "../../media/image/postulerSuccee.gif";
import { FacebookShareCount, FacebookShareButton } from "react-share";
import { AppContext } from "../../routes/AppContext";
import { Link, withRouter } from "react-router-dom";
import ArmyStarIcon from "../icons/ArmyStarIcon";
import { withTranslation } from "react-i18next";

class JobPostPreview extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = { applied: false,copied:false };
  }
  setCopied=(copied)=>{
    this.setState({copied})
  }
  apply = (e) => {
    e.preventDefault();
    // e.stopPropagation()
    if (this.context.role == "candidate") {
      let jobPost = this.props.jobPost;
      let jobPostState = "";
      if (jobPost.closed) {
        jobPostState = this.props.t("Archivé");
      } else if (moment(jobPost.jobPostPublicationDate).isAfter(moment(this.context.serverTime))) {
        jobPostState = this.props.t("Programmé");
      } else if (moment(jobPost.jobPostExpirationDate).isBefore(moment(this.context.serverTime))) {
        jobPostState = this.props.t("Expiré");
      } else if (!jobPost.published) {
        jobPostState = this.props.t("En attente");
      } else jobPostState = this.props.t("Publié");
      if (jobPostState === this.props.t("Publié")) {
        let score = this.props.jobPost.score
          ? this.props.jobPost.score
          : this.props.jobPost?.application?.length
          ? this.props.jobPost.application[0].score
          : this.props.jobPost?.matching?.length
          ? this.props.jobPost.matching[0].score
          : 0;
          if(!this.context.candidateAccess){
            notification.warn({
              description: <div className="text-center">
              <span> 
              {this.props.t("Vous devez renseigner votre profil et remplir votre cv pour pouvoir postuler.")}
              </span>
            </div>,
                className: "custom-class",
              placement: 'top',className: "custom-class",
              style: {
                width: 600,
                backgroundColor: "#fff",
                marginTop: "5rem",
              },
            });
           }
        else if (parseInt(score * 100) < 30) {
          notification.info({
            description: (
              <div className="text-center">
                <AsyncImage
                  src={gif}
                  alt="email"
                  style={{ width: "15rem", height: "15rem" }}
                />
                <br />
                <span>
                  {this.props.t("Malheureusement, vous ne pouvez pas postuler pour ce poste.")}
                  {this.props.t("Nous vous contacterons par e-mail dès qu'une offre correspondant à votre CV sera disponible.")}
                </span>
              </div>
            ),
            className: "custom-class",
            placement: "top",
            style: {
              width: 600,
              backgroundColor: "#fff",
              marginTop: "5rem",
            },
          });
        } else {
          Modal.confirm({
            title: this.props.t("Postuler"),
            icon: <ExclamationCircleOutlined />,
            content:
              this.props.t("voulez vous postuler à l'offre") +
              this.props.jobPost.title +
              ". " + this.props.t("Si vous postulez, vous ne pouvez pas annuler votre candidature."),
            okText: "Postuler",
            cancelText: "Annuler",
            onOk: async () => {
              let response = await Apply(
                {
                  idJobPost: this.props.jobPost._id,
                  score: score,
                  idCompany: this.props.company._id,
                  titleJobpost:this.props.jobPost.title
                },
                this.context.headers
              );
 if(response){
                notification.open({
                  description: (
                    <div className="text-center">
                      <span>
                        <b>{this.props.t(response.data.code)}</b>
                      </span>
                      <AsyncImage
                        src={gif2}
                        alt="postuler"
                        style={{ width: "15rem", height: "15rem" }}
                      />
                    </div>
                  ),
                  className: "custom-class mt-5 ",
                  placement: "top",
                  duration: 10,
                  style: {
                    backgroundColor: "#fff",
                    marginTop: "5rem",
                  },
                });
                let jobPosts = this.context.jobPosts;
                jobPosts.map((jobPost) => {
                  if (jobPost._id == this.props.jobPost._id) {
                    jobPost.applied = true;
                  }
                });
                this.context.setJobPosts(jobPosts);
                this.setState({ applied: true });
                if (response?.status == 201)
                  this.context.socket.emit("sendNotification", {
                    receiverId: this.props.jobPost.idCompany._id,
                    type: 0,
                  });
              }

              // this.props.history.push('/login/entreprise')
            },
            // onCancel:()=>{alert(index)}
          });
        }
      } else {
        //offre n'est plus publié
        notification.warn({
          description: this.props.t("L'offre n'est plus disponible"),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#fff3cd",
            marginTop: "5rem",
          },
        });
      }
    } else {
      Modal.confirm({
        title: this.props.t("S'authentifier"),
        content: this.props.t("Il faut se connecter pour avoir la possibilité de soumettre une candidature !"),
        okText: this.props.t("S'authentifier"),
        okType: "danger",
        cancelText: this.props.t("Annuler"),
        onOk: () => {
          // console.log(this.props.jobPost.reference)
          // console.log(this.context.redirectJobpost)
          if ("caches" in window) {
      caches
      .open("redirectJobpost")
      .then((cache) =>
        cache.addAll([
          '/jobPost/'+ this.props.jobPost.reference
        ]),
      )
          }
          this.props.history.push("/signin/candidate");
          // caches
          // .open("redirectJobpost")
          // .then((cache) =>
          //   cache.matchAll().then((val)=> console.log(val[0].url))
          // )
          // caches.delete("redirectJobpost")
          // this.Delete(jobPost._id, index);
        },
        // onCancel:()=>{alert(index)}
      });
    }
  };
  render() {
  const { t,i18n } = this.props;
  const languages = { "fr": "language_fr", "en": "language_en" ,"ar": "language_en"}
  const softSkills = { "fr": "soft_skill_fr", "en": "soft_skill_en", "ar": "soft_skill_en"  }
  const language = languages[i18n.language]
  const softSkill = softSkills[i18n.language]
    let jobPost = this.props.jobPost;
    let score = jobPost.score
      ? jobPost.score
      : jobPost?.application?.length
      ? jobPost.application[0].score
      : jobPost?.matching?.length
      ? jobPost.matching[0].score
      : 0;
    let company = this.props.company;
    let jobPostState = "";
    if (jobPost.closed) {
      jobPostState = this.props.t("Archivé");
    } else if (moment(jobPost.jobPostPublicationDate).isAfter(moment(this.context.serverTime))) {
      jobPostState = this.props.t("Programmé");
    } else if (moment(jobPost.jobPostExpirationDate).isBefore(moment(this.context.serverTime))) {
      jobPostState = this.props.t("Expiré");
    } else if (!jobPost.published) {
      jobPostState = this.props.t("En attente");
    } else jobPostState = this.props.t("Publié");

    let email = company.email;
    let phone = company.phone.toString();
    let hiddenEmail = "";
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    let hiddenPhone = "";

    for (let i = 0; i < phone.length; i++) {
      if (i > 4) {
        hiddenPhone += "*";
      } else {
        hiddenPhone += phone[i];
      }
    }
    let skillsTags = jobPost.idHardSkills.map((skill) => {
      return (
        <Tag className="m-1 capitalize" color="magenta">
          {skill.idHardSkill.skill}
        </Tag>
      );
    });
    let softSkillsTags = jobPost.idSoftSkills.map((skill) => {
      return (
        <Tag className="m-1 capitalize" color="cyan">
          {skill.idSoftSkill[softSkill]}
        </Tag>
      );
    });
    let languagesTags = jobPost.idLanguages.map((skill) => {
      return (
        <Tag className="m-1 capitalize" color="geekblue">
          {skill.idLanguage[language]}
        </Tag>
      );
    });
    for (let i = 0; i < jobPost.requestedSkills.length; i++) {
      const requestedSkill = jobPost.requestedSkills[i];
      if (requestedSkill.feature == 0) {
        skillsTags.push(
          <Tag className="m-1" color="magenta">
            {requestedSkill.skill}
          </Tag>
        );
      } else if (requestedSkill.feature == 1) {
        softSkillsTags.push(
          <Tag className="m-1" color="cyan">
            {requestedSkill[softSkill]}
          </Tag>
        );
      } else if (requestedSkill.feature == 2) {
        languagesTags.push(
          <Tag className="m-1" color="geekblue">
            {requestedSkill[language]}
          </Tag>
        );
      }
    }
    const ApplyButton=()=>
    <div className="mt-3">
    {jobPost.applied ||
    this.state.applied ||
    jobPost?.application?.length || this.props.appli==true ? (
      <span
        className=""
        tabindex="0"
        data-toggle="tooltip"
        title={t("Déja postulé")}
      >
        <button
          disabled={true}
          className="btn btn-primary btn-hover w-100 mt-2 "
         
        >
          {" "}
          {t("Déja postulé")}{" "}
        </button>
      </span>
    ) : (
      (this.context.role !=="company" && this.context.role !=="responsible") &&
      (<button
        onClick={this.apply}
        className="btn btn-primary btn-hover w-100 rounded "
       

      >
        <i className="mdi mdi-file"></i> {t("Postuler")}{" "}
      </button>)
    )}
    {/* <a href="#applyNow" data-bs-toggle="modal" className="btn btn-primary btn-hover w-100 mt-2">Apply Now <i className="uil uil-arrow-right"></i></a> */}
    {/* <a href="bookmark-jobs.html" className="btn btn-soft-warning btn-hover w-100 mt-2"><i className="uil uil-bookmark"></i> Add Bookmark</a> */}
  </div>
    const experienceText=[    t("0-1 ans"),
    t("1-3 ans"),t("1-3 ans"),
    t("3-5 ans"),t("3-5 ans"),
    t("&gt;5 ans"),]
    const genders=[t("Homme/Femme"),t("Homme"),t("Femme")]
    return (
      <div className="jobPostPreview modifiedTag pb-5 mb-5">
       <div 
                              style={{ position: "fixed", bottom: "30px", right: "0px",zIndex:"9999" }}
                              className="d-flex justify-content-center w-100 ">
                          {jobPost.applied ||
                          this.state.applied ||
                          jobPost?.application?.length || this.props.appli==true  ? (
                            <span
                              className=""
                              tabindex="0"
                              data-toggle="tooltip"
                              title="Déja postulé"
                            >
                              <button
                                disabled={true}
                                className="btn btn-primary btn-hover mt-2 "
                              // style={{ position: "fixed", bottom: "20px", right: "25vw",zIndex:"9999" }}

                              >
                                {" "}
                                {t("Déja postulé")}{" "}
                              </button>
                            </span>
                          ) : (
                            (this.context.role !=="company" && this.context.role !=="responsible") &&
                            <button
                              onClick={this.apply}
                              className="btn btn-special btn-hover rounded p-3 text-white"
                              // style={{ position: "fixed", bottom: "20px", right: "25vw",zIndex:"9999" }}

                            >
                              <i className="mdi mdi-file icons12"></i> {t("Postulez à cette offre dès maintenant !")}{" "}
                            </button>
                          )}
                          {/* <a href="#applyNow" data-bs-toggle="modal" className="btn btn-primary btn-hover w-100 mt-2">Apply Now <i className="uil uil-arrow-right"></i></a> */}
                          {/* <a href="bookmark-jobs.html" className="btn btn-soft-warning btn-hover w-100 mt-2"><i className="uil uil-bookmark"></i> Add Bookmark</a> */}
                        </div>
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 h-100">
                <div style={{ height: "10rem" }}>
                  <MapComponent localization={company.localization} />
                  <div>
                  <AsyncImage
                  style={{border:"0.125rem solid #fff",boxShadow: "0 0.125rem 0.25rem #2d2d2d1f, 0 0.0625rem 0.1875rem #2d2d2d29, 0 0 0.125rem #2d2d2d33",boxSizing: "border-box"}}
                          className="logo-m z-index-3 ms-3 mt-2 rounded-3"
                          alt={company.name}
                          src={
                            this.context.envVar.REACT_APP_CLIENT_URL  +
                            "/company/images/" +
                            company.image
                          }
                        />
                  </div>
                </div>

                <div className="card-body p-2 mt-5">
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="titre">
                      <h2 className="mt-5 ms-3 fw-bold text-primary text-capitalize">
                        {" "}
                        {jobPost.title}
                      </h2>
                      <div className="ms-4">
                        <i
                          title="Nombre de postes"
                          className="text-secondary mx-1 mb-2"
                          >
                          <UserIcon className="" width="14px" />
                        </i>
                        {jobPost.vacancies ? jobPost.vacancies : 1} {t("Poste vacant")}
                      </div>
                    </div>
                    <div className="text-center" >

                      <Progress
                        type="circle"
                        percent={parseInt(score * 100)}
                        format={(percent) => `${percent}%`}
                        width={55}
                        strokeColor={
                          parseInt(score * 100) < 30 ? "orange" : "green"
                        }
                        />
                        <ApplyButton/>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="row row justify-content-md-center g-2">
                      <div className="col-lg-4 col-sm-4">
                        <div className="border rounded-start p-3">
                          <p className="text-muted mb-0 fs-13">{t("Experience")}</p>
                          <p className="fw-medium fs-15 mb-0">
                            {experienceText[jobPost.experience]}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="border p-3">
                          <p className="text-muted fs-13 mb-0">
                            {t("Type de travail")}
                          </p>
                          <p className="fw-medium mb-0">
                            {jobPost.workTime
                              ? jobPost.workTime
                              : "Non précisé"}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-4">
                        <div className="border rounded-end p-3">
                          <p className="text-muted fs-13 mb-0">{t("Salaire")}</p>
                          <p className="fw-medium mb-0">
                            {" "}
                            {jobPost.salary ? jobPost.salary + " DT" : "Non précisé"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pb-5 description">
                    <div className="mt-4">
                      <h5 className="mb-3">{t("Mission")}</h5>
                      <div className="job-detail-desc">
                        {console.log(jobPost)}
                      <p
                          dangerouslySetInnerHTML={{
                            __html: jobPost?.idJobPostDescription?.description
                                      ?jobPost?.idJobPostDescription?.description
                                      :(jobPost?.idJobPostDescription?.length >0)
                                              ? jobPost?.idJobPostDescription[0]?.description
                                              : (i18n.language ==="fr")
                                                ? jobPost?.idSuggestJobDescription[0]?.descriptionfr
                                                : jobPost?.idSuggestJobDescription[0]?.descriptionEn
                          }}
                          className="text-muted mb-0 capitalize"
                        ></p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="mb-3">{t("Profil recherché")} </h5>
                      <div className="job-detail-desc mt-2">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: jobPost?.idJobPostRequestedProfile?.description
                                      ? jobPost?.idJobPostRequestedProfile?.description
                                      :(jobPost?.idJobPostRequestedProfile?.length >0)
                                        ? jobPost?.idJobPostRequestedProfile[0]?.description
                                        : (i18n.language ==="fr")
                                          ? jobPost?.idSuggestJobDescriptionProfil[0]?.descriptionprofilfr
                                          : jobPost?.idSuggestJobDescriptionProfil[0]?.descriptionprofilEn
                          }}
                          className="text-muted capitalize"
                        ></p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="mb-3">{t("Compétences techniques")}</h5>
                      <div className="job-detail-desc mt-2">
                        <p className="text-muted">{skillsTags}</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="mb-3">{t("Compétences interpersonnelles")}</h5>
                      <div className="job-detail-desc mt-2">
                        <p className="text-muted">{softSkillsTags}</p>
                      </div>
                    </div>
                    <div className="mt-4 mb-4">
                      <h5 className="mb-3">{t("Langues")}</h5>
                      <div className="job-detail-desc mt-2">
                        <p className="text-muted">{languagesTags}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-4 mt-lg-0">
                <div className="side-bar ms-lg-4">
                  <div className="card job-overview">
                    <div className="card-body row justify-content-md-center  p-4">
                      <div className="">
                        <h3 className="text-center">{t("Aperçu du poste")}</h3>
                        <ul className="list-unstyled mt-4 mb-0">
                          <li>
                            <div className="d-flex mt-4">
                              <i className="px-2 icon">
                                <UserIcon width="20px" />
                              </i>
                              <div className="">
                                <h6 className="fs-14 mb-2">{t("Genre")}</h6>
                                <p className="text-muted mb-0">
                                  {genders[jobPost.gender]}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex mt-4">
                              <i className="px-2 icon">
                                <ArmyStarIcon width="40px" />
                              </i>
                              <div className="">
                                <h6 className="fs-14 mb-2">{t("Handicapé")}</h6>
                                <p className="text-muted mb-0">
                                  {" "}
                                  {jobPost.handicap
                                    ? "Priorité"
                                    : "Non spécifié"}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex mt-4">
                              <i className="px-2 icon">
                                <MapMarkerIcon width="20px" />
                              </i>
                              <div className="">
                                <h6 className="fs-14 mb-2">{t("Emplacement")}</h6>
                                <p className="text-muted mb-0">
                                  {" "}
                                  {jobPost.location.idLocation.location}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex mt-4">
                              <i className="px-2 icon">
                                <MoneyIcon width="20px" />
                              </i>
                              <div className="">
                                <h6 className="fs-14 mb-2">{t("Salaire offert")}</h6>
                                <p className="text-muted mb-0">
                                  {jobPost.salary
                                    ? jobPost.salary + " DT"
                                    : "1 DT"}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex mt-4">
                              <i className="px-2 icon">
                                <SchoolIcon width="20px" />
                              </i>
                              <div className="">
                                <h6 className="fs-14 mb-2">{t("Qualification")}</h6>
                                <p className="text-muted mb-0">
                                  {jobPost.educationLevel
                                    ? jobPost.educationLevel
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex mt-4">
                              {/* <i className="px-2 icon ">
                                <WorkIcon
                                  width="20px"
                                  style={{
                                    marginTop: "0.4rem",
                                    marginLeft: "0.45rem",
                                  }}
                                />
                              </i> */}
                              <i
                                className=" icon mdi mdi-file"
                                style={{
                                  color: "black",
                                }}
                              ></i>
                              <div className="">
                                <h6 className="fs-14 mb-2">{t("Contrat")}</h6>
                                <p className="text-muted mb-0">
                                  {jobPost.contract ? (typeof jobPost.contract === 'string'?jobPost.contract:jobPost.contract.join(",")) : ""}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex mt-4">
                              <i className="px-2 icon">
                                <ClockIcon width="20px" />
                              </i>
                              <div className="">
                                <h6 className="fs-14 mb-2">{t("date postée")}</h6>
                                <p className="text-muted mb-0">
                                  {moment(this.context.serverTime).to(
                                    moment(jobPost.jobPostPublicationDate)
                                  )}
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <ApplyButton/>
                       
                      </div>
                    </div>
                  </div>

                  <div className="card company-profile mt-4">
                    <div className="card-body p-2">
                      <div className="text-center">
                        <AsyncImage
                          className="img-fluid  rounded-3 "
                          alt={company.name}
                          src={
                            this.context.envVar.REACT_APP_CLIENT_URL  +
                            "/company/images/" +
                            company.image
                          }
                        />

                        <div className="mt-4">
                          <h6 className="fs-17 mb-1">{company.name}</h6>
                          <p className="text-muted">
                            {company?.companyCreationDate
                              ? moment(this.context.serverTime).to(
                                  moment(company?.companyCreationDate)
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <ul className="list-unstyled mt-4">
                        <li>
                          <div className="d-flex">
                            <i className="uil uil-phone-volume text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">
                                <i className="mdi mdi-cellphone-basic"></i>
                                {t("Téléphone")}
                              </h6>
                              <p className="text-muted fs-14 mb-0">
                                {hiddenPhone}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="uil uil-envelope text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">
                                {" "}
                                <i className="mdi mdi-email"></i>{t("E-mail")}
                              </h6>
                              <p className="text-muted fs-14 mb-0">
                                {hiddenEmail}
                              </p>
                            </div>
                          </div>
                        </li>
                        {company.website && (
                          <li className="mt-3">
                            <div className="d-flex">
                              <i className="uil uil-globe text-primary fs-4"></i>
                              <div className="ms-3">
                                <h6 className="fs-14 mb-2">
                                  {" "}
                                  <i className="mdi mdi-web"></i> {t("Site Internet")}
                                </h6>
                                <p className="text-muted fs-14 text-break mb-0">
                                  {company.website}
                                </p>
                              </div>
                            </div>
                          </li>
                        )}
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="uil uil-map-marker text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">
                                {" "}
                                <i className="mdi mdi-google-maps"></i>{t("Adresse")}
                              </h6>
                              <p className="text-muted fs-14 mb-0">
                                {company.address}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      {/* <div className="mt-4">
                        <a className="btn btn-primary btn-hover w-100 rounded disabled">
                          <i className="mdi mdi-eye"></i> Voir Profil
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {jobPostState == "Publié" && (
          <>
            <div className="d-flex  justify-content-center">
              <a
                className="btn btn-primary m-1"
                // href={`https://facebook.com/sharer/sharer.php?u=${
                //   "https://rec-inov.com/jobPost/" + jobPost.reference
                // }`}
                href={`https://www.facebook.com/dialog/share?app_id=1196012994652694&display=iframe&href=https%3A%2F%2Frec-inov.com%2Fimages%2Fpub_jobposts%2F${jobPost.reference}.png`}
                target="_blank"
              >
                <i className="mdi mdi-facebook m-1"></i>
                {t("Partager sur facebook")}
              </a>

                <a
                  className="btn btn-primary m-1"
                  href={
                    "https://www.linkedin.com/sharing/share-offsite/?url=" +
                    "https://rec-inov.com/images/pub_jobposts/" +
                    jobPost.reference+".png"
                  }
                  target="_blank"
                  title="Partager sur LinkedIn"
                >
                  <i className="mdi mdi-linkedin m-1"></i>
                  {t("Partager sur LinkedIn")}
                </a>
              </div>
              <div className="d-flex  justify-content-center">
                <bouton
                  className={this.state.copied?"btn btn-success":"btn btn-primary"}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "https://rec-inov.com/jobPost/" + jobPost.reference
                    );
                    this.setCopied(true)
                    setTimeout(()=>{this.setCopied(false)},3000)
                  }}
                >
                  
            {this.state.copied?<><i className="mdi mdi-check-circle" ></i>{t("Lien copié")}</>:<><i className="mdi mdi-content-copy m-1"></i>
                  {t("copier le lien")}</>}

                </bouton>


              </div>
            </>
          )}
      </div>
    );
  }
}
export default withTranslation()(withRouter(JobPostPreview));
