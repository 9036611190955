import axios from "../../../interceptors/axios";
export const UploadJobPostService = async (formData, headers) => {
  try {
    let res = await axios.post("/api/upload/File", formData, {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
