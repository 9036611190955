import React, { Component } from "react";
import { Button, Carousel, Modal, notification } from "antd";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";

import CryptoJS from "crypto-js";
import gif from "../../media/image/profil.gif";
import { withRouter } from "react-router";
import { ObjectID } from "bson";

import {
  PlusOutlined,
  WarningOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import SoftSkills from "./SoftSkills";
import HardSkills from "./HardSkills";
import Languages from "./Languages";
import { AddJobPostService } from "../../services/api/manageJobPosts/AddJobPost";
import { UpdateJobPost } from "../../services/api/manageJobPosts/UpdateJobPost";
import DragDropComponent from "../cv/DragDropComponent";
import { getOrders } from "../../services/api/pricing/getOrders";
import { AppContext } from "../../routes/AppContext";
import { Link } from "react-router-dom";
import AsyncImage from "../AsyncImage";
import NoticeForm from "../noticeForm/NoticeForm";
import Weights from "./Weights";
import UploadIcon from "../icons/UploadIcon";

import JobPostPreview from "../manageJobPosts/JobPostPreview";
import { GetSuggestions } from "../../services/api/suggestSkill/GetSuggestions";
import { withTranslation } from "react-i18next";

class AddJobPost extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props);
    let jobPost = localStorage.getItem("jobPost")
      ? JSON.parse(localStorage.getItem("jobPost"))
      : {};
    // update jobPost
    console.log(jobPost)
    if (jobPost?.idHardSkills?.length) {
      jobPost.hardSkills = [];
      jobPost.idHardSkills.map((skill) => {
        let skillToPush = {};
        skillToPush.score = skill.score;
        skillToPush.skill = skill.idHardSkill.skill;
        skillToPush.code = skill.idHardSkill.code;
        skillToPush.idHardSkill = skill.idHardSkill._id;
        jobPost.hardSkills.push(skillToPush);
      });
    }
    if (jobPost?.idSoftSkills?.length) {
      jobPost.softSkills = [];

      jobPost.idSoftSkills.map((skill) => {
        let skillToPush = {};
        skillToPush.score = skill.score;
        skillToPush.soft_skill_fr = skill.idSoftSkill.soft_skill_fr;
        skillToPush.soft_skill_en = skill.idSoftSkill.soft_skill_en;
        skillToPush.code = skill.idSoftSkill.code;
        skillToPush.idSoftSkill = skill.idSoftSkill._id;
        jobPost.softSkills.push(skillToPush);
      });
    }
    if (jobPost?.idLanguages?.length) {
      jobPost.languages = [];

      jobPost.idLanguages.map((skill) => {
        let skillToPush = {};
        skillToPush.score = skill.score;
        skillToPush.language_fr = skill.idLanguage.language_fr;
        skillToPush.language_en = skill.idLanguage.language_en;
        skillToPush.code = skill.idLanguage.code;
        skillToPush.idLanguage = skill.idLanguage._id;
        jobPost.languages.push(skillToPush);
      });
    }
    if (jobPost?.requestedSkills?.length) {
      jobPost.requestedSkills.map((skill) => {
        jobPost.hardSkills = jobPost.hardSkills ?? [];
        jobPost.softSkills = jobPost.softSkills ?? [];
        jobPost.languages = jobPost.languages ?? [];
        let skillToPush = {};
        skillToPush.score = 0.2;
        if (skill.feature == 0) {
          skillToPush.skill = skill.skill;
          skillToPush.feature = 0;
          jobPost.hardSkills.push(skillToPush);
        } else if (skill.feature == 1) {
          skillToPush.soft_skill_fr = skill.soft_skill_fr;
          skillToPush.soft_skill_en = skill.soft_skill_en;
          skillToPush.feature = 1;
          jobPost.softSkills.push(skillToPush);
        } else if (skill.feature == 2) {
          skillToPush.language_fr = skill.language_fr;
          skillToPush.language_en = skill.language_en;
          skillToPush.feature = 2;
          jobPost.languages.push(skillToPush);
        }
      });
    }
    this.data = React.createRef({});
    
    // jobPost.idJobPostDescription
    //    jobPost.idJobPostDescription.description
    //   : "",
    // jobPost.idJobPostRequestedProfile
    //    jobPost.idJobPostRequestedProfile.description
    //   : "",
    this.data.current = {
      id: jobPost._id ? jobPost._id : false,
      _id: jobPost._id ? jobPost._id : false,
      title: jobPost.title ? jobPost.title : "",
      description: (jobPost?.idJobPostDescription?.description ==undefined ?((jobPost?.idJobPostDescription?.length >0)
                    ? jobPost?.idJobPostDescription[0]?.description
                    : (jobPost?.idSuggestJobDescription?.length >0)
                      ? (this.props.i18n.language ==="fr") ? jobPost?.idSuggestJobDescription[0]?.descriptionfr :jobPost?.idSuggestJobDescription[0]?.descriptionEn
                      : "")
                      :jobPost?.idJobPostDescription?.description)
                      ,
      requestedProfile: (jobPost?.idJobPostRequestedProfile?.description==undefined ?((jobPost?.idJobPostRequestedProfile?.length >0)
                        ? jobPost?.idJobPostRequestedProfile[0]?.description
                        : (jobPost?.idSuggestJobDescriptionProfil?.length >0)
                          ? (this.props.i18n.language ==="fr") ? jobPost?.idSuggestJobDescriptionProfil[0]?.descriptionprofilfr :jobPost?.idSuggestJobDescriptionProfil[0]?.descriptionprofilEn
                          : "")
                          :jobPost?.idJobPostRequestedProfile?.description),
        id_sugg: jobPost.id_sugg
                ? jobPost.id_sugg
                : (jobPost?.idSuggestJobDescription?.length >0)
                  ? jobPost?.idSuggestJobDescription[0]?.idsuggestionjobtitle
                  :(jobPost?.idSuggestJobDescription?.length >0)
                    ? jobPost?.idSuggestJobDescription[0]?.idsuggestionjobtitle
                    : "",
        sugg_dec: jobPost.sugg_dec
        ? jobPost.sugg_dec
        : (jobPost?.idSuggestJobDescription?.length >0)
          ? (this.props.i18n.language ==="fr") ? jobPost?.idSuggestJobDescription[0]?.descriptionfr :jobPost?.idSuggestJobDescription[0]?.descriptionEn
          : "",
        sugg_desc_prof: jobPost.sugg_desc_prof
        ? jobPost.sugg_desc_prof
        : (jobPost?.idSuggestJobDescriptionProfil?.length >0)
        ? (this.props.i18n.language ==="fr") ? jobPost?.idSuggestJobDescriptionProfil[0]?.descriptionprofilfr :jobPost?.idSuggestJobDescriptionProfil[0]?.descriptionprofilEn
        : "",
      sector: jobPost.sector ? jobPost.sector : "",
      gender: jobPost.gender ? jobPost.gender : 0,
      reference: jobPost.reference ? jobPost.reference : "",
      workType: jobPost.workType
        ? jobPost.workType
        : this.props.t("presentiel"),
      workTime: jobPost.workTime
        ? jobPost.workTime
        : this.props.t("Temps plein"),
      salary: jobPost.salary ? jobPost.salary : "",
      vacancies: jobPost.vacancies ? jobPost.vacancies : 1,
      experience: jobPost.experience ? jobPost.experience : 0,
      educationLevel: jobPost.educationLevel ? jobPost.educationLevel : "Bac",
      contract: jobPost.contract ? jobPost.contract : [],
      startMissionDate: jobPost.startMissionDate
        ? jobPost.startMissionDate
        : null,
      publicationDate: jobPost._id
        ? jobPost.jobPostPublicationDate
        : Date.now(),
      expirationDate: jobPost._id ? jobPost.jobPostExpirationDate : null,
      location: jobPost.location ? jobPost.location : null,
      idResponsible: this.props.idResponsible ? this.props.idResponsible : null,
      softSkills: jobPost.softSkills ? jobPost.softSkills : [],
      hardSkills: jobPost.hardSkills ? jobPost.hardSkills : [],
      languages: jobPost.languages ? jobPost.languages : [],
      skillsWeight: jobPost.skillsWeight ?? 1,
      softSkillsWeight: jobPost.softSkillsWeight ?? 1,
      locationsWeight: jobPost.locationsWeight ?? 1,
      languagesWeight: jobPost.languagesWeight ?? 1,
      defaultWeights: jobPost.defaultWeights ?? true,
      jobTitleWeight: jobPost.jobTitleWeight ?? true,
      experienceWeight: jobPost.experienceWeight ?? false,
      genderWeight: jobPost.genderWeight ?? false,
      handicap: jobPost.handicap ?? false,
    };

    this.state = {
      currentSlide: 0,
      titleError: "",
      descriptionError: "",
      requestedProfileError: "",
      refError: "",
      sectorError: "",
      contractError: "",
      published: true,
      softSkillsError: "",
      hardSkillsError: "",
      languagesError: "",
      locationError: "",
      vacanciesError: "",
      // salary:"",
      update: false,
      access: false,
      loading: false,
      modalVisible: false,
      // vacancies:"",
      // experience:"",
      suggestedSkills: jobPost.suggestedSkills ?? [],
      suggestedSoftSkills: jobPost.suggestedSoftSkills ?? [],
      suggestedLanguages: jobPost.suggestedLanguages ?? [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    let company = this.context.user;
    if (company.role == "responsible") {
      this.setState({ access: true });
    } else if (
      company.description == "" ||
      company.employeesNumber == "" ||
      company.employeesNumber == null ||
      company.sector == "" ||
      company.phone == "" ||
      company.address == ""
    ) {
      Modal.error({
        title: null,
        content: (
          <>
            <div className="d-flex justify-content-center">
              <AsyncImage className="w-100" src={gif} />
            </div>
            <h6 className="  text-dark rounded   ">
              {this.props.t(
                "Vous devez renseigner votre profil pour bénéficier de nos services."
              )}
            </h6>
          </>
        ),
        onOk: () => {
          this.props.history.push("/company/profile");
        },
      });
    } else {
      this.setState({ access: true, published: company.confirmAdmin });
    }
    window.addEventListener("keydown", (e) => {
      if (e.key == "ArrowRight" && this.state.currentSlide < 6) {
        this.next();
      }
      if (e.key == "ArrowLeft" && this.state.currentSlide != 0) {
        this.prev();
      }
      return true;
    });
  }
  getSuggestions = async () => {
    const { title, description, requestedProfile } = this.data.current;
    description.replace(/<[^>]+>/g, "");
    let text = `${title} \n ${description.replace(
      /<[^>]+>/g,
      ""
    )} \n ${requestedProfile.replace(/<[^>]+>/g, "")} `;

    let response = await GetSuggestions(text, this.context.headers);
    if (response) {
      let parsedJobPost = response?.data?.data;
      if (parsedJobPost) {
        const parsedHardSkills = parsedJobPost.skills;
        const parsedSoftSkills = parsedJobPost.soft_skills;
        const parsedLanguages = parsedJobPost.languages;

        let filteredHardSkills = this.context.hardSkillsList.filter((skill) => {
          return parsedHardSkills.includes(skill.similar_skill);
        });
        let filteredSoftSkills = this.context.softSkillsList.filter((skill) => {
          return parsedSoftSkills.includes(skill.other_ss);
        });
        let filteredLanguages = this.context.languagesList.filter((skill) => {
          return (
            parsedLanguages.includes(skill.language_fr) ||
            parsedLanguages.includes(skill.language_en)
          );
        });
        let hardSkills = [];
        let softSkills = [];
        let languages = [];
        //hardskills
        for (let i = 0; i < filteredHardSkills.length; i++) {
          const skill = filteredHardSkills[i];
          let notExist = true;
          for (let j = 0; j < hardSkills.length; j++) {
            const element = hardSkills[j];
            if (element.skill == skill.skill) notExist = false;
          }
          if (notExist) hardSkills.push(skill);
        }
        //softSkills
        for (let i = 0; i < filteredSoftSkills.length; i++) {
          const skill = filteredSoftSkills[i];
          let notExist = true;
          for (let j = 0; j < softSkills.length; j++) {
            const element = softSkills[j];
            if (element.code == skill.code) notExist = false;
          }
          if (notExist) softSkills.push(skill);
        }
        //languages
        for (let i = 0; i < filteredLanguages.length; i++) {
          const skill = filteredLanguages[i];
          let notExist = true;
          for (let j = 0; j < languages.length; j++) {
            const element = languages[j];
            if (element.code == skill.code) notExist = false;
          }
          if (notExist) languages.push(skill);
        }
        let oldHardSkills = this.state.suggestedSkills;
        let oldSoftSkills = this.state.suggestedSoftSkills;
        let oldLanguages = this.state.suggestedLanguages;
        hardSkills.map((skill) => {
          skill.score = 0.2;
          //if founded occ=2 && delete in the old list
          let skillIndex = oldHardSkills.findIndex((s) => s.code == skill.code);
          if (skillIndex != -1) {
            skill.occ = 2;
            oldHardSkills.splice(skillIndex, 1);
          } else skill.occ = 1;
        });
        softSkills.map((skill) => {
          skill.score = 0.2;
          //if founded occ=2 && delete in the old list
          let skillIndex = oldSoftSkills.findIndex((s) => s.code == skill.code);
          if (skillIndex != -1) {
            skill.occ = 2;
            oldSoftSkills.splice(skillIndex, 1);
          } else skill.occ = 1;
        });
        languages.map((skill) => {
          skill.score = 0.2;
          //if founded occ=2 && delete in the old list
          let skillIndex = oldLanguages.findIndex((s) => s.code == skill.code);
          if (skillIndex != -1) {
            skill.occ = 2;
            oldLanguages.splice(skillIndex, 1);
          } else skill.occ = 1;
        });
        this.setState({
          suggestedSkills: [...hardSkills, ...oldHardSkills],
          suggestedSoftSkills: [...softSkills, ...oldSoftSkills],
          suggestedLanguages: [...languages, ...oldLanguages],
        });
      }
    }
  };
  updateView = () => {
    this.setState({ update: !this.state.update });
  };
  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  setTitle = (value) => {
    this.data.current.title = value;
    if (this.state.titleError.length) {
      this.setState({ titleError: "" });
    }
    this.updateView();
  };

  setSkillsWeight = (skillsWeight) => {
    this.data.current.skillsWeight = skillsWeight / 100;
    this.updateView();
  };
  setSoftSkillsWeight = (softSkillsWeight) => {
    this.data.current.softSkillsWeight = softSkillsWeight / 100;
    this.updateView();
  };
  setLocationsWeight = (locationsWeight) => {
    this.data.current.locationsWeight = locationsWeight / 100;
    this.updateView();
  };
  setLanguagesWeight = (languagesWeight) => {
    this.data.current.languagesWeight = languagesWeight / 100;
    this.updateView();
  };
  setDefaultWeights = (defaultWeights) => {
    this.data.current.defaultWeights = !this.data.current.defaultWeights;
    this.updateView();
  };
  setJobTitleWeight = (jobTitleWeight) => {
    this.data.current.jobTitleWeight = !this.data.current.jobTitleWeight;
    this.updateView();
  };

  setExperienceWeight = (experienceWeight) => {
    this.data.current.experienceWeight = !this.data.current.experienceWeight;
    this.updateView();
  };
  setGenderWeight = (genderWeight) => {
    this.data.current.genderWeight = !this.data.current.genderWeight;
    this.updateView();
  };
  setHandicap = (handicap) => {
    this.data.current.handicap = !this.data.current.handicap;
    this.updateView();
  };
  setSuggestedSkills = (suggestedSkills) => {
    this.setState({ suggestedSkills });
  };
  setSuggestedSoftSkills = (suggestedSoftSkills) => {
    this.setState({ suggestedSoftSkills });
  };
  setDescription = (value) => {
    this.data.current.description = value;
    if (this.state.descriptionError.length) {
      this.setState({ descriptionError: "" });
    }
  };
  setRequestedProfile = (value) => {
    this.data.current.requestedProfile = value;
    if (this.state.requestedProfileError.length) {
      this.setState({ requestedProfileError: "" });
    }
  };
  setIdSygg = (item) => {
    this.data.current.id_sugg = item;
  };
  setSuggDesc = (item) => {
    this.data.current.sugg_dec = item;
  };
  setSuggDescProf = (item) => {
    this.data.current.sugg_desc_prof = item;
  };
  setReference = (e) => {
    this.data.current.reference = e.target.value;
  };

  setSalary = (e) => {
    this.data.current.salary = e.target.value;
  };
  setVacancies = (e) => {
    this.data.current.vacancies = e.target.value;
  };
  setExperience = (e) => {
    this.data.current.experience = e;
  };
  setStartMissionDate = (value) => {
    this.data.current.startMissionDate = value;
  };
  setExpirationDate = (value) => {
    this.data.current.expirationDate = value;
    if (this.state.expirationDateError.length) {
      this.setState({ expirationDateError: "" });
    }
  };
  setPublicationDate = (value) => {
    this.data.current.publicationDate = value;
  };
  setLocation = (value) => {
    this.data.current.location = value;
    if (this.state.locationError.length) {
      this.setState({ locationError: "" });
    }
  };
  setSoftSkills = (value) => {
    // alert(`${value}`)
    this.data.current.softSkills = value;
    this.setState({ update: !this.state.update });
    if (this.state.softSkillsError.length) {
      this.setState({ softSkillsError: "" });
    }
  };
  setHardSkills = (value) => {
    this.data.current.hardSkills = value;
    this.setState({ update: !this.state.update });
    if (this.state.hardSkillsError.length) {
      this.setState({ hardSkillsError: "" });
    }
  };

  setLanguages = (value) => {
    this.data.current.languages = value;
    this.setState({ update: !this.state.update });
    if (this.state.languagesError.length) {
      this.setState({ languagesError: "" });
    }
  };
  setResponsible = (value) => {
    this.data.current.idResponsible = value;
    //  this.setState({ idResponsible: value })
  };

  setSector = (value) => {
    this.data.current.sector = value;
    if (this.state.sectorError.length) {
      this.setState({ sectorError: "" });
    }
  };
  setGender = (value) => {
    this.data.current.gender = value;
  };
  setContract = (value) => {
    this.data.current.contract = value;
    if (this.state.contractError.length) {
      this.setState({ contractError: "" });
    }
  };
  setEducationLevel = (value) => {
    this.data.current.educationLevel = value;
  };
  setWorkTime = (value) => {
    this.data.current.workTime = value;
  };
  setWorkType = (value) => {
    this.data.current.workType = value;
  };
  // (value)=> {
  // this.setState({sector: value})  }
  next = () => {
    if (this.state.currentSlide == 0) {
      this.getSuggestions();
    }
    this.setState({ currentSlide: this.state.currentSlide + 1 });
    let jobPost = this.data.current;
    jobPost.idJobPostDescription = { description: jobPost.description };
    jobPost.idJobPostRequestedProfile = {
      description: jobPost.requestedProfile,
    };
    // jobPost.location =jobPost.location&& {
    //   score: jobPost.location.score,
    //   idLocation: { ...jobPost.location },
    // };
    localStorage.setItem("jobPost", JSON.stringify(jobPost));
  };
  prev = () => {
    this.setState({ currentSlide: this.state.currentSlide - 1 });
  };

  onChange = (a, b, c) => {};
  validation = () => {
    let isvalid = true;

    let title = this.data.current.title;
    let description = this.data.current.description;
    let requestedProfile = this.data.current.requestedProfile;
    let sector = this.data.current.sector;
    // let reference = this.data.current.reference
    let salary = this.data.current.salary;
    let vacancies = this.data.current.vacancies;
    let experience = this.data.current.experience;
    let contract = this.data.current.contract;
    let location = this.data.current.location;
    let softSkills = this.data.current.softSkills;
    let hardSkills = this.data.current.hardSkills;
    let languages = this.data.current.languages;
    let expirationDate = this.data.current.expirationDate;

    //ref validation
    // if (reference == "") {
    //   isvalid = false
    //   this.setState({ refError: "champ obligatoire" })
    //   this.setState({ currentSlide:0});

    // }
    // else
    //   this.setState({ refError: "" })

    //salary validation
    //   if(salary=="") {
    //     isvalid=false
    //     this.setState({salaryError:"champ obligatoire"})
    //   }
    // else
    // this.setState({salaryError:""})

    //experience validation
    //   if(experience=="") {
    //     isvalid=false
    //     this.setState({experienceError:"champ obligatoire"})
    //   }
    // else
    // this.setState({experienceError:""})

    //languages validation
    if (languages.length == 0) {
      isvalid = false;
      this.setState({
        languagesError: this.props.t(
          "veuillez ajouter au moins une compétence"
        ),
      });
      this.setState({ currentSlide: 5 });
    } else if (languages.length > 5) {
      isvalid = false;
      this.setState({
        languagesError: this.props.t("vous pouvez avoir maximum 5 langues"),
      });
      this.setState({ currentSlide: 5 });
    } else this.setState({ languagesError: "" });

    //softSkills validation
    if (softSkills.length == 0) {
      isvalid = false;
      this.setState({
        softSkillsError: this.props.t(
          "veuillez ajouter au moins une compétence"
        ),
      });
      this.setState({ currentSlide: 4 });
    } else if (softSkills.length > 5) {
      isvalid = false;
      this.setState({
        softSkillsError: this.props.t(
          "vous pouvez avoir maximum 5 compétences"
        ),
      });
      this.setState({ currentSlide: 4 });
    } else this.setState({ softSkillsError: "" });

    //hardSkills validation
    if (hardSkills.length == 0) {
      isvalid = false;
      this.setState({
        hardSkillsError: this.props.t(
          "veuillez ajouter au moins une compétence"
        ),
      });
      this.setState({ currentSlide: 3 });
    } else if (hardSkills.length > 5) {
      isvalid = false;
      this.setState({
        hardSkillsError: this.props.t(
          "vous pouvez avoir maximum 5 compétences"
        ),
      });
      this.setState({ currentSlide: 3 });
    } else this.setState({ hardSkillsError: "" });

    // vacancies validation
    let max =
      this.context.myFunctionalities.find(
        (functionality) => functionality.fType == 1
      )?.qte ?? 1;
    if (vacancies == "" || vacancies == null) {
      isvalid = false;
      this.setState({ vacanciesError: this.props.t("champ obligatoire") });
      this.setState({ currentSlide: 2 });
    } else if (vacancies > max) {
      isvalid = false;
      this.setState({ vacanciesError: "maximum " + max });
      this.setState({ currentSlide: 2 });
    } else this.setState({ vacanciesError: "" });

    //expirationDate validation
    if (expirationDate == null) {
      isvalid = false;
      this.setState({ expirationDateError: this.props.t("champ obligatoire") });
      this.setState({ currentSlide: 2 });
    } else this.setState({ expirationDateError: "" });

    //sector validation
    if (sector == "") {
      isvalid = false;
      this.setState({ sectorError: this.props.t("champ obligatoire") });
      this.setState({ currentSlide: 1 });
    } else this.setState({ sectorError: "" });

    //contract validation
    if (!contract.length) {
      isvalid = false;
      this.setState({ contractError: this.props.t("champ obligatoire") });
      this.setState({ currentSlide: 1 });
    } else this.setState({ contractError: "" });

    //location validation
    if (location == null) {
      isvalid = false;
      this.setState({ locationError: this.props.t("champ obligatoire") });
      this.setState({ currentSlide: 1 });
    } else this.setState({ locationError: "" });

    //title validation
    if (title.length < 5) {
      isvalid = false;
      this.setState({ titleError: this.props.t("champ obligatoire") });
      this.setState({ currentSlide: 0 });
    } else this.setState({ titleError: "" });

    //description validation
    if (description == "") {
      isvalid = false;
      this.setState({ descriptionError: this.props.t("champ obligatoire") });
      this.setState({ currentSlide: 0 });
    } else this.setState({ descriptionError: "" });

    //requestedProfile validation
    if (requestedProfile == "") {
      isvalid = false;
      this.setState({
        requestedProfileError: this.props.t("champ obligatoire"),
      });
      this.setState({ currentSlide: 0 });
    } else this.setState({ requestedProfileError: "" });

    return isvalid;
  };

  handleSubmit(event) {
    event.preventDefault();
    let softSkills = this.data.current.softSkills;
    let languages = this.data.current.languages;
    let hardSkills = this.data.current.hardSkills;

    languages.map((skill) => {
      if (skill._id) delete skill._id;
    });
    softSkills.map((skill) => {
      if (skill._id) delete skill._id;
    });
    hardSkills.map((skill) => {
      if (skill._id) delete skill._id;
    });

    if (this.validation()) {
      this.setState({ loading: true });
      let requestedSkills = [...languages, ...softSkills, ...hardSkills];
      requestedSkills = requestedSkills.filter((skill) => !skill.code);
      languages = languages.filter((skill) => skill.code);
      softSkills = softSkills.filter((skill) => skill.code);
      hardSkills = hardSkills.filter((skill) => skill.code);
      let qte = 5;
      qte = this.context.myFunctionalities.find(
        (functionality) => functionality.fType == 3
      )?.qte;

      if (this.data.current.id) {
        UpdateJobPost(
          {
            id: this.data.current.id,
            title: this.data.current.title,
            description: this.data.current.description,
            requestedProfile: this.data.current.requestedProfile,
            sector: this.data.current.sector,
            gender: this.data.current.gender,
            workType: this.data.current.workType,
            workTime: this.data.current.workTime,
            salary: this.data.current.salary,
            vacancies: this.data.current.vacancies,
            experience: this.data.current.experience,
            educationLevel: this.data.current.educationLevel,
            contract: this.data.current.contract,
            startMissionDate: this.data.current.startMissionDate,
            publicationDate: this.data.current.publicationDate,
            expirationDate: this.data.current.expirationDate,
            location: this.data.current.location,
            idResponsible: this.data.current.idResponsible,
            softSkills: softSkills,
            hardSkills: hardSkills,
            languages: languages,
            requestedSkills: requestedSkills,
            skillsWeight: this.data.current.skillsWeight,
            softSkillsWeight: this.data.current.softSkillsWeight,
            locationsWeight: this.data.current.locationsWeight,
            languagesWeight: this.data.current.languagesWeight,
            defaultWeights: this.data.current.defaultWeights,
            jobTitleWeight: this.data.current.jobTitleWeight,
            experienceWeight: this.data.current.experienceWeight,
            genderWeight: this.data.current.genderWeight,
            handicap: this.data.current.handicap,
            companyName: this.context.companyName,
            companyImage: this.context.user.image,
            reference: this.data.current.reference,
            maxDisplayCandidates: qte,
            idSugg:this.data.current.id_sugg,
            suggDesc: this.data.current.sugg_dec,
            suggDescProf:this.data.current.sugg_desc_prof,
          },
          this.context.headers
        );
        this.props.handleInvalidate();
        setTimeout(() => {
          this.props.history.push("/" + this.context.role + "/manageJobPosts");
          localStorage.removeItem("jobPost");
        }, 1000);
      } else {
        let addJP = this.context.myFunctionalities.find((f) => f.fType == 0);
        if (!addJP || addJP.qte <= 0) {
          let jobPost = this.data.current;
          jobPost.idJobPostDescription = { description: jobPost.description };
          jobPost.idJobPostRequestedProfile = {
            description: jobPost.requestedProfile,
          };
          // jobPost.location = {
          //   score: jobPost.location.score,
          //   idLocation: { ...jobPost.location },
          // };
          localStorage.setItem("jobPost", JSON.stringify(jobPost));
          Modal.error({
            title: null,
            content: (
              <>
                <div className="d-flex justify-content-center">
                  <AsyncImage className="w-100" src={gif} />
                </div>
                <h6 className="  text-dark rounded   ">
                  {this.props.t(
                    "Vous devez acheter un forfait pour bénéficier de nos services."
                  )}
                </h6>
              </>
            ),
            onOk: () => {
              this.props.history.push("/" + this.context.role + "/pricing");
            },
          });
        } else {
          AddJobPostService(
            {
              id: new ObjectID().toString(),

              title: this.data.current.title,
              description: this.data.current.description,
              requestedProfile: this.data.current.requestedProfile,
              sector: this.data.current.sector,
              gender: this.data.current.gender,
              reference: this.data.current.reference,
              workType: this.data.current.workType,
              workTime: this.data.current.workTime,
              salary: this.data.current.salary,
              vacancies: this.data.current.vacancies,
              experience: this.data.current.experience,
              educationLevel: this.data.current.educationLevel,
              contract: this.data.current.contract,
              startMissionDate: this.data.current.startMissionDate,
              publicationDate: this.data.current.publicationDate,
              expirationDate: this.data.current.expirationDate,
              location: this.data.current.location,
              idResponsible: this.data.current.idResponsible,
              softSkills: softSkills,
              hardSkills: hardSkills,
              languages: languages,
              requestedSkills: requestedSkills,
              published: this.state.published,
              idFunctionality: this.context.fonctionnalites.find(
                (functionality) => functionality.fType == 0
              )?._id,
              skillsWeight: this.data.current.skillsWeight,
              softSkillsWeight: this.data.current.softSkillsWeight,
              locationsWeight: this.data.current.locationsWeight,
              languagesWeight: this.data.current.languagesWeight,
              defaultWeights: this.data.current.defaultWeights,
              jobTitleWeight: this.data.current.jobTitleWeight,
              experienceWeight: this.data.current.experienceWeight,
              genderWeight: this.data.current.genderWeight,
              handicap: this.data.current.handicap,
              companyName: this.context.companyName,
              companyImage: this.context.user.image,
              maxDisplayCandidates: qte,
              idSugg:this.data.current.id_sugg,
            suggDesc: this.data.current.sugg_dec,
            suggDescProf:this.data.current.sugg_desc_prof,

            },
            this.context.headers,
            this.context.role
          ).then(()=>
            {this.setModalVisible(false);
            // this.props.history.push("/" + this.context.role + "/manageJobPosts");
            }
            ) ;
          this.context.decrementMyFunctionalities(0);

          // this.AddNewJobPost()
          if (!this.state.published) {
            Modal.info({
              title: null,
              content: (
                <div>
                  {/* <AsyncImage 
       className="w-100"
         src={gif} /> */}
                  <p>
                    {this.props.t(
                      "votre offre sera publiée dès l'activation de votre compte par <b>Rec-INOV</b> dans maximum 48h"
                    )}
                  </p>
                </div>
              ),

              onOk() {},
            });
          }

          this.props.handleInvalidate();
          // setTimeout(() => {
          //   this.props.history.push(
          //     "/" + this.context.role + "/manageJobPosts"
          //   );
          //   // localStorage.removeItem("jobPost");
          // }, 2000);
        }
      }
    }
  }
  AddNewJobPost = () => {
    localStorage.removeItem("jobPost");
    this.data.current = {
      id: false,
      title: "",
      description: "",
      requestedProfile: "",
      sector: "",
      gender: this.props.t("Homme/Femme"),
      reference: "",
      workType: this.props.t("presentiel"),
      workTime: this.props.t("Temps plein"),
      salary: "",
      vacancies: "",
      experience: 0,
      educationLevel: "Bac",
      contract: [],
      startMissionDate: null,
      publicationDate: Date.now(),
      expirationDate: moment(this.context.serverTime).add(1, "month").toDate(),
      location: null,
      idResponsible: "",
      softSkills: [],
      hardSkills: [],
      languages: [],
      id_sugg: "",
      sugg_dec: "",
      sugg_desc_prof: "",

    };

    this.setState({
      currentSlide: 0,
      titleError: "",
      descriptionError: "",
      requestedProfileError: "",
      expirationDateError: "",
      sectorError: "",
      contractError: "",
      softSkillsError: "",
      hardSkillsError: "",
      languagesError: "",
      locationError: "",
      // salary:"",
      update: false,
      // vacancies:"",
      // experience:"",
      // idResponsible:"",
    });
    this.setState({ currentSlide: 0 });
    this.props.history.push("/" + this.context.role + "/manageJobPosts");
  };

  render() {
    let visible =
      (this.state.currentSlide == 3 &&
        this.data.current.hardSkills.length > 5) ||
      (this.state.currentSlide == 4 &&
        this.data.current.softSkills.length > 5) ||
      (this.state.currentSlide == 5 && this.data.current.languages.length > 5);

    const { t } = this.props;

    return (
      <div className="modifiedTag">
        <div className="d-flex justify-content-between">
          <div className="titrePage fs-4">
            {" "}
            {this.state.currentSlide == 0 &&
              (this.data.current.id
                ? t("Modifier votre Offre : informations générales de l'offre")
                : t("Ajouter votre Offre : informations générales de l'offre"))}
            {this.state.currentSlide == 1 && t("Détails de l'offre")}
            {this.state.currentSlide == 2 && t("Options de soumission")}
            {this.state.currentSlide == 3 &&
              t("Compétences techniques (hard skills)")}
            {this.state.currentSlide == 4 &&
              t("Compétences personnelles (soft skills)")}
            {this.state.currentSlide == 5 && t("Langues")}
            {this.state.currentSlide == 6 &&
              t("Précisez vos critères de sélection")}
          </div>
          <div 
          // style={{ zIndex: 2 }} 
          className="">
            {this.data.current.id ? (
              <div
                onClick={this.AddNewJobPost}
                className="btn btn-primary rounded-pill m-2"
              >
                <PlusOutlined
                  className=" border  rounded-circle plusButton "
                  // style={{fontSize: "1.4rem"}}
                />
                <b className="m-1 d-none d-lg-inline">
                  {t("Ajouter nouveau Offre")}
                </b>
              </div>
            ) : (
              <button
                onClick={() => this.props.history.push("/company/uploadOffre")}
                className="btn btn-primary  rounded-pill m-2"
              >
                <i className=" m-2">
                  <UploadIcon fill="white" width="1.5rem" />
                </i>
                <b className="m-1 d-none d-lg-inline">{t("Upload offre")}</b>
              </button>
            )}
          </div>
        </div>
        {!this.context.orders.length ? (
          <div className="d-flex justify-content-center">
            <h6 className="w-100 m-2 p-4  text-dark rounded border bg-red ">
              {" "}
              <span className="m-1 icons12 ">
                {" "}
                <WarningOutlined />
              </span>
              {t(
                "Bonjour ! Pour bénéficier de nos services, vous devez acheter un forfait qui vous intéresse ou bien activer notre forfait GRATUIT."
              )}
              <Link to={"/" + this.context.role + "/pricing"}>
                {" "}
                {t("voir nos forfaits")}
              </Link>
            </h6>
          </div>
        ) : (
          ""
        )}
        {this.data.current.hardSkills.length > 5 &&
          this.state.currentSlide == 3 && (
            <DragDropComponent
              skills={this.data.current.hardSkills}
              feature={0}
              updateSkills={this.setHardSkills}
            />
          )}
        {this.data.current.softSkills.length > 5 &&
          this.state.currentSlide == 4 && (
            <DragDropComponent
              skills={this.data.current.softSkills}
              feature={1}
              updateSkills={this.setSoftSkills}
            />
          )}
        {this.data.current.languages.length > 5 &&
          this.state.currentSlide == 5 && (
            <DragDropComponent
              skills={this.data.current.languages}
              feature={2}
              updateSkills={this.setLanguages}
            />
          )}

        {this.state.currentSlide == 0 && (
          <div className="overflow-y  ">
            <Form1
              id={this.data.current.id}
              next={this.next}
              prev={this.prev}
              title={this.data.current.title}
              setTitle={this.setTitle}
              setSuggestedSkills={this.setSuggestedSkills}
              setSuggestedSoftSkills={this.setSuggestedSoftSkills}
              suggestedSkills={this.state.suggestedSkills}
              suggestedSoftSkills={this.state.suggestedSoftSkills}
              reference={this.data.current.reference}
              setReference={this.setReference}
              description={this.data.current.description}
              setDescription={this.setDescription}
              requestedProfile={this.data.current.requestedProfile}
              setRequestedProfile={this.setRequestedProfile}
              titleError={this.state.titleError}
              descriptionError={this.state.descriptionError}
              requestedProfileError={this.state.requestedProfileError}
              setIdSygg={this.setIdSygg}
              setSuggDesc={this.setSuggDesc}
              setSuggDescProf={this.setSuggDescProf}
              // refError={this.state.refError}
            />
          </div>
        )}

        {this.state.currentSlide == 1 && (
          <div className="overflow-y ">
            <Form2
              next={this.next}
              prev={this.prev}
              sector={this.data.current.sector}
              setSector={this.setSector}
              gender={this.data.current.gender}
              setGender={this.setGender}
              workTime={this.data.current.workTime}
              setWorkTime={this.setWorkTime}
              workType={this.data.current.workType}
              setWorkType={this.setWorkType}
              contract={this.data.current.contract}
              setContract={this.setContract}
              educationLevel={this.data.current.educationLevel}
              setEducationLevel={this.setEducationLevel}
              location={this.data.current.location}
              setLocation={this.setLocation}
              sectorError={this.state.sectorError}
              contractError={this.state.contractError}
              locationError={this.state.locationError}
            />
          </div>
        )}

        {this.state.currentSlide == 2 && (
          <div className="overflow-y ">
            <Form3
              next={this.next}
              prev={this.prev}
              startMissionDate={this.data.current.startMissionDate}
              setStartMissionDate={this.setStartMissionDate}
              expirationDate={this.data.current.expirationDate}
              setExpirationDate={this.setExpirationDate}
              expirationDateError={this.state.expirationDateError}
              publicationDate={this.data.current.publicationDate}
              setPublicationDate={this.setPublicationDate}
              salary={this.data.current.salary}
              setSalary={this.setSalary}
              vacancies={this.data.current.vacancies}
              vacanciesError={this.state.vacanciesError}
              setVacancies={this.setVacancies}
              experience={this.data.current.experience}
              setExperience={this.setExperience}
            />
          </div>
        )}

        {this.state.currentSlide == 3 && (
          <div className="overflow-y ">
            {this.data.current.hardSkills.length <= 5 && (
              <>
                {" "}
                <HardSkills
                  suggestedSkills={this.state.suggestedSkills}
                  hardSkills={this.data.current.hardSkills}
                  setHardSkills={this.setHardSkills}
                />
                <p className="text-danger">
                  <small>{this.state.hardSkillsError}</small>
                </p>
              </>
            )}
          </div>
        )}

        {this.state.currentSlide == 4 && (
          <div className="overflow-y">
            {this.data.current.softSkills.length <= 5 && (
              <>
                <SoftSkills
                  suggestedSoftSkills={this.state.suggestedSoftSkills}
                  softSkills={this.data.current.softSkills}
                  setSoftSkills={this.setSoftSkills}
                />
                <p className="text-danger">
                  <small>{this.state.softSkillsError}</small>
                </p>
              </>
            )}
          </div>
        )}

        {this.state.currentSlide == 5 && (
          <div className="overflow-y">
            {this.data.current.languages.length <= 5 && (
              <>
                <Languages
                  suggestedLanguages={this.state.suggestedLanguages}
                  languages={this.data.current.languages}
                  setLanguages={this.setLanguages}
                />
                <p className="text-danger">
                  <small>{this.state.languagesError}</small>
                </p>
              </>
            )}
          </div>
        )}

        {this.state.currentSlide == 6 && (
          <div className="overflow-y">
            <Weights
              //  variables with setters
              skillsWeight={this.data.current.skillsWeight}
              softSkillsWeight={this.data.current.softSkillsWeight}
              locationsWeight={this.data.current.locationsWeight}
              languagesWeight={this.data.current.languagesWeight}
              defaultWeights={this.data.current.defaultWeights}
              jobTitleWeight={this.data.current.jobTitleWeight}
              experienceWeight={this.data.current.experienceWeight}
              genderWeight={this.data.current.genderWeight}
              handicap={this.data.current.handicap}
              setSkillsWeight={this.setSkillsWeight}
              setSoftSkillsWeight={this.setSoftSkillsWeight}
              setLocationsWeight={this.setLocationsWeight}
              setLanguagesWeight={this.setLanguagesWeight}
              setDefaultWeights={this.setDefaultWeights}
              setJobTitleWeight={this.setJobTitleWeight}
              setExperienceWeight={this.setExperienceWeight}
              setGenderWeight={this.setGenderWeight}
              setHandicap={this.setHandicap}
            />
          </div>
        )}

        {this.state.currentSlide + 1 + "/7"}
        <div
          className="right d-flex"
          //  style={{float: 'right'}}
          style={{ direction: "ltr"}}
        >
          {this.state.currentSlide != 0 && (
            <div
              onClick={this.prev}
              className="btn btn-primary m-1  d-flex  align-items-center"
            >
              <LeftOutlined />
            </div>
          )}
          {this.state.currentSlide < 6 ? (
            <div
              onClick={this.next}
              className="btn btn-primary m-1  d-flex  align-items-center"
            >
              <RightOutlined />
            </div>
          ) : (
            <button
              disabled={this.state.loading}
              onClick={() => {
                if (this.validation()) this.setModalVisible(true);
              }}
              className="btn btn-primary m-1"
            >
              {t("Enregistrer")}
            </button>
          )}
        </div>
        {/* <div onClick={this.prev}
           className="btn btn-primary" >
            < LeftOutlined   />
             </div>
                <div onClick={this.next}
           className="btn btn-primary" >
           <RightOutlined   />
            </div> */}
        <NoticeForm userId={this.props.userId} />
        {this.state.modalVisible && (
          <Modal
            title={
              <h3 className=" m-1 text-primary">{t("Détails du poste")}</h3>
            }
            className="m-5 w-75"
            centered
            closeIcon={
              <i className="mt-5 ">
                <CloseOutlined className="m-3" />
              </i>
            }
            visible={this.state.modalVisible}
            onOk={() => {
              if (this.validation()) this.handleSubmit();
            }}
            onCancel={() => this.setModalVisible(false)}
            footer={
              <div className="mb-5">
                <input
                  type="button"
                  style={{ zIndex: "99999" }}
                  className=" btn btn-secondary p-1 rounded m-1"
                  value="Modifier"
                  onClick={() => {
                    this.setModalVisible(false);
                  }}
                />
                <Button
                  style={{ zIndex: "99999" }}
                  type="primary"
                  loading={this.state.loading}
                  onClick={this.handleSubmit}
                >
                  {t("Enregistrer")}
                </Button>
                {/* <input
                type="button"
                className=" btn btn-primary p-1 rounded m-1 "
                value="Enregistrer"
                onClick={this.handleSubmit}/> */}
              </div>
            }
          >
            <div className="">
              <JobPostPreview
                company={
                  this.context.role == "company"
                    ? this.context.user
                    : this.context.user.company
                }
                jobPost={{
                  ...this.data.current,
                  idHardSkills: this.data.current.hardSkills.map((skill) => {
                    let s = { ...skill };
                    s.idHardSkill = skill;
                    return s;
                  }),
                  idSoftSkills: this.data.current.softSkills.map((skill) => {
                    let s = { ...skill };
                    s.idSoftSkill = skill;
                    return s;
                  }),
                  idLanguages: this.data.current.languages.map((skill) => {
                    let s = { ...skill };
                    s.idLanguage = skill;
                    return s;
                  }),
                  requestedSkills: [],
                }}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
export default withTranslation()(withRouter(AddJobPost));
