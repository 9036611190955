const formatTime = (timeInSeconds) => {
    const minutes=Math.floor(timeInSeconds/60)
    const seconds=timeInSeconds%60
    let time=""
    if(minutes){
      time=minutes
      if(minutes==1){
        time=time+" Mn "
      }else time=time+" Mn "
    }
    if(seconds) time= time+seconds.toFixed(1)+" Sc"
    if(!minutes&&!seconds) time="0 Sc"
    return time
};

export default formatTime;
