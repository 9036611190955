import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
class HardSkillsEColor extends Component {
    constructor(props){
        super(props)
      }
  render() {
    let i=-1
    let list=this?.props?.hardSkills?.map(hardSkill=>{
      i++
      return( 
                <div className="tech-l" key={i}>{hardSkill.skill}</div>

        )
    })
    return (
        <div className="cv-body-competence" style={{border: `1px solid ${this?.props?.to2}`}}>
            <div className="cv-r-top">
            <div className="cv-b-comp" style={{color: this?.props?.to1}}>{this.props.t("Compétences techniques")}</div>                
            </div>
            <div className="tech-comp">
            {list}
            </div>

        </div>
    )
  }
}
export default withTranslation()( HardSkillsEColor )