import { Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { GetNotFixedInterviews } from '../../services/api/interview/GetNotFixedInterviews';
import decrypt from '../../utils/decrypt';
import FileIcon from '../icons/FileIcon';

import { AppContext } from '../../routes/AppContext';
import AsyncImage from '../AsyncImage';

import { JobPostContext } from '../dashboard/jobPostDashboard/JobPostContext';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';

const { Column } = Table;

 function NotFixedInterviews(props) {
  const { role,envVar,headers } = useContext(AppContext);
  const {jobPost,applications} = useContext(JobPostContext);
  const { t } = useTranslation();

  const history = useHistory();

    const [interviews, setInterviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const get = async () => {
        let response = await GetNotFixedInterviews(
          { id: jobPost._id },
          headers
        );
    if(response){
        let data = JSON.parse(decrypt(response.data));
        setInterviews(data);
        setLoading(false)}
      };
    
      useEffect(() => {
        jobPost._id
          ? get()
          : history.push("/" + role + "/manageJobPosts");
      }, []);
    
  return (
    <div>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
         <Table
         loading={loading}
          className="m-2"
          pagination={true}
          dataSource={interviews}
          rowKey={(record) => {
            let k = `{"${record.idCandidate}":"${record.candidate[0].email}"}`;
            return k;
          }}
        >
          <Column
            align="left"
            sorter={(a, b) => a.candidate[0].name.localeCompare(b.candidate[0].name)}
            title={t("Nom")}
            dataIndex="name"
            key="name"
            render={(text, interview, index) => {
              let candidate = interview.candidate[0];
              return (
                <div
                role="button"
                onClick={() => {
                  let application=applications.find(app=>app.idCandidate==candidate._id)
                  if(application.cvFile){
                    candidate.cvFile=application.cvFile
                    candidate.uploaded=true
                  }
                  props.setCandidate(candidate)
                  props.setModalVisible1(true)}} className=" d-flex justify-content-start align-items-center">
                  <AsyncImage
                    className="logo-m rounded me-1"
                    src={
                      envVar.REACT_APP_CLIENT_URL +
                      "/candidate/images/" +
                      candidate.image
                    }
                  />
                  {candidate.name}
                </div>
              );
            }}
          />

 

          {/* <Column
            align="center"
            title="Actions"
            dataIndex="Actions"
            key="Actions"
            render={(text, interview, {t(index)}) => {
              let candidate = interview.candidate[0];
              return (
                <div className="">
                  <i
                    role="button"
                    onClick={() => {
                      props.setCandidate(candidate)
                      props.setModalVisible1(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <FileIcon width="1.2rem" fill="#676ee7" />
                  </i>
           
                </div>
              );
            }}
          /> */}
    
        </Table>
        </ConfigProvider>
    </div>
  )
}
export default withRouter(NotFixedInterviews);

