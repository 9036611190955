import React, { useContext } from 'react'
import CandidateCv from '../../components/cv/CandidateCv'
import Loading from '../../components/Loading';
import { AppContext } from '../../routes/AppContext';
export default function CandidateCvView(props) {
  const {load} = useContext(AppContext);
    return (
        <div className='DivCarousel'>
            {load?<Loading/>:<CandidateCv userId={props.userId} />}
        </div>
    )
}
