import React from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import JobPostTestingContainer from '../../components/jobPostTesting/JobPostTestingContainer'

 function CandidateJobPostTestingView(props) {
  const history = useHistory()
  let idTest=props?.location?.state?.idTest
    // props?.location?.state?.idTest?console.log():history.push('/candidate/jobPosts')
  return (
    <><JobPostTestingContainer method={props.method} userId={props.userId} idCompany={props?.location?.state?.idCompany} idTest={idTest} idJobPost={props?.location?.state?.idJobPost}/></>
  )
}
export default withRouter(CandidateJobPostTestingView)