import React from "react";
import { useDropzone } from "react-dropzone";
const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};

const Dropzone = ({ onDrop, accept,image }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  return (
    <div className="d-flex justify-content-center w-50 mx-auto" >
    <div className={getClassName("dropzone", isDragActive)} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center " style={{borderStyle:"dashed"}} >
        {isDragActive ? (
            <p className="zoom-1 dropzone-content  border-primary border-dashed ">

            <img  className="    my-2   rounded  w-100" alt="recinov"src={image}
            // style={{maxWidth:"90%"}}
            />

          </p>
        ) : (
          <p className="dropzone-content  border-primary border-dashed ">

            <img  className="    my-2   rounded w-100 " alt="recinov"src={image}
            // style={{maxWidth:"90%"}}
            />

          </p>
        )}
      </div>
    </div></div>
  );
};

export default Dropzone;