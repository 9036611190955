import axios from "../../../interceptors/axios";
export const GetCandidateInterviews = async (data, headers) => {
  try {
    let res = await axios.get("/api/interview/get/candidate", {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
