import React from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import JobPostPreview from '../../components/manageJobPosts/JobPostPreview'

 function CandidateJobPostView(props) {
  const history = useHistory()
    props.location.state.jobPost?console.log():history.push('/candidate/jobPosts')
    return (
        <>
            
            <JobPostPreview userId={props.userId} company={props.location.state.jobPost.idCompany} jobPost={props.location.state.jobPost} appli={props.location.state.appli} />
        </>
    )
}
export default withRouter(CandidateJobPostView)