import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AnswerComponent from './AnswerComponent'
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

export default function QuestionComponent(props) {
  useEffect(() => {
    hljs.highlightAll();
  });
  const shuffle=useCallback((a)=> {
    for (let i = a?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  },[])

  const answers=useMemo(()=>{return shuffle(props.question.answers)}
,[])
 const [selected,setSelected]=useState([])
  const setAnswer=(val)=> {
    props.setAnswer(val)
    setSelected(val)
  }
  function onChange(val) {
    // if(val/1000<countDown)
    // {
    // setCountDown(val/1000)
    // }
  }
  let i=-1
    let list=answers.map(a=>{
      i++
      return(
        <AnswerComponent selected={selected} setSelected={setAnswer} answer={a} key={i} />
    )})
 
  return (
      <div className='text-center ' >   <div  dangerouslySetInnerHTML={{
        __html: props.question.question_fr,
      }} ></div> 
       {/* <h1>{props.question.question_fr}</h1> */}
  {/* <CountDown countDown={60} /> */}
    <div className='m-2' >{list}</div>
    </div>

  )
}
