import React from "react";
import { useTranslation } from "react-i18next";

export default function InterviewsNav(props) {
  const { t } = useTranslation();

  return (
    <div className="row mx-2">
      <div className="w-50">
        <div  onClick={()=>props.setShowFixedInterviews(true)} className={props.showFixedInterviews?"btn btn-primary rounded m-2 w-100":"btn btn-outline-primary rounded m-2 w-100"} >
        {t("Entretiens confirmé")}
        </div>
        </div>
      <div className="w-50">
        <div onClick={()=>props.setShowFixedInterviews(false)} className={props.showFixedInterviews?"btn btn-outline-primary rounded m-2 w-100":"btn btn-primary rounded m-2 w-100"} >
        {t("Entretiens programmé")}
        </div>
        </div>
    </div>
  );
}
