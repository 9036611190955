import { notification } from 'antd'
import axios from "../../../interceptors/axios"
export const StartTest = async (data,headers)=> {
    try {
   let res = await   axios.post('/companyTest/start',data,
      {
      headers: headers
    }, { withCredentials: true } )
      return res
  }
 catch (err) {
    }
  }