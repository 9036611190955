import React, { Component } from 'react';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { notification, Modal, Input, Button } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import moment from 'moment-timezone';
import { UpdateResponsible } from "../../services/api/manageResponsible/UpdateResponsible";
import { AddResponsible } from '../../services/api/manageResponsible/AddResponsible';

import { AppContext } from '../../routes/AppContext';
import { withTranslation } from 'react-i18next';
class AddResponsibleForm extends Component {
  static contextType = AppContext;

    constructor(props) {
        super(props);
        if(this.props.responsible){
          let responsible =this.props.responsible

          this.state = { 
            responsible: responsible,
            id:responsible._id,
            name:responsible.name,
            email:responsible.email,
            password: "",
            phone: "+"+responsible.phone.toString(),
            jobTitle: responsible.jobTitle,
            nameError:"",
            emailError:"",
            passwordError: "",
            phoneError: "",
            jobTitleError: "",
            loading:false
        }}
        else{
        this.state = { 
          name:"",
          email:"",
          password: "",
          phone: "",
          jobTitle: "",
          nameError:"",
          emailError:"",
          passwordError: "",
          phoneError: "",
          jobTitleError: "",
          loading:false
        };
      }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
 componentDidMount(){

 }
      handleChange(event) {
        const{name,value}=event.target;
        this.setState({
          [name]:value
        })
        
      }
      validation=()=>{
       let isvalid=true
       let email=this.state.email.toLowerCase()
       let name=this.state.name
       let password=this.state.password
       let phone=this.state.phone
       let jobTitle=this.state.jobTitle
       
     if(!this.state.id) { //email validation
       if(email=="") {
          isvalid=false
          this.setState({emailError:this.props.t("champ obligatoire")})
        }
       else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))){
         isvalid=false
         this.setState({emailError:"email non valide"})
      }
      else 
      this.setState({emailError:""})
    }
      
      //password validation
      if(password=="") {
        isvalid=false
        this.setState({passwordError:this.props.t("champ obligatoire")})
      }
      else if (!(
        password.match(/[0-9]/g) &&
        password.match(/[A-Z]/g) &&
        password.match(/[a-z]/g) &&
        password.match(/[^a-zA-Z\d]/g) &&
        password.length >= 8
      ))
      {
        isvalid=false
        this.setState({passwordError:
          this.props.t("Mot de passe faible ( * Au moins 1 caractère majuscule 'ABCD...', * Au moins 1 caractère minuscule 'abcd..', *Au moins 1 chiffre '123...' , *Au moins 1 caractère spécial'+-*/...' , *Minimum 8 caractères).")})
      
      }
      else 
      this.setState({passwordError:""})

      //phone validation
      if(phone==undefined) {
        isvalid=false
        this.setState({phoneError:this.props.t("champ obligatoire")})
      }
      else if (!isValidPhoneNumber(phone)) {
        this.setState({phoneError:this.props.t("Numéro non valide")});
        isvalid=false
      }
      else 
      this.setState({phoneError:""})

    //name validation
    if(name=="") {
      isvalid=false
      this.setState({nameError:this.props.t("champ obligatoire")})
    }
    else 
    this.setState({nameError:""})
    
     //jobTitle validation
    if(jobTitle=="") {
      isvalid=false
      this.setState({jobTitleError:this.props.t("champ obligatoire")})
    }
    else 
    this.setState({jobTitleError:""})
 
    
   
    
    return isvalid
    }
 async     handleSubmit(event){
        event.preventDefault();
    
        if(this.validation()){
          this.setState({loading:true})
          if(this.state.id){
            UpdateResponsible({
              
              id:this.state.id,
             name:this.state.name,
             phone:this.state.phone,
             jobTitle:this.state.jobTitle,
         password:this.state.password
           },
           this.context.headers)
         
          let newOne=this.state.responsible
          newOne.name=this.state.name
          newOne.phone=this.state.phone
          newOne.jobTitle=this.state.jobTitle
   
             this.props.updateResponsible(newOne)
           
               // this.props.history.push('/login/entreprise')


          }
          else{
           let response=await AddResponsible({
              
             name:this.state.name,
             phone:this.state.phone,
             jobTitle:this.state.jobTitle,
             email:this.state.email.toLowerCase(),
             password:this.state.password
           },this.context.headers)

 if(response){
        //notification
        notification.success({
        
         description:this.props.t(response.data.code),
         className: 'custom-class',
         style: {
          width: 600,
          backgroundColor:"#d4edda",
          marginTop:"5rem"
        },
       })
     if(response.data.result){
      let r=response.data.result
      r.creationDate = moment(r.creationDate).format("DD/MM/YYYY");
       this.props.addResponsible(r)
 
     }}
    }
         this.setState({
           name:"",
           email:"",
           password:"",
           phone: "",
           jobTitle: "",
         })
         this.props.setModalVisible(false)
        }
     }
    
      render = () => {
  const { t } = this.props;

        return (
          <div className=" ">
                   <Modal
          title={<h5 className="m-1 text-primary"><b>{this.state.id ? t("Modifier") : t("Ajout")} {t("responsable")}</b></h5>}
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={this.props.modalVisible}
          onOk={() => this.props.setModalVisible(false)}
          onCancel={() => this.props.setModalVisible(false)}
          footer={<></>}
     
        >


<div className="">
              <div className="row justify-content-start">
                <div className="  ">
                  <form   className="   "
                    onSubmit={this.handleSubmit}>
                    <div className="">
                      <div className="mx-6">
                     
                        <label>{t("Nom")} *</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            id="name"
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                             
                          />
                        </div>
    <p className="text-danger"><small>{this.state.nameError}</small></p>
  
     
    
    <label>{t("Poste")} *</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            id="jobTitle"
                            type="text"
                            name="jobTitle"
                            value={this.state.jobTitle}
                            onChange={this.handleChange}
                             
                          />
                        </div>
    <p className="text-danger"><small>{this.state.jobTitleError}</small></p>

    <label className="col-12  " htmlFor="tel">
                    <span className="float-left"> {t("Téléphone")} * </span>
                    <br />

                    <PhoneInput
                    defaultCountry="TN"
                      numberInputProps={{ className: "form-control" }}
                      placeholder={t("Entrez le numéro de téléphone")}
                      
                      value={this.state.phone}
                      onChange={(value) => {
                        this.setState({ phone: value });
                      }}
                    />
                    <p className="text-danger"><small>{this.state.phoneError}</small></p>
                  </label>
    
    
                        <label>{t("Adresse Email")} *</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            type="text"
                            id="email"
                            name="email"
                            value={this.state.email}
                            readOnly={this.state.id}
                            onChange={this.handleChange}
                             
                          />
                        </div>
                        <p className="text-danger"><small>{this.state.emailError}</small></p>
    
                        <label>{t("Mot de passe")} *</label>
                        <div className="md-form">
                          {/* <input
                            id="mdp"
                            className="form-control validate mb-2"
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                          /> */}
                               <Input.Password
                         id="mdp"
                         className=" validate mb-2"
                         type="password"
                         name="password"
                         value={this.state.password}
                         onChange={this.handleChange}
                    />
    {/* 
    <Space direction="vertical">
        <Input.Password placeholder="input password" />
        <Input.Password
          placeholder="input password"
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Space> */}
                          <span>
                        <p className="text-danger"><small>{this.state.passwordError}</small></p>
    
                 
    
                          </span>
                        </div>
                        <br />
                        <div className="text-center">
                          {/* <button
                            type="submit"
                            className=" submitspinner btn btn-primary btn-block z-depth-1a"
                          
                          >
                      
                      {this.state.id ? "Modifier" : "Ajouter"}
                          </button> */}
                          <Button type="primary" loading={this.state.loading} onClick={this.handleSubmit}>
                          {this.state.id ? t("Modifier") : t("Ajouter")}
        </Button>
                        </div>
                        <br />
                      </div>
                  
                    </div>
                  </form>
                </div>
              </div>
            </div>



        </Modal>
           
          </div>
        );
      };
    }
    export default  withTranslation()(AddResponsibleForm)