import { Modal } from "antd";
import React, { useContext } from "react";
import { DeleteHardSkill } from "../../../services/api/cv/hardSkills/DeleteHardSkill";
import XMarkIcon from "../..//icons/XMarkIcon";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../routes/AppContext";

import { DeleteRequestedSkill } from "../../../services/api/cv/requestedSkill/DeleteRequestedSkill";
import { useTranslation } from "react-i18next";

const HardSkillComponents = (props) => {
    const { user, setUser,headers } = useContext(AppContext);
    const { t } = useTranslation();
  const deleteHardSkill = async () => {
    if (user.hardSkills.length == 1) {
    } else
      Modal.confirm({
        title: t("Supprimer formation"),
        icon: <ExclamationCircleOutlined />,
        content:
        t('voulez vous supprimer la compétence') + props.hardSkill.skill,
        okText: "Supprimer",
        okType: "danger",
        cancelText: "Annuler",
        onOk: async () => {
          //send request
          if (props.hardSkill.code) {
            let res = await DeleteHardSkill(
              { id: props.hardSkill.id },
              headers
            );
          } else {
            let res = await DeleteRequestedSkill(
              { id: props.hardSkill.id },
              headers
            );
          }
          //update context
          let cv = user;
          cv.hardSkills.splice(props.index, 1);
          cv.updated = true;
          setUser(cv);
          props.updateView();
        },
        // onCancel:()=>{alert(index)}
      });
  };
  return (
    <div>
        <div className="tech-l" >{props.hardSkill.skill}
        
        <XMarkIcon
          onClick={deleteHardSkill}
          className="me-2 zoom1"
          width="0.6rem"
          fill="red"
          style={{marginLeft:"5px"}}
        />
        
        </div>
        
        
    </div>
  )
}

export default HardSkillComponents