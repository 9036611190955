import { Modal } from "antd";
import React, { useContext } from "react";
import { AppContext } from "../../../routes/AppContext";
import { DeleteLanguage } from "../../../services/api/cv/languages/DeleteLanguage";
import XMarkIcon from "../../icons/XMarkIcon";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { DeleteRequestedSkill } from "../../../services/api/cv/requestedSkill/DeleteRequestedSkill";

import { useTranslation } from "react-i18next";
const LanguageComponents = (props) => {
    const { user, setUser,headers } = useContext(AppContext);
    const { t } = useTranslation();
  const deleteLanguage = async () => {
    if (user.languages.length == 1) {
    } else
      Modal.confirm({
        title: t("Supprimer formation"),
        icon: <ExclamationCircleOutlined />,
        content:
          t("voulez vous supprimer la langue") + props.language.language_fr,
        okText: "Supprimer",
        okType: "danger",
        cancelText: "Annuler",
        onOk: async () => {
          //send request
          if (props.language.code) {
            let res = await DeleteLanguage(
              { id: props.language.id },
              headers
            );
          } else {
            let res = await DeleteRequestedSkill(
              { id: props.language.id },
              headers
            );
          }
          //update context
          let cv = user;
          cv.languages.splice(props.index, 1);
          cv.updated = true;
          setUser(cv);
          props.updateView();
        },
        // onCancel:()=>{alert(index)}
      });
  };
  return (
    <div className="cv-l" >
     {props.language.language_fr}
     <span alt="Profil" role="button" className="right">
        <XMarkIcon
          onClick={deleteLanguage}
          className="me-2 zoom1"
          width="0.6rem"
          fill="red"
          style={{marginLeft:"5px"}}
        />
     </span>
    </div>
  )
}

export default LanguageComponents