import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({ title, description, name, url, image , lang}) {
  return (
    <Helmet prioritizeSeoTags={true}>
      <title>{title}</title>
      
      {/* Open Graph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={name} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {/* End Open Graph tags */}
      
      {/* Standard metadata tags */}
      <meta name="description" content={description} />
      <meta name="keywords" content="recrutement, work, job, pfe, stage, employee, travail, e-recrutement, recruitment, employment, internship, career, jobs, hiring, human resources, talent acquisition, CV, résumé, hiring process, career opportunities, وظائف, عمل, توظيف, تدريب, وظيفة, فرصة عمل, مسار مهني, اكتساب المواهب, عملية التوظيف, فرص عمل" />
      <meta name="robots" content="index, follow" />
      <meta charSet="utf-8" />
      <meta name="language" content={lang} />
      {/* End standard metadata tags */}
      
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
