import React, { useMemo, useState } from "react";
import Candidat from "./Candidat";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Button, Drawer, Modal, notification, Radio, Select, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ConfirmationModal from "./confirmationModal/ConfirmationModal";
import JobApplicationsDrawer from "./JobApplicationsDrawer";
import { useTranslation } from "react-i18next";
const { Option } = Select;

// import InterviewDetails from

//   ];
// let show=false
// const handleOpen=()=>{
//   // show=true
//   setShow(true)
// }
// const handleClose=()=>{
//   // show=false
// setShow(false)
// }



export default function Candidatures(props) {
  const [show, setShow] = useState(false);
  const [idCandidat, setIdCandidat] = useState("");

  const handleOpen = (id) => {
    setIdCandidat(id);
    // show=true
    setShow(true);
  };
  const handleClose = () => {
    // show=false
    setShow(false);
  };
  
  return (
    <div className="ml-3 ">
      {/* {list} */}

      <div className="">
        <DragAndDrop
          vacancies={props.vacancies}
          startMissionDate={props.startMissionDate}
          interviews={props.interviews}
          title={props.title}
          userId={props.userId}
          candidatures={props.candidatures}
          show={show}
          idCandidat={idCandidat}
          handleOpen={handleOpen}
          handleClose={handleClose}
          id={props.id}
        />
      </div>
    </div>
  );
}

function DragAndDrop(props) {
  const { t } = useTranslation();

  const columnsFromBackend = {
    0: {
      color: "blue",
      name: t("En attente"),
      items: [],
    },
  
    1: {
      color: "magenta",
      name: t("Test"),
      items: [],
    },
    2: {
      color: "orange",
      name: t("Entretien"),
      items: [],
    },
    3: {
      color: "red",
      name: t("Refusé"),
      items: [],
    },
    4: {
      color: "green",
      name: t("Accepté"),
      items: [],
    },
  };
  
  const [columns, setColumns] = useState(columnsFromBackend);
  const [entretiens, setEntretiens] = useState(props.interviews);
  const [modalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const [gender, setGender] = useState(["Homme","Femme"]);
  const [experience, setExperience] = useState(["0-1","1-3","3-5",">5"]);
  const [regions, setRegions] = useState([]);
  const [handicap, setHandicap] = useState(false);

  const [scoreFilter, setScoreFilter] = useState(0)

  useMemo(() => {
    console.log(props.candidatures);
    for (let i = 0; i < 5; i++) {
      columnsFromBackend[i].items = [];
    }
    let list = props.candidatures.map((candidat, index) => {
      columnsFromBackend[candidat.state].items.push({
        id: candidat._id,
        visible:true,
        content: (
          <Candidat idJobPost={props.id} index={index} candidat={candidat} />
        ),
      });

      return (
        <div key={index}>
          <Candidat
            userId={props.userId}
            idJobPost={props.id}
            index={index}
            candidat={candidat.candidat}
          />
        </div>
      );
    });
    setColumns(columnsFromBackend)
  }, [props.candidatures]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onDragEnd = (result, columns, setColumns, props) => {
    if (!result.destination) return;
    const { source, destination } = result;
  
    if (source.droppableId !== destination.droppableId) {
      if (destination.droppableId == 4 && columns[4] == props.vacancies) {
        //notif
        notification.warn({
          description: t("Vous avez atteint le maximum des candidats acceptés"),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#fff3cd",
            marginTop: "5rem",
          },
        });
      } else {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        });
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  // let entretiens=[]
  const addEntretien = (entretien) => {
    let interviews = entretiens;

    interviews.push(entretien);
    setEntretiens(interviews);
    props.handleClose();
  };
  const dateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };

  const [applications, setApplications] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
  });

  const Confirm = (event) => {
    event.preventDefault();

    let candidatures = { 0: [], 1: [], 2: [], 3: [], 4: [] };

    for (let i = 0; i < 5; i++) {
      columns[i].items.map((item) => {
        for (let j = 0; j < props.candidatures.length; j++) {
          const element = props.candidatures[j];

          if ((element._id == item.id) & (element.state != i)) {
            //  let obj={}
            //  let id=item.id
            //  obj[id]=item.content.props.candidat
            candidatures[i].push(item.content.props.candidat);
          }
        }
      });
    }
    setModalVisible(true);
    setApplications(candidatures);
  };

  return (
    <>
      <div>
        {open && (
          <JobApplicationsDrawer
            gender={gender}
            setGender={setGender}
            experience={experience}
            setExperience={setExperience}
            regions={regions}
            setRegions={setRegions}
            handicap={handicap}
            setHandicap={setHandicap}
            setColumns={setColumns}
            columns={columns}
            open={open}
            onClose={onClose}
            scoreFilter={scoreFilter}
            setScoreFilter={setScoreFilter}
          />
        )}
        <div className="d-flex justify-content-end">
          <Button type="primary" onClick={showDrawer}>
            <b> &lt; {t("Fitrer par critère")} </b>
          </Button>
        </div>
        <div className="row">
          <div
            className="text-center d-flex justify-content-start h-100"
            // style={{ display: "flex", alignItems:"center", height: "100%" }}
          >
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(result, columns, setColumns, props)
              }
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 5px 0 0",
                    }}
                    key={columnId}
                  >
                    <Tag className="m-1" color={column.color}>
                      {column.name} ({column.items.length})
                    </Tag>
                    <div>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "#c1dae5"
                                  : "#f1f1f1",
                                padding: 4,
                                width: "14rem",
                                minHeight: "25rem",
                                maxHeight: "25rem",
                                overflowY: "auto",
                                overflowX: "hidden",
                              }}
                            >
                              {column.items.map((item, index) => {
                                // console.log(item);
                                if(item.visible)
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="rounded"
                                          style={{
                                            userSelect: "none",
                                            padding: "2px 2px 2px 2px",
                                            margin: "2px 0 2px 0",
                                            minHeight: "50px",
                                            backgroundColor: snapshot.isDragging
                                              ? "#fff8dc"
                                              :(item.content.props.candidat.cvFile?'#dce4f1': "white"),
                                            color: "black",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {item.content}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
            </DragDropContext>
          </div>
        </div>

        {/* modal */}
        <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b>{t("Confirmation")} </b>
            </h5>
          }
          className="m-5 w-75 min-vh-100"
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <div style={{ minHeight: "75vh" }}>
            <ConfirmationModal
              startMissionDate={props.startMissionDate}
              userId={props.userId}
              interviews={props.interviews}
              jobPostTitle={props.title}
              idJobPost={props.id}
              applications={applications}
            />
          </div>
        </Modal>
      </div>
      {!modalVisible && (
        <div className="d-flex justify-content-end ">
          <div className=" m-2">
            <button className=" btn btn-primary " name="send" onClick={Confirm}>
              {t("Enregistrer")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
