import React, { Component } from 'react'
import {LeftOutlined,
    RightOutlined
} from '@ant-design/icons';
import { DatePicker, Select } from 'antd';
 import moment from 'moment-timezone';
import { AppContext } from '../../routes/AppContext';
import { withTranslation } from 'react-i18next';
const { Option } = Select;

class Form3 extends Component {
  static contextType = AppContext

   disabledDate=(current)=> {
    // Can not select days before today and today
    return current && current < moment(this.context.serverTime).subtract(1, 'day');
  }
  disabledExpirationDate=(current)=> {
    // Can not select days before today and today
    let date=this.context.orders.length?moment(this.context.orders[0].datefin):moment(this.context.serverTime).add(1, 'months');
    return current 
    && current > moment(date) || current<moment(this.context.serverTime)
  }
  render() {
    const { t } = this.props;

        return (
            <div className="row p-2" >
              <div className="col-lg-6 col-sm-12 " >

                 <label className="col-12 mt-2" htmlFor="linkedinPage">
                      <span className="float-left"> {t("Date de publication")} </span>
                      <div className="" >
                      <DatePicker
                        placeholder="DD-MM-YYYY"

                        format={"DD-MM-YYYY"}
                      allowClear={false}
                      disabledDate={this.disabledDate}
                      className="border form-control"
                         defaultValue={ moment(this.props.publicationDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                          let m = moment.utcOffset(0);
                          m.set({hour:0,minute:0,second:0,millisecond:0})
                            this.props.setPublicationDate(m.toDate())
                    }
                      }}
                       />
                      </div>
                    </label>
                    {/* <p className="text-danger"><small>{this.state.companyCreationDateError}</small></p> */}
                   
                           <label className="col-12 mt-2" htmlFor="linkedinPage">
                      <span className="float-left"> {t("Date de début de mission")}  </span>
                      <div className="col-12" >
                      <DatePicker
                        placeholder="DD-MM-YYYY"

                        format={"DD-MM-YYYY"}

                      className="border form-control"
                      defaultValue={this.props.startMissionDate && moment(this.props.startMissionDate)}
                      onChange={(moment,date)=>{
                          
                          if(moment!=null){
                            let m = moment.utcOffset(0);
                            m.set({hour:0,minute:0,second:0,millisecond:0})
                            this.props.setStartMissionDate(m.toDate())

                            
                    }
                    else{
                      this.props.setStartMissionDate(null)
                      
                    }
                  }}
                       />
                      </div>
                    </label>
                    <label className="col-12 mt-2" htmlFor="linkedinPage">
                      <span className="float-left"> {t("Date d'expiration")}  <b style={{    color: "blue"}}>* </b></span>
                      <div className="" >
                      <DatePicker
                        placeholder="DD-MM-YYYY"

                      allowClear={false}
                      disabledDate={this.disabledExpirationDate}
                      format={"DD-MM-YYYY"}

                      className="border form-control"
                         defaultValue={this.props.expirationDate && moment(this.props.expirationDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                          let m = moment.utcOffset(0);
                        m.set({hour:0,minute:0,second:0,millisecond:0})
                            this.props.setExpirationDate(m.toDate())
                    }
                      }}
                       />
                      </div>
                    </label>
                    <p className="text-danger"><small>{this.props.expirationDateError}</small></p> 
                  </div>
                    <div className="col-lg-6 col-sm-12">
                    <label className="col-12 mt-2 " htmlFor="salary">
                    <span className="">{t("Fourchette salariale")}</span> <br />
                    <input
                      className="border form-control "
                      id="salary"
                      type="number"
                      name="salary"
                      min="0"
                       defaultValue={this.props.salary}
                       onChange={this.props.setSalary}
                    />
                  </label>
                  {/* <p className="text-danger"><small>{this.state.addressError}</small></p> */}
                  <label className="col-12 mt-2 " htmlFor="experience">
                    <span className="">{t("Experience requise")}</span> <br />
                    {/* <label>Niveau d'étude</label> */}
                    <Select
    // style={{ width: 200 }}
    placeholder={t("Selectionner..")}
    className="mb-2 w-100"
    // style={{width:"100%"}}
    onChange={this.props.setExperience}
    defaultValue={this.props.experience}
  >
     {/* bac , bac+2, bac+3 , bac+4 , bac+5 , bac> 5,autre */}
    <Option value={0}>{t("0-1 ans")}</Option>
    <Option value={1}>{t("1-3 ans")}</Option>
    <Option value={3}>{t("3-5 ans")}</Option>
    <Option value={5}>{t("&gt;5 ans")}</Option>
  </Select>
<br/>
                  
                  </label>
                  <label className="col-12 mt-2 " htmlFor="vacancies">
                    <span className=""> {t("Nombre de postes")}</span> <br />
                    <input
                      className="border form-control "
                      id="vacancies"
                      type="number"
                      name="vacancies"
                      min="0"
                      defaultValue={this.props.vacancies}
                      onChange={this.props.setVacancies}
                    />
                  </label>
                  <p className="text-danger"><small>{this.props.vacanciesError}</small></p>
                
                  {/* <p className="text-danger"><small>{this.state.addressError}</small></p> */}

                  </div>

















                    {/* <p className="text-danger"><small>{this.state.companyCreationDateError}</small></p> */}

                {/* <div onClick={this.props.prev}
           className="btn btn-primary m-1" >
      <LeftOutlined   />
    </div>
            <div onClick={this.props.next}
           className="btn btn-primary m-1" >
      <RightOutlined   />
    </div> */}
            </div>
        )
    }
}
export default withTranslation()(Form3)
