import React, { Fragment, useContext, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { message, notification, Card, Table  } from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
// import AffichePFD from "./affiche_PDF";
import Dropzone from "../../Dropzone";
import { Spin, Space } from "antd";
import image from "../../../media/image/upload-cv.webp";
import JobChoice from "./JobChoice";
import { AppContext } from "../../../routes/AppContext";
import { UploadCVService } from "../../../services/api/cv/UploadCVService";
import TrashIcon from "../../icons/TrashIcon";
import { useQueryClient } from "react-query";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";

const { Column } = Table;

function beforeUpload(file) {
  if (file !== "") {
    var typeFile = file.type.split(".").pop();
    let acceptedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/rtf",
      "application/vnd.oasis.opendocument.text",
      // "image/png",
      // "image/jpeg",
      "document",
      "text/plain",
      "application/pdf",
      "document",
    ];
    const isTrue = acceptedTypes.includes(typeFile);
    if (!isTrue) {
      message.error(
        "Vous ne pouvez télécharger que le type de fichier Pdf - doc - docx - txt"
        // "doc/docx//rtf/png/jpeg/text!"
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Le fichier doit être plus petite que 5MB!");
    }
    return isTrue && isLt5M;
  } else {
    message.warning("Sélection votre offre svp!");
    return false;
  }
}

const ExtractWithUpload = (props) => {
  const [afficheProgress, setafficheProgress] = useState(false);
  const [verif, setverif] = useState("");
  const [verifupload, setverifupload] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {  userId } =
    useContext(AppContext);
    const next=()=>{
      if(!props.idJobPost){
        notification.warn({
          description: "veuillez selectionner un offre",
          className: 'custom-class',
          style: {
            width: 600,
            backgroundColor: "#fff3cd",
            marginTop: "5rem"
          },
        })
        return
      }
      props.next()
    }
  const onChange = (files) => {
    console.log(files);
    if((files.length+props.files.length)>10){
      notification.warn({
        description:t('veuillez selectionner 10 cv maximum !'),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
      return
    }
    props.setAttachments(files.length+props.files.length);
    console.log(files.length);
    props.setFiles("");
    setverif("");
    setverifupload(false);
    console.log("before",[...props.files])
    console.log("new",[...files])



    let acceptedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/rtf",
      "application/vnd.oasis.opendocument.text",
      // "image/png",
      // "image/jpeg",
      "document",
      "text/plain",
      "application/pdf",
      "document",
    ];

    const filteredFiles = files.filter(file => {
      // Vérifier si le type de fichier est accepté
      const isAcceptedType = acceptedTypes.includes(file.type);
    
      // Vérifier si la taille du fichier est inférieure à 5 Mo
      const isUnderSizeLimit = file.size / 1024 / 1024 < 5;
    
      // Retourner vrai si le type et la taille du fichier sont acceptés
      return isAcceptedType && isUnderSizeLimit;
    });
    console.log("filteredFiles.length!==files.length",filteredFiles.length,files.length)
    if(filteredFiles.length!==files.length){
        message.error(
          "Le fichier doit être plus petite que 5MB! et le type de fichier Pdf - doc - docx - txt"
        );
    }

    props.setFiles([...props.files,...filteredFiles]);


    setverif(beforeUpload(files[0]));

    setafficheProgress(false);
    // document.getElementById("uploadedFile").scrollIntoView();
    // document.getElementById("uploadedFile").focus();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setafficheProgress(true);
    setverif(false);
    props.next();
    queryClient.invalidateQueries(['applications',props.idJobPost]);

    let parsedAttachments = 0;
  
    for (const file of props.files) {
      console.log(file);
      const formData = new FormData();
      formData.append("idCompany", userId);
      formData.append("idJobPost", props.idJobPost);
      formData.append("role", "company");
      formData.append("file", file);
  
      if (beforeUpload(file)) {
        try {
          const res = await UploadCVService(formData, {
            "Content-Type": "multipart/form-data",
          });
          console.log(res);
          if (res.status === 200) {

            parsedAttachments++;
            props.setParsedAttachments(parsedAttachments);
            console.log(parsedAttachments);
  
            let candidate = { filename: file?.name, score: res.data.score };
            props.setCandidates(prevCandidates => [...prevCandidates, candidate]);
          }
          else{
            parsedAttachments++;
            let candidate = { filename: `${t("erreur")}  ${" - "}  ${file.name}`, score: 0};
            props.setCandidates(prevCandidates => [...prevCandidates, candidate]);
          }
        } catch (err) {
          console.log(err);
          setafficheProgress(false);
          notification.open({
            description: "Erreur avec le serveur, veuillez réessayer ! ",
            className: "custom-class ",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            style: {
              width: "auto",
            },
          });
          // You might want to handle the error here, decide whether to break out of the loop or continue to the next iteration.
        }
      }
    }
  
    // Move this block outside of the loop to execute only after all requests are complete
    setafficheProgress(false);
    setverifupload(true);
  };
  
const deleteFile=(file)=>{
  const filename=file.name
  let files=props.files.filter(file=>file.name!=filename)
  props.setFiles(files);
  props.setAttachments(files.length);
  if(!files.length){
    setverif(false);
  }
}
  return (
    <div className="uploadFile mt-2">
      <h3 className="titrePage">{t("Télécharger vos cvs")} </h3>
      {/* {files.length > 0 && ( */}
        
      {/*  )} */}

      <div className="modal-body">
        <Fragment>
          <form onSubmit={onSubmit}>
            <Dropzone image={image} onDrop={onChange} />
            {
              props.files.length?
              <Card className="m-3 mt-5 rounded shadow bg-white" style={{}}>

      <h3 className="titrePage"> {t("Liste des CV à télécharger")} :</h3>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
              <Table 
        dataSource={props.files}>
          <Column align="center" title={t("Fichiers")} dataIndex="Actions" key="Actions" 
            render={(text, file, index) => {
              return (
                <div className="">
              {file.name}                  
                </div>
              );
            }}
          />
            <Column align="center" title="" dataIndex="Actions" key="Actions" 
            render={(text, file, index) => {
              return (
                <i role="button" onClick={()=>{deleteFile(file)}} className="">
                          <TrashIcon fill="#e25757" width="15px" />
                </i>
              );
            }}
          />
        </Table>
        </ConfigProvider>
              </Card>:""
            }
            <div className="d-flex justify-content-between" id="uploadedFile">
              {" "}
              <button
                  onClick={() => {
                    props.previous();
                  }}
                  className="btn btn-primary btn-block mt-4 "
                >
                   {t("Précedent")}
                </button>
              <div>
              {props.files.length>0 ? (
                <>
                  <input
                    type="submit"
                    value={t('Suivant')}
                    className="d-flex  btn btn-primary btn-block mt-4  mx-auto p-1"
                  />
                </>
              ) : null}
              </div>
              {afficheProgress ? (
                <div className="d-flex justify-content-center">
                  <Space size="large">
                    <Spin size="large" />
                  </Space>
                </div>
              ) : null}
            </div>
            {verifupload ? (
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => {
                    props.next();
                  }}
                  className="btn btn-primary btn-block mt-4 "
                >
                  {t("Etape Suivante")} &#8594;
                </button>
              </div>
            ) : null}
            
          </form>
        </Fragment>
      </div>
    </div>
  );
};

export default withRouter(ExtractWithUpload);
