import axios from "../../../interceptors/axios"

export const checkCompanyPackage = async (data, headers) => {
    try {
      let res = await axios.post(`/api/pricing/companyPackage/check`, data,{
        headers: headers,
      });
      return res;
    } catch (err) {
    }
  };