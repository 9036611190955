import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
// import SimpleBar from "simplebar-react"
import logo from "../../media/image/marker.png"
import NotificationComponent from "./NotificationComponent"
import { useTranslation } from 'react-i18next';
const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const { t } = useTranslation();
  let i=-1
  let notifications=props.notifications
  notifications=notifications.map((notification)=>{i++
   return(
   <NotificationComponent key={i} notification={notification} setMenu={setMenu} />
   )
 })
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block "
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {props.children}
          {/* <i className="mdi mdi-bell-outline"></i>
          <span className="badge rounded-pill bg-danger ">3</span> */}
        </DropdownToggle>

        <DropdownMenu style={{width:"20rem",margin:"5px",maxHeight:"80vh",overflow:"auto"}} className=" dropdown-menu-lg dropdown-menu-lg-end py-2 ">
          <div className="p-1">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{t("Notifications")} </h6>
                <hr/>
              </Col>
              
            </Row>
          </div>
          {notifications}
        
          {/* <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            ><i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              View all
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default NotificationDropdown
