import React from 'react';
import logo from '../../media/image/recin-transparent.webp'
import AsyncImage from '../AsyncImage';

import { withTranslation } from "react-i18next";
class FooterAlt extends React.Component {
    render() {

        return (
            <React.Fragment>
                <footer className="footer-alt container-fluid" style={{background: "#2765c0"}}>
                    <div className="container">
                        <div className="row d-flex">
                   
                            <div className="col-lg-12 text-center">  
                                <div className="copyright text-white f-14 font-weight-light mb-0 d-flex justify-content-center  align-items-center ">         
                                  <AsyncImage src={logo} alt="" height="80px" width="80px" /> <p className='my-1' > © {new Date().getFullYear()} {this.props.t("Rec-INOV Tous les droits sont réservés")}</p></div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
export default withTranslation()(FooterAlt);