import axios from "../../../interceptors/axios";

export const MyApplications = async (data, headers) => {
  try {
    let res = await axios.get("/api/jobPost/myApplications", {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
