import React from 'react'
import NotFound from '../components/notFound/NotFound'

export default function NotFoundView() {
    return (
        <div>
            <NotFound/>
        </div>
    )
}
