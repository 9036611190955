import { Upload, message, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";
import ImgCrop from "antd-img-crop";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { ProfileImageService } from "../../services/api/profileImage/ProfileImageService";
import AsyncImage from "../AsyncImage";
import img from "../../media/image/user.png";
import { AppContext } from "../../routes/AppContext";
import { withTranslation } from 'react-i18next';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
class ImageUploader extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
  }
  state = {
    loading: false,
    imageUrl: this.props.image,
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
  };
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  uploadImage = async (file) => {
    // Your process logic. Here we just mock to the same file
    let bodyFormData = new FormData();
    bodyFormData.append("file", file.file);
    let url = this.props.isCompany
      ? `/api/profileImage/company`
      : `/api/profileImage/candidate`;
    let response = await ProfileImageService(url, bodyFormData, {
      ...this.context.headers,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    });
    if(response){
    let path = `${response.data.path}`;
    this.setState({ loading: false, imageUrl: path });
    this.props.setImage(path);}
  };
  render() {
    const { loading, imageUrl } = this.state;
              
    const uploadButton = (
      <div className="btnUpload">
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div>{this.props.t("Upload")}</div>
      </div>
    );
    return (
      <>
        <ImgCrop rotate>
          <Upload
            listType="picture-card"
            fileList={
              imageUrl
                ? [
                    {
                      uid: "1",
                      name: "image.png",
                      status: "done",
                      url: 
                        this.props.isCompany
                          ? 
                                this.context.envVar.REACT_APP_CLIENT_URL
                        + "/company/images/" +
                        imageUrl
                          : 
                            this.context.envVar.REACT_APP_CLIENT_URL
                            + "/candidate/images/" +
                            imageUrl
                        ,
                    },
                  ]
                : [  {
                  uid: "1",
                  name: "image.png",
                  status: "done",
                  url: img,
                },]
            }
            name="logo"
            className="avatar-uploader"
            onPreview={this.handlePreview}
            beforeUpload={beforeUpload}
            onChange={this.handleChange}
            customRequest={this.uploadImage}
          >
            {uploadButton}
          </Upload>
        </ImgCrop>
        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={this.handleCancel}
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined className="m-3" />
            </i>
          }
        >

          <AsyncImage
            alt="example"
            style={{ width: "100%" }}
            src={
              this.props.isCompany
                ? 
                    
                      this.context.envVar.REACT_APP_CLIENT_URL
              + "/company/images/" +
              imageUrl
                    
                  
                : 
                  this.context.envVar.REACT_APP_CLIENT_URL
                  + "/candidate/images/" +
                  imageUrl
                
              }
           
          />
        </Modal>
      </>
    );
  }
}
export default withTranslation()(ImageUploader);
