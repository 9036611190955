import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import AsyncImage from '../AsyncImage';
import AnswerComponent from './AnswerComponent'
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { AppContext } from '../../routes/AppContext';

export default function QuestionComponent(props) {
  const {envVar,testLanguage } = useContext(AppContext);
  let questionLanguage=testLanguage=="fr"?"question_fr":"question_en"
  
  useEffect(() => {
    hljs.highlightAll();
  });
  const shuffle = useCallback((a) => {
    for (let i = a?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }, [])

  const answers =props.question.answers
  //  useMemo(() => { return shuffle(props.question.answers) }, [])
  // const [selected, setSelected] = useState(props.answer)
  const setAnswer = (val) => {
    props.setAnswer(val)
    // setSelected(val)
  }
  function onChange(val) {
    // if(val/1000<countDown)
    // {
    // setCountDown(val/1000)
    // }
  }
  let i = -1
  let list = answers.map(a => {
    i++
    return (
      <AnswerComponent selected={props.answer} setSelected={setAnswer} answer={a} key={i} />
    )
  })

  return (
    <>
    <div 
    // className="container" style={{maxHeight: '70% !important',
    // maxWidth: '75% !important',
    // overflow: 'auto'}}
    >
      <div 
    >
        <p 
        // className="inline block ptag"
        >
          <div
        // style={{maxHeight:"20rem",maxWidth:"100%",overflow:"auto"}}

          >
      <div className="text-primary mt-2 h5">
       { props.question.type != 3 ? (
          <div  dangerouslySetInnerHTML={{
            __html: props.question[questionLanguage],
          }} ></div>
        ) : (
          <AsyncImage
          className="w-100"     
              src={envVar.REACT_APP_CLIENT_URL + "/Test/images/" + props.question[questionLanguage]}
          />
        )}
     </div></div>
      {/* <CountDown countDown={60} /> */}
      {props.question.type==2&&     <textarea
                name="description"
                className="border form-control "
                onChange={(e) => {
                  props.setAnswer({...props.answer,answer:e.target.value})
                  // setSelected({...selected,answer:e.target.value})
                }}
                value={props.answer.answer}
                id="description"
                rows="2"
              ></textarea>} 
      <div className='m-2  ' >{list}</div>
      </p>
      </div>
    </div>
    </>

  )
}
