import React, { useState } from "react";
import { Form, Modal, Checkbox, Radio } from "antd";
import { SettingFilled } from "@ant-design/icons/lib/icons";
import InfoIcon from "./../icons/InfoIcon";
import { useTranslation } from "react-i18next";
export default function ModalSetting({
  languages,
  setLanguages,
  stopwatch,
  setStopwatch,
  correctionMethod,
  setCorrectionMethod,
  showResult,
  setShowResult,
  fromForm,
  permission,
  setPermission,
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const info = () => {
    Modal.info({
      title: null,
      okText: t("ok"),
      content: (
        <div>
          <p>
            {" "}
            <b>{t("Méthode de Correction Modérée des Tests")}</b>
            <br />
            <br />
            <br />
            <h6>{t("Bienvenue dans notre Méthode de Correction Modérée !")}</h6>
            <p>
              {t("Nous voulons vous expliquer comment nous évaluons vos tests sur cette plateforme de manière simple et juste.")} 
              {t("Voici comment cela fonctionne")} :
            </p>
            <span>
              <b>
                <i>1. {t("Tout Juste")} :</i>
              </b>
              {t("Si toutes vos réponses sont correctes, vous obtenez le score maximum.")}
              <br />
              <b>
                <i>2. {t("Juste et Faux")} :</i>
              </b>{" "}
              {t("Si vous avez une réponse correcte et une incorrecte, la réponse incorrecte annule la réponse correcte.")}
              <br />
              <b>
                <i>3. {t("Plusieurs Bonnes Réponses, Une Seule Donnée")} : </i>
              </b>
              {t("Si plusieurs réponses sont correctes mais que vous n'en choisissez qu'une, votre note dépend du nombre total de bonnes réponses possibles.")}
              <br />
              <b>
                <i>4. {t("Toutes les Bonnes Réponses Données")} :</i>
              </b>{" "}
              {t("Si vous fournissez toutes les bonnes réponses, vous obtenez le score maximum.")}
              <br />
              <b>
                <i>5. {t("Mélange de Bonnes et de Mauvaises Réponses")} </i>
              </b>{" "}
              {t("Si vous donnez deux bonnes réponses et une mauvaise réponse, l'une des bonnes réponses est retirée, et votre score est ajusté en conséquence.")}
            </span>
          </p>
        </div>
      ),

      onOk() {},
    });
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 900);
  };
  const showResultOptions = [
    { label: t("oui"), value: false },
    { label: t("non"), value: true },
  ];
  const plainLangueOptions = [
    { label: t("Français"), value: "fr" },
    { label: t("Anglais"), value: "en" },
  ];
  // const [selectedOption, setSelectedOption] = useState('Un Chronométre Par Question');

  const plainStopwatchOptions = [
    { value: false, label: t("Un Chronométre Par Question") },
    { value: true, label: t("Un Chronométre Global") },
  ];
  const plainCorrectionMethodsOptions = [
    { value: 0, label: t("Modérée") },
    { value: 1, label: t("Tout ou rien"), disabled: true },
  ];

  const defaults = ["Français"];

  return (
    <div>
      <SettingFilled
        style={{
          color: "#002fff",
          fontSize: "25px",
          marginRight: 30,
          marginTop: -5,
        }}
        onClick={showModal}
      />
      <Modal
        title={t("Paramètres par défaut")}
        visible={open}
        okText={t("ok")}
        cancelText={t("Annuler")}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={700}
      >
        <p
          className="mt-2"
          style={{ fontSize: "16px", fontWeight: "bold", color: "#717171" }}
        >
          {t("En quelle langue les questions doivent-elles être posées à vos candidats ?")}
        </p>
        <Checkbox.Group
          name="languageCandidate"
          options={plainLangueOptions}
          value={languages}
          onChange={(value) => {
            if (value.length) setLanguages(value);
          }}
        />
        <p
          className="mt-2"
          style={{ fontSize: "16px", fontWeight: "bold", color: "#717171" }}
        >
          {t("Comment souhaitez-vous que le candidat soit chronométré ?")}
        </p>

        <Radio.Group
          options={plainStopwatchOptions}
          value={stopwatch}
          onChange={(e) => {
            setStopwatch(e.target.value);
          }}
        />

        <p
          className="mt-2"
          style={{ fontSize: "16px", fontWeight: "bold", color: "#717171" }}
        >
          {t("Méthode correction ?")}
          <span onClick={info} role="button">
            <InfoIcon className="zoom1 m-1" fill="blue" width="1rem" />{" "}
          </span>
        </p>

        <Radio.Group
          options={plainCorrectionMethodsOptions}
          value={correctionMethod}
          onChange={(e) => {
            setCorrectionMethod(e.target.value);
          }}
        />
        <div>
          {/* <label>Offre </label> */}
          <p
            className="mt-2"
            style={{ fontSize: "16px", fontWeight: "bold", color: "#717171" }}
          >
            {t("Voulez vous afficher la résultat du test aux candidats ?")}
          </p>
          <Radio.Group
            options={showResultOptions}
            value={showResult}
            onChange={(e) => {
              setShowResult(e.target.value);
            }}
          />
        </div>
        {fromForm ? (
          <div>
            {/* <label>Offre </label> */}
            <p
              className="mt-2"
              style={{ fontSize: "16px", fontWeight: "bold", color: "#717171" }}
            >
              {t("Nous sollicitons votre autorisation afin d'utiliser les questions que vous avez ajoutées.")} 
              {t("Avec votre accord, nous pourrons améliorer notre plateforme et offrir une assistance de meilleure qualité")}
            </p>
            <Radio.Group
              options={showResultOptions}
              value={permission}
              onChange={(e) => {
                setPermission(e.target.value);
              }}
            />
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}
