import React, { memo } from 'react'

const CVFileIcon = memo((props) => {
  return (
    <>
<svg alt="CVFileIcon" {...props} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
<g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
<path d="M2213.8,4221.2L1427,3434.4l4.1-3771.2l6.1-3771.2l65.1-138.2c126-266.3,384.2-471.7,654.6-522.5c150.4-28.5,5546-28.5,5696.4,0c270.4,50.8,528.6,256.2,654.6,522.5l65.1,138.2V110.5V4329l-65.1,138.2c-126.1,266.3-384.2,471.7-654.6,522.5c-69.1,12.2-977.9,20.3-2480.2,20.3H3002.6L2213.8,4221.2z M7835,4579c115.9-34.6,270.4-178.9,315.1-296.8c34.6-91.5,36.6-276.5,36.6-4171.7c0-3895.2-2-4080.2-36.6-4171.7c-44.7-117.9-199.2-262.2-315.1-296.8c-115.9-34.6-5543.9-34.6-5659.8,0c-107.7,32.5-266.3,176.9-313.1,284.6c-36.6,83.3-38.6,229.7-44.7,3793.5l-4.1,3704.1H2413h599.7v589.6v589.6h2370.5C7156,4603.4,7776,4597.3,7835,4579z"/>
<path d="M5511.3,3912.2c-248-42.7-457.4-211.4-551-443.2c-40.7-101.7-46.8-152.5-46.8-390.3c0-309,26.4-410.7,144.3-575.3c168.8-231.8,522.5-311.1,908.8-203.3l115.9,32.5v140.3c0,138.2-2,142.3-44.7,130.1c-404.6-117.9-622.1-75.2-729.8,144.3c-40.7,83.3-46.8,128.1-48.8,321.2c0,201.3,6.1,235.8,54.9,339.5c75.2,160.6,176.9,227.7,347.6,225.7c79.3,0,166.7-16.3,233.8-44.7c59-22.4,113.9-42.7,122-42.7c8.1,0,36.6,56.9,63,126c46.8,117.9,46.8,126,12.2,150.4C5997.2,3893.9,5678,3942.7,5511.3,3912.2z"/>
<path d="M6233,3857.3c8.1-18.3,117.9-341.5,246-715.6c128.1-376.1,246-727.8,266.3-780.7l32.5-97.6l185,6.1l185,6.1l270.4,792.9c148.4,435,270.4,799,270.4,807.1c0,10.2-75.2,16.3-168.7,16.3h-168.7l-176.9-563.1c-97.6-311-183-605.8-189.1-654.6c-16.3-97.6-34.6-81.3-59,48.8c-12.2,73.2-168.7,587.5-307,1012.4l-52.9,156.5h-172.8C6239.1,3891.9,6222.8,3887.8,6233,3857.3z"/>
<path d="M2478.1,1110.7c-46.8-50.8-48.8-351.7-4.1-390.3c44.7-36.6,5058.1-38.6,5102.8,0c48.8,40.7,42.7,347.6-6.1,392.4c-34.6,32.5-239.9,34.6-2549.4,34.6C2614.3,1147.3,2508.6,1145.3,2478.1,1110.7z"/>
<path d="M2488.3-3.3c-40.7-28.5-44.7-48.8-44.7-201.3s4.1-172.8,44.7-201.3c40.7-28.4,315.1-32.5,2537.2-32.5c2222.1,0,2496.5,4.1,2537.2,32.5c40.7,28.5,44.7,48.8,44.7,201.3s-4.1,172.8-44.7,201.3c-40.7,28.4-315.1,32.5-2537.2,32.5C2803.4,29.2,2528.9,25.1,2488.3-3.3z"/>
<path d="M2488.3-1121.5c-40.7-28.5-44.7-48.8-44.7-201.3s4.1-172.8,44.7-201.3c40.7-28.5,315.1-32.5,2537.2-32.5c2222.1,0,2496.5,4.1,2537.2,32.5c40.7,28.5,44.7,48.8,44.7,201.3s-4.1,172.8-44.7,201.3c-40.7,28.4-315.1,32.5-2537.2,32.5C2803.4-1088.9,2528.9-1093,2488.3-1121.5z"/>
<path d="M2467.9-2251.8c-38.6-38.6-32.5-351.7,8.1-390.3c28.5-28.5,286.7-32.5,2547.3-32.5c2907.2,0,2583.9-30.5,2583.9,235.8c0,99.6-10.2,172.8-24.4,187C7548.3-2217.3,2502.5-2217.3,2467.9-2251.8z"/>
<path d="M2467.9-3370c-38.6-38.6-32.5-351.7,8.1-390.3c28.5-28.5,286.7-32.5,2549.4-32.5c2262.7,0,2520.9,4.1,2549.4,32.5c40.7,38.6,46.8,351.7,8.1,390.3C7548.3-3335.4,2502.5-3335.4,2467.9-3370z"/>
</g></g>
</svg>
</>
  )
})

export default CVFileIcon