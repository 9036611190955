import React, { Component } from 'react'
import { Input, Modal, notification, Select, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
 import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import jwt from "jsonwebtoken"
import { ExclamationCircleOutlined } from "@ant-design/icons";
// import questionPreview from './questionPreview';
import { CloseOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import EyeIcon from "../../icons/EyeIcon";
import QuestionPreview from "../testPreview/QuestionPreview";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { AppContext } from '../../../routes/AppContext';
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Option } = Select;

const { Column } = Table;
class QuestionsList extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      question: {},
      questions: [],
      filteredQuestions: [],
      update: false,
      filterInput: "",
      selectedRowKeys: [],
      skills: [],
    };
    props.innerRef.current = this.updateQuestions;
  }

  updateQuestions = async () => {
    let skills = this.props.selectedSkills.map((s) => {
      let skill=s.skill?s.skill:s.soft_skill_fr?s.soft_skill_fr:s.language_fr?s.language_fr:s.mad_skill_fr
      return({
      text: skill,
      value: skill,
    })});
    let i = -1;
    let questions = await this.props.questions.map((q) => {
      i++;
      q.key = i;
      if (q.level == 0) q.level = this.props.t("Débutant");
      else if (q.level == 1) q.level = this.props.t("Intérmediaire");
      else if (q.level == 2) q.level = this.props.t("Avancé");
      else if (q.level == 3) q.level = this.props.t("Expert");

      for (let j = 0; j < this.props.selectedSkills.length; j++) {
        if (q.skill == this.props.selectedSkills[j].code) {
          q.skill = this.props.selectedSkills[j].skill
            ? this.props.selectedSkills[j].skill
            : this.props.selectedSkills[j].soft_skill_fr
            ? this.props.selectedSkills[j].soft_skill_fr
            : this.props.selectedSkills[j].language_fr
            ? this.props.selectedSkills[j].language_fr
            : this.props.selectedSkills[j].mad_skill_fr;
        }
      }

      return q;
    });
    this.setState((prev) => ({
      questions: questions,
      filteredQuestions: questions,
      skills: skills,
    }));
  };
  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  // onChange = (selected) => {
  //   this.setState({ selectedRowKeys:selected });
  // };
  onSelect = (record, selected, selectedRows, nativeEvent) => {
    let selectedQuestionsKeys = [...this.state.selectedRowKeys];
    if (selected) {
      selectedQuestionsKeys.push(record.key);
    } else {
      selectedQuestionsKeys.splice(
        selectedQuestionsKeys.indexOf(record.key),
        1
      );
    }
    // this.onChange(selectedQuestionsKeys)
    // this.setState(prev => ({ selectedRowKeys:selectedQuestionsKeys }))
    this.setState({ selectedRowKeys: selectedQuestionsKeys });
    this.props.setSelectedQuestions(selectedQuestionsKeys);
  };
  componentDidMount() {
    hljs.highlightAll();
  }

  render() {
    const {t}=this.props
    let selectedRowKeys=this.state.selectedRowKeys
    let testLanguage=this.context.testLanguage
    let questionLanguage=testLanguage=="fr"?"question_fr":"question_en"
    return (
      <div className="my-2">
        {/* <div onClick={() => this.props.history.push('/company/addquestion')} className="btn btn-primary rounded-pill m-2" >
          <PlusOutlined className=" border  rounded-circle " style={{ fontSize: "1.4rem" }} />
          <b className="m-3">Ajouter Offre</b>
        </div> */}
        <div className="m-3 d-flex justify-content-center">
          <Input
            className=""
            placeholder={t("Chercher une question..")}
            value={this.state.filterInput}
            onChange={(e) => {
              const currValue = e.target.value;
              // setValue(currValue);
              this.setState({ filterInput: currValue })
              this.setState(prev => ({
                filteredQuestions: prev.questions.filter(question =>
                  ((question[questionLanguage]).includes(currValue))
                )
              }))
              // const filteredData = this.state.question.filter(entry =>
              //   entry.name.includes(currValue)
              // );
              // setDataSource(filteredData);
            }}
          />
<Select className="w-50"  
                  // style={{ width: '40%' }} 
                   showSearch value={this.context.testLanguage}
    placeholder={t("Chercher pour sélectionner")} onChange={this.context.setTestLanguage}>
    <Option value="fr">{t("Francais")}</Option>
    <Option value="en">{t("Anglais")}</Option>
  </Select>
          </div>
          <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table 
        loading={this.props.loading}
        rowSelection={{
          selectedRowKeys,
          // onChange: this.onChange,
          onSelect: this.onSelect,
        }}
        hideSelectAll={true}
        // pagination={false} 
        dataSource={this.state.filteredQuestions}>
          <Column align="center" sorter={(a, b) => a[questionLanguage].localeCompare(b[questionLanguage])} 
          // responsive={['lg']} 
          title={t("Question")} dataIndex={questionLanguage} key={questionLanguage}   
           render={(text, question, index) => {
            return (        <div  dangerouslySetInnerHTML={{
              __html: question[questionLanguage],
            }} ></div>)}}
            />
               <Column align="center" 
          // responsive={['lg']} 
          onFilter= {(value, record) => record.skill==value}
          filters= {this.state.skills} sorter={(a, b) => a.skill.localeCompare(b.skill)} 
          title={t("compétences")} dataIndex="skill" key="skill" />
               <Column align="center" sorter={(a, b) => a.level.localeCompare(b.level)} 
          responsive={['lg']} 
          onFilter= {(value, record) => record.level==value}
          filters= {[
            {
              text: t("Débutant"),
              value: t("Débutant"),
            },
            {
              text: t("Intérmediaire"),
              value: t("Intérmediaire"),
            },
            {
              text: t("Avancé"),
              value: t("Avancé"),
            },
            {
              text: t("Expert"),
              value: t("Expert"),
            }, 
            
          ]}
          title={t("niveau")} dataIndex="level" key="level" />
          <Column align="center" title={t("Actions")} dataIndex="Actions" key="Actions" 
          // responsive={['lg']} 
            render={(text, question, index) => {
              return (
                <div className="">
                  {/* 
                <i title="prévu" className=" m-2" role="button"

                  onClick={() => {
                    this.setState({ question: question })
                    this.setModalVisible(true)
                  }}
                ></i> */}
                  {/* <Link to={{
                                        pathname: "/company/interviews",
                                        state: { question },
                                      }}  >
                                  <i title="Entretiens" className=" text-info m-2" role="button"
                  
                                  //  onClick={this.Delete(responsible._id)}
                                  ></i>
                  </Link> */}

                  <i
                    title="prévu"
                    className="text-primary m-2"
                    role="button"
                    onClick={() => {
                      this.setModalVisible(true);
                      this.setState({ question: question });
                    }}
                  >
                    <EyeIcon width="1.5rem" fill="#676ee7" />
                  </i>
                </div>
              );
            }}
          />
        </Table>
        </ConfigProvider>
        <Modal
          title={
            <h5 className=" m-1 text-primary">
              <b>{t("Aperçu de question")}</b>
            </h5>
          }
          className="m-5 w-75"
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
          footer={<></>}
        >
          {this.state.modalVisible && (
            <QuestionPreview question={this.state.question} />
          )}
        </Modal>
      </div>
    );
  }
}
export default React.forwardRef((props, ref) => (
  <QuestionsList innerRef={ref} {...props} />
));
