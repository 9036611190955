import axios from "../../../interceptors/axios"

export const DeleteInterview = async (data,headers) => {
    try {
    let res=await  axios.delete(`/api/interview/delete/${data.id}`,
      {
        headers: headers
    }, { withCredentials: true }
    )
    return res
} catch (err) {
    }
  }