
import axios from "../../../interceptors/axios";

export const Apply = async (data, headers) => {
  try {
  let res=await  axios.post('/api/jobPost/apply', data,
      {
        headers: headers
    }, { withCredentials: true })
return res
  } catch (err) {
  }
}