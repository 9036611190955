import React, { Component } from 'react'
import { Modal } from 'antd' 
import { CloseOutlined } from "@ant-design/icons";
import { DatePicker } from 'antd';
 import moment from 'moment-timezone';
import { AppContext } from '../../../routes/AppContext';
import { ObjectID } from "bson";
import isUrl from 'is-url';

import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
class ProjectForm extends Component {
  static contextType = AppContext;
  constructor(props){
    super(props)
    let project =this.props.project ?this.props.project :{}

    this.state={
         title:project.title?project.title:"",
         description:project.description?project.description:"",
         url:project.url?project.url:"",
         startDate:project.startDate?project.startDate:null,
         endDate:project.endDate?project.endDate:null,
         currently:(project.startDate!=null & project.endDate==null )? true :false,
 
         titleError:"",
         descriptionError:"",
         urlError:"",
         startDateError:"",
         endDateError:"",
 
     }
  }
    handleChange=(event)=> {
        const{name,value}=event.target;
        this.setState({
          [name]:value
        })
        
      }
      validation=()=>{
        let isvalid=true
        let title=this.state.title
        let description=this.state.description
        let url=this.state.url
        let startDate=this.state.startDate
        let endDate=this.state.endDate
        let currently=this.state.currently
      
     
     //title validation
     if(title=="") {
       isvalid=false
       this.setState({titleError:this.props.t("champ obligatoire")})
     }
     else 
     this.setState({titleError:""})

  //description validation
  if(description=="") {
    isvalid=false
    this.setState({descriptionError:this.props.t("champ obligatoire")})
  }
  else if(description.length<10) {
    isvalid=false
    this.setState({descriptionError:this.props.t("Minimum 10 caractères")})
  }
  else 
  this.setState({descriptionError:""}) 

   //url validation
  if(url!="") {
    if(!isUrl(url))
   { isvalid=false
    this.setState({urlError:this.props.t("url non valide")})}
  else 
  this.setState({urlError:""})  
}
  //startDate validation
  if(startDate==null) {
    isvalid=false
    this.setState({startDateError:this.props.t("champ obligatoire")})
  }
  else 
  this.setState({startDateError:""}) 

  //endDate validation
  if(!currently&&startDate!==null ){
    
    if(endDate==null ) {
      isvalid=false
      this.setState({endDateError:this.props.t("champ obligatoire")})
    }
     
    else   if(moment(endDate).isBefore(moment(startDate))){
      isvalid=false
    this.setState({endDateError:this.props.t("la date de fin doit étre apres la date de début")})}
  
    
    else 
    this.setState({endDateError:""})
  }


     return isvalid
     }
       handleSubmit=(event)=>{
         event.preventDefault();
         if(this.validation()){
           let cv=this.context.user
           if(this.props.index!=null){
             let project=cv.projects[this.props.index]
             project.title=this.state.title
             project.description=this.state.description
             project.url=this.state.url
             project.startDate=this.state.startDate
             project.endDate=this.state.endDate
             cv.projects[this.props.index]=project
            
           }
           else{

           let postedObject={
             title:this.state.title,
             description:this.state.description,
             url:this.state.url,
             startDate:this.state.startDate,
             endDate:this.state.endDate,
           }
           if(this.props.id){
            postedObject._id=this.props.id}

           cv.projects.push(postedObject)
           this.props.updateView(postedObject)
          }
          this.context.setUser(cv)
           this.props.setModalVisible(false)

      }
    }


    disabledDate=(current)=> {
      return current > moment(this.context.serverTime);
        }
      setStartDate=(value)=>{this.setState({startDate: value})}
      setEndDate=(value)=>{this.setState({endDate: value})}

    render() {
        return (
            <div>
                 <Modal
   title={<h5 className="m-1 text-primary"><b>{this.props.index===null ? this.props.t('Ajouter'): this.props.t("Modifier")} {this.props.t('un projet')}</b></h5>}
   className=""

   centered
   closeIcon={
     <i className="mt-5 ">
       <CloseOutlined
         // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
         className="m-3"
       />
     </i>
   }
   visible={this.props.modalVisible}
   onOk={() => this.props.setModalVisible(false)}
   onCancel={() => {this.props.setModalVisible(false);this.props.setIndex(null);}}
   footer={null}

 >

<div className="">
              <div className="row justify-content-start">
                <div className="  ">
                  <form   className=" "
                    onSubmit={this.handleSubmit}
                    >
                    <div className="">
                      <div className="">
                     
                        <label>{this.props.t("Titre")} *</label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            id="title"
                            type="text"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleChange}
                             
                          />
                          
                        </div>
    <p  className="text-danger"><small>{this.state.titleError}</small></p>
  
     
    
    <label>{this.props.t("url")} </label>
                        <div className="md-form">
                          <input
                            className="form-control validate mb-2"
                            id="url"
                            type="text"
                            name="url"
                            value={this.state.url}
                            onChange={this.handleChange}
                            placeholder="https://github.com/"
                             
                          />
                        </div>
    <p className="text-danger"><small>{this.state.urlError}</small></p>


                        <label className="col-12 mb-2 ">  <span className="float-left"> {this.props.t("Description")} *</span>
       <textarea 
       name="description"
       placeholder={this.props.t("Réalisation des taches 1 2 3 ...")}
        className="border form-control "
       onChange={this.handleChange}
      value={this.state.description} 
      id="description"
       rows="4"
       >
        
       </textarea>
    <p className="text-danger"><small>{this.state.descriptionError}</small></p>

       </label>

                        <div className="row mb-2" >
              <div className="col-lg-6 col-sm-12 " >

                 <label className="col-12" htmlFor="linkedinPage">
                      <span className="float-left">{this.props.t("Date de début")}  *</span>
                      <div className="" >
                      <DatePicker
                        placeholder="MM-YYYY"
                        picker="month"

                    //   allowClear={false}
                        format={"MM-YYYY"}
                          disabledDate={this.disabledDate}
                      className="border form-control"
                         defaultValue={this.state.startDate && moment(this.state.startDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                            this.setStartDate(moment.toDate())
                    }
                    else 
                    this.setStartDate(null)
                      }}
                       />
                      </div>
                    </label>
                    <p className="text-danger"><small>{this.state.startDateError}</small></p>
                    </div>
              <div className="col-lg-6 col-sm-12 " >

                  {!this.state.currently &&  <label className="col-12" htmlFor="linkedinPage">
                      <span className="float-left"> {this.props.t("Date de fin")}*</span>
                      <div className="" >
                      <DatePicker
                        placeholder="MM-YYYY"
                        picker="month"

                    //   allowClear={false}
                    //   disabledDate={this.disabledDate}
                    format={"MM-YYYY"}
                    disabledDate={
                      this.disabledDate}
                      className="border form-control"
                         defaultValue={this.state.endDate && moment(this.state.endDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                            this.setEndDate(moment.toDate())
                    }
                    else 
                    this.setEndDate(null)
                      }}
                       />
                      </div>
                    <p className="text-danger"><small>{this.state.endDateError}</small></p>

                    </label>}
                    </div></div>
                    <div className="form-check mb-4">
  <input onChange={(e)=>this.setState({currently:!this.state.currently,endDate:null})} className="form-check-input " type="checkbox" checked={this.state.currently} role="button" id="defaultCheck1"/>
  <label className="form-check-label" for="defaultCheck1">
  {this.props.t("Je travaille actuellement sur ce projet")}
  </label>
</div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className=" submitspinner  btn btn-primary btn-block z-depth-1a"
                          
                          >
                      
                      
  {this.props.t("Enregistrer")}
                          </button>
                        </div>
                      </div>
                  
                    </div>
                  </form>
                </div>
              </div>
            </div>



 </Modal>
            </div>
        )
    }
}
export default withTranslation()( withRouter(ProjectForm))