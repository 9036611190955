import { notification } from "antd";
import axios from "../../../interceptors/axios"
import gif from "../../../media/image/email.gif"

import React from 'react'
import AsyncImage from "../../../components/AsyncImage";
export const companySignup = async (data,headers ) => {
  try {
    axios.post('/api/signup/company', data,{headers:headers}, { withCredentials: true }).then(response => {
      //notification
      notification.open({

        message: ' Confirmer votre compte.',
        description: <div className="text-center"><AsyncImage src={gif} alt="email" style={{ width: "10rem", height: "10rem" }} /><br />
          <span>{response.data.message}</span>
        </div>,
        className: 'custom-class mt-5 ',
        placement: 'top',
        style: {

          backgroundColor: "#fff",
          marginTop: "5rem"
        },
      })

      // this.props.history.push('/login/entreprise')

    }).catch((error) => {
      let message = error.response.data.message ? error.response.data.message : 'Une erreur est survenue'

      //notification
      notification.error({

        description: message,
        className: 'custom-class mt-5',
        style: {
          width: 600,
          backgroundColor: "#f8d7da",
          marginTop: "5rem"
        },
      })


      // console.warn(error.response.data);
    });
  } catch (error) {
    //notification
    notification.error({

      description: "Une erreur est survenue",
      className: 'custom-class mt-5',
      style: {
        width: 600,
        backgroundColor: "#f8d7da",
        marginTop: "5rem"
      },
    })
  }
};