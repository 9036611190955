import React, { Component } from 'react'

export default class Description extends Component {
    render() {
        return (
            <div className="left">
                <b> Titre : </b>{this.props.offre.titre}<br/>
                <b> Contrat : </b>{this.props.offre.contrat}<br/>
                <b> Experience : </b>{this.props.offre.experience}<br/>
                <b> Description : </b>{this.props.offre.description}<br/>
                <b> Local : </b>{this.props.offre.local}<br/>
                <b> Postes vacants : </b>{this.props.offre.posteNbr}<br/>
                <b> Salaire : </b>{this.props.offre.salaire}<br/>


            </div>
        )
    }
}
