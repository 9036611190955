import React, { memo } from 'react'

const UsersIcon = memo((props) => {
  return (
    <>
    
<svg alt="Users" {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" >
<g id="web_x5F_settings">
	<g>
  <path d="M43,12c-0.6,0-1,0.4-1,1v22c0,0.6-0.4,1-1,1h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c1.7,0,3-1.3,3-3V13C44,12.4,43.6,12,43,12z"
  />
  <path d="M41,0H3C1.3,0,0,1.3,0,3v32c0,1.7,1.3,3,3,3h4c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h38
			c0.6,0,1,0.4,1,1v5H5C4.4,8,4,8.4,4,9s0.4,1,1,1h38c0.6,0,1-0.4,1-1V3C44,1.3,42.7,0,41,0z"/>
		<circle cx="5" cy="5" r="1"/>
		<circle cx="9" cy="5" r="1"/>
		<circle cx="13" cy="5" r="1"/>
		<path d="M22,20c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S26.4,20,22,20z M22,34c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S25.3,34,22,34z
			"/>
		<path d="M38,28c0-1.6-1.1-3.1-2.7-3.5c-0.5-0.1-0.9-0.5-1.1-1c-0.1-0.4-0.3-0.7-0.4-1.1c-0.2-0.5-0.2-1,0.1-1.5
			c0.8-1.4,0.6-3.2-0.5-4.4c-1.2-1.2-2.9-1.4-4.4-0.5c-0.4,0.3-1,0.3-1.4,0.1c-0.3-0.2-0.7-0.3-1.1-0.4c-0.5-0.2-0.8-0.6-1-1.1
			C25.1,13.1,23.6,12,22,12s-3.1,1.1-3.5,2.7c-0.1,0.5-0.5,0.9-1,1.1c-0.4,0.1-0.7,0.3-1.1,0.4c-0.5,0.2-1,0.2-1.5-0.1
			c-1.4-0.8-3.2-0.6-4.4,0.5c-1.2,1.2-1.4,2.9-0.5,4.4c0.3,0.4,0.3,1,0.1,1.4c-0.2,0.3-0.3,0.7-0.4,1.1c-0.2,0.5-0.6,0.8-1.1,1
			C7.1,24.9,6,26.4,6,28s1.1,3.1,2.7,3.5c0.5,0.1,0.9,0.5,1.1,1c0.1,0.4,0.3,0.7,0.4,1.1c0.2,0.5,0.2,1-0.1,1.5
			c-0.8,1.4-0.6,3.2,0.5,4.4c1.2,1.2,2.9,1.4,4.4,0.5c0.4-0.3,1-0.3,1.4-0.1c0.3,0.2,0.7,0.3,1.1,0.4c0.5,0.2,0.8,0.6,1,1.1
			c0.4,1.6,1.8,2.7,3.5,2.7s3.1-1.1,3.5-2.7c0.1-0.5,0.5-0.9,1-1.1c0.4-0.1,0.7-0.3,1.1-0.4c0.5-0.2,1-0.2,1.5,0.1
			c1.4,0.8,3.2,0.6,4.4-0.5c1.2-1.2,1.4-2.9,0.5-4.4c-0.3-0.4-0.3-1-0.1-1.4c0.2-0.3,0.3-0.7,0.4-1.1c0.2-0.5,0.6-0.8,1.1-1
			C36.9,31.1,38,29.6,38,28z M34.8,29.5c-1.1,0.3-2.1,1.1-2.5,2.2c-0.1,0.3-0.2,0.6-0.4,0.9c-0.5,1.1-0.4,2.3,0.2,3.3
			c0.4,0.6,0.3,1.4-0.2,1.9c-0.5,0.5-1.3,0.6-1.9,0.2c-1-0.6-2.3-0.7-3.3-0.2c-0.3,0.1-0.6,0.3-0.9,0.4c-1.1,0.4-1.9,1.3-2.2,2.5
			C23.3,41.5,22.7,42,22,42s-1.3-0.5-1.5-1.2c-0.3-1.1-1.1-2.1-2.2-2.5c-0.3-0.1-0.6-0.2-0.9-0.4c-0.5-0.2-1-0.3-1.5-0.3
			c-0.6,0-1.3,0.2-1.8,0.5c-0.6,0.4-1.4,0.3-1.9-0.2c-0.5-0.5-0.6-1.3-0.2-1.9c0.6-1,0.7-2.3,0.2-3.3c-0.1-0.3-0.3-0.6-0.4-0.9
			c-0.4-1.1-1.3-1.9-2.5-2.2C8.5,29.3,8,28.7,8,28s0.5-1.3,1.2-1.5c1.1-0.3,2.1-1.1,2.5-2.2c0.1-0.3,0.2-0.6,0.4-0.9
			c0.5-1.1,0.4-2.3-0.2-3.3c-0.4-0.6-0.3-1.4,0.2-1.9c0.5-0.5,1.3-0.6,1.9-0.2c1,0.6,2.3,0.7,3.3,0.2c0.3-0.1,0.6-0.3,0.9-0.4
			c1.1-0.4,1.9-1.3,2.2-2.5c0.2-0.7,0.8-1.2,1.5-1.2s1.3,0.5,1.5,1.2c0.3,1.1,1.1,2.1,2.2,2.5c0.3,0.1,0.6,0.2,0.9,0.4
			c1.1,0.5,2.3,0.4,3.3-0.2c0.6-0.4,1.4-0.3,1.9,0.2c0.5,0.5,0.6,1.3,0.2,1.9c-0.6,1-0.7,2.3-0.2,3.3c0.1,0.3,0.3,0.6,0.4,0.9
			c0.4,1.1,1.3,1.9,2.5,2.2c0.7,0.2,1.2,0.8,1.2,1.5S35.5,29.3,34.8,29.5z"/>
		<circle cx="22" cy="28" r="2"/>
	</g>
</g>
<g id="web_x5F_search">
</g>
<g id="web_x5F_speed">
</g>
<g id="web_x5F_award">
</g>
<g id="funnel">
</g>
<g id="chain">
</g>
<g id="globe_x5F_settings">
</g>
<g id="target">
</g>
<g id="web_x5F_coins">
</g>
<g id="pc_x5F_chart">
</g>
<g id="marketing">
</g>
<g id="flag">
</g>
<g id="search">
</g>
<g id="clipboard">
</g>
<g id="pc_x5F_lock">
</g>
<g id="idea">
</g>
<g id="ppc">
</g>
<g id="web_x5F_eye">
</g>
<g id="globe_x5F_location">
</g>
<g id="target_x5F_audience">
</g>
  </svg></>
  )
})

export default UsersIcon