import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AppContext } from '../../routes/AppContext';
import QuestionComponent from './QuestionComponent';

export default function TestQuestions(props) {
  const {envVar } = useContext(AppContext);
  const [currentQuestion,setCurrentQuestion]=useState(0)
  const answer=useRef([])
  // const testingTime=useRef(0)
  const timeLeft=useRef(0)

const addAnswer=(val)=>{
  answer.current=val
}
const convertTime=(val)=>{
  let m=Math.floor(val/60)
  let s=val%60
  return m+":"+s
}
        //  openFullscreen
       const openFullScreen=()=>{
              let elem = document.getElementById("test");
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        } 
      }
      let downloadTimer=useRef()
    
        const next=()=>{
          // testingTime.current+=timeLeft.current
          clearInterval(downloadTimer.current);
        
          props.pushAnswer(answer.current)
          if(currentQuestion<props.questions.length-1)
          {setCurrentQuestion(currentQuestion+1)
          }
          else{
            props.finishTest(timeLeft.current)
          }
          addAnswer([])
        }

    useEffect(() => {
      let count=60
      switch (props.questions[currentQuestion].level) {
        case 0: count = parseInt(envVar.REACT_APP_QUESTION_TIME_0);
          break;
          case 1: count = parseInt(envVar.REACT_APP_QUESTION_TIME_1);
          break;
          case 2: count = parseInt(envVar.REACT_APP_QUESTION_TIME_2);
          break;
          case 3: count = parseInt(envVar.REACT_APP_QUESTION_TIME_3);
          break;
        default:
          break;
      }
      
      let timeleft = count;
      // timeLeft.current=count
      
       downloadTimer.current = setInterval(function () {
          if (timeleft <= 0) {
            next()
          }
          if(timeleft<=20&&timeleft>=10){
            document.getElementById("progress-bar").className="progress-bar bg-warning"
            document.getElementById("countDown").className="right  m-2 text-warning "
          }
          else if(timeleft<10){
          document.getElementById("progress-bar").className="progress-bar bg-danger"
          document.getElementById("countDown").className="right  m-2 text-danger "
          }
          else {
          document.getElementById("progress-bar").className="progress-bar bg-primary"
          document.getElementById("countDown").className="right  m-2 text-primary "
          }
          document.getElementById("countDown").innerHTML = convertTime(timeleft);
          document.getElementById("progress-bar").style.width=`${timeleft*100/count}%`
          timeLeft.current++
          timeleft -= 1;
        }, 1000);
      
              }, [currentQuestion]);
        const closeFullscreen=()=> {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
              document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
              document.msExitFullscreen();
            }
          }
    useEffect(() => {
openFullScreen()
//   window.addEventListener("fullscreenchange",  e => {
// openFullScreen()
//   })
  window.addEventListener('keydown', e => {
      e.stopPropagation()
      e.stopImmediatePropagation()
      e.preventDefault();
    // openFullScreen()
    return false
  });


//   setInterval(() => {
//     if(window.innerWidth == window.outerWidth && window.innerHeight == window.outerHeight){
//     }else{
// openFullScreen()
//     }
//   }, 1000);



//   navigator.keyboard.lock();
      }, []);

      let i=-1
      const list=useMemo(()=>props?.questions.map(q=>{
        i++
        return(
          <QuestionComponent key={i} question={q} setAnswer={addAnswer} />
      )}),[])
    
 return (
    <div id="test" className='bg-light w-100 h-75 ' >
      
      <div>
      <div style={{height:"25px"}} className="progress">
        <div id="progress-bar" className="progress-bar bg-primary" style={{ width: "100%"}}></div>
      </div>
      <h3  className="right  m-2 text-primary "  id="countDown">0</h3>
    </div>
    <br/>
    <div className='my-3 align-middle' >
    <br/>
        {list[currentQuestion]}
    </div>
        <button onClick={()=>{next()}} className='btn btn-primary right' >Suivant</button>
    </div>
  )
}
