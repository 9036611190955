import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import img1 from './assets/images/hero-2-img.png';
import img2 from './assets/images/hero-2-shape.png';
import { withTranslation } from "react-i18next";
class Section extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="hero-2-bg position-relative d-flex align-items-center bg-gradient-primary" id="home">
                    <div className="hero-2-bg-overlay"></div>
                    <Container>
                        <div className="hero-2-content">
                            <Row className="align-items-center">
                                <Col lg={6}>
                                    <div className="hero-2-title pr-lg-5">
                                    <h1 className="hero-2-title text-white font-weight-normal line-height-1_4"> <b> {this.props.t("Recrutez simplement")}   ,</b></h1>
                                        <h1 className="hero-2-title text-white mb-4 font-weight-normal line-height-1_4"> <b> {this.props.t("plus vite")} </b></h1>
                                        <p className="text-white-70 font-weight-light f-18 line-height-1_6 mb-5 ">{this.props.t("Une application SaaS")} </p>
                                        <Link  to="/demo" className="btn btn-warning mr-lg-2"><i className="mdi mdi-link-variant mr-2"></i>{this.props.t("Demande de démo")} </Link>
                                        <Link to="/signup/company" className="btn btn-light ms-3">{this.props.t("Essayer gratuitement")}  </Link>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-5 mt-lg-0">
                                        <img src={img1} alt="" className="img-fluid d-block mx-auto"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="hero-bottom-img">
                        <img src={img2} alt="" className="img-fluid d-block w-100 mx-auto"/>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Section);