import React, { Component } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Dropdown,
  Button,
  Card,
  notification,
  Drawer,
  Badge,
} from "antd";
import { Link, Redirect, withRouter } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
// import logo from "../../media/image/recin-transparent.webp";
// import { logo } from "./Logo";
import logoText from "../../media/image/rec-text-blan.png";
import logo from "../../media/image/rec-logo-blan.png";
import {
  DesktopOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  PlusCircleOutlined,
  DiffOutlined,
  RocketOutlined,
  UserOutlined,
  UnorderedListOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
  RightOutlined,
  LeftOutlined,
  SettingOutlined,
  LogoutOutlined,
  BoldOutlined,
LockOutlined,
QuestionCircleOutlined
} from "@ant-design/icons";
import BellIcon from "../icons/BellIcon";
import HeartIcon from "../icons/HeartIcon";
import BlogIcon from "../icons/BlogIcon";
import Cv from "../cvPDF/Cv";
import { GetCv } from "../../services/api/cv/GetCv";
import decrypt from "../../utils/decrypt";
import { AppContext } from "../../routes/AppContext";
import AsyncImage from "../AsyncImage";
import Axios from "axios";
import NotificationDropdown from "../notification/NotificationDropdown";
import { GetCandidateNotification } from "../../services/api/notification/GetCandidateNotification";
import { withTranslation } from "react-i18next";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class CandidateNavbar extends Component {  
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
    collapsed: true,
    visible:false,
    notifications:[],
    logoSelected : logoText
  };
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    console.log(window.innerWidth)
    if(window.innerWidth <992)
      this.setState({ logoSelected:logo})
    else
    this.setState({ logoSelected:logoText})
  }
  // async componentDidMount(){

  // }
SetVisible=(value)=>{
  this.setState({visible:value})
}
  GetMyCV = async () => {
    let response = await GetCv(null, this.context.headers);
 if(response){
    let cv = JSON.parse(decrypt(response.data));
    let hardSkillsList = this.context.hardSkillsList
    let softSkillsList = this.context.softSkillsList
    let languagesList = this.context.languagesList
    let hardSkills = [];
    let softSkills = [];
    let languages = [];
    for (let i = 0; i < cv.hardSkills.length; i++) {
      for (let j = 0; j < hardSkillsList.length; j++) {
        if (cv.hardSkills[i].code == hardSkillsList[j].code) {
          let skill = hardSkillsList[j];
          skill.score = cv.hardSkills[i].score;
          skill.id = cv.hardSkills[i]._id;
          hardSkills.push(skill);
        }
      }
    }
    for (let i = 0; i < cv.softSkills.length; i++) {
      for (let j = 0; j < softSkillsList.length; j++) {
        if (cv.softSkills[i].code == softSkillsList[j].code) {
          let skill = softSkillsList[j];
          skill.score = cv.softSkills[i].score;
          skill.id = cv.softSkills[i]._id;
          softSkills.push(skill);
        }
      }
    }
    for (let i = 0; i < cv.languages.length; i++) {
      for (let j = 0; j < languagesList.length; j++) {
        if (cv.languages[i].code == languagesList[j].code) {
          let skill = languagesList[j];
          skill.score = cv.languages[i].score;
          skill.id = cv.languages[i]._id;
          languages.push(skill);
        }
      }
    }
    cv.hardSkills = hardSkills;
    cv.softSkills = softSkills;
    cv.languages = languages;
   this.context.setUser(cv)
   cv?.educations?.length?this.props.history.push("/candidate/myCv"):
   this.props.history.push("/candidate/cv/upload");}
  };
  RedirectMe = (e) => {
    // let cryptedToken = localStorage.getItem("token");
    // let bytes = CryptoJS.AES.decrypt(
    //   cryptedToken,
    //   process.env.REACT_APP_CRYPTING_KEY
    // );
    // let token = bytes.toString(CryptoJS.enc.Utf8);
    // const decodedToken = jwt.verify(
    //   JSON.parse(token),
    //   process.env.REACT_APP_JWT_SECRET
    // );
    // let candidate = decodedToken.data;
    // let cv=JSON.parse(sessionStorage.getItem("cv"))
    // if (candidate?.address?.length == 0) {
    //   this.props.history.push("/candidate/profile");
    //   //notification
    //   notification.warn({
    //     description: "veuiller remplir votre profil pour continuer",
    //     className: "custom-class",
    //     style: {
    //       width: 600,
    //     },
    //   });
    //   return "";
    // }
    //  else if (!cv?.experiences?.length) {
    //   this.props.history.push("/candidate/cv");
    //   notification.warn({
    //     description: "veuiller remplir votre cv pour continuer",
    //     className: "custom-class",
    //     style: {
    //       width: 600,
    //     },
    //   });
    //   return "";
    // }

    
    switch (e.key) {
      case "1":
        this.props.history.push("/candidate/profile");
        break;
      case "s02":
        this.props.history.push("/candidate/contact");
        break;
      case "2":
        if(this.context.user._id){
         (this.context.candidateAccess)?this.props.history.push("/candidate/myCv"):
         this.props.history.push("/candidate/cv/upload");
        }
        else{
this.GetMyCV()
          
        }
        break;
      case "3":
        this.props.history.push("/candidate/jobPosts");
        break;
      case "4":
        this.props.history.push("/candidate/training");
        break;
      case "5":
        this.props.history.push("/candidate/test");
        break;
      case "6":
        this.props.history.push("/candidate/applications");
        break;
        case "7":
          this.props.history.push("/candidate/dashboard");
          break;
      default:
        break;
    }
  };
  // componentDidMount() {
 
  // }
  getCandidateNotifications=async()=>{
    if(this.context.notificationsNumber>0||this.state.notifications.length==0)
 {  
   let response =await GetCandidateNotification(null, this.context.headers)
 if(response){
    let notifications = JSON.parse(decrypt(response.data));
    this.setState({notifications})}
  }
    this.context.setNotificationsNumber(0)
  }
  onCollapse = (collapsed) => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  MenuButton = (props) => (
    <div className="fixed-bottom w-25 z-500 ">
      <button
        className="   btn-primary btn-lg rounded "
        style={{ width: props.collapsed ? "5rem" : "17rem", outline: "none" }}
        onClick={this.onCollapse}
      >
        {props.collapsed ? <RightOutlined /> : <LeftOutlined />}
      </button>
    </div>
  );
  
  render() {
    const { collapsed } = this.state;
  const menu = (
    <Menu style={{ width:"160px"}}>
      <Menu.Item key="1" icon={<BoldOutlined />}>
        <Link to="/blog">{this.props.t("Blogs")} </Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<SettingOutlined />}>

      <Link to="/candidate/setting">{this.props.t("Paramètres")} </Link>
    
      </Menu.Item>
      <Menu.Item key="3" icon={<LockOutlined />}>
        <Link to="/candidate/politique-confidentialite">{this.props.t("politique...")} </Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<QuestionCircleOutlined />}>
        <Link to="/candidate/faq">{this.props.t("FAQ")}</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<LogoutOutlined />}>
        <Link to="/logout">{this.props.t("Déconnecter")} </Link>
      </Menu.Item>
    </Menu>
  );
    return (
      <>
     { this.props.candidate.role=="candidate"
      ?
      <div className="navbar-candidate">
        <Layout>
          <div className="shadow-lg">
            <nav
              className="navbar navbar-expand-lg navbar-dark"
              style={{
                height: "",
                boxShadow: "0 4px 2px -2px white",
                padding: "0",
              }}
            >
              <Link to="/candidate/jobPosts">
                <div className="navbar-brand ml-2">
                  <AsyncImage
                    alt="recinov"
                    src={this.state.logoSelected}
                    className="m-1 "
                    style={{  height: "3rem" }}
                  />
                  {/* <b className="d-none d-lg-inline  ">Rec-INOV</b> */}
                </div>
              </Link>

              {/* <div className="m-0 d-lg-none ">
                <div
                  className=" p-1 btn btn-primary  "
                  style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                >
                  <HeartIcon fill="white" width="20px" />
                </div>
      
              </div> */}
                  <div className="d-lg-none m-0">
                    <LanguageSelector/>
                  </div>
              <div className="d-lg-none dropdown">
                <NotificationDropdown notifications={this.state.notifications} >
                <div
                onClick={this.getCandidateNotifications}
               id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" renderMenuOnMount="true"
                  className=" p-1 btn btn-primary  "
                  style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                >
                      <Badge
                    style={{ boxShadow: "0 0 0 1px red" }}
                    size="small"
                    count={this.context.notificationsNumber}
                  >
                  <BellIcon fill="white" width="20px" />
                  </Badge>
                </div>
                </NotificationDropdown>
              
              {/* <div  className="dropdown-menu dropdown-menu-lg-right dropdown-secondary  border-dark" aria-labelledby="dropdownMenuButton" style={{backgroundColor:"#FFF",height:"70vh",width:"90vw",overflowX: "hidden", overflowY: "scroll",marginLeft:"5vw",marginRight:"5vw"}} >
             <h5 className="ml-2">Notifications</h5>
              {notifications}
            </div>  */}
      
              </div>
               
              {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button> */}
              <Link to="">
                <div className="d-lg-none m-2">
                  <Dropdown
                    overlay={menu}
                    placement="bottom"
                    trigger={["click"]}
                  >
                    <div
                      onClick={(e) => e.preventDefault()}
                      className=" p-1 btn btn-primary bg-primary ant-dropdown-link  icons15"
                    >
                     
                     {this.props.candidate.image ? (
                      <AsyncImage
                        className="logo rounded-circle"
                        alt="recinov"
                        src={
                          this.context.envVar.REACT_APP_CLIENT_URL
                          + "/candidate/images/" +
                          this.props.candidate.image
                        }
                      />
                    ) : (
                      <UserOutlined />
                    )}
                      
                    </div>
                  </Dropdown>
                </div>
              </Link>
              <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav mr-auto">
                  <li className="d-lg-none">
                    <Link to="/logout">
                      <span className="navbar-text  ">
                        {" "}
                        <div className="nav-link">{this.props.t("Déconnecter")}</div>{" "}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
  
              {/* <div className="m-0 d-none d-lg-inline">
                <div
                  className="p-1 btn btn-primary m-2  "
                  style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                >
                  <HeartIcon fill="white" width="20px" />
                </div>
         
              </div> */}
              <div className="m-0 d-none d-lg-inline m-2">
              <NotificationDropdown notifications={this.state.notifications}>
                <div
                  className=" p-1 btn btn-primary  "
                  style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                onClick={this.getCandidateNotifications}

                >
                      <Badge
                    style={{ boxShadow: "0 0 0 1px red" }}
                    size="small"
                    count={this.context.notificationsNumber}
                  >
                  <BellIcon fill="white" width="20px" />
                  </Badge>
                </div>
           </NotificationDropdown>
              </div>
              <div className="m-0 d-none d-lg-inline m-2">
                    <LanguageSelector/>
                  </div>
              <Dropdown
                overlay={menu}
                placement="bottom"
                trigger={["click"]}
              >
                <div className=" m-2 btn btn-primary rounded-pill p-1 d-none d-lg-inline border ">
                  <div className="rounded-circle p-1 btn btn-primary icons15">
                    {this.props.candidate.image ? (
                      <AsyncImage
                        className="logo rounded-circle"
                        alt="recinov"
                        src={
                          this.context.envVar.REACT_APP_CLIENT_URL
                          + "/candidate/images/" +
                          this.props.candidate.image
                        }
                      />
                    ) : (
                      <UserOutlined />
                    )}
                  </div>
                  <b className="text-white d-none d-lg-inline m-0">
                    {this.props.candidate.name}
                  </b>
                </div>
              </Dropdown>
            </nav>
          </div>
          <div className=" d-lg-none text-center" >
          <Menu
                 mode="horizontal"
                // defaultSelectedKeys={["1"]}
                // defaultOpenKeys={["1"]}
                onClick={(e) => this.RedirectMe(e)}
                 className="d-flex justify-content-around w-100 p-1"
              >
                     <Menu.Item key="7" icon={<HomeOutlined />}>
                  <div></div>
                </Menu.Item>{" "}

                <Menu.Item className="m-0 p-1 "  key="1" icon={<DesktopOutlined />} >
                  <small>{this.props.t("Profil")}</small>
                </Menu.Item>
                <Menu.Item className="m-0 p-1"  key="2" icon={<UserOutlined />}>
                <small>{this.props.t("Mon CV")}</small>
                  
                </Menu.Item>
                <Menu.Item
                  className="m-0 p-1"
                  key="3"
                  icon={<UnorderedListOutlined />}
                >
                <small>{this.props.t("Liste Offres")}</small>
                  
                </Menu.Item>
                <Menu.Item className="m-0 p-1" key="6" icon={<DatabaseOutlined />}>
                <small>{this.props.t("Mes Candidatures")}</small>
                 
                </Menu.Item>
                </Menu>
          </div>

          <Layout  >

            <Sider
              collapsed={collapsed}
              onCollapse={this.onCollapse}
              breakpoint="lg"
              width={"17rem"}
              collapsedWidth={"5rem"}
              style={{
                backgroundColor: "#005bea",
                boxShadow: "4px 0 2px -2px gray",
              }}
              className="site-layout-background  d-none d-lg-inline"
            >
              <Menu
                mode="inline"
                // defaultSelectedKeys={["1"]}
                // defaultOpenKeys={["1"]}
                onClick={(e) => this.RedirectMe(e)}
                className="MenuSider"
              >
                    <Menu.Item key="7" icon={<HomeOutlined />}>
                  <div>{this.props.t("Tableau de bord")}</div>
                </Menu.Item>{" "}
                <Menu.Item className="" key="1" icon={<DesktopOutlined />}>
                  
                  {this.props.t("Profil")}
                </Menu.Item>{" "}
                <Menu.Item className="" key="2" icon={<UserOutlined />}>
                 
                  {this.props.t("Mon CV")}
                </Menu.Item>{" "}
                <Menu.Item
                  className=""
                  key="3"
                  icon={<UnorderedListOutlined />}
                >
                  {this.props.t("Liste Offres")}
                 
                </Menu.Item>{" "}
                <Menu.Item className="" key="6" icon={<DatabaseOutlined />}>
                  
                  {this.props.t("Mes Candidatures")}
                </Menu.Item>{" "}
                {/* <Menu.Item className="m-1" key="4" icon={<RocketOutlined />}>
                  Formation
                </Menu.Item>{" "}
                <Menu.Item className="m-1" key="5" icon={<FileTextOutlined />}>
                  Test
                </Menu.Item>{" "} */}
                <this.MenuButton collapsed={collapsed} />
              </Menu>
            </Sider>
            <Layout className="sectionBlanc sectionIN"  >
              <Content
                className="site-layout-background candidate-bg"
                style={{
                  padding: 24,
                  margin: 0,
                  // backgroundImage:"url('../../media/backgroundImage/profil-contact-e.jpg')"
                }}
              >
                <Card className="m-3 mt-5 rounded shadow bg-white  " style={{}}>
                  {this.props.children}
                </Card>
              </Content>
            </Layout>
          </Layout>
        </Layout>


  


      </div>:<>{this.props.children}</>}</>
    );
  }
}
export default withTranslation()(withRouter(CandidateNavbar));
