import React, { useEffect, useState } from 'react'
import QuestionComponent from './QuestionComponent'

export default function Questions(props) {
  const [index, setIndex] = useState(0);
useEffect(()=>{
  if(index==props.questions.length&&props.answers.length){
    props.set()
  }
},[index])
    let list= props.questions.map((question,i)=>{
        return(<QuestionComponent index={index} myIndex={i} setIndex={setIndex} key={i} question={question} setAnswers={props.setAnswers} answers={props.answers} />)
    })
  return (
    <div>
        {list}
    </div>
  )
}
