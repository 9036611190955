import React, { useContext, useMemo, useState } from "react";
import Test from "../test/Test";
import TestDetails from "./TestDetails";
import TestResult from "./TestResult";
import TestQuestions from "./TestQuestions";

import { useHistory } from "react-router-dom";
import { notification } from "antd";
import { GenerateRandomTest } from "../../services/api/skillTest/GenerateRandomTest";

import { AppContext } from "../../routes/AppContext";
export default function SkillTestingContainer(props) {
  const history = useHistory({});
  const {headers} = useContext(AppContext);

  const [authenticated, setAuth] = useState(false);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [time, setTime] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  let getRandomTest = async () => {
    let response = await GenerateRandomTest(
      {
        
        codeSkill: props.codeSkill,
        level: props.level,
      },
      headers
    );
    if(response){
    setQuestions(response.data);
    setStarted(true);}
  };
  const pushAnswer = (answer) => {
    let toPush = answers;
    toPush.push(answer);
    setAnswers(toPush);
  };
  const shuffle = (a) => {
    for (let i = a?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };
  const shuffledQuestions = useMemo(() => shuffle(questions), [questions]);
  const finishTest = (time) => {
    setFinished(true);
    setTime(time);
  };
  return (
    <div>
      {!authenticated && <Test setAuth={setAuth} />}
      {authenticated && !started && !finished && (
        <TestDetails
          getRandomTest={getRandomTest}
          questions={shuffledQuestions}
        />
      )}
      {started && !finished && (
        <TestQuestions
          setTime={setTime}
          pushAnswer={pushAnswer}
          questions={shuffledQuestions}
          answers={answers}
          setAnswers={setAnswers}
          finishTest={finishTest}
        />
      )}
      {finished && (
        <TestResult
          skill={props.skill}
          userId={props.userId}
          testTime={props.testTime}
          time={time}
          level={props.level}
          questions={shuffledQuestions}
          answers={answers}
          idSkill={props.idSkill}
          feature={props.feature}
          idCandidateSkill={props.idCandidateSkill}
        />
      )}
    </div>
  );
}
