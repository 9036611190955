import axios from "axios";
import React, { Component } from "react";
import {
  notification,
  Select,
  DatePicker,
  Switch,
  Divider,
  Space,
  Typography,
  AutoComplete,
  Modal,
  Button,
} from "antd";
import moment from "moment-timezone";
import CryptoJS from "crypto-js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ImageUploader from "./ImageUploader";

import InscriptionSteps from "../InscriptionSteps";
import { withRouter } from "react-router";
import { profileCandidate } from "../../services/api/profile/candidateProfile";
import decrypt from "../../utils/decrypt";
import { PlusOutlined } from "@ant-design/icons";
import { AppContext } from "../../routes/AppContext";
import gif from "../../media/image/happywork.gif"
import AsyncImage from "../AsyncImage";

import { withTranslation } from "react-i18next";
const { Option } = Select;

// import { Link } from "react-router-dom";

class CandidateProfile extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props);
    //   let entreprise=JSON.parse(sessionStorage.getItem("user"))
    const { user } = context;
    // let cryptedToken = localStorage.getItem("token");
    // let bytes = CryptoJS.AES.decrypt(
    //   cryptedToken,
    //   process.env.REACT_APP_CRYPTING_KEY
    // );
    // let token = bytes.toString(CryptoJS.enc.Utf8);
    // const decodedToken = jwt.verify(
    //   JSON.parse(token),
    //   process.env.REACT_APP_JWT_SECRET
    // );
    let candidate = user;
    let locations = [];
    let selectedLocations = [];
    console.log(candidate)
    candidate.locations
      ? candidate.locations.map((item) => {
          selectedLocations.push(item.location);
        })
      : candidate.idLocations.map((item) => {
          selectedLocations.push(item.location);
          locations.push(item);
        });
    this.state = {
      id: candidate.id,
      name: candidate.name,
      lastName:candidate.lastName,
      email: candidate.email,
      searchedValue: "",
      image: candidate.image,
      address: candidate.address,
      phone: candidate.phone !== null ? "+" + candidate.phone.toString() : "",
      birthday: candidate.birthday,
      gender: candidate.gender,

      desiredJobPost: candidate.desiredJobPost,
      desiredSalary: candidate.desiredSalary,
      contractType: candidate.contractType,
      handicap: candidate.handicap,
      workAuthorization: candidate.workAuthorization,
      availabilityDate: candidate.availabilityDate,
      viewcv: candidate.viewcv !== undefined ? candidate.viewcv : true,

      //au moins 1
      locations: candidate.locations ? candidate.locations : locations,
      selectedLocations: selectedLocations,
      // locationsList:sessionStorage.getItem("locations") ? JSON.parse(decrypt(sessionStorage.getItem("locations"))):[],
      desiredJobPostError: "",
      desiredSalaryError: "",
      imageError: "",
      addressError: "",
      phoneError: "",
      birthdayError: "",
      locationsError: "",
      genderError: "",
      jobTitles:context.jobTitles,
      desiredJobPostMessage:true,
      loading:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  onChange=(value)=> {
    this.setState({
      desiredJobPost: value,
    });
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  setImage = (value) => {
    this.setState({ image: value });
  };
  validation = () => {
    let isvalid = true;
    let image = this.state.image;
    let address = this.state.address;
    let phone = this.state.phone;
    let birthday = this.state.birthday;
    let locations = this.state.locations;
    let desiredJobPost = this.state.desiredJobPost;
    let desiredSalary = this.state.desiredSalary;
    let gender = this.state.gender;
    //desiredJobPost validation
    if (desiredSalary != "" && desiredSalary != null) {
      if (desiredSalary < 100) {
        isvalid = false;
        this.setState({ desiredSalaryError: "minimum 100" });
      } else if (desiredSalary > 10000) {
        isvalid = false;
        this.setState({ desiredSalaryError: "maximum 10000" });
      } else this.setState({ desiredSalaryError: "" });
    } else this.setState({ desiredSalaryError: "" });

    //desiredJobPost validation
    if (desiredJobPost == "") {
      isvalid = false;
      this.setState({ desiredJobPostError: this.props.t("champ obligatoire") });
    } else this.setState({ desiredJobPostError: "" });

    //address validation
    if (address == "") {
      isvalid = false;
      this.setState({ addressError: this.props.t("champ obligatoire") });
    } else this.setState({ addressError: "" });

    //image validation
    // if (image == null) {
    //   isvalid = false;
    //   this.setState({ imageError: this.props.t("champ obligatoire") });
    // } else this.setState({ imageError: "" });

    //birthday validation
    if (birthday == null) {
      isvalid = false;
      this.setState({ birthdayError: this.props.t("champ obligatoire") });
    } else this.setState({ birthdayError: "" });

    //locations validation
    if (locations.length == 0) {
      isvalid = false;
      this.setState({ locationsError: this.props.t("champ obligatoire") });
    } else this.setState({ locationsError: "" });

    //phone validation
    if (phone == undefined) {
      isvalid = false;
      this.setState({ phoneError: this.props.t("champ obligatoire") });
    } else if (!isValidPhoneNumber(phone)) {
      this.setState({ phoneError: this.props.t("Numéro non valide") });
      isvalid = false;
    } else this.setState({ phoneError: "" });

     //gender validation
 if (gender == null) {
  isvalid = false;
  this.setState({ genderError: this.props.t("champ obligatoire") });
} else this.setState({ genderError: "" });

    return isvalid;
  };
  async handleSubmit(event) {
    event.preventDefault();

    if (this.validation()) {
      this.setState({loading:true})
      let response = await profileCandidate(
        {
          
          name: this.state.name,
          lastName: this.state.lastName,
          address: this.state.address,
          phone: this.state.phone,
          birthday: this.state.birthday,
          gender: this.state.gender,
          desiredSalary: this.state.desiredSalary,
          desiredJobPost: this.state.desiredJobPost,
          contractType: this.state.contractType,
          handicap: this.state.handicap,
          workAuthorization: this.state.workAuthorization,
          availabilityDate: this.state.availabilityDate,
          locations: this.state.locations,
          viewcv:this.state.viewcv
        },
        {
          headers: this.context.headers
        }
      );
 if(response){
      let data = this.context.user;

      data.name = this.state.name;
      data.lastName = this.state.lastName;
      data.image = this.state.image;
      data.updated = true;
      data.address = this.state.address;
      data.phone = parseInt(this.state.phone.substring(1), 10);
      data.birthday = this.state.birthday;
      data.gender = this.state.gender;
      data.desiredSalary = this.state.desiredSalary;
      data.desiredJobPost = this.state.desiredJobPost;
      data.contractType = this.state.contractType;
      data.handicap = this.state.handicap;
      data.workAuthorization = this.state.workAuthorization;
      data.availabilityDate = this.state.availabilityDate;
      data.viewcv=this.state.viewcv;
      //au moins 1
      data.locations = this.state.locations;
      this.context.setUser(data);

      //notification
      notification.success({
        description: this.props.t(response.data.code),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem",
        },
      });

      this.props.history.push("/candidate/cv/upload");}

      //
    }
  }
  disabledBirthday = (current) => {
    let date = moment(this.context.serverTime).subtract(15, "years");
    // Can not select days before today and today
    return current > date;
  };
  disabledAvailabilityDate = (current) => {
    // Can not select days before today and today
    return current < moment(this.context.serverTime);
  };

  handleSelect = (value, i) => {
    // this.setState({ skill:value,key:i.key });
    //    alert(value)
    let locations = [];
    //  if (i?.length > 4) {
    //   i.pop();
    // }
    const { locationsList } = this.context;

    i.map((loc) => {
      let location = locationsList[loc.key];
      location.score = 1;
      locations.push(location);
    });
    this.setState({ locations: locations, selectedLocations: value });
  };

  setPosition = (position) => {
    this.setState({ localization: position });
  };

  render() {
    const children = [];
    const { locationsList, jobTitles } = this.context;
 
    // let locationsList = this.state.locationsList;
    for (let i = 0; i < locationsList.length; i++) {
      children.push(
        <Option
          disabled={
            (this.state.locations.length > 3) &
            !this.state.selectedLocations.includes(locationsList[i].location)
              ? true
              : false
          }
          key={i}
          value={locationsList[i].location}
        >
         <div className='capitalize' > {locationsList[i].location}</div>
        </Option>
      );
    }
    const options = this.context.jobTitles;
    return (
      <div className="">
        <div className=" m-1">
          <div className="">
            <form className=" rounded" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className=" col-lg-6 col-sm-12 ">
                  <label className="col-12  " htmlFor="name">
                    <span className="">{this.props.t("Nom et prénom")} </span> <br />
                    <div className="row" >
                    <div className="col-6" >
                    <input
                      className="border form-control pe-0"
                      id="name"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />

                    </div>
                    <div className="col-6" >
                    <input
                      className="border form-control"
                      id="lastName"
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                    />

                    </div>
                    </div>
                  </label>
                  <p className="text-danger">
                    <small>{this.state.sectorError}</small>
                  </p>

                  <label className="col-12  " htmlFor="email">
                    <span className="">{this.props.t("Email")} </span> <br />
                    <input
                      className="border form-control "
                      id="email"
                      type="text"
                      name="email"
                      value={this.state.email}
                      readOnly
                    />
                  </label>
                  <p className="text-danger">
                    <small>{this.state.sectorError}</small>
                  </p>

                  <label className=" col-12 " htmlFor="birthday">
                    <span className="float-left"> {this.props.t("Date de naissance")}  <b style={{    color: "blue"}}>* </b></span>
                    <div className="col-12">
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        className="border form-control"
                        placeholder="DD-MM-YYYY"
                        // value={moment(this.state.companyCreationDate)}
                        disabledDate={this.disabledBirthday}
                        defaultValue={
                          this.state.birthday && moment(this.state.birthday)
                          // .format("DD-MM-YYYY")
                        }
                        onChange={(moment, date) => {
                          if (moment != null) {
                            this.setState({ birthday: moment.toDate() });
                          } else {
                            this.setState({ birthday: null });
                          }
                        }}
                      />
                    </div>
                  </label>
                  <p className="text-danger">
                    <small>{this.state.birthdayError}</small>
                  </p>
                  <label className="">{this.props.t("Genre")}  <b style={{    color: "blue"}}>* </b></label>
                  <br />
                  <Select
                    // style={{ width: 200 }}
                    placeholder={this.props.t("Selectionner..")}
                    className=" w-100"
                    // style={{width:"100%"}}
                    onChange={(value) => this.setState({ gender: value })}
                    value={this.state.gender}
                  >
                    <Option value={1}>{this.props.t("Homme")} </Option>
                    <Option value={2}>{this.props.t("Femme")} </Option>
                  </Select>
                  <br />
                  <p className="text-danger">
                    <small>{this.state.genderError}</small>
                  </p>

                  <label className=" col-12 " htmlFor="desiredSalary">
                    <span className="float-left"> {this.props.t("Salaire mensuel désiré")}  </span>
                    <input
                      className="border form-control "
                      id="desiredSalary"
                      type="number"
                      name="desiredSalary"
                      value={this.state.desiredSalary}
                      onChange={this.handleChange}
                    />
                  </label>

                  <p className="text-danger">
                    <small>{this.state.desiredSalaryError}</small>
                  </p>
                  <label className="">{this.props.t("Lieux de travail")}  <b style={{    color: "blue"}}>* </b></label>
                  <br />
                  <Select
                    className="border form-control w-75 p-0"
                    // style={{ width: '40%' }}
                    defaultValue={this.state.selectedLocations}
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder={this.props.t("Rechercher pour sélectionner")}
                    onChange={this.handleSelect}
                  >
                    {children}
                  </Select>
                  <br />
                  <p className="text-danger">
                    <small>{this.state.locationsError}</small>
                  </p>
                 

                  <label className="m-2 ">
                    <Switch
                      className="me-1"
                      size="small"
                      checked={this.state.handicap}
                      onChange={() => {
                        this.setState({ handicap: !this.state.handicap });
                      }}
                    />
                    {this.props.t("Handicapé")} 
                  </label>
                  <label className="m-2">
                    <Switch
                      className="me-1"
                      size="small"
                      checked={this.state.workAuthorization}
                      onChange={() => {
                        this.setState({
                          workAuthorization: !this.state.workAuthorization,
                        });
                      }}
                    />
                    {this.props.t("Autorisation de travail")}
                  </label>
                  <br />
                  <label className="m-2">
                    <Switch
                      className="me-1"
                      size="small"
                      checked={this.state.viewcv}
                      onChange={() => {
                        this.setState({
                          viewcv: !this.state.viewcv,
                        });
                      }}
                    />
                    {this.props.t("Votre CV est afficher pour les entreprise")}
                  </label>
                  <br/>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <label className="col-12  " htmlFor="address">
                    <span className="">
                    {this.props.t("Adresse")}    <b style={{    color: "blue"}}>* </b>
                    </span>{" "}
                    <br />
                    <input
                      className="border form-control "
                      id="address"
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.handleChange}
                    />
                  </label>
                  <p className="text-danger">
                    <small>{this.state.addressError}</small>
                  </p>

                  <div className="  ">
                    <label className="col-12  " htmlFor="tel">
                      <span className="float-left"> {this.props.t("Téléphone")} <b style={{    color: "blue"}}>* </b> </span>
                      <br />

                      <PhoneInput
                        defaultCountry="TN"
                        numberInputProps={{ className: "form-control" }}
                        placeholder={this.props.t("Entrez le numéro de téléphone")}
                        value={this.state.phone}
                        onChange={(value) => {
                          this.setState({ phone: value });
                        }}
                      />
                      <p className="text-danger">
                        <small>{this.state.phoneError}</small>
                      </p>
                    </label>

                    <label className=" col-12 " htmlFor="di">
                      <span className="float-left">
                        {" "}
                        {this.props.t("Date de disponibilité")} {" "}
                      </span>
                      <div className="col-12">
                        <DatePicker
                          placeholder="DD-MM-YYYY"
                          format={"DD-MM-YYYY"}
                          className="border form-control"
                          // value={moment(this.state.companyCreationDate)}
                          disabledDate={this.disabledAvailabilityDate}
                          defaultValue={
                            this.state.availabilityDate &&
                            moment(this.state.availabilityDate)
                            // .format("DD-MM-YY")
                          }
                          onChange={(moment, date) => {
                            if (moment != null) {
                              this.setState({
                                availabilityDate: moment.toDate(),
                              });
                            } else {
                              this.setState({ availabilityDate: null });
                            }
                          }}
                        />
                      </div>
                    </label>
                    <p className="text-danger">
                      <small>{this.state.sectorError}</small>
                    </p>

                    <label className="">{this.props.t("Contrat souhaité")} </label>
                    <br />

                    <Select
                      // style={{ width: 200 }}
                      mode="multiple"
                      placeholder={this.props.t("Selectionner..")}
                      showSearch
                      onSearch={(value) => {
                        this.setState({ searchedValue: value });
                      }}
                      className=" w-100"
                      // style={{width:"100%"}}
                      onChange={(value) =>
                        this.setState({ contractType: value })
                      }
                      value={this.state.contractType}
                      notFoundContent={
                        <>
                          <Divider style={{ margin: "8px 0" }} />
                          <Space
                            align="center"
                            style={{ padding: "0 8px 4px" }}
                          >
                            <Typography.Link
                              onClick={() => {
                                this.setState({
                                  contractType: this.state.searchedValue,
                                });
                              }}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <PlusOutlined />{this.props.t("Ajouter")}  
                            </Typography.Link>
                          </Space>
                        </>
                      }
                    >
                      {/* CDD-CDI-Sivp-stage-cae-cui-ctt-karama-alternant(ca) */}
                      <Option value="CDD">CDD</Option>
                      <Option value="CDI">CDI</Option>
                      <Option value="CIVP">CIVP</Option>
                      <Option value="Stage">{this.props.t("Stage")}  </Option>
                      <Option value="CTT">CTT </Option>
                      <Option value="CIE">CIE </Option>
                      <Option value="CAE">CAE </Option>
                      <Option value="CUI">CUI </Option>
    <Option value="Freelance">{this.props.t("Freelance")}   </Option>
                      <Option value="Contrat de professionnalisation">
                      {this.props.t("Contrat de professionnalisation(alternance)")} {" "}
                      </Option>
                      <Option value="Contrat d’apprentissage">
                      {this.props.t("Contrat d’apprentissage(alternance)")}  {" "}
                      </Option>
                      <Option value="Contrat de travail intermittent">
                      {this.props.t("Contrat de travail intermittent")} {" "}
                      </Option>
                      {/* <Option value="Alternant">Alternance</Option>
    <Option value="Karama">Karama</Option>
    <Option value="CAE">CAE</Option>
    <Option value="CUI">CUI</Option>
    <Option value="CTT">CTT</Option> */}
                      {/* <Option value="Autre">Autre</Option> */}
                    </Select>
                    <br />
                    <p className="text-danger">
                      <small>{this.state.sectorError}</small>
                    </p>

                    <label className="col-12  " htmlFor="desiredJobPost">
                      <span className="">
                      {this.props.t("Poste désiré")} <b style={{    color: "blue"}}>* </b>
                      </span>{" "}
                      <br />
                      {/* <input
                        className="border form-control "
                        id="desiredJobPost"
                        type="text"
                        name="desiredJobPost"
                        value={this.state.desiredJobPost}
                        onChange={this.handleChange}
                      /> */}
                      <AutoComplete
                    className="w-100 border "
                        value={this.state.desiredJobPost}
                        onChange={this.onChange}
                        onFocus={()=>{
                          if(this.state.desiredJobPostMessage){
                            Modal.info({
                              title: null,
                              content: (
                                <div>
                                  <AsyncImage 
                                      className="w-100"
                                        src={gif} />
                                  <h6> {this.props.t("Veuillez préciser le poste que vous souhaitez afin de trouver la meilleure opportunité d'emploi qui correspond à votre profil et à vos aspirations professionnelles 💼 !")} <br/> {this.props.t("Veuillez préciser le poste que vous souhaitez afin de trouver")}  </h6>
                                </div>
                              ),
                              onOk() {},
                            });
                            this.setState({desiredJobPostMessage:false})

                          }
                        }}
                        options={this.state.jobTitles}
                        // placeholder="try to type `b`"
                        filterOption={(inputValue, option) =>
                          { 
                            if(option.value)
                           return option?.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1}
                        }
                      />
                    </label>
                    <p className="text-danger">
                      <small>{this.state.desiredJobPostError}</small>
                    </p>

                    <label className=" ">
                      {" "}
                      <span className="">{this.props.t("Photo du profil")} </span>
                      <ImageUploader
                        isCompany={false}
                        id={this.state.id} 
                        image={this.state.image}
                        setImage={this.setImage}
                      />
                    </label>
                    <p className="text-danger">
                      <small>{this.state.imageError}</small>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 "></div>

                <br />
                <div className="text-center mt-4">
                
                  <Button  className="p-1 m-1" type="primary" loading={this.state.loading} onClick={this.handleSubmit}>
                  {this.props.t("Enregistrer")} 
</Button>
                </div>
                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(withRouter(CandidateProfile));
