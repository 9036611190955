import React, { Component } from 'react'
import { Timeline} from 'antd';
import CvCercle from './cv-cercle/CvCercle';
import SchoolIcon from '@material-ui/icons/School';
import { withTranslation } from 'react-i18next';
class EducationsEColor extends Component {
  render() {
    const { t } = this.props;
    let i=-1
    let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]

    let list=this?.props?.educations?.map(education=>{
        i++
        let startDate=new Date(education.startDate)
        let date2 =new Date(education.endDate)
        let endDate=education.endDate? months[date2.getMonth()]+date2.getFullYear():t("Présent")
       let dateText=months[startDate.getMonth()]+startDate.getFullYear()+" - "+endDate
      return(
        <Timeline.Item dot={<CvCercle/>}  key={i} >
        <div className="time">
          <div className="time-title">
          {education.school}
          </div>
          <div className="time-body">

            <div className="time-date" style={{border: `solid 1px ${this?.props?.to1}`,color: this?.props?.to1}}>
            {dateText}
            </div>
            <div className="time-cont">
            {education.diploma}
            </div>
          </div>
        </div>
      </Timeline.Item>
      )
    }) 
    return (
        <>
        {list.length>0&&(<div className="cv-cert">
            <div className="cert-t">

          <div className="cert-top">
            <div className="cert-icon">
              <div className="cert-icons" style={{border: `solid 2px ${this?.props?.to1}`,color: this?.props?.to1}}>
                {/* <img src={Graduliation} alt="grad" className='cert-icon-cont' /> */}
                <SchoolIcon className='cert-icon-cont'  />
                
              </div>
            </div>
            <div className="cert-name" style={{background: this?.props?.to1}}>
            {t("Formations académiques")}
            </div>
          </div>
        </div>

        <Timeline style={{marginLeft:"25px"}}>
        {list}
          
          
        </Timeline>
      </div>)}
      </>
    )
  }
}
export default withTranslation()(EducationsEColor)