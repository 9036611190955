import { Modal } from "antd";
import React, { Component } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import TrashIcon from "../../icons/TrashIcon";
import EditIcon from "../../icons/EditIcon";
import SchoolIcon from "../../icons/Schoolcon";
import { AppContext } from "../../../routes/AppContext";

import { withTranslation } from "react-i18next";
 class EducationComponent extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      education: this.props.education,
      date: this.props.education.startDate,
    };
  }
  componentDidMount() {}
  render() {
    let education = this.props.education;
    let dateText = "";
    if (education.startDate) {
      let startDate = new Date(education.startDate).getFullYear();
      let endDate = education.endDate
        ? new Date(education.endDate).getFullYear()
        :  this.props.t("Présent");
      dateText = startDate + "-" + endDate;
    }
    return (
      <div
        className={
          education.startDate
            ? "border rounded m-2 p-2 d-flex justify-content-start"
            : "border border-danger bg-red rounded m-2 p-2 d-flex justify-content-start"
        }
      >
        <div className="align-self-start me-2">
          <SchoolIcon fill="blue" width="3rem" />
        </div>
        <div className="me-auto">
          <h5>{education.diploma}</h5>
          <i className="text-break text-primary ">{dateText}</i>
          <h6>{education.school}</h6>
        </div>
        <div className="">
          <i
            title={this.props.t("Modifier")}
            className=" text-success  m-1"
            role="button"
            onClick={() => {
              this.props.setModalVisible(true, this.props.index);
            }}
          >
            <EditIcon width="1.8rem" fill="green" />
          </i>
        </div>
        <div className="">
          <i
            title={this.props.t("Supprimer")}
            className=" text-danger m-1"
            role="button"
            onClick={() => {
              // alert("fassakh baba fassakh")
              Modal.confirm({
                title: this.props.t("Supprimer formation"),
                icon: <ExclamationCircleOutlined />,
                content:
                  this.props.t("voulez vous supprimer la formation") + education.diploma,
                  okText: this.props.t("Supprimer"),
                  okType: "danger",
                  cancelText: this.props.t("Annuler"),
                onOk: () => {
                  let cv = this.context.user
                  cv.educations.splice(this.props.index, 1);
                  this.context.setUser(cv)
                  this.props.updateView();
                },
                // onCancel:()=>{alert(index)}
              });
              //
            }}
          >
            <TrashIcon fill="red" width="1.5rem" />
          </i>
        </div>
      </div>
    );
  }
}
export default withTranslation()(EducationComponent);