import React, { Component } from 'react'
import {  notification } from 'antd';
import { ResponsibleAccountEmailConfirm } from '../../services/api/accountEmailConfirm/ResponsibleAccountEmailConfirmController';
import { withRouter } from 'react-router';

import { AppContext } from '../../routes/AppContext';

class ResponsibleAccountConfirm extends Component {
  static contextType = AppContext;

    constructor(props){
        super(props);
        
    } 
   async  componentDidMount(){
        let token =this.props.match.params.token
let res = await ResponsibleAccountEmailConfirm({token:token},this.context.headers)
  notification.open({
           
    description:res.data.message,
    className: 'custom-class mt-5',
    style: {
        width: 600,
        border: "2px solid black",
        marginTop:"5rem"
      },
  });
  res?.data?.message &&    this.props.history.push('/signin/company')
  // if(res.status==200){
  //   localStorage.clear();
  //   sessionStorage.clear();
  //    localStorage.setItem("token", res.data.token);
  //   window.location.replace("/company/profile");
  // }
}
 
    
    
    render() {
        return (
            <div>

            </div>
        )
    }
}
export default withRouter(ResponsibleAccountConfirm)
