import React from 'react';
import Candidate from './Candidate';
import { WarningOutlined} from '@ant-design/icons';

export default function Refused(props) {
    let i=-1
    let list= props.applications.map((candidat)=>{
        i++
        return(<li key={i}><Candidate userId={props.userId}  index={i} candidat={candidat}/></li>)});
  return <div>
      <ol className='' >
{list}
</ol>
{props.applications.length==0 && <h4 className=' d-flex justify-content-center text-secondary' > <span className='m-3 icons-lg ' > <WarningOutlined /></span> <br/> Aucun candidat refusé </h4>}

  </div>;
}
