import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { GetContract } from '../../services/api/smartContract/GetContract'
import ContractSend from './ContractSend'
import ContractSign from './ContractSign'
import { UserOutlined, SolutionOutlined, AuditOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Steps } from 'antd'

import decrypt from '../../utils/decrypt'
import { AppContext } from '../../routes/AppContext'

const { Step } = Steps;
   
 function SmartContractContainer(props) {

  const {envVar,headers } = useContext(AppContext);

    const [state,setState]=useState(0)
    const [deadline, setDeadline] = useState(null);
    const [responsibleName, setResponsibleName] = useState("");
  
  const getContract=async()=>{
    let res=await GetContract({idJobPost:props?.location?.state?.idJobPost,idCandidate:props?.location?.state?.idCandidate},headers)
    if(res){
    let data = JSON.parse(decrypt(res.data));
    if(data.length){
      let contract =data[0]
      setState(contract.state)
      setDeadline(contract.deadline)
      setResponsibleName(contract.responsibleName)
    }}
  }
  useEffect(()=>{
    getContract()
  },[])
  return (
    <div>
       <div>
                <Steps  className="mb-5" current={state} >
                    <Step status="" description="Envoi du contrat" title="Etape 1" icon={<UserOutlined />} />
                    <Step status="" description="Signature" title="Etape 2" icon={<AuditOutlined />} />
                    <Step status="" description="Confirmation candidat" title="Etape 3" icon={<SolutionOutlined />} />
                    <Step status="" description="Signature candidat" title="Etape 4" icon={<SolutionOutlined />} />
                    <Step status="" description="" title="Succée" icon={<FileDoneOutlined />} />
                </Steps> 
            </div>
        {state==0&&<ContractSend deadline={deadline}
setDeadline={setDeadline}
responsibleName={responsibleName}
setResponsibleName={setResponsibleName} setState={setState}/>}
        {state==1&&<ContractSign responsibleName={responsibleName} deadline={deadline} cState={state}  setCState={setState} />}
        {state==2&&<>Vous devez attendre la confirmation du candidat
          <div onContextMenu={(e) => e.preventDefault()} className="modal-dialog  modal-lg ">
          <div className="modal-content" style={{ height: "650px" }}>
            <div className="modal-body" id="modal-body">
              <iframe src={envVar.REACT_APP_CLIENT_URL+"/api/company.pdf"+ "#toolbar=0"} height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>
        </>}
        {state==3&&<>Vous devez attendre la signature du candidat
          <div onContextMenu={(e) => e.preventDefault()} className="modal-dialog  modal-lg ">
          <div className="modal-content" style={{ height: "650px" }}>
            <div className="modal-body" id="modal-body">
              <iframe src={envVar.REACT_APP_CLIENT_URL+"/api/company.pdf"+ "#toolbar=0"} height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>
        </>}
        {state==4&&<>Contrat signé avec succée
          <div onContextMenu={(e) => e.preventDefault()} className="modal-dialog  modal-lg ">
          <div className="modal-content" style={{ height: "650px" }}>
            <div className="modal-body" id="modal-body">
              <iframe src={envVar.REACT_APP_CLIENT_URL+"/api/contract.pdf"} height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>
        </>}
    </div>
  )
}
export default withRouter(SmartContractContainer)