import React, { useContext, useEffect, useState } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment-timezone";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";
import isUrl from "is-url";
import { Checkbox, notification, Radio, Select } from "antd";
import { AppContext } from "../../routes/AppContext";
import { UpdateInterview } from "../../services/api/interview/UpdateInterview";
import { UpdateInterviewLink } from "../../services/api/interview/UpdateInterviewLink";

import FullCalendarModal from "./FullCalendarModal";
import localeValues from "antd/lib/locale/fr_FR";
import { JobPostContext } from "../dashboard/jobPostDashboard/JobPostContext";
import { useTranslation } from "react-i18next";
moment.locale("fr");
const { Option } = Select;

export default function InterviewForm(props) {
  const { t,i18n } = useTranslation();

  const { myFunctionalities, headers, socket, serverTime, companyName } =
    useContext(AppContext);
  const jPContext = useContext(JobPostContext);

  const [selectedDate, handleDateChange] = useState(
    props.interview ? moment(props.interview.date) : moment(serverTime)
  );
  const [update, setUpdate] = useState(props.interview ? true : false);
  const [link, setLink] = useState(
    props.interview ? props.interview.link : null
  );
  const [type, setType] = useState(
    props.interview ? (props.interview?.link ? 1 : 0) : 1
  );
  const [modalVisible, setModalVisible] = useState(false);
  //variables to set jobpost if it's not
  const [jobPostIndex, setJobPostIndex] = useState(0);
  const [interviewType, setInterviewType] = useState(
    props.interview ? (props.interview?.address ? true : false) : false
  );
  const [address, setAddress] = useState(
    props.interview ? props.interview.address : null
  );

  let videoconferencingFunctionality = myFunctionalities.find(
    (f) => f.fType == 4
  );
  let qte = "";
  qte = videoconferencingFunctionality.qte;
  let h = Math.floor(qte / 60);
  let m = qte % 60;
  qte = h ? h + "h" : "";
  if (m) qte = qte + m;
  if (!h) qte = qte + "m";
  let quota = qte;
  useEffect(() => {
    if (videoconferencingFunctionality.qte < 30) {
      notification.warn({
        description:
          "votre quota est " +
          videoconferencingFunctionality.qte +
          " minutes !",
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (props.date) {
      handleDateChange(moment(props.date));
      setUpdate(true);
    }
  }, [props.date]);
  const setInterviewLink = (e) => {
    setLink(e.target.value);
    setAddress(null);
  };
  const setInterviewAddress = (e) => {
    setAddress(e.target.value);
    setLink(null);
  };
  const isValidHttpUrl = (url) =>
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      url
    );

  function isValidUrl(string) {
    return isUrl(string);
  }

  const onChange = (e) => {
    setType(e.target.value);
    setLink("");
  };
  const handleChange = async (value) => {
    handleDateChange(value);
    setUpdate(true);
  };
  const updateInterview = async () => {
    let response = await UpdateInterview(
      {
        idInterview: props.interview._id,
        date: selectedDate,
        link: link,
        address: address,
      },
      headers
    );
    if (response) {
      props.updateInterview(
        {
          ...props.interview,
          link: link,
          date: selectedDate,
          address: address,
        },
        true
      );
      notification.success({
        description: t(response.data.code),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem",
        },
      });
    }
  };
  const updateInterviewLink = async () => {
    let response = await UpdateInterviewLink(
      {
        idInterview: props.interview._id,
        link: link,
        address: address,
        idCandidate: props.interview.idCandidate,
        idJobPost: props.interview.idJobPost,
        email: props.interview.candidate[0].email,
        name: props.interview.candidate[0].name,
        companyName: companyName,
        jobPostTitle: jPContext.jobPost.title,
        date: selectedDate,
      },
      headers
    );
    if (response) {
      socket.emit("sendNotification", {
        receiverId: props.interview.idCandidate,
        type: 21,
      });
      props.updateInterview({ ...props.interview, link: link }, false);
      notification.success({
        description: t(response.data.code),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem",
        },
      });
    }
  };
  const validation = () => {
    let isValid = true;
    //link validation
    if (type == 1 && !interviewType) {
      if (!isValidUrl(link)) {
        isValid = false;
        notification.warn({
          description: t("lien invalide"),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#fff3cd",
            marginTop: "5rem",
          },
        });
      }
    }
    //address validation
    if (interviewType) {
      if (!address || !address.length) {
        isValid = false;
        notification.warn({
          description: t("veuillez remplir le champs d adresse"),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#fff3cd",
            marginTop: "5rem",
          },
        });
      }
    }
    //date validation
    if (!selectedDate.isValid()) {
      isValid = false;
      notification.warn({
        description: t("date invalide"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    }
    return isValid;
  };

  const handleUpdate = async () => {
    if (validation()) {
      if (props.interview.idCandidate) {
        updateInterviewLink();
        props.setModalVisible(false);
      } else {
        updateInterview();
        props.setModalVisible(false);
      }
    }
  };

  const handleDateClick = (date) => {
    handleChange(moment(date));
    setModalVisible(false);
  };

  const addInterview = async () => {
    if (validation()) {
      props.add({
        date: selectedDate,
        link: link,
        jobPostIndex: jobPostIndex,
        address: address,
      });
      setUpdate(false);
    }
  };
  return (
    <div>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale={i18n.language}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div>
            {!props.idJobPost && props.date && (
              <>
                <label>{t("Offre")} </label>

                <Select
                  placeholder={t("Selectionner..")}
                  className=" w-100 my-1"
                  onChange={(value) => {
                    setJobPostIndex(value);
                  }}
                  value={jobPostIndex}
                >
                  {props.jobPosts &&
                    props.jobPosts.map((jp, i) => (
                      <Option value={i}>{jp.title}</Option>
                    ))}
                </Select>
              </>
            )}
            {props.interview?.idCandidate ? (
              "Date: " + moment(props.interview.date).format("HH:mm L")
            ) : (
              <KeyboardDateTimePicker
                invalidDateMessage={t("Date invalide!")}
                maxDateMessage={t("Date invalide!")}
                minDateMessage={t("Date invalide!")}
                // keyboardIcon
                variant="inline"
                ampm={false}
                label={t("Ajouter une date")}
                value={selectedDate}
                onChange={handleChange}
                // onError={console.log}
                disabled={props.interview?.idCandidate}
                disablePast
                format="HH:mm L"
              />
            )}
            <span
              onClick={() => {
                setModalVisible(true);
              }}
              className="rounded-circle btn btn-light m-2 "
            >
              <i className="mdi mdi-calendar-account icons12"></i>
            </span>
            {update && (
              <>
                <br />
                <div>
                  {/* <label>Offre </label> */}
                  <Checkbox
                    checked={interviewType}
                    onChange={(e) => setInterviewType(!interviewType)}
                  />{" "}
                  {t("Présentiel")}
                </div>
                {interviewType ? (
                  <div>
                    <>
                      <span className="mt-2"> {t("Adresse")} </span>
                      <input
                        className="border form-control "
                        id="address"
                        type="text"
                        name="address"
                        value={address}
                        onChange={setInterviewAddress}
                      />
                    </>
                  </div>
                ) : (
                  <div className="">
                    <label>{t("Service vidéoconférence")} </label>
                    <br />
                    <Radio.Group onChange={onChange} value={type}>
                      <Radio disabled value={0}>
                        {t("Notre service")}({quota})
                      </Radio>
                      <Radio value={1}>{t("Autre")}</Radio>
                    </Radio.Group>

                    <br />
                    {type === 1 && (
                      <>
                        <span className="mt-2"> {t("Lien")} </span>
                        <input
                          className="border form-control "
                          id="website"
                          type="text"
                          name="website"
                          placeholder="https://meet.google.com/"
                          value={link}
                          onChange={setInterviewLink}
                        />
                      </>
                    )}
                  </div>
                )}
                <br />

                {props.interview ? (
                  <button
                    onClick={handleUpdate}
                    className="btn btn-primary m-2"
                  >
                    {t("Modifier")}
                  </button>
                ) : (
                  <button
                    onClick={addInterview}
                    className="btn btn-primary m-2"
                  >
                    {t("Ajouter")}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
      {modalVisible && (
        <FullCalendarModal
          idJobPost={props.idJobPost}
          handleDateClick={handleDateClick}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )}
    </div>
  );
}
