import React, { Component } from 'react'
import {   Progress } from 'antd'

// import CvComponent from '../cv/CvComponent'
// import Modal from 'react-modal';
export default class Candidate extends Component {
    state={      modalVisible: false,
    }
    setModalVisible = (modalVisible) => {
        this.setState({ modalVisible });
      };

    render() {
let candidat=this.props.candidat.candidate[0]
        return (
            <div  className="mt-1 ml-2 ">
            
            <span className='' >
            <Progress type="circle" 
            percent={parseInt(this.props.candidat.score*100)}
            format={percent => `${percent}%`}
             width={35}   strokeColor={parseInt(this.props.candidat.score*100) < 30 ? "orange" : "green"} />
            </span>

       {/* <img className="col-4 rounded-circle p-0 mt-0 mb-0 ml-3 logo" alt="recinov"src={this.props.candidat.image}/> */}
                <span className=" m-1 text-capitalize ">{candidat.name}</span>

    

            </div>
        )
    }
}
