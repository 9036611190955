import { notification } from 'antd';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { AppContext } from '../../routes/AppContext';
import CheckIcon from '../icons/CheckIcon';
import ContactUsModal from './ContactUsModal';
import FreePackage from './FreePackage';

export default function PackageComponent(props) {
  const { t,i18n } = useTranslation();

    const [companyPackageModalVisible, setCompanyPackageModalVisible] =
    useState(false);
  const [freePackageModalVisible, setFreePackageModalVisible] = useState(false);
  const [freePackage, setPackage] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
    const {
        cart,
        setCart,
        fonctionnalites,
        setFonctionnalites,
        orders,
        role,
        modules
      } = useContext(AppContext);

      const language=i18n.language 
      const modulesLanguage={"fr":"name","en":"name_en","ar":"name_ar"}
      const module=modulesLanguage[language]
    
      const functionalitiesLanguage={"fr":"fName","en":"fName_en","ar":"fName_ar"}
      const functionalityLanguage=functionalitiesLanguage[language]

      const packagesLanguage={"fr":"pName","en":"pName_en","ar":"pName_ar"}
      const packageLanguage=packagesLanguage[language]

    const AddToCart = async (p) => {
        if (p.pCategory == "Gratuit") {
          setFreePackageModalVisible(true);
          setPackage(p);
        } else if (p.pCategory == "Entreprise") {
          setCompanyPackageModalVisible(true);
        } else {
          let exist = false;
          for (let i = 0; i < cart.length; i++) {
            if (cart[i]._id == p._id) exist = true;
          }
          if (exist) {
            //notification
            notification.info({
              description: t("déja existe dans le panier"),
              className: "custom-class",
              style: {
                width: 600,
                backgroundColor: "#d1ecf1",
                marginTop: "5rem",
              },
            });
          } else {
            let cartList = [...cart];
            cartList.push(p);
            //notification
            notification.success({
              description: t("ajouté au panier avec succée"),
              className: "custom-class",
              style: {
                width: 600,
                backgroundColor: "#d1ecf1",
                marginTop: "5rem",
              },
            });
            setCart(cartList);
          }
        }
      };
  const plan=props.plan
  return (<>
    <Col lg="3" md="6" >
    <div
      className={
        plan.is_active
          ? "pricing-box active mt-4 "
          : "pricing-box mt-4 "
      }
    >
      <div className="price bg-light position-relative p-4">
        <div className="">
          <h5 className="text-dark title mt-2 font-weight-normal f-18 mb-0">
            {plan[packageLanguage]}
          </h5>
        </div>
        <div className="">
        <h5 className="text-dark font-weight-normal text-end mb-0 mt-2">
             {/* <sup>TDN</sup> */}
            {plan.pPrice} {t("DTN")}
          {/* <sub style={{fontSize:"0.8rem"}}>HT</sub> */}
          </h5>
        </div>
      </div>
      <div style={{minHeight:"375px"}} className="p-4 pricing-list ">
        <ul className="list-unstyled mb-0">
          {modules && modules.length > 0
            ? modules.map((item2, index2) => {
                for (
                  let i = 0;
                  i <
                  plan.modules.length;
                  i++
                ) {
                  if (
                    item2._id ==
                    plan.modules[
                      i
                    ].idModule
                  )
                    return (<>{i!=6&&
                      <li className="text-muted f-14">
                        <span>
                          {item2[module]}
                          {/* <span className="font-bold text-black">
                            {i==4?plan.fonctionnalites[0]
                                .listefonctionnalite[i].qte/60:
                              plan.fonctionnalites[0]
                                .listefonctionnalite[i].qte
                            }
                            {
                              (i==5||i==4)&&" heures"
                            }
                          </span> */}
                        </span>
                      </li>}</>
                    );
                }
              })
            : null}
            {/* {modules && modules.length > 0&&plan.pCategory=="Entreprise"
            ? modules.map((module, index2) => {
                    return (<>{index2!=6&&
                      <li className="text-muted f-14">
                        <span>
                          <i className='m-1' ><CheckIcon fill="rgb(0,91,234)" width="1rem" /></i>
                      
                          {module.name}
                        </span>
                      </li>}</>
                    );
              })
            : null} */}
        </ul>
      </div>
      {showDetails&&<div className='m-1'   dangerouslySetInnerHTML={{
                            __html: plan.pDescription,
                          }} >
        </div>}
      <div
        role="button"
        className="text-primary d-flex justify-content-center"
      >
        {/* <span
          onClick={(e) =>
            {
                setShowDetails(!showDetails)
            }
          }
        >
          Détails &gt;
        </span> */}
      </div>
      {role=="company"&&(plan.pCategory == "Gratuit" && orders.length > 0 ? (
        <div className="mb-4 mt-2 d-flex justify-content-center ">
          <Link
            to="#"
            className={
              plan.is_active
                ? "btn btn-outline btn-sm active disabled"
                : "btn btn-outline btn-sm disabled"
            }
          >
            {t("Essayer")}
          </Link>
        </div>
      ) : (
        <div
          onClick={() => AddToCart(plan)}
          className="mb-4 mt-2 d-flex justify-content-center "
        >
          <Link
            to="#"
            className={
              plan.is_active
                ? "btn btn-outline btn-sm active"
                : "btn btn-outline btn-sm"
            }
          >
           {plan.pCategory == "Gratuit"?<>{t("Essayer")}</>:(plan.pCategory=="Entreprise"?<>{t("Contactez-nous")}</>: <>{t("Acheter maintenant")}</>)}

          </Link>
        </div>
      ))}
    </div>    <ContactUsModal
        modalVisible={companyPackageModalVisible}
        setModalVisible={setCompanyPackageModalVisible}
      />
      <FreePackage
        package={freePackage}
        modalVisible={freePackageModalVisible}
        setModalVisible={setFreePackageModalVisible}
      />
  </Col>
    
      </>
  )
}
