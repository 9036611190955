import moment from "moment-timezone";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { SaveTestResult } from "../../services/api/companyTest/SaveTestResult";
import { UserOutlined } from "@ant-design/icons";
import { AppContext } from "../../routes/AppContext";
import { GetTest } from "../../services/api/companyTest/GetTest";
import decrypt from "../../utils/decrypt";
import gif from "../../media/image/Examenterminé.png";
import AsyncImage from "../AsyncImage";

import { useTranslation } from "react-i18next";

export default function TestResult(props) {
  const { user,socket,testLanguage,headers,role } = useContext(AppContext);
  const { t } = useTranslation();
  const [totalScore, setTotalScore] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);

  // const[time,setTime]=useState(0)

  useEffect(() => {
    return () => {

    };
  }, []);
  const convertTime = (val) => {
    let m = Math.floor(val / 60);
    let s = val % 60;

    return m + "m" + s;
  };
  const postResult = (score,points,pointsPerQuestion) => {
    //axios
    SaveTestResult(
      {
        method:props.method,
        name: props.name,
        email: props.email,
        idTest: props.idTest,
        idJobPost: props.idJobPost,
        idCompany:props.idCompany,
        idCandidateTest: props.idCandidateTest,
        score: score,
        points:points,
        testTimeTook: props.time,
        timePerQuestion:props.timePerQuestion,
        pointsPerQuestion:pointsPerQuestion,
        answers: props.answers,
        idQuestions:props.questions.map(q=>q._id)
      },
      headers
    );
    if(socket){
      let notifType=14
      if(props.method==1) notifType=26
      else if(props.method==2) notifType=27
    socket.emit("sendNotification",
    {receiverId:props.idCompany,type:notifType}
    )}
    setTimeout(() => {
       window.location.replace("/");
    }, 3000);
  };
  // let time =props.time
  let weightsSum = useMemo(() => {
    let testTime = 0;
    let weight = 0;
    for (let j = 0; j < props.questions.length; j++) {
      const question = props.questions[j];
      const questionWeight = question?.points
        ? question?.points
        : question?.level + 1;
      weight += questionWeight;

    }
    // setTime(props.time)
    return weight;
  }, []);
  // let totalScore=0
  useEffect(async() => {
    let questReponseCandidat
    if(role==="candidate"||role===""){
          let response = await GetTest(
          { id: props.idTest },
          headers
        );
        if (response) {
          const datatesty = JSON.parse(decrypt(response.data));
          questReponseCandidat=datatesty
          console.log("api")
        }
    }
    console.log("1st")
    let totalPoints = 0;
    let totalScore = 0;
    let pointsPerQuestion=[]
    for (let i = 0; i < props.questions.length; i++) {
      const question = props.questions[i];
      let questionok=question
      if(role==="candidate"||role===""){
      // const questionn=props.questiony.find(item=>item._id==question._id)
      console.log("questres",questReponseCandidat)
      const questionn=questReponseCandidat.find(item=>item._id==question._id)
      console.log("questionn",questionn)
      questionok=questionn
      }
      const answers=props.answers[i]??[]
      const questionWeight = questionok?.points
        ? questionok?.points
        : questionok?.level + 1;
      let trueAnswers = 0;
      let falseAnswers = 0;
      let questionScore = 0;
      let questionPoints = 0;
      let correctAnswers = 0;
      for (let answer of questionok.answers) {
        if (answer.isCorrect) {
          correctAnswers++;
          if (answers.includes(answer._id)) {
            trueAnswers += 1;
          }
        } else {
          if (answers.includes(answer._id)) {
            falseAnswers += 1;
          }
        }
      }
      if (trueAnswers == 0 || trueAnswers <= falseAnswers) {
        questionScore = 0;
      } else {
        questionPoints=((trueAnswers - falseAnswers) / correctAnswers)* questionWeight
        questionScore = questionPoints*(100/weightsSum);
      }
      pointsPerQuestion.push(questionPoints)
      totalPoints+=questionPoints
      totalScore += questionScore;
    }
    if(totalScore>100)totalScore=100
    setTotalScore(Math.ceil(totalScore));
    setTotalPoints(Math.ceil(totalPoints));
    if(!props.isCompany){
      postResult(Math.ceil(totalScore),Math.ceil(totalPoints),pointsPerQuestion);
    }
  }, []);
  const languageOptions = {"fr": t("Français"),"en": t("Anglais") }
  
  return (
    <div>

    {props.testDescription.showResult?
      <>
 <UserOutlined className='border border-primary rounded-circle p-1 m-1 text-primary h5' /> {user.name}({user.email})
      <hr/>
      <div className='d-flex justify-content-evenly'>
      <div>
  <span className='text-primary m-1' >  {t("Test")}:</span> 
      {props.testDescription.title}
        </div>   
        <div>
             <span className='text-primary m-1' >  {t("Langue")} :</span>
      {languageOptions[testLanguage]} 
        </div>
      <div>
          <span className='text-primary m-1' > {t("Date")} :</span>
      {moment().format("LL")}
          </div>

      </div>
 <br/>
      <div>
  <div>  
    <small>  {t("Score")}  </small> 
    <h5 className='text-primary' >{totalScore} %</h5>
  </div>
      <br/>
      <div>
        <small> {t("Durée")} </small> 
      <h5 className='text-primary' >   {convertTime(props.time)}</h5> 
      </div></div>
      </>
    :  <div className="text-center">
        <h3>{t("Vous avez terminé votre examen. Bonne chance avec vos résultats !")} </h3>
        <AsyncImage
        height="350"
        weight="350"
          src={gif}
          alt="email"
        />
        <br />
      </div>
    }
     
    </div>
  );
}
