import React, { Component } from 'react'
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
export default class Contact extends Component {
  render() {
    let email=this.props.candidate.email
      let phone=this.props.candidate?.phone?.toString()??""
      let hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (i > 2 && i< email.indexOf("@") ) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  let hiddenPhone = "";

  for (let i = 0; i < phone.length; i++) {
    if (i > 4) {
      hiddenPhone += "*";
    } else {
      hiddenPhone += phone[i];
    }
  }
console.log("this.props.candidate.viewcv",this.props.candidate.viewcv)
console.log("this.props.candidate?.viewcv===false",this.props.candidate?.viewcv===false)
console.log("!this.props.decryptedData",this.props.decryptedData)
console.log("(!this.props.decryptedData && (this.props.candidate?.viewcv===false)",(this.props.decryptedData && (this.props.candidate?.viewcv!==false)))
console.log(hiddenEmail,hiddenPhone)
console.log(this.props.role)
    return (
      <div className="cv-r-details">
              
              <div className="cv-contact">
                <RoomIcon className='cv-c-icon' />
                <div className="cv-c-val">: {this.props.candidate.address}</div>
              </div>
              <div className="cv-contact">
                <EmailIcon className='cv-c-icon' />
                {this.props.role==="candidate"
                ?<div className="cv-c-val">: {this.props.decryptedData?email:hiddenEmail}</div>
                :(<div className="cv-c-val">: {(this.props.decryptedData && (this.props.candidate?.viewcv!==false))?email :hiddenEmail}</div>)
                }
              </div>
              <div className="cv-contact">
                <PhoneIcon className='cv-c-icon' />  
                {this.props.role==="candidate"
                ?<div className="cv-c-val">: +{this.props.decryptedData?phone:hiddenPhone}</div>            
                :(<div className="cv-c-val">: +{(this.props.decryptedData && (this.props.candidate?.viewcv!==false))?phone:hiddenPhone}</div>)
                }
              </div>

    </div>
    )
  }
}