import React, { useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
 import moment from 'moment-timezone';
import { AppContext } from '../../../routes/AppContext';
import { useTranslation } from "react-i18next";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function VerticalBarChart(props) {
  const {serverTime} = useContext(AppContext);
  const { t } = useTranslation();
  const options = {
   responsive: true,
   plugins: {
     legend: {
       position: 'top',
     },
     title: {
       display: false,
       text: 'Statistiques des postulation en '+moment(serverTime).year(),
     },
   },
  };
  

  const labels = [t("janv"),t("févr"),t("mars"),t("avr"),t("mai"),t("juin"),t("juill"),t("août"),t("sept"),t("oct"),t("nov"),t("déc")];

  
  const data = {
   labels,
   datasets: [
     {
       label: t("En attente"),
       data:props.pending,
       backgroundColor: 'rgba(83,109,254,255)',
     },
     {
       label: t("Entretien"),
       data: props.interview,
       backgroundColor: 'rgba(0,150,136,255)',
     },
     {
       label: t("Accepté"),
       data: props.accepted,
       backgroundColor: 'rgba(3,169,244,255)',
     },
     {
       label: t("Refusé"),
       data: props.refused,
       backgroundColor: 'rgba(251,140,0,255)',
     },
   ],
  };
  return <Bar options={options} data={data} />;
}
