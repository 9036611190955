import React, { Component } from 'react'
import { Steps, Divider } from 'antd';
import { UserOutlined, SolutionOutlined, CheckSquareOutlined, SmileOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next";
const { Step } = Steps;


class TestSteps extends Component {
    render() {
        return (
            <div style={{ direction: "ltr"}}>
                <Steps  className="mb-5" current={this.props.current} >
                    {/* <Step status="" description="Confirmation au politique de l'examen" title="Etape 1" icon={<UserOutlined />} /> */}
                    <Step status="" description={this.props.t("Mise en place pour le passage de l'examen")} title={this.props.t("Etape") + " 1"} icon={<CheckSquareOutlined />} />
                    <Step status="" description={this.props.t("Vérification de l'identité")} title={this.props.t("Etape") + " 1"} icon={<SolutionOutlined />} />
                    <Step status="" description={this.props.t("Accés à la demande")} title={this.props.t("Succée")}  icon={<SmileOutlined />} />
                </Steps> 
            </div>
        )
    }
}
export default withTranslation()(TestSteps);