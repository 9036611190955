import React, { Component } from "react";
import { Input, notification } from "antd";
import axios from "axios";


import { AppContext } from "../../routes/AppContext";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class ChangePasswordCompany extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      password: "",
      passwordConfirm: "",

      oldPasswordError: "",
      passwordError: "",
      passwordConfirmError: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {}

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  validation = () => {
    let isvalid = true;

    let oldPassword = this.state.oldPassword;
    let password = this.state.password;
    let passwordConfirm = this.state.passwordConfirm;
    //old password validation
    if (oldPassword == ""&&this.context.user.password) {
      isvalid = false;
      this.setState({ oldPasswordError: this.props.t("champ obligatoire") });
    }
    //password validation
    if (password == "") {
      isvalid = false;
      this.setState({ passwordError: this.props.t("champ obligatoire") });
    } else if (
      !(
        password.match(/[0-9]/g) &&
        password.match(/[A-Z]/g) &&
        password.match(/[a-z]/g) &&
        password.match(/[^a-zA-Z\d]/g) &&
        password.length >= 8
      )
    ) {
      isvalid = false;
      this.setState({
        passwordError:
          this.props.t("Mot de passe faible ( * Au moins 1 caractère majuscule 'ABCD...', * Au moins 1 caractère minuscule 'abcd..', *Au moins 1 chiffre '123...' , *Au moins 1 caractère spécial'+-*/...' , *Minimum 8 caractères).")
      });
    } else this.setState({ passwordError: "" });

    //password Confirm validation
    if (passwordConfirm == "") {
      isvalid = false;
      this.setState({ passwordConfirmError: this.props.t("champ obligatoire") });
    } else if (!(password === passwordConfirm)) {
      isvalid = false;
      this.setState({
        passwordConfirmError: this.props.t("Mot de passe doit étre conforme."),
      });
    } else this.setState({ passwordConfirmError: "" });

    return isvalid;
  };
  handleSubmit(event) {
    event.preventDefault();

    if (this.validation()) {
      axios
        .post(
          "/api/changePassword/company",
          {
            
            oldPassword: this.state.oldPassword,
            password: this.state.password,
          },
          {
            headers: this.context.headers
          }
        )
        .then((response) => {
          //notification
          notification.success({
            description: this.props.t(response.data.code),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor: "#d4edda",
              marginTop: "5rem",
            },
          });
            //update context
            let user=this.context.user
            user.password=this.state.password
            this.context.setUser(user)
          this.props.history.push("/company/dashboard");
        })
        .catch((error) => {
          //notification
          notification.error({
            description: this.props.t(error.response.data.code),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor: "#f8d7da",
              marginTop: "5rem",
            },
          });

          // console.warn(error.response.data);
        });

      this.setState({
        oldPassword: "",
        password: "",
        passwordConfirm: "",
      });
    }
  }

  render() {
  const { t } = this.props;

    return (
      <div className="ResetPassword">
        <div className="container">
          <div className=" centered-element mt-2">
            <form className=" shadow p-5 " onSubmit={this.handleSubmit}>
              <h1>{t("Changer mon mot de passe de connexion")}</h1>
              {t("Pour changer votre mot de passe, complétez les informations ci-dessous.")}
              <br />
              <br />
              <div className="row  ">
                <div className="col-2"></div>
                <div className="col-8">
                  <label>
                    {t("Saisissez votre ancien mot de passe")}  <b style={{    color: "blue"}}>* </b>
                  </label>
                  <div className="form-group  ">
                    {/* <input
                      id="password"
                      className="form-control  mb-0 pr-5 pl-5 col-8"
                      type="password"
                      name="oldPassword"
                      value={this.state.oldPassword}
                      onChange={this.handleChange}
                    /> */}
                    <Input.Password
                  id="password"
                  className="  mb-0 pr-5 pl-5 col-8"
                  type="password"
                  name="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.handleChange}
                  disabled={!this.context.user.password}

                    />
                    <span>
                      <p className="text-danger">
                        <small>{this.state.oldPasswordError}</small>
                      </p>
                    </span>
                  </div>
                  <label>
                    {t("Saisissez votre nouveau mot de passe")}  <b style={{    color: "blue"}}>* </b>
                  </label>
                  <div className="form-group  ">
                    {/* <input
                      id="password"
                      className="form-control  mb-0 pr-5 pl-5 col-8"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    /> */}
                    <Input.Password
                  id="password"
                  className="  mb-0 pr-5 pl-5 col-8"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                    />
                    <span>
                      <p className="text-danger">
                        <small>{this.state.passwordError}</small>
                      </p>
                    </span>
                  </div>
                  <label>{t("Resaisissez votre nouveau mot de passe")} <b style={{    color: "blue"}}>* </b></label>

                  <div className=" form-group">
                    {/* <input
                      id="passwordConfirm"
                      className="form-control  mb-0 pr-5 pl-5 col-8"
                      type="password"
                      name="passwordConfirm"
                      value={this.state.passwordConfirm}
                      onChange={this.handleChange}
                    /> */}
                    <Input.Password
                       id="passwordConfirm"
                       className="  mb-0 pr-5 pl-5 col-8"
                       type="password"
                       name="passwordConfirm"
                       value={this.state.passwordConfirm}
                       onChange={this.handleChange}
                    />
                    <span>
                      <p className="text-danger">
                        <small>{this.state.passwordConfirmError}</small>
                      </p>
                    </span>
                  </div>
                  <div className="  text-center ">
                    <button
                      type="submit"
                      className="form-control rounded-pill  btn  btn-primary btn-sm text-capitalize "
                    >
                      <b>{t("Changer mot de passe")} </b>
                    </button>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            </form>
          </div>
        </div>{" "}
      </div>
    );
  }
}
export default withTranslation()(withRouter(ChangePasswordCompany))