import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import CandidatesList from '../../components/candidatesList/CandidatesList'
import { JobPostContext } from '../../components/dashboard/jobPostDashboard/JobPostContext';
function MatchingView(props) {
  const { jobPost} = useContext(JobPostContext);

    return (
        <div className="AddTestView">   
            <CandidatesList jobPost={jobPost}  userId={props.userId}/>
        
        </div>
    )
}
export default withRouter(MatchingView)