import { getEnvVars } from '../../../utils/envVars';
import axios from "../../../interceptors/axios"
export const initiatePayment = async (data) => {
  const envVar = getEnvVars();
        try{
           let res = await axios.post('https://app.paymee.tn/api/v1/payments/create',
            data,
         {
          headers: {"Content-Type": "application/json",
          Authorization: `Token ${envVar.REACT_APP_PAYMEE_TOKEN}`}
        })
        return res
        }catch(err){
        }
    }
