import axios from "../../../interceptors/axios"
export const UpdateCv =async (data,headers)=> {
    try {
    let res =await  axios.put(
        "/api/cv/update",
    data,
        {
          headers: headers
        }
      )
      return res
} catch (err) {
    }
  }