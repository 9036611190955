import moment from 'moment';
import React, { useContext } from 'react'
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import ManageJobPosts from '../../components/manageJobPosts/ManageJobPosts'
import { AppContext } from '../../routes/AppContext';
import { GetJobPosts } from '../../services/api/manageJobPosts/GetJobPosts';
import decrypt from '../../utils/decrypt';
export default function ManageJobPostsView(props) {
  const { serverTime,headers } = useContext(AppContext);

    const getJobPosts=async()=>{
        let response = await GetJobPosts(
            {},
            headers
          );
       if(response){
          let jobPosts = JSON.parse(decrypt(response.data));
          if (props.idResponsible)
      jobPosts = jobPosts.filter(
        (jobPost) => jobPost?.idResponsible == props.idResponsible
      );
    for (let i = 0; i < jobPosts.length; i++) {
      const element = jobPosts[i];
      if (element.closed) {
        element.status = "Archivé";
      }
      else if (moment(element.jobPostPublicationDate).isAfter(moment(serverTime))) {
        element.status = "Programmé";
      } else if (moment(element.jobPostExpirationDate).isBefore(moment(serverTime))) {
        element.status = "Expiré";
      }
      else if (!element.published) {
        element.status = "En attente";
      }
      else element.status = "Publié";
    }
    
    return jobPosts
}
    }
    const { data: jobPosts, isLoading, isError } = useQuery(
        ['jobPosts'], // Query key
        getJobPosts,{
          // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
    refetchOnMount: true,
    refetchQueries: ['jobpost'],
        }
      );
    
    return (
        <div className="UploadJobPostPage">
            
            {isLoading?<Loading/>:<ManageJobPosts  jobPosts={jobPosts} idResponsible={props.idResponsible} company={props.company} userId={props.userId} />}
        </div>
    )
}
