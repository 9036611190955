import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
class AffichePFD extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {t}=this.props
    return (
      <card>
        <h4>{t("Merci de valider votre Test")}</h4>
        <div className="modal-dialog  modal-lg ">
          <div className="modal-content" style={{ height: "350px" }}>
            <div className="modal-body" id="modal-body">
              <iframe src={this.props.src} height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>
{/* {this.props.adr &&        <Link
          to={{
            pathname: "/company/addTest/form",
            state: { questions: this.props.questions },
          }}
        >
          <button className="btn btn-primary btn-block mt-4 col-lg-2 mx-auto">
            Extraire
          </button>
        </Link>} */}
      </card>
    );
  }
}
export default withTranslation()(AffichePFD)