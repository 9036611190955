import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, Modal, Input, Checkbox } from "antd";
import {
  Container,
  Row,
  Col,
  Label,
  Button,
  FormGroup,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { candidateSignup } from "../../services/api/signup/candidateSignup";
import { withRouter } from "react-router";
import "antd/dist/antd.css";
import DetailsCookies from "../home/cookies/DetailsCookies";
import CheckIcon from "../icons/CheckIcon";

import { AppContext } from "../../routes/AppContext";
import { withTranslation } from "react-i18next";

const { Option } = Select;

class CandidateSignup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      name: "",
      lastName: "",
      email: props.email??"",
      confirmEmail: props.email??"",
      password: "",
      confirmPassword: "",
      checked: false,
      nameError: "",
      lastNameError: "",
      emailError: "",
      passwordError: "",
      passwordValidations:[],
      checkedError: "",
      confirmPasswordError: "",
      confirmEmailError: "",
      verifpwd: false,
      sending: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  onChange = (value) => {
    this.setState({ sector: value });
  };
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    if(name=="password"){
      this.validatePassword(value)
    }
  }
  validatePassword=(password)=>{
    //
    let list=[]
    list.push(password.match(/[0-9]/g))
    list.push(password.match(/[A-Z]/g)) 
    list.push(password.match(/[a-z]/g)) 
    list.push(password.match(/[^a-zA-Z\d]/g)) 
    list.push(password.length >= 8)
    this.setState({passwordValidations:list})
  }
  validation = () => {
    let isvalid = true;
    let email = this.state.email.toLowerCase();
    let confirmEmail = this.state.confirmEmail.toLowerCase();
    let name = this.state.name;
    let lastName = this.state.lastName;
    let password = this.state.password;
    let confirmPassword = this.state.confirmPassword;
    let checked = this.state.checked;

    //email validation
    if (email == "") {
      isvalid = false;
      this.setState({ emailError: this.props.t("champ obligatoire") });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      isvalid = false;
      this.setState({ emailError: this.props.t("Ces emails ne correspondent pas. Veuillez réessayer") });
    } else this.setState({ emailError: "" });

    //confirmEmail validation
    if (confirmEmail == "") {
      isvalid = false;
      this.setState({ confirmEmailError: this.props.t("champ obligatoire")});
    } else if (confirmEmail != email) {
      isvalid = false;
      this.setState({
        confirmEmailError:
        this.props.t("Ces emails ne correspondent pas. Veuillez réessayer"),
      });
    } else this.setState({ confirmEmailError: "" });

    //confirmPassword validation
    if (confirmPassword == "") {
      isvalid = false;
      this.setState({ confirmPasswordError: this.props.t("champ obligatoire")});
    } else if (confirmPassword != password) {
      isvalid = false;
      this.setState({
        confirmPasswordError:
        this.props.t("Ces mots de passe ne correspondent pas. Veuillez réessayer"),
      });
    } else this.setState({ confirmPasswordError: "" });

    //name validation
    if (name == "") {
      isvalid = false;
      this.setState({ nameError: this.props.t("champ obligatoire")});
    } else this.setState({ nameError: "" });

    //lastName validation
    if (lastName == "") {
      isvalid = false;
      this.setState({ lastNameError: this.props.t("champ obligatoire")});
    } else this.setState({ lastNameError: "" });

    //password validation
    if (password == "") {
      isvalid = false;
      this.setState({ passwordError: this.props.t("champ obligatoire")});
    } else if (
      !(
        password.match(/[0-9]/g) &&
        password.match(/[A-Z]/g) &&
        password.match(/[a-z]/g) &&
        password.match(/[^a-zA-Z\d]/g) &&
        password.length >= 8
      )
    ) {
      isvalid = false;
      this.setState({
        passwordError:
        "Mot de passe faible.",
      });
    } else this.setState({ passwordError: "" });

    //checked validation
    if (!checked) {
      isvalid = false;
      this.setState({ checkedError: this.props.t("champ obligatoire")});
    } else this.setState({ checkedError: "" });

    return isvalid;
  };
  handleSubmit(event) {
    event.preventDefault();

    if (this.validation()) {
      candidateSignup({
        
        name: this.state.name,
        lastName: this.state.lastName,
        email: this.state.email.toLowerCase(),
        password: this.state.password,
      },this.context.headers);
      this.setState({
        name: "",
        email: "",
        password: "",
        registrationNumber: "",
      });
      this.props.history.push("/signin/candidate");
    } else {
    }
  }

  render = () => {
    let passwordValidation=[
      this.props.t("Au moins 1 chiffre '123...'"),
      this.props.t("Au moins 1 caractère majuscule"),
      this.props.t("Au moins 1 caractère minuscule"),
      this.props.t("Au moins 1 caractère spécial '+-*/...'"),
      this.props.t("Minimum 8 caractères")]
    const { sending } = this.state;
    return (
      <div className="company-signup  mt-5">
        <Container className="mt-5">
          <Row>
            <Col
              lg={{ size: 4 }}
              md={{ size: 5 }}
              sm={{ size: 6 }}
              className="section1"
            >
              <div className="p-4">
                <div className="mx-6">
                  <div className="text-center mb-4">
                    <h3 className="text-primary mb-3 switch__title ">
                      <strong>{this.props.t("Bienvenue")} ! 👋</strong>

                      <hr />
                    </h3>
                  </div>
                  <p className="service-title text-dark font-weight-normal pt-1 mb-4">
                  {this.props.t("Découvrez votre prochaine entreprise...")} 
                  </p>
                  <div className="text-center">
                    <Link to={"/signin/candidate"}>
                      <button className=" btn1 "> {this.props.t("Connectez-vous")} </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={{ size: 7, offset: 1 }}
              md={{ size: 5, offset: 1 }}
              sm={{ size: 6, offset: 0 }}
              className="section2"
            >
              <div className="text-center custom-form mt-4 mt-lg-0">
                <div id="message"></div>
                <AvForm
                  onSubmit={this.handleSubmit}
                  name="contact-form"
                  id="contact-form"
                >
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mb-4">
                        <h2 className="text-primary mb-3 text-uppercase">
                          <strong>{this.props.t("Inscription Candidat")} </strong>
                          <hr />
                        </h2>
                      </div>
                      </Col>
                      <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="email">
                          {" "}
                          {this.props.t("Prénom")}  *
                        </Label>
                        <AvField
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("Votre prénom ...")} 
                          errorMessage={this.props.t("Entrez votre prénom ..")} 
                          // validate={{ required: { value: true } }}
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.nameError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="email">
                        {this.props.t("NOM")} *
                        </Label>
                        <AvField
                          name="lastName"
                          id="lastName"
                          type="text"
                          className="form-control"
                          placeholder= {this.props.t("Votre nom ...")} 
                          errorMessage={this.props.t("Entrez votre nom ..")} 
                          // validate={{ required: { value: true } }}
                          value={this.state.lastName}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.lastNameError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                  
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="password">
                        {this.props.t("Adresse Email")}    *
                        </Label>
                        <AvField
                        disabled={this.props.email}
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder={this.props.t("Votre E-mail...")} 
                          errorMessage={this.props.t("E-mail invalide")} 
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.emailError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="password">
                        {this.props.t("Confirmer votre Adresse Email")}  *
                        </Label>
                        <AvField
                        disabled={this.props.email}
                          name="confirmEmail"
                          id="email1"
                          type="email"
                          className="form-control"
                          placeholder={this.props.t("Votre E-mail...")} 
                          errorMessage={this.props.t("E-mail invalide")} 
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={this.state.confirmEmail}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.confirmEmailError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="password">
                        {this.props.t("Votre mot de passe")}  *
                        </Label>
                        <Input.Password
                         className=""
                         placeholder= {this.props.t("Votre mot de passe...")} 
                         value={this.state.password}
                          onChange={this.handleChange}
                          name="password"
                          id="mdp"
                         />
                        <p className="text-danger">
                          <small>{this.state.passwordError}</small>
                        </p>
                        <div className="text-start ms-1" >
                        {this.state.passwordValidations.map((v,i)=>{return(<>
                        <small className={v?"text-success":"text-danger"} >
                        <CheckIcon fill={v?"green":"red"} width="1rem" />
                        {passwordValidation[i]}
                        </small><br/></>)})}</div>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="password">
                        {this.props.t("Confirmer Votre mot de passe")}  *
                        </Label>
                        <Input.Password
                         className=""
                         placeholder={this.props.t("Votre mot de passe...")} 
                         value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          name="confirmPassword"
                          id="mdp"
                         />
                        <p className="text-danger">
                          <small>{this.state.confirmPasswordError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <FormGroup check>
                        <Label for="invalidCheck3" check>
                          {/* <Input
                            type="checkbox"
                            id="checkbox2"
                            onChange={(e) =>
                              this.setState({ checked: !this.state.checked })
                            }
                          />{" "} */}
                          <Checkbox onChange={(e)=>
                              this.setState({ checked: !this.state.checked })
                          }>
                             {this.props.t("J'ai lu et accepté")} *
                          <Link>
                            <i onClick={() => this.setModalVisible(true)}>
                            {this.props.t("les conditions générales d'utilisation")} 
                              
                            </i>{" "}
                          </Link>
                          {this.props.t("de Rec-INOV, sa politique de confidentialité et son utilisation des cookies")} 
                        
                            </Checkbox>
                         
                        </Label>
                        <p className="text-danger">
                          <small>{this.state.checkedError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <Col sm={12}>
                        <Button
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn1"
                          disabled={sending}
                        >
                        {this.props.t("S'inscrire")} 
                        </Button>
                  <hr/>
                  {this.props.t("S'inscrire avec")}  
                  <div className="socialLogin" >
      
                    <div className="btn btn-primary rounded-circle hoverShadow   m-1" > <a target="_blank" href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&redirect_uri=https%3A%2F%2Frec-inov.com%2Fapi%2Fsignup%2Flinkedin%2Fcallback%2Fcandidate&scope=r_emailaddress%20r_liteprofile&client_id=77ogiqbkfqv1wu">
                 <i className="mdi mdi-linkedin text-light icons12" ></i>
                        </a></div>
                        <div className=" btn btn-primary rounded-circle hoverShadow  m-1" > <a target="_blank" href="https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Frec-inov.com%2Fapi%2Fsignup%2Fgoogle%2Fcallback%2Fcandidate&scope=profile%20email&client_id=81688516951-jb145kmqo96vrs4haq4ekdf0bkc952b7.apps.googleusercontent.com&service=lso&o2v=2&flowName=GeneralOAuthFlow">
                 <i className="mdi mdi-google text-light icons12" ></i>
                        </a></div>
                        <div className="btn btn-primary rounded-circle hoverShadow  m-1" > <a target="_blank" href="https://www.facebook.com/v3.2/dialog/oauth?response_type=code&redirect_uri=https%3A%2F%2Frec-inov.com%2Fapi%2Fsignup%2Ffacebook%2Fcallback%2Fcandidate&scope=public_profile%2Cemail&client_id=1196012994652694">
                 <i className="mdi mdi-facebook text-light icons12" ></i>
                        </a></div>
                  </div>
                      </Col>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </Col>
          </Row>
        </Container>
     
        <Modal
          title={this.props.t("POLITIQUE DE CONFIDENTIALITÉ")}
          bodyStyle={{height:"20rem" , overflowY:'scroll' }}
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
        >
          <DetailsCookies />
        </Modal>
      </div>
    );
  };
}
export default withTranslation()(withRouter(CandidateSignup));
