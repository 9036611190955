import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import "antd/dist/antd.css";
import { message, notification } from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import AffichePFD from "./affiche_PDF";
import Dropzone from "../Dropzone";
import {SmileOutlined} from '@ant-design/icons';
import { Spin, Space } from "antd";
import { AppContext } from "../../routes/AppContext";
import { useHistory } from 'react-router-dom'
import { UploadJobPostService } from "../../services/api/manageJobPosts/UploadJobPostService";
import image from "../../media/image/offreUpload.png"
import { useTranslation } from "react-i18next";



const UploadJobPost = (props) => {
  const { t } = useTranslation(); 

  const history = useHistory({});
  const [afficheProgress, setafficheProgress] = useState(false);
  const [statusprogresse, setstatusprogresse] = useState("");
  const [styleprogresse, setstyleprogresse] = useState({ display: "none" });
  const [file, setFile] = useState("");
  const [FileName, setFileName] = useState(" Nom du fichier ");
  const [verif, setverif] = useState("");
  const [verifupload, setverifupload] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { hardSkillsList,softSkillsList,languagesList,role,headers} = useContext(AppContext);

  function getBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setUploadedFile(reader.result);
      return reader.result;
    };
    reader.onerror = function (error) {
      return reader.result;
    };
  }

function beforeUpload(file) {
  if (file !== "") {
    let typeFile = file.type.split(".").pop();
    let acceptedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/rtf",
      "application/vnd.oasis.opendocument.text",
      // "image/png",
      // "image/jpeg",
      "document","text/plain",
      "application/pdf",
      "document",
    ];
    const isTrue = acceptedTypes.includes(typeFile);
    if (!isTrue) {
      message.error(
        t("Vous ne pouvez télécharger que le type de fichier Pdf - doc - docx - txt")
        // "doc/docx//rtf/png/jpeg/text!"
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(t("Le fichier doit être plus petite que 5MB!"));
    }
    return isTrue && isLt5M;
  } else {
    message.warning(t("Sélectionner votre offre svp!"));
    return false;
  }
}
  const onChange = (files) => {
    setFile("");
    setverif("");
    setFileName("");
    setverifupload(false);
    setFileName(files[0].name);
    setFile(files[0]);
    getBase64(files[0]);
   let printedTypes= ["text/plain","application/pdf"]
    let typeFile = files[0].type.split(".").pop();
    setShowFile(printedTypes.includes(typeFile));
  //   else
     setverif(beforeUpload(files[0]));
    
    setafficheProgress(false);
    setstatusprogresse("");
    // document.getElementById("uploadedFile").scrollIntoView();
    // document.getElementById("uploadedFile").focus();
  };
 
  const onSubmit = async (e) => {
    e.preventDefault();
    setafficheProgress(true);
    setverif(false);
    const formData = new FormData();
    formData.append("idCompany", props.userId);
    formData.append('role', "company");
    formData.append("file", file);
    if (beforeUpload(file)) {
      setstyleprogresse({});
      try {
        const res = await UploadJobPostService(formData,{
            "Content-Type": "multipart/form-data",
            ...headers
          })
     
        if (res?.status === 200) {
          setUploadPercentage(100);
          notification.open({
            description:
              t("Votre Offre a été téléchargé avec succès"),
            className: "custom-class ",
            icon: <UploadOutlined style={{ color: "#148f24" }} />,
            style: {
              width: "auto",
            },
          });
          let parsedJobPost = res.data.data;
      

          const parsedHardSkills = parsedJobPost.skills;
          const parsedSoftSkills = parsedJobPost.soft_skills;
          const parsedLanguages = parsedJobPost.languages;

          let filteredHardSkills = hardSkillsList.filter((skill) => {
            return parsedHardSkills.includes(skill.similar_skill);
          });
          let filteredSoftSkills = softSkillsList.filter((skill) => {
            return (
              parsedSoftSkills.includes(skill.other_ss) 
            );
          });
          let filteredLanguages = languagesList.filter((skill) => {
            return (
              parsedLanguages.includes(skill.language_fr) ||
              parsedLanguages.includes(skill.language_en)
            );
          });
          let hardSkills = [];
          let softSkills = [];
          let languages = [];
          //hardskills
          for (let i = 0; i < filteredHardSkills.length; i++) {
            const skill = filteredHardSkills[i];
            let notExist = true;
            for (let j = 0; j < hardSkills.length; j++) {
              const element = hardSkills[j];
              if (element.skill == skill.skill) notExist = false;
            }
            if (notExist) hardSkills.push(skill);
          }
          //softSkills
          for (let i = 0; i < filteredSoftSkills.length; i++) {
            const skill = filteredSoftSkills[i];
            let notExist = true;
            for (let j = 0; j < softSkills.length; j++) {
              const element = softSkills[j];
              if (element.code == skill.code) notExist = false;
            }
            if (notExist) softSkills.push(skill);
          }
          //languages
          for (let i = 0; i < filteredLanguages.length; i++) {
            const skill = filteredLanguages[i];
            let notExist = true;
            for (let j = 0; j < languages.length; j++) {
              const element = languages[j];
              if (element.code == skill.code) notExist = false;
            }
            if (notExist) languages.push(skill);
          }

          hardSkills.map((skill) => {
            skill.score = 0.2;
          });
          softSkills.map((skill) => {
            skill.score = 0.2;
          });
          languages.map((skill) => {
            skill.score = 0.2;
          });

          let jobPost = {};
          jobPost.contract = parsedJobPost.contract;
          //  jobPost.phone = parsedJobPost.phone[0] ? parsedJobPost.phone[0] : ""
          jobPost.workTime = parsedJobPost.work_time[0]
            ? parsedJobPost.work_time[0]
            : "";
          jobPost.workType = parsedJobPost.work_type[0]
            ? parsedJobPost.work_type[0]
            : "";
            jobPost.salary = parsedJobPost.salary
            ? parsedJobPost.salary
            : "";
            jobPost.title = parsedJobPost.title
            ? parsedJobPost.title
            : "";
            jobPost.vacancies = parsedJobPost.vacancies
            ? parsedJobPost.vacancies
            : "";
            jobPost.educationLevel = parsedJobPost.educationLevel
            ? parsedJobPost.educationLevel
            : "";
            jobPost.sector = parsedJobPost.sector
            ? parsedJobPost.sector
            : "";
            if(parsedJobPost.gender) { 
        if(parsedJobPost.gender=="femme") jobPost.gender=2
        else if (parsedJobPost.gender=="homme") jobPost.gender=1
        else jobPost.gender=0
          }
          jobPost.experience = parsedJobPost.experience_jp[0]
            ? parsedJobPost.experience_jp[0]
            : "";
            
            
            
          jobPost.suggestedSoftSkills = softSkills;
          jobPost.suggestedSkills = hardSkills;
          jobPost.suggestedLanguages = languages;

          jobPost.idJobPostDescription = {};
          jobPost.idJobPostRequestedProfile = {};

          jobPost.idJobPostDescription.description = parsedJobPost.mission
            ? parsedJobPost.mission
            : "";
          jobPost.idJobPostRequestedProfile.description =
            parsedJobPost.requested_profile
              ? parsedJobPost.requested_profile
              : "";

          localStorage.setItem("jobPost", JSON.stringify(jobPost));
        
          
          history.push({
            pathname: "/"+role+"/addJobPost",
          
          });
     
          setafficheProgress(false);
          setverifupload(true);
          setstatusprogresse("active");
        }
      } catch (err) {
        setstatusprogresse("exception");
        setafficheProgress(false);
        // notification.open({
        //   description: "erreur avec le serveur, veuillez réessayer ! ",
        //   className: "custom-class ",
        //   icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        //   style: {
        //     width: "auto",
        //   },
        // });
      }
    }
  };

  return (
    <div className="uploadFile ">
<h3 className='titrePage'> {t("Télécharger votre Offre")}</h3>

      <div className="modal-body">
      {/* <div className='d-flex justify-content-center' >
      <h6 className='w-80 p-4  text-dark rounded border  ' style={{backgroundColor: "beige"}}>  <SmileOutlined />    Téléchargez votre offre d'emploi. Avec notre outil innovant, nous vous simplifions la tâche. </h6>
      </div> */}
  
        <Fragment>
          <form onSubmit={onSubmit}>
            {/* <div className="input-group">
              <label className="input-group-btn">
                <span className="btn btn-primary">
                Choisir un fichier &hellip;{" "} */}
                  {/* <input
                    id="formFileSm"
                    type="file"
                    onChange={onChange}
                    style={{ display: "none" }}
                  /> */}
                  <Dropzone image={image} onDrop={onChange} />
                {/* </span>
              </label>
              <input
                type="text"
                className="form-control"
                value={FileName}
                readonly
              />
            </div> */}

           <div id="uploadedFile" > {verif ? (
              <>
               {showFile ? <AffichePFD src={uploadedFile} />:""}
                <input
                  type="submit"
                  value={t("Extraire Les Informations")}
                  className="d-flex justify-content-center btn btn-primary btn-block mt-4  mx-auto p-1"
                />
              </>
            ) : null}
            {afficheProgress ? (
              <div className="d-flex justify-content-center">
                <Space size="large">
                  <Spin size="large" />
                </Space>
              </div>
            ) : null}</div>
            {verifupload ? (
              <>
                <Link to={role=="company"?"/company/addJobPost":"/responsible/addJobPost"} className="d-flex">
                  <button className="btn btn-primary btn-block mt-4 col-lg-2 mx-auto">
                    {t("Etape Suivante")} <h1>&#8594;</h1>
                  </button>
                </Link>
              </>
            ) : null}
          </form>
        </Fragment>
      </div>
    </div>
  );
};

export default UploadJobPost;
