import axios from "../../../interceptors/axios";
export const GetAppointments = async (headers) => {
  try {
    let res = await axios.get("/api/appointment/get" , {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
