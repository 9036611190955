export const homeState = {
  fonctionnalites: null,
  products: null,
  loading: false,
};
export const homeReducer = (state, action) => {
  switch (action.type) {
    /* get products */
    case "setProducts":
      return {
        ...state,
        products: action.payload,
      };
    /* get fonctionnalites */
    case "setFonctionnalites":
      return {
        ...state,
        fonctionnalites: action.payload,
      };
    /* loading */
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
