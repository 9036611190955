import { t } from "i18next";
import React from "react";
import rocket from "../media/image/animat-rocket-color.gif";
import AsyncImage from "./AsyncImage";
import { useTranslation } from "react-i18next";
export default function ComingSoon() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="main-content">
        <div className="page-content my-5">
          <section className="bg-coming-soon bg-auth">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="text-center">
                    <div className="mb-4 pb-3">
                      <AsyncImage src={rocket} alt="rocket"  titre="rocket" height="150" className="mg-fluid" />
                    </div>
                    <h1>{t("Nous lançons bientôt..!!")}</h1>
                    <p className="text-muted mb-4 pb-3">
                      {t("ce service sera disponible très prochainement")}.
                    </p>
                    <div id="countdown" className="d-flex"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
