import React, { Component, useContext } from "react";
import Router from "./Router.js";
import jwt_decode from "jwt-decode";
import jwt from "jsonwebtoken";
import CryptoJS from "crypto-js";
import { Route, Switch } from "react-router-dom";
import NotFoundView from "../views/NotFoundView";
import PublicRouter from "./router/PublicRouter";
import Navbar from "../components/navbar/Navbar";
import CompanySignupView from "../views/company/CompanySignupView";
import CompanySigninView from "../views/company/CompanySigninView";
import CancelAppointment from "../components/demo/CancelAppointment";
import ResetPasswordCompany from "../components/resetPassword/ResetPasswordCompany";
import CandidateAccountConfirm from "../components/accountConfirm/CandidateAccountConfirm";
import ResetPasswordCandidate from "../components/resetPassword/ResetPasswordCandidate";
import Room from "../components/videoconference/Room";
import DetailsCookiesView from "../views/detailscookiesView";
import BlogView from "../views/BlogView";
import DemoView from "../views/DemoView";
import CandidateRouter from "./router/CandidateRouter";
import CandidateCvView from "../views/candidate/CandidateCvView";
import CandidateCvUploadView from "../views/candidate/CandidateCvUploadView";
import CandidateProfileView from "../views/candidate/CandidateProfileView";
import CandidateJobPostsListView from "../views/candidate/CandidateJobPostsListView";
import CandidateJobPostView from "../views/candidate/CandidateJobPostView";
import CandidateApplicationsView from "../views/candidate/CandidateApplicationsView";
import CandidateSkillTestingView from "../views/candidate/CandidateSkillTestingView";
import CandidateJobPostTestingView from "../views/candidate/CandidateJobPostTestingView";
import CandidateProfilCvView from "../views/candidate/CandidateProfilCvView";
import HomePage from "../views/Home";
import CandidateSignupView from "../views/candidate/CandidateSignupView";
import CandidateSigninView from "../views/candidate/CandidateSigninView";
import CompanyAccountConfirm from "../components/accountConfirm/CompanyAccountConfirm";
import CandidateNavbar from "../components/navbar/CandidateNavbar";
import { AppContext } from "./AppContext";
import Logout from "../components/Logout";
import decrypt from "../utils/decrypt";
import { GetFeatures } from "../services/api/features/GetFeatures";
import crypt from "../utils/crypt";
import CompanyRouter from "./router/CompanyRouter";
import ManageJobPostsView from "../views/company/ManageJobPostsView";
import CompanyProfileView from "../views/company/CompanyProfileView";
import CompanyContact from "../components/contact/CompanyContact";
import ManageResponsible from "../components/manageResponsible/ManageResponsible";
import UploadJobPostView from "../views/company/UploadJobPosteView";
import JobApplications from "../components/jobApplications/JobApplications";
import MatchingView from "../views/company/MatchingView";
import AddJobPostView from "../views/company/AddJobPostView";
import HomeCompanyPage from "../views/HomeCompanyView";
import ComingSoon from "../components/ComingSoon";
import Home from "../components/pricing";
import CheckoutVerificationPage from "../components/checkout/CheckoutVerificationPage";
import CheckoutPage from "../components/checkout/CheckoutPage";
import PayMe from "../components/checkout/PayMe";
import UserOrders from "../components/orders/UserOrders";
import CompanyNavbar from "../components/navbar/CompanyNavbar";
import { getOrders } from "../services/api/pricing/getOrders";
import { GetCv } from "../services/api/cv/GetCv.js";
import Interviews from "../components/interviews/Interviews.js";
import { GetSchools } from "../services/api/features/GetSchools.js";
import ResponsibleRouter from "./router/ResponsibleRouter.js";
import ResponsibleNavbar from "../components/navbar/ResponsibleNavbar.js";
import ResponsibleAccountConfirm from "../components/accountConfirm/ResponsibleAccountConfirm.js";
import ResetPasswordResponsible from "../components/resetPassword/ResetPasswordResponsible.js";
import ChangePasswordCandidate from "../components/changePassword/ChangePasswordCandidate.js";
import ChangePasswordCompany from "../components/changePassword/ChangePasswordCompany.js";
import ChangePasswordResponsible from "../components/changePassword/ChangePasswordResponsible.js";
import DetailsCookies from "../components/home/cookies/DetailsCookies.js";
import JobPostView from "../views/JobPostView.js";
import { GetCandidateNotificationsNumber } from "../services/api/notification/GetCandidateNotificationsNumber.js";
import { GetCompanyNotificationsNumber } from "../services/api/notification/GetCompanyNotificationsNumber.js";
import io from "socket.io-client";
import { getAllFonctionnalite } from "../services/api/pricing/getAllFonctionnalite.js";
import moment from "moment-timezone";
import Calendar from "../components/calendar/Calendar.js";
import AddTestView from "../views/company/AddTestView.js";
import UploadTest from "../components/addTest/uploadTest/UploadTest.js";
import AddTestFormView from "../views/company/AddTestFormView.js";
import ManageTests from "../components/manageTests/ManageTests.js";
import TestResults from "../components/testResults/TestResults.js";
import { GetCompanyProfile } from "../services/api/profile/GetCompanyProfile.js";
import JobPostDashboard from "../components/dashboard/jobPostDashboard/JobPostDashboard.js";
import CompanyDashboard from "../components/dashboard/companyDashboard/CompanyDashboard.js";
import CandidateDashboard from "../components/dashboard/candidateDashboard/CandidateDashboard.js";
import { GetJobTitles } from "../services/api/features/GetJobTitles.js";

import CreateTestContainer from "../components/addTest/fromDB/CreateTestContainer.js";
import CheckCompanyPackage from "../components/pricing/CheckCompanyPackage.js";
import AcceptedCandidates from "../components/smartContract/AcceptedCandidates.js";
import SmartContractContainer from "../components/smartContract/SmartContractContainer.js";
import ContractPreview from "../components/smartContract/ContractPreview.js";
import BlogPage from "../components/blog/BlogPage.js";
import { MyApplications } from "../services/api/application/MyApplications.js";
import notifications from "../content/notifications.json";
import CandidateSocialLogin from "../components/signin/CandidateSocialLogin.js";
import { GetCertificates } from "../services/api/features/GetCertificates.js";
import CompanyEmailsContainer from "../components/companyEmail/CompanyEmailsContainer.js";
import CompanySettingNavbar from "../components/settings/CompanySettingNavbar";
import { GetContent } from "../services/api/content/GetContent.js";
import decryptEnv from "../utils/decryptEnv.js";
import { getEnvVars, setEnvVars } from "../utils/envVars.js";
import { GetSectors } from "../services/api/features/GetSectors.js";
import { GetEnv } from "../services/api/data/GetEnv.js";
import ExtractEmailsContainer from "../components/extractEmails/ExtractEmailsContainer.js";
import { GetModules } from "../services/api/pricing/GetModules.js";
import { GetRefreshToken } from "../services/api/auth/GetRefreshToken.js";
import { GetAccessToken } from "../services/api/auth/GetAccessToken.js";
import SimpleRouter from "./router/SimpleRouter.js";
import axios from "../interceptors/axios.js";
import PricingNavbar from "../components/pricing/PricingNavbar.js";
// import axios from "axios";
 import { ReactQueryDevtools } from 'react-query/devtools'
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import ManageTestsView from "../views/company/ManageTestsView.js";
import { notification } from "antd";
import LanguageSelector from "../components/languageSelector/LanguageSelector.js";
import { withTranslation } from "react-i18next";

import ExtractWithUpload from "../components/extractCvs/extractWithUpload/ExtractWithUpload.js";
import UploadCvsContainer from "../components/extractCvs/extractWithUpload/UploadCvsContainer.js";
import { CheckUploadedCvs } from "../services/api/extractCvs/CheckUploadedCvs.js";
import UpdateCvConfirm from "../components/UpdateCvConfirm.js";
import Faq from "../components/faq/Faq.jsx";
import CvThequeParsing from "../components/cvtheque/CvThequeParsing.jsx";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime:1000*60*5,
      refetchOnMount:true,
      // keepPreviousData:true,
      // keepUnusedData: true, // Keep all data as active
    },
  },
});

 class Routes extends Component {
  constructor(props) {
    super(props);
    this.user = React.createRef({});
    this.auth = React.createRef({});
    this.user.current = { role: "" };
    this.auth.current = {
      token: "",
      refreshToken: "",
      accessToken: "",
      headers: {},
    };
    this.jwtVerify();

    this.state = {
      hardSkillsList: [],
      softSkillsList: [],
      languagesList: [],
      locationsList: [],
      madSkillsList: [],

      schoolsList: [],
      certificatesList: [],
      jobTitles: [],
      sectorsList: [],
      jobPosts: [],
      cv: {},
      candidateAccess: true,
      candidateApplications: [],
      cart: [],
      uCodepromo: null,
      uPtsFidelite: 0,
      products: [],
      modules: [],
      fonctionnalites: [],
      myFunctionalities: [],
      total: 0,
      orders: [],
      tests: [],
      recinovQuestions: [],
      selectedSkills: [],

      notificationsNumber: 0,
      socket: null,
      load: true,
      tokensLoad: true,

      content:[],
      envVar:getEnvVars(),

      testLanguage:"fr",

      serverTime:"",
      redirectJobpost:"",

      updateCvConfirmVisible:false
    };

  }
  setEnvVar=(envVar)=>{
    this.setState({envVar})
    setEnvVars(envVar)
  }
  setUpdateCvConfirmVisible=(updateCvConfirmVisible)=>{this.setState({updateCvConfirmVisible})}
  setTestLanguage=(testLanguage)=>{this.setState({testLanguage})}
  setRedirectJobpost=(redirectJobpost)=>{this.setState({redirectJobpost})}
  setContent=(content)=>{this.setState({content})}
  setServerTime = (serverTime) => {
    // axios.defaults.headers.common['x-request-time'] = serverTime;
    this.setState({ serverTime });
  };
  setRefreshToken = (refreshToken) => {
    axios.defaults.headers.common['x-refresh-token'] = refreshToken;
    this.auth.current.refreshToken=refreshToken
  };
  setAccessToken = (accessToken) => {
    axios.defaults.headers.common['x-token'] = accessToken;
    this.auth.current.accessToken=accessToken
  };
  axiosInterceptor = ()=> {
    let refresh = false;
    axios.interceptors.response.use(async resp => {
      this.setServerTime(resp.headers.date)
      return resp}, async error => {
        try{
          if (error.response.status === 401&& !refresh) {
            if(error.response.headers['x-token']){
              let response = await GetAccessToken(
                {},
                {}
              );
              error.config.headers['x-token'] = response.data;
              this.setAccessToken(response.data)
              if(response.status==401){
                let response2 = await GetRefreshToken({});
              error.config.headers['x-refresh-token'] = response2.data;
              this.setRefreshToken(response2.data)
              }
            }
            else if(error.response.headers['x-refresh-token']){
              let response2 = await GetRefreshToken({});
            error.config.headers['x-refresh-token'] = response2.data;
            this.setRefreshToken(response2.data)
            let response = await GetAccessToken({},{});
            error.config.headers['x-token'] = response.data;
            this.setAccessToken(response.data)
            }
            else if(error.response.headers['authorization']){
              //set role and delete auth token
              this.logout()

            }
  
            // axios.defaults.headers.common['x-request-key'] = "eyey";
            refresh = false;
            return axios(error.config);
          }
          else if(error.response.status==403){
            notification.error({
              description:"Réessayer avec une autre fichier",
              className: 'custom-class',
              style: {
                width: 600,
                backgroundColor:"#f8d7da",
                marginTop:"5rem"
              },
            })
          }
          else{
            notification.error({
              description:error?.response?.data?.code==undefined?this.props.t("INTERNAL_SERVER_ERROR"):this.props.t(error.response.data.code),
              className: 'custom-class',
              style: {
                width: 600,
                backgroundColor:"#f8d7da",
                marginTop:"5rem"
              },
            }) 
          }
          refresh = false;
          // refresh = true;
          return error;
        } catch(err){
          // console.log(err);
        }
    });
    
  }
  setHeaders = () => {
    this.auth.current.headers={
      // "x-request-key":"",
      // "x-request-time":"",
      // "authorization":this.auth.current.token,
      // "x-refresh-token":this.auth.current.refreshToken,
      // "x-token":this.auth.current.accessToken,
    }
    // console.log(this.auth.current.headers);
  };
  updateHeaders = (serverTime,refreshToken,accessToken) => {
   if(serverTime) this.setServerTime(serverTime)
   if(refreshToken) this.setRefreshToken(refreshToken)
   if(accessToken) this.setAccessToken(accessToken)
   this.setHeaders()
    // console.log(this.auth.current.headers);
  };
  setContent = (content) => {
    this.setState({ content });
  };
  setTokensLoad = (tokensLoad) => {
    this.setState({ tokensLoad });
  };
  getContent = async () => {
    let response = await GetContent(
      {},
      this.auth.current.headers
    );
    if(response){
    let data = JSON.parse(decrypt(response.data));
    this.setContent(data);}
  };
  setModules = (modules) => {
    this.setState({ modules });
  };
  getModules = async () => {
    let response = await GetModules(this.auth.current.headers);
    if(response){
    let data = JSON.parse(decrypt(response.data));
    this.setModules(data);}
  };
  setSocket = (socket) => {
    this.setState({ socket });
  };
  setCandidateApplications = (candidateApplications) => {
    this.setState({ candidateApplications });
  };
  setLoad = (load) => {
    this.setState({ load });
  };
  setJobPosts = (jobPosts) => {
    this.setState({ jobPosts });
  };
  setTests = (tests) => {
    this.setState({ tests });
  };
  setRecinovQuestions = (recinovQuestions) => {
    this.setState({ recinovQuestions });
  };
  setSelectedSkills = (selectedSkills) => {
    this.setState({ selectedSkills });
  };

  setUser = (user) => {
    this.user.current = user;
  };
  NavigatorNotification = (type) => {
    let permission = Notification.permission;

    if (permission === "granted") {
      showNotification();
    } else if (permission === "default") {
      requestAndShowPermission();
    } else {
      alert("Vous avez une nouvelle notification en attente!");
    }

    function requestAndShowPermission() {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          showNotification();
        }
      });
    }
    function showNotification() {
      //  if(document.visibilityState === "visible") {
      //      return;
      //  }
      let title = "Rec-INOV";
      let icon =
        "https://rec-inov.com/company/images/629fc301b4512d557d6ec69b.png";
      let body =this.props.i18n.language=='fr' ? notifications[type].title : notifications[type].title_en;

      let notification = new Notification(title, { body, icon });

      notification.onclick = () => {
        notification.close();
        window.parent.focus();
      };
    }
  };
  connectToSocketServer = (userId) => {
    let socket = io.connect("/", { secure: true });
    this.setSocket(socket);
    socket.on("connect", () => {
      socket.emit("new-user", userId);
      socket.on("getNotification", async (data) => {
        // setNotifications((prev) => [...prev, data]);
        this.NavigatorNotification(data.type);
        this.setState({
          notificationsNumber: this.state.notificationsNumber + 1,
        });
        if (this.user.current.role == "candidate") {
          let response = await MyApplications(
            {},
            this.auth.current.headers
          );
          let applications = JSON.parse(decrypt(response.data));
          this.setCandidateApplications(applications);
        }
      });
    });
  };

  // useEffect(() => {
  //   socket?.emit("newUser", user);
  // }, [socket, user]);

  componentDidMount() {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("454254848939680");
        ReactPixel.pageView();

        Router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }
  setCandidateAccess = async () => {
    this.setState({ candidateAccess: true });
  };
  setNotificationsNumber = async (n) => {
    this.setState({ notificationsNumber: n });
  };
  getCV = async () => {
    let response = await GetCv(null, this.auth.current.headers);
    let cv = JSON.parse(decrypt(response.data));
      for (let i = 0; i < cv.hardSkills.length; i++) {
        cv.hardSkills[i].id=cv.hardSkills[i]._id
        delete cv.hardSkills[i]._id
      }
      for (let i = 0; i < cv.softSkills.length; i++) {
        cv.softSkills[i].id=cv.softSkills[i]._id
        delete cv.softSkills[i]._id
          }
      for (let i = 0; i < cv.languages.length; i++) {
        cv.languages[i].id=cv.languages[i]._id
        delete cv.languages[i]._id
      }
      if(!(cv.hardSkills.length&&cv.softSkills.length&&cv.languages.length&&cv.address.length&&cv.cv)){
        this.setState({candidateAccess:false})
        //redirect to profile
      }
      else{
        this.checkUploadedCvs()
      }
      await this.setCv(cv);
      this.user.current={...this.user.current,...cv}

    return true;
  };
  setCv = async (cv) => {
    this.setState({ cv: cv });
  };
  setCart = async (cart) => {
    this.setState({ cart: cart });
    localStorage.setItem("cart", crypt(cart));
  };
  setTotal = async (total) => {
    this.setState({ total: total });
  };
  setUCodepromo = async (uCodepromo) => {
    this.setState({ uCodepromo: uCodepromo });
    localStorage.setItem("uCodepromo", crypt(uCodepromo));
  };
  setUPtsFidelite = async (uPtsFidelite) => {
    this.setState({ uPtsFidelite: uPtsFidelite });
    localStorage.setItem("uPtsFidelite", uPtsFidelite);
  };
  setProducts = async (products) => {
    this.setState({ products: products });
  };
  setFonctionnalites = async (fonctionnalites) => {
    this.setState({ fonctionnalites: fonctionnalites });
  };
  setMyFunctionalities = async (myFunctionalities) => {
    this.setState({ myFunctionalities: myFunctionalities });
  };
  decrementMyFunctionalities = async (fType) => {
    let myFunctionalities = [...this.state.myFunctionalities];
    for (let i = 0; i < myFunctionalities.length; i++) {
      if (myFunctionalities[i].fType == fType) {
        myFunctionalities[i].qte--;
      }
    }
    this.setState({ myFunctionalities: myFunctionalities });
  };
  setOrders = async (orders) => {
    this.setState({ orders: orders });
  };
  getOrder = async () => {
    let uId =
      this.user.current.role == "responsible"
        ? this.user.current?.idCompany
        : this.user.current.id;
    let response = await getOrders(this.auth.current.headers);
    let data = JSON.parse(decrypt(response.data));

    this.setOrders(data.Order);
    let idFonctionnalities = [];
    let qtes = [];
    let fTypes = [];
    let orders = data.Order.filter((order) =>
      moment(this.state.serverTime).isBefore(moment(order.datefin))
    );
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      for (let j = 0; j < order.fonctionnalites.length; j++) {
        const item = order.fonctionnalites[j];
        let index = idFonctionnalities.indexOf(item.idFonctionnalite);
        if (index == -1) {
          idFonctionnalities.push(item.idFonctionnalite);
          fTypes.push(item.fType);
          qtes.push(parseInt(item.qte));
        } else {
          if (item.fType == 1 || item.fType == 3) {
            qtes[index] = Math.max(qtes[index], parseInt(item.qte));
          } else qtes[index] = qtes[index] + parseInt(item.qte);
        }
      }
    }
    let list = [];
    for (let i = 0; i < idFonctionnalities.length; i++) {
      list.push({
        idFonctionnalite: idFonctionnalities[i],
        qte: qtes[i],
        fType: fTypes[i],
      });
    }
    this.setMyFunctionalities(list);
  };
  getFunctionalities = async () => {
    let response = await getAllFonctionnalite(this.auth.current.headers);
    this.setFonctionnalites(JSON.parse(decrypt(response.data)).fonctionnalites);
  };
  async getSchools() {
    if (!sessionStorage.getItem("schools")) {
      let response = await GetSchools(
        {},
        this.auth.current.headers
      );
      let data = JSON.parse(decrypt(response.data));
      this.setState({ schoolsList: data });
      sessionStorage.setItem("schools", crypt(data));
    } else {
      this.setState({
        schoolsList: JSON.parse(decrypt(sessionStorage.getItem("schools"))),
      });
    }
    return true;
  }
  async getSectors() {
    if (!sessionStorage.getItem("sectors")) {
      let response = await GetSectors(
        {},
        this.auth.current.headers
      );
      let data = JSON.parse(decrypt(response.data));
      this.setState({ sectorsList: data });
      sessionStorage.setItem("sectors", crypt(data));
    } else {
      this.setState({
        sectorsList: JSON.parse(decrypt(sessionStorage.getItem("sectors"))),
      });
    }
    return true;
  }
  async getCertificates() {
    if (!sessionStorage.getItem("certificates")) {
      let response = await GetCertificates(
        {},
        this.auth.current.headers
      );
      let data = JSON.parse(decrypt(response.data));
      this.setState({ certificatesList: data });
      sessionStorage.setItem("certificates", crypt(data));
    } else {
      this.setState({
        certificatesList: JSON.parse(
          decrypt(sessionStorage.getItem("certificates"))
        ),
      });
    }
    return true;
  }
  async getJobTitles() {
    if (!sessionStorage.getItem("jobTitles")) {
      let response = await GetJobTitles(
        {},
        this.auth.current.headers
      );
      let data = JSON.parse(decrypt(response.data));
      this.setState({ jobTitles: data });
      sessionStorage.setItem("jobTitles", crypt(data));
    } else {
      this.setState({
        jobTitles: JSON.parse(decrypt(sessionStorage.getItem("jobTitles"))),
      });
    }
    return true;
  }
  async getCandidateNotificationsNumber() {
    let response = await GetCandidateNotificationsNumber(
      {},
      this.auth.current.headers
    );
    this.setState({ notificationsNumber: response.data });
    return true;
  }
  async getCompanyNotificationsNumber() {
    let response = await GetCompanyNotificationsNumber(
      {},
      this.auth.current.headers
    );
    this.setState({ notificationsNumber: response.data });
    return true;
  }
  async getCompanyProfile() {
    let response = await GetCompanyProfile(
      {},
      this.auth.current.headers
      );
      let company = JSON.parse(decrypt(response.data));
      if (this.user.current.role == "company") {
      this.user.current = { ...this.user.current, ...company };
    }
    else{
      this.user.current = { ...this.user.current,company };
    }
    return true;
  }
  async get() {
    if (
      !sessionStorage.getItem("languages") ||
      !sessionStorage.getItem("soft-skills") ||
      !sessionStorage.getItem("hard-skills") ||
      !sessionStorage.getItem("locations")
    ) {
      let response = await GetFeatures(
        {},
        this.auth.current.headers
      );
      if(response){
      let data = JSON.parse(decrypt(response.data));
      for (let i = 0; i < 5; i++) {
        const element = data[i];
        switch (element._id) {
          case 0:
            await element.features.map((feature) => {
              feature.idHardSkill = feature._id;
              delete feature._id;
            });
            this.setState({ hardSkillsList: element.features });
            await sessionStorage.setItem(
              "hard-skills",
              crypt(element.features)
            );
            break;
          case 1:
            await element.features.map((feature) => {
              feature.idSoftSkill = feature._id;
              delete feature._id;
            });
            this.setState({ softSkillsList: element.features });
            await sessionStorage.setItem(
              "soft-skills",
              crypt(element.features)
            );
            break;
          case 2:
            await element.features.map((feature) => {
              feature.idLanguage = feature._id;
              delete feature._id;
            });
            this.setState({ languagesList: element.features });
            await sessionStorage.setItem("languages", crypt(element.features));
            break;
          case 3:
            await element.features.map((feature) => {
              feature.idLocation = feature._id;
              delete feature._id;
            });
            this.setState({ locationsList: element.features });
            await sessionStorage.setItem("locations", crypt(element.features));
            break;
          case 4:
            await element.features.map((feature) => {
              feature.idMadSkill = feature._id;
              delete feature._id;
            });
            this.setState({ madSkillsList: element.features });
            await sessionStorage.setItem("mad-skills", crypt(element.features));
            break;
          default:
            break;
        }
      }}
    } else {
      this.setState({
        hardSkillsList: JSON.parse(
          decrypt(sessionStorage.getItem("hard-skills"))
        ),
        softSkillsList: JSON.parse(
          decrypt(sessionStorage.getItem("soft-skills"))
        ),
        languagesList: JSON.parse(decrypt(sessionStorage.getItem("languages"))),
        locationsList: JSON.parse(decrypt(sessionStorage.getItem("locations"))),
      });
    }

    return true;
  }
  async getRefreshToken() {
  // console.log(decrypt("U2FsdGVkX1/v5IHr+q9JNQxzxiq3bnaohlWcwlESwsY4c9VrLv0+GCPOBkE7KpI6UgySjf1FazcxuyGjpShggHAx7arPQ67vTCmMYiqE7aOEFPHFzC39NkTeNAtEGX8OMV35S7CVhs5s5beLSr08/tsnDR1rmBwynjTYovrs69RDMOJj26KoHM0eYlyyPqZxNuBMo93uP3XHC2kSkvi0xnGRM2fYuv9H7dmK125ACBJaT0Lch0WNaSXREePe7rwR8D7UCnqd6gPKwkCtyg2fVFTJvCt7l0fO3Gdf04xJF5xfV30EHNf4dN7dh6eykahKMgWymdWJDDo7vymAqUjHm7jl9ZWuARcrKaYFgoDyjFhJbNQ+dlB4etfolM6F/4oQ37MIjOpFi09xmj4YX/Jt/54yjJkDOH5itCGwIXm0Sj7M6cUSO0GzF/6ueVqgDFNnHnqOqwbEQl3NrwcHEeWwotsCyGA5DBgClwg5ebnGJ+cyCS7/oFoxR119te2WYOA2IwgmTFn0KD1w42HrZfVcJI47ukXvycoI8sqeSFgDezil35UkiauDySMVDgHKgrAZACaTish36DUaLeRgLd2DIFrjIx+CbLrScbnDJUQ3l6gxLG9luQDRJpRIsgq/YuAap9NGK6bC+bgPDJxdKGCotR3CEKQnoUdFfg0RFEcL7cObYzzpXG0LkKOdmU7c5ZaG+5cStZ2+3ZDnQzdpcxySuZIjF7CBXqTXQMhlRqCDJC/ekDPq7UpqNCBugdiMPEsDURLVijmd3Q2FLZWf1LCu0PThxZHhm5+ruzaRdcQFxEMcOZoocmM0gmCA40o5D9U5AKK+pVsxLQyVMDcYpt9s4H3izs/6lsVc37nFAG/6ZPay9Z4GM59Ud2RohIJuH2Na/sZHkp9q35Bdi6IwFp4jWbMW4nLQ2iBKPviOeHQs8kLhQ4CuNHLNNHernPGg7XpAHg1404TxRXTnQQ7t3CmHJtvVPklEl1xtX9DTJKvP6Js="));

      let response = await GetRefreshToken(
          this.auth.current.headers 
      );
      this.setRefreshToken(response.data)
    this.setHeaders()
    await this.getAccessToken()
    return response;
  }
  async getAccessToken() {
    let response = await GetAccessToken(
      {},
      this.auth.current.headers
    );
    this.setServerTime(response?.headers?.date)
    await this.setAccessToken(response?.data)
    await this.setHeaders()
    return response;
  }

  async checkUploadedCvs() {
    let response = await CheckUploadedCvs(
      {},
      this.auth.current.headers
    );
    if(response?.data){
      //open uploaded cv modal
      this.setUpdateCvConfirmVisible(true)
    }
    return response;
  }

  async getData(){
    let role = this.user.current.role;
    if (role == "company" || role == "responsible") {
      localStorage.getItem("cart") &&
        this.setCart(JSON.parse(decrypt(localStorage.getItem("cart"))));
      localStorage.getItem("uCodepromo") &&
        this.setUCodepromo(
          JSON.parse(decrypt(localStorage.getItem("uCodepromo")))
        );
      localStorage.getItem("uPtsFidelite") &&
        this.setUPtsFidelite(localStorage.getItem("uPtsFidelite"));
      await Promise.all([
        this.getCompanyProfile(),
        this.get(),
        this.getOrder(),
        this.getFunctionalities(),
        this.getModules(),
        this.getCompanyNotificationsNumber(),
        this.getJobTitles(),
        this.getContent(),
        this.getSectors(),
      ]);
      this.setLoad(false);
    }
    else if (role == "candidate") {
      await Promise.all([
        this.getSchools(),
        this.get(),
        this.getCV(),
        this.getCandidateNotificationsNumber(),
        this.getJobTitles(),
        this.getCertificates(),
        this.getContent(),
      ]);
      this.setLoad(false);
    }
    else if (role == "") {
      await Promise.all([this.getSectors(), this.getContent()]);
    }
    if (role == "candidate" || role == "company") {
      this.connectToSocketServer(this.user.current.id);
    } else if (role == "responsible")
      this.connectToSocketServer(this.user.current.idCompany);
  }
  async componentDidMount() {
    this.axiosInterceptor()
    await this.getRefreshToken();
    this.setTokensLoad(false)
    this.getData()
    
  }
  jwtVerify=async()=> {
    try {
      const token = localStorage.getItem("token");
      if (token) {
    axios.defaults.headers.common['authorization'] = token;
        this.auth.current.token=token
        let decoded = jwt_decode(token);
        // log.info('User Role : ', decoded.data.role, "user ID", decoded.data.id);
        // const exp = decoded.exp;
        // let current_time = new Date().getTime() / 1000;
        // if (current_time > exp) {
          /* expired */
          // localStorage.removeItem("token");
        // } else {
          if (decoded.data.role) {
            this.user.current = decoded.data;
            if (decoded.data.role == "candidate") {
              // if((decoded.data.address.length==0)){
              //   this.setState({candidateAccess:false})
              // }
            }
          }
        }
      // }
      let res=await GetEnv()
      if(res?.data){
        const encodedEnvVar = res.data
        const decodedEnvVar =await decryptEnv(encodedEnvVar);
        const envVars = JSON.parse(decodedEnvVar);
        this.setEnvVar(envVars)
        if(token){
          //verify token
         jwt.verify(
            token,
            envVars.REACT_APP_JWT_SECRET
          );
        }}
      } catch (error) {//logout
this.logout()
}
      
  }
   logout = async () => {
     localStorage.clear();
     sessionStorage.clear()
     console.log("logout");
      window.location.replace('/');

   }
  setCv = async (cv) => {
    this.setState({ cv: cv });
  };
  render() {
    let values = {
      envVar: this.state.envVar,
      user: this.user.current ,
      setUser:this.setUser,
      load: this.state.load,
      setLoad: this.setLoad,
      cv: this.state.cv,
      candidateAccess: this.state.candidateAccess,
      setCandidateAccess: this.setCandidateAccess,
      setCv: this.setCv,
      hardSkillsList: this.state.hardSkillsList,
      softSkillsList: this.state.softSkillsList,
      languagesList: this.state.languagesList,
      locationsList: this.state.locationsList,
      madSkillsList: this.state.madSkillsList,
      schoolsList: this.state.schoolsList,
      jobTitles: this.state.jobTitles,
      certificatesList: this.state.certificatesList,
      content: this.state.content,
      sectorsList: this.state.sectorsList,
      total: this.state.total,
      setTotal: this.setTotal,
      cart: this.state.cart,
      setCart: this.setCart,
      uCodepromo: this.state.uCodepromo,
      setUCodepromo: this.setUCodepromo,
      uPtsFidelite: this.state.uPtsFidelite,
      setUPtsFidelite: this.setUPtsFidelite,
      products: this.state.products,
      setProducts: this.setProducts,
      modules: this.state.modules,
      setModules: this.setModules,
      fonctionnalites: this.state.fonctionnalites,
      setFonctionnalites: this.setFonctionnalites,
      decrementMyFunctionalities: this.decrementMyFunctionalities,

      userId:
        this.user.current.role == "responsible"
          ? this.user.current?.idCompany
          : this.user.current.id,
      companyName:
        this.user.current.role == "responsible"
          ? this.user.current?.company?.name
          : this.user.current.name,
      role: this.user.current.role,
      orders: this.state.orders,
      setOrders: this.setOrders,
      myFunctionalities: this.state.myFunctionalities,
      setMyFunctionalities: this.setMyFunctionalities,
      notificationsNumber: this.state.notificationsNumber,
      setNotificationsNumber: this.setNotificationsNumber,
      socket: this.state.socket,

      tests: this.state.tests,
      setTests: this.setTests,

      jobPosts: this.state.jobPosts,
      setJobPosts: this.setJobPosts,

      candidateApplications: this.state.candidateApplications,
      setCandidateApplications: this.setCandidateApplications,

      recinovQuestions: this.state.recinovQuestions,
      setRecinovQuestions: this.setRecinovQuestions,

      selectedSkills: this.state.selectedSkills,
      setSelectedSkills: this.setSelectedSkills,
      testLanguage:this.state.testLanguage,
      setTestLanguage:this.setTestLanguage,
      redirectJobpost:this.state.redirectJobpost,
      setRedirectJobpost:this.setRedirectJobpost,
      refreshToken: this.auth.current.refreshToken,
      accessToken: this.auth.current.accessToken,
      headers: this.auth.current.headers,
      setRefreshToken: this.setRefreshToken,
      setAccessToken: this.setAccessToken,
      setHeaders: this.setHeaders,

      tokensLoad:this.state.tokensLoad,
      serverTime:this.state.serverTime,
      setServerTime:this.setServerTime
    };
    return (
        <QueryClientProvider client={queryClient}>
      <Router ssrLocation={this.props.ssrLocation}>
        <AppContext.Provider value={values}>
          <Navbar user={this.user.current}>
            <CandidateNavbar setCv={this.setCv} candidate={this.user.current}>
              <CompanyNavbar company={this.user.current}>
                <ResponsibleNavbar
                  role={this.user.current.role}
                  company={this.user.current.company}
                >
                <div className={`app ${this.props.i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
                  <Switch>
                    <SimpleRouter exact path="/logout">
                      <Logout />
                    </SimpleRouter>
                    <SimpleRouter
                      exact
                      path="/accountConfirm/company/:token"
                    >
                      <CompanyAccountConfirm /> 
                    </SimpleRouter>

                    <SimpleRouter
                      exact
                      path="/accountConfirm/candidate/:token"
                      >
                      <CandidateAccountConfirm />
                    </SimpleRouter>

                    <SimpleRouter
                      exact
                      path="/accountConfirm/responsible/:token"
                      >
                      <ResponsibleAccountConfirm />
                    </SimpleRouter>
                    <SimpleRouter
                      exact
                      path="/appointment/cancel/:token"
                      >
                      <CancelAppointment />
                    </SimpleRouter>
                    <SimpleRouter
                      exact
                      path="/jobPost/:reference"
                    >
                     <JobPostView />
                    </SimpleRouter>
                    <SimpleRouter path="/demo/:url">
                      <div
                        style={{
                          paddingTop: "14rem",
                          minHeight: "100%",
                          marginBottom: "5rem",
                        }}
                      >
                        <Room isDemo={true} />
                      </div>
                    </SimpleRouter>
                    <SimpleRouter path="/blog/:title">
                      <BlogPage />
                    </SimpleRouter>
                    <SimpleRouter path="/blog">
                    <BlogView />
                    </SimpleRouter>

                    <PublicRouter
                      exact
                      path="/changePassword/company/:token1/:token2/:token3"
                    >
                    <ResetPasswordCompany />
                    </PublicRouter>

                    <PublicRouter
                      exact
                      path="/changePassword/responsible/:token1/:token2/:token3"
                      >
                      <ResetPasswordResponsible />
                      </PublicRouter>
                    <PublicRouter
                      exact
                      path="/changePassword/candidate/:token1/:token2/:token3"
                      >
                      <ResetPasswordCandidate />
                      </PublicRouter>
                    <PublicRouter path="/room/:url">
                      <div
                        style={{
                          paddingTop: "14rem",
                          minHeight: "100%",
                          marginBottom: "5rem",
                        }}
                      >
                        <Room />
                      </div>
                    </PublicRouter>

                    <PublicRouter path="/demo"   >
                        <DemoView/>
                    </PublicRouter>

                    <PublicRouter exact path="/">
                      <HomePage />
                    </PublicRouter>

                    <PublicRouter
                      exact
                      path="/signup/company"
                      >
                      <CompanySignupView/>
                  </PublicRouter>
                    <PublicRouter
                      exact
                      path="/signup/candidate"
                      >
                      <CandidateSignupView/>
                  </PublicRouter>
                  <PublicRouter
                      exact
                      path="/signup/candidate/:token"
                      >
                      <CandidateSignupView/>
                  </PublicRouter>
                    <PublicRouter
                      exact
                      path="/signin/company"
                      >
                      <CompanySigninView/>
                  </PublicRouter>
                    <PublicRouter
                      exact
                      path="/signin/candidate"
                      >
                      <CandidateSigninView/>
                  </PublicRouter>
                    <PublicRouter
                      path="/signin/company/:token"
                      >
                      <CandidateSocialLogin/>
                  </PublicRouter>
                    <PublicRouter
                      path="/signin/candidate/:token"
                    >
                        <CandidateSocialLogin/>
                    </PublicRouter>

                    <PublicRouter
                      path="/politique-confidentialite"
                      >
                      <DetailsCookiesView/>
                  </PublicRouter>

                    <PublicRouter
                      path="/home-company"
                      >
                      <HomeCompanyPage/>
                  </PublicRouter>

                    {/* <CandidateRouter path="/candidate/blog/:title">
                  <BlogPage/>
                  </CandidateRouter>
                <CandidateRouter path="/candidate/blog" component={BlogView} /> */}

                    <CandidateRouter  exact path="/candidate/cv" load={this.state.load}>
                        <CandidateCvView userId={this.user.current.id} />
                    </CandidateRouter>
                    <CandidateRouter exact path="/candidate/cv/upload">
                      <CandidateCvUploadView userId={this.user.current.id} />
                    </CandidateRouter>
                    <CandidateRouter exact path="/candidate/test">
                    </CandidateRouter>
                    <CandidateRouter exact path="/candidate/profile" load={this.state.load}>
                      <CandidateProfileView />
                    </CandidateRouter>

                    <CandidateRouter exact path="/candidate/jobPosts">
                      <CandidateJobPostsListView
                        userId={this.user.current.id}
                      />
                    </CandidateRouter>
                    <CandidateRouter exact path="/candidate/jobPost">
                      <CandidateJobPostView userId={this.user.current.id} />
                    </CandidateRouter>

                    <CandidateRouter exact path="/candidate/applications">
                      <CandidateApplicationsView
                        userId={this.user.current.id}
                      />
                    </CandidateRouter>

                    <CandidateRouter exact path="/candidate/skillTest">
                      <CandidateSkillTestingView
                        userId={this.user.current.id}
                      />
                    </CandidateRouter>

                    <CandidateRouter exact path="/candidate/jobPostTest">
                  <CandidateJobPostTestingView method={0} userId={this.user.current.id} />
                </CandidateRouter>

                <Route exact path="/jobPostTest/link/:link">
                  <CandidateJobPostTestingView  method={1} userId={this.user.current.id} />
                </Route>
                
                <Route exact path="/jobPostTest/:id">
                  <CandidateJobPostTestingView  method={2} userId={this.user.current.id} />
                </Route>
                    <CandidateRouter exact path="/candidate/myCv" load={this.state.load}>
                        <CandidateProfilCvView userId={this.user.current.id} />
                    </CandidateRouter>
                    <CandidateRouter path="/candidate/room/:url" load={this.state.load}>
                     <Room />
                    </CandidateRouter>

                    <CandidateRouter exact path="/candidate/setting">
                      <ChangePasswordCandidate userId={this.user.current.id} />
                    </CandidateRouter>

                    <CandidateRouter
                      exact
                      path="/candidate/politique-confidentialite"
                    >
                      <DetailsCookies />
                    </CandidateRouter>

                    <CandidateRouter  load={this.state.load} exact path="/candidate/dashboard">
                        <CandidateDashboard userId={this.user.current.id} />
                    </CandidateRouter>
                    <CandidateRouter exact path="/company/language">
                      <CompanySettingNavbar selectedKey="3" />
                      <LanguageSelector/>
                    </CandidateRouter>

                    <CandidateRouter
                      exact
                      path="/candidate/smartContract/:token"
                    >
                      <ComingSoon />
                      {/* <ContractPreview userId={this.user.current.id} /> */}
                    </CandidateRouter>
                    <CandidateRouter exact path="/candidate/faq" load={this.state.load}>
                      <Faq /> 
                    </CandidateRouter>

                    <CompanyRouter exact path="/company/dashboard" load={this.state.load}>
                        <CompanyDashboard userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/profile" load={this.state.load}>
                      <CompanyProfileView />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/contact" load={this.state.load}>
                      <CompanyContact />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/manageResponsible" load={this.state.load}>
                        <ManageResponsible userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/uploadOffre">
                      <UploadJobPostView userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/manageJobPosts" load={this.state.load}>
                        <ManageJobPostsView
                          company={this.user.current}
                          userId={this.user.current.id}
                        />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/faq" load={this.state.load}>
                      <Faq /> 
                    </CompanyRouter>

                    <CompanyRouter
                      exact
                      path="/company/manageJobPost/:reference"
                    >
                      <JobPostDashboard userId={this.user.current.id} />
                    </CompanyRouter>

                    <CompanyRouter exact path="/company/jobApplications">
                      <JobApplications headers={this.auth.current.headers} userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/interviews">
                      <Interviews userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/matching">
                      <MatchingView userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/addJobPost" load={this.state.load}>
                        <AddJobPostView userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/addTest" load={this.state.load}>
                        <AddTestView userId={this.user.current.id} />
                      {/* <ComingSoon /> */}
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/addTest/upload" load={this.state.load}>
                        <UploadTest userId={this.user.current.id} />
                      {/* <ComingSoon /> */}
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/addTest/fromDB" load={this.state.load}>
                        <CreateTestContainer userId={this.user.current.id} />
                      {/* <ComingSoon /> */}
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/addTest/form" load={this.state.load}>
                        <AddTestFormView userId={this.user.current.id} />
                      {/* <ComingSoon /> */}
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/manageTests" load={this.state.load}>
                        <ManageTestsView userId={this.user.current.id} />
                      {/* <ComingSoon /> */}
                    </CompanyRouter>

                    <CompanyRouter exact path="/company/testResults">
                      <TestResults userId={this.user.current.id} />
                    </CompanyRouter>
                    {/* <CompanyRouter exact path="/company/applications">
              <CompanyNavbar  company={this.user.current} content={<Test  userId={this.user.current.id}  image={this.user.current.image} />} />
            </CompanyRouter> */}

                    <CompanyRouter path="/company/room/:url" load={this.state.load}>
                       <Room />
                    </CompanyRouter>

                    {/* <CompanyRouter path="/company/blog/:title">
                  <BlogPage/>
                  </CompanyRouter>
                <CompanyRouter path="/company/blog" component={BlogView} /> */}

                    {/* <CompanyRouter path="/company/blog/:type/:title" component={BlogView} /> */}

                    <CompanyRouter exact path="/company/pricing" load={this.state.load}>
                    <PricingNavbar selectedKey="1" />
                        <Home userId={this.user.current.id} />
                    </CompanyRouter>

                    <CompanyRouter exact path="/company/checkout/1" load={this.state.load}>
                        <CheckoutVerificationPage
                          userId={this.user.current.id}
                        />
                    </CompanyRouter>

                    <CompanyRouter exact path="/company/checkout/2" load={this.state.load}>
                        <CheckoutPage userId={this.user.current.id} />
                    </CompanyRouter>

                    <CompanyRouter exact path="/company/paymee" load={this.state.load}>
                        <PayMe userId={this.user.current.id} />
                    </CompanyRouter>

                    <CompanyRouter exact path="/company/setting">
                      <CompanySettingNavbar selectedKey="1" />
                      <ChangePasswordCompany userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/language">
                      <CompanySettingNavbar selectedKey="3" />
                      <LanguageSelector/>
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/orders" load={this.state.load}>
                    <PricingNavbar selectedKey="2" />
                       <UserOrders />
                    </CompanyRouter>
                    <CompanyRouter
                      exact
                      path="/company/politique-confidentialite"
                    >
                      <DetailsCookies />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/calendar">
                      <Calendar userId={this.user.current.id} />
                    </CompanyRouter>

                    <CompanyRouter exact path="/company/companyPackage/:token">
                      <CheckCompanyPackage userId={this.user.current.id} />
                    </CompanyRouter>

                    <CompanyRouter
                      exact
                      path="/company/smartContract/acceptedCandidates"
                    >
                      <AcceptedCandidates userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/smartContract">
                      {/* <SmartContractContainer userId={this.user.current.id} /> */}
                      <ComingSoon />
                    </CompanyRouter>
                    <CompanyRouter
                      exact
                      path="/company/CandidateParsing"
                    >
                      <CvThequeParsing userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/emails" load={this.state.load}>
                      <CompanySettingNavbar selectedKey="2" />
                      <CompanyEmailsContainer userId={this.user.current.id} />
                    </CompanyRouter>
                    <CompanyRouter exact path="/company/extract-emails">
                {/* <ExtractEmailsContainer userId={this.user.current.id} /> */}
                      <ComingSoon />
                  </CompanyRouter>
                  <CompanyRouter exact path="/company/extract-cvFiles">
                {/* <ExtractEmailsContainer userId={this.user.current.id} /> */}
                      <UploadCvsContainer />
                  </CompanyRouter>
                    {/* </SettingNavbar> */}

                    {/* responsible  */}

                    <ResponsibleRouter exact path="/responsible/dashboard">
                      {/* <CompanyNavbar company={this.user.current} content={<CompanyDashboard company={this.user.current} />} /> */}
                      <CompanyDashboard
                        userId={this.user.current.idCompany}
                      />
                    </ResponsibleRouter>
                    {/* <ResponsibleRouter exact path="/responsible/profile">
                    <CompanyProfileView />
                  </ResponsibleRouter>
                  <ResponsibleRouter exact path="/responsible/contact">
                    <CompanyContact />
                  </ResponsibleRouter>
                  <ResponsibleRouter exact path="/responsible/manageResponsible">
                    <ManageResponsible userId={this.user.current.idCompany} />
                  </ResponsibleRouter> */}
                    <ResponsibleRouter exact path="/responsible/uploadOffre">
                      <UploadJobPostView
                        userId={this.user.current.idCompany}
                      />
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/manageJobPosts" load={this.state.load}>
                        <ManageJobPostsView
                          idResponsible={this.user.current.id}
                          company={this.user.current}
                          userId={this.user.current.idCompany}
                        />
                    </ResponsibleRouter>
                    <ResponsibleRouter
                      exact
                      path="/responsible/manageJobPost/:reference"
                    >
                      <JobPostDashboard
                        userId={this.user.current.idCompany}
                      />
                    </ResponsibleRouter>
                    <ResponsibleRouter
                      exact
                      path="/responsible/jobApplications"
                    >
                      <JobApplications
                      headers={this.auth.current.headers}
                        userId={this.user.current.idCompany}
                      />
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/interviews">
                      <Interviews userId={this.user.current.idCompany} />
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/matching">
                      <MatchingView userId={this.user.current.idCompany} />
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/addJobPost" load={this.state.load}>
                        <AddJobPostView
                          idResponsible={this.user.current.id}
                          userId={this.user.current.idCompany}
                        />
                    </ResponsibleRouter>

                    <ResponsibleRouter exact path="/responsible/addTest" load={this.state.load}>
                        <AddTestView
                          userId={this.user.current.idCompany}
                        />
                      {/* <ComingSoon /> */}
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/addTest/upload">
                      <UploadTest userId={this.user.current.idCompany} />
                      {/* <ComingSoon /> */}
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/addTest/fromDB">
                      <CreateTestContainer
                        userId={this.user.current.idCompany}
                      />
                      {/* <ComingSoon /> */}
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/addTest/form">
                      <AddTestFormView
                        userId={this.user.current.idCompany}
                      />
                      {/* <ComingSoon /> */}
                    </ResponsibleRouter>

                    <ResponsibleRouter exact path="/responsible/testResults">
                      <TestResults userId={this.user.current.idCompany} />
                      {/* <ComingSoon /> */}
                    </ResponsibleRouter>

                    <ResponsibleRouter path="/responsible/room/:url" load={this.state.load}>
                       <Room />
                    </ResponsibleRouter>

                    {/* <ResponsibleRouter path="/responsible/blog/:title" component={BlogView} />
                <ResponsibleRouter
                  path="/responsible/blog"
                  component={BlogView}
                /> */}

                    <ResponsibleRouter exact path="/responsible/setting">
                      <ChangePasswordResponsible
                        userId={this.user.current.id}
                      />
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/orders">
                    <PricingNavbar selectedKey="2" />
                      <UserOrders />
                    </ResponsibleRouter>
                    <ResponsibleRouter
                      exact
                      path="/responsible/politique-confidentialite"
                    >
                      <DetailsCookies />
                    </ResponsibleRouter>

                    <ResponsibleRouter exact path="/responsible/manageTests" load={this.state.load}>
                        <ManageTestsView
                          idResponsible={this.user.current.id}
                          userId={this.user.current.idCompany}
                        />
                      {/* <ComingSoon /> */}
                    </ResponsibleRouter>

                    <ResponsibleRouter exact path="/responsible/testResults">
                      <TestResults userId={this.user.current.idCompany} />
                      {/* <ComingSoon /> */}
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/pricing">
                    <PricingNavbar selectedKey="1" />
                      <Home userId={this.user.current.idCompany} />
                    </ResponsibleRouter>

                    <ResponsibleRouter exact path="/responsible/checkout/1">
                      <Home userId={this.user.current.idCompany} />
                    </ResponsibleRouter>

                    <ResponsibleRouter exact path="/responsible/checkout/2">
                      <Home userId={this.user.current.idCompany} />
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/responsible/faq" load={this.state.load}>
                      <Faq /> 
                    </ResponsibleRouter>
                    <ResponsibleRouter exact path="/company/language">
                      <CompanySettingNavbar selectedKey="3" />
                      <LanguageSelector/>
                    </ResponsibleRouter>
                    <Route component={NotFoundView} />
                
                  </Switch>
                </div>
                </ResponsibleNavbar>
              </CompanyNavbar>
            </CandidateNavbar>
          </Navbar>
      <UpdateCvConfirm modalVisible={this.state.updateCvConfirmVisible}
setModalVisible={this.setUpdateCvConfirmVisible} />
        </AppContext.Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </Router>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    );
  }
}
export default withTranslation()(Routes)