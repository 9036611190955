import React, { useContext, useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import listPlugin from "@fullcalendar/list" // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid" // needed for dayClick
 import moment from 'moment-timezone';
import decrypt from '../../utils/decrypt'

import { GetInterviews } from '../../services/api/interview/GetInterviews'
import frLocale from '@fullcalendar/core/locales/fr';
import InterviewModal from './InterviewModal'
import { AppContext } from '../../routes/AppContext'
import { useTranslation } from 'react-i18next'
export default function Calendar(props) {
  const { i18n } = useTranslation();
  const {serverTime}=useContext(AppContext)
  const [modalVisible,setModalVisible]=useState(false)  
  const [interview,setInterview]=useState(null)  

   const handleDateClick = (arg) => { // bind with an arrow function
        props.handleDateClick(arg.dateStr)
      }
      
  return (
    <div className=' p-4 m-2' >
    {/* <h3>
    <b className=' rounded-circle bg-primary text-white px-2 py-1  m-1 ' >1</b>
    <strong> Choisissez un rendez-vous</strong>
    <hr />
    </h3> */}
    <FullCalendar
        plugins={[ dayGridPlugin,interactionPlugin,listPlugin,timeGridPlugin ]}
        initialView="dayGridMonth"
        // loading={true}
        // views={}
        weekends={true}
        locale={i18n.language}
        locales={frLocale}
        timeZone='Africa/Tunis'
        // slotMinTime="08:00:00"
        // slotMaxTime="18:00:00"
        // slotDuration={{minutes:30}}
        dateClick={handleDateClick}
        
        headerToolbar={{
          left: "title",
          center: "",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek prev,next",
        }}
        // footerToolbar= {{
        //   left: '',
        //   center: '',
        //   right: 'prev,next'
        // }}
        navLinks={true}
        allDaySlot={false}
   initialDate={new Date(serverTime)}
  //  initialDate={"2021-05-09T00:00:00"}
  // editable={true}
  height={640}
  selectable={true}
  themeSystem= 'bootstrap5'
  // selectMirror={true}
  dayMaxEvents={true}
  // select={(e)=>{console.log(e);}}
  events={props.events}
  // eventColor={e=>{console.log(e);return "blue"}}
  eventClick={(e) => {
    setInterview(e.event._def.extendedProps.interview)
    setModalVisible(true)
  }}
  // eventTextColor="red"
  
  // eventContent= {(e)=>{
    // return(<h6><b className='text-center text-white center  ' >
    //   {e.timeText}
    //   </b></h6>)
  // }}
  // dayHeaderFormat={(param) => {
  //   return param.date.day.toString();}}
      />
      {modalVisible &&<InterviewModal modalVisible={modalVisible} setModalVisible={setModalVisible} interview={interview} />}
    </div>
  )
}
