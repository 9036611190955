import React, { useState } from "react";
import { Steps } from "antd";

import {
  SettingOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function FormSteps(props) {
  const { t } = useTranslation();

  return (
    <div className="Steps" style={{ direction: "ltr"}}>
      {" "}
      <Steps
        style={{ padding: "32px 8px" }}
        // onChange={setCurrent}
        current={props.current}
      >
        <Steps.Step title={t("Paramètres du test")} icon={<SettingOutlined />} />
        <Steps.Step title={t("Ajout question")} icon={<PlusCircleOutlined />} />
        <Steps.Step title={t("Aperçu du test")} icon={<EyeOutlined />} />
      </Steps>
    </div>
  );
}
