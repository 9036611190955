import { Menu } from "antd";
import React, { useContext, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { AppContext } from "../../routes/AppContext";
import { useTranslation } from "react-i18next";

function PricingNavbar(props) {
  
  const { t } = useTranslation();

  const history = useHistory({});
  const { role } = useContext(AppContext);

  const RedirectMe = (e) => {
    switch (e.key) {
      case "1":
        history.push("/" + role + "/pricing");
        break;
      case "2":
        history.push("/" + role + "/orders");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Menu
        id="sNavbar"
        onClick={(e) => RedirectMe(e)}
        mode="horizontal"
        defaultSelectedKeys={[props.selectedKey]}
      >
        <Menu.Item key="1">
          {" "}
          {/* <UnorderedListOutlined />  */}
          {t("Forfaits")}
        </Menu.Item>
        <Menu.Item key="2">
          {/* <ContainerOutlined />  */}
          {t("Suivi")}
        </Menu.Item>
      </Menu>
    </>
  );
}
export default withRouter(PricingNavbar);
