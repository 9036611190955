import React from "react";
import loadar from "../media/image/loadar.gif";
import loadfr from "../media/image/loadfr.gif";
import loaden from "../media/image/loaden.gif";
import { useTranslation } from "react-i18next";
export default function Loading() {
  const { i18n } = useTranslation();
  const load= i18n.language==="ar" 
              ?loadar
              :(i18n.language==="en"
                ?loaden :loadfr)
  return (
    <div>
      <div className="main-content">
        <div className="page-content my-5">
          <section className="bg-coming-soon bg-auth">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="text-center">
                    <div className="mb-4 pb-3">
                      <img src={load} alt="load"  titre="load" height="300" className="mg-fluid" />
                    </div>
                    <div id="countdown" className="d-flex"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
