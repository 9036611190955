/* eslint-disable */
import React, { Fragment, useEffect, useContext, useState } from "react";
import moment from "moment-timezone";

// import Layout from "./Layout";
// import { DashboardUserContext } from "./Layout";
// import { getAllfonctionnaliteByType } from "../../admin/fonctionnalites/FetchApi";
// import { fetchOrderByUser } from "./Action";
// import { OrderLastByUserFonct } from "./FetchApi";
import Navbar from "../settings/CompanySettingNavbar";
import { AppContext } from "../../routes/AppContext";
import { getAllFonctionnalite } from "../../services/api/pricing/getAllFonctionnalite";
import decrypt from "../../utils/decrypt";
import PaymentBill from "./PaymentBill";

import EstimationBills from "../estimationBills/EstimationBills";
import { useTranslation } from "react-i18next";


/* table suivi achat (header) */
const TableHeaderSuivi = () => {
  const { t,i18n } = useTranslation();
  const { fonctionnalites } = useContext(AppContext);
  const language=i18n.language 
  const modulesLanguage={"fr":"name","en":"name_en","ar":"name_ar"}
  const module=modulesLanguage[language]

  const functionalitiesLanguage={"fr":"fName","en":"fName_en","ar":"fName_ar"}
  const functionalityLanguage=functionalitiesLanguage[language]

  const packagesLanguage={"fr":"pName","en":"pName_en","ar":"pName_ar"}
  const packageLanguage=packagesLanguage[language]


  return (
    <Fragment>
      <thead style={{ background: "#005bea" }}>
        <tr>
          <th className="px-4 border text-gray-100"></th>
          {fonctionnalites && fonctionnalites.length > 0
            ? fonctionnalites.map((item1, key) => {
                return (
                  <th className="px-4 border text-white" key={key}>
                    {item1[functionalityLanguage]}
                  </th>
                );
              })
            : null}
        </tr>
      </thead>
    </Fragment>
  );
};

const TableBodySuivi = () => {
  const { t } = useTranslation();
  const { fonctionnalites,orders,myFunctionalities,role } = useContext(AppContext);

  let list=myFunctionalities
 

  return (
    <Fragment>
      <tr className="border-b">
        <td className="w-48 hover:bg-gray-200 p-2 flex flex-col space-y-1">
          <span className="block flex items-center space-x-2">
            <span>{t("Total")}</span>
          </span>
        </td>
        {fonctionnalites && fonctionnalites.length > 0
          ? fonctionnalites.map((item, key) => {
          let func=list.find(f=>item.fType == f.fType)  
           let qte=func?.qte
            if(func?.fType==4){
              let h=Math.floor(func?.qte/60)
              let m=func?.qte%60
              qte=h?h+"h":""
              m?qte=qte+m:""
              h?"":qte=qte+"m"
            }
              return func !== undefined ? (
                item.fType == func.fType ? (
                  <td className="p-2 text-center">{qte>999999?t("illimité"):qte}</td>
                ) : (
                  <td className="p-2 text-center">-</td>
                )
              ) : (
                <td className="p-2 text-center">-</td>
              );
            })
          : null}
      </tr>
    </Fragment>
  );
};


const OrdersComponent = () => {
  const { t } = useTranslation();
  const { orders } = useContext(AppContext);
  return (
    <Fragment>
      <div className="flex flex-col w-full my-4 md:my-0 md:w-11/12 md:px-8">
        <div className="border">
          {/* <div className="py-4 px-4 text-lg font-semibold border-t-2 bg-white">
            Orders
          </div>
          <hr /> */}
          <div className="overflow-auto bg-white shadow-lg p-4">
            <div className="py-2 px-4 text-lg font-semibold ">
               <h2> <strong>{t("Table de suivi consommation du forfait disponible")}</strong> </h2> 
            </div>

            <table className="table-auto border w-full my-2 ">
              <TableHeaderSuivi />
              <tbody>
              {orders && orders.length > 0 ? (
                  <TableBodySuivi />
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="text-xl text-center font-semibold py-8"
                    >
                      {t("Aucune achat réalisée")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <br />
            
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const UserOrders = (props) => {
  return (
    <Fragment>
    <EstimationBills/>
    <OrdersComponent />
    </Fragment>
  );
};

export default UserOrders;
