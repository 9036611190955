import React, { Component,Suspense } from "react";
import { GetSuggestedSkills } from "../../services/api/suggestSkill/GetsuggestSkill";
// import { CKEditor } from "ckeditor4-react"; 
// import ReactQuill from 'react-quill';
import InfoIcon from "../icons/InfoIcon";
import { AutoComplete, Modal } from "antd";
import { AppContext } from "../../routes/AppContext";
import { withTranslation } from "react-i18next";
import { GetSuggDesc } from "../../services/api/suggestSkill/GetSuggDesc";
import { LoadingOutlined } from "@ant-design/icons";
class Form1 extends Component {
  static contextType = AppContext
  constructor(props,context) {
    super(props);
    this.state = {
      suggg:false,
      descc:this.props.description,
      descproff:this.props.requestedProfile,
      isReady: false,
      error: null, 
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // Set isReady to true when component mounts on the client
    console.log('Component mounted');
    this.setState({ isClient: true });
}
componentDidCatch(error, info) {
  console.error('Component error:', error); // Log de débogage pour les erreurs de composant
  this.setState({ error }); // Mettre à jour l'état d'erreur en cas d'erreur
}

  info = () => {
    Modal.info({
      title: null,
      okText: this.props.t("ok"),
      content: (
        <div>
           <p className='fw-bold' > {this.props.t("Vous pouvez choisir parmi nos intitulés de postes qui peuvent être plus similaires aux emplois pour lesquels les candidats souhaitent postuler, ou vous pouvez créer vos propres intitulés de poste .")}  </p>
        </div>
      ),
      onOk() { },
    });
  };
  async handleSubmit(event) {
    event.preventDefault();
    let response = await GetSuggestedSkills(this.props.title,this.context.headers);
if(response?.data){
   let skills=response.data.skills
   let softSkills=response.data.soft_skills

   let filteredHardSkills = this.context.hardSkillsList.filter((skill) => {
    return skills.includes(skill.similar_skill);
  });
  let filteredSoftSkills = this.context.softSkillsList.filter((skill) => {
    return softSkills.includes(skill.other_ss)
  });
  let oldHardSkills=[...this.props.suggestedSkills]
  let oldSoftSkills=[...this.props.suggestedSoftSkills]
  filteredHardSkills.map((skill) => {
    skill.score = 0.2;
    //if founded occ=2 && delete in the old list  
    let skillIndex=oldHardSkills.findIndex((s)=>s.code==skill.code)
    if(skillIndex!=-1){
      skill.occ=2
      oldHardSkills.splice(skillIndex, 1);
    }
    else
    skill.occ=1
  });
  filteredSoftSkills.map((skill) => {
    skill.score = 0.2;
     //if founded occ=2 && delete in the old list  
     let skillIndex=oldSoftSkills.findIndex((s)=>s.code==skill.code)
     if(skillIndex!=-1){
       skill.occ=2
       oldSoftSkills.splice(skillIndex, 1);
     }
     else
     skill.occ=0
  });
    this.props.setSuggestedSkills([...oldHardSkills,...filteredHardSkills])
    this.props.setSuggestedSoftSkills([...oldSoftSkills,...filteredSoftSkills])}
    return true;
  }
  async getSugg(event,tt,tab) {
    this.setState({ suggg: true });
    if(tt!==undefined){
      const sel=await tab.find((item)=> item.value===tt)
    // const sel=await tab.map((item)=> {
    //   // console.log(item.value)
    //   if(item.value===tt){

    //     (console.log(item))
    //     return item
    //   } 
    
    // })
    if(sel!==undefined){
      let response= await GetSuggDesc(sel._id,this.context.headers).then((res)=>res.data);
          console.log(response)
          if(response.id){
            console.log("yes",this.props.i18n.language,this.props,this.context.headers)
            this.props.setIdSygg(response.id)
            if(this.props.description.length===0 || this.props.description==="<p><br></p>"){
              if(this.props.i18n.language==="fr"){
                this.props.setDescription(response.descriptionfr)
                this.setState({ descc: response.descriptionfr });
                this.props.setSuggDesc(response.descriptionfr)
              }else if(this.props.i18n.language==="en" || this.props.i18n.language==="ar"){
                this.props.setDescription(response.descriptionEn)
                this.setState({ descc: response.descriptionEn });
                this.props.setSuggDesc(response.descriptionEn)
              }
            }
            if(this.props.requestedProfile.length===0 || this.props.requestedProfile==="<p><br></p>" ){
              if(this.props.i18n.language==="fr"){
                this.props.setRequestedProfile(response.descriptionprofilfr)
                this.setState({ descproff: response.descriptionprofilfr });
                this.props.setSuggDescProf(response.descriptionprofilfr)
              }else if(this.props.i18n.language==="en" || this.props.i18n.language==="ar"){
                this.props.setRequestedProfile(response.descriptionprofilEn)
                this.setState({ descproff: response.descriptionprofilEn });
                this.props.setSuggDescProf(response.descriptionprofilEn)
              }
            }
          }else {console.log("no no")}
    }
    this.setState({ suggg: false });
  }
    // map((item)=>(item.value==tt return item))  this.props.setDescription(data)
    
    // let response2 = await GetSuggDesc("id",this.context.headers);
    // if(this.props.description!=="" ){

    //   this.props.setDescription(response2);
    // }
    // if(this.props.description!=="" ){

    //   this.props.setDescription(response2);
    // }
    // this.setState({ suggg: false });


    // const data = event.editor.getData();
    //             this.props.setDescription(data);
    // this.props.setSuggestedSkills([...oldHardSkills,...filteredHardSkills])
  }


  render() {
    const { t,i18n } = this.props;
  //   const { isReady ,error } = this.state;
  //   if (error) {
  //     return <div>Erreur : {error.message}</div>; // Affichez l'erreur si elle se produit
  // }
  // const LoadingFallback = () => <div></div>;

  //   // Render ReactQuill only when isReady is true (client-side)
  //   const LazyReactQuill = React.lazy(() => import('react-quill'));
  const { error, isClient } = this.state;

  if (error) {
    return <div>Erreur : {error.message}</div>; // Affichez l'erreur si elle se produit
  }

  // Importer ReactQuill uniquement côté client
  const LazyReactQuill = isClient ? require("react-quill") : null;


    return (
      <div>
        <div className="row mb-5 p-2">
          <label className="col-lg-8 col-sm-12 mt-2 " htmlFor="title">
            <span className="">
              {t("Titre d'offre")} <b style={{    color: "blue"}}>* </b>
        <span onClick={this.info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>

            </span>{" "}
            <br />
            <AutoComplete
                    className="w-100 border "
                    id="title"
                    type="text"
                    name="title"
                    defaultValue={this.props.title}
                    onChange={async(e)=>{await this.props.setTitle(e) ;await this.getSugg(e,this.props.title,this.context.jobTitles)}}
                    onClick={this.getSugg}
                    onBlur={this.handleSubmit}
                        options={this.context.jobTitles}
                        // placeholder="try to type `b`"
                        filterOption={(inputValue, option) =>
                          { 
                            if(option.value)
                           return option?.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1}
                        }
                      />
            {/* <input
              className="border form-control "
              id="title"
              type="text"
              name="title"
              defaultValue={this.props.title}
              onChange={this.props.setTitle}
              onBlur={this.handleSubmit}
            /> */}
            <p className="text-danger">
              <small>{this.props.titleError}</small>
            </p>
          </label>
        {!this.props.id &&  <label className="col-lg-4 col-sm-12 mt-2 " htmlFor="title">
            <span className="">
              {t("Référence")}
            </span>{" "}
            <br />
            <input
              className="border form-control "
              id="reference"
              type="text"
              name="reference"
              defaultValue={this.props.reference}
              onChange={this.props.setReference}
              disabled={this.props.id}
            />
          </label>}
         {this.state.suggg 
         ? <LoadingOutlined style={{fontSize: '40px',marginTop:"20px"}} />
         : <>
          <label className="col-lg-6 col-sm-12 mt-2 " htmlFor="description">
            <span className="">
              
                {t("Description de poste")}  <b style={{    color: "blue"}}>* </b>
        {/* <span onClick={this.descriptionInfo} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span> */}

            </span>{" "}
            <br />
            <div className="parent-container">
        {isClient && LazyReactQuill && (
          <LazyReactQuill
            value={this.state.descc}
            onChange={(e)=>{this.props.setDescription(e);this.setState({ descc: e })}}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, 
                 {'indent': '-1'}, {'indent': '+1'}],
                ['clean']                                         
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 
            ]}
          />
        )}
      </div>
            {/* <CKEditor
              onChange={(event) => {
                const data = event.editor.getData();
                this.props.setDescription(data);
                this.setState({ descc: data });
              }}
              data={this.state.descc}
              initData={this.state.descc}
              config={{toolbar:[
                [ 'Bold', 'Italic',"Underline","Strike","-","RemoveFormat" ],
                // [ 'Link', 'Unlithis.state.desccnk',"Anchor" ],
                [ 'NumberedList', 'BulletedList'],
                // ["Outdent","Indent","Blockquote" ],
                // [ 'Image', 'HorizontalRule',"SpecialChar" ],
                [ 'Styles', 'Format' ],
                [ 'Maximize' ],
            ]}}
            /> */}
            <p className="text-danger">
              <small>{this.props.descriptionError}</small>
            </p>
          </label>
          <label className="col-lg-6 col-sm-12 mt-2" htmlFor="profile">
            <span className="">
            {t("Description du profil recherché pour tenir le poste")}  <b style={{    color: "blue"}}>* </b>
        {/* <span onClick={this.requestedProfileInfo} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span> */}

            </span>{" "}
            <br />
            {isClient && LazyReactQuill && (
          <LazyReactQuill
          value={this.state.descproff} onChange={(e)=>{this.props.setRequestedProfile(e);this.setState({ descproff: e })}}
          modules={{
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, 
               {'indent': '-1'}, {'indent': '+1'}],
              ['clean']                                         
            ],
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 
          ]}
          />
        )}
            {/* <CKEditor
              onChange={(event) => {
                const data = event.editor.getData();
                this.props.setRequestedProfile(data);
                this.setState({ descproff: data });
              }}
              data={this.state.descproff}
              initData={this.state.descproff}
              config={{toolbar:[
                [ 'Bold', 'Italic',"Underline","Strike","-","RemoveFormat" ],
                // [ 'Link', 'Unlink',"Anchor" ],
                [ 'NumberedList', 'BulletedList'],
                // ["Outdent","Indent","Blockquote" ],
                // [ 'Image', 'HorizontalRule',"SpecialChar" ],
                [ 'Styles', 'Format' ],
                [ 'Maximize' ],
            ]}}
            /> */}
            <p className="text-danger">
              <small>{this.props.requestedProfileError}</small>
            </p>
          </label>
          </>
  }
        </div>
      </div>
    );
  }
}
export default withTranslation()(Form1)