import axios from "../../../interceptors/axios"

export const AddCompanyEmail = async (data,headers) => {
    try {
    let res=await  axios.post('/api/companyEmail/add',data,
     {
      headers: headers
  }
     )
return res
} catch (err) {
    }
  }