import { Collapse, DatePicker, Progress, Radio, Space, Switch, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next, { t } from 'i18next';
import { useTranslation } from "react-i18next";
const { Panel } = Collapse;

const { Column } = Table;
//TO DO :  score,redirect to jobpost
export default function UploadedList(props) {
  const [extractedEmails, setExtractedEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reqNumber, setReqNumber] = useState(0);
  const { t } = useTranslation();



  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > moment();
  };
  const onSelectChange = (newSelectedRowKeys) => {
    props.setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: props.selectedRowKeys,
    onChange: onSelectChange,
  };
  
 
  console.log(reqNumber,props.attachments);
console.log((reqNumber / props.attachments) * 100);
  return (
    <div className="m-3 shadow p-5 ">
      <h2 className="titrePage text-center">{t("Liste des candidats extraites")} </h2>
      <Progress
          strokeWidth={15}
          strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
          className="my-3"
          percent={Math.floor((props?.parsedAttachments/props?.files?.length)*100)}
          status="active"
        />
        <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
      <Table
        loading={props?.parsedAttachments<props?.files?.length}
        className="m-2"
        pagination={true}
        dataSource={props.candidates}
        rowKey={(record,index) => {
          return index;
        }}
        // rowSelection={rowSelection}
      >
        <Column
          align="center"
          // sorter={(a, b) => a.model.localeCompare(b.model)}
          title={t("Fichiers")}
          dataIndex="filename"
          key="filename"
        />
        
        <Column
                        align="center"
                        sorter={(a, b) => a.score - b.score}
                        // responsive={["lg"]}
                        title={t("Score")}
                        dataIndex="score"
                        key="score"
                        render={(text, candidate, index) => {
                          return (
                            <>
                              {
                                <Progress
                                  type="circle"
                                  percent={parseInt(candidate.score * 100)}
                                  format={(percent) => `${percent}%`}
                                  width={55}
                                  strokeColor={
                                    parseInt(candidate.score * 100) < 30
                                      ? "orange"
                                      : "green"
                                  }
                                />
                              }
                            </>
                          );
                        }}
                      />
      </Table>
      </ConfigProvider>
      {props?.parsedAttachments==props?.files?.length &&(<div className="d-flex flex-column align-items-end">
        <Link to={"/company/manageJobPost/"+props.jobPost.reference}>
      <button
          onClick={() => {
            props.setCurrent(2);
          }}
          className="btn btn-primary rounded"
        >
          {t("Suivant")}
        </button>
              </Link>
      </div>)}
    </div>
  );
}
