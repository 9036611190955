import React, { useContext, useEffect, useState } from "react";

import { Link, useHistory, withRouter } from "react-router-dom";
import decrypt from "../../utils/decrypt";
import { AppContext } from "../../routes/AppContext";
import { Modal, notification, Table } from "antd";
import { DelayInterview } from "../../services/api/interview/DelayInterview";
import ClockIcon from "../icons/ClockIcon";
import FileIcon from "../icons/FileIcon";
import Cv from "../cvPDF/Cv";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import AsyncImage from "../AsyncImage";
import crypt from "../../utils/crypt";
import { GetAcceptedCandidates } from "../../services/api/smartContract/GetAcceptedCandidates";
import UserPenIcon from "../icons/UserPenIcon";
import FileSignIcon from "../icons/FileSignIcon";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';

const { Column } = Table;

function AcceptedCandidates(props) {
  const { companyName, socket, role,envVar,headers } = useContext(AppContext);
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [candidate, setCandidate] = useState({});

  const history = useHistory();
  const handleChangePage = (e, page) => {
    setPage(page);
    // document.getElementById("scroller").scrollIntoView();
  };
  const get = async () => {
    let response = await GetAcceptedCandidates(
      { },
      headers
    );
    if(response){
    let data = JSON.parse(decrypt(response.data));
    if (!data.length) setModalVisible(true);
  return data}
  };


  const { data, isLoading, isError } = useQuery(
    ['acceptedCandidates'], // Query key
    get
  );
  let candidates=data??[]
  return (
    <div className="row" id="scroller">
      <div className="">
        
        <h3 className=" titrePage mb-5"> {t("Liste des candidats acceptés")}</h3>
        <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table
         loading={isLoading}
          className="m-2"
          pagination={true}
          dataSource={candidates}
          rowKey={(record) => {
            return record._id;
          }}
        >
          <Column
            align="left"
            sorter={(a, b) => a.idCandidate[0].name.localeCompare(b.idCandidate[0].name)}
            title={t("Nom")}
            dataIndex="name"
            key="name"
            render={(text, application, index) => {
              let candidate = application.idCandidate[0];
              return (
                <div role="button"
                onClick={() => {
                  setCandidate(candidate)
                  setModalVisible1(true)}} className=" d-flex justify-content-start align-items-center">
                  <AsyncImage
                    className="logo-m rounded me-1"
                    src={
                      envVar.REACT_APP_CLIENT_URL +
                      "/candidate/images/" +
                      candidate.image
                    }
                  />
                  {candidate.name}
                </div>
              );
            }}
          />
<Column
            align="center"
            sorter={(a, b) => a.idCandidate[0].email.localeCompare(b.idCandidate[0].email)}
            // responsive={["lg"]}
            title={t("Email")}
            dataIndex="email"
            key="email"
            render={(text, application, index) => {
              return <>{application.idCandidate[0].email}</>;
            }}
          />
          <Column
            align="center"
            sorter={(a, b) => a.idCandidate[0].phone.localeCompare(b.idCandidate[0].phone)}
            // responsive={["lg"]}
            title={t("Téléphone")}
            dataIndex="phone"
            key="phone"
            render={(text, application, index) => {
              return <>{application.idCandidate[0].phone}</>;
            }}
          />
          <Column
            align="center"
            sorter={(a, b) => a.idJobPost[0].title.localeCompare(b.idJobPost[0].title)}
            // responsive={["lg"]}
            title={t("Offre")}
            dataIndex="Offre"
            key="Offre"
            render={(text, application, index) => {
              return <>{application.idJobPost[0].title}</>;
            }}
          />

          <Column
            align="center"
            title={t("Actions")}
            dataIndex="Actions"
            key="Actions"
            render={(text, application, index) => {
              let candidate = application?.idCandidate[0];
              let idCandidate=candidate._id
              let email=candidate.email
              let name=candidate.name
              let image=candidate.image
              let idJobPost=application?.idJobPost[0]._id
              let jobPostTitle=application?.idJobPost[0].title
              return (
                <div className="">
                  {/* <i
                    role="button"
                    onClick={() => {
                      setCandidate(candidate)
                      setModalVisible1(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <FileIcon width="1.2rem" fill="#676ee7" />
                  </i> */}
                  <i
                    role="button"
                    onClick={() => {
                      history.push({
                        pathname: "/"+role+"/smartContract",
                        state: { idJobPost,idCandidate,jobPostTitle,name,email,image},
                      
                      });
                    }}
                    className=" text-success ms-2  me-1 zoom1 icons12"
                  >
                    <FileSignIcon width="2rem" fill="rgb(239 128 72 / 85%)" />
                  </i>

                
                 
                </div>
              );
            }}
          />
        </Table>
        </ConfigProvider>
      </div>
      {/* {list} */}

      {/* <div className="col-sm-12 col-md-4 rounded shadow">
        <Dates
          setEvents={setEvents}
          idJobPost={props?.location?.state?.jobPost._id}
          userId={props.userId}
          events={events}
          loading={loading}
        />
      </div>
      <ScheduleInterviewsConfirm
        jobPost={props?.location?.state?.jobPost}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setPage={props.setPage}
      />

      /> */}
      {modalVisible1&& <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{t("Aperçu du CV")}</b>
                      </h5>
                    }
                    className="mb-2 mt-2"
                    width={"95%"}
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    visible={modalVisible1}
                    onOk={() => setModalVisible1(false)}
                    onCancel={() => setModalVisible1(false)}
                    footer={<></>}
                  >
                    {modalVisible1 && (
                      <Cv decryptedData={true} userId={props.userId} candidate={candidate} />
                    )}
                  </Modal>}
    </div>
  );
}
export default withRouter(AcceptedCandidates);
