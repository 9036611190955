import React, { Component } from 'react';
import { Tag } from 'antd'
import { withTranslation } from 'react-i18next';

class SoftSkills extends Component {
  constructor(props){
    super(props)
  }
  render() {
    const { t,i18n } = this.props;
    const language=i18n.language 
    const softSkillLanguages={"fr":"soft_skill_fr","en":"soft_skill_en"}
    const softSkillLanguage=softSkillLanguages[language]
      let i=-1
    let list=this?.props?.softSkills?.map(softSkill=>{
        i++
      return( <Tag className="m-1 capitalize" color="cyan">
      {softSkill[softSkillLanguage]}
    </Tag>
      )
    })
    return (
        <div className='mt-3' >

<h6><b> {t("Compétences interpersonnelles")} </b></h6>
{list}
    </div>)
  }
}
export default withTranslation()(SoftSkills)