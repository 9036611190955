import React, { Component } from "react";
import { Modal, notification, Spin } from "antd";

import congrats from "../../media/image/congrats.svg";
import { withTranslation } from 'react-i18next';

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import SoftSkills from "./SoftSkills";
import HardSkills from "./HardSkills";
import Languages from "./Languages";
import ExperienceContainer from "./experience/ExperienceContainer";
import EducationContainer from "./education/EducationContainer";
import { withRouter } from "react-router-dom";
import { UpdateCv } from "../../services/api/cv/UpdateCv";
import DragDropComponent from "./DragDropComponent";
import { AppContext } from "../../routes/AppContext";
import AsyncImage from "../AsyncImage";

import CertificateContainer from "./certificate/CertificateContainer";
import CvSteps from "./CvSteps";
class CandidateCv extends Component {
  static contextType = AppContext
  constructor(props, context) {
    super(props);
    let user = context.user
    let cv = user
    this.state = {
      cv: cv,
      softSkillsError: "",
      hardSkillsError: "",
      languagesError: "",
      experiencesError: "",
      educationsError: "",
      currentSlide: 0,
      update: false,
      sending: false,
      load: !this.props.location?.state?.fromDB,
      redirectt:"/candidate/myCv"
      // load:true,
    };
    // alert("constructor")
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  updateView = () => {
    this.setState({
      cv: this.context.user,
      update: !this.state.update,
    });
    // this.validation()
  };
  setSoftSkills = (value) => {
    let cv = this.context.user
    cv.softSkills = value;
    this.context.setUser(cv)
    this.updateView();
    this.setState({ softSkillsError: "" });
  };
  setHardSkills = (value) => {
    let cv = this.context.user
    cv.hardSkills = value;
    this.context.setUser(cv)
    this.updateView();
    this.setState({ hardSkillsError: "" });
  };
  setLanguages = (value) => {
    let cv = this.context.user
    cv.languages = value;
    this.context.setUser(cv)
    this.updateView();
    this.setState({ languagesError: "" });
  };
  setCurrentSlide = (currentSlide) => {
    this.setState({ currentSlide });
  };
  next = () => {
    // this.slider.next();
    this.setState({ currentSlide: this.state.currentSlide + 1 });
  };
  prev = () => {
    // this.slider.prev();
    this.setState({ currentSlide: this.state.currentSlide - 1 });
  };
  onChange = (a, b, c) => {

  };
  validation = () => {
    let isvalid = true;
    let experiences = this.state.cv.experiences;
    let educations = this.state.cv.educations;
    let softSkills = this.state.cv.softSkills;
    let hardSkills = this.state.cv.hardSkills;
    let languages = this.state.cv.languages;




    //educations validation
    if (educations.length == 0) {
      isvalid = false;
      this.setState({
        educationsError: this.props.t("Veuillez ajouter au moins une formation"),
      });
      this.setState({ currentSlide: 4 });
    } else this.setState({ educationsError: "" });
    //check startDate
    for (let i = 0; i < educations.length; i++) {
      if (!educations[i].startDate) {
        isvalid = false;
        this.setState({
          educationsError: this.props.t("Veuillez valider vos formations"),
        });
        this.setState({ currentSlide: 4 });
      } else this.setState({ educationsError: "" });
    }

    //experiences validation
    // if (experiences.length == 0) {
    //   isvalid = false;
    //   this.setState({
    //     experiencesError: "veuillez ajouter au moins une experience",
    //   });
    //   this.slider.goTo(3);
    // } else this.setState({ experiencesError: "" });
    //check startDate
    for (let i = 0; i < experiences.length; i++) {
      if (!experiences[i].startDate) {
        isvalid = false;
        this.setState({
          experiencesError: this.props.t("Veuillez valider vos expériences professionnelles"),
        });
        this.setState({ currentSlide: 3 });
      } else this.setState({ experiencesError: "" });
    }

    //languages validation
    if (languages.length == 0) {
      isvalid = false;
      this.setState({
        languagesError: this.props.t("Veuillez ajouter au moins une compétence"),
      });
      this.setState({ currentSlide: 2 });

    } else if (languages.length > 5) {
      isvalid = false;
      this.setState({ languagesError: this.props.t("Vous pouvez avoir maximum 5 langues") });
      this.setState({ currentSlide: 2 });

    } else this.setState({ languagesError: "" });

    //softSkills validation
    if (softSkills.length == 0) {
      isvalid = false;
      this.setState({
        softSkillsError: this.props.t("Veuillez ajouter au moins une compétence"),
      });
      this.setState({ currentSlide: 1 });

    } else if (softSkills.length > 5) {
      isvalid = false;
      this.setState({
        softSkillsError: this.props.t("Vous pouvez avoir maximum 5 compétences"),
      });
      this.setState({ currentSlide: 1 });

    } else this.setState({ softSkillsError: "" });
    //hardSkills validation
    if (hardSkills.length == 0) {
      isvalid = false;
      this.setState({
        hardSkillsError: this.props.t("Veuillez ajouter au moins une compétence"),
      });
      this.setState({ currentSlide: 0 });

    } else if (hardSkills.length > 5) {
      isvalid = false;
      this.setState({
        hardSkillsError: this.props.t("Vous pouvez avoir maximum 5 compétences"),
      });
      this.setState({ currentSlide: 0 });
    } else this.setState({ hardSkillsError: "" });

    return isvalid;
  };
  async handleSubmit() {
    this.setState({ sending: true })

    if (this.validation()) {
      let cv = this.state.cv;
      cv.languages.map((skill) => {
        if (skill._id) delete skill._id;
        skill.idCandidate = this.props.userId
      });
      cv.softSkills.map((skill) => {
        if (skill._id) delete skill._id;
        skill.idCandidate = this.props.userId
      });
      cv.hardSkills.map((skill) => {
        if (skill._id) delete skill._id;
        skill.idCandidate = this.props.userId
      });
      let requestedSkills = [...cv.languages, ...cv.softSkills, ...cv.hardSkills]
      cv.requestedSkills = requestedSkills.filter(skill => !skill.code);
      cv.languages = cv.languages.filter(skill => skill.code);
      cv.softSkills = cv.softSkills.filter(skill => skill.code);
      cv.hardSkills = cv.hardSkills.filter(skill => skill.code);

      let response = await UpdateCv(
        {

          cv: cv,
        },
        this.context.headers
      );

      //fonction pour mettre a jour les scores des compétences 

      // for (let i = 0; i < response.data.skills.hardSkills.length; i++) {
      // cv.hardSkills[i].score=response.data.skills.hardSkills[i].score
      // }
      // for (let i = 0; i < response.data.skills.softSkills.length; i++) {
      //   cv.softSkills[i].score=response.data.skills.softSkills[i].score
      //   }
      //   for (let i = 0; i < response.data.skills.languages.length; i++) {
      //     cv.languages[i].score=response.data.skills.languages[i].score
      //     }
      //     sessionStorage.setItem("cv", JSON.stringify(cv));
      //     this.updateView();
      if (response) {
        this.setState({ load: !this.state.load })

        //notification
        notification.success({
          description: this.props.t(response.data.code),
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#d4edda",
            marginTop: "5rem"
          },
        });

        let data = this.context.user;
        data.updated = true;
        this.context.setUser(data)
        if (data.address == "") {
          this.context.setCandidateAccess()
          this.props.history.push("/candidate/profile")
        }
        else {

    
          caches.delete("redirectJobpost")
          // this.props.history.push(this.state.redirectt)
          this.context.setCandidateAccess()
          setTimeout(() => {
            Modal.confirm({
              title: null,
              icon: null,
              okText: 'Ok',
              cancelText: this.props.t('Annuler'),
              content: (
                <div>
                  <AsyncImage src={congrats} alt="" width="150" className="mg-fluid w-100 fade-in-image" />
                  <p> {this.props.t("Bien fait!")}<br />
                    {this.props.t("Vous pouvez maintenant profiter de notre moteur de recherche d'emploi intelligent et trouver votre travail idéal.")}
                    <br />
                    <b> {this.props.t("Allez-y !")}</b>
                  </p>
                </div>
              ),
              onOk: () => {
                this.state.redirectt==="/candidate/myCv"
                ? (this.props.history.push("/candidate/jobPosts"))
                :(window.location.replace((this.state.redirectt)))
              },
            });
          }, 5000)
          this.state.redirectt==="/candidate/myCv"
                ? (this.props.history.push("/candidate/myCv"))
                :(window.location.replace((this.state.redirectt)))
          // this.props.history.push(this.state.redirectt)

        }
      }
    }
    else
      this.setState({ sending: false })
  }
  render() {
    const { t,i18n } = this.props;
    let visible = (this.state.currentSlide == 0 && this.state.cv?.hardSkills.length > 5) || (this.state.currentSlide == 1 && this.state.cv?.softSkills.length > 5) || (this.state.currentSlide == 2 && this.state.cv?.languages.length > 5)
    caches
    .open("redirectJobpost")
    .then((cache) =>
      cache.matchAll().then((val)=> 
      val[0].url &&(
      this.setState({ redirectt: val[0].url }))
      )
    )
    return (
      <div className=" carousel ">
        <h5 className="m-1 text-primary">
          <b>{this.props.t("Créer votre cv")}</b>
        </h5>
        <div>
          {/* {this.state.cv.hardSkills.length>3 && <DragDropComponent feature={0} skills={this.state.cv.hardSkills} />} */}
        </div>
        {/* <div className="right">{this.state.currentSlide + 1 + "/6"}</div> */}
        <CvSteps current={this.state.currentSlide} setCurrent={this.setCurrentSlide} />
        {i18n.language==="ar" 
        ?(<div
          className="d-flex justify-content-end"
        //  style={{float: 'right'}}
        >
          {this.state.currentSlide < 5 ? (
            <div onClick={this.next} className="btn btn-primary m-1 ms-2 d-flex align-items-center">
             <RightOutlined /> {this.props.t("Suivant")} 
            </div>
          ) : (
            !this.state.sending ? <div onClick={this.handleSubmit} disabled={this.state.sending} className="btn btn-primary m-1">
              {this.props.t("Enregistrer")}
            </div> : <Spin />
          )}
          {this.state.currentSlide != 0 && (
            <div onClick={this.prev} className="btn btn-primary m-1 d-flex  align-items-center">
             {this.props.t("Précedent")} <LeftOutlined /> 
            </div>
          )}
        </div>)
        :(<div
          className="d-flex justify-content-end"
        //  style={{float: 'right'}}
        >
          {this.state.currentSlide != 0 && (
            <div onClick={this.prev} className="btn btn-primary m-1 d-flex  align-items-center">
            <LeftOutlined />  {this.props.t("Précedent")}
            </div>
          )}
          {this.state.currentSlide < 5 ? (
            <div onClick={this.next} className="btn btn-primary m-1 ms-2 d-flex align-items-center">
              {this.props.t("Suivant")} <RightOutlined />
            </div>
          ) : (
            !this.state.sending ? <div onClick={this.handleSubmit} disabled={this.state.sending} className="btn btn-primary m-1">
              {this.props.t("Enregistrer")}
            </div> : <Spin />
          )}
        </div>)
        
        }

        <div className="" >
          {(this.state.cv?.hardSkills.length > 5 && this.state.currentSlide == 0) && <DragDropComponent skills={this.state.cv?.hardSkills} feature={0} updateSkills={this.setHardSkills} />}
          {(this.state.cv?.softSkills.length > 5 && this.state.currentSlide == 1) && <DragDropComponent skills={this.state.cv?.softSkills} feature={1} updateSkills={this.setSoftSkills} />}
          {(this.state.cv?.languages.length > 5 && this.state.currentSlide == 2) && <DragDropComponent skills={this.state.cv?.languages} feature={2} updateSkills={this.setLanguages} />}
          {/* <Carousel
          className={!visible?" p-2 h-63":"h-0"}
          style={{ display:visible && "none"}}
          dots={false}
          afterChange={this.onChange}
          ref={(node) => (this.slider = node)}
        > */}
          {this.state.currentSlide == 0 && <div className="overflow-y ">
            {this.state.load ? (
              <>

                {this.state.cv?.hardSkills.length <= 5 && <>  <HardSkills
                  userId={this.props.userId}
                  hardSkills={this.state.cv?.hardSkills}
                  setHardSkills={this.setHardSkills}
                />
                  <p className="text-danger">
                    <small>{this.state.hardSkillsError}</small>
                  </p></>
                }

              </>
            ) : (
              <div>

                <HardSkills
                  userId={this.props.userId}
                  hardSkills={this.state.cv?.hardSkills}
                  setHardSkills={() => { }} />
                <p className="text-danger">
                  <small>{this.state.hardSkillsError}</small>
                </p>

              </div>
            )}
          </div>}
          {this.state.currentSlide == 1 && <div className="overflow-y ">
            {this.state.load ? (
              <>
                {this.state.cv?.softSkills.length <= 5 && <>   <SoftSkills
                  userId={this.props.userId}
                  softSkills={this.state.cv?.softSkills}
                  setSoftSkills={this.setSoftSkills}
                />
                  <p className="text-danger">
                    <small>{this.state.softSkillsError}</small>
                  </p></>
                }

              </>
            ) : (
              <div>
                <SoftSkills
                  userId={this.props.userId}
                  softSkills={this.state.cv?.softSkills}
                  setSoftSkills={() => { }} />
                <p className="text-danger">
                  <small>{this.state.softSkillsError}</small>
                </p>

              </div>
            )}
          </div>}
          {this.state.currentSlide == 2 && <div className="overflow-y ">
            {this.state.load ? (
              <>

                {this.state.cv?.languages.length <= 5 && <>   <Languages
                  languages={this.state.cv?.languages}
                  userId={this.props.userId}
                  setLanguages={this.setLanguages}
                />
                  <p className="text-danger">
                    <small>{this.state.languagesError}</small>
                  </p></>}
              </>
            ) : (
              <div>

                <Languages
                  userId={this.props.userId}
                  languages={this.state.cv?.languages}
                  setLanguages={() => { }} />
                <p className="text-danger">
                  <small>{this.state.languagesError}</small>
                </p>
              </div>
            )}
          </div>}

          {this.state.currentSlide == 3 && <div className="overflow-y ">
            <ExperienceContainer
              updateView={this.updateView}
              experiences={this.state.cv?.experiences}
            />
            <p className="text-danger">
              <small>{this.state.experiencesError}</small>
            </p>
          </div>}

          {this.state.currentSlide == 4 && <div className="overflow-y ">
            <EducationContainer
              updateView={this.updateView}
              educations={this.state.cv?.educations}
            />
            <p className="text-danger">
              <small>{this.state.educationsError}</small>
            </p>
          </div>}
          {this.state.currentSlide == 5 && <div className="overflow-y ">
            <CertificateContainer
              updateView={this.updateView}
              certificates={this.state.cv?.certificates}
            />
            <p className="text-danger">
              {/* <small>{this.state.certificatesError}</small> */}
            </p>
          </div>}
          {/* </Carousel> */}
        </div>
        
      </div>
    );
  }
}
export default withTranslation()(withRouter(CandidateCv));
