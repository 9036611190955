import axios from "../../../../interceptors/axios"
export const DeleteCertificate = async (data,headers) => {
    try {
      let res=await   axios.delete(`/api/cv/certificate/delete/${data.id}`,
      {
        headers: headers
    }, { withCredentials: true }
    )
    return res
} catch (err) {
    }
  }