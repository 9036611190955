import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
class HardSkillsTwo extends Component {
    constructor(props){
        super(props)
      }
  render() {
    const { t } = this.props;
    let i=-1
    let list=this?.props?.hardSkills?.map(hardSkill=>{
      i++
      return( 
               <div className="cvt-comp-elem" key={i}>{hardSkill.skill}</div>
        )
    })
    return (
        <div className="cvt-comp-t">
        <div className="cvt-comp-t-top">
            <div className="cvt-rect">
                <div className="cvt-rectt"></div>
            </div>
            <div className="cvt-comp-name">{t("Compétences techniques")} :</div>
        </div>
        <div className="cvt-comp-all">
            {list}
        </div>
    </div>

    )
  }
}
export default withTranslation()(HardSkillsTwo)