import axios from "../../../interceptors/axios";
export const GetTestDescriptionByIdCandidate = async (data, headers) => {
  try {
    let res = await axios.get("/companyTest/description/idCandidate/" + data.id, {
      headers: headers,
    }, { withCredentials: true });
    return res;
  } catch (err) {
  }
};
