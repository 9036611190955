import { Col, InputNumber, Modal, Row, Slider, Switch, notification } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../icons/InfoIcon";

export default function Weights(props) {
  const { t } = useTranslation();

  const notify = () => {
    if (props.defaultWeights) {
      notification.warn({
        description: t("Assurez-vous de décocher le bouton 'notre formule recommandée' pour vous assurer d'indiquer votre formule personnalisée."),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
    }
  };

  const info = () => {
    Modal.info({
      title: null,
      okText: t("ok"),
      content: (
        <div>
          <p>  <b>{t("Gagnez du temps avec la mise en correspondance des opportunités")}</b><br />
            {t("Notre solution est basée sur l'intelligence artificielle et associe les candidats à votre offre d'emploi en fonction de vos critères de sélection.")}
          </p>
        </div>
      ),

      onOk() { },
    });
  };
  const jobTitleInfo = () => {
    Modal.info({
      title: null,
      okText: t("ok"),
      content: (
        <div>
          <p>  <b>{t("Adéquation poste-candidat")}</b><br />
            {t("Vérification de la correspondance entre le poste du candidat souhaité et le titre de poste proposé par l'entreprise.")}
          </p>
        </div>
      ),

      onOk() { },
    });
  };
  return (
    <div style={{ direction: "ltr",textAlign: "left"}}>
      <div className="m-2 d-flex justify-content-center align-items-center">
        <Switch
          className="me-1"
          size="small"
          checked={props.defaultWeights}
          onChange={props.setDefaultWeights}
        />
        <span
          className={!props.defaultWeights ? "text-secondary fs-5" : "text-dark fs-5"}
        >
          {t("Notre formule recommandée")}
        </span>
        <span onClick={info} role="button" ><InfoIcon className="zoom1 m-1" fill="blue" width="1.4rem" /> </span>
      </div>

      <br />
      <div className=" row mx-2" onClick={notify} >
        <div className="d-flex justify-content-center">
        <div className="col-lg-6 col-md-12 mt-3  p-2"  style={{ boxShadow: "1px 1px 5px 3px #aaa", borderRadius: "7px", margin: "auto auto 10px" }} >

          <span className={props.defaultWeights ? "text-secondary mt-5" : "text-dark mt-5"}>
            {t("Compétences techniques")}
          </span>
          <Row>
            <Col span={12}>
              <Slider

                disabled={props.defaultWeights}
                min={1}
                max={100}
                onChange={props.setSkillsWeight}
                value={Math.round(props.skillsWeight * 100)}
                step={1}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                disabled={props.defaultWeights}
                min={1}
                max={100}
                style={{
                  margin: "0 16px",
                }}
                step={1}
                onChange={props.setSkillsWeight}
                value={Math.round(props.skillsWeight * 100)}
              />
            </Col>
          </Row>
          <span className={props.defaultWeights ? "text-secondary" : "text-dark"}>
            {t("Compétences interpersonnelles")}
          </span>
          <Row>
            <Col span={12}>
              <Slider
                disabled={props.defaultWeights}
                min={1}
                max={100}
                onChange={props.setSoftSkillsWeight}
                value={Math.round(props.softSkillsWeight * 100)}
                step={1}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                disabled={props.defaultWeights}
                min={1}
                max={100}
                style={{
                  margin: "0 16px",
                }}
                step={1}
                onChange={props.setSoftSkillsWeight}
                value={Math.round(props.softSkillsWeight * 100)}
              />
            </Col>
          </Row>
          <span className={props.defaultWeights ? "text-secondary" : "text-dark"}>
            {t("Langues")}
          </span>
          <Row>
            <Col span={12}>
              <Slider
                disabled={props.defaultWeights}
                min={1}
                max={100}
                onChange={props.setLanguagesWeight}
                value={Math.round(props.languagesWeight * 100)}
                step={1}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                disabled={props.defaultWeights}
                min={1}
                max={100}
                style={{
                  margin: "0 16px",
                }}
                step={1}
                onChange={props.setLanguagesWeight}
                value={Math.round(props.languagesWeight * 100)}
              />
            </Col>
          </Row>
          <span className={props.defaultWeights ? "text-secondary mb-5" : "text-dark mb-5"}>
            {t("Lieu de travail")}
          </span>
          <Row>
            <Col span={12}>
              <Slider
                disabled={props.defaultWeights}
                min={1}
                max={100}
                onChange={props.setLocationsWeight}
                value={Math.round(props.locationsWeight * 100)}
                step={1}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                disabled={props.defaultWeights}
                min={1}
                max={100}
                style={{
                  margin: "0 16px",
                }}
                step={1}
                onChange={props.setLocationsWeight}
                value={Math.round(props.locationsWeight * 100)}
              />
            </Col>
          </Row>
        </div>
        <div className="col-lg-5 col-md-12 mt-3  p-2" style={{ boxShadow: "1px 1px 5px 3px #aaa", borderRadius: "7px", margin: "auto auto 10px" }}>
          <label className="m-2">
            <Switch
              disabled={props.defaultWeights}
              className="me-1"
              size="small"
              checked={props.jobTitleWeight}
              onChange={props.setJobTitleWeight}
            />
            <span className={props.defaultWeights ? "text-secondary" : "text-dark"}>
              {t("Similaité du titre de l'offre")}
            </span>
            <span onClick={jobTitleInfo} role="button" ><InfoIcon className="zoom1 m-1" fill="blue" width="1rem" /> </span>
          </label>
          <br />

          <label className="m-2">
            <Switch
              disabled={props.defaultWeights}
              className="me-1"
              size="small"
              checked={props.experienceWeight}
              onChange={props.setExperienceWeight}
            />

            <span className={props.defaultWeights ? "text-secondary" : "text-dark"}>
              {t("Experience")}
            </span>
          </label>
          <br />

          <label className="m-2">
            <Switch
              disabled={props.defaultWeights}
              className="me-1"
              size="small"
              checked={props.genderWeight}
              onChange={props.setGenderWeight}
            />
            <span className={props.defaultWeights ? "text-secondary" : "text-dark"}>
              {t("Genre")}
            </span>
          </label>
          <br />

          <label className="m-2">
            <Switch
              disabled={props.defaultWeights}
              className="me-1"
              size="small"
              checked={props.handicap}
              onChange={props.setHandicap}
            />
            <span className={props.defaultWeights ? "text-secondary" : "text-dark"}>
              {t("Handicapé")}
            </span>
          </label>

        </div>
        </div>
      </div>
    </div>
  );
}
