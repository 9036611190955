import React, { useEffect, useState } from "react";
import Routes from "../src/client/routes/Routes";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enUS from "antd/lib/locale/en_US";
import arEG from "antd/lib/locale/ar_EG";
import moment from "moment-timezone";
import "moment/locale/fr";


import "../src/client/styles/styles.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { renderToString } from "react-dom/server";
import SEO from "./client/routes/SEO";
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
function FacebookPixel() {
  React.useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("1837792273395347");
        ReactPixel.pageView();

        Routes.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  });
  return null;
}

const helmetContext = {};
export default function App(props) {
const [languagelocal, setLanguagelocal] = useState("fr")
  i18next
.use(initReactI18next)
.use(LanguageDetector)
.init({
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
  lng: languagelocal, // Set the default language
  fallbackLng:"fr",
  resources: {
    en: {
      translation: require('./locales/en.json'),
    },
    fr: {
      translation: require('./locales/fr.json'),
    },
    ar: {
      translation: require('./locales/arb.json'),
    },
    // Add more languages here
  },
});

  moment.locale(i18next.language);
moment.tz.setDefault("Africa/Tunis");
  useEffect(()=>{
if(localStorage){
  console.log("languagelocal:",languagelocal)
      setLanguagelocal(localStorage?.getItem("ln"))
}
},[i18next.language])
  return (
    <HelmetProvider>
     <SEO title="Rec-INOV" description="Rec-INOV Votre assistant RH. Une solution ALL-IN-ONE fonctionnant via une intelligence artificielle pour une efficacité optimale." name="Rec-INOV" url="https://rec-inov.com/" image="https://rec-inov.com/company/images/629fc301b4512d557d6ec69b.png" />
  
 <I18nextProvider i18n={i18next}>
      <ConfigProvider locale={languagelocal === "fr" ? frFR : languagelocal === "en" ? enUS : arEG}>
        <Routes ssrLocation={props.ssrLocation} />
      </ConfigProvider>
    </I18nextProvider>
  </HelmetProvider>
  );
}
const html = renderToString(App);
const { helmet } = helmetContext;