import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Blog Box
import CounterBox from "./counter-box";

//Import Images
import { GetStatistics } from "../../../../services/api/statistics/GetStatistics";
import decrypt from "../../../../utils/decrypt";
import SectionTitle from "../common/section-title";
import { withTranslation } from "react-i18next";
class Counter extends Component {
  state = {
    // counters: [
    //   { icon: "mdi mdi-account-multiple", title: "Candidats", postfix: "", end: 0 },
    //   { icon: "mdi mdi-domain", title: "Entreprises", postfix: "", end: 0 },
    //   { icon: "mdi mdi-briefcase", title: "Offres", postfix: "", end: 0 },
    //   { icon: "mdi mdi-file-send", title: "Candidatures", postfix: "", end: 0 },
    //   // { icon: "", title: "Entretiens", postfix: "", end: data.interviews },
    //   //   { icon: "pe-7s-like2", title: "SUCCESSFUL PROJECT", postfix: "", end: 0 },
    //   //   { icon: "pe-7s-light", title: "PROJECT IDEAS", postfix: "", end: 0 },
    //   //   { icon: "pe-7s-smile", title: "SATISFIED CLIENTS", postfix: "", end: 0 },
    //   //   { icon: "pe-7s-star", title: "AWARD WIN", postfix: "", end: 0 },
    // ],
    candidates:0,
    companies:0,
    jobPosts:0,
    applications:0
  };
  getStatistics = async () => {
    let res = await GetStatistics({}, {});
    if(res){
    let data = JSON.parse(decrypt(res.data));
    this.setState({
      // counters: [
      //   { icon: "mdi mdi-account-multiple", title: this.props.t("Candidats"), postfix: "", end: data.candidates },
      //   { icon: "mdi mdi-domain", title:this.props.t("Entreprises") , postfix: "", end: data.companies },
      //   { icon: "mdi mdi-briefcase", title: this.props.t("Offres"), postfix: "", end: data.jobPosts },
      //   { icon: "mdi mdi-file-send", title: this.props.t("Candidatures"), postfix: "", end: data.applications },
      //   // { icon: "", title: "Entretiens", postfix: "", end: data.interviews },
      // ],
      candidates: data.candidates ,
      companies: data.companies ,
      jobPosts: data.jobPosts,
      applications: data.applications

    });}
  };
  componentDidMount() {
    this.getStatistics()
  }
  render() {
    const counters=[
        { icon: "mdi mdi-account-multiple", title: this.props.t("Candidats"), postfix: "", end: this.state.candidates },
        { icon: "mdi mdi-domain", title:this.props.t("Entreprises") , postfix: "", end: this.state.companies },
        { icon: "mdi mdi-briefcase", title: this.props.t("Offres"), postfix: "", end: this.state.jobPosts },
        { icon: "mdi mdi-file-send", title: this.props.t("Candidatures"), postfix: "", end: this.state.applications },
        // { icon: "", title: "Entretiens", postfix: "", end: data.interviews },
      ]
    return (
      <React.Fragment>
        <section className="section counter counter-bg " >

          <Container>
            {this.props.isCompany ? <>
              <SectionTitle
                title1={this.props.t('NOS')}
                title2={this.props.t('Statistiques')}
              // desc="Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem."
              /></> : <Row>
              <Col lg="12 mt-5">
                <div className="title-heading mb-5">
                  <h2 className="text-dark mb-1 font-weight-light text-uppercase">
                  {this.props.t('NOS STATISTIQUES')}
                  </h2>
                  <div className="title-border-simple position-relative"></div>
                </div>
              </Col>
            </Row>}

            <div id="" className="seo_fact_info pb-5">
              <CounterBox counters={counters} />
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation() (Counter);
