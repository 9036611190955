import { Modal } from "antd";
import React, { Component } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import TrashIcon from "../../icons/TrashIcon";
import EditIcon from "../../icons/EditIcon";
import WorkIcon from "../../icons/WorkIcon";
import { AppContext } from "../../../routes/AppContext";
import { withTranslation } from "react-i18next";
class ExperienceComponent extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
  }
  months = [
    this.props.t("Janvier"),
    this.props.t("Février"),
    this.props.t("Mars"),
    this.props.t("Avril"),
    this.props.t("May"),
    this.props.t("Juin"),
    this.props.t("Juillet"),
    this.props.t("Aout"),
    this.props.t("Septembre"),
    this.props.t("Octobre"),
    this.props.t("Novembre"),
    this.props.t("Décembre"),
  ];
  render() {
    let experience = this.props.experience;
    let dateText = "";
    if (experience.startDate) {
      let startDate = new Date(experience.startDate);
      let date2 = new Date(experience.endDate);
      let endDate = experience.endDate
        ? this.months[date2.getMonth()] + date2.getFullYear()
        : this.props.t("Présent");
      dateText =
        this.months[startDate.getMonth()] +
        startDate.getFullYear() +
        " - " +
        endDate;
    }

    return (
      <div
        className={
          experience.startDate
            ? "border rounded m-2 p-2 d-flex justify-content-start "
            : "border border-danger bg-red rounded m-2 p-2 d-flex justify-content-start"
        }
      >
        <div className="align-self-start">
          <WorkIcon fill="blue" width="3rem" />
        </div>
        <div className="me-auto">
          {" "}
          <h6>{experience.title}</h6>
          <i className="text-primary" >{dateText}</i>
          <h6> {experience.companyName}</h6>
          <span className="text-break ">{experience.description}</span>
        </div>
        <div className="">
          <i
            title={this.props.t("Modifier")}
            className=" text-success  m-2"
            role="button"
            onClick={() => {
              this.props.setModalVisible(true, this.props.index);
            }}
          >
            <EditIcon width="1.8rem" fill="green" />
          </i>
        </div>
        <div className="">
          <i
            title={this.props.t("Supprimer")}
            className=" text-danger m-2"
            role="button"
            onClick={() => {
              // alert("fassakh baba fassakh")
              Modal.confirm({
                title: this.props.t("Supprimer experience"),
                icon: <ExclamationCircleOutlined />,
                content:
                this.props.t("voulez vous supprimer l'experience") + experience.title,
                okText: this.props.t("Supprimer"),
                okType: "danger",
                cancelText: this.props.t("Annuler"),
           
                onOk: () => {
                  let cv = this.context.user
                  cv.experiences.splice(this.props.index, 1);
                  this.context.setUser(cv)
                  this.props.updateView();
                },
                // onCancel:()=>{alert(index)}
              });
              //
            }}
          >
            <TrashIcon fill="red" width="1.5rem" />
          </i>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ExperienceComponent);