import React, { useContext } from 'react';
import Candidate from './Candidate';
import { WarningOutlined} from '@ant-design/icons';
import TestsForm from '../../jobPostTestResults/TestsForm';
import { withRouter } from 'react-router-dom';
import { JobPostContext } from '../../dashboard/jobPostDashboard/JobPostContext';

 function Test(props) {
  const {jobPost} = useContext(JobPostContext);
  
  const whatever=(thing)=>{
    //do something
  }
    let i=-1
    let list= props.applications.map((candidat)=>{
        i++
        return(<li key={i}><Candidate userId={props.userId}  index={i} candidat={candidat}/></li>)});
  return <div className='row' >
    {props.applications.length ?<> <ol className='col-md-5 col-sm-12'>
{list}
</ol>
<div className='col-md-7 col-sm-12' > <TestsForm setIdTest={props.setIdTest} setTestsNumber={whatever} idTest={jobPost.idTest} idJobPost={jobPost._id} userId={props.userId} /> </div>
</> :
 <h4 className=' d-flex justify-content-center text-secondary' > <span className='m-3 icons-lg ' > <WarningOutlined /></span> <br/> Aucun candidat invité pour test  </h4>}
  </div>;
}
export default withRouter(Test)