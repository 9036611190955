import React, { Component } from 'react';
import { Container } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import FeatureBox from "./feature-box";

//Import Images
import feature1 from "../../assets/images/features/img-1.png";
import feature2 from "../../assets/images/features/img-2.png";
import feature3 from "../../assets/images/features/img-3.png";
import feature4 from "../../assets/images/features/img-4.png";
import { withTranslation } from "react-i18next";
class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features : [
                {id : 1, img : feature1, icon : "mdi  mdi-publish", title : this.props.t("Publication"), desc :this.props.t("Parseur Intelligent") , link : "/demo"},
                {id : 2, img : feature2, icon : "mdi  mdi-magnet-on", title : this.props.t("Matching"), desc :this.props.t("Moteur Matching intelligent") , link : "/demo"},
                {id : 3, img : feature3, icon : "mdi  mdi-account-star", title : this.props.t("Gestion des candidatures"), desc : this.props.t("Accélération du processus de recrutement"),list:[this.props.t("Gérez facilement les demandes"),this.props.t("Suivi du pipeline de recrutement"),this.props.t("Automatisation des tâches répétitives"),this.props.t("Économie de temps dans la pré-qualification") ], link : "/demo"},
                {id : 4, img : feature4, icon : "mdi  mdi-camcorder-box", title : this.props.t("Visio-conférence"), desc : this.props.t("Entretien vidéo à distance"),list:[this.props.t("Synchronisation des agendas"),this.props.t("Rappels des entretiens à venir") ,  this.props.t("Évaluations & Notes partagées")], link : "/demo"},
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
            <section className="section features-section" id="features">
                <Container>
                                <SectionTitle
                                    title1={this.props.t("Logiciel RH")}
                                    title2={this.props.t("Tout en un")}
                                    desc={this.props.t("Plus simple et facile à segmenter")} 
                                    desc2={this.props.t("Description du logiciel")} 
                                />

                            <FeatureBox features={this.state.features} />
                </Container>
            </section>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Features);