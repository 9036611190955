import axios from "../../../interceptors/axios";

export const GetApplications = async (data, headers) => {
  try {
    let response = await axios.get("/api/jobPost/applications/" + data.id, {
      headers: headers,
    }, { withCredentials: true });
    return response;
  } catch (err) {
  }
};
