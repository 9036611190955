import React, { Component } from 'react';
import { Row, Col } from "reactstrap";

class SectionTitle extends Component {
    render() {
        return (
            <React.Fragment>
                        <Row className="justify-content-center">
                            <Col lg={6} md={8}>
                                <div className="title text-center mb-1">
                                    <h3 className="font-weight-normal text-dark">{this.props.title1} <span className="text-primary">{this.props.title2}</span></h3>
                                    <h4 className="text-muted mb-1">{this.props.desc}</h4>
                                    <p className="text-muted mb-1">{this.props.desc2}</p>
                                    <p className="text-muted mb-1">{this.props.desc3}</p>
                                </div>
                            </Col>
                        </Row>
            </React.Fragment>
        );
    }
}

export default SectionTitle