 import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react'
import UserIcon from "../icons/UserIcon"
import ClockIcon from "../icons/ClockIcon"
import AsyncImage from '../AsyncImage';
import { GetBlogByTitle } from '../../services/api/blog/GetBlogByTitle';
import { useHistory, useParams } from 'react-router-dom';
import decrypt from '../../utils/decrypt';

import { AppContext } from '../../routes/AppContext';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
const { Option } = Select;

export default function BlogPage(props) {
  const {headers,serverTime} = useContext(AppContext);
  const [blog,setBlog]=useState({})
  const history = useHistory({});
  const { i18n,t } = useTranslation();
const [showLanguage,setShowLanguage]=useState(false)
const language=i18n.language 
// const [language,setLanguage]=useState(i18n.language)
const contents={"fr":"content","en":"content_en","ar":"content_en"}
const titles={"fr":"title","en":"title_en","ar":"title_en"}
const title=titles[language]
const content=contents[language]
// const changeLanguage = (lng) => {
//   // setShowLanguage(false)
//   setLanguage(lng);
  
// };
  // history.push('/'+role+'/manageTests')
  // buf.toString('base64')


  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       includedLanguages: 'ar',
  //       default:'ar',
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  // useEffect(() => {
  //   const addGoogleTranslateScript = () => {
  //     const script = document.createElement("script");
  //     script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   };

  //   addGoogleTranslateScript();
  // }, []);

  // const translateElement = () => {
  //   const contentToTranslate = document.getElementById('content-to-translate');
  //   const textToTranslate = contentToTranslate.innerText;

  //   const xhr = new XMLHttpRequest();
  //   xhr.open('GET', `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=ar&dt=t&q=${encodeURI(textToTranslate)}`, true);
  //   xhr.onreadystatechange = function() {
  //     if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
  //       const response = JSON.parse(xhr.responseText);
  //       const translatedText = response[0][0][0];
  //       contentToTranslate.innerText = translatedText;
  //     }
  //   };
  //   xhr.send();
  // };

  const getBlog=async()=>{
    let title= decodeURI(window.location.href.split("blog/")[1])
    let response = await GetBlogByTitle({title:title},headers)
    if(response){
      const blog=JSON.parse(decrypt(response.data))
      setBlog(blog)
      console.log(blog)
      if(!blog[content]){
        const blogLanguage=language=="fr"?"en":"fr"
        // setLanguage(blogLanguage)
      }
    }
     }
     useEffect(()=>{
      getBlog()
     },[])
  const   dateFromObjectId =  (objectId) =>{
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};
  return (
    <div className='mt-5  p-2 pt-5 row ' style={{ direction: "ltr",textAlign: "left"}} >
      {/* <div id="content-to-translate">
        <h4>Hello World</h4>
        <p>This is the content to be translated.</p>
      </div>
      <button onClick={translateElement}>Translate</button> */}

      <div className='col-md-1' >
      <div className="">
      {/* <Select className=""  
                  // style={{ width: '40%' }} 
                   showSearch value={language}
    placeholder={t("Rechercher pour sélectionner")} onChange={changeLanguage}>
    <Option disabled={!blog.content}  value="fr">🇫🇷 {t("Francais")}</Option>
    <Option disabled={!blog.content_en}  value="en">🇬🇧 {t("Anglais")}</Option>
  </Select> */}
      {/* <select
      role="button"
      onFocus={()=>{setShowLanguage(true)}}
      onBlur={()=>{setShowLanguage(false)}}
        style={{ border: "transparent" }}
        value={language}
        onChange={changeLanguage}
        className="bg-transparent text-white  form-select"
        aria-label="Default select example"
      >
        <option disabled={!blog.content}  className="text-dark" value="fr">
          🇫🇷 { showLanguage && t("Francais") }
        </option>
        <option disabled={!blog.content_en}  className="text-dark" value="en">
          🇬🇧 { showLanguage && t("Anglais") }
        </option>
      </select> */}
    </div>
      </div>
      <div className='col-md-10 col-sm-12' >
      <div className='text-center' >
      <h1>
        {blog[title]}
        </h1>
        {/* <div>
        <i className="mx-1">
          <UserIcon width="1rem" />
        </i>

        {props.blog.designation}
        </div> */}
        <div>
        <i className=" mx-1">
          <ClockIcon width="1rem" />
        </i>
        {blog?._id&&moment(serverTime).to(moment(dateFromObjectId(blog?._id)))}
        </div>
<AsyncImage style={{maxWidth:"37rem"}} alt={blog?.title} src={blog?.image} width="80%" className=""  />
</div>
        <div   dangerouslySetInnerHTML={{
                            __html: blog[content],
                          }}
                           className='mt-3 pe-5 lh-lg fs-5'>
        
        
        </div>
      <div className='col-md-1' ></div>
      </div>
    </div>
  )
}
