import React, { Component } from 'react';
import {Rate, Tag } from 'antd'
import { withTranslation } from 'react-i18next';

class Languages extends Component {
  constructor(props){
    super(props)
  }
  render() {
    const { t,i18n } = this.props;
    const languages = { "fr": "language_fr", "en": "language_en" }
    const lang = languages[i18n.language]
      let i=-1
    let list=this?.props?.languages?.map(language=>{
        i++
      return(  <Tag className="m-1 capitalize" color="geekblue">
      {language[lang]}
    </Tag>

      )
    }) 
    return (
        <div className='mt-3 mb-3' >

<h6><b> {t("Langues")} </b></h6>
{list}
    </div>)
  }
}
export default withTranslation()(Languages)