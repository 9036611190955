import React, { useContext } from "react";
import logo from "../../media/image/recin.webp";
import notifications from "../../content/notifications.json";
import AsyncImage from "../AsyncImage";
import moment from "moment-timezone";
import { AppContext } from "../../routes/AppContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function NotificationComponent(props) {
  const { role,companyName,envVar } = useContext(AppContext);
  const { i18n,t } = useTranslation();
  const history = useHistory({});
  let image="",company="",jobPostTitle="",
  testTitle="",
  id="",
  type="",
  seen="",
  title="",
  description="",candidateName=""

  if (role == "candidate") {
     image = props.notification.idJobPost[0].idCompany[0].image;
     company = props.notification.idJobPost[0].idCompany[0].name;
     jobPostTitle = props.notification.idJobPost[0].title;
     testTitle = props.notification.idTest.length?props.notification.idTest[0].title:"";
     id = props.notification._id;
     type = props.notification.type;
     seen = props.notification.seen;
     title = i18n.language=='fr'?notifications[type].title:notifications[type].title_en;
     description =i18n.language=='fr'?notifications[type].description:notifications[type].description_en;
    description = description.split("${jobPostTitle}").join(jobPostTitle);
    description = description.split("${companyName}").join(company);
    description = description.split("${testTitle}").join(testTitle);
  }
  else {
     image =props.notification.idCandidate.length? props.notification.idCandidate[0].image:"";
     candidateName =props.notification.idCandidate.length? props.notification.idCandidate[0].name:t("un candidat");
     jobPostTitle = props.notification.idJobPost.length?props.notification.idJobPost[0].title:"";
     testTitle = props.notification.idTest.length?props.notification.idTest[0].title:"";
     id = props.notification._id;
     type = props.notification.type;
     seen = props.notification.seen;
     title = i18n.language=='fr'?notifications[type].title:notifications[type].title_en;
     description = i18n.language=='fr'?notifications[type].description:notifications[type].description_en;
    description = description.split("${jobPostTitle}").join(jobPostTitle);
    description = description.split("${name}").join(candidateName);
    description = description.split("${companyName}").join(companyName);
    description = description.split("${testTitle}").join(testTitle);
  }
  const dateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };
  const Redirect=()=>{
    if(role=="candidate"){
      history.push({
        pathname: "/jobPost/"+props.notification.idJobPost[0].reference,
      });
    }
    else {
      if(type==12){
        history.push({
          pathname: "/"+role+"/orders",
        });
      }
      else if(type==13||type==16){
        history.push({
          pathname: "/"+role+"/dashboard",
        });
      }
      else
      history.push({
        pathname: "/"+role+"/manageJobPost/"+props.notification.idJobPost[0].reference,
      });
    }
    props.setMenu(false)
    }
  return (
    <div
    onClick={Redirect}
      role="button"
      className={
        seen
        ? "d-flex align-items-start p-2 blog"
          : "d-flex align-items-start p-2 bg-lightblue"
      }
    >
      {/* <img
      src={logo}
      className="me-3 rounded-circle avatar-xs logo"
      alt="user-pic"
    /> */}
     {image? <AsyncImage
        className="me-3 rounded-circle avatar-xs logo"
        alt={company}
        src={ envVar.REACT_APP_CLIENT_URL
        +(role=="candidate"? "/company/images/":"/candidate/images/") + image}
      />:<AsyncImage
      className="me-3 rounded-circle avatar-xs logo"
      alt={"Rec-INOV"}
      src={logo}
    />}
      <div className="flex-1">
        {/* <h6 className="mt-0 mb-1">{title}</h6> */}
        <div className="font-size-12 p-0">
          {description}
          <p className="m-0 text-muted">
            <small>
              <i className="mdi mdi-clock-outline" />
              {moment().to(moment(dateFromObjectId(id)))}
            </small>
          </p>
        </div>
      </div>
    </div>
  );
}
