import moment from "moment-timezone";
import React, { useContext } from "react";
import UserIcon from "../icons/UserIcon";
import ClockIcon from "../icons/ClockIcon";
import AsyncImage from "../AsyncImage";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../routes/AppContext";
import { useTranslation } from "react-i18next";
export default function BlogComponent(props) {
  const history = useHistory({});
  const { role } = useContext(AppContext);
  const { t,i18n } = useTranslation();
  const language=i18n.language 
  const blogLanguageT={"fr":"title","en":"title_en","ar":"title_en"}
  const blogT=blogLanguageT[language]
  const blogLanguageD={"fr":"designation","en":"content_en","ar":"content_en"}
  const blogD=blogLanguageD[language]

  const dateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };
  return (
    <div
      style={{ backgroundColor: "#fafafa" }}
      role="button"
      onClick={() => {
        history.push("/blog/" + props.blog.title);
      }}
      className="p-2 hoverShadow rounded h-100 m-2 "
    >
      {/* <AsyncImage
        alt={props.blog.title}
        src={props.blog.image}
        className="w-100 h-50"
      /> */}
      <div className="blog-img position-relative">
        {/* <img  src={blog.image} alt=""  className="img-fluid mx-auto d-block rounded clipped" /> */}
        <div
          style={{
            backgroundImage: `url(${props.blog.image})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "15rem",
          }}
        ></div>
      </div>
      <h4 className="fw-bold capitalize m-2" style={{ color: "#4a4a4a" }}>
        {props.blog[blogT]}
      </h4>
      <div className="text-muted">
        {moment(dateFromObjectId(props.blog._id)).format("LL")}
        &nbsp; |&nbsp;
        {props.blog.designation}
      </div>
    </div>
  );
}
