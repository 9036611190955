import { notification } from 'antd';
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { checkCompanyPackage } from '../../services/api/pricing/checkCompanyPackage';

import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { useTranslation } from 'react-i18next';


export default function CheckCompanyPackage(props) {
  const { t } = useTranslation();

  const { setCart,role,headers } = useContext(AppContext);
  const history = useHistory({});


  const params = useParams();
  useEffect(async()=>{
    let res = await checkCompanyPackage({token:params.token},headers)
  // notification.open({
           
  //   description:res.data.message,
  //   className: 'custom-class mt-5',
  //   style: {
  //       width: 600,
  //       border: "2px solid black",
  //       marginTop:"5rem"
  //     },
  // });
  // res?.data?.message &&    this.props.history.push('/signin/candidate')
  if(res?.status==200){
    let companyPackage = JSON.parse(decrypt(res.data));
    let cart=[
      {
        pType: "Mensuel",
        pCategory: "Payant",
        pforme: "Par defaut",
        pStatus: "Activé",
        pName: companyPackage[0].pName,
        pDescription: companyPackage[0].pDescription,
      pPrice: companyPackage[0].pPrice,
        fonctionnalites: [
          {
            listefonctionnalite: companyPackage[0].functionalities.map(f=>({...f,idFonctionnalite:f.idFunctionality}))
          }
        ],
        pourcentagepromotion: 0,
        archive: companyPackage[0].archived,
        idCompanyPackage:companyPackage[0]._id,
        duration:companyPackage[0].duration,
        modules:companyPackage[0].modules
      }
    ]
    setCart(cart)
    history.push({
      pathname: "/company/checkout/1",
    });
  }else{
    history.push({
      pathname: "/company/dashboard",
    });
  }
  },[])
  return (
    <div></div>
  )
}
