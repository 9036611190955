import { Modal } from 'antd'
import React from 'react'
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

export default function EmailViewer(props) {
  const { t,i18n } = useTranslation();
  
  return (
    <div>
         <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{props.email.model}</b>
                      </h5>
                    }
                    className="m-5 w-75"
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    visible={props.modalVisible}
                    onOk={() => props.setModalVisible(false)}
                    onCancel={() => props.setModalVisible(false)}
                    footer={<></>}
                  >
                    <div 
        // className='d-flex justify-content center h-100'
        >
            <h5>{props.email.emailObject}</h5>
            <div  dangerouslySetInnerHTML={{
        __html:props.email.emailBody ,
      }} ></div>
              </div>
                  </Modal>
    </div>
  )
}
