import React from 'react'
import { Helmet } from 'react-helmet-async';
import IndexHome from "../components/home/indexHome";
import SEO from '../routes/SEO';

export default function HomePage(props) {
    return (
        <div  className="HomePage"> 

          <IndexHome />
        </div>
    )
}
