import React, { useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
    DesktopOutlined,
    ContainerOutlined,
    UploadOutlined,
    ArrowLeftOutlined,
    UserOutlined,
    UnorderedListOutlined,
    ContactsOutlined,
    FileProtectOutlined,
    RightOutlined,
    LeftOutlined,
    SettingOutlined,
    LogoutOutlined,
    FieldTimeOutlined,
    BoldOutlined,
    HomeOutlined,
    DollarOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    AuditOutlined
  } from "@ant-design/icons";
import { useTranslation } from "react-i18next";


const SideBarMenu = () => {
    const { t } = useTranslation();
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
  const items = [
    {
      label: '',
      key: 'dash',
      icon: <Link to="/company/dashboard"> <HomeOutlined /></Link> ,
    },
  //   {
  //     label: 'Navigation Two',
  //     key: 'app',
  //     icon: <BoldOutlined />,
  //     disabled: true,
  //   },
    {
      label: '',
      key: 'cord',
      icon: <DesktopOutlined />,
      children: [
        {
          type: 'group',
          label: '',
          children: [
            {
              label: <Link to="/company/profile"> <FileProtectOutlined />&nbsp; {t("Profil")}</Link>,
              key: 'setting:1',
            },
            {
              label: <Link to="/company/contact"> <ContactsOutlined /> &nbsp;{ t("Contact")}</Link>,
              key: 'setting:2',
            },
          ],
        },
      ],
    },
    {
        label: '',
        key: 'dash2',
        icon: <Link to="/company/manageResponsible"> <UserOutlined /></Link> ,
      },
      {
        label: '',
        key: 'offer',
        icon: <UnorderedListOutlined />,
        children: [
          {
            type: 'group',
            label: '',
            children: [
              {
                label: <Link to="/company/addJobPost"> <ContainerOutlined /> &nbsp;  {t("Créer une offre via le formulaire")}</Link>,
                key: 'offer:3',
              },
              {
                label: <Link to="/company/uploadOffre"> <UploadOutlined /> &nbsp; {t("Télécharger l'offre")}</Link>,
                key: 'offer:2',
              },
              {
                label: <Link to="/company/manageJobPosts"> <UnorderedListOutlined /> &nbsp;
                {t("Liste des offres..")}</Link>,
                key: 'offer:1',
              },
             
            ],
          },
        ],
      },
      {
        label: '',
        key: 'dash3',
        icon: <Link to="/company/extract-cvFiles"> <FileProtectOutlined /></Link> ,
      },
      {
        label: '',
        key: 'test',
        icon: <ContainerOutlined />,
        children: [
          {
            type: 'group',
            label: '',
            children: [
              {
                label: <Link to="/company/manageTests"> <UnorderedListOutlined /> &nbsp;
                {t("Liste Tests")}</Link>,
                key: 'test:1',
              },
              {
                label: <Link to="/company/addTest"> <ContainerOutlined /> &nbsp; {t("Créer Test")}</Link>,
                key: 'test:2',
              },
            ],
          },
        ],
      },
      {
        label: '',
        key: 'dash4',
        icon: <Link to="/company/smartContract/acceptedCandidates"> <FileProtectOutlined /></Link> ,
      },
      {
        label: '',
        key: 'dash5',
        icon: <Link to="/company/CandidateParsing"> <AuditOutlined /></Link> ,
      },

    
  ];
  return <div className="nav-sidebar-menu">
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items}  />
         </div>
};


export default SideBarMenu