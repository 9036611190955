let envVars = {
  REACT_APP_CLIENT_URL: "https://rec-inov.com",
  REACT_APP_SAME_LEVEL_QUESTIONS: "3",
  REACT_APP_PREVIOUS_LEVEL_QUESTIONS: "2",
  REACT_APP_QUESTION_TIME_0: "60",
  REACT_APP_QUESTION_TIME_1: "120",
  REACT_APP_QUESTION_TIME_2: "240",
  REACT_APP_QUESTION_TIME_3: "360",
  REACT_APP_DEVTOOL:'dev'
};

function setEnvVars(newValue) {
  envVars = newValue;
}

function getEnvVars() {
  return envVars;
}

export { setEnvVars, getEnvVars };
