import moment from 'moment'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import decodeToken from '../../utils/decodeToken'
import decrypt from '../../utils/decrypt'
import jwt from "jsonwebtoken"
import axios from "axios";
import {
UserOutlined,
} from '@ant-design/icons';
import { notification } from 'antd'
import { SaveTestResult } from '../../services/api/skillTest/SaveTestResult'
import { AppContext } from '../../routes/AppContext'
export default function TestResult(props) {
  const {user,headers} = useContext(AppContext);

  const[totalScore,setTotalScore]=useState(0)
  // const[time,setTime]=useState(0)
  const convertTime=(val)=>{
    let m=Math.floor(val/60)
    let s=val%60
  
   
      return m+"m"+s
    
  }
  const postResult=async(score)=>{
//axios
let res=await SaveTestResult({
  
  idSkill:props.idSkill,
  level:props.level,
  score:score,
  testTimeTook:props.time,
  answers:props.answers,
  feature:props.feature ,
  idCandidateSkill:props.idCandidateSkill
},headers)
if(score>=70){
  let cv = this.context.user
  if(props.feature==0){
    for (let i = 0; i < cv.hardSkills.length; i++) {
    if(cv.hardSkills[i].idHardSkill==props.idSkill) {
      cv.hardSkills[i].score=(props.level+2)/5
    }
    }
  }
  else if(props.feature==1){
    for (let i = 0; i < cv.softSkills.length; i++) {
    if(cv.softSkills[i].idSoftSkill==props.idSkill) {
      cv.softSkills[i].score=(props.level+2)/5
    }
    }
  }
  else if(props.feature==2){
    for (let i = 0; i < cv.languages.length; i++) {
    if(cv.languages[i].idLanguage==props.idSkill) {
      cv.languages[i].score=(props.level+2)/5
    }
    }
  }
  this.context.setUser(cv)
}

  }
  // let time =props.time
  let weightsSum=useMemo(()=>{
    let testTime=0
    let weight=0
    for (let j = 0; j < props.questions.length; j++) {
      const question = props.questions[j];
      const questionWeight=question.level==props.level?1.5:1
      weight+=questionWeight
      // switch (question.level) {
      //   case 0: testTime += 60;
      //     break;
      //     case 1: testTime += 120;
      //     break;
      //     case 2: testTime += 240;
      //     break;
      //     case 3: testTime += 360;
      //     break;
      //   default:
      //     break;
      // }

    }
    // setTime(props.time)
    return weight
  },[])
  // let totalScore=0
 useEffect(()=>{
   let total=0
  for (let i = 0; i < props.questions.length; i++) {
    const question = props.questions[i];
    const questionWeight=question.level==props.level?1.5:1
    let trueAnswers=0
    let falseAnswers=0
    let questionScore=0
    let correctAnswers=0
  for (let answer of question.answers) {
    if(answer.isCorrect){
      correctAnswers++
if (props.answers[i].includes(answer._id)){
  trueAnswers+=1
}

    }
    else{
      if (props.answers[i].includes(answer._id)){
        falseAnswers+=1
      }
    }
  }
  if(trueAnswers==0||trueAnswers<=falseAnswers){
    questionScore=0
  }
  else{
questionScore=((trueAnswers-falseAnswers)/correctAnswers)*(100*questionWeight/weightsSum)
  }
  total+=questionScore
}
setTotalScore(Math.ceil(total))
postResult(Math.ceil(total))
},[])
  return (
    <div>
     <UserOutlined className='border border-primary rounded-circle p-1 m-1 text-primary h5' /> {user.name}({user.email})
      <hr/>
      <div className='d-flex justify-content-evenly'>
        <div>
  <span className='text-primary m-1' >  Test:</span> 
      {props.skill}
        </div>   
        <div>
             <span className='text-primary m-1' >Langue:</span>
      {"Francais"} 
        </div>
        <div>
          <span className='text-primary m-1' >Date:</span>
      {moment().format("LL")}
          </div>

      </div>
 <br/>
      <div>
  <div>  
    <small>  Score </small> 
    <h5 className='text-primary' >{totalScore} %</h5>
  </div>
      <br/>
      <div>
        <small>Durée</small> 
      <h5 className='text-primary' >   {convertTime(props.time)}</h5> 
      </div></div>
    </div>
  )
}
