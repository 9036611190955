import React, { useContext, useMemo, useState } from "react";
import { notification, Progress, Rate } from "antd";
import { useHistory } from "react-router-dom";
import QuestionIcon from "../icons/QuestionIcon"
import FileIcon from "../icons/FileIcon"
import ClockIcon from "../icons/ClockIcon"
import { GetTestScore } from "../../services/api/skillTest/GetTestScore";
import ComingSoon from "../ComingSoon";
import decrypt from "../../utils/decrypt";
import { AppContext } from "../../routes/AppContext";
import { useTranslation } from 'react-i18next';
export default function ChooseLevelModal(props) {
  const { t } = useTranslation();
  const { envVar } = useContext(AppContext);

  const history = useHistory({});
  const {headers} = useContext(AppContext);

  const [tests,setTests]=useState([])
  let skillToTest = props.skill;
  let codeSkill = skillToTest.code;
  let skill=""
  let idSkill=""
  if (skillToTest.feature == 0) {
     skill = skillToTest.skill;
     idSkill = skillToTest.idHardSkill;
  } else if (skillToTest.feature == 1) {
     skill = skillToTest.soft_skill_fr;
     idSkill = skillToTest.idSoftSkill;
  } else if (skillToTest.feature == 2) {
     skill = skillToTest.language_fr;
     idSkill = skillToTest.idLanguage;
  }
useMemo(async()=>{
  let res=await GetTestScore({id:idSkill},headers)
  if(res){
  let data=JSON.parse(decrypt(res.data))

  setTests(data)}
},[])
  let getRandomTest = (level,time) => {
    history.push({
      pathname: "/candidate/skillTest",
      state: { idSkill:idSkill,feature:skillToTest.feature,idCandidateSkill:skillToTest.id, skill:skill, codeSkill: codeSkill, level: level,time:time },
    });
  };
  let i = -1;
  let levels = [t("Débutant"), t("Intermédiaire"), t("Avancé"), t("Expert")];

  let levelsComponent = levels.map((level) => {
    i++;
    let time=0
    const index=i
    switch (index) {
      case 0:
        time=(parseInt(envVar.REACT_APP_SAME_LEVEL_QUESTIONS,10)+parseInt(envVar.REACT_APP_PREVIOUS_LEVEL_QUESTIONS,10))*parseInt(envVar.REACT_APP_QUESTION_TIME_0)/60
        break;
        case 1:
        time=(parseInt(envVar.REACT_APP_SAME_LEVEL_QUESTIONS,10)*parseInt(envVar.REACT_APP_QUESTION_TIME_1)+parseInt(envVar.REACT_APP_PREVIOUS_LEVEL_QUESTIONS,10)*parseInt(envVar.REACT_APP_QUESTION_TIME_0))/60
        break;
        case 2:
          time=(parseInt(envVar.REACT_APP_SAME_LEVEL_QUESTIONS,10)*parseInt(envVar.REACT_APP_QUESTION_TIME_2)+parseInt(envVar.REACT_APP_PREVIOUS_LEVEL_QUESTIONS,10)*parseInt(envVar.REACT_APP_QUESTION_TIME_1))/60
        break;
        case 3:
          time=(parseInt(envVar.REACT_APP_SAME_LEVEL_QUESTIONS,10)*parseInt(envVar.REACT_APP_QUESTION_TIME_3)+parseInt(envVar.REACT_APP_PREVIOUS_LEVEL_QUESTIONS,10)*parseInt(envVar.REACT_APP_QUESTION_TIME_2))/60
        break;
        
    
      default:
        break;
    }
  
    return (
      <div
        key={index}
        onClick={() => getRandomTest(index,time)}
        role="button"
        className="shadow-sm rounded zoom-0 d-flex d-flex bd-highlight m-2 p-4 "
      >
        <span className="flex-fill my-4">
          <span className="rounded-circle bg-primary p-2 m-2 ">
            <i className=" text-light m-1  ">
            <FileIcon width="1.5rem" fill="white" />
            </i>
          </span>{" "}
          <span className="icons12" >
            {skill}
            </span>
        </span>

        
        <span className="flex-fill text-center">
          <div className="d-flex justify-content-center" >
            <span className="border border-3 border-primary p-0 mx-1" >
          <div className="border bg-primary p-2  ">
          <i className=" text-info">
          <QuestionIcon width="1.5rem" fill="white" />

          </i>
          <div className="text-white" >{t('Questions')}</div>
          </div>{" "}
         <div className="" > {parseInt(envVar.REACT_APP_SAME_LEVEL_QUESTIONS,10)+parseInt(envVar.REACT_APP_PREVIOUS_LEVEL_QUESTIONS,10)}
         </div>
         </span>
         <span className="border border-3 border-primary p-0 mx-1" >
          <div className="border bg-primary p-2 ">
          <i className=" text-info">
          <ClockIcon width="1.5rem" fill="white" />

          </i>
          <div className="text-white" >{t('Minutes')} </div>
          </div>{" "}
         <div className="" > {time}
         </div>
         </span>
         </div>
        </span>

        <span className="flex-fill">
            <p className="text-center" >{level}</p>
        
        <Rate className=" d-flex justify-content-center" disabled value={i + 2} />
        </span>
        <span className="flex-fill">
          <span className="d-flex justify-content-center" >
        <Progress type="circle" 
              percent={tests[i]?tests[i].score:0}
               width={55} format={percent => `${percent}%`}  strokeColor={(tests[i]?tests[i].score:0) < 70 ? "orange" : "green"} />
         </span>      
            <p className="text-center" >{t('Score')}</p>
        </span>
      </div>
    );
  });

  return <div>
    {/* {levelsComponent} */}
    <ComingSoon/>
    </div>;
}
