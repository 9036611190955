import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../routes/AppContext";
// import { cartListProduct } from "./FetchApi";
// import { cartList } from "../productDetails/Mixins";
// import { subTotal, totalCost } from "./Mixins";
// import { panier } from "../order/Action";



const CartModal = (props) => {
  const { t,i18n } = useTranslation();

  const history = useHistory();

  const language=i18n.language 
    const modulesLanguage={"fr":"name","en":"name_en"}
    const moduleLanguage=modulesLanguage[language]
  
    const functionalitiesLanguage={"fr":"fName","en":"fName_en"}
    const functionalityLanguage=functionalitiesLanguage[language]

    const packagesLanguage={"fr":"pName","en":"pName_en"}
    const packageLanguage=packagesLanguage[language]

  const [state, setState] = useState({
    error: false,
    success: true,
    clientToken: null,
  });
  // const { data, dispatch } = useContext(LayoutContext);
  const { cart, setCart,fonctionnalites } = useContext(AppContext);

  const products = cart

  // Cart modal
  const cartModalOpen = () =>
    // dispatch({ type: "cartModalToggle", payload: !data.cartModal });
    props.setCartModalOpen(false)

  // useEffect(() => {
  //   isRecruteur ?
  //   fetchData()
  //   :
  //   fetchDataFormation()
  // }, []);

  let oldItems = [];
  let newItem = {};
  let List = [];
let total=0


  // cette fonction ajouter les forfaits de recrutement du panier au modal cartProduct et calcul total & dispatch layout context
  //   pour pouvoir afficher au panier
  // const fetchData = async () => {
  //   try {
  //     let responseData = await cartListProduct();

  //     if (responseData && responseData.Products) {
  //       dispatch({ type: "cartProduct", payload: responseData.Products });
  //       dispatch({ type: "cartTotalCost", payload: totalCost() });
  //     }
  //   } catch (error) {
  //   }
  // };

  // delete forfait du panier
  const removeCartProduct = (id) => {
    let cartList=[...cart]
    cartList=cartList.filter(p=>p._id!=id)
    setCart(cartList)
  };
const clearCart=()=>{
  setCart([])
}
const pay=()=>{
  cartModalOpen()
  history.push({
    pathname: "/company/checkout/1",
    // state: { idTest:props.idTest},
  });
}
  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        className={`${
          !props.open ? "hidden" : ""
        } fixed top-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* Cart Modal Start */}
      <section
        className={`${
          !props.open ? "hidden" : ""
        } fixed z-40 inset-0 flex items-start justify-end`}
      >
        <div
          style={{ background: "#303031" }}
          className="w-full md:w-5/12 lg:w-4/12 h-full flex flex-col justify-between"
        >
          <div className="overflow-y-auto">
            <div className="border-b border-gray-700 flex justify-between">
              <div className="p-4 text-white text-lg font-semibold">{t("Panier")}</div>
              {/* Cart Modal Close Button */}
              <div className="p-4 text-white">
                <svg
                  onClick={(e) => cartModalOpen()}
                  className="w-6 h-6 cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="m-4 flex-col">
              {/* fixer la periode globale de tous les forfaits en cas de nbre de forfaits >1 */}
              {
                products?.length>0 &&
                products.map((item, index) => {
                  localStorage.setItem("periode", "Mensuel");
                  if (item.pType == "Annuel") {
                    localStorage.setItem("periode", item.pType);
                  }
                  let price=0
                  price=item.pPrice 
                  total+=price
                  return (
                    <Fragment key={index}>
                      {/* Cart Product Start */}
                      <div className="text-white flex space-x-2 my-4 items-center">
                        <div className="relative w-full flex flex-col">
                          <div className="my-2">
                            {item[packageLanguage]} ({item.pforme}-{item.pType})
                          </div>

                          <div className="flex items-center justify-between">
                            <div className="flex items-center justify-between space-x-2">
                              <div className="text-sm text-gray-400">
                                {t("Quantité")} :
                              </div>
                              <div className="flex items-end">
                                <span className="text-sm text-gray-200">1</span>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <span className="text-sm text-gray-400">
                                {t("prix")} :
                               
                                  <span>
                                    {" "}
                                    {price} {t("TND")}
                                  </span>
                                
                                
                              </span>
                            </div>
                            {/* SUbtotal Count */}
                          </div>

                          {fonctionnalites && fonctionnalites.length > 0
                            ? fonctionnalites.map((item2, index2) => {
                                for (
                                  let i = 0;
                                  i <
                                  item.fonctionnalites[0].listefonctionnalite
                                    .length;
                                  i++
                                ) {
                                  if (
                                    item.fonctionnalites[0].listefonctionnalite[
                                      i
                                    ] !== undefined
                                  ) {
                                    if (
                                      item2._id ==
                                      item.fonctionnalites[0]
                                        .listefonctionnalite[i].idFonctionnalite
                                    ) {
                                      if (List[i] == null)
                                        List[i] =
                                          item.fonctionnalites[0].listefonctionnalite[
                                            i
                                          ].qte;
                                      else {
                                        List[i] =
                                          parseInt(List[i]) +
                                          parseInt(
                                            item.fonctionnalites[0]
                                              .listefonctionnalite[i].qte
                                          );
                                      }
                                      return (
                                       
                                       
                                        (
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center justify-between space-x-2">
                                              <div className="text-sm text-gray-400">
                                                {item2[functionalityLanguage]}:
                                              </div>
                                              <div className="flex items-end">
                                                <span className="text-sm text-gray-200">
                                                  {
                                                    item.fonctionnalites[0]
                                                      .listefonctionnalite[i]
                                                      .qte
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      );
                                    }
                                  }
                                }
                              })
                            : null}

                          {/* Cart Product Remove Button */}
                          <div
                            onClick={(e) => 
                               removeCartProduct(item._id)
                            }
                            className="absolute top-0 right-0 text-white"
                          >
                            <svg
                              className="w-5 h-5 cursor-pointer"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {/* Cart Product Start */}
                    </Fragment>
                  );
                })}

              {!products.length && (
                <div className="m-4 flex-col text-white text-xl text-center">
                  {t("Pas de Forfait sélectionné")}
                </div>
              )}
            </div>
          </div>
          {/* cart buttons */}
          <div className="m-4 space-y-4">
            {/* Annuler button */}
            <div
              onClick={(e) => {
                clearCart()
                cartModalOpen()}}
              className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
              style={{ background: "#303031" }}
              // className="cursor-pointer px-4 py-2 border border-gray-400 text-white text-center cursor-pointer"
            >
              {t("Annuler")}
            </div>
            {/* Payer button */}
            {/* verif si somme total calculée et si utilisateur authentifié */}
           
             {!cart.length?
             <div className="px-4 py-2 bg-black text-white text-center  cursor-not-allowed">
             {t("Payer")}
           </div>
             : <div onClick={pay} className="px-4 py-2 bg-black text-white text-center cursor-pointer">
                {t("Payer")} {total} {t("TND")}
              </div>}
          </div>
            
        </div>
      </section>
      {/* Cart Modal End */}
    </Fragment>
  );
};

export default CartModal;
