import axios from "../../../interceptors/axios";
export const Gestyessin = async (data, headers) => {
  try {
    console.log("headers",headers)
       let response = await axios.get("/api/testYessin/getsugg/" + data.id, {
      headers: headers,
    }, { withCredentials: true });
    return response;
  } catch (err) {
  }
};
