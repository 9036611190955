import React, { Component } from 'react'
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import { withTranslation } from 'react-i18next';
class ContactTwoEColor extends Component {
  render() {
    const { t } = this.props;
    let email=this.props.candidate.email
      let phone=this.props.candidate?.phone?.toString()??""
      let hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (i > 2 && i< email.indexOf("@") ) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  let hiddenPhone = "";

  for (let i = 0; i < phone.length; i++) {
    if (i > 4) {
      hiddenPhone += "*";
    } else {
      hiddenPhone += phone[i];
    }
  }
    return (
        <>
        <hr className='cvt-w'/>                       
        <div className="cvt-contact">
            <div className="cvt-adress">
                <div className="cvt-a-top">
                    <RoomIcon className='cvt-a-icon' style={{width:"20px",height:"20px",color: this?.props?.tt3}} />
                    <div className="cvt-w">
                        <div className="cvt-a-name" style={{color: this?.props?.tt3}}>{  t('Adresse')} :</div>
                        <div className="cvt-a-value">{this.props.candidate.address}</div>
                    </div>
                </div>
                
                <div className="cvt-a-top">
                    <EmailIcon className='cvt-a-icon' style={{width:"20px",height:"20px",color: this?.props?.tt3}}/>
                    <div className="cvt-w">
                        <div className="cvt-a-name" style={{color: this?.props?.tt3}}>{  t('E-mail')} :</div>
                        <div className="cvt-a-value">{this.props.decryptedData?email:hiddenEmail}</div>
                    </div>
                </div>

                <div className="cvt-a-top">
                    <PhoneIcon className='cvt-a-icon' style={{width:"20px",height:"20px",color: this?.props?.tt3}}/>
                    <div className="cvt-w">
                        <div className="cvt-a-name" style={{color: this?.props?.tt3}}>{  t('Téléphone')} :</div>
                        <div className="cvt-a-value">+{this.props.decryptedData?phone:hiddenPhone}</div>
                    </div>   
                </div>


            </div>

        </div>
        </>
    )
  }
}
export default withTranslation()(ContactTwoEColor)