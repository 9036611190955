import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

import { withTranslation } from "react-i18next";
class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : [
                { icon : "pe-7s-rocket service-icon", title : this.props.t("Un outil unique et complet pour tous vos besoins"), desc : this.props.t("Améliorez la qualité et la rapidité de vos recrutements..." )},
                { icon : "pe-7s-mail-open-file service-icon", title : this.props.t("Allégez votre boîte de réception trop saturée",), desc : this.props.t("Oubliez le casse-tête des courriels et des fichiers Excel. Optez à utiliser nos outils automatisés de sélection intelligent."
                )  },
                { icon : "pe-7s-clock service-icon", title : this.props.t("Economisez du temps avec un outil simple et performant"), desc : this.props.t("Augmentez la vitesse de vos recrutements...") },
                // { icon : "pe-7s-cup service-icon", title : "Goal Business", desc : "Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis." },
                // { icon : "pe-7s-light service-icon", title : "Branding Identity", desc : "Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis." },
                // { icon : "pe-7s-graph1 service-icon", title : "Dynamic Growth", desc : "Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis." },
            ]
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <section className="section" id="services">
                    <Container>
                        <SectionTitle
                            title1={this.props.t("NOS")}
                            title2={this.props.t("SERVICE")}
                            desc={this.props.t("Facilitez votre procédure de recrutement.")}
                            desc2={this.props.t("Créez des offres d'emploi de qualité en quelques clics.")}
                            desc3={this.props.t("Recevez des conseils de spécialistes...")}
                        />

                        <Row>
                            <ServiceBox services={this.state.services} />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}   

export default withTranslation()(Service);