import React, { useContext, useRef, useState }  from 'react'
import './TemplateTwo.css'
import { AppContext } from "../../../routes/AppContext";
import { Switch } from 'antd';
import EditTemplate from './editTemplate/EditTemplate';
import TemplateTwoBody from './TemplateTwoBody';
import TemplateOneBody from '../template/TemplateOneBody';
import TemplateTwoBodyEColor from './TemplateTwoBodyEColor';
import { useTranslation } from "react-i18next";
const TemplateTwo = ({decryptedData,cv,candidate,hardSkillModalVisible,setHardSkillModalVisible,
                      to1,setTo1,to2,setTo2, tt1,setTt1,tt2,setTt2,tt3,setTt3,tt4,setTt4}) => {
    const [showCVFile, setShowCVFile] = useState(false);
    const [template, setTemplate] = useState("one")  
    // const reportTemplateRef = useRef(null);
    const { t } = useTranslation();
    const { envVar} = useContext(AppContext);
    
    
    
  return (
    <div className="wrapper">
    {candidate.cvFile && (
      <div className="right m-1">
        <Switch
          checkedChildren={"cv original"}
          unCheckedChildren={"cv original"}
          className={showCVFile ? "me-1 btn-success" : "me-1 btn-danger"}
          // size="small"
          checked={showCVFile}
          onChange={() => {
            setShowCVFile(!showCVFile);
          }}
        />
      </div>
    )}
    {showCVFile ? (
      <div style={{ height: "650px" }}>
        <iframe
          src={
            envVar.REACT_APP_CLIENT_URL +
            "/candidate/cv_file/" +
            encodeURIComponent(candidate.cvFile)
          }
          height="100%"
          width="100%"
        ></iframe>
      </div>
    ) : ( 
       <div className="cvt-body">

       
        {template==="one" &&(<TemplateOneBody candidate={candidate} decryptedData={decryptedData} cv={cv}  to1={to1} to2={to2}/>)}
        {template==="two" &&(<TemplateTwoBodyEColor candidate={candidate} decryptedData={decryptedData} cv={cv} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4} />)}

        <div className="cvt-edit">
          <EditTemplate template={template} setTemplate={setTemplate} 
                        to1={to1} setTo1={setTo1} to2={to2} setTo2={setTo2}
                        tt1={tt1} setTt1={setTt1} tt2={tt2} setTt2={setTt2} tt3={tt3} setTt3={setTt3} tt4={tt4} setTt4={setTt4}
                         />
        </div>
        </div> 
        )}
    </div>
  )
}

export default TemplateTwo