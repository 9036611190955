import React from 'react'
import CompanySignup from '../../components/signup/CompanySignup'

export default function CompanySignupView() {
    return (
        <main  className="CompanySigninPage container-fluid">
            <CompanySignup/>
        </main>
    )
}
