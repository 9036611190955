 import moment from 'moment-timezone';
import React from 'react';
import crypt from '../../utils/crypt'
import { Link, useHistory } from "react-router-dom";
import isUrl from 'is-url';
import { useTranslation } from "react-i18next";
export default function Interview(props) {
  const history = useHistory({});
  const { t } = useTranslation();
 const goToMeet=(e)=>{
    e.preventDefault()
    e.stopPropagation()
    if(props.interview.link)
    window.open(props.interview.link,"_blank");
    else
    history.push({
            pathname: "/candidate/room/"+crypt(props.interview),
          });
    
  }
  return <div>
    {t("Entretien")}  {props.interview?.address&&"Présentiel"} :
    {" "+moment(props.interview.date).format("LLLL")} 
    
    {props.interview?.address&&<><br/>  {t("Adresse")}  : {isUrl(props.interview?.address)?<button type='' onClick={()=>{
      window.open(props.interview?.address,"_blank");
    }} className='btn btn-primary btn-sm mx-3' > {t("Consultez la localisation de l'adresse ")} ==  &gt;</button>:props.interview?.address}</>}
 {!props.interview?.address&&<Link to={"/candidate/room/"+crypt(props.interview)}  >   <button type='' onClick={goToMeet} className='btn btn-primary btn-sm mx-3' > {t("Accédez au lien de la réunion")}  ==&gt;</button></Link>}
  </div>;
}
