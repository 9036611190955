import React, { memo } from "react";

const MatchingIcon = memo((props) => {
  return (
    <>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	{...props}  viewBox="0 0 964.8 964.8"
	><title>Matching</title>
<g>
	<g>
		<path d="M166.48,328.9c4.4-5.6,8.9-11.1,13.6-16.4c6-6.9,12.3-13.7,18.9-20.2c23.7-23.7,49.9-44,78.4-60.9v-6.6
			c0-15.4-12.5-27.8-27.8-27.8h-30.9c-5.5,0-10.6,2.6-13.7,7.1l-45.6,64.5l-11.1-23.4l17.7-37.2c2.4-5.1-1.3-11-6.9-11h-35.4
			c-5.6,0-9.4,5.9-6.9,11l17.7,37.2l-10.9,23l-44.5-63.9c-3.1-4.5-8.3-7.3-13.8-7.3h-32.5c-15.4,0-27.8,12.5-27.8,27.8v184.9h112.7
			C130.78,380.8,147.081,353.8,166.48,328.9z"/>
		<path d="M51.48,89.6c0,49.4,40.2,89.6,89.6,89.6s89.6-40.2,89.6-89.6c0-49.4-40.2-89.6-89.6-89.6S51.48,40.2,51.48,89.6z"/>
		<path d="M896.28,197c-5.5,0-10.6,2.6-13.7,7.1l-45.6,64.5l-11.1-23.4L843.58,208c2.4-5.1-1.3-11-6.899-11h-35.4
			c-5.6,0-9.399,5.9-6.899,11l17.699,37.2l-10.899,23l-44.4-63.9c-3.1-4.5-8.3-7.3-13.8-7.3h-32.5c-15.4,0-27.8,12.5-27.8,27.8v7.1
			c28.1,16.7,54,36.9,77.399,60.3c6.801,6.8,13.301,13.8,19.5,20.9c4.4,5.1,8.801,10.4,12.9,15.7c19.4,24.9,35.8,51.9,48.8,80.8
			h113.7V224.8c0-15.4-12.5-27.8-27.8-27.8H896.28L896.28,197z"/>
		<path d="M729.181,89.6c0,49.4,40.2,89.6,89.6,89.6c49.4,0,89.601-40.2,89.601-89.6c0-49.4-40.2-89.6-89.601-89.6
			C769.381,0,729.181,40.2,729.181,89.6z"/>
		<path d="M940.28,875.2L817.381,781c-16.101,26.2-35.301,50.399-57.301,72.399c-1.8,1.801-3.699,3.601-5.6,5.4l124.9,95.7
			c9.1,7,19.8,10.3,30.399,10.3c15,0,29.9-6.7,39.7-19.6C966.28,923.399,962.181,892,940.28,875.2z"/>
		<path d="M616.08,571c0-15.4-12.5-27.8-27.8-27.8h-30.899c-5.5,0-10.601,2.6-13.7,7.1l-45.601,64.5l-11.1-23.4l17.7-37.199
			c2.399-5.101-1.3-11-6.9-11h-35.399c-5.601,0-9.4,5.899-6.9,11l17.7,37.199l-10.9,23l-44.3-63.899c-3.1-4.5-8.3-7.3-13.8-7.3
			h-32.5c-15.4,0-27.8,12.5-27.8,27.8v184.899h272.3V571H616.08z"/>
		<path d="M479.98,525.5c49.4,0,89.6-40.2,89.6-89.601c0-49.399-40.199-89.6-89.6-89.6s-89.6,40.2-89.6,89.6
			C390.381,485.3,430.58,525.5,479.98,525.5z"/>
		<path d="M334.78,915.399c45.9,19.4,94.601,29.2,144.7,29.2c50.2,0,98.9-9.8,144.7-29.2c40.899-17.3,77.899-41.5,110.2-71.899
			c2.699-2.5,5.399-5.101,8-7.7c21.199-21.2,39.6-44.7,55-70c9.3-15.5,17.6-31.5,24.6-48.2c19.4-45.899,29.2-94.6,29.2-144.7
			c0-50.1-9.8-98.899-29.2-144.699c-2.6-6.2-5.4-12.4-8.4-18.4c-18-36.9-41.899-70.4-71.3-99.8c-18.3-18.3-38.3-34.5-59.7-48.5
			c-18.5-12.1-38-22.5-58.5-31.2c-45.899-19.4-94.6-29.2-144.699-29.2c-50.2,0-98.9,9.8-144.7,29.2c-20.101,8.5-39.3,18.7-57.5,30.5
			c-21.8,14.1-42.1,30.5-60.7,49.1c-29.3,29.3-53.2,62.8-71.3,99.8c-2.9,6-5.8,12.199-8.4,18.399c-19.4,45.9-29.2,94.601-29.2,144.7
			s9.8,98.9,29.2,144.7c18.7,44.3,45.5,84,79.7,118.2C250.681,869.8,290.48,896.6,334.78,915.399z M271.181,409.7c2-2.5,4-5,6.1-7.4
			c4.8-5.7,9.8-11.2,15.2-16.5c50-50,116.4-77.5,187.1-77.5c70.7,0,137.101,27.5,187,77.5c5.7,5.7,11.101,11.6,16.2,17.7
			c1.7,2.1,3.4,4.1,5.101,6.3c36.5,46.4,56.199,103.2,56.199,163.1c0,70.7-27.5,137.101-77.5,187.101s-116.399,77.5-187,77.5
			c-70.6,0-137.1-27.5-187-77.5c-50-50-77.5-116.4-77.5-187.101C214.98,512.899,234.78,456.1,271.181,409.7z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

    </>
  );
});

export default MatchingIcon;
