import { notification } from 'antd'

import axios from "../../../interceptors/axios"
export const SendTest = async (data,headers)=> {
    try {
   let res = await   axios.post('/companyTest/send',data,
      {
      headers: headers
    }, { withCredentials: true } )
      return res
  }
 catch (err) {
    }
  }