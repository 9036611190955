import React, { useContext, useRef, useState }  from 'react'
import { useReactToPrint } from "react-to-print";
import logo from "../../../media/image/logo-rec.png"
import Contact from './Contact';
import HardSkills from './HardSkills';
import SoftSkills from './SoftSkills';
import Language from './Language';
import Certificates from './Certificates';
import Educations from './Educations';
import Experiences from './Experiences';
import Projects from './Projects';
import HardSkillsEColor from './HardSkillsEColor';
import SoftSkillsEColor from './SoftSkillsEColor';
import LanguageEColor from './LanguageEColor';
import EducationsEColor from './EducationsEColor';
import CertificatesEColor from './CertificatesEColor';
import ExperiencesEColor from './ExperiencesEColor';
import ProjectsEColor from './ProjectsEColor';
import { AppContext } from '../../../routes/AppContext';
import { useTranslation } from "react-i18next";
const TemplateOneBody = ({candidate,decryptedData,cv,to1,to2}) => {
  const { envVar,role } = useContext(AppContext);
  const { t } = useTranslation();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => {
        return componentRef.current;
      }
    });
  
  return (
    <div className="cv-contain" ref={componentRef} >
      <div className="cv-top">
        <div className="cv-t-l">
          <img src={
                envVar.REACT_APP_CLIENT_URL +
                "/candidate/images/" +
                candidate.image
              } alt='image' className='cv-img'/>
        </div>
        <div className="cv-t-r" style={{border: `1px solid ${to2}`}}>
          <div className="cv-r-top">
            <div className="cv-r-name" style={{color:to1}}>{candidate.name + " " + candidate.lastName}</div>
            {/* <div className="cv-r-social">
              <FaFacebookSquare className="social-icons" />
              <FaLinkedin   className="social-icons"/>
            </div> */}
            
          </div>
          <div className="cv-r-bot">
            <div className="cv-r-exp">
             <div className="cv-job">{candidate.desiredJobPost}</div> 
             <div className="cv-exp">Total experience: {cv.experience ? (Math.floor(cv.experience / 12)) : "-"} Ans</div>
            </div>
            {cv.email && (
            <Contact decryptedData={decryptedData} candidate={candidate} role={role} />
            )}
          </div>
        </div>
        <div className="cv-t-r-logo">
          {/* <div className="cv-logo"> */}
            <img src={logo} alt='logo' className='cv-log' />
          {/* </div> */}
          <div className="cv-down" onClick={handlePrint}>
          {t("Télécharger CV")}
          </div>
        </div>

        
      </div>



      <div className="cv-body">
        <div className="cv-b-l">

          {/* compétence  */}
           
          {cv.hardSkills && (
                <HardSkillsEColor hardSkills={cv.hardSkills} to1={to1} to2={to2} />
              )}
            {cv.softSkills && (
                <SoftSkillsEColor softSkills={cv.softSkills} to1={to1} to2={to2} />
              )} 
              {cv.languages && (
                <LanguageEColor languages={cv.languages} to1={to1} to2={to2}/>
              )}
            



          {/* Formations académiques */}

          {cv.educations && (
                <EducationsEColor educations={cv.educations} to1={to1} to2={to2} />
              )}

            {/* Certificats */}
            {cv.certificates && (
                <CertificatesEColor certificates={cv.certificates} to1={to1} to2={to2}/>
              )}
        </div>


        <div className="cv-b-m" style={{backgroundColor: to2}}></div>


        <div className="cv-b-r">

            {/* Expériences professionnelles */}
            {cv.experiences && (
                <ExperiencesEColor experiences={cv.experiences} to1={to1} to2={to2} />
              )}
          

          {/* Projets académiques */}
          {cv.projects && <ProjectsEColor projects={cv.projects} to1={to1} to2={to2}/>}
          


        </div>
      </div>
    </div>
  )
}

export default TemplateOneBody