import { Select } from 'antd';
import React, { useState } from 'react'
import { SendContract } from '../../services/api/smartContract/SendContract';
import ContractFromDB from './ContractFromDB';
import UploadContract from './UploadContract';
const { Option } = Select;

export default function ContractSend(props) {
    const [method,setMethod]=useState(0)

  
  return (
    <div>
            {/* <label>source du contrat</label> */}
          <br />
          {/* <Select
            placeholder="Selectionner.."
            className=" w-100"
            onChange={(value) => {
              setMethod(value);
            }}
            value={method}
          >
            <Option value={0}>upload</Option>
            <Option value={1}>notre base de données</Option>
          </Select>
          <br /> */}

          {method==0&&<UploadContract deadline={props.deadline}
setDeadline={props.setDeadline}
responsibleName={props.responsibleName}
setResponsibleName={props.setResponsibleName} setState={props.setState} />}
          {method==1&&<ContractFromDB/>}
          <br/>
          {/* <div className='d-flex justify-content-center' >
          <button onClick={Send} className='btn btn-primary rounded' >Envoyer le contrat</button></div> */}
    </div>
  )
}
