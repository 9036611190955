import axios from "../../../interceptors/axios"

export const UpdateCompanyEmailLanguage = async (data,headers) => {
    try {
    let res=await  axios.put('/api/companyEmail/updatelanguageemail',data,
     {
      headers: headers
  }
     )
return res
} catch (err) {
    }
  }