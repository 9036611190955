import axios from "axios";
import { GetAccessToken } from "../services/api/auth/GetAccessToken";
import { GetRefreshToken } from "../services/api/auth/GetRefreshToken";

// axios.defaults.baseURL = 'http://localhost:8000/';
// let refresh = false;
//     axios.interceptors.response.use(async resp => {
//       return resp}, async error => {
//         if (error.response.status === 401&& !refresh) {
//           console.log(error.response.headers);
//           if(error.response.headers['x-token']){
//             let response = await GetAccessToken({},{});
//             error.config.headers['x-token'] = response.data;
//             axios.defaults.headers.common['x-token'] = response.data;
//             if(response.status==401){
//               let response2 = await GetRefreshToken({});
//             error.config.headers['x-refresh-token'] = response2.data;
//             axios.defaults.headers.common['x-refresh-token'] = response2.data;
//             }
//           }
//           else{
//             let response2 = await GetRefreshToken({});
//           error.config.headers['x-refresh-token'] = response2.data;
//           axios.defaults.headers.common['x-refresh-token'] = response2.data;
//           let response = await GetAccessToken({},{});
//           error.config.headers['x-token'] = response.data;
//           axios.defaults.headers.common['x-token'] = response.data;
//           }
//           axios.defaults.headers.common['x-request-key'] = "eyey";
//           refresh = true;
//           return axios(error.config);
//         }
//         refresh = false;
//         return error;
//     });
export default axios;