import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
class SoftSkillsEColor extends Component {
    constructor(props){
        super(props)
      }
  render() {
    const { t } = this.props;
    let i=-1
    let list=this?.props?.softSkills?.map(softSkill=>{
        i++
      return( <div className="tech-int" key={i}>          
                {softSkill.soft_skill_fr}
                </div>
      // <div key={i} className='row'>
      //   <div className=' col-6' >{softSkill.soft_skill_fr}</div>
      //   <div className='col-6' ><Rate className='m-0 p-0' style={{fontSize:15}}  allowHalf disabled value={softSkill.score*5} />
      //   </div><br/>
      //   </div>
      )
    })
    return (
        <div className="cv-body-competence" style={{border: `1px solid ${this?.props?.to2}`}}>
        <div className="cv-r-top">
          <div className="cv-b-comp" style={{color: this?.props?.to1}}>{t("Compétences interpersonnelles")}</div>                
        </div>
        <div className="tech-comp">
            {list}
        </div>
      </div>
    )
  }
}
export default withTranslation()(SoftSkillsEColor)