import React, { useContext } from "react";
import { AppContext } from "../../../routes/AppContext";
import AsyncImage from "../../AsyncImage";
import { UserOutlined } from "@ant-design/icons";
import PenIcon from "../../icons/PenIcon";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProfileComponent() {
  const { user,envVar } = useContext(AppContext);
  const history = useHistory({});
  const { t } = useTranslation();
  return (
    <div>
      <div className="row p-3">
        {user.image ? (
          <AsyncImage
            className="w-100 rounded-circle"
            alt="recinov"
            src={ 
              envVar.REACT_APP_CLIENT_URL
              + "/candidate/images/" + user.image}
          />
        ) : (
          <UserOutlined />
        )}
      </div>
      <h5 className="text-center">{user.name?user.name + " " + user.lastName:""}</h5>
      <h5 className="text-center">
        <b>{user.desiredJobPost}</b>
      </h5>
      <div>
        <div className="d-flex justify-content-between align-items-center my-3 ">
          <h6>
            <b>
            {t("Contact")}
            </b>
          </h6>
          <span
            className="btn btn-light rounded-circle pb-2"
            role="button"
            onClick={() => {
              history.push("/candidate/profile")
            }}
          >
            <PenIcon className="" width="1rem" fill="black" />
          </span>
        </div>
        <div className="align-items-center" >
        <i className="mdi mdi-email icons12 me-2 "></i>
           {user.email}
            </div>
        <div>
        <i className="mdi mdi-phone icons12 me-2"></i>
           +{user.phone} </div>
        <div>
        <i className="mdi mdi-home icons12 me-2"></i>
           {user.address} </div>
      </div>
    </div>
  );
}
