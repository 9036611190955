import React, { useContext, useEffect, useState } from 'react'
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { AppContext } from '../../routes/AppContext';
import { Col, Progress, Row } from 'antd';
import AsyncImage from '../AsyncImage';
import formatTime from '../../utils/formatTime';
import DoughnutChart from '../DoughnutChart';
import { useTranslation } from 'react-i18next';

export default function QuestionComponent(props) {
  const { t } = useTranslation();

  // console.log(props);
  const { testLanguage,envVar, hardSkillsList } = useContext(AppContext);
  const [skill, setSkill] = useState("")
  let questionLanguage = testLanguage == "fr" ? "question_fr" : "question_en"
  let answerLanguage = testLanguage == "fr" ? "answer_fr" : "answer_en"
  const levels = [t("Débutant"),
    t("Intermédiaire"),
    t("Avancé"),
    t("Expert")]
  useEffect(() => {
    hljs.highlightAll();
  });
  useEffect(() => {
    if (props?.question?.skill) {
      let s = hardSkillsList.find(s => s.code == props?.question?.skill)
      if (s) setSkill(s.skill)
    }
  }, []);

  let i = -1
  let list = props?.question?.answers?.map((answer) => {
    i++
    return (<li className="" key={i}  >
      <div className='row my-1' >
        <div className={answer.isCorrect ? 'border-light-success rounded ms-2 p-1 bg-light-success  col-10' : 'border rounded ms-2 p-1  col-10'} >
          {answer.type != 1 ? (
            <div dangerouslySetInnerHTML={{
              __html: answer[answerLanguage],
            }} ></div>

          ) : (
            <AsyncImage
              className="w-100"
              src={
                envVar.REACT_APP_CLIENT_URL
                + "/Test/images/" +
                answer[answerLanguage]
              }
            />
          )}</div>
        <div className='col-1' >
          <i className='mdi mdi-human-male icons12 ' ></i>
          {answer.numCandidatesChoosingThisAnswer}</div>
      </div>
      {/* <AnswerComponent  index={i}  answer={answer} /> */}
    </li>)
  })
  return (
    <div className='Content-Question p-1' >
      <h5 className="h5">{t("Question")} {!isNaN(props?.startIndex) && (props?.startIndex + props?.index + 1)}</h5>
      <div className="secondary-Setup-Question">
        <Row span={24}>

          <Col >
            <p>
              <strong>{t("Difficulté")}: </strong>
              {levels[props?.question.level]}
            </p>
          </Col >
          <Col>
            <p>
              <strong>{t("Compétence")}:</strong> {skill}
            </p>
          </Col>
          <Col>
            <p>
              <strong>{t("Durée")}:</strong> {formatTime(props?.question.time)}
            </p>
          </Col>
          <Col >
            <p>
              <strong>{t("Points")}:</strong> {props?.question.points}
            </p>
          </Col>
        </Row>
      </div>
      <div className='d-flex justify-content-center' >
        <hr style={{ boxShadow: "10px grey" }} />
      </div>
      <p className='Questions-Para' >
        {props.question.type != 3 ? (
          <div dangerouslySetInnerHTML={{
            __html: props?.question[questionLanguage],
          }} ></div>
        ) : (
          <AsyncImage
            className="w-100"
            src={envVar.REACT_APP_CLIENT_URL + "/Test/images/" + props?.question[questionLanguage]}
          />
        )}
      </p>


      <ul>{list}</ul>
      <div className='d-flex justify-content-center' >
        <hr style={{ boxShadow: "10px grey" }} />
      </div>
      <div className="Content-Question  p-4 mb-4">
          <div className="row g-3 justify-content-center" >
            <h4> {t("Statistiques de Durée")} :</h4>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Durée moyenne")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {formatTime(props.question.timeAvg)}
                </h6>

              </div>
            </div>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Durée maximale")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {formatTime(props.question.timeMax)}
                </h6>

              </div>
            </div>

            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Durée minimale")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {formatTime(props.question.timeMin)}
                </h6>
              </div>
            </div>
          </div>
        </div>


      {/* <div className="p-5 d-flex justify-content-center" >

        <div className='m-1  d-flex justify-content-end ' >
          <div role="button" className="rounded bg-primary text-white mb-2 mx-0 p-2">
            <p> Durée moyenne </p>
            {props.question.timeAvg} secondes
          </div>
        </div>

        <div className='m-1  d-flex justify-content-end ' >
          <div role="button" className="rounded bg-primary text-white mb-2 mx-0 p-2">
            <p> Durée maximale </p>
            {props.question.timeMax} secondes
          </div>
        </div>

        <div className='m-1  d-flex justify-content-end ' >
          <div role="button" className="rounded bg-primary text-white mb-2 mx-0 p-2">
            <p> Durée minimale </p>
            {props.question.timeMin} secondes
          </div>
        </div>
      </div> */}
      <div className="Content-Question  p-4 mb-4">
          <div className="row g-3 justify-content-center" >
            <h4> {t("Statistiques de Scores")} :</h4>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Moyenne des scores")}</h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {props.question.pointsAvg??0} {t("points")} 
                </h6>
              </div>
            </div>
            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Score maximale")}</h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {props.question.pointsMax??0} {t("points")} 
                </h6>

              </div>
            </div>

            <div className='col-md-6 col-xl-4' >
              <div role="button" className="rounded  text-white mb-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ backgroundColor: "#558ae8" }}>
                <h5 style={{ color: "ghostwhite", textShadow: "1px 3px 5px #00000026" }} > {t("Score minimale")} </h5>
                <h6 className="card-value fs-32 mb-2 ls--1">
                  {props.question.pointsMin??0} {t("points")} 
                </h6>
              </div>
            </div>           

          </div>

        </div>
      {/* <div className="p-5  d-flex justify-content-center" >

        <div className='m-1  d-flex justify-content-end ' >
          <div role="button" className="rounded bg-primary text-white mb-2 mx-0 p-2">
            <p> Moyenne des scores </p>
            {props.question.pointsAvg} points
          </div>
        </div>
        <div className='m-1  d-flex justify-content-end ' >
          <div role="button" className="rounded bg-primary text-white mb-2 mx-0 p-2">
            <p> Score maximale </p>
            {props.question.pointsMax} points
          </div>
        </div>
        <div className='m-1  d-flex justify-content-end ' >
          <div role="button" className="rounded bg-primary text-white mb-2 mx-0 p-2">
            <p> Score minimale </p>
            {props.question.pointsMin} points
          </div>
        </div>

      </div> */}

<div className="Content-Question  p-4 mb-4  ">
        <h4> {t("Statistiques de Réponses")} : </h4>
        <div className=" d-flex justify-content-center p-1 "   >
          <div className=" col-5">
          <DoughnutChart
            labels={[t("Réponses Correctes"), t("Réponses Incorrectes"),t("Non-Consultation de la Question")]}
            colors={[
              "#11d165",
              "#d1112f",
              "#d1c211",
            ]}
            data={[props.question.numCorrectAnswers, props.question.numViews - props.question.numCorrectAnswers,props.candidates-props.question.numViews]}
            title={""}
          />
          </div>
        </div>
          <b>{t("Pourcentage de candidats ayant répondu correctement à cette question")} : </b>
            <Progress
              type="circle"
              percent={((props.question.numCorrectAnswers / props.question.numViews) * 100).toFixed(1)}
              format={(percent) => `${percent??0}%`}
              width={45}
              strokeColor={
                ((props.question.numCorrectAnswers / props.question.numViews) * 100) < 30 ? "orange" : "green"
              }
            />
      </div>
 
    </div>
  )
}
