import React, { Component } from 'react'
import { AppContext } from '../../../routes/AppContext';
import { Timeline} from 'antd';
import CvCercle from './cv-cercle/CvCercle';


import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { withTranslation } from 'react-i18next';
class CertificatesEColor extends Component {
    static contextType = AppContext

    render() {
      const { t } = this.props;
      let i=-1
      let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]
  
      let list=this?.props?.certificates?.map(certificate=>{
          i++
          let emissionDate=new Date(certificate.emissionDate)
          let date2 =new Date(certificate.expirationDate)
          let expirationDate=certificate.expirationDate? months[date2.getMonth()]+date2.getFullYear(): t("Présent")
         let dateText=months[emissionDate.getMonth()]+emissionDate.getFullYear()
        
        return(
            <Timeline.Item dot={<CvCercle/>}  key={i}>
            <div className="time">
              <div className="time-title">
              {certificate.certificateName}
              </div>
              <div className="time-body">

                <div className="time-date" style={{border: `solid 1px ${this?.props?.to1}`,color: this?.props?.to1}}>
                {dateText}
                </div>
                <div className="time-cont">
                {certificate?.reference}
                </div>
              </div>
            </div>
          </Timeline.Item>
        )
      }) 
    return (
      <>
      {list.length>0&&(<div className="cv-cert">
            <div className="cert-t">

                <div className="cert-top">
                    <div className="cert-icon">
                        <div className="cert-icons" style={{border: `solid 2px ${this?.props?.to1}`,color: this?.props?.to1}}>
                            {/* <CardMembershipIcon className='cert-icon-cont'  /> */}
                            <CardMembershipIcon  className='cert-icon-cont'  />
                        </div>
                    </div>
                    <div className="cert-name" style={{background: this?.props?.to1}}>
                    {t("Certificats")}
                    </div>
                </div>
            </div>

        <Timeline style={{marginLeft:"25px"}}>
            {list}
        </Timeline>
      </div>)}
      </>
    )
  }
}
export default withTranslation()(CertificatesEColor)