import { Modal, Timeline } from "antd";
import React, { useContext } from "react";
import { DeleteExperience } from "../../../services/api/cv/experience/DeleteExperience";
import PenIcon from "../../icons/PenIcon";
import XMarkIcon from "../../icons/XMarkIcon";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../routes/AppContext";
import CvCercle from "../../cvPDF/template/cv-cercle/CvCercle";
import { useTranslation } from "react-i18next";

const ExperienceComponents = (props) => {
    const {user,setUser,headers} = useContext(AppContext);
    const { t } = useTranslation();
  let { experience, getDate } = props;
  const updateExperience = async () => {
    props.setIndex(props.index);
    props.setModalVisible(true)
  };
  const deleteExperience = async () => {
    Modal.confirm({
      title: t("Supprimer experience"),
      icon: <ExclamationCircleOutlined />,
      content:
      t("voulez vous supprimer l experience")  + experience.title,
      okText: "Supprimer",
      okType: "danger",
      cancelText: "Annuler",
      onOk: async() => {
        //send request
        let res=await  DeleteExperience({id:experience.idExperienceDetail[0]._id},headers)
        //update context
        let cv = user
        cv.experiences.splice(props.index, 1);
        setUser(cv)
        props.updateView()
      },
      // onCancel:()=>{alert(index)}
    });
  };
  let description =
  experience.description
    .slice(0, 200) + " ...";

  return (
    <Timeline.Item dot={<CvCercle/>}  >
      <div className="time">
        <div className="time-title">
        {experience.companyName}
        <PenIcon
          onClick={updateExperience}
          className="me-2   zoom1"
          width="1rem"
          fill="blue" style={{marginLeft: "10px"}}
        />
        <XMarkIcon onClick={deleteExperience} className="me-2 zoom1" width="1rem" fill="red" />
        </div>
        <div className="time-body">
          <div className="time-b-top">
            <div className="time-date">
            {getDate(experience.startDate, experience.endDate)}
            </div>
            <div className="time-job">
            {experience.title}            
            </div>
          </div>

          <div className="time-cont">
          {description}
          </div>
        </div>
      </div>
    </Timeline.Item>
  )
}

export default ExperienceComponents