import React, { Component } from 'react'
import { Timeline} from 'antd';

import SchoolIcon from '@material-ui/icons/School';
import CvCercle from '../template/cv-cercle/CvCercle';
import { withTranslation } from 'react-i18next';
class EducationsTwoEColor extends Component {
    render() {
        const { t } = this.props;
        let i=-1
        let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]
    
        let list=this?.props?.educations?.map(education=>{
            i++
            let startDate=new Date(education.startDate)
            let date2 =new Date(education.endDate)
            let endDate=education.endDate? months[date2.getMonth()]+date2.getFullYear():t("Présent")
           let dateText=months[startDate.getMonth()]+startDate.getFullYear()+" - "+endDate
          return(
                    <Timeline.Item dot={<CvCercle/>}  key={i}  >
                        <div className="cvt-f-t">
                            <div className="cvt-f-t-top">
                                <div className="cvt-f-t-top-l">
                                    <div className="cvt-f-t-name">
                                    {education.school}
                                    </div>                                            
                                </div>
                            </div>
                            <div className="cvt-f-t-top-time">
                                <div className="cvt-f-t-top-r" style={{color: this?.props?.tt1}}>
                                {dateText}
                                </div>
                            </div>

                            <div className="cvt-f-t-b">
                                {education.diploma}
                            </div>
                        </div>
                    </Timeline.Item>
          )
        }) 
    return (
        <>
        {list.length>0&&(<div className="cvt-formation theme-two">
        <div className="cvt-f-top">
            <div className="cvt-f-img" style={{background: this?.props?.tt1}}>
                <SchoolIcon className='cert-icon-cont'  />
            </div>
            <div className="cvt-f-name" style={{color: this?.props?.tt1}}>{t("Formations académiques")} :</div>
        </div>
        <Timeline style={{marginLeft:"25px"}}>
            {list}            
        </Timeline>
    </div>
    )}
    </>
    )
  }
}
export default withTranslation()(EducationsTwoEColor)