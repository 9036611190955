import axios from "../../../interceptors/axios"

export const GetFeaturesQuestions =async (data,headers) => {
    try {
      let res=await  axios.get('/companyTest/featuresQuestions/get',
      {
       headers: headers
     })
     return res
  }
 catch (err) {
    }
  }