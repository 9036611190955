import React, { useContext, useEffect, useState } from "react";

import { Link, useHistory, withRouter } from "react-router-dom";
import decrypt from "../../utils/decrypt";
import { AppContext } from "../../routes/AppContext";
import { Modal, notification, Progress, Table } from "antd";
import { DelayInterview } from "../../services/api/interview/DelayInterview";
import ClockIcon from "../icons/ClockIcon";
import FileIcon from "../icons/FileIcon";
import Cv from "../cvPDF/Cv";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import AsyncImage from "../AsyncImage";
import crypt from "../../utils/crypt";
import { GetAcceptedCandidates } from "../../services/api/smartContract/GetAcceptedCandidates";
import UserPenIcon from "../icons/UserPenIcon";
import FileSignIcon from "../icons/FileSignIcon";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
import { GetAplicationsParsing } from "../../services/api/application/GetAplicationsParsing";
import { async } from "regenerator-runtime";
import UploadedCv from "../cvPDF/UploadedCv";

const { Column } = Table;

function CvThequeParsing(props) {
  const { companyName, socket, role,envVar,headers } = useContext(AppContext);
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [candidate, setCandidate] = useState({});
  const [tab, setTab] = useState([]);
  const [candd, setCandd] = useState({});
  const [applic, setApplic] = useState({});
  const [jobpost, setJobpost] = useState("")
const [tabfiltered, setTabfiltered] = useState([]);
const [loading, setLoading] = useState(true)
useEffect(async()=>{

    let response = await GetAplicationsParsing(
        { id: props.userId},
        headers
      );
      if(response){
      let data = JSON.parse(decrypt(response.data));
      setTab(data)
   
    const tabFilter = data.flatMap(item => 
        item.application
          .filter(app => app.cvFile) // Filtrer les candidatures qui ont un cvFile non vide
          .map(app => ({
            _id: item._id,
            title: item.title,
            application: app
          }))
      );  
    setTabfiltered(tabFilter)
    console.log(tabFilter);
        }
        setLoading(false)
    },[])

  const history = useHistory();
  const handleChangePage = (e, page) => {
    setPage(page);
    // document.getElementById("scroller").scrollIntoView();
  };
  const get = async () => {
    let response = await GetAcceptedCandidates(
      { },
      headers
    );
    if(response){
    let data = JSON.parse(decrypt(response.data));
    if (!data.length) setModalVisible(true);
  return data}
  };



  return (
    <div className="row" id="scroller">
      <div className="">
        
        <h3 className=" titrePage mb-5"> {t("Liste des CV téléchargés")}</h3>
        <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table
         loading={loading}
          className="m-2"
          pagination={true}
          dataSource={tabfiltered}
          rowKey={(record,index) => {
            return index;
          }}
        >
          <Column
            title={t("Nom et Prénom")}
            dataIndex="application"
            key="nom"
            sorter={(a, b) => {
                const nameA = (a.application.candidate[0].encName || '') + (a.application.candidate[0].encLastName || '');
                const nameB = (b.application.candidate[0].encName || '') + (b.application.candidate[0].encLastName || '');
                return nameA.localeCompare(nameB);
            }}
            render={(text, record, index) => {
                const encName = record.application.candidate[0].encName || '';
                const encLastName = record.application.candidate[0].encLastName || '';
                const cand=record.application.candidate[0]
                const appl=record.application
                return (
                  <div role="button" onClick={() => {
                    setCandd(cand)
                    setApplic(appl)
                    setJobpost(record._id)
                    setModalVisible1(true)}} className=" d-flex justify-content-start align-items-center">
                    <AsyncImage
                      className="logo-m rounded me-1"
                      src={ 
                        envVar.REACT_APP_CLIENT_URL
                        + "/candidate/images/" + cand.image}
                    />
                    {/* Insérez ici votre composant AsyncImage si nécessaire */}
                    {encName || encLastName ? `${encName} ${encLastName}` : ''}
                </div>
                );
            }}
            />
            <Column
                title={t("Date de téléchargement")}
                dataIndex={["application", "createdAt"]}
                key="createdAt"
                sorter={(a, b) => new Date(a.application.createdAt) - new Date(b.application.createdAt)}
                render={(text, record, index) => {
                    // Convertir la date en objet Date
                    const createdAt = new Date(record.application.createdAt);

                    // Formater la date selon vos besoins
                    const formattedDate = `${createdAt.toLocaleTimeString()} ${createdAt.toLocaleDateString()}`;

                    return (
                    <div>{formattedDate}</div>
                    );
                }}
                />
                <Column
                        align="center"
                        sorter={(a, b) => a.application.score - b.application.score}
                        // responsive={["lg"]}
                        title={t("Score de correspondance")}
                        dataIndex={["application", "score"]}
                        key="score"
                        render={(text, record, index) => {
                          return (
                            <>
                              {
                                <Progress
                                  type="circle"
                                  percent={parseInt(record.application.score * 100)}
                                  format={(percent) => `${percent}%`}
                                  width={55}
                                  strokeColor={
                                    parseInt(record.application.score * 100) < 30
                                      ? "orange"
                                      : "green"
                                  }
                                />
                              }
                            </>
                          );
                        }}
                      />
            <Column
            title={t("Titre de l'offre d'emploi")}
            dataIndex="title"
            key="title"
            sorter={(a, b) => a.title.localeCompare(b.title)}
            />

        </Table>
        </ConfigProvider>
      </div>

      {modalVisible1&&    <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{t("Aperçu du CV")}</b>
                      </h5>
                    }
                    className="mb-2 mt-2"
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    width={"95%"} 
                    visible={modalVisible1}
                    onOk={() => setModalVisible1(false)}
                    onCancel={() => setModalVisible1(false)}
                    footer={<></>}
                  >
                   
      {modalVisible1 &&  (applic.cvFile?<UploadedCv decryptedData={true} userId={props.userId} candidate={candd} idJobPost={jobpost} cvtheque={true} />:<Cv  decryptedData={true} userId={props.userId} candidate={candidate} />)}

                  </Modal>}
    </div>
  );
}
export default withRouter(CvThequeParsing);