import React, { Component } from 'react'
import { Timeline } from 'antd';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CvCercle from '../template/cv-cercle/CvCercle';
import { withTranslation } from 'react-i18next';
class ExperiencesTwo extends Component {
    render() {
        const { t } = this.props;
        let i=-1
        let list=this?.props?.experiences?.map(experience=>{
            i++
        let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]
    
            let startDate=new Date(experience.startDate)
            let date2 =new Date(experience.endDate)
            let endDate=experience.endDate? months[date2.getMonth()]+date2.getFullYear():t("Présent")
           let dateText=months[startDate.getMonth()]+startDate.getFullYear()+" - "+endDate
          return(<Timeline.Item dot={<CvCercle/>}  key={i} >
                    <div className="cvt-f-t">
                            <div className="cvt-f-t-top">
                                <div className="cvt-f-t-top-l">
                                    <div className="cvt-f-t-name">
                                        {experience.companyName}
                                    </div>
                                    <div className="cvt-f-t-job">
                                    {experience.title}  
                                    </div>
                                </div>

                            </div>
                            <div className="cvt-f-t-top-time">
                                <div className="cvt-f-t-top-r">
                                {dateText}
                                </div>
                            </div>

                            <div className="cvt-f-t-b">
                                {experience.description}
                            </div>
                    </div>
                </Timeline.Item>
          )
        }) 
    return (
        <>
        {list.length>0&&(
            <div className="cvt-formation theme-two">
                <div className="cvt-f-top">
                    <div className="cvt-f-img-exp">
                        <LocationCityIcon   className='cert-icon-cont'  />
                    </div>
                    <div className="cvt-f-name-exp">{t("Expériences professionnelles")} :</div>
                </div>
                <Timeline style={{marginLeft:"25px"}}>
                    {list}
                </Timeline>
            </div>)}
        </>
    )
  }
}
export default withTranslation()(ExperiencesTwo)