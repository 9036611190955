import React, { useEffect, useState } from 'react'
import { ExclamationCircleOutlined,CheckOutlined, CloseOutlined,CloseCircleOutlined} from '@ant-design/icons';
import TrashIcon from '../../../../icons/TrashIcon';
import { Modal, Radio, Select, Space, Switch } from 'antd';
import ImageUploader from '../../ImageUploader';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export default function AnswerForm(props) {
  const { t } = useTranslation();

  const [update,setUpdate]=useState(false)
  const [answerError,setAnswerError]=useState("")


useEffect(()=>{
  if(props.answer.type==0){
  const textarea = document.getElementById("txt"+props.questionIndex+"a"+props.index);
  textarea.addEventListener("input", function (e) {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
  });}
},[])
const Update=(answer)=>{
  props.updateAnswer(props.index,answer)
  setUpdate(!update)
}
const setAnswer=(value)=>{
  if(value==""){
  setAnswerError(t("champ obligatoire"))
  }
  else
  setAnswerError("")
  let answer=props.answer
  if(props.language=="fr"){
    answer.answer_fr = value;
  }else if(props.language=="en"){
    answer.answer_en = value;
  }
  Update(answer)
  
}
const setIsCorrect=(value)=>{
  let answer=props.answer
  answer.isCorrect=value
  Update(answer)
}
const setType=(value)=>{
  let answer=props.answer
  answer.type=value
  if(props.language=="fr"){
    answer.answer_fr = "";
  }else if(props.language=="en"){
    answer.answer_en = "";
  }
  Update(answer)
}
const handleChange = (checked) => {
  if (checked) {
    setIsCorrect(true);
  } else {
    setIsCorrect(false);
  }
};
let isCorrect=props.answer.isCorrect
let answer=(props.language=="fr"?props.answer.answer_fr:props.language=="en"?props.answer.answer_en:"")
let type=props.answer.type
  return (
    <div className='AddAnswer p-2 m-2' >
         {props.numAnswers>2 &&<i  title="Supprimer"  className=" text-danger m-2 right" role="button"

onClick={() => {
  const i=props.index+1
  Modal.confirm({
    title: t("Supprimer réponse"),
    icon: <ExclamationCircleOutlined />,
    content:t("voulez vous supprimer la réponse")+ " " +i,
    okText: t("Supprimer"),
    okType:"danger",
    cancelText: 'Annuler',
    onOk:()=>{
      props.deleteAnswer(props.index)
    },
  });
}}
>
<CloseCircleOutlined
          style={{
            height: 40,
            color: "#EE1C1C",
            opacity: 0.8,
            marginLeft: "auto",
            fontSize: "25px",
          }}
        />

</i>}
       <b><small>  {t("Réponse")} {props.index+1} :</small></b>
      <br/>
      <div className="my-1">
          <label>{t("Type de réponse")} </label>
          <br />
          <Select
            placeholder={t("Selectionner..")}
            className=" w-100"
            onChange={(value) => setType(value)}
            value={type}
          >
            <Option value={0}>{t("Text")}</Option>
            <Option value={1}>{t("Image")}</Option>
            <Option value={2} disabled>
              {t("Programmation")}
            </Option>
          </Select>
          <br />
          {/* <p className="text-danger"><small>{titleError}</small></p> */}



        </div>{" "}
        <div className='d-flex align-items-center justify-content-start'  >

         
       {/* <span role="button" className={isCorrect?'icons15 text-success m-2 p-2 border-bottom':'icons15 text-secondary m-2 p-2 border-bottom border-white'} onClick={()=>{setIsCorrect(true)}} >
       <CheckOutlined/>
       </span>
       <span role="button" className={isCorrect?'icons15 text-secondary m-2 p-2 border-bottom border-white':'icons15 text-danger m-2 p-2 border-bottom'} onClick={()=>{setIsCorrect(false)}} >
       <CloseOutlined />
       </span> */}

      <label className="me-1 w-100">  
                    {/* <span className=""> Question * </span> */}
    {type==0?   <textarea 
       name="description"
        className={ isCorrect? "border form-control border-success" : "border form-control border-danger" }
       onChange={(e)=>{setAnswer(e.target.value)}}
      value={answer} 
      id={"txt"+props.questionIndex+"a"+props.index}
       rows="1"
       >
        
       </textarea>: <label className=" ">
                      <ImageUploader
                        id={props.id}
                        image={answer}
                        setImage={setAnswer}
                      />
                    </label>}
<p className="text-danger"><small>{answerError}</small></p>
       </label>
       <Stack direction="row" alignItems="center">
    <Typography marginTop={-2.6} marginRight={1} marginLeft={1} >
      <CloseOutlined />
    </Typography>
    <Switch
        checked={isCorrect}
        style={{
          
          backgroundColor:isCorrect ? "#0B984F" : "#C31818",
          opacity: 0.7,
          fontSize: 15,
          marginBottom: "14px"
        }}
        onChange={handleChange}
        
      />
    <Typography marginTop={-2.6} marginLeft={1} marginRight={2}>
      <CheckOutlined />
    </Typography>
  </Stack>
       </div>
       {/* <i  title="vrai"  className=" text-success m-2 icons12 " role="button" onClick={() => {}}></i>
       <i  title="faux"  className=" text-danger m-2  icons12 " role="button" onClick={() => {}}>x</i> */}


      <br/>
    </div>
  )
}
