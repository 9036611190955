import React, { Component } from "react";
import { Container, Row, Col, Label, Button, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import {  notification } from 'antd';
//Import Section Title
import SectionTitle from "../common/section-title";
import { contactUs } from '../../../../services/api/getInTouch/getInTouch'
import { AppContext } from "../../../../routes/AppContext";
import { withTranslation } from "react-i18next";
class GetInTouch extends Component {
  static contextType = AppContext;

  constructor() {
    super()
    this.state = {
      email: "",
      message:'',
      subject:"",
      name:""
    }
    this.handleSubmit = this.handleSubmit.bind(this); 
    this.handleChange = this.handleChange.bind(this); 
  }

  handleChange(event) {
    event.preventDefault();
      let value =event.target.name
      switch(value) {
        case 'name':
            return( this.setState({name: event.target.value}))
        case 'email':
            return( this.setState({email: event.target.value}))
        case 'subject':
            return( this.setState({subject: event.target.value}))
        case 'comments':
            return( this.setState({message: event.target.value}))   
       
      }
    ;
}
handleSubmit(event) {
    event.preventDefault();
   let verif;
   let email =  this.state.email
   if( email !== "undefined"){
    let lastAtPos = email.lastIndexOf('@');
    let lastDotPos = email.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            verif = false;
        }
        else{
            verif=true
        }
    }

    if(verif=== false || this.state.name === ""  || this.state.subject === ""||  this.state.email === ""|| this.state.message === "" ){
     verif= false
    }
    else{
        verif=true
    }
    if(verif===true){
        contactUs({
            name:this.state.name,
            email:this.state.email,
            subject:this.state.subject,
            message:this.state.message

          },this.context.headers)
.then(() => {       notification.success({
  message: 'Notification',
  description:'Merci Pour Votre Message',
  className: 'custom-class',
  style: {
    width: 600,
    backgroundColor:"#d4edda",
    marginTop:"5rem"
  },
});
 })
.catch(() =>   notification.error({
  message: 'Notification',
  description:"There was an error, please try again",
  className: 'custom-class',
  style: {
    width: 600,
    backgroundColor:"#d4edda",
    marginTop:"5rem"
  },
})
) 
    }
 

}

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <SectionTitle
              title1= {this.props.t("contactez")}
              title2=  {this.props.t("-NOUS")}
              // desc="Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem."
            />

            <Row>
              <Col lg={4}>
                <div className="contact-details mt-2">
                  <div className="float-left contact-icon mr-3 mt-2">
                    <i className="mdi mdi-map-marker text-muted h5"></i>
                  </div>
                  <div className="app-contact-desc text-muted pt-1">
                    <h6 className="mb-0 info-title f-13">{this.props.t("Adresse")} :</h6>
                    <p className="mb-0 f-13">
                         <Link to="https://maps.app.goo.gl/Y4YNR62xyNbG2q199" className="text-muted">
                        5 rue Roger masson , 95870 , Bezons , Paris
                          </Link>
                          <br/>
                          <Link to="https://maps.app.goo.gl/Y4YNR62xyNbG2q199" className="text-muted">
                            Avenue 5 aout rue ibn rachik ,Sfax Tunisie
                          </Link>
                    </p>
                  </div>
                </div>

                <Row>
                  <Col md={6}>
                    <div className="float-left contact-icon mr-3 mt-2">
                      <i className="mdi mdi-email-outline text-muted h5"></i>
                    </div>
                    <div className="app-contact-desc text-muted pt-1">
                      <h6 className="mb-0 info-title f-13">{this.props.t("Email")}  </h6>
                      <p className="mb-0 f-13">
                        <Link to="" className="text-muted">
                          service@rec-inov.com
                        </Link>
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="contact-details">
                      <div className="float-left contact-icon mr-3 mt-2">
                        <i className="mdi mdi-headphones text-muted h5"></i>
                      </div>
                      <div className="app-contact-desc text-muted pt-1">
                        <h6 className="mb-0 info-title f-13">{this.props.t("Appel :")}</h6>
                        <p className="mb-0 f-13">+216 58 063 066</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <div className="follow mt-4">
                      <h6 className="mb-0 info-title f-13">{this.props.t("Suivre :")}</h6>
                      <ul className="follow-icon list-inline mt-32 mb-0">
                        <li className="list-inline-item f-18">
                          <a
                            href="https://www.facebook.com/Rec-Inov-100595538605052"
                            className="social-icon text-muted"
                          >
                            <i className="mdi mdi-facebook"></i>
                          </a>
                        </li>
                        &nbsp;
                        <li className="list-inline-item f-18">
                          <a href="https://www.instagram.com/rec-inov" className="social-icon text-muted">
                            <i className="mdi mdi-instagram"></i>
                          </a>
                        </li>
                        &nbsp;
                        <li className="list-inline-item f-18">
                          <a
                            href="https://www.linkedin.com/company/rec-inov/"
                            className="social-icon text-muted"
                          >
                            <i className="mdi mdi-linkedin"></i>
                          </a>
                        </li>
                        &nbsp;
                        <li className="list-inline-item f-18">
                          <a
                            href="tel:+216 58 063 066"
                            className="social-icon text-muted"
                          >
                            <i className="mdi mdi-whatsapp"></i>
                          </a>
                        </li>
                        &nbsp;
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={{ size: 7, offset: 1 }}>
                <div className="custom-form mt-4 mt-lg-0">
                  <div id="message"></div>
                  <AvForm onSubmit={this.handleSubmit}  name="contact-form" id="contact-form">
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="app-label">
                          <Label for="name">{this.props.t("Nom")}</Label>
                          <AvField
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder={this.props.t("votre nom...")}
                            errorMessage={this.props.t("Entrez votre nom..")}
                            validate={{ required: { value: true } }}
                            value={this.state.name}  
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="app-label">
                          <Label for="email">{this.props.t("Adresse électronique")}</Label>
                          <AvField
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder={this.props.t("Votre E-mail...")}
                            errorMessage={this.props.t("Entrez Votre e-mail...")}
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            value={this.state.email}  onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12}>
                        <FormGroup className="app-label">
                          <Label for="subject">{this.props.t("Sujet")}</Label>
                          <AvField
                            name="subject"
                            id="subject"
                            type="text"
                            className="form-control"
                            placeholder={this.props.t("Entrez le sujet...")}
                            errorMessage={this.props.t("Entrez votre sujet")}
                            validate={{
                              required: { value: true },
                            }}
                            value={this.state.subject}  onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12}>
                        <FormGroup className="app-label">
                          <Label for="comments">{this.props.t("Message")}</Label>
                          <textarea
                            name="comments"
                            id="comments"
                            rows="3"
                            className="form-control"
                            placeholder={this.props.t("Entrez le message...")}
                            value={this.state.message}  onChange={this.handleChange}
                          ></textarea>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Button
                          type="submit"
                          id="submit"
                          name="send"
                          color="warning"
                          onClick={this.Confirm}
                        >
                         {this.props.t("Envoyer un message")} <i className="mdi mdi-telegram ml-2"></i>
                        </Button>
                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(GetInTouch);
