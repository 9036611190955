import { Divider, Modal, notification, Select, Space, Typography } from "antd";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../routes/AppContext";
import { AddSkill } from "../../../services/api/features/AddSkill";

import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

import {  useTranslation } from "react-i18next";
const { Option } = Select;

export default function LanguageForm(props) {
  var { t,i18n } = useTranslation();
  const { languagesList,user,setUser,headers } = useContext(AppContext);
  const [name, setName] = useState("");
  
  const languages = { "fr": "language_fr", "en": "language_en" }
  const softSkills = { "fr": "soft_skill_fr", "en": "soft_skill_en" }
  const language = languages[i18n.language]
  const softSkill = softSkills[i18n.language]
  
  const onNameChange = (value) => {
    setName(value);
  };

  const addItem = async (e) => {
    e.preventDefault();
    if (name) {
      let res = await AddSkill(
        {
          skill: name,
          feature: 2,
          
        },
        headers
      );
      if (validation(name)) {
        let skill = { language_fr: name,language_en: name };
        skill.score = 0.2;
        skill.feature = 2;
        skill.id=props.id
        let res=await props.setLanguage(skill);
    if(res){
 let cv = user
        notification.success({
          description: res.data.message,
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#d4edda",
            marginTop: "5rem",
          },
        });
        let languages = props.languages;
        languages.push(skill);
        cv.languages=languages
        setUser(cv)}
        props.setModalVisible(false)


      }
      setName("");
    }
  };

  const handleSelect = (value, i) => {
    addSkill(value, i.key);
  };
  const validation = (skill) => {
    let isvalid = true;
    let languages = props.languages;
    if (skill == "") {
      isvalid = false;
      notification.warn({
        description: t("veuillez choisir une compétence"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    } else if (languages.length >= 5) {
      isvalid = false;
      notification.warn({
        description: t("vous atteignez le maximum de compétences possibles"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    } else {
      for (let i = 0; i < languages.length; i++) {
        const element = languages[i];
        if (element.language_fr == skill||element.language_en == skill) {
          isvalid = false;
          notification.warn({
            description: t("compétence déja utilisé"),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor: "#fff3cd",
              marginTop: "5rem",
            },
          });
        }
      }
    }
    return isvalid;
  };
  const addSkill =async (s, key) => {
    if (validation(s)) {
      let skill = languagesList[key];
        skill.id=props.id
        skill.score = 0.2;
        let res=await props.setLanguage(skill);
       if(res){
        let cv = user
        let languages = props.languages;
        languages.push(skill);
        cv.languages=languages
        cv.updated=true
        setUser(cv)}
        props.setModalVisible(false)

    }
  };
  const children = [];
  for (let i = 0; i < languagesList.length; i++) {
    children.push(
      <Option key={i} value={languagesList[i][language]}>
        <div className='capitalize' >{languagesList[i][language]}</div>
      </Option>
    );
  }
  return (
    <div>
      <Modal
        title={
          <h5 className="m-1 text-primary">
            <b>{t("Ajouter une langue")}</b>
          </h5>
        }
        className=""
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined className="m-3" />
          </i>
        }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={null}
      >
        <div className=" m-3">
          <Select
            className="w-75"
            // style={{ width: '40%' }}
            showSearch
            placeholder={t("Rechercher pour sélectionner")}
            onChange={handleSelect}
            onSearch={onNameChange}
            notFoundContent={
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Typography.Link
                    onClick={addItem}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <PlusOutlined /> {t('Ajouter')}
                  </Typography.Link>
                </Space>
              </>
            }
          >
            {children}
          </Select>
        </div>
      </Modal>
    </div>
  );
}
