import axios from "../../../interceptors/axios"

export const GetJobPosts =async (data,headers)=> {
    try {
     let res=await axios.get('/api/manageJobPosts/get', {
        headers: headers
    }, { withCredentials: true })
      return res
} catch (err) {
    }
  }