import axios from "axios";

export const getAllFonctionnalite = async (headers) => {
  try {
    let res = await axios.get(
      `/api/pricing/fonctionnalite/all-fonctionnalite`,{
        headers:headers
      }
    );
    return res;
  } catch (err) {
  }
};