 import moment from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import Modal from 'antd/lib/modal/Modal';
import Cv from '../cvPDF/Cv';
import { Button, notification, Pagination, Select } from 'antd';
import { GetTestResult } from '../../services/api/companyTest/GetTestResult';
import QuestionsList from './QuestionsList';
import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import QuestionPreview from './QuestionPreview';
import QuestionComponent from './questions/QuestionComponent';
import { UpdateTestResult } from '../../services/api/companyTest/UpdateTestResult';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

// function useForceUpdate(){
//   const [value, setValue] = useState(0); // integer state
//   return () => setValue(value => value + 1); // update the state to force render
// }
export default function TestResultComponent(props) {
  const { t } = useTranslation();

  const { testLanguage,setTestLanguage,headers } = useContext(AppContext);

  const [result,setResult]=useState([])
  const [questions,setQuestions]=useState([])
  const [answers,setAnswers]=useState([])
  const [questionNumber,setQuestion]=useState(1)
  const [update,setUpdate]=useState(false)
  const [editable,setEditable]=useState(false)
  const [questionsResults,setQuestionsResults]=useState([])
  const [score,setScore]=useState(props.testResult.score??0)
  const [points,setPoints]=useState(props.testResult.points??0)
  const [loading,setLoading]=useState(false)


  // const forceUpdate = useForceUpdate();
//   useMemo(()=>{
// setQuestions([])
//   },[])
let totalPoints=useMemo(()=>{
  return questions.reduce(function(acc, question) { return acc + question.points; }, 0)
},[questions])
  const get=async()=>{
    let idAnswers=[]
    let answers=[]
    let data=[]
if(props.idTestResult){    
  let response=await 
    GetTestResult({id:props.idTestResult},headers)
    if(response){
    data=JSON.parse(decrypt(response.data))
   for (let i = 0; i < data.length; i++) {
     idAnswers=idAnswers.concat(data[i].idAnswer)
     if(typeof(data[i].answer)=="string"){
      answers.push(data[i])
     }
   }
   setAnswers(data)
  //  let points=data.reduce(function(acc, question) { return acc + question.points; }, 0)
  //  setPoints(points)
  //  setScore((points/totalPoints)*100)
  }
   setUpdate(!update)
  setResult(idAnswers)
return({idAnswers,data})}
  }

  useEffect(async()=>{
     setResult([])
     let qResult=[]
    let {idAnswers,data}=await get()
    let questionsList=[...props.test?.questions]
    for (let i = 0; i < questionsList?.length; i++) {
      const question = questionsList[i];
      for (let j = 0; j < data.length; j++) {
      if(data[j].idQuestion==question._id) {
        qResult.push({idQuestion:question._id,points:data[j]?.points??0})
      }        
      }
      for (let j = 0; j < question.answers.length; j++) {
        const answer = question.answers[j];
          if(idAnswers.includes(questionsList[i].answers[j]._id))
          {
            questionsList[i].answers[j].chosen=true
        }
        else 
        questionsList[i].answers[j].chosen=false
      }
    }
    setQuestionsResults(qResult)
    await   setQuestions(questionsList)
  await setQuestion(1)
    setUpdate(!update)
    
  },[])
  const updateResult=async()=>{
    setLoading(true)
    let res=await UpdateTestResult({idCandidateTest:props.idTestResult,questions:questionsResults,score:score,points:points},headers)
  props.setModalVisible(false)
  }
 
  const updateQuestionResult=(index,points)=>{
    let qResult=[...questionsResults]
    qResult[index].points=points
    let newTotalPoints=qResult.reduce(function(acc, question) { return acc + question?.points??0; }, 0)
    setPoints(newTotalPoints)
    setScore((newTotalPoints/totalPoints)*100)
    setQuestionsResults(qResult)
  }
  const list=questions.map((question,index)=>{
    let answer ={}
      for (let j = 0; j < answers.length; j++) {
      if(answers[j].idQuestion==question._id) {
        answer=answers[j]
      }        
      }
    return(<>
   {/* {question && <QuestionComponent key={i} index={i}  question={question} answer={answer} />} */}
   {question && <QuestionPreview questionResult={questionsResults[index]} updateQuestionResult={updateQuestionResult} editable={editable} key={index} index={index}  question={question} answer={answer} />}
    </>)
  })
  return  <div  className=''>
  <h6>
  {props?.test?.title}
  </h6>
  <small>
    {props?.test?.description}
  </small>
  <br/>
  <div className='d-flex justify-content-between' >

  <Select className="w-50"  
                  // style={{ width: '40%' }} 
                   showSearch value={testLanguage}
    placeholder={t("Rechercher pour sélectionner")} onChange={setTestLanguage}>
    <Option value="fr">{t("Francais")}</Option>
    <Option value="en">{t("Anglais")}</Option>
  </Select>
  <div>Score: {points}/{totalPoints}({(score).toFixed(2)}%)</div>
  <button onClick={()=>{setEditable(true)}} className='btn btn-primary' >{t("Modifier")}</button>
  </div>
  <hr/>
  <div>
    {/* <QuestionsList questions={questions} questionNumber={questionNumber}/> */}
    {list && list[questionNumber-1]}
  </div>

  <div className='right' >
  <Pagination 
  current={questionNumber}
  // total={props.test.questions.length} 
  total={questions.length}
  pageSize={1}
  onChange={(page)=>{setQuestion(page)}}
   />
  </div>
  <br/>
  {editable &&<div className='d-flex justify-content-end' >
  <Button  className="" type="primary" loading={loading} onClick={updateResult}>
              {t("Enregistrer")}
</Button>
  </div>}
</div>;
}
