import React, { useContext, useRef, useState }  from 'react'
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import SchoolIcon from '@material-ui/icons/School';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { AppContext } from "../../../routes/AppContext";
import { Timeline,Switch } from 'antd';


// import jsPDF from 'jspdf';

// import { GrCertificate } from "react-icons/gr";


import { ClockCircleOutlined } from '@ant-design/icons';
import { useReactToPrint } from "react-to-print";


// import { IoMail } from "react-icons/io5";


import CardMembershipIcon from '@material-ui/icons/CardMembership';

// import { FaBuilding, FaCodeFork, FaGraduationCap, FaPhone} from 'react-icons/fa6';
// import { FaLocationDot } from "react-icons/fa6";
// import { GrValidate } from "react-icons/gr";
import logo from "../../../media/image/logo-rec.png"
import profile from "../../../media/image/y.jpg"
import CvCercle from '../template/cv-cercle/CvCercle';
import ContactTwo from './ContactTwo';
import HardSkillsTwo from './HardSkillsTwo';
import SoftSkillsTwo from './SoftSkillsTwo';
import LanguageTwo from './LanguageTwo';
import EducationsTwo from './EducationsTwo';
import CertificatesTwo from './CertificatesTwo';
import ExperiencesTwo from './ExperiencesTwo';
import ProjectsTwo from './ProjectsTwo';
import EditTemplate from './editTemplate/EditTemplate';
import ContactTwoEColor from './ContactTwoEColor';
import HardSkillsTwoEColor from './HardSkillsTwoEColor';
import SoftSkillsTwoEColor from './SoftSkillsTwoEColor';
import LanguageTwoEColor from './LanguageTwoEColor';
import EducationsTwoEColor from './EducationsTwoEColor';
import CertificatesTwoEcolor from './CertificatesTwoEColor';
import ExperiencesTwoEColor from './ExperiencesTwoEColor';
import ProjectsTwoEColor from './ProjectsTwoEColor';
import { useTranslation } from "react-i18next";
const TemplateTwoBodyEColor = ({candidate,decryptedData,cv,tt1,tt2,tt3,tt4}) => {
    const componentRef = useRef();
    const { t } = useTranslation();
    const { envVar } = useContext(AppContext);
    const handlePrint = useReactToPrint({
      content: () => {
        return componentRef.current;
      }
    });
  return (
    <div className="cvt-all">
            <div className="cvt-rec">
                <div className="cvt-rec-r">
                    <div className="cvt-down" onClick={handlePrint}>
                    {t("Télécharger CV")}
                    </div>
                    <img src={logo} alt='logo' className='cvt-logo' />                    
                </div>
            </div>
            <div className="cvt-contain" ref={componentRef}>
            {/* theme-two */}
                <div className="cvt-l">
                    <div className="cvt-detail theme-two">
                        <div className="cvt-profile">
                            <div className="cvt-img-profile">
                                <img src={
                envVar.REACT_APP_CLIENT_URL +
                "/candidate/images/" +
                candidate.image
              } alt='logo' className='cvt-i-profile' />  
                            </div>
                            <div className="cvt-name" style={{color:tt1}}>{candidate.name + " " + candidate.lastName}</div>
                            <div className="cvt-job" style={{color:tt2}}>{candidate.desiredJobPost}</div>
                        </div>
                        {cv.email && (
                            <ContactTwoEColor decryptedData={decryptedData} candidate={candidate} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4} />
                            )}

                        

                    </div>
                    <div className="cvt-comp theme-two">

                        {cv.hardSkills && (
                            <HardSkillsTwoEColor hardSkills={cv.hardSkills} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4}/>
                        )}

                        {cv.softSkills && (
                            <SoftSkillsTwoEColor softSkills={cv.softSkills} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4}/>
                        )}
                        {cv.languages && (
                            <LanguageTwoEColor languages={cv.languages} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4} />
                        )}
                    </div>

                </div>
                <div className="cvt-r">

                    {/* Formations académiques */}
                    {cv.educations && (
                        <EducationsTwoEColor educations={cv.educations} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4} />
                    )}

                    {/* Certificats */}
                    {cv.certificates && (
                        <CertificatesTwoEcolor certificates={cv.certificates} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4}/>
                    )}

                    {/* Expériences professionnelles */}
                    {cv.experiences && (
                        <ExperiencesTwoEColor experiences={cv.experiences} tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4}/>
                    )}

                    {/* Projets académiques */}
                    {cv.projects && <ProjectsTwoEColor projects={cv.projects}  tt1={tt1} tt2={tt2} tt3={tt3} tt4={tt4}/>}                    
                    
                    
                    
                </div>
            </div>
        </div>
  )
}

export default TemplateTwoBodyEColor