import React, { Component } from 'react'
import { Steps, Divider } from 'antd';
import { UserOutlined, SolutionOutlined, AuditOutlined, FileDoneOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
const { Step } = Steps;

 class InscriptionSteps extends Component {
    render() {
  const { t } = this.props;

        return (
            <div style={{ direction: "ltr"}}>
                <Steps  className="mb-5" current={this.props.current} >
                    <Step status="" description={t("Confirmation compte")} title={t("Etape")+" 1"} icon={<UserOutlined />} />
                    <Step status="" description={t("Profil entreprise")} title={t("Etape")+" 2"} icon={<AuditOutlined />} />
                    <Step status="" description={t("Contact")} title={t("Etape")+" 3"} icon={<SolutionOutlined />} />
                    <Step status="" description="" title={t("Succée")} icon={<FileDoneOutlined />} />
                </Steps> 
            </div>
        )
    }
}
export default withTranslation()(InscriptionSteps);
