import React from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../../media/image/upload-cv.webp"
const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};
const Dropzone = ({ onDrop, accept }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  return (
    <div className={getClassName("dropzone", isDragActive)} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center" style={{borderStyle:"dashed"}} >
        {isDragActive ? (
            <p className="zoom-1 dropzone-content  border-primary border-dashed ">

            <img  className="    my-2   rounded  w-100" alt="recinov"src={upload}
            // style={{maxWidth:"90%"}}
            />

          </p>
        ) : (
          <p className="dropzone-content  border-primary border-dashed ">

            <img  className="    my-2   rounded w-100 " alt="recinov"src={upload}
            // style={{maxWidth:"90%"}}
            />

          </p>
        )}
      </div>
    </div>
  );
};

export default Dropzone;