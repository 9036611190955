import React from 'react'
import JobPostsList from '../../components/jobPostsList/JobPostsList'
import decodeToken from '../../utils/decodeToken'
import decrypt from '../../utils/decrypt'

export default function CandidateJobPostsListView(props) {
    return (
        <>
            <JobPostsList  userId={props.userId} />
        </>
    )
}
