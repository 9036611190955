import React, { useEffect } from 'react'
import IndexHome from "../components/HomeCompany/index";

export default function HomeCompanyPage(props) {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
    return (
        <div  className="HomeCompanyPage"> 
         
          <IndexHome />
        </div>
    )
}
