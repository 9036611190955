import React, { useContext, useEffect, useState } from "react";

import { Link, useHistory, withRouter } from "react-router-dom";
import { GetInterviewsByJobPost } from "../../services/api/interview/GetInterviewsByJobPost";
import decrypt from "../../utils/decrypt";
import ScheduleInterviewsConfirm from "./ScheduleInterviewsConfirm";
import { AppContext } from "../../routes/AppContext";
import { Modal, notification, Switch, Table } from "antd";
import { DelayInterview } from "../../services/api/interview/DelayInterview";
import ClockIcon from "../icons/ClockIcon";
import { CancelInterview } from "../../services/api/interview/CancelInterview";
import BanIcon from "../icons/BanIcon";
import FileIcon from "../icons/FileIcon";
import Cv from "../cvPDF/Cv";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import AsyncImage from "../AsyncImage";
import crypt from "../../utils/crypt";
import Dates from "./Dates";
import ManageApplicationsModal from "../jobApplications/ManageApplicationsModal";
import InterviewsNav from "./InterviewsNav";
import NotFixedInterviews from "./NotFixedInterviews";
import EditIcon from "../icons/EditIcon";
import InterviewForm from "./InterviewForm";

import { JobPostContext } from "../dashboard/jobPostDashboard/JobPostContext";
import { useTranslation } from "react-i18next";
import UploadedCv from "../cvPDF/UploadedCv";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;

function Interviews(props) {
  const { companyName, socket, role,envVar,headers,serverTime } = useContext(AppContext);
  const { jobPost,setInterviews,setDates,dates,applications} = useContext(JobPostContext);
  const { t } = useTranslation();

  const [interviews, setStateInterviews] = useState([]);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showFixedInterviews, setShowFixedInterviews] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [candidate, setCandidate] = useState({});
  const [interviewToUpdate, setInterviewToUpdate] = useState({});
  const [interviewIndex, setInterviewIndex] = useState(0);
  
  const history = useHistory();

  const updateInterview=(interview,updateEvents)=>{
    if(updateEvents){
      let interviewsList=[...events]
    interviewsList[interviewIndex]=interview
    setEvents(interviewsList)
    }
    else{
      let interviewsList=[...interviews]
    interviewsList[interviewIndex]=interview
    setStateInterviews(interviewsList)}
  }

  const goToMeet=(interview)=>{
     let interviewLink=interview.link
     if(interviewLink)
  window.open(interviewLink,"_blank");
  else
     history.push({
             pathname: "/"+role+"/room/"+crypt(interview),
           });
     
   }
  const handleChangePage = (e, page) => {
    setPage(page);
    // document.getElementById("scroller").scrollIntoView();
  };
  const get = async () => {
    let response = await GetInterviewsByJobPost(
      { id: jobPost._id },
      headers
    );
    if(response){
    let data = JSON.parse(decrypt(response.data));
    setStateInterviews(data.filter((interview) => interview.idCandidate));
    setEvents(data.filter(e=>moment(serverTime).isBefore(moment(e.date))));
    setInterviews(data.filter((interview) => interview.idCandidate))
    setDates(data.filter(e=>moment(serverTime).isBefore(moment(e.date))).filter((d) => !d.idCandidate))
    setLoading(false)
    if (!data.length) setModalVisible(true);}

  };

  useEffect(() => {
    jobPost._id
      ? get()
      : history.push("/" + role + "/manageJobPosts");
  }, []);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled:moment(serverTime).isBefore(moment(record.date)),
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div className="row" id="scroller">
      <div className="col-sm-12 col-md-8 rounded shadow">
        <InterviewsNav showFixedInterviews={showFixedInterviews} setShowFixedInterviews={setShowFixedInterviews} />
       {showFixedInterviews?<>
       <div className="d-flex justify-content-left align-items-center" > 
        <h6 className=" titre-table"> {t("Liste des entretiens")} </h6>
    
        <button
          onClick={() => {
            if (selectedRowKeys.length) {
              setModal2Visible(true);
            } else {
              notification.warn({
                description: "aucun candidat selectionné",
                className: "custom-class",
                style: {
                  width: 600,
                  backgroundColor: "#fff3cd",
                  marginTop: "5rem",
                },
              });
            }
          }}
          className="btn btn-primary rounded-pill m-1"
        >
          {t("Etape suivante")}
        </button>
        <Switch
                    checkedChildren={t("historique")} unCheckedChildren={t("historique")}
                    className={showHistory ? "me-1 btn-success" : "me-1 btn-danger"}
                    // size="small"
                    checked={showHistory}
                    onChange={() => {setShowHistory(!showHistory)
                    }}
                  /></div>
         <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>         
        <Table
         loading={loading}
          className="m-2"
          pagination={true}
          dataSource={showHistory?interviews:interviews.filter(i=>moment(serverTime).subtract(1, 'days').isBefore(moment(i.date)))}
          rowKey={(record) => {
            let k = `{"${record.idCandidate}":"${record.candidate[0].email}"}`;
            return k;
          }}
          rowSelection={rowSelection}
        >
          <Column
            align="left"
            sorter={(a, b) => a.candidate[0].name.localeCompare(b.candidate[0].name)}
            title={t("Nom")}
            dataIndex="name"
            key="name"
            render={(text, interview, index) => {
              let candidate = interview.candidate[0];
              return (
                <div role="button"
                onClick={() => {
                  let application=applications.find(app=>app.idCandidate==candidate._id)
                  if(application.cvFile){
                    candidate.cvFile=application.cvFile
                    candidate.uploaded=true
                  }
                  setCandidate(candidate)
                  setModalVisible1(true)}} className=" d-flex justify-content-start align-items-center">
                  <AsyncImage
                    className="logo-m rounded me-1"
                    src={
                      envVar.REACT_APP_CLIENT_URL+
                      "/candidate/images/" +
                      candidate.image
                    }
                  />
                  {candidate.name}
                </div>
              );
            }}
          />

          <Column
            align="center"
            sorter={(a, b) => new Date(a.date) - new Date(b.date)}
            // responsive={["lg"]}
            title={t("Date")}
            dataIndex="date"
            key="date"
            render={(text, interview, index) => {
              return <>{moment(interview.date).format("HH:mm L")}</>;
            }}
          />

          <Column
            align="center"
            title={t("Actions")}
            dataIndex="Actions"
            key="Actions"
            render={(text, interview, index) => {
              let candidate = interview.candidate[0];
              return (
                <div className="">
                  {/* <i
                    role="button"
                    onClick={() => {
                      setCandidate(candidate)
                      setModalVisible1(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <FileIcon width="1.2rem" fill="#676ee7" />
                  </i> */}
           
                  {moment(serverTime).isBefore(moment(interview.date))?<i
                    role="button"
                    onClick={() => {
                      Modal.confirm({
                        title: t("Retarder entretien"),
                        icon: <ExclamationCircleOutlined />,
                        content: (
                          <div>
                            {t("Voulez vous retarder ce entretien !")}
                            <br />
                            {t("aprés la retardation ce candidat doit choisir une autre date d'entretien")}
                          </div>
                        ),
                        okText: "Oui",
                        okType: "danger",
                        cancelText: "Non",
                        onOk: () => {
                          DelayInterview(
                            {
                              
                              idInterview: interview._id,
                              email: candidate.email,
                              companyName: companyName,
                              jobPostTitle:
                                jobPost?.title,
                              idJobPost: jobPost?._id,
                              idCandidate: candidate._id,
                            },
                            headers
                          );
                          //send notification
                          socket.emit("sendNotification", 
    {receiverId:candidate._id,type:7}
    );

                          //remove interview from list
                          let interviewsList=[...interviews]
                          interviewsList.splice(index,1)
                          setStateInterviews(interviewsList)
                        },
                      });
                    }}
                    className=" text-success  m-1 zoom1 icons12"
                  >
                    <ClockIcon width="1.7rem" fill="rgb(239 128 72 / 85%)" />
                  </i>:
                     <i                     role="button"
                     className=" text-success  m-1 zoom1 icons12"
  >
                     <ClockIcon width="1.7rem" fill="gray" />
                   </i>
                  }
       <i className="text-success m-1 zoom1 icons12" role="button"
                  onClick={() => {
                    setInterviewToUpdate(interview)
                    setModal3Visible(true)
                    setInterviewIndex(index)
                  }}
                >
                  <EditIcon fill="#4caf50" width="1.8rem" />
                </i>
                  {/* <i
                    role="button"
                    onClick={() => {
                      Modal.confirm({
                        title: "Annuler entretien",
                        icon: <ExclamationCircleOutlined />,
                        content: (
                          <div>
                            Voulez vous annuler ce entretien !
                            <br />
                            aprés l'annulation ce candidat va étre réfusé
                            automatiquement
                          </div>
                        ),
                        okText: "Oui",
                        okType: "danger",
                        cancelText: "Non",
                        onOk: () => {
                          CancelInterview(
                            {
                              companyName: companyName,
                              jobPostTitle:
                                jobPost?.title,
                              
                              idInterview: interview._id,
                              email: candidate.email,
                            },
                            {
                              authorization: generateToken(
                                { id: props.userId }
                              ),
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            }
                          );
                          socket.emit("sendNotification", candidate._id);
                        },
                      });
                    }}
                    className=" text-danger  m-1 zoom1 icons12 "
                  >
                    <BanIcon width="1.7rem" fill="#e25757" />
                  </i> */}
                  
                </div>
              );
            }}
          />
          <Column
            align="center"
            // responsive={["lg"]}
            title={t("Lien")}
            dataIndex=""
            key="Lien"
            render={(text, interview, index) => {
              return (
                <div>
                    {interview.address?t("Présentiel"):<button onClick={()=>goToMeet(interview)} className="btn btn-primary ">
                      {" "}
                      <b className="mobile">{t("Acceder")} </b>&#10143;{" "}
                    </button>}
                </div>
              );
            }}
          />
        </Table>
        </ConfigProvider>
        </>:
        <>
        <NotFixedInterviews setCandidate={setCandidate}
setModalVisible1={setModalVisible1} />
        </>}
      </div>
      {/* {list} */}

      <div className="col-sm-12 col-md-4 rounded shadow">
        <Dates
          setEvents={setDates}
          idJobPost={jobPost._id}
          userId={props.userId}
          events={dates}
          loading={loading}
          setInterviewToUpdate={setInterviewToUpdate}
          setModal3Visible={setModal3Visible}
          setInterviewIndex={setInterviewIndex}
        />
      </div>
      <ScheduleInterviewsConfirm
        jobPost={jobPost}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setPage={props.setPage}
      />
  {modal2Visible&&    <ManageApplicationsModal
        isTest={false}
        selectedCandidates={selectedRowKeys}
        title={jobPost.title}
        startMissionDate={jobPost.startMissionDate}
        idTest={jobPost.idTest}
        idJobPost={jobPost._id}
        modalVisible={modal2Visible}
        setModalVisible={setModal2Visible}
        interviews={dates}
      />}
  {modalVisible1&&    <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{t("Aperçu du CV")}</b>
                      </h5>
                    }
                    className="mb-2 mt-2"
width={"95%"}
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    visible={modalVisible1}
                    onOk={() => setModalVisible1(false)}
                    onCancel={() => setModalVisible1(false)}
                    footer={<></>}
                  >
      {modalVisible1 &&  
      (candidate.uploaded?<UploadedCv decryptedData={true} userId={props.userId} candidate={candidate} idJobPost={jobPost._id} />:
      <Cv  decryptedData={true} userId={props.userId} candidate={candidate} />)}

              
                  </Modal>}
                  

                  {modal3Visible&&    <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{t("Modifier Entretien")}</b>
                      </h5>
                    }
                    className="m-5 w-75"
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    visible={modal3Visible}
                    onOk={() => setModal3Visible(false)}
                    onCancel={() => setModal3Visible(false)}
                    footer={<></>}
                  >
                    {modal3Visible && (
                     <InterviewForm idJobPost={jobPost._id} updateInterview={updateInterview} interview={interviewToUpdate} setModalVisible={setModal3Visible} />
                    )}
                  </Modal>}
    </div>
  );
}
export default withRouter(Interviews);
