import React, { useState } from "react";
import Confirmation from "./Confirmation";
import DateTimePicker from "./DateTimePicker";
import UserInformations from "./UserInformations";
import {Helmet} from 'react-helmet-async';
import SEO from "../../routes/SEO";
import { useTranslation } from "react-i18next";
export default function DemoContainer() {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [selected, setSelected] = useState({});
  const [step, setStep] = useState(1);
  return (
    
    <div className="my-5">
            <SEO
title={t("DEMO")}
description={("Utilisez le planificateur ci-dessous pour sélectionner le jour et l'heure qui vous conviennent le mieux. Chez Rec-INOV, vous ne rencontrerez jamais un environnement de vente a haute pression.")}
name="Rec-INOV" 
url="https://rec-inov.com/demo"
image="https://Rec-INOV.com/company/images/null"
/>
      {/* <Helmet prioritizeSeoTags> */}
      
        {/* <meta name="description" content=" PLANIFIEZ UNE DEMO SANS PRESSION

Utilisez le planificateur ci-dessous pour sélectionner le jour et l'heure qui vous conviennent le mieux.
Chez Rec-INOV, vous ne rencontrerez jamais un environnement de vente a haute pression.
Nous sommes ici pour Demande de démo sur vos défis et obiectifs d " data-react-helmet="true" />
        <meta name="theme-color" content="#008f68" data-react-helmet="true" /> */}
        {/* <!-- Open Graph meta pour Facebook --> */}

      <h1 className="text-primary text-center mt-3">
        <strong>{t("PLANIFIEZ UNE DEMO SANS PRESSION")}
        </strong>
      </h1>
      <p className="text-secondary text-center mx-5 my-4">
      {t("Utilisez le planificateur ci-dessous pour sélectionner le jour et l'heure qui vous conviennent le mieux.")}
        <br/> {t("Chez Rec-INOV, vous ne rencontrerez jamais un environnement de vente a haute pression.")}
      </p>

      <div className=" mb-5 text-center ">
      {t("Nous programmons des démos jusqu'a 3 semaines à l'avance.")}
      <br/>
      {t("Pour planifier quelque chose plus tard")}
      ,<br/> 
      {t("veuillez contacter")}
      <br/> service@Rec-INOV.com
      </div>
      <DateTimePicker step={step} setStep={setStep} setSelected={setSelected} />
      <UserInformations setUser={setUser} step={step} setStep={setStep} />
      <Confirmation
        selected={selected}
        user={user}
        setStep={setStep}
        step={step}
      />
     
    </div>
  );
}
