import React, { memo } from 'react'

const CloneIcon = memo((props) => {
  return (
    <>
    <svg alt="Clone" {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 224C0 188.7 28.65 160 64 160H128V288C128 341 170.1 384 224 384H352V448C352 483.3 323.3 512 288 512H64C28.65 512 0 483.3 0 448V224zM224 352C188.7 352 160 323.3 160 288V64C160 28.65 188.7 0 224 0H448C483.3 0 512 28.65 512 64V288C512 323.3 483.3 352 448 352H224z"/></svg>
 </>
  )
})

export default CloneIcon