import { Radio } from "antd";
import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { useTranslation } from "react-i18next";
export default function UserInformations(props) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [isCompany, setIsCompany] = useState(false);
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [roleError, setRoleError] = useState("");
  const onChange = (e) => {
    setIsCompany(e.target.value);
  };
  const validation = () => {
    let isvalid = true;

    //email validation
    if (email == "") {
      isvalid = false;
      setEmailError(t("champ obligatoire"));
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      isvalid = false;
      setEmailError(t("email non valide"));
    } else setEmailError("");

    //name validation
    if (name == "") {
      isvalid = false;
      setNameError(t("champ obligatoire"));
    } else setNameError("");

    //company validation
    if (company == "" && isCompany) {
      isvalid = false;
      setCompanyError(t("champ obligatoire"));
    } else setCompanyError("");
    //company validation
    if (role == "" && isCompany) {
      isvalid = false;
      setRoleError(t("champ obligatoire"));
    } else setRoleError("");
    //phone validation
    if (phone == undefined) {
      isvalid = false;
      setPhoneError(t("champ obligatoire"));
    } else if (!isValidPhoneNumber(phone)) {
      setPhoneError(t("Numéro non valide"));
      isvalid = false;
    } else setPhoneError("");

    return isvalid;
  };
  const handleSubmit = () => {
    if (validation()) {
      props.setStep(3);
      props.setUser({ name, email, phone, company, isCompany, role });
    }
  };

  return (
    <div className=" py-4 my-2">
      <h4>
        <b className=" rounded-circle bg-primary text-white px-2 py-1  m-1 ">
          2
        </b>
        <strong> {t('Vos informations')}</strong>
      </h4>
      {props.step == 2 && (
        <>
          <hr />

          <div className="mx-5">
            <label>{t('Nom et prénom')} *</label>
            <div className="md-form">
              <input
                className="form-control validate mb-2"
                id="name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <p className="text-danger">
              <small>{nameError}</small>
            </p>

            <label> {t('Téléphone')}  *</label>
            <div className="md-form">
              {/* <input
                className="form-control validate mb-2"
                id="phone"
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              /> */}

              <PhoneInput
                defaultCountry="TN"
                numberInputProps={{ className: "form-control validate " }}
                placeholder={t("Entrez le numéro de téléphone")}
                value={phone}
                onChange={(value) => {
                  setPhone(value);
                }}
              />
            </div>
            <p className="text-danger">
              <small>{phoneError}</small>
            </p>

            <label>{t('Email')}  *</label>
            <div className="md-form">
              <input
                className="form-control validate mb-2"
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <p className="text-danger">
              <small>{emailError}</small>
            </p>
            {/* <label>
                    <Switch
                    size="small"
                    checked={isCompany}
                    onChange={() => {
                      setIsCompany(!isCompany)
                    }}
                  />
                  Entreprise
                  </label> */}
            <Radio.Group onChange={onChange} value={isCompany}>
              <Radio value={false}> {t('Candidat')}</Radio>
              <Radio value={true}>{t('Entreprise')}</Radio>
            </Radio.Group>
            {isCompany && (
              <>
                <br />
                <label>{t("Nom d'entreprise")}  *</label>
                <div className="md-form">
                  <input
                    className="form-control validate mb-2"
                    type="text"
                    id="company"
                    name="company"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  />
                </div>
                <p className="text-danger">
                  <small>{companyError}</small>
                </p>
                <label>{t('Role')}  *</label>
                <div className="md-form">
                  <input
                    className="form-control validate mb-2"
                    type="text"
                    id="role"
                    name="role"
                    value={role}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                  />
                </div>
                <p className="text-danger">
                  <small>{roleError}</small>
                </p>
              </>
            )}
          </div>
          <br />
          <div className="text-center mb-1">
            <button
              type="button"
              onClick={handleSubmit}
              className=" submitspinner btn btn-primary btn-block z-depth-1a"
            >
              {t('Confirmer')} 
            </button>
          </div>
          <br />
        </>
      )}
    </div>
  );
}
