import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
export default function Timer() {
  const { t } = useTranslation();
  const intervalRef = useRef(null);
  const history = useHistory();
  // initialiser timer en 5mn
  const [timer, setTimer] = useState("00:05:00");

  // decrementation
  function getTimeRemaining(endtime) {

    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  function startTimer(deadline) {
    let { total, days, hours, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setTimer(
        // champs dates valeurs max 9 (entre 0-9)
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(intervalRef.current);
    }
  }

  function clearTimer(endtime) {
    setTimer("00:05:00");

    if (intervalRef.current) clearInterval(intervalRef.current);
    // decrementation par 1s
    const id = setInterval(() => {
      startTimer(endtime);
    }, 1000);
    intervalRef.current = id;
  }

  function getDeadlineTime() {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 300);

    return deadline;
  }

  useEffect(() => {
    clearTimer(getDeadlineTime());

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div
      className="font-medium text text-red-600 tracking-widest px-3 py-2 rounded-lg"
      style={{ marginLeft: 20 }}
    >
      {t("Temps restant")}: {timer}
      {/* retour to verification page si time out */}
      {timer === "00:00:00" ? history.push("/checkout/1") : null}
    </div>
  );
}
