import React from 'react'
import AddJobPost from '../../components/addJobPost/AddJobPost'
import { useQueryClient } from 'react-query';
export default function AddJobPostView(props) {
    const queryClient = useQueryClient();
  
    const handleInvalidate = () => {
      // Invalidate the query data
      console.log("invalidation");
      queryClient.invalidateQueries(['jobPosts']);
      queryClient.invalidateQueries('jobPost');
      queryClient.getQueryCache().findAll('queries', query => query.queryKey[0] === 'jobPost')
      .forEach(query => {
        console.log(query);
        // queryClient.invalidateQueries(query.queryKey);
      });
    };
    return (
        <div className="UploadJobPostPage">
            
            <AddJobPost handleInvalidate={handleInvalidate} idResponsible={props.idResponsible} userId={props.userId} />
        </div>
    )
}
