
import React, { Component } from "react";
import MapComponent from "./MapComponent";
import jwt from "jsonwebtoken"
import CryptoJS from "crypto-js";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import InscriptionSteps from "../InscriptionSteps";
import AddResponsibleConfirm from "../AddJobPostConfirm";
import {CompanyContactService} from "../../services/api/contact/CompanyContactController"
import { AppContext } from "../../routes/AppContext";
import { withTranslation } from "react-i18next";

// import { Link } from "react-router-dom";

class CompanyContact extends Component {
  static contextType = AppContext

  constructor(props,context) {
    super(props);
let company=context.user
    this.state = {
      stepState:2,
      modalVisible: false,

      id: company.id,
        address: company.address,
        phone:company.phone!==null ? "+"+company.phone.toString():"",
        website: company.website,
        facebookPage: company.facebookPage,
         linkedinPage: company.linkedinPage,
        // id:entreprise._id,
         tel_err:true ,
        tel_err2:true ,
        adresse_err:true ,
        localization: company.localization,

        phoneError:"",
        addressError:"",
        localizationError:"",
        
    };
    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }


    setModalVisible=(modalVisible)=> {
      this.setState({ modalVisible });
    }
      handleChange(event) {
        const{name,value}=event.target;
        this.setState({
          [name]:value
        })
    
      }
      validation=()=>{
       let isvalid=true
       let address=this.state.address
       let phone=this.state.phone
       let localization=this.state.localization
       //address validation
        if(address=="") {
          isvalid=false
          this.setState({addressError:this.props.t("champ obligatoire")})
        }
    else 
    this.setState({addressError:""})
    
    
    //phone validation
    if(phone==undefined) {
      isvalid=false
      this.setState({phoneError:this.props.t("champ obligatoire")})
    }
    else if (!isValidPhoneNumber(phone)) {
      this.setState({phoneError:this.props.t("Numéro non valide")});
      isvalid = false;
    }
    else 
    this.setState({phoneError:""})

  //localization validation
//   if(!localization.lat) {
//     isvalid=false
//     this.setState({localizationError:"champ obligatoire"})
//   }
// else 
// this.setState({localizationError:""})

    return isvalid
    }
      handleSubmit(event){
        event.preventDefault();
    
      
        if(this.validation()){
          CompanyContactService({
            
         address:this.state.address,
         phone:this.state.phone,
         localization:this.state.localization,
         website:this.state.website,
         linkedinPage:this.state.linkedinPage,
         facebookPage:this.state.facebookPage,
         },this.context.headers)
     
            let data=this.context.user
            data.address=this.state.address
            data.phone=parseInt(this.state.phone.substring(1),10)
            data.localization=this.state.localization
            data.website=this.state.website
            data.facebookPage=this.state.facebookPage
            data.linkedinPage=this.state.linkedinPage
          this.context.setUser(data)
    this.setState({stepState:3})
    this.setModalVisible(true)
      // this.props.history.push('/login/entreprise')

        
        }}




  setPosition = (position) => {
    this.setState({ localization: position });
  };

  render() {
  const { t } = this.props;

    return (
      <div className="CompanyContactPage">
        <div className=" m-1">

  <InscriptionSteps current={this.state.stepState}/>
   
          <div className="">
            <form className=" rounded" onSubmit={this.handleSubmit}>
              <div className="row">
            

                <div className=" col-lg-6 col-sm-12 ">

                <label className="col-12  " htmlFor="tel">
                    <span className="float-left"> {t("Téléphone")} * </span>
                    <br />

                    <PhoneInput
                    defaultCountry="TN"
                      numberInputProps={{ className: "form-control" }}
                      placeholder={t("Entrez le numéro de téléphone")}
                      
                      value={this.state.phone}
                      onChange={(value) => {
                        this.setState({ phone: value });
                      }}
                    />
                    <p className="text-danger"><small>{this.state.phoneError}</small></p>
                  </label>

                <label className="col-12 mt-2 " htmlFor="address">
                    <span className="">{t("Adresse")} *</span> <br />
                    <input
                      className="border form-control "
                      id="address"
                      type="text"
                      name="address"
                      
                      value={this.state.address}
                      onChange={this.handleChange}
                    />
                  </label>
                  <p className="text-danger"><small>{this.state.addressError}</small></p>

       <label className="col-12 mt-2" htmlFor="website">
                    <span className=""> {t("Site Web")} </span>
                    <input
                      className="border form-control "
                      id="website"
                      type="text"
                      name="website"
                      value={this.state.website}
                      onChange={this.handleChange}
                    />
                  </label>
                  <p className="text-danger"><small></small></p>

                  <div className="  ">
                    <label className=" col-12 mt-2" htmlFor="facebookPage">
                      <span className="float-left"> {t("Page facebook")} </span>
                      <input
                        className="border form-control "
                        id="facebookPage"
                        type="text"
                        name="facebookPage"
                        value={this.state.facebookPage}
                        onChange={this.handleChange}
                      />
                    </label>
                    <p className="text-danger"><small></small></p>
                   
                    <label className=" col-12 mt-2" htmlFor="linkedinPage">
                      <span className="float-left"> {t("Page LinkedIn")} </span>
                      <input
                        className="border form-control "
                        id="linkedinPage"
                        type="text"
                        name="linkedinPage"
                        value={this.state.linkedinPage}
                        onChange={this.handleChange}
                      />
                    </label>
                    <p className="text-danger"><small></small></p>

                  </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 ">
                  <label className="col-12">
                    <span className=""> {t("Localisation")} </span>
                   

                    <MapComponent localization={this.state.localization} setPosition={this.setPosition} />
                    {/* <MapComponent /> */}
                  </label>
                  <p className="text-danger"><small>{this.state.localizationError}</small></p>

                </div>

                <br />
                <div className="text-center mt-4">
                  <input
                    className=" btn btn-primary "
                    type="submit"
                    id="inscri"
                    name="send"
                    value={t("Enregistrer")}
                  />
                </div>
                <br />
              </div>
            </form>
    <AddResponsibleConfirm modalVisible={this.state.modalVisible} setModalVisible={this.setModalVisible}  />

          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(CompanyContact);
