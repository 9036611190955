import { Table } from 'antd';
import React from 'react'
import EyeIcon from '../icons/EyeIcon';
import PenIcon from '../icons/PenIcon';
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;

export default function DefaultEmails(props) {


  return (
    <div>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
        <Table
         loading={props.loading}
          className="m-2"
          pagination={false}
          dataSource={props.defaultEmails}
          rowKey={(record) => {return record._id;
          }}
        >
          <Column
            align="center"
            sorter={(a, b) => a.model.localeCompare(b.model)}
            title="Email"
            dataIndex="model"
            key="name"
          />

          <Column
            align="center"
            title="Actions"
            dataIndex="Actions"
            key="Actions"
            render={(text, email, index) => {
              return (
                <div className="">
                  <i
                    role="button"
                    onClick={() => {
                      props.setEmail(email)
                      props.setModalVisible(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <EyeIcon width="1.2rem" fill="#676ee7" />
                  </i>
                  <i
                    role="button"
                    onClick={() => {
                      props.setEmail(email)
                      props.setModalFormVisible(true)}}
                    className=" text-primary  m-1 zoom1 icons12"
                  >
                    <PenIcon width="1.2rem" fill="blue" />
                  </i>
           
                </div>
              );
            }}
          />
    
        </Table>
        </ConfigProvider>
    </div>
  )
}
