import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import AsyncImage from '../AsyncImage';
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { AppContext } from '../../routes/AppContext';
import { useTranslation } from "react-i18next";
export default function AnswerComponent(props) {

  const [selected,setSelected]=useState(props.selected.includes(props.answer._id))
  const {envVar,testLanguage } = useContext(AppContext);
  let answerLanguage=testLanguage=="fr"?"answer_fr":"answer_en"

  // setSelected(false)
  
  useEffect(() => {
    hljs.highlightAll();
  });

const select=()=>{
  let selectedList=props.selected
  //condition to push or to pop
  if(selected){
    selectedList=selectedList.filter(item=>{
return item!=props.answer._id
    })
    props.setSelected(selectedList)

  }
  else{
    selectedList.push(props.answer._id)
    props.setSelected(selectedList)
  }
  setSelected(!selected)

}
  return (
    <div onClick={select} role="button" 
    style={{maxHeight:"15rem",maxWidth:"100%",overflow:"auto"}}

    className={selected?'border border-info border-3 shadow m-3 p-1 ':' shadow p-1 m-3 '} >
      {  props.answer.type != 1 ? (
          <div  dangerouslySetInnerHTML={{
            __html: props.answer[answerLanguage],
          }} ></div>
          
        ) : (
          <AsyncImage
            className="w-100"
            src={
              envVar.REACT_APP_CLIENT_URL
              + "/Test/images/" +
              props.answer[answerLanguage]
            }
          />
        )}
    </div>
  )
}
