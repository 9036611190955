import React, { useRef, useState } from 'react'
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const Colaps = ({question,reponse}) => {
    const [isOpen, setIsOpen] = useState(false)
    const content = useRef();
    const text = `
A dog is a type of domesticated animal.
Known for its loyalty and faithfulness,
it can be found as a welcome guest in many households across the world.
`;
  return (
    <div  className={isOpen ? "colapsi borderl" : "colapsi" }>

        
        <div className="colapsi-top" onClick={()=>setIsOpen(!isOpen)}>
            <div className="colapsi-l">
            {question}
            </div>
            <div className="colapsi-r">
                {isOpen ?  <RemoveIcon className="colapsi-min" style={{ fontSize: 40 }} /> :<AddIcon className="colapsi-add" style={{ fontSize: 40 }}/>}
            </div>
        </div>
        <div className={isOpen ? "colapsi-display" : "colapsi-bot"}  ref={content}  style={isOpen ? { maxHeight: content.current.scrollHeight +
  "px" } : { }} >
                  {reponse}
        </div>
    </div>
  )
}

export default Colaps
