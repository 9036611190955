import React, { memo, useContext } from 'react';
import CvComponent from './CvComponent';
import { GetCv } from '../../services/api/cv/GetCv';
import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { useQuery } from 'react-query';
import { GetUploadedCv } from '../../services/api/extractCvs/GetUploadedCv';
import Content from './template/Content';
const UploadedCv=memo((props)=> {
  const { candidate, decryptedData,idJobPost,cvtheque } = props;
  const { headers } = useContext(AppContext);

  const { data: cvData, isLoading, isError } = useQuery(
    ['uploadedCv',idJobPost,candidate._id], // Query key
    async () => {
      
      const response = await GetUploadedCv({ idCandidate: candidate._id,idJobPost:idJobPost }, headers);
      if (response){
        let data=JSON.parse(decrypt(response.data))
        return data ;
      }
    },
    {
      // staleTime:60*1000,
      // // Cache the data for a certain duration (in milliseconds)
      // cacheTime: 60000, // 1 minute
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }
  let myname=""
  if(cvtheque){
    if(candidate.encName)
    myname=myname+candidate.encName
    if(candidate.encLastName)
    myname=myname+ " "+candidate.encLastName

  }else
  {if(candidate.name)
  myname=myname+candidate.name
  if(candidate.lastName)
  myname=myname+ " "+candidate.lastName
  }

  const cv = cvData ? cvData : {};
  return (
    <div>
      {/* <CvComponent decryptedData={decryptedData} cv={cv} candidate={cv} /> */}
      <Content decryptedData={decryptedData} cv={cv} candidate={cv} myname={myname} />
    </div>
  );
}
)
export default UploadedCv;
