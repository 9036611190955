import React, { useContext, useEffect, useState } from 'react';
import XMarkIcon from '../icons/XMarkIcon';
import { SendTest } from '../../services/api/companyTest/SendTest';
import { AppContext } from '../../routes/AppContext';
import { Button, DatePicker, notification, Select } from 'antd';
import moment from 'moment';
import { GetJobPostsTitles } from '../../services/api/manageJobPosts/GetJobPostsTitles';
import decrypt from '../../utils/decrypt';
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select;

export default function SendTestWithEmail(props) {
  const { t } = useTranslation();

  const { userId,companyName,headers,serverTime } = useContext(AppContext);
  let expiration=new Date(serverTime)
  expiration.setDate(expiration.getDate()+props?.test?.validity)

  const [candidatesList, setCandidatesList] = useState([{ email: '', name: '' }]);
  const [jobPosts, setJobPosts] = useState([]);
  const [jobPostIndex, setJobPostIndex] = useState(0);
  const [expirationDate, setExpirationDate] = useState(expiration);
  const [loading, setLoading] = useState(false);

  const addCandidate = () => {
    setCandidatesList([...candidatesList, { email: '', name: '' }]);
  };
  const getJobPosts = async() => {
    let response = await GetJobPostsTitles(
      {},
      headers
    );
    if(response){
    let jobPosts = JSON.parse(decrypt(response.data));
    setJobPosts(jobPosts)}
  };
  useEffect(()=>{
    getJobPosts()
  },[])
  const deleteCandidate = (index) => {
    const updatedList = [...candidatesList];
    updatedList.splice(index, 1);
    setCandidatesList(updatedList);
  };

  const handleCandidateChange = (index, field, value) => {
    const updatedList = [...candidatesList];
    updatedList[index][field] = value;
    if(index==candidatesList.length-1){
      updatedList.push({ email: '', name: '' })
    }
    setCandidatesList(updatedList);
  };
  const disabledExpirationDate=(current)=> {
    // Can not select days before today and today
    let date=moment(serverTime).add(1, 'months');
    return current 
    && current > moment(date) || current<moment(serverTime)
  }
  const validation=()=>{
    let valid =true
    let candidates=candidatesList.filter(c=>c.name.length&&c.email.length)
    if(!candidates.length){
      valid=false
      notification.warn({
        description: t("ajouter au moins un candidat"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    }
    for (let i = 0; i < candidatesList.length; i++) {
      const candidate = candidatesList[i];
      if(candidate.email.length&&(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(candidate.email))){
        valid=false
        //notif
        const name=candidate.name


        notification.warn({
          description:<Trans i18nKey="invalidEmailSendTest">
  L'email du candidat <strong>{{name}}</strong> n'est pas valide
</Trans> ,
          className: "custom-class",
          style: {
            width: 600,
            backgroundColor: "#fff3cd",
            marginTop: "5rem",
          },
        });
      }
      
    }
    return valid
  }
  const handleSubmit = async(event) => {
    event.preventDefault();
    if(validation()){
      setLoading(true)
     let res=await SendTest({
        candidates:candidatesList.filter((c)=>c.name.length&&c.email.length),
        idTest:props.test._id,
        idJobPost:jobPosts[jobPostIndex]._id,
        jobPostTitle:jobPosts[jobPostIndex].title,
        companyName:companyName,
        expirationDate:expirationDate,
      },     
      headers
      )
		if(res){
      notification.success({
        description: res.data.message,
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem",
        },
      });
      props.setModalVisible(false)}
    };
    }

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <div className='row'>

        <label className='col-5 ms-2 me-4' htmlFor="name">{t("Nom")}</label>
        <label className='col-6 ms-4' htmlFor="email">{t("Email")}</label>
        </div>

        {candidatesList.map((candidate, index) => (
          <div className='d-flex justify-content-center' key={index}>
            <input
              type="text"
              name="name"
              className='form-control m-2'
              value={candidate.name}
              onChange={(e) => handleCandidateChange(index, 'name', e.target.value)}
            />
            <input
              type="email"
              name="email"
              className='form-control m-2'
              value={candidate.email}
              onChange={(e) => handleCandidateChange(index, 'email', e.target.value)}
            />
             <span alt="Profil" role="button" className="d-flex align-content-center">
        <XMarkIcon
          onClick={() => deleteCandidate(index)}
          className="me-2 zoom1"
          width="1rem"
          fill="red"
        />
      </span>
       
          </div>
        ))}
        {/* <button className='btn btn-primary' type="button" onClick={addCandidate}>
          Ajouter
        </button> */}
        <hr/>
        <div>

        {(jobPosts.length)? <>
<label>{t("Offre")} </label>
                
                <Select
            placeholder="Selectionner.."
            className=" w-100 my-1"
            onChange={(value) => {
              setJobPostIndex(value)
            }}
            value={jobPostIndex}
          >
                      { jobPosts&&jobPosts.map((jp,i)=>
                      <Option value={i}>{jp.title}</Option>)}
                      
          </Select></>:""}
          <label className="col-12 mt-2" htmlFor="linkedinPage">
                      <span className="float-left"> {t("Date d'expiration")}</span>
                      <div className="" >
                      <DatePicker
                        placeholder="DD-MM-YYYY"

                      allowClear={false}
                      disabledDate={disabledExpirationDate}
                      format={"DD-MM-YYYY"}

                      className="border form-control"
                          defaultValue={expirationDate && moment(expirationDate)}
                      onChange={(moment,date)=>{
                          if(moment!=null){
                          let m = moment.utcOffset(0);
                        m.set({hour:0,minute:0,second:0,millisecond:0})
                            setExpirationDate(m.toDate())
                    }
                      }}
                       />
                      </div>
                    </label>
        </div>

        <div className='d-flex justify-content-center'>
        <Button  className="m-2" type="primary" loading={loading} onClick={handleSubmit}>
        {t("Envoyer")}
</Button>
</div>
      </form>
    </div>
  );
}

