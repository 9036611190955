import { notification, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { GetQuestions } from '../../../services/api/companyTest/GetQuestions';
import decrypt from '../../../utils/decrypt'
import jwt from "jsonwebtoken"
import { AppContext } from '../../../routes/AppContext';
import { GetFeaturesQuestions } from '../../../services/api/companyTest/GetFeaturesQuestions';
import { useTranslation } from 'react-i18next';


const { Option } = Select;

export default function ChooseSkillsForm(props) {
  const { t } = useTranslation();

    const [skillsList,setSkillsList]=useState([])
    const [selected,setSelected]=useState([])
    const [skills,setSkills]=useState("")
    const [skillsError,setSkillsError]=useState("")
  const {recinovQuestions,setRecinovQuestions,setSelectedSkills,
    selectedSkills,headers} = useContext(AppContext);

    useEffect(async()=>{
      props.setLoading(true)
     let res=await GetFeaturesQuestions({},headers)
    if(res){

     let skillsList=JSON.parse(decrypt(res.data))
        // let skills= hardSkillsList
        // let softSkills= softSkillsList
        // let languages= languagesList
        // let skillsList= await skills.concat(softSkills,languages)
        setSkillsList(skillsList)
        props.setQuestions(recinovQuestions)
      props.setLoading(false)}
    },[])
    const children = [];
for (let i = 0; i <skillsList.length ; i++) {
    let skill=!skillsList[i].skill?(!skillsList[i].soft_skill_fr?(!skillsList[i].language_fr?(!skillsList[i].mad_skill_fr?"":skillsList[i].mad_skill_fr):skillsList[i].language_fr):skillsList[i].soft_skill_fr):skillsList[i].skill
children.push(<Option 
  // disabled={skills.length > 9& !selected.includes(skillsList[i].code)? true: false} 
  key={i} value={skill}>
    <div className='capitalize' >
    {skill}

    </div>
    </Option>);
}
// const validation=()=>{
//     let isValid=true
//     if(selected.length<1){
//         isValid=false
//         setSkillsError("champ obligatoire")
//     }
//     return isValid
// }
const removeDuplicates=(list)=>{
  const uniqueIds = [];
 let filteredList = list.filter(element => {
  const isDuplicate = uniqueIds.includes(element._id);
  if (!isDuplicate) {
    uniqueIds.push(element._id);
    return true;
  }
  return false;
});
return filteredList
}
const handleSubmit=async(code)=>{
      props.setLoading(true)
        // let code=""
        // for (let i = 0; i < selected.length; i++) {
        //     code=code+selected[i].code
        //     if(i<selected.length-1)
        //     code=code+"+"
        // }
      let res=await  GetQuestions({code:code},headers)
    if(res){
          let data=JSON.parse(decrypt(res.data))
if(data.length==0){
  notification.error({
    description:t("Une erreur est survenue"),
    className: 'custom-class',
    style: {
      width: 600,
      backgroundColor:"#f8d7da",
      marginTop:"5rem"
    },
  }) 
}
let questions=[...recinovQuestions,...data]
// const uniqueIds = [];
//  questions = questions.filter(element => {
//   const isDuplicate = uniqueIds.includes(element._id);
//   if (!isDuplicate) {
//     uniqueIds.push(element._id);
//     return true;
//   }
//   return false;
// });
questions=removeDuplicates(questions)
props.setQuestions(questions)
setRecinovQuestions(questions)
      props.setLoading(false)}

}
const handleSelect=(value,i)=> {
    // if(i.length>=1){
    //     setSkillsError("")
    // }
    // this.setState({ skill:value,key:i.key });
    //    alert(value)
// let skills=[]
    //  if (i?.length > 4) {
    //   i.pop();
    // }
    let skill= skillsList[i.key]
    setSelectedSkills(removeDuplicates([...selectedSkills,skill]))
    handleSubmit(skill.code)
    
    // i.map(s=>{
        // skills.push(skill)
      // })
      // setSelected(skills)
      // props.setSelectedSkills(skills)
      // setSkills(value)
  }
  return (
    <>
        <h5>{t("Choisir les compétences")} </h5>
        {/* <label className="" >Lieux de travail</label> */}
  
                  <Select className="border form-control w-50 p-0 m-2"  
                  loading={props.loading}
                  disabled={props.loading}
                  // style={{ width: '40%' }} 
                //   defaultValue={this.state.selectedLocations}
                   showSearch   
                  //  mode="multiple"
                   allowClear 
    placeholder={t("Rechercher pour sélectionner")} 
    onChange={handleSelect}>
    {children}
  </Select>
  
  {/* <p className="text-danger"><small>{skillsError}</small></p> */}
  {/* {props.loading?<button className='btn btn-primary rounded right disabled' >Confirmer</button>:<button className='btn btn-primary rounded right' onClick={handleSubmit} >Confirmer</button>} */}
    </>
  )
}
