import React from 'react'
import AnswerComponent from './AnswerComponent'

export default function CorrectAnswers(props) {
    let i =-1
    let list=props?.answers.filter(a=>a.isCorrect).map((answer)=>{
      i++
      return(<li>
      <AnswerComponent key={i} index={i} solution={true} answer={answer} />
      </li>)
    })
  return (
    <div className='mx-3' >
        <ul>
        {list}
        </ul>
    </div>
  )
}
