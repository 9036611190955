import { Collapse, Divider, notification, Table } from 'antd'
import React, { useContext, useState } from 'react'
import { AppContext } from '../../routes/AppContext';
import ContactUsModal from './ContactUsModal';
import FreePackage from './FreePackage';
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;
const { Panel } = Collapse;
export default function PackagesTable(props) {
  const {role,fonctionnalites,products,cart,setCart,orders,modules} = useContext(AppContext); 
  const [companyPackageModalVisible, setCompanyPackageModalVisible] = useState(false);
  const [freePackageModalVisible, setFreePackageModalVisible] = useState(false);
  const [freePackage, setPackage] = useState(null);
  const { t,i18n } = useTranslation();

  const language=i18n.language 
  const modulesLanguage={"fr":"name","en":"name_en","ar":"name_ar"}
  const moduleL=modulesLanguage[language]

  const functionalitiesLanguage={"fr":"fName","en":"fName_en","ar":"fName_ar"}
  const functionalityLanguage=functionalitiesLanguage[language]

  const packagesLanguage={"fr":"pName","en":"pName_en","ar":"pName_ar"}
  const packageLanguage=packagesLanguage[language]
  const labelLanguage={"fr":"label","en":"labelEn","ar":"labelAr"}
  const labels=labelLanguage[language]
  const AddToCart=async(p)=>{
    if(p.pCategory=="Gratuit"){
      setFreePackageModalVisible(true)
      setPackage(p)
    }
    else if(p.pCategory=="Entreprise"){
      setCompanyPackageModalVisible(true)
    }
    else{
    let exist=false
    for (let i = 0; i < cart.length; i++) {
      if (cart[i]._id==p._id) exist=true
    }
    if(exist){
      //notification
      notification.info({
        
        description: "déja existe dans le panier",
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor:"#d1ecf1",
          marginTop:"5rem"
        },
      });
    }
    else
    {let cartList=[...cart]
    cartList.push(p)
        //notification
        notification.success({
        
          description: "ajouté au panier avec succée",
          className: 'custom-class',
          style: {
            width: 600,
            backgroundColor:"#d1ecf1",
            marginTop:"5rem"
          },
        });
    setCart(cartList)}}
  }
  return (
    <div className='mt-5' >
      <div className="text-center mt-24">
          <h3 className="font-bold text-2xl mb-2">
            <b>{t("Détail des fonctionnalités")}</b>
          </h3>
          <h6 className="text-gray-600">
            {/* Choisi le forfait de tarification que vous convient */}
          </h6>
          <br />
          <br />
        </div>
        {modules.map((module,index)=>{
          // let functionalities=fonctionnalites.filter(m=>module.functionalities.includes(m.fType))
          let functionalities=module.functionalities.map(fType=>{
            for (let i = 0; i < fonctionnalites.length; i++) {
              const fun = fonctionnalites[i];
              if(fun.fType==fType) return fun
            }
          })
          return(<>
          <div>
        {products&&fonctionnalites&& <>
          <Collapse size="large">
      <Panel header={module[moduleL]} key={index}>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
      <Table 
         pagination={false}
rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' :  'table-row-light'}
     rowKey={record =>{
       return record._id}}
      dataSource={[...functionalities,{}]}> 
          <Column
            align="center"
            title={t("SERVICE")}
            dataIndex={functionalityLanguage}
            key="title"
        //   render={(text, application, index) => {
        //       return (<>
        //       <span  >{application.idJobPost[0].title} </span>
        //       </>)}}
          />
          {products.map((p,i)=>{
            return(<>
            <Column
            align="center"
            title={p[packageLanguage]}
            // title={p.pName}
            dataIndex="title"
            key="title"
          render={(text, func, index) => {
            if(func.fName){  let fl=p.fonctionnalites[0].listefonctionnalite
            let check=fl.filter(f=>(f.idFonctionnalite==func._id))
              // return (<>
              // <span  >{check.length||p.pCategory=="Entreprise"?
              // <>
              // {console.log("check", check)}
              // {console.log("p.pCategory", p.pCategory)}
              // {console.log("check[0]", check[0].textrecinovjwtsecret)}
              // {console.log("func[0]", func)}
              // {check[0].textrecinovjwtsecret??(check[0].qte?(func.getQuantified?(check[0].qte>999999?"illimité":`${func.preLabel} ${check[0].qte} ${func.label}`):<i className='mdi mdi-check text-primary icons15' ></i>):<i className='mdi mdi-close text-secondary icons15' ></i>)}
              // </>
              
              // :<i className='mdi mdi-close text-secondary icons15' ></i>
              // } </span>
              // </>)}
              return (
                <>
                  <span>
                    {
                      check.length || p.pCategory == "Entreprise" ?
                        <>
                          {check[0]?.textrecinovjwtsecret ?? (check[0]?.qte ? (func.getQuantified ? (check[0].qte > 999999 ? t("illimité") : `${func.preLabel} ${check[0].qte} ${func[labels]}`) : <i className='mdi mdi-check text-primary icons15'></i>) : <i className='mdi mdi-close text-secondary icons15'></i>)}
                        </> :
                        <i className='mdi mdi-close text-secondary icons15'></i>
                    }
                  </span>
                </>
              );
            }
              else{

                if(role=="company")
                return(<><button disabled={p.pCategory=="Gratuit"&&orders.length>0} onClick={()=>{AddToCart(p)}} className='btn btn-primary rounded-pill' >{p.pCategory=="Entreprise"? t("Contactez-nous") :(p.pCategory=="Gratuit"? t('Essayer') : t("Acheter") )}</button></>)
              }
            }}
          />
            </>)
          })
          }
        </Table>
        </ConfigProvider>
      </Panel>
    </Collapse>
    <br/>
        </>}
        </div>
          </>)
        }) }
        <ContactUsModal modalVisible={companyPackageModalVisible} setModalVisible={setCompanyPackageModalVisible} />
        <FreePackage package={freePackage} modalVisible={freePackageModalVisible} setModalVisible={setFreePackageModalVisible} />
   
    </div>
  )
}
