import React, { useContext, useState, useEffect } from 'react';
import { Modal, notification, Popover, Table } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AddResponsibleForm from './AddResponsibleForm';
import moment from 'moment-timezone';
import { DeleteResponsible } from '../../services/api/manageResponsible/DeleteResponsible';
import { GetResponsible } from '../../services/api/manageResponsible/GetResponsible';
import TrashIcon from '../icons/TrashIcon';
import EditIcon from '../icons/EditIcon';
import decrypt from '../../utils/decrypt';
import AsyncImage from '../AsyncImage';
import { withRouter,useHistory } from "react-router-dom";
import { AppContext } from '../../routes/AppContext';
import dateFromObjectId from '../../utils/dateFromObjectId';
import gif from "../../media/image/profil.gif";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';



const { Column } = Table;

const ManageResponsible = (props) => {
  const context = useContext(AppContext);
  const history = useHistory({});
  const { t } = useTranslation();

  const [access, setAccess] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [responsible, setResponsible] = useState([]);
  const [responsibleToUpdate, setResponsibleToUpdate] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const addResponsible = (newOne) => {
    setResponsible(prevResponsible => [...prevResponsible, newOne]);
  };

  const updateResponsible = (newOne) => {
    setResponsible(prevResponsible =>
      prevResponsible.map(r => (r._id === newOne._id ? newOne : r))
    );
  };

  const fetchData = async () => {
    const company = context.user;
    
    if (
      company.description === "" ||
      company.employeesNumber === "" ||
      company.employeesNumber === null ||
      company.sector === "" ||
      company.phone === "" ||
      company.address === ""
    ) {
      // Display error modal
      Modal.error({
        title: null,
        content: (
          <>
            <div className="d-flex justify-content-center">
              <AsyncImage className="w-100" src={gif} />
            </div>
            <h6 className="  text-dark rounded   ">
              {t("Vous devez renseigner votre profil pour bénéficier de nos services.")}
            </h6>
          </>
        ),
        onOk: () => {
          history.push("/company/profile");
        },
      });
    } else if (!company.confirmAdmin) {
      // Display error modal
      Modal.error({
        title: null,
        content: (
          <>
            <div className="d-flex justify-content-center">
              <AsyncImage className="w-100" src={gif} />
            </div>
            <h6 className="  text-dark rounded   ">
              {t("Vous devez attendre la confirmation admin pour pouvoir ajouter des responsables.")}
            </h6>
          </>
        ),
        onOk: () => {
          history.push("/company/dashboard");
        },
      });
    }
   
    else {
      setAccess(true);
      const response = await GetResponsible({}, context.headers);
      if (response) {
        const decryptedData = JSON.parse(decrypt(response.data));
        const updatedResponsible = decryptedData.map(r => ({
          ...r,
          creationDate: moment(dateFromObjectId(r._id)).format("DD/MM/YYYY")
        }));
        setResponsible(updatedResponsible);
        setLoading(false);
        if (!updatedResponsible.length) {
          handleVisibleChange(true);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const res = await DeleteResponsible({ id: id }, context.headers);
    if (res) {
      notification.success({
        description: res.data.message,
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#d4edda",
          marginTop: "5rem"
        },
      });

      setResponsible(prevResponsible =>
        prevResponsible.filter(responsible => responsible._id !== id)
      );
    }
  };
const handleAddResponsible=async()=>{
  let max =
  context.myFunctionalities.find(
    (functionality) => functionality.fType == 27
    )?.qte ?? 0;
    if(max==0){
      // Display error modal 
      //forfait
      Modal.error({
        title: null,
        content: (
          <>
            <div className="d-flex justify-content-center">
              <AsyncImage className="w-100" src={gif} />
            </div>
            <h6 className="  text-dark rounded   ">
            {t("Votre forfait ne permet pas d'ajouter un responsable actuellement. Consultez nos offres pour activer cette fonction.")}
            </h6>
          </>
        ),
        onOk: () => {
          history.push("/company/pricing");
        },
      });
  }
  else if(max>responsible.length){
    //open modal
    setModalVisible(true); 
  }
  else{
      // Display error modal 
      Modal.error({
        title: null,
        content: (
          <>
            <div className="d-flex justify-content-center">
              <AsyncImage className="w-100" src={gif} />
            </div>
            <h6 className="  text-dark rounded   ">
            {t("Votre forfait ne permet pas d'ajouter un responsable actuellement. Consultez nos offres pour activer cette fonction.")}
            </h6>
          </>
        ),
        onOk: () => {
          history.push("/company/pricing");
        },
      });
      // 
//max attendue
  }
}
  return (
    <div>
      <h3 className='titrePage'> {t("Liste des responsables")}</h3>

      {access && (
        <Popover
          content={<p> {t("Vous pouvez ajouter un responsable du recrutement en cliquant ici👌.")}
            <i>{t("Le responsable que vous ajoutez peut déposer des offres et gérer les candidatures.")}</i>
          </p>}
          title={t("Ajouter des responsables")}
          placement="bottom"
          visible={popoverVisible}
          onVisibleChange={handleVisibleChange}
        >
          <div onClick={handleAddResponsible} className="btn btn-primary rounded-pill m-2">
            <PlusOutlined className=" border  rounded-circle " style={{ fontSize: "1.4rem" }} />
            <b className="m-3 mobile">{t("Ajouter Responsable")}</b>
          </div>
        </Popover>
      )}
<ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
      <Table loading={loading} pagination={false} dataSource={responsible}>
        <Column sorter={(a, b) => a.name.localeCompare(b.name)} responsive={['lg']} title={t("Nom")} dataIndex="name" key="name" />
        <Column sorter={(a, b) => a.email.localeCompare(b.email)} title={t("Email")} dataIndex="email" key="email" />
        <Column sorter={(a, b) => a.jobTitle.localeCompare(b.jobTitle)} responsive={['lg']} title={t("Poste")} dataIndex="jobTitle" key="jobTitle" />
        <Column sorter={(a, b) => a.creationDate.localeCompare(b.creationDate)} responsive={['lg']} title={t("Date de création")} dataIndex="creationDate" key="creationDate" />
        <Column title={t("Actions")} dataIndex="Actions" key="Actions"
          render={(text, responsible) => (
            <div>
              <i className=" text-success m-1" role="button"
                onClick={() => {
                  setResponsibleToUpdate(responsible);
                  setModalVisible(true);
                }}
              >
                <EditIcon fill="#4caf50" width="1.8rem" />
              </i>
              <i className=" text-danger m-1" role="button"
                onClick={() => {
                  Modal.confirm({
                    title: t("Archiver responsable"),
                    icon: <ExclamationCircleOutlined />,
                    content: `${t("Voulez-vous archiver le responsable")} ${responsible.name}?`,
                    okText: t("Archiver"),
                    okType: "danger",
                    cancelText: t("Annuler"),
                    onOk: () => { handleDelete(responsible._id) },
                  });
                }}
              >
                <TrashIcon fill="#e25757" width="1.5rem" />
              </i>
            </div>
          )}
        />
      </Table>
</ConfigProvider>
      {modalVisible && (
        <AddResponsibleForm
          responsible={responsibleToUpdate}
          userId={props.userId}
          addResponsible={addResponsible}
          updateResponsible={updateResponsible}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )}
    </div>
  );
};

export default withRouter(ManageResponsible);
