import React from 'react';
import Section from './Section';
import Service from './components/Service/service';
import Feature from './components/Features/feature';
import Counter from './components/Counter/counter';
import Clients from './components/Clients/Clients';
import GetInTouch from './components/GetInTouch/GetInTouch';
import Subscribe from './components/Subscribe/Subscribe';
//Import Icon Css
import "./assets/css/materialdesignicons.min.css";
import "./assets/css/pe-icon-7.css";

// const Section = React.lazy(() => import('./Section'));
// const Service = React.lazy(() => import('./components/Service/service'));
// const About = React.lazy(() => import('./components/About/about'));
// const Feature = React.lazy(() => import('./components/Features/feature'));
// const Counter = React.lazy(() => import('./components/Counter/counter'));
// const Clients = React.lazy(() => import('./components/Clients/Clients'));
// const GetInTouch = React.lazy(() => import('./components/GetInTouch/GetInTouch'));
// const Subscribe = React.lazy(() => import('./components/Subscribe/Subscribe'));
// const Footer = React.lazy(() => import('./components/Footer/footer'));
class IndexHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        navItems : [
            { id: 1 , idnm : "home", navheading: "Home" },
            { id: 2 , idnm : "services", navheading: "Services" },
            { id: 3 , idnm : "about", navheading: "About" },
            { id: 4 , idnm : "features", navheading: "Features" },
            { id: 5 , idnm : "clients", navheading: "Clients" },
        ],
        // pos : document.documentElement.scrollTop,
        imglight : true,
        navClass : "navbar-light"
    };
}

componentDidMount() {
    // window.addEventListener("scroll", this.scrollNavigation, true);
}

componentWillUnmount(){
    // window.removeEventListener("scroll", this.scrollNavigation, true);
}

// scrollNavigation = () => {
//     let scrollup=document.documentElement.scrollTop;
//     if(scrollup > this.state.pos)
//     {
//         this.setState({navClass : "navbar-light nav-sticky", imglight : false});
//     }
//     else
//     {
//         this.setState({navClass : "navbar-light", imglight : true});
//     }
// };


render() {
    return (
        <React.Fragment>
{/*      
                {/* Importing section */}
                <Section/>

                {/* Importing service */}
                <Service/>

                {/* Importing about us */}  
                {/* <About/> */}

                {/* Importing feature */}
                <Feature/>

                {/* Importing counter */}
                <Counter isCompany={true} />

                {/* Importing clients */}
                {/* <Clients/> */}

                {/* Importing get in touch */}
                <GetInTouch/>

                {/* Importing subscribe */}
                {/* <Subscribe/> */}
                
              
     

        </React.Fragment>
    );
}
}
export default IndexHome;
