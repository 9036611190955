import { Card, notification, Rate, Select, Table, Modal, Divider, Space, Tag, Typography } from 'antd'

import { AddSkill } from '../../services/api/features/AddSkill';
import React, { Component } from 'react'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import PlusIcon from '../icons/PlusIcon';
import TrashIcon from '../icons/TrashIcon';
import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { WarningOutlined } from '@ant-design/icons';
import InfoIcon from '../icons/InfoIcon';
import 'antd/dist/antd.css';
import { withTranslation } from 'react-i18next';

const { Column } = Table;

const { Option } = Select;
class HardSkills extends Component {
  static contextType = AppContext;
  state = {
    score: 1,
    skill: "",
    key: null,
    update: false,
    error: "",
    name: "",
    firstTime: true,
    showAllSuggestion: false,
  };

  info = () => {
    this.setState({ firstTime: false });
    Modal.info({
      title: null,
      okText: this.props.t("ok"),
      content: (
        <div>
          <p>
            {this.props.t("Pour avoir plus de chances de trouver le bon candidat pour votre poste! Veuillez préciser les compétences professionnelles clés pour ce métier (maximum 5) La précision fait toute la qualité.")}
          </p>
        </div>
      ),

      onOk() {},
    });
  };

  onNameChange = (value) => {
    this.setState({ name: value });
  };
  setShowAllSuggestion = () => {
    this.setState({ showAllSuggestion:!this.state.showAllSuggestion });
  };
  addItem = async (e) => {
    e.preventDefault();
    if (this.state.name) {
      if (this.validation(this.state.name)) {
      let res = await AddSkill({ skill: this.state.name, feature: 0}, this.context.headers)
      if(res){
        let skill = {
          score: 0.2,
          skill: this.state.name,
          feature: 0,
        };
        let hardSkills = this.props.hardSkills;
        hardSkills.push(skill);
        this.props.setHardSkills(hardSkills);}
      }
    }
  };
  handleChange = (value) => {
    this.setState({ score: value });
  };
  handleSelect = (value, i) => {
    this.addSkill(value, i.key);
    // this.setState({ skill: value, key: i.key });
  };
  validation = (skill) => {
    let isvalid = true;
    let hardSkills = this.props.hardSkills;

    if (skill == "") {
      isvalid = false;
      notification.warn({
        description: this.props.t("veuillez choisir une compétence"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    } else if (hardSkills.length >= 5) {
      isvalid = false;
      notification.warn({
        description: this.props.t("vous avez atteindre le maximum des compétences possibles"),
        className: "custom-class",
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem",
        },
      });
    } else {
      for (let i = 0; i < hardSkills.length; i++) {
        const element = hardSkills[i];
        if (element.skill == skill) {
          isvalid = false;
          notification.warn({
            description: this.props.t("compétence déja utilisé"),
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor: "#fff3cd",
              marginTop: "5rem",
            },
          });
        }
      }
    }

    return isvalid;
  };
  addSkillFromTags = (skill) => {
    if (this.validation(skill.skill)) {
      // let skillToAdd= this.context.hardSkillsList.find(s=>s.skill==skill)
      // if(skillToAdd){
      //   skillToAdd.score=0.2
      let hardSkills = this.props.hardSkills;
      hardSkills.push(skill);
      this.props.setHardSkills(hardSkills);
      // }
    }
  };

  addSkill = (s, key) => {
    let skill = this.context.hardSkillsList[key];
    if (this.validation(skill.skill)) {
      skill.score = 0.2;
      let hardSkills = this.props.hardSkills;
      hardSkills.push(skill);
      this.props.setHardSkills(hardSkills);
    }
  };
  updateScore = (index, value) => {
    let hardSkills = this.props.hardSkills;
    hardSkills[index].score = value / 5;
    this.props.setHardSkills(hardSkills);
  };
  render() {
    const { t } = this.props;

    let i = -1;
    let list = this.props.hardSkills.map((skill) => {
      i++;
      let code = skill.code;
      let index = i;

      return (
        <div
          key={i}
          className="p-2 m-3 border row rounded d-flex align-items-center "
        >
          <div className="col-4 text-center capitalize">{skill.skill}</div>
          <div className="col-6 text-center">
            <Rate
              allowHalf
              onChange={(value) => this.updateScore(index, value)}
              value={skill.score * 5}
            />
          </div>
          <div className="col-2 text-center ">
            <i
              title={this.props.t("Supprimer")}
              className=" text-danger m-1"
              role="button"
              onClick={() => {
                Modal.confirm({
                  title: "Supprimer compétence",
                  icon: <ExclamationCircleOutlined />,
                  content: t("voulez vous supprimer la compétence")+ " " + skill.skill,
                  okText: "Supprimer",
                  okType: "danger",
                  cancelText: "Annuler",
                  onOk: () => {
                    this.props.setHardSkills(
                      this.props.hardSkills.filter((item) => {
                        return item.skill !== skill.skill;
                      })
                    );
                    this.setState({ update: !this.state.update });
                  },
                });
              }}
            >
              <TrashIcon fill="red" width="1.5rem" />
            </i>
          </div>
        </div>
      );
    });

    const children = [];
    let hardSkillsList = this.context.hardSkillsList;
    for (let i = 0; i < hardSkillsList.length; i++) {
      children.push(
        <Option key={i} value={hardSkillsList[i].similar_skill}>
          <div className="capitalize">{hardSkillsList[i].similar_skill}</div>
        </Option>
      );
    }
    let suggestedSkills=this.props.suggestedSkills
    suggestedSkills.sort((a, b) => b.occ - a.occ);
    if(!this.state.showAllSuggestion)suggestedSkills= suggestedSkills.slice(0, 10)
    suggestedSkills= suggestedSkills.map((skill) => {
      return (
        <span
          role="button"
          onClick={() => {
            this.addSkillFromTags(skill);
          }}
        >
          {" "}
          <Tag
            className="capitalize"
            color={
              skill.occ == 0
                ? "geekblue"
                : skill.occ == 1
                ? "cyan"
                : "magenta"
            }
          >
            {skill.skill}
          </Tag>
        </span>
      );
    })
    return (
      <div>
        {/* <h4 className="text-primary" > Compétences techniques </h4> */}
        {/* <b> - Compétences techniques </b> */}

        {this.props.suggestedSkills.length != 0 && (
          <>
            <br />{" "}
            <Card
              style={{
                maxHeight: "10rem",
                overflowY: "auto",
              }}
            >
              {suggestedSkills}
         { this.props.suggestedSkills.length >10 &&     <div className="d-flex justify-content-center" >
              <button  class="btn btn-primary" onClick={this.setShowAllSuggestion} >
                {t("Voir Plus..")}
          </button>
              </div>}

            </Card>
          </>
        )}
        <div className=" m-3">
          <Select
            className=" w-50"
            onFocus={() => {
              if (this.props.hardSkills.length == 0 && this.state.firstTime)
                this.info();
            }}
            showSearch
            placeholder={t("Rechercher pour sélectionner")}
            onChange={this.handleSelect}
            onSearch={this.onNameChange}
            notFoundContent={
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Typography.Link
                    onClick={this.addItem}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <PlusOutlined /> {t("Ajouter")}
                  </Typography.Link>
                </Space>
              </>
            }
          >
            {children}
          </Select>
          <span onClick={this.info} role="button">
            <InfoIcon className="zoom1 m-1" fill="blue" width="1.4rem" />{" "}
          </span>

          {/* <span className="m-3" >
            <Rate allowHalf onChange={this.handleChange} value={this.state.score} defaultValue={1} />
          </span> */}
          {/* <span className="m-3">
            <button
              onClick={this.addSkill}
              style={{ borderRadius: "50%", width: "35px", height: "35px" }}
              className='btn btn-primary  p-0 m-2 '>
              <i className="">
                <PlusIcon fill="white" width="1.5rem" />
              </i>
            </button>
          </span> */}
        </div>
        <div>
          <div className="mx-5">{list}</div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(HardSkills)
