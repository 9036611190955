import React, { Component } from 'react'
import { Timeline} from 'antd';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CvCercle from '../template/cv-cercle/CvCercle';
import { withTranslation } from 'react-i18next';
class ProjectsTwo extends Component {
  render() {
    const { t } = this.props;
    let i=-1
    let list=this?.props?.projects?.map(project=>{
        i++
    let months=[t("Janvier"),t("Février"),t("Mars"),t("Avril"),t("May"),t("Juin"),t("Juillet"),t("Aout"),t("Septembre"),t("Octobre"),t("Novembre"),t("Décembre")]

        let startDate=new Date(project.startDate)
        let date2 =new Date(project.endDate)
        let endDate=project.endDate? months[date2.getMonth()]+date2.getFullYear():t("Présent")
       let dateText=months[startDate.getMonth()]+startDate.getFullYear()+" - "+endDate
      return(
            <Timeline.Item dot={<CvCercle/>}  key={i} >
               <div className="cvt-f-t">
                    <div className="cvt-f-t-top">
                        <div className="cvt-f-t-top-l">
                            <div className="cvt-f-t-name">
                            {project.title}
                            </div>
                        </div>

                    </div>
                    <div className="cvt-f-t-top-time">
                        <div className="cvt-f-t-top-r">
                        {dateText}
                        </div>
                    </div>

                    <div className="cvt-f-t-b">
                    {project.description}
                    </div>
               </div>
            </Timeline.Item>
      )
    }) 
    return (
        <>
            {list.length>0&&(
            <div className="cvt-formation theme-two">
                <div className="cvt-f-top">
                    <div className="cvt-f-img">
                        <AccountTreeIcon   className='cert-icon-cont'  />
                    </div>
                    <div className="cvt-f-name">{t("Projets académiques")} :</div>
                </div>
                <Timeline style={{marginLeft:"25px"}}>
                    {list}
                </Timeline>
            </div>
        )}
        </>
    )
  }
}
export default withTranslation()(ProjectsTwo)