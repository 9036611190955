import React, { useContext, useState } from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AppContext } from "../../routes/AppContext";
import gif from "../../media/image/useMatching.gif";
import AsyncImage from "../AsyncImage";
import { useTranslation } from "react-i18next";

const UseMatchingConfirm = (props) => {
  const { t } = useTranslation();
  
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div>
      <Modal
        title={<h5 className="m-1">{t("Toujours pas de candidatures?")}</h5>}
        className=""
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined className="m-3" />
          </i>
        }
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={
          <>
            <input
              type="button"
              className=" btn btn-secondary p-1 rounded m-1"
              value="Ignorer"
              onClick={() => setModalVisible(false)}
            />
            <input
              type="button"
              className=" btn btn-primary p-1 rounded m-1"
              value="Ok"
              onClick={() => {
                setModalVisible(false);
              }}
            />
          </>
        }
      >
        <div className="d-flex justify-content-center">
          <AsyncImage className="w-100" src={gif} />
        </div>
        {t(
          "Embauchez plus rapidement et trouvez le candidat idéal grâce à notre solution de matching"
        )}
      </Modal>
    </div>
  );
};

export default UseMatchingConfirm;
