import React, { Component } from "react";
import { Link } from "react-router-dom";
import CandidateForgotPassword from "../forgotPassword/CandidateForgotPassword";
import { candidateSignin } from "../../services/api/signin/candidateSignin";
import { Container, Row, Col, Label, Button, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import InfoIcon from "../icons/InfoIcon";
import { Input, notification } from "antd";
import Loading from "../Loading";

import { withTranslation } from "react-i18next";
import { AppContext } from "../../routes/AppContext";
// import { notify } from "react-notify-toast";

// import Spinner from '../email/Spinner'
// import { API_URL } from '../../config'
// import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody,  MDBModalFooter } from 'mdbreact';

class CandidateSignin extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      sending: false,
      modalVisible: false,
      passwordError: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  onChange = (value) => {
    this.setState({ sector: value });
  };
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  validation = () => {
    let isvalid = true;
    let email = this.state.email;
    let password = this.state.password;
    //email validation
    if (email == "") {
      isvalid = false;
      this.setState({ emailError: this.props.t("champ obligatoire")});
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      isvalid = false;
      this.setState({ emailError: this.props.t("E-mail invalide")});
    } else this.setState({ emailError: "" });

    //password validation
    if (password == "") {
      isvalid = false;
      this.setState({ passwordError: this.props.t("champ obligatoire")});
    } else this.setState({ passwordError: "" });
    return isvalid;
  };
  handleSubmit(event) {
    event.preventDefault();
    if (this.validation()) {
      this.setState({ sending: true });
      setTimeout(async () => {
        let response = await candidateSignin({
          
          email: this.state.email.toLowerCase(),
          password: this.state.password,
        },this.context.headers);
        this.setState({ password: "" });
        if (response.status==200) {
          // localStorage.clear();
          sessionStorage.clear();
          await localStorage.setItem("token", response.data.token);
          //notification
          notification.success({
            description: this.props.t(response.data.code),
            className: "custom-class mt-5",
            style: {
              width: 600,
              backgroundColor: "#d4edda",
              marginTop: "5rem",
            },
          });
          window.location.replace("/candidate/dashboard");
        } else this.setState({ sending: false });
      }, 1000);
    }
  }

  render = () => {
    const { sending } = this.state;
    return (
      <>
        {sending ? (
          <div style={{ height: "80vh" }} className="m-5 p-5">
            <Loading />
          </div>
        ) : (
          <div className="company-signin  mt-5">
            <Container className="mt-5">
              <Row>
                <Col
                  lg={{ size: 4 }}
                  md={{ size: 5 }}
                  sm={{ size: 6 }}
                  className="section1"
                >
                  <div className="p-4">
                    <div className="mx-6">
                      <div className="text-center mb-4">
                        <h3 className="text-primary mb-3 switch__title ">
                          <strong>{this.props.t("Content de votre retour")}! 👋 </strong>

                          <hr />
                        </h3>
                      </div>
                      <p className="service-title text-dark font-weight-normal pt-1 mb-4">
                      {this.props.t("Rec-INOV plateforme...")} 
                      </p>
                      <p className="service-title text-dark font-weight-normal pt-1 mt-1 mb-4">
                      {this.props.t("Oubliez les recherches...")}
                      
                     
                      </p>
                      <div className="text-center">
                        <Link to={"/signup/candidate"}>
                          <button className=" btn1 ">{this.props.t("S'inscrire")}   </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={{ size: 7, offset: 1 }}
                  md={{ size: 5, offset: 1 }}
                  sm={{ size: 6, offset: 0 }}
                  className="section2"
                >
                  <div className="text-center custom-form mt-4 mt-lg-0">
                    <div id="message"></div>
                    <AvForm
                      onSubmit={this.handleSubmit}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col lg={12}>
                          <div className="text-center mb-4">
                            <h2 className="text-primary mb-3 text-uppercase">
                              <strong>{this.props.t("Login candidat")} </strong>
                            </h2>

                            <hr />
                          </div>
                          <FormGroup className="app-label">
                            <Label for="email">
                            {this.props.t("Adresse Email")} *
                            </Label>
                            <AvField
                              name="email"
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder={this.props.t("Votre E-mail...")} 
                              errorMessage={this.props.t("E-mail invalide")} 
                              validate={{ required: { value: true } }}
                              value={this.state.email}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="app-label">
                            <Label for="password">
                        {this.props.t("Votre mot de passe")}  *
                            </Label>
                            <Input.Password
                              className=""
                              placeholder= {this.props.t("Votre mot de passe...")} 
                              value={this.state.password}
                              onChange={this.handleChange}
                              name="password"
                              id="mdp"
                            />
                            {/* <AvField
                          name="password"
                          id="mdp"
                          type="password"
                          className="form-control"
                          placeholder="Votre mot de passe..."
                          errorMessage="Entrez Votre mot de passe..."
                          validate={{
                            required: { value: true }
                          }}
                          value={this.state.password}
                          onChange={this.handleChange}
                          
                        /> */}
                            <p className="text-danger">
                              <small>{this.state.passwordError}</small>
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg={12}>
                          <Col sm={12}>
                            <Button
                              type="submit"
                              id="submit"
                              name="send"
                              className="btn1"
                            >
                            {this.props.t("Login")}  
                            </Button>
                
                          </Col>
                        </Col>
                      </Row>
                    </AvForm>
                    <div className="mt-0 text-center">
                      <div className="navbar-text ">
                        <a onClick={() => this.setModalVisible(true)} href="#">

                  {this.props.t("Mot de passe oublié?")}  
                          {" "}
                        </a>
                      </div>
                      {/* <div className="visible">
                    Vous n'avez pas de compte ?{" "}
                    <Link to="/signup/candidate">
                      <span className="navbar-text ">S'inscrire </span>
                    </Link>
                  </div> */}
                    </div>
                    <hr/> {this.props.t("Se connecter avec")}  
                  <div className="socialLogin" >
                    <div className="btn btn-primary rounded-circle hoverShadow   m-1" > <a target="_blank" href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&redirect_uri=https%3A%2F%2Frec-inov.com%2Fapi%2Fsignup%2Flinkedin%2Fcallback%2Fcandidate&scope=r_emailaddress%20r_liteprofile&client_id=77ogiqbkfqv1wu">
                 <i className="mdi mdi-linkedin text-light icons12" ></i>
                        </a></div>
                        <div className=" btn btn-primary rounded-circle hoverShadow  m-1" > <a target="_blank" href="https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Frec-inov.com%2Fapi%2Fsignup%2Fgoogle%2Fcallback%2Fcandidate&scope=profile%20email&client_id=81688516951-jb145kmqo96vrs4haq4ekdf0bkc952b7.apps.googleusercontent.com&service=lso&o2v=2&flowName=GeneralOAuthFlow">
                 <i className="mdi mdi-google text-light icons12" ></i>
                        </a></div>
                        <div className="btn btn-primary rounded-circle hoverShadow  m-1" > <a target="_blank" href="https://www.facebook.com/v3.2/dialog/oauth?response_type=code&redirect_uri=https%3A%2F%2Frec-inov.com%2Fapi%2Fsignup%2Ffacebook%2Fcallback%2Fcandidate&scope=public_profile%2Cemail&client_id=1196012994652694">
                 <i className="mdi mdi-facebook text-light icons12" ></i>
                        </a></div>
                  </div>
                    <CandidateForgotPassword
                      modalVisible={this.state.modalVisible}
                      setModalVisible={this.setModalVisible}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  };
}
export default withTranslation()(CandidateSignin);