import { Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { WarningOutlined} from '@ant-design/icons';
import gif from "../../media/image/skill.gif"
import AsyncImage from '../AsyncImage';
import InfoIcon from '../icons/InfoIcon';
import {  useTranslation } from "react-i18next";
export default function DragDropComponent(props) {
  var { t,i18n } = useTranslation();
  const languages = { "fr": "language_fr", "en": "language_en" }
  const softSkills = { "fr": "soft_skill_fr", "en": "soft_skill_en" }
  const language = languages[i18n.language]
  const softSkill = softSkills[i18n.language]
  useEffect(()=>{
info()
   
  },[])
const  info = () => {
  Modal.info({
    title: null,
    content: (
      <div>
        <AsyncImage 
 
 className="w-100"
   src={gif} />
        <p> {("Pour avoir plus de chances à raccrocher votre emploi idéal.Veuillez sélectionner jusqu'à 5 compétences  qui vous correspondent le mieux et que vous excellez le plus. (min 1 / max 5)")}</p>
      </div>
    ),
    
    onOk() {},
  });
  };
  const columnsFromBackend = { 
    0: {
      name: "En attente",
      items: []
    },
    
   1: {
      name: "Test",
      items: []
    },
  
  };


  let i=-1
  for (let i = 0; i < 2; i++) {
      columnsFromBackend[i].items=[]
      
  }
          let list= props.skills.map((skill)=>{
              i++
               
              columnsFromBackend[0].items.push({id:skill.code,content:
                
                <div key={i} skill={skill} role="button" className="text-center   "
                //  style={{width:200}}
                 >
                    <div className=" ">{props.feature==0?skill.skill:(props.feature==1?skill[softSkill]:(props.feature==2?skill[language]:""))}</div>
                </div>
            })
             
             
              return(<div key={i}>
                    <div role="button" className="text-center " 
                    // style={{width:200}}
                    >
                    <div className=" ">{props.feature==0?skill.skill:(props.feature==1?skill[softSkill]:(props.feature==2?skill[language]:""))}</div>
                </div>
              </div>)});
    const [columns, setColumns] = useState(columnsFromBackend);
    const [modalVisible, setModalVisible] = useState(false);

   const onDragEnd = (result, columns, setColumns,props) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (destination.droppableId!=source.droppableId) {

      if(columns[1].items.length==5&&destination.droppableId==1){
//notif
notification.warn({
  description:t("veuillez choisir 5 compétences maximum"),
  className: 'custom-class',
  style: {
    width: 600,
    backgroundColor:"#fff3cd",
    marginTop:"5rem"
  },
})
      }
else
  {  const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });}
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
};
const handleDoubleClick=(column,index)=>{
  let newColumns={...columns}
  if(column==0){
    if(columns[1].items.length==5){
      //notif
      notification.warn({
        description:t("veuillez choisir 5 compétences maximum"),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor:"#fff3cd",
          marginTop:"5rem"
        },
      })
            }
            else{
                newColumns[1].items.push(newColumns[0].items[index])
     newColumns[0].items.splice(index, 1);
            }
  }
  else{
    newColumns[0].items.push(newColumns[1].items[index])
    newColumns[1].items.splice(index, 1);
  }
  setColumns(newColumns)
}
      const Confirm=(event)=>{
        event.preventDefault();
        let skills=[]
           columns[1].items.map((item)=>{
                 skills.push(item.content.props.skill)
           })
           props.updateSkills(skills)
        }

    return (
      <> 
      <div className='d-flex justify-content-end' >

<span onClick={info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>
      </div>
      <div className='text-center d-flex justify-content-lg-center ' 
      // style={{ display: "flex", alignItems:"center", height: "100%" }}
      >

        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns,props)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  
                }}
                key={columnId}
              >
                {/* <b className="text-primary ">{column.name}</b> */}
                <div 
                style={{ margin:"0 8px 0 8px" }}
                >
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      // let cId=columnId
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              :(columnId=="0")? "#eae6e647":"white",
                            padding: 4,
                            width: columnId=="0"?"30rem":"10rem",
                            // width: "30rem",
                            minHeight: "20rem"
                          }}
                          className={columnId=="0"?"d-flex flex-wrap overflow-y-50":"border"}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                    onDoubleClick={()=>handleDoubleClick(columnId,index,item,provided,snapshot)}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="rounded  m-2 border border-primary d-flex align-items-center"
                                      style={{
                                        userSelect: "none",
                                        padding: "2px 25px 6px 25px",
                                        // margin: "2px 0 2px 0",
                                        minHeight: "35px",
                                        // maxHeight:"60px",
                                        backgroundColor: snapshot.isDragging
                                          ? "rgb(208 232 244)"
                                          :(columnId=="0"? "#FFF":"#adc6ff"),
                                        color: "#1d39c4",
                                     background: "#f0f5ff",
                                        ...provided.draggableProps.style
                                      }}
                                    >
                                      {item.content}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
</div>
       
                    <div  className="text-center m-3">
                        <button
                        className=" btn btn-primary "
                        //style={{position:'fixed',top:"80%",left:"30%"}}
                        // style={{position:'fixed',top:"35rem",left:"75rem",width:"7rem"}}
                        name="send"
                        onClick={Confirm}
                         >{t("Enregistrer")}</button></div>
                  

      </>
    );
  }
