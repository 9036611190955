import { Menu } from 'antd'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../routes/AppContext';

export default function BlogNavbar(props) {
  const { role} = useContext(AppContext);
  const history = useHistory({});

 const RedirectMe = (e) => {
    props.setFilter(e.key)
    props.setPage(1)
    // history.push("/"+role+"/blog/"+e.key);
  };
  return (
    <div>
            <Menu
                 mode="horizontal"
                // defaultSelectedKeys={["1"]}
                // defaultOpenKeys={["1"]}
                onClick={(e) =>RedirectMe(e)}
                 className=""
              >
                     <Menu.Item key="all" >
                  <div>
                    Tout
                  </div>
                </Menu.Item>
                {props.blogs.map((blog,i)=>{
                    return(
                        <Menu.Item key={blog} >
                        <div>
                          {blog}
                        </div>
                      </Menu.Item>  
                    )
                })}
          
                </Menu>
    </div>
  )
}
