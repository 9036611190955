import axios from "../../../interceptors/axios"
export const contactUs = async (data,headers) => {
    try {
        let res=await  axios.post('/api/contactUs/send',data, {
          headers: headers,
        },{ withCredentials: true })
        return res
} catch (err) {
    }
  }
