import React, { useContext, useEffect, useState } from 'react'
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Steps } from 'antd';
import { GetCandidateTimeline } from '../../services/api/interview/GetCandidateTimeline';
import { AppContext } from '../../routes/AppContext';
import decrypt from '../../utils/decrypt';

import moment from 'moment';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const { Step } = Steps;

export default function TimelineModal(props) {
  const { t } = useTranslation();

  const { userId,headers,serverTime } = useContext(AppContext);
    const getCandidateTimeline= async()=>{
       let response=await GetCandidateTimeline(
      { idJobPost: props?.idJobPost,idCandidate:props?.candidate._id },
      headers
    );
    if(response){
    let data = JSON.parse(decrypt(response.data));
    return(data)
  }
    }
    const { data, isLoading, isError } = useQuery(
      ['timeline',props?.idJobPost,props?.candidate._id ], // Query key
      getCandidateTimeline
    );
    const interviews=data?.length?data:[]
    let current=0
    let list =interviews.map((interview,index)=>{
      if(moment(serverTime).isBefore(moment(interview.date))) current=index
    })
  return (
    <div>
          <Modal
                    title={
                      <h5 className=" m-1 text-primary">
                        <b>{t("Historique des entretiens")}</b>
                      </h5>
                    }
                    className="m-5"
                    centered
                    closeIcon={
                      <i className="mt-5 ">
                        <CloseOutlined
                          // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                          className="m-3"
                        />
                      </i>
                    }
                    visible={props.modalVisible}
                    onOk={() => props.setModalVisible(false)}
                    onCancel={() => props.setModalVisible(false)}
                    footer={<></>}
                  >
                    <div 
        // className='d-flex justify-content center h-100'
        >
              <Steps  progressDot direction="vertical"  className="mb-5" 
              current={current}
               >
   {           interviews.map((interview,index)=>{
    let title=interview.idTest?(t("Test technique")+"(score:"+interview.score+"/100)"):(t("Entretien visio")+(interview.archived?t("(retardé)"):""))
    return(<>
    <Step status="" description={moment(interview.date).format("L")} title={title}  />
  </>)})}
                </Steps></div>
                  </Modal>
    </div>
  )
}
