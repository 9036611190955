import axios from "axios";
import React, { Component } from "react";
// import NavbarEntreprise from '../NavbarEntreprise';

import gif from "../../media/image/candidatures.gif";
import Candidatures from "./Candidatures";
import Description from "./Description";
import { withRouter } from "react-router-dom";
import { GetApplications } from "../../services/api/application/GetApplications";
import decrypt from "../../utils/decrypt";
import UseMatchingConfirm from "./UseMatchingConfirm";
import { Input, notification, Select, Spin } from "antd";
import AsyncImage from "../AsyncImage";
import TagResponsibleForm from "./tagResponsible/TagResponsibleForm";

import { JobPostContext } from "../dashboard/jobPostDashboard/JobPostContext";
import { withTranslation } from "react-i18next";
const { Option } = Select;

// import { ToastContainer, toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';
class JobApplications extends Component {
  static contextType = JobPostContext;
  constructor(props,context) {
    super(props);
    context.jobPost._id
      ? console.log()
      : this.props.history.push("/company/manageJobPosts");
    // applications =[]
    this.ref = React.createRef();
    this.state = {
      applications: [],
      filteredApplications: [],
      sorter:0,
      filterInput:"",
      interviews: [],
      id: context.jobPost._id,
      title: context.jobPost.title,
      startMissionDate: context.jobPost.startMissionDate,
      // id:this.props.location.state._id
      isScrolling: false,
      clientX: 0,
      scrollX: 0,
      modalVisible: false,
      isLoading:true
    };
  }
  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  onMouseDown = (e) => {
    this.setState({ ...this.state, isScrolling: true, clientX: e.clientX });
  };

  onMouseUp = () => {
    this.setState({ ...this.state, isScrolling: false });
  };

  onMouseMove = (e) => {
    const { clientX, scrollX } = this.state;
    if (this.state.isScrolling) {
      this.ref.current.scrollLeft = scrollX + e.clientX - clientX;
      this.state.scrollX = scrollX + e.clientX - clientX;
      this.state.clientX = e.clientX;
    }
  };
  setSorter = (value) => {
    let filteredApplications
    if(value==0){
      filteredApplications=this.state.applications
    }
    else if(value==1){
      filteredApplications=this.state.applications.sort((a,b)=>b.score-a.score)
    }
    else if(value==2){
      filteredApplications=this.state.applications.sort((a,b)=>a.candidate[0].name.localeCompare(b.candidate[0].name))
    }
    this.setState({ sorter: value,filteredApplications:filteredApplications });

  };
  async componentDidMount() {
    let response = await GetApplications(
      { id: this.state.id },
      this.props.headers
    );
    if(response){
    let data = JSON.parse(decrypt(response.data));
    if (data.length == 0) {
      this.setModalVisible(true);
    } else {
      notification.open({
        placement: "top",
        duration: 5,
        description: (
          <>
            <AsyncImage src={gif} />
          </>
        ),
        className: "custom-class",
        style: {
          width: 440,
          height: 130,
          marginTop: "2rem",
        },
      });
    }
    this.context.setApplications(data[0].applications)
    this.context.setNonFixedInterviews(data[0].applications.filter(a=>a.state==1.5))
    this.context.setDates(data[0].interviews)
    let applications=data[0].applications.reverse()
    applications.map(a=>{if(a.state==1.5)a.state=2;return a})
    this.setState({
      applications: applications,
      filteredApplications: applications,
      interviews: data[0].interviews,
      isLoading:false
    });}
  }
  render() {
    //  let offre=this.props.location.state

    return (
      <div className="row " id="scroller">
        <Spin  spinning={this.state.isLoading}>
        <div className="col-sm-12 col-12 rounded shadow">
          <h6 className=" titre-table"> {this.props.t("Gérer les Candidatures")} </h6>
          <div
            ref={this.ref}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
            onMouseMove={this.onMouseMove}
            id="draggingSpace"
            style={{ overflowX: "auto" }}
          >
            {/* filter and sorter */}
            {/* <div className="row px-1  my-2">
              <div  className="col-md-6 col-sm-12" >
                <Input
                  className="m-1 "
                  placeholder="Rechercher un candidat.."
                  value={this.state.filterInput}
                  onChange={(e) => {
                    const currValue = e.target.value;
                    this.setState({ filterInput: currValue });
                  }}
                />
              </div>
              <div className="col-md-6 col-sm-12" >
              <Select
                // style={{ width: 200 }}
                placeholder="Selectionner.."
                className="m-1 w-100"
                // style={{width:"100%"}}
                onChange={this.setSorter}
                defaultValue={this.state.sorter}
              >
                <Option value={0}>Trier par date</Option>
                <Option value={1}>Trier par score</Option>
                <Option value={2}>Trier par nom</Option>
              </Select>
              </div>
            </div> */}
            <Candidatures
              title={this.state.title}
              startMissionDate={this.state.startMissionDate}
              userId={this.props.userId}
              id={this.state.id}
              candidatures={this.state.filteredApplications}
              interviews={this.state.interviews}
              vacancies={this?.context.jobPost.vacancies}
            />
          </div>
          <UseMatchingConfirm
            setPage={this.props.setPage}
            jobPost={this?.context.jobPost}
            modalVisible={this.state.modalVisible}
            setModalVisible={this.setModalVisible}
          />
        </div>
        </Spin>
      </div>
    );
  }
}
export default withTranslation()(withRouter(JobApplications));
