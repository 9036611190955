import React, { Component } from "react";
import { Link } from "react-router-dom";
import CompanyForgotPassword from "../forgotPassword/CompanyForgotPassword";
import { companySignin } from "../../services/api/signin/companySignin";
import { Container, Row, Col, Label, Button, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Input, notification } from "antd";
import Loading from "../Loading";

import { AppContext } from "../../routes/AppContext";


import { withTranslation } from "react-i18next";
class CompanySignin extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      passwordError:"",
      sending:false,
      modalVisible: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  onChange = (value) => {
    this.setState({ sector: value });
  };
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  validation = () => {
    let isvalid = true;
    let email = this.state.email;
    let password = this.state.password;
    //email validation
    if (email == "") {
      isvalid = false;
      this.setState({ emailError: this.props.t("champ obligatoire")});
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      isvalid = false;
      this.setState({ emailError: "email non valide" });
    } else this.setState({ emailError: "" });

    //password validation
    if (password == "") {
      isvalid = false;
      this.setState({ emailError: this.props.t("champ obligatoire")});
    } else this.setState({ passwordError: "" });
    return isvalid;
  };
  handleSubmit(event) {
    event.preventDefault();

    if (this.validation()) {
      this.setState({sending:true})
      setTimeout(async()=>{
     let response=  await companySignin({
         
         email: this.state.email.toLowerCase(),
         password: this.state.password,
       },this.context.headers);
       this.setState({password: ""})
       if(response?.data?.token)
       {     
        //  localStorage.clear();
         sessionStorage.clear()
         localStorage.setItem('token',response.data.token)
         //notification
         notification.success({
           description:this.props.t(response.data.code),
           className: 'custom-class mt-5',
           style: {
            width: 600,
            backgroundColor:"#d4edda",
            marginTop:"5rem"
          },
         });
           window.location.replace('/company/dashboard');}
           else{this.setState({sending:false})}
      },1000)

    }
  }

  render = () => {
    const { sending } = this.state;
    return (
      <>
      {  sending?<div style={{height:"80vh"}} className="m-5 p-5" ><Loading/></div>:
      <div className="company-signin  mt-5">
        <Container className="mt-5">
          <Row>
            <Col
              lg={{ size: 4 }}
              md={{ size: 5 }}
              sm={{ size: 6 }}
              className="section1"
            >
              <div className="p-4">
                <div className="mx-6">
                  <div className="text-center mb-4">
                    <h3 className="text-primary mb-3 switch__title ">
                          <strong>{this.props.t("L'avenir du recrutement commence aujourd'hui")}! 👋 </strong>
                      <hr />
                    </h3>
                  </div>
                  <p className="service-title text-dark font-weight-normal pt-1 mb-4">
                  {this.props.t("Redéfinissez...")} 
                  </p>
                  <div className="text-center" >
                
                  <Link to={"/signup/company"}>
                          <button className=" btn1 ">{this.props.t("S'inscrire")}   </button>
                  </Link></div>
                </div>
              </div>
            </Col>
            <Col
             lg={{ size: 7, offset: 1 }}
             md={{ size: 5, offset: 1 }}
             sm={{ size: 6, offset: 0 }}
              className="section2"
            >
              <div className="text-center custom-form mt-4 mt-lg-0">
                <div id="message"></div>
                <AvForm
                  onSubmit={this.handleSubmit}
                  name="contact-form"
                  id="contact-form"
                >
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mb-4">
                        <h2 className="text-primary mb-3 text-uppercase">
                              <strong>{this.props.t("Login Entreprise")} </strong>
                          <hr />
                        </h2>

                        
                      </div>
                      <FormGroup className="app-label">
                        <Label for="email">
                            {this.props.t("Adresse Email")} *</Label>
                        <AvField
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder={this.props.t("Votre E-mail...")} 
                          errorMessage={this.props.t("E-mail invalide")} 
                          validate={{ required: { value: true } }}
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <FormGroup className="app-label">
                        <Label for="password">
                        {this.props.t("Votre mot de passe")}  *</Label>
                        <Input.Password
                         className=""
                         placeholder= {this.props.t("Votre mot de passe...")} 
                         value={this.state.password}
                          onChange={this.handleChange}
                          name="password"
                          id="mdp"
                         />
                          <p className="text-danger">
                          <small>{this.state.passwordError}</small>
                        </p>
                      </FormGroup>
                    </Col>

                    <Col lg={12}>
                      <Col sm={12}>
                        <Button
                          type="submit"
                          id="submit"
                          name="send"
                         
                          className="btn1"
                        >
                        {this.props.t("Login")}  
                        </Button>
                   
                      </Col>
                    </Col>
                  </Row>
                </AvForm>
                <div className="mt-0 text-center">
                  <div className="navbar-text ">
                    <a onClick={() => this.setModalVisible(true)} href="#">
                    {this.props.t("Mot de passe oublié?")}  
                          {" "}
                    </a>
                  </div>

                </div>
                <CompanyForgotPassword
                  modalVisible={this.state.modalVisible}
                  setModalVisible={this.setModalVisible}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>}</>
    );
  };
}

export default withTranslation()(CompanySignin);
