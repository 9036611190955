import React, { Component } from "react";
import { DatePicker, Modal, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { AppContext } from "../../../routes/AppContext";

import { withTranslation } from 'react-i18next';
const { Option } = Select;

 class EducationForm extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    let education = this.props.education
      ? this.props.education
      : {};

    this.state = {
      diploma: education.diploma ? education.diploma : "",
      school: education.school ? education.school : "",
      startDate: education.startDate ? education.startDate : null,
      endDate: education.endDate ? education.endDate : null,
      currently:
        (education.startDate != null) & (education.endDate == null)
          ? true
          : false,

      //  schoolsList:context.schoolsList,
      diplomaError: "",
      schoolError: "",
      startDateError: "",
      endDateError: "",
    };
  }
  handleSelect = (value, i) => {
    let str = `${value}`;
    let list = str.split(",");
    this.setState({ school: list[list.length - 1] });
  };
  onNameChange = (value) => {
    this.setState({ school: value });
  };
  disabledDate = (current) => {
    return current > moment(this.context.serverTime);
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  validation = () => {
    let isvalid = true;
    let diploma = this.state.diploma;
    let school = this.state.school;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let currently = this.state.currently;

    //diploma validation
    if (diploma == "") {
      isvalid = false;
      this.setState({titleError:this.props.t("champ obligatoire")})
    } else this.setState({ diplomaError: "" });

    //school validation
    if (school == "") {
      isvalid = false;
      this.setState({titleError:this.props.t("champ obligatoire")})
    } else this.setState({ schoolError: "" });

    //startDate validation
    if (startDate == null) {
      isvalid = false;
      this.setState({titleError:this.props.t("champ obligatoire")})
    } else this.setState({ startDateError: "" });

    //endDate validation
    if (!currently && startDate !== null) {
      if (endDate == null) {
        isvalid = false;
        this.setState({titleError:this.props.t("champ obligatoire")})
      } else if (moment(endDate).isBefore(moment(startDate))) {
        isvalid = false;
        this.setState({
          endDateError: this.props.t("la date de fin doit étre apres la date de début"),
        });
      } else this.setState({ endDateError: "" });
    }

    return isvalid;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validation()) {
      let cv = this.context.user
      let postedObject
      if (this.props.index != null) {
         postedObject=cv.educations[this.props.index]
        postedObject.diploma=this.state.diploma
        postedObject.school=this.state.school
        postedObject.startDate=this.state.startDate
        postedObject.endDate=this.state.endDate
        
        cv.educations[this.props.index] = postedObject
      } else {
         postedObject = {
          diploma: this.state.diploma,
          school: this.state.school,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        };
        if(this.props.id){
          postedObject._id=this.props.id}
        cv.educations.push(postedObject);
      }
      this.context.setUser(cv)
      this.props.updateView(postedObject);
      this.props.setModalVisible(false);
    }
  };
  setStartDate = (value) => {
    this.setState({ startDate: value });
  };
  setEndDate = (value) => {
    this.setState({ endDate: value });
  };
  render() {
    const children = [];
    const {t,i18n}=this.props
    const language=i18n.language 
    const schools={"fr":"school_fr","en":"school_en","ar":"school_en"}
    const school=schools[language]
    let schoolsList = this.context.schoolsList;
    for (let i = 0; i < schoolsList.length; i++) {
      children.push(
        <Option key={i} value={schoolsList[i][school]}>
          {schoolsList[i][school]}
        </Option>
      );
    }
    return (
      <div>
        <Modal
          title={
            <h5 className="m-1 text-primary">
              <b>
                {this.props.index == null ?  this.props.t('Ajouter'): this.props.t("Modifier")} {this.props.t('une formation')} 
                
              </b>
            </h5>
          }
          className=""
          centered
          closeIcon={
            <i className="mt-5 ">
              <CloseOutlined
                // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
                className="m-3"
              />
            </i>
          }
          visible={this.props.modalVisible}
          onOk={() => this.props.setModalVisible(false)}
          onCancel={() => {this.props.setModalVisible(false);this.props.setIndex(null);}}
          footer={null}
        >
          <div className="">
            <div className="row justify-content-start">
              <div className="  ">
                <form className="   " onSubmit={this.handleSubmit}>
                  <div className="">
                    <div className="">
                      <label>{this.props.t("Diplome")} *</label>
                      <div className="md-form">
                        <input
                          className="form-control validate mb-2"
                          id="diploma"
                          type="text"
                          name="diploma"
                          value={this.state.diploma}
                          onChange={this.handleChange}
                        />
                      </div>
                      <p className="text-danger">
                        <small>{this.state.diplomaError}</small>
                      </p>

                      <label>{this.props.t("Etablissement")} *</label>
                      <div className="md-form">
                        {/* <input
                            className="form-control validate mb-2"
                            id="school"
                            type="text"
                            name="school"
                            value={this.state.school}
                            onChange={this.handleChange}
                             
                          /> */}
                        <Select
                          className=" w-100"
                          // style={{ width: '40%' }}
                          showSearch
                          //  defaultValue={this.state.school}
                          autoClearSearchValue={false}
                          placeholder={this.props.t("Rechercher pour sélectionner")}
                          onChange={this.handleSelect}
                          value={this.state.school}
                          mode="multiple"
                          onSearch={this.onNameChange}
                        >
                          {children}
                        </Select>
                      </div>
                      <p className="text-danger">
                        <small>{this.state.schoolError}</small>
                      </p>

                      <div className="row mb-2">
                        <div className="col-lg-6 col-sm-12 ">
                          <label className="col-12" htmlFor="linkedinPage">
                            <span className="float-left"> {this.props.t("Date de début")}  </span>
                            <div className="">
                              <DatePicker
                                placeholder="MM-YYYY"
                                picker="month"
                                format={"MM-YYYY"}
                                //   allowClear={false}
                                disabledDate={this.disabledDate}
                                className="border form-control"
                                defaultValue={
                                  this.state.startDate &&
                                  moment(this.state.startDate)
                                }
                                onChange={(moment, date) => {
                                  if (moment != null) {
                                    this.setStartDate(moment.toDate());
                                  } else this.setStartDate(null);
                                }}
                              />
                            </div>
                          </label>
                          <p className="text-danger">
                            <small>{this.state.startDateError}</small>
                          </p>
                        </div>
                        <div className="col-lg-6 col-sm-12 ">
                          {!this.state.currently && (
                            <label className="col-12" htmlFor="linkedinPage">
                              <span className="float-left">{this.props.t("Date de fin")} </span>
                              <div className="">
                                <DatePicker
                                  placeholder="MM-YYYY"
                                  picker="month"
                                  //   allowClear={false}
                                  disabledDate={this.disabledDate}
                                  format={"MM-YYYY"}
                                  className="border form-control"
                                  defaultValue={
                                    this.state.endDate &&
                                    moment(this.state.endDate)
                                  }
                                  onChange={(moment, date) => {
                                    if (moment != null) {
                                      this.setEndDate(moment.toDate());
                                    } else this.setEndDate(null);
                                  }}
                                />
                              </div>
                              <p className="text-danger">
                                <small>{this.state.endDateError}</small>
                              </p>
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          onChange={(e) =>
                            this.setState({
                              currently: !this.state.currently,
                              endDate: null,
                            })
                          }
                          className="form-check-input "
                          type="checkbox"
                          checked={this.state.currently}
                          role="button"
                          id="defaultCheck1"
                        />
                        <label className="form-check-label" for="defaultCheck1">
                        {this.props.t("En cours")} 
                        </label>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className=" submitspinner  btn btn-primary btn-block z-depth-1a"
                        >
                        {this.props.t("Enregistrer")}  
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()( EducationForm )