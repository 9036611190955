import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../routes/AppContext";
import { getAllFonctionnalite } from "../../services/api/pricing/getAllFonctionnalite";
import { getEstimationBills } from "../../services/api/pricing/getEstimationBills";
import dateFromObjectId from "../../utils/dateFromObjectId";
import decrypt from "../../utils/decrypt";

import generateToken from "../../utils/generateToken";
import PaymentBill from "../orders/PaymentBill";
import EstimationBill from "./EstimationBill";

export default function EstimationBills() {
  const { t } = useTranslation();

  const [bills, setBills] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const { userId,role,orders,headers } = useContext(AppContext);
  const history = useHistory({});



  useEffect(async () => {
    let response = await getEstimationBills(headers);
    if(response)
    {let data = JSON.parse(decrypt(response.data));
    setBills(data);}
  }, []);

  return (
    <div className="flex flex-col w-full my-4 md:my-0 md:w-11/12 md:px-8">
      <div className="border">
        <div className="overflow-auto bg-white shadow-lg p-4">
        <div className="mt-8 py-2 px-4 text-lg font-semibold border-t-2 ">
             <h2> <strong> {t("List des forfaits achetés")}</strong></h2>
            </div>
            <table className="table-auto border w-full my-2">
              <TableHeader />
              <tbody>
                {orders && orders.length > 0 ? (
                  orders.map((item, i) => {
                    return <TableBody key={i} order={item} />;
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="text-xl text-center font-semibold py-8"
                    >
                      {t("Historique des achats vide")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="text-sm text-gray-600 mt-2">
              <strong>{t("Total achats réalisées")} : </strong>
              {orders && orders.length} 
            </div>
            <br/>
          <div className="py-2 px-4 text-lg font-semibold ">
            <h2>
              <strong>{t("List des devis")}</strong>
            </h2>
          </div>
          <table className="table-auto border w-full my-2">
          <thead>
        <>
          <th className="px-4 py-2 border">{t("Forfait de Recrutement")}</th>
          <th className="px-4 py-2 border">{t("Date d'envoi")}</th>
          <th className="px-4 py-2 border">{t("Validité")}</th>
          <th className="px-4 py-2 border">{t("Total")}</th>
          <th className="px-4 py-2 border">{t("Expiration")}</th>
      
          {role=="company"&&<th className="px-4 py-2 border">{t("Devis")}</th>}
          {role=="company"&&<th className="px-4 py-2 border"></th>}
        </>
      </thead>
              <tbody>
                {bills && bills.length > 0 ? (
                  bills.map((bill, i) => {
                    return (<TableLine key={i} bill={bill} />)
                  })
                ) : (
                  <tr >
                    <td
                      colSpan="8"
                      className="text-xl text-center font-semibold py-8"
                    >
                      {t("Liste des devis vide")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  );
}

/* table achats (header) */
const TableHeader = () => {
  const { t } = useTranslation();

  const { role } = useContext(AppContext);
  return (
    <>
      <thead>
        <>
          <th className="px-4 py-2 border">{t("Forfait de Recrutement")}</th>
          <th className="px-4 py-2 border">{t("Type")}</th>
          <th className="px-4 py-2 border">{t("Total")}</th>
          <th className="px-4 py-2 border">{t("Transaction Id")}</th>
          <th className="px-4 py-2 border">{t("Checkout")}</th>
          <th className="px-4 py-2 border">{t("Expiration")}</th>
          {role=="company"&&<th className="px-4 py-2 border">{t("Facture")}</th>}
        </>
      </thead>
    </>
  );
};

/* table achats (body) */
const TableBody = ({ order }) => {
  const { t,i18n } = useTranslation();

  const [modalVisible,setModalVisible]=useState(false)
  const { fonctionnalites,setFonctionnalites,headers,role } = useContext(AppContext);

  const language=i18n.language 
    const modulesLanguage={"fr":"name","en":"name_en"}
    const moduleLanguage=modulesLanguage[language]
  
    const functionalitiesLanguage={"fr":"fName","en":"fName_en"}
    const functionalityLanguage=functionalitiesLanguage[language]

    const packagesLanguage={"fr":"pName","en":"pName_en"}
    const packageLanguage=packagesLanguage[language]

  useEffect(async()=>{
    if(!fonctionnalites.length)
   { let response2 = await getAllFonctionnalite(headers)
    if(response2)
    {setFonctionnalites(JSON.parse(decrypt(response2.data)).fonctionnalites)}
  } 
  },[])
  // const { fonctionnalites } = data;
  let a = 0;
  return (
    <>
      <tr className="border-b">
        <td className="w-48 hover:bg-gray-200 p-2 flex flex-col space-y-1">
          {order.allProduct.map((product, i) => {
            return (
              <span className="block flex items-center space-x-2" key={i}>
                <span>{product.id[packageLanguage]}</span>
                <span>1x</span>
              </span>
            );
          })}
          {order.idCompanyPackage?
            <span className="block flex items-center space-x-2" >
            <span>{order.idCompanyPackage.pName}</span>
          </span>
          :""}
        </td>
       
        <td className="hover:bg-gray-200 p-2 text-center">
              <span className="block flex items-center space-x-2">
                {order.allProduct.length?order.allProduct[0].id.pType:"-"}
              </span>
        </td>

        <td className="hover:bg-gray-200 p-2 text-center">
          {order.amount.toFixed(0)} {t("DTN")}
        </td>

        <td className="hover:bg-gray-200 p-2 text-center">
          {order.amount == 0 ? "-" : order.transactionId}
        </td>
        <td className="hover:bg-gray-200 p-2 text-center">
          {moment(order.createdAt).format("DD/MM/YYYY")}
        </td>
        <td className="hover:bg-gray-200 p-2 text-center">
          {moment(order.datefin).format("DD/MM/YYYY")}
        </td>
       {role=="company"&& <td className="hover:bg-gray-200 p-2 text-center">
          {(order.amount>0&&!order.hideBill)?<i onClick={()=>{setModalVisible(true)}} role="button" className="mdi mdi-file-table icons12 " ></i>:<i role="button" className="mdi mdi-file-table icons12 text-secondary" ></i>}
        </td>}
      </tr>
     {modalVisible&& <PaymentBill order={order} modalVisible={modalVisible} setModalVisible={setModalVisible} />}
    </>
  );
};

const TableLine = ({ bill }) => {
  const { t } = useTranslation();

  const [modalVisible,setModalVisible]=useState(false)
  const { role } = useContext(AppContext);
  const history = useHistory({});

  const pay=(pkg)=>{
    const tokenData = {
      idPackage: pkg.idCompanyPackage,
      idCompany: pkg.idCompany,
    }
    const token=generateToken(tokenData, moment(dateFromObjectId(bill._id)).unix()+60*60*24*7)
  history.push('/company/companyPackage/'+token)
  }
  return (
      <><tr  className="border-b">
                    <td className="hover:bg-gray-200 p-2 text-center">
                          <span className="block flex items-center space-x-2">
                            {bill.companyPackage.pName}
                          </span>
                    </td>
                    <td className="hover:bg-gray-200 p-2 text-center">
                          <span className="block flex items-center space-x-2">
                            {moment(dateFromObjectId(bill._id)).format("DD/MM/YYYY")}
                          </span>
                    </td>
                    <td className="hover:bg-gray-200 p-2 text-center">
                      {bill.companyPackage.duration} {t("jours")}
                    </td>
                    <td className="hover:bg-gray-200 p-2 text-center">
                      {bill.companyPackage.pPrice} {t("DTN")}
                    </td>
                    <td className="hover:bg-gray-200 p-2 text-center">
                      {moment(bill.expirationDate).format("DD/MM/YYYY")}
                    </td>
                    
                   {role=="company"&& <td className="hover:bg-gray-200 p-2 text-center">
                      <i onClick={()=>{setModalVisible(true)}} role="button" className="mdi mdi-file-table icons12 " ></i>
                    </td>}
                    {role=="company"&& <td className="hover:bg-gray-200 p-2 text-center">
                    <button onClick={()=>{pay(bill)}} className="btn btn-primary rounded" >{t("Payer")}</button>
                    </td>}
                  </tr>
                 {modalVisible&& <EstimationBill bill={bill.companyPackage} modalVisible={modalVisible} setModalVisible={setModalVisible} />}</>
  );
};