import React, { useContext, useState } from "react";
import img1 from "../../media/image/test1.webp";
import img2 from "../../media/image/test2.webp";
import img3 from "../../media/image/Upgrade-img.webp";
import img4 from "../../media/image/unnamed.webp";
import AsyncImage from "../AsyncImage";
import { Link } from "react-router-dom";
import TestsForm from "./TestsForm";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { AppContext } from "../../routes/AppContext";
import { useTranslation } from "react-i18next";

export default function SideTestComponent(props) {
  const { role } = useContext(AppContext);
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  return (
    <div className="shadow rounded p-2  col-sm-12 col-md-2 ">
      {props.idTest ? (
        <div className="d-flex align-items-center" >
        <div>
          {/* <div className="m-1 mt-3 d-flex justify-content-center align-items-center">
            <button
              onClick={() => {
                setModalVisible(true);
              }}
              className="btn btn-primary"
            >
              <i className="mdi mdi-plus-circle-outline m-1"></i>
              Nouveau test
            </button>
          </div> */}
          <div className="m-1 d-flex justify-content-center ">
            <AsyncImage
              // width={240}
              className="w-100"
              src={img2}
            />
          </div>
          <div className="m-1 d-flex justify-content-center align-items-center tested-count">
            <AsyncImage className="logo-m" src={img1} />
            {props.candidatesNumber} {t("candidats ont terminé le test")}
          </div>
        </div></div>
      ) : (
        <>
          <div className="m-1 d-flex justify-content-center ">
            <AsyncImage
              // width={255}
              className="w-100"
              src={img3}
            />
          </div>
          <div className="m-1 d-flex justify-content-center align-items-center">
            <AsyncImage className="logo-m" src={img4} />
            {props.testsNumber} {t("tests disponible")}
          </div>
          <div className="m-1 d-flex justify-content-center">
            <Link to={{ pathname: "/" + role + "/manageTests" }}>
              <button className="btn btn-primary">{t("Consulter")}</button>
            </Link>
          </div>
          <div className="m-1 d-flex justify-content-center">
            <Link to={{ pathname: "/" + role + "/addTest" }}>
              <button className="btn btn-primary">{t("Ajouter")}</button>
            </Link>
          </div>
        </>
      )}
      {/* modal */}
      <Modal
        title={
          <h5 className=" m-1 text-primary">
            <b>{t("Choisir le niveau du test")}</b>
          </h5>
        }
        className=""
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
              className="m-3"
            />
          </i>
        }
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={<></>}
      >
        <TestsForm
          setIdTest={props.setIdTest}
          setTestsNumber={props.setTestsNumber}
          idTest={props.idTest}
          idJobPost={props.idJobPost}
          userId={props.userId}
        />
      </Modal>
    </div>
  );
}
