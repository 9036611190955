import React, { useContext, useEffect, useState } from 'react'
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { Col, Row } from 'antd';
import { AppContext } from '../../routes/AppContext';
import AsyncImage from '../AsyncImage';
import CorrectAnswers from './questions/answers/CorrectAnswers';
import formatTime from '../../utils/formatTime';
import { useTranslation } from 'react-i18next';
//time and points from level if !time||!points
//skill from code
export default function QuestionPreview(props) {
  const { t , i18n} = useTranslation();

  const { testLanguage,envVar,hardSkillsList } = useContext(AppContext);
  const [skill,setSkill]=useState("")

  let questionLanguage=testLanguage=="fr"?"question_fr":"question_en"
  let answerLanguage=testLanguage=="fr"?"answer_fr":"answer_en"
  let levels=[t("Débutant"),
    t("Intermédiaire"),
    t("Avancé"),
    t("Expert")]
  useEffect(() => {
    hljs.highlightAll();
  });
  useEffect(() => {
    if(props?.question?.skill){
      let s=hardSkillsList.find(s=>s.code==props?.question?.skill)
     if(s) setSkill(s.skill)
    }
  },[]);
  let i =-1
  let list=props?.question?.answers?.map((answer)=>{
    i++
    return(<li 
      // className={answer.isCorrect?'border rounded m-2 p-1 bg-light-success ul':'border rounded m-2 p-1 ul'}
      className={
        props.solution
          ? "border border-secondary border-3 rounded m-2 p-1 bg-secondary"
          : answer.chosen
          ? answer.isCorrect
            ? " border-light-success rounded m-2 p-1 bg-light-success"
            : "border border-danger border-3 rounded m-2 p-1 bg-danger"
          : "border rounded m-2 p-1 ul"
      }
    key={i}  >
         {  answer.type != 1 ? (
          <div  dangerouslySetInnerHTML={{
            __html: answer[answerLanguage],
          }} ></div>
          
        ) : (
          <AsyncImage
            className="w-100"
            src={
              envVar.REACT_APP_CLIENT_URL
              + "/Test/images/" +
              answer[answerLanguage]
            }
          />
        )}
    {/* <AnswerComponent  index={i}  answer={answer} /> */}
    </li>)
  })

  return (
    <div className='Content-Question' >
              <h5 className="h5" style={{direction : i18n.language==="ar" ?"rtl" : "ltr" , textAlign: i18n.language==="ar" ?"right":"left"}}>{t("Question")} {props?.startIndex??0 + props?.index + 1}</h5>

              <div className="secondary-Setup-Question">
                <Row span={24}>
              
                <Col >
                <p>
                  <strong>{t("Difficulté")}: </strong>
                  {levels[props?.question.level]}
                </p>
                </Col >
                <Col>
                <p>
                  <strong>{t("Compétence")}:</strong> {skill}
                </p>
                </Col>
                <Col>
                <p>
                  <strong>{t("Durée")}:</strong> {formatTime(props?.question.time)}
                </p>
                </Col>
                <Col >
                <p>
                  <strong>{t("Points")}:</strong> {props?.question.points}
                </p>
                </Col>
                </Row>
              </div>
              <div className='d-flex justify-content-center' >
    <hr style={{boxShadow:"10px grey"}} />
    </div>
      <p className='Questions-Para' >
      { props.question.type != 3 ? (
          <div  dangerouslySetInnerHTML={{
            __html: props?.question[questionLanguage],
          }} ></div>
        ) : (
          <AsyncImage
          className="w-100"     
              src={envVar.REACT_APP_CLIENT_URL + "/Test/images/" + props?.question[questionLanguage]}
          />
        )}
        </p>
        <ul>
    {list}</ul>
    <p className='p-2' >
    {props.answer?.answer}

    </p>
    <div className="secondary-Setup-Question">
                <Row span={24}>
                <Col>
                <p>
                  <strong>{t("Temps")}:</strong> {formatTime(props?.answer.timeTook)}
                </p>
                </Col>
                <Col >
                <p>
                  <strong>{t("Score")}:</strong> {props.editable?<input onChange={(e)=>{
                    if(Number(e.target.value)>=0&&Number(e.target.value)<=props?.question?.points)
                    props.updateQuestionResult(props.index,Number(e.target.value))
                  }}
                     type="number" value={props?.questionResult?.points} min={0} max={props?.question?.points} />:props?.questionResult?.points} / {props?.question?.points}
                </p>
                </Col>
                </Row>
              </div>
              <div className='d-flex justify-content-center' >
    <hr style={{boxShadow:"10px grey"}} />
    </div>
              <div className='p-1' >
          <div style={{direction : i18n.language==="ar" ?"rtl" : "ltr" , textAlign: i18n.language==="ar" ?"right":"left"}}>{t("Les réponses correctes")} : </div>
          <CorrectAnswers answers={props?.question?.answers} />

              </div>
    </div>
  )
}
