import React from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import SkillTestingContainer from '../../components/skillTesting/SkillTestingContainer'

 function CandidateSkillTestingView(props) {
    const history = useHistory()
    props.location?.state?.codeSkill?console.log():history.push('/candidate/cv/upload')
  return (
    <>
        <SkillTestingContainer 
        userId={props.userId} 
        skill={props?.location?.state?.skill} 
        codeSkill={props?.location?.state?.codeSkill} 
        level={props?.location?.state?.level} 
        idSkill={props?.location?.state?.idSkill} 
        feature={props?.location?.state?.feature} 
        idCandidateSkill={props?.location?.state?.idCandidateSkill} 
        />
    </>
  )
}
export default  withRouter(CandidateSkillTestingView)