import React from 'react'
import CandidateProfile from '../../components/profile/CandidateProfile'

export default function CandidateProfileView() {
    return (
        <>
            <CandidateProfile/>
        </>
    )
}
