import React, { memo, useContext } from 'react';
import CvComponent from './CvComponent';
import { GetCv } from '../../services/api/cv/GetCv';
import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { useQuery } from 'react-query';

import Content from './template/Content';

import { useTranslation } from "react-i18next";
const Cv=memo((props)=> {

  const { t } = useTranslation();
  const { candidate, decryptedData } = props;
  const { headers } = useContext(AppContext);

  const { data: cvData, isLoading, isError } = useQuery(
    ['cv', candidate._id], // Query key
    async () => {
      
      const response = await GetCv({ id: candidate._id }, headers);
      if (response){
        let data=JSON.parse(decrypt(response.data))
        return data ;
      }
    },
    {
      // staleTime:60*1000,
      // // Cache the data for a certain duration (in milliseconds)
      // cacheTime: 60000, // 1 minute
    }
  );

  if (isLoading) {
    return <div>{t('Loading...')}</div>;
  }

  if (isError) {
    return <div>{t('Error fetching data')}</div>;
  }

  const cv = cvData ? cvData : {};
  return (
    <div>
      {/* <CvComponent decryptedData={decryptedData} cv={cv} candidate={cv} /> */}
      <Content decryptedData={decryptedData} cv={cv} candidate={cv}  />
    </div>
  );
}
)
export default Cv;
