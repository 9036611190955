import React, { useContext, useState } from "react";
import { Modal, notification, Select } from "antd";
import moment from "moment-timezone";
import CheckIcon from "../icons/CheckIcon";
import { ConfirmInterviewService } from "../../services/api/interview/ConfirmInterview";
import { AppContext } from "../../routes/AppContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { Option } = Select;
export default function ConfirmInterview(props) {
  const { t } = useTranslation();
  const { socket,headers,serverTime,user } = useContext(AppContext);
  const [selected, setSelected] = useState(false);
  const [idInterview, setIdInterview] = useState(null);
  const [index, setIndex] = useState(null);
  const handleSelect = (value, i) => {
    setIdInterview(props.interviews[value]._id);
    setIndex(value);
  };
  const handleSubmit = async (e) => {
    e.stopPropagation();
    if (index != null) {
      Modal.confirm({
        title: t("Confirmer date d'entretien"),
        icon: <ExclamationCircleOutlined />,
        content:
          t("voulez vous choisir cette date, si vous fixer la date vous ne pouvez pas la changer"),
        okText: t("Confirmer"),
        okType: "danger",
        cancelText: t("Annuler"),
        onOk: async () => {
          setSelected(true);
          let response = await ConfirmInterviewService(
            {
              idInterview: idInterview,
              idJobPost: props.idJobPost,
              idCompany: props.idCompany,
              companyName:props.company.name,
              companyEmail:props.company.email,
              name:user.name,
              email:user.email,
              jobPostTitle:props.jobPost.title,
              interview:props.interviews[index]
            },
            headers
          );
          if (response?.status == 200) {
            notification.success({
              description: t(response.data.code),
              className: "custom-class",
              style: {
                width: 600,
                backgroundColor: "#d4edda",
                marginTop: "5rem",
              },
            });
            props.confirmInterview(props.interviews[index]);
            //send notif
            socket.emit("sendNotification", {
              receiverId: props.idCompany,
              type: 5,
            });
          }
        },
        // onCancel:()=>{alert(index)}
      });
    }
  };
  const children = [];
  let interviews = props.interviews;
  // .filter(i=>moment().isBefore(moment(i.date)))
  for (let i = 0; i < interviews.length; i++) {
    children.push(
      <Option
        disabled={moment(interviews[i].date).isBefore(moment(serverTime))}
        key={i}
        value={i}
      >
        {moment(interviews[i].date).format("LLLL")}
        {interviews[i].address ? `(Présentiel:${interviews[i].address})` : ""}
      </Option>
    );
  }
  return (
    <div className="">
      <div>
        <label>{t("Choisir la date d'entretien")}</label>
        <br />
        <Select
          className=" w-50"
          // style={{ width: '40%' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          placeholder={t("Rechercher pour sélectionner")}
          onChange={handleSelect}
        >
          {children}
        </Select>
        {selected ? (
          <span
            onClick={handleSubmit}
            className="ms-2 btn btn-success rounded-circle p-1"
          >
            <i className="">
              <CheckIcon width="1.5rem" />
            </i>
          </span>
        ) : (
          index==null ?
          <span
            onClick={handleSubmit}
            className="ms-2 btn btn-primary rounded-circle p-1"
          >
            <i className="">
              <CheckIcon width="1.5rem" />
            </i>
          </span>
          :
          <span
            onClick={handleSubmit}
            className="ms-2 btn btn-danger rounded-circle p-1"
          >
            <i className="">
              <CheckIcon width="1.5rem" />
            </i>
          </span>
        )}
        <br />
        {/* <p className="text-danger"><small>{locationError}</small></p> */}
      </div>
    </div>
  );
}
