import { Button, Modal, notification, Radio, Select } from 'antd'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../routes/AppContext';
import { UpdateApplications } from '../../services/api/application/UpdateApplications';

import InterviewsForm from '../addInterviews/InterviewsForm';
import TestsForm from '../jobPostTestResults/TestsForm';

const { Option } = Select;

export default function ManageApplicationsModal(props) {
  const { t } = useTranslation();

  const { user,socket,headers } =useContext(AppContext);
  const history = useHistory()
  const [interviews,setInterviews]=useState(props.interviews??[])
  const [idTest,setIdTest]=useState(props.idTest)
  const [state,setState]=useState(props.isTest?1:2)
  const [loading,setLoading]=useState(false)
  const validation =()=>{
    let valid=true
    //interviews validation
    if(props.selectedCandidates.length>interviews.length&&state==2){
      valid=false
      const selectedCandidatesNum=props.selectedCandidates.length
      notification.warn({
        description:<Trans i18nKey="invalidDatesNumber">
        Veuiller ajouter au moins {{selectedCandidatesNum}} dates
      </Trans>, 
      // "Veuiller ajouter au moins "+props.selectedCandidates.length+" dates",
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
    }
    //test validation
    if(state==1&&!idTest){
      valid=false
      notification.warn({
        description: t("Veuiller ajouter un test"),
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
    }
    return valid
  }
  const handleSubmit=async(event)=>{
    event.preventDefault();
   if(validation()){
    setLoading(true)
     let arrObj=props.selectedCandidates.map(c=>JSON.parse(c))
    let selectedCandidates=Object.assign({}, ...arrObj)
    let applications={0:{},1:{},2:{},3:{},4:{}}
    applications[state]=selectedCandidates
    let notifications=[]
    let ids=Object.keys(selectedCandidates)
       for (let i = 0; i < ids.length; i++) {
        let idCandidate=ids[i]
       notifications.push({
        idCandidate:idCandidate,
        idJobPost:props.idJobPost,
        type:state,
        seen:false
       })
       socket.emit("sendNotification",
    {receiverId:idCandidate,type:state}

       )
      }

      
    
 let companyName=user.role=="company"?user.name:user?.company?.name
   let res=await UpdateApplications({
      
      applications: applications,
      interviews:interviews,
      companyName:companyName,
      idJobPost:props.idJobPost,
      jobPostTitle:props.title,
      startMissionDate:props.startMissionDate,
      notifications:notifications,
      idTest:idTest,
    }, headers)
  if(res){
          //notification
          notification.success({
            description: res.data.message,
            className: "custom-class",
            style: {
              width: 600,
              backgroundColor:"#d4edda",
              marginTop:"5rem"
            },
          });
        history.push('/'+user.role+'/manageJobPosts')}
      }
  }
  return (
    <div>
  <Modal
        title={<h5 className="m-1">{t("Gestion des candidatures")}</h5>}
        className="w-75"
        centered
        // closeIcon={
        //    <i className="mt-5 ">
        //      <CloseOutlined className="m-3" />
        //    </i>
        // }
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        footer={
          <>
            {/* <input
                type="button"
                className=" btn btn-secondary p-1 rounded m-1"
                value="Ignorer "
                onClick={() => {
                  props.history.push("/company/manageJobPosts");
                }}
              /> */}
              <Button  className="p-1 m-1" type="primary" loading={loading} onClick={handleSubmit}>
              {t("Enregistrer")}
</Button>
            
          </>
        }
      >
             <Select
              // style={{ width: 200 }}
              placeholder={t("Selectionner..")}
              className=""
              // style={{width:"100%"}}
              onChange={setState}
              defaultValue={state}
            >
              <Option value={1}>{props.isTest?t("Nouveau")+" " :""}{t("Test")}</Option>
              <Option value={2}>{!props.isTest?t("Nouveau")+" " :""}{t("Entretien")}</Option>
              <Option value={3}>{t("Refusé")}</Option>
              <Option value={4}>{t("Accepté")}</Option>
            </Select>
            {state==1&&<><TestsForm setIdTest={setIdTest} setTestsNumber={()=>{}} idTest={idTest} idJobPost={props.idJobPost} userId={props.userId} /></>}
            {state==2&&<><InterviewsForm applications={props.selectedCandidates} interviews={interviews} setInterviews={setInterviews} />

            </>}

      </Modal>
    </div>
  )
}
