import { Modal, notification, Table } from 'antd'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import AddInterviewForm from './InterviewForm';
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteInterview } from '../../services/api/interview/DeleteInterview';
import { AddInterviews } from '../../services/api/interview/AddInterviews';

import { ObjectID } from "bson";
import EditIcon from '../icons/EditIcon';
import TrashIcon from '../icons/TrashIcon';

import { AppContext } from '../../routes/AppContext';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import enEN from "antd/lib/locale/en_US";
import arAR from "antd/lib/locale/ar_EG";
import i18next from 'i18next';
const { Column } = Table;

export default function Dates(props) {
  const { t } = useTranslation();
  
  const {headers} = useContext(AppContext);

  const [modalVisible, setModalVisible] = useState(false)
  const deleteInterview = async (id) => {
    //send request
    let response = await DeleteInterview(
      { id: id },
      headers
    );
    //if 200 delete from list
    if (response?.status == 200) {
      let events = [...props.events]
      events = events.filter(e => e._id != id)
      props.setEvents(events)
    }
  }
  const addInterview = async (interview) => {
    //send request 
    let id = new ObjectID().toString()
    let response = await AddInterviews(
      { interviews: [{ date: interview.date,link: interview.link,address: interview.address, _id: id }], idJobPost: props.idJobPost,  },
      headers
    );
    if(response){
    notification.success({
      description: t(response.data.code),
      className: 'custom-class',
      style: {
        width: 600,
        backgroundColor: "#d4edda",
        marginTop: "5rem"
      },
    });
    //push to dates
    props.setEvents([...props.events, { _id: id, date: interview.date,link: interview.link }])}
  }
  let ed = props.events.filter(e => !e.idCandidate)
  let eo = props.events.filter(e => e.idCandidate)
  return (
    <div>
      <div className='d-flex justify-content-center m-2' >
        <button onClick={() => { setModalVisible(true) }} className='btn btn-primary rounded-pill'  > 
        <i className="mdi mdi-plus-circle-outline m-1"></i>
        <b className="mobile">{t("Ajouter nouvelles dates")}</b>
          </button>
      </div>
      <ConfigProvider locale={i18next.language=="fr"?frFR:enEN}>
      <Table
loading={props.loading}
        pagination={true} dataSource={props.events}>
        <Column
          align="center"
          title={t("Status")}
          dataIndex="idCandidate"
          key="name"
          render={(text, event, index) => {
            return (<div className=" d-flex justify-content-center">
              {event.idCandidate ? <>{t("occupée")}</> : <>{t("libre")}</>}
            </div>)
          }}
        />

        <Column
          align="center"
          sorter={(a, b) => new Date(a.date) - new Date(b.date)}
          // responsive={["lg"]}
          title={t("Date")}
          dataIndex="date"
          key="date"
          render={(text, interview, index) => {
            return (<>
              {moment(interview.date).format("HH:mm L")}
            </>)
          }}
        />


        <Column
          align="center"
          // responsive={["lg"]}
          title=""
          dataIndex=""
          key="Lien"
          render={(text, interview, index) => {
            return (
              <div>
                {interview.idCandidate ? <>
                  <i className="text-success m-1 zoom1 icons12" role="button">
                  <EditIcon fill="gray" width="1.8rem" />
                </i>
                <i title={t("Archiver")} className=" text-danger m-2" role="button"
              >
        <TrashIcon fill="gray" width="1.5rem" />
              </i>
                </> :
                 <>
                   <i className="text-success m-1 zoom1 icons12" role="button"
                  onClick={() => {
                    props.setInterviewToUpdate(interview)
                    props.setModal3Visible(true)
                    props.setInterviewIndex(index)
                  }}
                >
                  <EditIcon fill="#4caf50" width="1.8rem" />
                </i>
                <i title={t("Archiver")} className=" text-danger m-2" role="button"

                onClick={() => {
                  Modal.confirm({
                    title: t('Supprimer date d entretien'),
                    icon: <ExclamationCircleOutlined />,
                    content: t('voulez vous supprimer cette date'),
                    okText: t('Supprimer'),
                    okType: "danger",
                    cancelText: t('Annuler'),
                    onOk: () => {
                      deleteInterview(interview._id)
                    }

                  })


                }}
              >
        <TrashIcon fill="red" width="1.5rem" />

              </i>
                
                </>}
              </div>)
          }}
        />
      </Table>
      </ConfigProvider>
      <div className='p-2' >
        {t("Dates disponibles")} : <b>{ed.length}</b>
      </div>
      {/* <div className='p-2' >
        Dates occupées : <b>{eo.length}</b>
      </div> */}

      <Modal
        title={
          <h5 className=" m-1 text-primary">
            <b>{t("Ajouter des dates")}</b>
          </h5>
        }
        style={{ top: 20 }}
        className=" h-100"
        centered
        closeIcon={
          <i className="mt-5 ">
            <CloseOutlined
              // style={{ fontSize: "20px", color: "#08c", margin: "20px" }}
              className="m-3"
            />
          </i>
        }
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={<></>}
      >
        <div className='' >
          <AddInterviewForm idJobPost={props.idJobPost} add={addInterview} />
        </div>
      </Modal>
    </div>
  )
}
