import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import AnswersList from "./answers/AnswersList";
import {
  ExclamationCircleOutlined,
  RightOutlined,
  DownOutlined,CloseCircleOutlined
} from "@ant-design/icons";

import { Select, Modal } from "antd";
import TrashIcon from "../../../icons/TrashIcon";
import ImageUploader from "../ImageUploader";
import { AppContext } from "../../../../routes/AppContext";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const QuestionForm = forwardRef((props, ref) => {
  const { envVar,hardSkillsList } = useContext(AppContext);
  const { t } = useTranslation();

  const [update, setUpdate] = useState(false);
  const [questionError, setQuestionError] = useState("");
  const [skillError, setSkillError] = useState("");
  const [answersError, setAnswersError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(1);
  const [pointsError, setPointsError] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const answersValidation = () => {
    let answers = props.question.answers;
    if (answers.length < 2) {
      setAnswersError(t("chaque question doit contenir au moins 2 réponses"));
      return;
    } else {
      let isValid = false;
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        if (answer.isCorrect) {
          isValid = true;
        }
      }
      if (!isValid) {
        setAnswersError(
          t("chaque question doit contenir au moins une réponse correcte")
        );
      } else {
        setAnswersError("");
      }
    }
  };
  useImperativeHandle(ref, () => ({
    validation() {
      let { question_fr,question_en, time, points, answers, type,skill } = props.question;
      //question
      if(props.language=="fr"){
        if (question_fr == "") {
          setQuestionError(t("champ obligatoire"));
        }
      }else if(props.language=="en"){
        if (question_en == "") {
          setQuestionError(t("champ obligatoire"));
        }
      }
     
      //answers
      if (type != 2) answersValidation();

      //time
      if (time == "") {
        setTimeError(t("champ obligatoire"));
      }
      //points
      if (points == "") {
        setPointsError(t("champ obligatoire"));
      }
        //skill
        // if (skill == "") {
        //   setSkillError("champ obligatoire");
        // }
    },
  }));
  useEffect(() => {
    if (props.question.type != 3) {
      const textarea = document.getElementById("question" + props.index);
      textarea.addEventListener("input", function (e) {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      });
    }
    if(props?.question?.skill){
      let s=hardSkillsList.find(s=>s.code==props?.question?.skill)
     if(s) setSelectedSkill(s.skill)
    }
    answersValidation()
  }, []);
  const Update = (question) => {
    props.updateQuestion(props.index, question);
    setUpdate(!update);
  };

  const setQuestion = (value) => {
    if (value == "") {
      setQuestionError(t("champ obligatoire"));
    } else {
      setQuestionError("");
    }
    let question = props.question;
    if(props.language=="fr"){
      question.question_fr = value;
    }else if(props.language=="en"){
      question.question_en = value;
    }
    Update(question);
  };
  const setSkill = (value,i) => {
    let s=hardSkillsList[i.key]
    // if (value == "") {
    //   setSkillError("champ obligatoire");
    // } else {
    //   setSkillError("");
    // }
    let question = props.question;
    question.skill = s.code;
    setSelectedSkill(value)
    Update(question);
  };
  const setLevel = (value) => {
    let question = props.question;
    question.level = value;
    switch (value) {
      case 0:
        question.time = parseInt(envVar.REACT_APP_QUESTION_TIME_0) / 60;
        question.points = 1;
        break;
      case 1:
        question.time = parseInt(envVar.REACT_APP_QUESTION_TIME_1) / 60;
        question.points = 2;
        break;
      case 2:
        question.time = parseInt(envVar.REACT_APP_QUESTION_TIME_2) / 60;
        question.points = 3;
        break;
      case 3:
        question.time = parseInt(envVar.REACT_APP_QUESTION_TIME_3) / 60;
        question.points = 4;
        break;
      default:
        break;
    }
    Update(question);
  };
  const setTime = (value) => {
    if (value == "") {
      setTimeError(t("champ obligatoire"));
    } else {
      setTimeError("");
    }
    let question = props.question;
    question.time = value;
    Update(question);
  };
  const setType = (value) => {
    let question = props.question;
    question.type = value;
    switch (value) {
      case 0:
        question.answers = [
          { answer_en: "",answer_fr: "", isCorrect: true, type: 0 },
        { answer_en: "",answer_fr: "", isCorrect: false, type: 0 },
        ];
        break;
      case 1:
        question.answers = [
          { answer_en: "True",answer_fr: "Vrai", isCorrect: true, type: 0 },
        { answer_en: "False",answer_fr: "Faux", isCorrect: false, type: 0 },
        ];
        break;
      case 2:
        question.answers = [];
        break;
      case 3:
        question.answers = [
          { answer_en: "",answer_fr: "", isCorrect: true, type: 0 },
          { answer_en: "",answer_fr: "", isCorrect: false, type: 0 },
        ];
        question.question_fr = "";
        question.question_en = "";
        break;

      default:
        break;
    }
    Update(question);
  };
  const setPoints = (value) => {
    if (value == "") {
      setPointsError(t("champ obligatoire"));
    } else {
      setPointsError("");
    }
    let question = props.question;
    question.points = value;
    Update(question);
  };

  const addAnswer = () => {
    let question = props.question;
    question.answers.push({ answer_fr: "",answer_en: "", isCorrect: false, type: 0 });
    props.updateQuestion(props.index, question);
    answersValidation();
  };
  const deleteAnswer = (index) => {
    //answers validation
    let question = props.question;
    question.answers.splice(index, 1);
    props.updateQuestion(props.index, question);
    answersValidation();
  };
  const updateAnswer = (index, answer) => {
    let question = props.question;
    question.answers[index] = answer;
    props.updateQuestion(props.index, question);
    answersValidation();
  };
  const children = [];
  for (let i = 0; i < hardSkillsList.length; i++) {
    children.push(
      <Option key={i} value={hardSkillsList[i].similar_skill}>
        <div className="capitalize">{hardSkillsList[i].similar_skill}</div>
      </Option>
    );
  }
  let question=(props.language=="fr"?props.question.question_fr:props.language=="en"?props.question.question_en:"")
  return (
    <div className="">
      {/* <i
        title="ouvrir"
        className=" m-2 right"
        role="button"
        onClick={() => {
// setClosed(!closed)
props.updateOpenQuestions(props.index)
        }}
      >
               {props.openQuestions[props.index] ? <RightOutlined /> : <DownOutlined />}
      </i> */}
     {props.numQuestions>1 && <i
        title={t("Supprimer")}
        className="right"
        role="button"
        onClick={() => {
          const i = props.index + 1;
          Modal.confirm({
            title: t("Supprimer Question"),
            icon: <ExclamationCircleOutlined />,
            content: t("voulez vous supprimer la question")+" " + i,
            okText: t("Supprimer"),
            okType: "danger",
            cancelText: t("Annuler"),
            onOk: () => {
              props.deleteQuestion(props.index);
            },
          });
        }}
      >
        <CloseCircleOutlined
          style={{
            height: 40,
            color: "#EE1C1C",
            opacity: 0.8,
            marginLeft: "auto",
            fontSize: "30px",
          }}
        />
      </i>}

      <h5>{t("Question")} {props.index + 1} :</h5>
      <br />
      <div hidden={props.currentPageIndex!=props.index}>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <label>{t("Niveau")}</label>
            <br />
            <Select
              placeholder={t("Selectionner..")}
              className=" w-100"
              onChange={(value) => {
                setLevel(value);
              }}
              value={props.question.level}
            >
              <Option value={0}>{t("Débutant")}</Option>
              <Option value={1}>{t("Intermédiaire")}</Option>
              <Option value={2}>{t("Avancé")}</Option>
              <Option value={3}>{t("Expert")}</Option>
            </Select>
            <br />
            {/* <p className="text-danger"><small>{titleError}</small></p> */}
          </div>
          <div className="col-lg-4 col-md-12">
            <label>
              {t("Points")} <b style={{ color: "blue" }}>* </b>
            </label>
            <div className="md-form">
              <input
                className="form-control validate mb-1"
                type="number"
                id={"points" + props.index}
                name="points"
                value={props.question.points}
                onChange={(e) => {
                  setPoints(e.target.value);
                }}
              />
            </div>
            <p className="text-danger">
              <small>{pointsError}</small>
            </p>
          </div>{" "}
          <div className="col-lg-4 col-md-12">
            <label>
              {t("Temps")} <small>({t("minutes")}:{t("secondes")})</small> <b style={{ color: "blue" }}>* </b>
            </label>
            <div className="md-form d-flex align-items-center">
              <input
                className="form-control validate mb-1 mx-1"
                type="number"
                max={59}
                min={0}
                id={"time" + props.index}
                name="time"
                value={Math.floor(props.question.time/60)}
                onChange={(e) => {
                  setTime((props.question.time%60)+ Number(e.target.value)*60);
                }}
              />:
               <input
                className="form-control validate mb-1 mx-1"
                min={0}
                max={59}
                type="number"
                id={"time" + props.index}
                name="time"
                value={props.question.time%60}
                onChange={(e) => {
                  setTime(Number(e.target.value)+Math.floor(props.question.time/60)*60);
                }}
              />
                          </div>
            <p className="text-danger">
              <small>{timeError}</small>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <label>{t("Type de question")} </label>
            <br />
            <Select
              placeholder={t("Selectionner..")}
              className=" w-100"
              onChange={(value) => setType(value)}
              value={props.question.type}
            >
              <Option value={0}>{t("QCM")}</Option>
              <Option value={1}>{t("Vrai/Faux")}</Option>
              <Option value={2}>{t("Champ libre")}</Option>
              <Option value={3}>{t("Image")}</Option>
              <Option value={4} disabled>
                {t("Programmation")}
              </Option>
            </Select>
            <br />
            {/* <p className="text-danger"><small>{titleError}</small></p> */}
          </div>{" "}
          <div className="col-lg-6 col-md-12">
            <label>{t("Compétence")}</label>
            <br />
            <Select
            showSearch
              placeholder={t("Selectionner..")}
              className=" w-100"
              onChange={setSkill}
              value={selectedSkill}
              id={"skill"+ props.index}
            >
             {children}
            </Select>
            <br />
            <p className="text-danger"><small>{skillError}</small></p>
          </div>{" "}
        </div>

        <label className="col-12 ">
          <span className=""> {t("Question")} <b style={{ color: "blue" }}>* </b> </span>
          {props.question.type == 3 ? (
            <label className=" ">
              <br />
              <ImageUploader
                id={props.id}
                image={question}
                setImage={setQuestion}
              />
            </label>
          ) : (
            <textarea
              name="description"
              className=" form-control "
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              value={question}
              id={"question" + props.index}
              rows="1"
            ></textarea>
          )}
        </label>
        <p className="text-danger">
          <small>{questionError}</small>
        </p>

        {props.question.type != 2 && (
          <div className="">
            <AnswersList
              questionIndex={props.index}
              answers={props.question.answers}
              addAnswer={addAnswer}
              deleteAnswer={deleteAnswer}
              updateAnswer={updateAnswer}
          language={props.language}
          id={props.id}
            />
          </div>
        )}
        <p className="text-danger">
          <small>{answersError}</small>
        </p>
      </div>
    </div>
  );
});

export default QuestionForm;
