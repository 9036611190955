import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../AppContext";
import Container from "../Container";
export default function CandidateRouter({ children,component, ...rest }) {
  const { user } = useContext(AppContext);
  return (
    <Route {...rest}>
      {user.role == "candidate" ? (
        <Container {...rest}>{children?children:component()}</Container>
      ) : user.role == "" ? (
        <Redirect to="/signin/candidate" />
      ) : user.role == "company" ? (
        <Redirect to="/company/dashboard" />
      ) : (
        <Redirect to="/responsible/dashboard" />
      )}
    </Route>
  );
}
