import React from 'react'
import CandidateSignup from '../../components/signup/CandidateSignup'
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";

export default function CandidateSignupView() {
    const params = useParams();
    let email
    let token=params?.token
    if(token){
        let decoded = jwt_decode(token);
        if(decoded.data.id){
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem("token", token);
            window.location.replace("/candidate/applications");
        }else{
            email=decoded.data
        }

    }
    return (
        <main  className="CompanySigninPage container-fluid">
            <CandidateSignup email={email} />
        </main>
    )
}
