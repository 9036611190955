import { notification, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { GetJobPostsTitles } from '../../../services/api/manageJobPosts/GetJobPostsTitles';
import decrypt from '../../../utils/decrypt';
import { useTranslation } from "react-i18next";
import { AppContext } from '../../../routes/AppContext';
import { GetJobPosts } from '../../../services/api/manageJobPosts/GetJobPosts';
import { useQuery } from 'react-query';
import moment from 'moment';
const { Option } = Select;

export default function JobChoice(props) {
  const { serverTime,headers } = useContext(AppContext);

  const getJobPosts=async()=>{
    let response = await GetJobPosts(
        {},
        headers
      );
   if(response){
      let jobPosts = JSON.parse(decrypt(response.data));
      if (props.idResponsible)
  jobPosts = jobPosts.filter(
    (jobPost) => jobPost?.idResponsible == props.idResponsible
  );
for (let i = 0; i < jobPosts.length; i++) {
  const element = jobPosts[i];
  if (element.closed) {
    element.status = "Archivé";
  }
  else if (moment(element.jobPostPublicationDate).isAfter(moment(serverTime))) {
    element.status = "Programmé";
  } else if (moment(element.jobPostExpirationDate).isBefore(moment(serverTime))) {
    element.status = "Expiré";
  }
  else if (!element.published) {
    element.status = "En attente";
  }
  else element.status = "Publié";
}

return jobPosts
}
}
const { data: jobPosts, isLoading, isError } = useQuery(
    ['jobPosts'], // Query key
    getJobPosts,{
      // refetchOnWindowFocus: false,
// refetchOnReconnect: false,
refetchOnMount: true,
refetchQueries: ['jobpost'],
    }
  );
  const { t } = useTranslation();
  const next=()=>{
    if(!props.idJobPost){
      notification.warn({
        description: "veuillez selectionner un offre",
        className: 'custom-class',
        style: {
          width: 600,
          backgroundColor: "#fff3cd",
          marginTop: "5rem"
        },
      })
      return
    }
    props.next()
  }
  

   const  handleSelect=(value,i,)=> {
    console.log(value);
    let jobPost=jobPosts.find(jp=>jp.title==value)
    props.setJobPost(jobPost)
    props.setIdJobPost(jobPost._id)
  }

  
  return (
    <div className="m-3 shadow p-5 ">
    <h2  className="titrePage text-center" >
      {/* Utiliser les paramètres d'exportation sélective pour extraire les
      pièces jointes */}
      {t("Sélectionner votre offre svp!")}
      
    </h2>
<br/>
<Select className=" w-50 m-1"  
showSearch
                  // style={{ width: '40%' }} 
                   onClick={(e)=>{
                    e.preventDefault()
                    e.stopPropagation()

                   }}
    placeholder={t("Rechercher pour sélectionner")}
    onChange={handleSelect}
    >
      {!isLoading&&jobPosts.map((jobPost,index)=>{return(<Option key={index} value={jobPost.title}>{jobPost.title}</Option>)})}
  </Select>
  <br/>
  <div className='text-end' >
      <button onClick={next} className="btn btn-primary">{t('Suivant')}</button>
</div>  
    </div>
  )
}
