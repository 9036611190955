import React, { Component } from "react";
import { Link ,Router} from "react-router-dom";
import { Select, Modal, Checkbox, Input, AutoComplete } from "antd";
import { Container, Row, Col, Label, Button, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { companySignup } from "../../services/api/signup/companySignup";
import { withRouter } from "react-router";
import DetailsCookies from "../home/cookies/DetailsCookies";
import InfoIcon from "../icons/InfoIcon";
import CheckIcon from "../icons/CheckIcon";

import { withTranslation } from "react-i18next";
import { AppContext } from "../../routes/AppContext";

const { Option } = Select;

class CompanySignup extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      name: "",
      sector: "",
      email: "",
      password: "",
      checked: false,
      nameError: "",
      sectorError: "",
      emailError: "",
      passwordError: "",
      passwordValidations:[],
      checkedError: "",
      confirmEmail: "",
      confirmPassword: "",
      confirmPasswordError: "",
      confirmEmailError: "",

      verifpwd: false,
      sending: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  info = () => {
    Modal.info({
      title: null,
      content: (
        <div>
          <strong>  {this.props.t("Il s'agit simplement d'une adresse qui comporte votre nom de société à la place d'un compte générique fourni par Gmail, Outlook, Orange ou Yahoo.")} </strong>
        </div>
      ),

      onOk() { },
    });
  };
  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible });
  };
  onChange = (value) => {
    this.setState({ sector: value });
  };
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    if(name=="password"){
      this.validatePassword(value)
    }
  }
  validatePassword=(password)=>{
    //
    let list=[]
    list.push(password.match(/[0-9]/g))
    list.push(password.match(/[A-Z]/g)) 
    list.push(password.match(/[a-z]/g)) 
    list.push(password.match(/[^a-zA-Z\d]/g)) 
    list.push(password.length >= 8)
    this.setState({passwordValidations:list})
  }
  validation = () => {
    let isvalid = true;
    let email = this.state.email.toLowerCase();
    let name = this.state.name;
    let sector = this.state.sector;
    let password = this.state.password;
    let checked = this.state.checked;
    let confirmEmail = this.state.confirmEmail.toLowerCase();
    let confirmPassword = this.state.confirmPassword;

    //email validation
    if (email == "") {
      isvalid = false;
      this.setState({ emailError: "champ obligatoire !" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      isvalid = false;
      this.setState({ emailError: "email non valide" });
    } else if (
      email.includes("@live") ||
      // email.includes("@outlook") ||
      // email.includes("@gmail") ||
      // email.includes("@yahoo") ||
      // email.includes("@hotmail") ||
      email.includes("@zoho") ||
      email.includes("@tutanota") ||
      email.includes("@gmx") ||
      email.includes("@icloud")
    ) {
      this.setState({ emailError: "email non professionnel" });
      isvalid = false;
    } else this.setState({ emailError: "" });

    //name validation 
    if (name == "") {
      isvalid = false;
      this.setState({ nameError: "champ obligatoire !" });
    } else this.setState({ nameError: "" });

    //confirmEmail validation
    if (confirmEmail == "") {
      isvalid = false;
      this.setState({ confirmEmailError: "champ obligatoire" });
    }
    else if (confirmEmail != email) {
      isvalid = false;
      this.setState({ confirmEmailError: "Ces emails ne correspondent pas. Veuillez réessayer." });
    }
    else this.setState({ confirmEmailError: "" });

    //confirmPassword validation
    if (confirmPassword == "") {
      isvalid = false;
      this.setState({ confirmPasswordError: "champ obligatoire" });
    }
    else if (confirmPassword != password) {
      isvalid = false;
      this.setState({ confirmPasswordError: "Ces mots de passe ne correspondent pas. Veuillez réessayer." });
    }
    else this.setState({ confirmPasswordError: "" });

    //sector validation
    if (sector == "") {
      isvalid = false;
      this.setState({ sectorError: "champ obligatoire !" });
    } else this.setState({ sectorError: "" });

  

    //password validation
    if (password == "") {
      isvalid = false;
      this.setState({ passwordError: "champ obligatoire !" });
    } else if (
      !(
        password.match(/[0-9]/g) &&
        password.match(/[A-Z]/g) &&
        password.match(/[a-z]/g) &&
        password.match(/[^a-zA-Z\d]/g) &&
        password.length >= 8
      )
    ) {
      isvalid = false;
      this.setState({
        passwordError:
        "Mot de passe faible.",
      });
    } else this.setState({ passwordError: "" });

    //checked validation
    if (!checked) {
      isvalid = false;
      this.setState({ checkedError: "champ obligatoire !" });
    } else this.setState({ checkedError: "" });

    return isvalid;
  };
  handleSubmit(event) {
    event.preventDefault();

    if (this.validation()) {
      companySignup({
        
        name: this.state.name,
        sector: this.state.sector,
        email: this.state.email.toLowerCase(),
        password: this.state.password,
      },this.context.headers);

      this.setState({
        name: "",
        email: "",
        password: "",
      });
      this.props.history.push("/signin/company")
    }
  }

  render = () => {
    const sectors = { "fr": "sector_fr", "en": "sector_en" }
    const sector = sectors[this.props.i18n.language]

    let passwordValidation=[
      this.props.t("Au moins 1 chiffre '123...'"),
      this.props.t("Au moins 1 caractère majuscule"),
      this.props.t("Au moins 1 caractère minuscule"),
      this.props.t("Au moins 1 caractère spécial '+-*/...'"),
      this.props.t("Minimum 8 caractères")]
    const { sending } = this.state;
    const renderItem = (c) => ({
      value: `${c[sector]}`,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'start'
          }}
        >
          {c[sector]}
        </div>
      ),
    });
    let sectorsList=this.context.sectorsList
   const sectorChildren=sectorsList.map((s)=>renderItem(s))
    return (
      <div className="company-signup  mt-5">
        <Container className="mt-5">
          <Row>


            <Col
              lg={{ size: 4 }}
              md={{ size: 5 }}
              sm={{ size: 6 }}
              className="section1"
            >
              <div className="p-4">
                <div className="mx-6">
                  <div className="text-center mb-4">
                    <h3 className="text-primary mb-3 switch__title ">
                      <strong>
                      {this.props.t("CHOISIR NOTRE SOLUTION Rec-INOV")} !
                      </strong>

                      <hr />
                    </h3>
                  </div>
                  <h6>{this.props.t("c'est faciliter vos recrutements...")}.</h6>
                  <p className="service-title text-dark font-weight-normal pt-1 mb-4">
                  {this.props.t("Améliorez votre processus de recrutement et de gestion des talents grâce à nos tests et nos outils de suivi. Déposez vos offres et évaluez les capacités de vos candidats pour une meilleure adéquation avec le poste et l'entreprise")}
                  </p>
                  <div className="text-center" >

                    <Link to={"/signin/company"}>
                      <button className=" btn1 ">  {" "}
                      {this.props.t("Connectez-vous")}{" "} </button>
                    </Link></div>
                </div>
              </div>
            </Col>
            <Col
              lg={{ size: 7, offset: 1 }}
              md={{ size: 5, offset: 1 }}
              sm={{ size: 6, offset: 0 }}
              className="section2"
            >
              <div className="text-center custom-form mt-4 mt-lg-0">
                <div id="message"></div>
                <AvForm
                  onSubmit={this.handleSubmit}
                  name="contact-form"
                  id="contact-form"
                >
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mb-4">
                        <h2 className="text-primary mb-3 text-uppercase">
                          <strong>{this.props.t("Inscription Entreprise")} </strong>
                          <hr />
                        </h2>
                      </div>
                      <FormGroup className="app-label">
                        <Label for="name">{this.props.t("Nom de l'entreprise")} *</Label>
                        <AvField
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("Votre nom de l'entreprise ...")}
                          errorMessage="Entrez votre nom de l'entreprise .."
                          // validate={{ required: { value: true } }}
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.nameError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <FormGroup className="app-label">
                        <Label for="password">{this.props.t("Secteur Activité")} *</Label>
                        <AutoComplete
                    className="w-100 border "
                    id="sector"
                    type="text"
                    name="sector"
                    value={this.state.sector}
                    onChange={this.onChange}
                        options={sectorChildren}
                        // placeholder="try to type `b`"
                        filterOption={(inputValue, option) =>
                          { 
                            if(option.value)
                           return option?.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1}
                        }
                      />
                        {/* <Select
                          // style={{ width: 200 }}
                          placeholder="Selectionner.."
                          className="mb-2 w-100"
                          // style={{width:"100%"}}
                          showSearch
                          onChange={this.onChange}
                          
                        >
                       {sectorChildren}
                        </Select> */}
                        <br />
                        <p className="text-danger">
                          <small>{this.state.sectorError}</small>
                        </p>
                      </FormGroup>
                    </Col>
              
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="email">{this.props.t("Adresse Email")} *
        <span onClick={this.info} role="button" ><InfoIcon className="zoom1" fill="blue" width="1.4rem" /> </span>
                        </Label>
                        <AvField
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder={this.props.t("Votre E-mail...")}
                          errorMessage={this.props.t("E-mail invalide")}
                          validate={{
                            required: { value: true },
                          }}
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.emailError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="password">{this.props.t("Confirmer votre Adresse Email")} *</Label>
                        <AvField
                          name="confirmEmail"
                          id="email1"
                          type="email"
                          className="form-control"
                          placeholder={this.props.t("Votre E-mail...")}
                          errorMessage={this.props.t("E-mail invalide")}
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={this.state.confirmEmail}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger">
                          <small>{this.state.confirmEmailError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="password">{this.props.t("Votre mot de passe")} *</Label>
                        <Input.Password
                         className=""
                         placeholder={this.props.t("Votre mot de passe...")}
                         value={this.state.password}
                          onChange={this.handleChange}
                          name="password"
                          id="mdp"
                         />
                        <p className="text-danger">
                          <small>{this.state.passwordError}</small>
                        </p>
                        <div className="text-start ms-1" >
                        {this.state.passwordValidations.map((v,i)=>{return(<>
                        <small className={v?"text-success":"text-danger"} >
                        <CheckIcon fill={v?"green":"red"} width="1rem" />
                        {passwordValidation[i]}
                        </small><br/></>)})}</div>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="app-label">
                        <Label for="password">{this.props.t("Confirmer Votre mot de passe")} *</Label>
                        <Input.Password
                         className=""
                         placeholder={this.props.t("Votre mot de passe...")}
                         value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          name="confirmPassword"
                          id="mdp"
                         />
                        <p className="text-danger">
                          <small>{this.state.confirmPasswordError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <FormGroup check>
                        <Label for="invalidCheck3" check>
                        <Checkbox onChange={(e)=>
                              this.setState({ checked: !this.state.checked })
                          }>
                             {this.props.t("J'ai lu et accepté")}{" "}
                          <Link>
                            <i onClick={() => this.setModalVisible(true)}>
                            {this.props.t("les conditions générales d'utilisation")}
                            </i>{" "}
                          </Link>
                          {this.props.t("de Rec-INOV, sa politique de confidentialité et son utilisation des cookies")}
                            </Checkbox>
                        </Label>
                        <p className="text-danger">
                          <small>{this.state.checkedError}</small>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <Col sm={12}>
                        <Button
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn1"
                          disabled={sending}
                        >
                          {this.props.t("S'inscrire")}
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </AvForm>

              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          title={this.props.t("POLITIQUE DE CONFIDENTIALITÉ")}
          bodyStyle={{ height: "20rem", overflowY: 'scroll' }}
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
        >
          <DetailsCookies />
        </Modal>
      </div>
    );
  };
}
export default withTranslation()(withRouter(CompanySignup))
