import React, { Component } from 'react'
import {LeftOutlined,
    RightOutlined,PlusOutlined
} from '@ant-design/icons';
import { AutoComplete, Divider, Select, Space, Typography } from 'antd';
import decrypt from '../../utils/decrypt';
import { AppContext } from '../../routes/AppContext';
import { withTranslation } from 'react-i18next';
const { Option } = Select;

class Form2 extends Component {
  static contextType = AppContext
// state={searchedValue:""}

  handleSelect=(value,i)=> {
        let location= this.context.locationsList[i.key]
        location.score=1
        location.idLocation = {
        ...location
        };
        location.idLocation._id=location.idLocation.idLocation
        this.props.setLocation(location) 
      // code: "LOC_192"
      // feature: 3
      // location:"ain djeloula"
      // _id:"61fbdd7e0d966de54b517a5c"
    }

    render() {
      const { t,i18n } = this.props;
      const sectors = { "fr": "sector_fr", "en": "sector_en" ,"ar": "sector_ar" }
      const sector = sectors[i18n.language]
      const children = [];
      let locationsList=this.context.locationsList
  for (let i = 0; i <locationsList.length ; i++) {
  children.push(<Option key={i} value={locationsList[i].location}><div className='capitalize' >{locationsList[i].location}</div></Option>);
  }
  const renderItem = (c) => ({
    value: `${c[sector]}`,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'start'
        }}
      >
        {c[sector]}
      </div>
    ),
  });
  let sectorsList=this.context.sectorsList
 const sectorChildren=sectorsList.map((s)=>renderItem(s))
        return (
            <div className="mb-5 row p-2" >
            
        {/* 
        location
        tag
         */}
<div className="col-lg-6 col-sm-12" >
<label>{t("Secteur Activité")}  <b style={{    color: "blue"}}>* </b></label>
<br/>
<AutoComplete
                    className="w-100 border "
                    id="sector"
                    type="text"
                    name="sector"
                    defaultValue={this.props.sector}
                    onChange={this.props.setSector}
                        options={sectorChildren}
                        // placeholder="try to type `b`"
                        filterOption={(inputValue, option) =>
                          { 
                            if(option.value)
                           return option?.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1}
                        }
                      />
                    {/* <Select
   
    // style={{ width: 200 }}
    placeholder={t("Selectionner..")}
    className="mb-2 w-100"
    // style={{width:"100%"}}
    showSearch
    onChange={this.props.setSector}
    defaultValue={this.props.sector}
  >
     {sectorChildren}

  </Select> */}
<br/>
<p className="text-danger"><small>{this.props.sectorError}</small></p>

<label>{t("Genre")}</label>
<br/>
                    <Select
    // style={{ width: 200 }}
    placeholder={t("Selectionner..")}
    className="mb-2 w-100"
    // style={{width:"100%"}}
    onChange={this.props.setGender}
    defaultValue={this.props.gender}
  >
    <Option value={0}>{t("Homme/Femme")}</Option>
    <Option value={1}>{t("Homme")}</Option>
    <Option value={2}>{t("Femme")}</Option>
  </Select>
<br/>
<p className="text-danger"><small>{}</small></p>
<label>{t("Niveau d'étude")}</label>
<br/>
                    <Select
    // style={{ width: 200 }}
    placeholder={t("Selectionner..")}
    className="mb-2 w-100"
    // style={{width:"100%"}}
    onChange={this.props.setEducationLevel}
    defaultValue={this.props.educationLevel}
  >
     {/* bac , bac+2, bac+3 , bac+4 , bac+5 , bac> 5,autre */}
    <Option value="Bac">Bac</Option>
    <Option value="Bac+2">Bac+2</Option>
    <Option value="Bac+3">Bac+3</Option>
    <Option value="Bac+4">Bac+4</Option>
    <Option value="Bac+5">Bac+5</Option>
    <Option value="Bac>5">Bac &gt; 5</Option>
    <Option value="Autre">Autre</Option>
  </Select>
<br/>
<p className="text-danger"><small>{}</small></p>

<label>{t("Lieu de travail")}  <b style={{    color: "blue"}}>* </b></label>
<br/>
<Select className="w-100 mb-2"  
                  // style={{ width: '40%' }} 
                   showSearch defaultValue={this.props?.location?.idLocation?.location}
    placeholder={t("Rechercher pour sélectionner")} onChange={this.handleSelect}>
    {children}
  </Select><br/>
<p className="text-danger"><small>{this.props.locationError}</small></p>

</div>
<div className="col-lg-6 col-sm-12" >

<label>{t("Contrat")}  <b style={{    color: "blue"}}>* </b></label>
<br/>

                    {/* <Select
    placeholder={t("Selectionner..")}
    className="mb-2 w-100"
    onChange={this.props.setContract}
    defaultValue={this.props.contract}
  >
    <Option value="CDD">CDD</Option>
    <Option value="CDI">CDI</Option>
    <Option value="CIVP">CIVP</Option>
    <Option value="Stage">Stage PFE </Option>
    <Option value="Stage">Stage PFA </Option>
    <Option value="Autre">Autre</Option>
  </Select> */}
  <Select
                    // style={{ width: 200 }}
                    placeholder={t("Selectionner..")}
                    showSearch
                    onSearch={(value) => {
                      this.setState({searchedValue:value})
                     }}
                     className="mb-2 w-100"
                    // style={{width:"100%"}}
                    onChange={this.props.setContract}
                    defaultValue={this.props.contract}
                    mode="multiple"
                    allowClear
                    // notFoundContent={<>
                    //   <Divider style={{ margin: '8px 0' }} />
                    //        <Space align="center" style={{ padding: '0 8px 4px' }}>
                    //          <Typography.Link onClick={()=>{this.props.setContract(this.state.searchedValue)}} style={{ whiteSpace: 'nowrap' }}>
                    //            <PlusOutlined /> Ajouter
                    //          </Typography.Link>
                    //        </Space> 
                    //  </>}
                  >
                    {/* CDD-CDI-Sivp-stage-cae-cui-ctt-karama-alternant(ca) */}
                    <Option value="CDD">CDD</Option>
    <Option value="CDI">CDI</Option>
    <Option value="CIVP">CIVP</Option>
    <Option value="Stage">Stage</Option>
    <Option value="CTT">CTT </Option>
    <Option value="CIE">CIE </Option>
    <Option value="CAE">CAE </Option>
    <Option value="CUI">CUI </Option>
    <Option value="Freelance">Freelance </Option>
    <Option value="Contrat de professionnalisation">{t("Contrat de professionnalisation(alternance)")}  </Option>
    <Option value="Contrat d’apprentissage">{t("Contrat d’apprentissage(alternance)")}  </Option>
    <Option value="Contrat de travail intermittent">{t("Contrat de travail intermittent")} </Option>

                  </Select>

<br/>
<p className="text-danger"><small>{this.props.contractError}</small></p>

<label>{t("Nature de travail")}</label>
<br/>
                    <Select
    // style={{ width: 200 }}
    placeholder={t("Selectionner..")}
    className="mb-2 w-100"
    // style={{width:"100%"}}
    onChange={this.props.setWorkType}
    defaultValue={this.props.workType}
  >
    <Option value="presentiel">{t("presentiel")}</Option>
    <Option value="Teletravail">{t("teletravail")}</Option>
    <Option value="Hybride">{t("hybride")}</Option>
  </Select>
<br/>
<p className="text-danger"><small>{}</small></p>

<label>{t("Temps de travail")}</label>
<br/>
                    <Select
    // style={{ width: 200 }}
    placeholder={t("Selectionner..")}
    className="mb-2 w-100"
    // style={{width:"100%"}}
    onChange={this.props.setWorkTime}
    defaultValue={this.props.workTime}
  >
    <Option value="Temps plein">{t("Temps plein")}</Option>
    <Option value="Temps partiel">{t("Temps partiel")}</Option>
  </Select>
<br/>
<p className="text-danger"><small>{}</small></p>

</div>



                {/* <div onClick={this.props.prev}
           className="btn btn-primary m-1" >
      <LeftOutlined   />
    </div>
            <div onClick={this.props.next}
           className="btn btn-primary m-1" >
      <RightOutlined   />
    </div> */}
            </div>
        )
    }
}
export default withTranslation()(Form2)
