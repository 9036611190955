import React, { memo } from "react";

const ArmyStarIcon = memo((props) => {
  return (
    <>
      <svg
        alt="Analytics"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="6 -5 120 120"
      >
        <path
          class="st0"
          d="M50,2.5l11.4,35h36.8L68.4,59.1l11.4,35L50,72.4L20.2,94.1l11.3-35L1.9,37.5h36.7L50,2.5z M17.3,34.9  c5.3-9.4,14.5-16.3,25.4-18.5L45,9.1C29.2,10.9,15.9,21,9.6,34.9H17.3z M57.4,16.4c10.9,2.2,20.1,9.1,25.4,18.5h7.7  C84.1,21,70.8,10.9,55,9.1L57.4,16.4z M87.3,49c0.2,1.4,0.2,2.8,0.2,4.2c0,9.8-3.8,18.7-9.9,25.4l2.4,7.3  c8.9-8.1,14.4-19.8,14.4-32.7c0-3-0.3-5.9-0.9-8.7L87.3,49z M65.5,87.3c-4.7,2.1-10,3.3-15.5,3.3s-10.8-1.2-15.5-3.3l-6.2,4.5  c6.4,3.6,13.8,5.7,21.7,5.7s15.3-2.1,21.7-5.7L65.5,87.3z M20.1,85.9l2.4-7.3c-6.1-6.7-9.9-15.6-9.9-25.4c0-1.4,0.1-2.8,0.2-4.2  l-6.2-4.5c-0.6,2.8-0.9,5.7-0.9,8.7C5.7,66.1,11.2,77.7,20.1,85.9z"
        />
      </svg>
    </>
  );
});

export default ArmyStarIcon;