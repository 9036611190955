import React, { Component } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

import ApplicationComponent from "./ApplicationComponent";
import { MyApplications } from "../../services/api/application/MyApplications";
import decrypt from "../../utils/decrypt";
import { Input, notification, Select, Space, Spin,Modal } from "antd";
import { withRouter } from "react-router-dom";
import AsyncImage from "../AsyncImage";
import gif from "../../media/image/candidature.gif";
import Pagination from "@mui/material/Pagination";
import { AppContext } from "../../routes/AppContext";
import profile from "../../media/image/profil.gif";

import { withTranslation } from "react-i18next";
const { Option } = Select;

class ApplicationsList extends Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      applications: [],
      page: 1,
      filter: null,
      filterInput: "",
      loading: true,
    };
  }
  setFilter = (value) => {
    this.setState({ filter: value });
  };
  setPage = () => {
    this.setState({ page: 1 });
  };

  showModal=()=>{
    console.log("show modal",this.context.candidateAccess);

    if(!this.context.cv?.cv){
    Modal.confirm({
      title: null,
      okText: this.props.t('ok'),
      cancelText: this.props.t('Annuler'),
      content: (
        <>
          <div className="d-flex justify-content-center">
            <AsyncImage className="w-100" src={profile} />
          </div>
          <h6 className=" text-dark rounded">
                {" "}
                {this.props.t('Vous devez renseigner votre')}{" "}
                {this.props.t('profil')}{" "}{this.props.t('et remplir votre')}{" "}
                {this.props.t("cv")}{" "}{this.props.t('pour bénéficier de nos services .')}
              </h6>
        </>
      ),
      onOk: () => {
        this.props.history.push("/candidate/cv/upload");
      },
    });}
  }
  handleChangePage = (e, page) => {
    this.setState({ page: page });
    // document.getElementById("scroller").scrollIntoView();
  };
  async componentDidMount() {
    let response = await MyApplications(
      {},
      this.context.headers
    );
    if(response){
    let applications = JSON.parse(decrypt(response.data));
      this.context.setCandidateApplications(applications)
    this.setState({ applications: applications, loading: false });}
    setTimeout(() => {
      this.showModal();
    }, 3000);
  }
  render() {
    let i = -1;
    let firstIndex = (this.state.page - 1) * 5;
    let applications = this.context.candidateApplications;
    if (this.state.filter != null)
      applications = applications.filter(
        (application) => application.state == this.state.filter
      );
    if (this.state.filterInput != "")
      applications = applications.filter((application) => {
        let filter = this.state.filterInput.toLowerCase();
        let jobPost = application.idJobPost[0];
        let jobPostTitle = application.idJobPost[0].title.toLowerCase();
        let companyName = jobPost.idCompany[0].name.toLowerCase();
        return jobPostTitle.includes(filter) || companyName.includes(filter);
      });
      let filterApp=applications;

    applications = applications.slice(firstIndex, firstIndex + 5);
    let list =
      applications &&
      applications.map((application) => {
        i++;
        return (
          <div style={{ direction: "ltr",textAlign: "left",backgroundColor:application.cvFile?'#dce4f1': "white"}} key={i}>
            <ApplicationComponent
              userId={this.props.userId}
              index={i}
              application={application}
            />
          </div>
        );
      });
    return (
      <div id="scroller">
        {this.state.loading ? (
          <div className="d-flex justify-content-center">
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : this.context.candidateApplications.length ? (
          <div className="row my-2 mx-1">
            <Input
              className="col-md-6 col-sm-12 my-1"
              placeholder={this.props.t("Rechercher un offre..")}
              value={this.state.filterInput}
              onChange={(e) => {
                const currValue = e.target.value;
                this.setState({ filterInput: currValue });
                this.setPage()
              }}
            />

            <Select
              // style={{ width: 200 }}
              placeholder="Selectionner.."
              className="col-md-6 col-sm-12 my-1"
              // style={{width:"100%"}}
              onChange={(e)=>{this.setFilter(e);this.setPage()}}
              defaultValue={this.state.filter}
            >
              <Option value={null}>{this.props.t('Tous mes candidatures')}</Option>
              <Option value={0}>{this.props.t('En attente')}</Option>
              <Option value={1}>{this.props.t('Test')}</Option>
              <Option value={1.5}>{this.props.t('Entretien')}</Option>
              <Option value={2}>{this.props.t('Entretien planifié')}</Option>
              <Option value={3}>{this.props.t('Refusé')}</Option>
              <Option value={4}>{this.props.t('Accepté')}</Option>
            </Select>
            <br />
          </div>
        ) : (
          <div className="">
            <div className="d-flex justify-content-center  ">
              <AsyncImage
                src={gif}
                alt="email"
                style={{ width: "20rem", height: "20rem" }}
              />
            </div>
            <br />
            <h6 className="text-center">
              {" "}
              <span className="m-1 icons12 "> </span>
              {this.props.t("Vous n'avez pas encore postulé ! Allez utiliser notre solution de Matching et suivez vos offres via cette interface")}.
            </h6>
          </div>
        )}
        {list}
        {filterApp.length > 0 && (
          <div className="d-flex justify-content-end m-1 mt-3 " style={{ direction: "ltr"}}>
            <Pagination
              page={this.state.page}
              onChange={this.handleChangePage}
              // rowsPerPage={3}
              count={Math.ceil(filterApp.length / 5)}
              color="primary"
            />
          </div>
        )}
     
      </div>
    );
  }
}
export default withTranslation()(withRouter(ApplicationsList));
