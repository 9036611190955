
import axios from "axios";
export const CandidateSigninWithToken = async (data, headers) => {
  try {
  let res= await  axios.post("/api/signin/candidate/token", data, {headers:headers}, { withCredentials: true })
  return res
  } catch (error) {
   
  }
};
